import { Injectable, NgZone } from "@angular/core";
import { store } from "@enersis/gp-components/store";
import { Co2BalanceToken, ITokenService } from "./token.interface";
import jwt_decode from "jwt-decode";

const tokenSlice = store.slice<string>({
  scope: "login",
  name: "token:co2balance"
});

@Injectable({
  providedIn: "root"
})
export class TokenService implements ITokenService {
  private co2balanceToken: string = "";

  constructor(private zone: NgZone) {
    tokenSlice.subscribe((token: string) => this.zone.run(() => (this.co2balanceToken = token)));
  }

  getCo2BalanceToken(): string {
    return this.co2balanceToken;
  }
  getParsedCo2BalanceToken(): Co2BalanceToken {
    return jwt_decode(this.co2balanceToken);
  }
}
