<ng-container [ngSwitch]="column">
  <ng-container *ngSwitchCase="'full'">
    <section ene-sidenav-start>
      <h2>{{ "COCKPIT.TITLE" | translate }}</h2>
      <div class="region" *ngIf="selectedRegionDetails$ | async as regionDetails">
        <b>{{ regionDetails.name }}</b> ({{
          (regionDetails.regionType === "union" ? "REGIONS.UNION_REGION" : "REGIONS.LEVELS." + regionDetails.level)
            | translate
        }})
      </div>
    </section>
  </ng-container>
  <ng-container *ngSwitchCase="'fixed'">
    <app-co2-cockpit-fixed></app-co2-cockpit-fixed>
  </ng-container>
  <ng-container *ngSwitchCase="'enlarged'">
    <app-co2-cockpit-enlarged></app-co2-cockpit-enlarged>
  </ng-container>
  <ng-container *ngSwitchDefault>Empty...</ng-container>
</ng-container>
