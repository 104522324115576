import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { onlyKwhUnit, valueAndTotalFormatter } from "../../tooltip-formatter";

@Injectable()
export class ChartFinalEnergyTraficService {
  public config: any;
  public onLoad$: Subject<any> = new Subject();
  public calculatedTotal: number;
  public onSeriesClick$: Subject<any> = new Subject();

  constructor(private translate: TranslateService) {}

  public initConfig() {
    this.config = {
      chart: {
        unit: " [kWh]",
        width: 282,
        height: 330,
        events: {
          load: (e: any) => {
            this.onLoad$.next(e);
          }
        }
      },
      plotOptions: {
        series: {
          events: {
            click: (e) => {
              this.onSeriesClick$.next(e);
            }
          }
        },
        sunburst: {
          states: {
            hover: {
              borderColor: "var(--ene-main-white)"
            }
          },
          borderWidth: 2,
          borderColor: "var(--ene-main-white)",
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
            distance: -50,
            filter: {
              property: "percentage",
              operator: ">",
              value: 4
            }
          }
        }
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        formatter: this.tooltipFormatter()
      }
    };
  }

  private tooltipFormatter() {
    const translate = this.translate;
    const that = this;

    return function () {
      return valueAndTotalFormatter({
        unitConfig: onlyKwhUnit,
        title: this.key,
        value: this.point.value,
        total: that.calculatedTotal,
        translate
      });
    };
  }
}
