import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { AccountingMethodService, DisplayChartConfig, EnergyType } from "@energy-city/components";
import { AccountingMethod } from "../../../../configs/accounting-method";
import { assetUrl } from "../../../../share/asset-url";

@Component({
  selector: "app-accounting-info-wrapper",
  templateUrl: "./accounting-info-wrapper.component.html",
  styleUrls: ["./accounting-info-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountingInfoWrapperComponent implements OnInit {
  @Input() public dqi: number = 0;
  @Input() public config: DisplayChartConfig;
  @Input() public disabledPrimary = false;

  @Output() public method = new EventEmitter<any>();

  public weatherCorrection$: Observable<any>;
  public accMethod$: Observable<AccountingMethod>;
  public energyTypePrimary: EnergyType = EnergyType.PRIMARY;
  public energyTypeFinal: EnergyType = EnergyType.FINAL;
  public locale: string;

  constructor(private accService: AccountingMethodService) {}

  public ngOnInit(): void {
    this.locale = "1.0-0";
    this.weatherCorrection$ = this.accService.weatherCorrection$;
    this.accMethod$ = this.accService.selectedAccountingMethod$;
  }

  public onChange(method: EnergyType): void {
    this.method.emit(method);
  }

  public getAssetUrl(url: string): string {
    return assetUrl(url);
  }
}
