import { Co2CockpitActionType, GetCo2CockpitReductionActions } from "./co2-cockpit-actions";

export function reducer(state = [], action: GetCo2CockpitReductionActions) {
  switch (action.type) {
    case Co2CockpitActionType.GET_KPI_EMISSION:
      return action;
    case Co2CockpitActionType.GET_KPI_EMISSION_SUCCESS:
      // case Co2CockpitActionType.GET_REDUCTION:
      // case Co2CockpitActionType.GET_REDUCTION_SUCCESS:
      // case Co2CockpitActionType.GET_REDUCTION_GOAL:
      // case Co2CockpitActionType.GET_REDUCTION_GOAL_SUCCESS:
      // case Co2CockpitActionType.GET_RANK:
      // case Co2CockpitActionType.GET_RANK_SUCCESS:
      // case Co2CockpitActionType.GET_PER_AREA:
      // case Co2CockpitActionType.GET_PER_AREA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case Co2CockpitActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
