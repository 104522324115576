import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { acceptJsonWithoutCache, createErrorMessage } from "../../../../common";
import {
  Co2SavingActionType,
  GetCo2SavingData,
  GetCo2SavingDataError,
  GetCo2SavingDataSuccess
} from "./co2-saving-actions";
import { ICo2SavingData } from "../common";

export interface ICo2SavingDataFixedYearResponse {
  result?: ICo2SavingData;
}

@Injectable()
export class Co2SavingEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  /**
   * returns details about the CO2 saving of a given region.
   */
  @Effect() public getCo2SavingData$ = this.actions$.pipe(
    ofType(Co2SavingActionType.GET_CO2_SAVING_DATA),
    switchMap((action: GetCo2SavingData) => {
      const uri = `${environment.emission.calculationApi}/publicsector/regions/${action.regionId}/co2-saving`;
      return this.http
        .get<ICo2SavingDataFixedYearResponse>(uri, {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: this.setHttpParams(action.year, action.scenarioId)
        })
        .pipe(
          map((data: ICo2SavingDataFixedYearResponse) => new GetCo2SavingDataSuccess(data.result)),
          catchError(
            (error): Observable<GetCo2SavingDataError> => of(new GetCo2SavingDataError(createErrorMessage(error)))
          )
        );
    })
  );

  private setHttpParams = (year: number, scenarioId: string): HttpParams => {
    return new HttpParams().set("year", String(year)).set("scenarioId", scenarioId);
  }
}
