import { Action } from "@ngrx/store";

export enum EMobilityActionType {
  GET_E_MOBILITY_DATA = "[energy-publicSector] GET_E_MOBILITY_DATA",
  GET_E_MOBILITY_DATA_SUCCESS = "[energy-publicSector] GET_E_MOBILITY_DATA_SUCCESS",
  GET_E_MOBILITY_DATA_ERROR = "[energy-publicSector] GET_E_MOBILITY_DATA_ERROR"
}

export class GetEMobilityData implements Action {
  public readonly type = EMobilityActionType.GET_E_MOBILITY_DATA;

  constructor(public connectionGrouId: string, public year: number, public scenarioId: string) {}
}

export class GetEMobilityDataSuccess implements Action {
  public readonly type = EMobilityActionType.GET_E_MOBILITY_DATA_SUCCESS;

  constructor(public data: IEMobilityData) {}
}

export class GetEMobilityDataError implements Action {
  public readonly type = EMobilityActionType.GET_E_MOBILITY_DATA_ERROR;

  constructor(public error: string) {}
}

export interface IEMobilityData {
  count: number;
  capacity: number;
  consumption: number;
  co2Saving: number;
}

export type EMobilityAction = GetEMobilityData | GetEMobilityDataSuccess | GetEMobilityDataError;
