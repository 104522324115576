export class Rank {
  public readonly rank: number;
  public readonly numberOfRegions: number;

  constructor(rank: number, numberOfRegions: number) {
    if (typeof rank !== "number") {
      throw Error(`Rank ${rank} is not of type number`);
    }
    if (typeof numberOfRegions !== "number") {
      throw Error(`Number of Regions ${numberOfRegions} is not of type number`);
    }
    this.rank = rank;
    this.numberOfRegions = numberOfRegions;
  }
}
