import { Action, ActionReducer, combineReducers } from "@ngrx/store";
import * as fromGasCockpitKpi from "./kpi";
import * as fromGasNetwork from "./network";
import { IApplicationState } from "../index";

export * from "./kpi/kpi-effects";
export * from "./network/network-effects";

export interface State {
  gasCockpitKpi: fromGasCockpitKpi.State;
  gasNetworkLength: fromGasNetwork.State;
}

export const gasCockpitRedusers: ActionReducer<any, Action> = combineReducers({
  gasCockpitKpi: fromGasCockpitKpi.gasCockpitKpiReducers.gasCockpitKpi,
  gasNetworkLength: fromGasNetwork.gasNetworkLengthReducers.gasNetworkLength
});

export const getGasCockpitState = (state: IApplicationState) => state && state.app && state.app.gasCockpit;
