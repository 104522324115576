<header ene-header role="header">
  <ene-header-row>
    <div ene-header-left>
      <img class="logo" [src]="logo" alt="Logo" (click)="openStaticDashboard()" />
      <div class="platform">{{ title }}</div>
    </div>
    <div ene-header-center (click)="reloadPage()">
      <img
        id="coatOfArms"
        alt="Coat of Arms"
        *ngIf="hasCoatOfArms"
        [src]="coatOfArms"
        (error)="onCoaLoadError($event)"
      />
      <div class="municipality-name">{{ municipalityName }}</div>
    </div>
    <ul ene-header-right>
      <searchbox mode="max" *ngIf="!showMinSearchbox; else minSearchbox" id="maxBox"></searchbox>
      <ng-template #minSearchbox>
        <li>
          <searchbox mode="min"></searchbox>
        </li>
      </ng-template>
      <li
        *ngIf="launchpadLinks.length"
        [ene-tooltip]="'FRAMEWORK.TOOLTIP.LAUNCHPAD' | translate"
        [placement]="tooltip.placement"
        [show-delay]="tooltip.showDelay"
      >
        <app-launchpad-popover [links]="launchpadLinks"></app-launchpad-popover>
      </li>
      <li
        *ngIf="showHelp"
        [ene-tooltip]="helpTooltipText | translate"
        [placement]="tooltip.placement"
        [show-delay]="tooltip.showDelay"
      >
        <app-help-popover></app-help-popover>
      </li>
      <li
        [ene-tooltip]="'FRAMEWORK.TOOLTIP.PROFILE' | translate"
        [placement]="tooltip.placement"
        [show-delay]="tooltip.showDelay"
      >
        <app-default-user-popover [user]="user"></app-default-user-popover>
      </li>
    </ul>
  </ene-header-row>

  <ene-modal *ngIf="!!staticDashboardImage" class="static-dashboard" id="static-dashboard" sizing="large">
    <app-dashboard-hansewerk [fileName]="staticDashboardImage"></app-dashboard-hansewerk>
  </ene-modal>
</header>
