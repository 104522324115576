import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Optional, Inject } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  AccountingMethodService,
  ChartsKpiService,
  Co2EntryComponents,
  IExtendedCockpitTab
} from "@energy-city/components";
import { NoDisplayComponent } from "../../charts/no-display/no-display.component";
import { AccountingMethod } from "../../../../configs/accounting-method";
import { EneKpisV2Component } from "@energy-city/ui/kpis-v2";
import { getTabsConfig } from "../../../../configs/tabs-config";
import { IEnvDto, PLATFORM_ENV_FILE } from "@co2-shared/common";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-co2-cockpit-enlarged",
  templateUrl: "./co2-cockpit-enlarged.component.html",
  styleUrls: ["./co2-cockpit-enlarged.component.scss"]
})
export class Co2CockpitEnlargedComponent implements OnInit, OnDestroy, AfterViewInit {
  public tabs: Array<IExtendedCockpitTab> = [];
  public ns: string = "co2_cockpit_extended";
  public activeParentTab$: Subject<IExtendedCockpitTab> = new Subject();

  public activeParentTab: IExtendedCockpitTab;
  public activeChildTab: IExtendedCockpitTab;
  public AccountingMethod = AccountingMethod;
  public weatherCorrection$ = this.accountingMethodService.weatherCorrection$;

  private entryComponents: Co2EntryComponents = new Co2EntryComponents();
  private selectedAccountingMethod: AccountingMethod;
  private destroy$: Subject<undefined> = new Subject();
  @ViewChild("eneKpis", { static: true }) private eneKpis: EneKpisV2Component;

  constructor(
    public accountingMethodService: AccountingMethodService,
    private chartsKpiService: ChartsKpiService,
    private translateService: TranslateService,
    @Optional() @Inject(PLATFORM_ENV_FILE) protected platformEnvFile: IEnvDto
  ) {}

  public ngOnInit(): void {
    this.handleParentTabChanges();
    this.handleClientConfig();
    this.handleAccountingMethod();
  }

  public ngAfterViewInit(): void {
    this.chartsKpiService.setComponentRef(this.eneKpis);
  }

  public tracker(index: number): number {
    return index;
  }

  public switchParentTab(tabName: string, index: number): void {
    this.activateCertainTab(tabName, this.tabs);
    this.activeParentTab$.next(this.tabs[index]);
  }

  public switchChildTab(index: number): void {
    this.activeChildTab.active = false;
    this.activeChildTab = this.activeParentTab.tabs[index];
    this.activeChildTab.chart.component =
      this.entryComponents.getComponent(
        this.selectedAccountingMethod || AccountingMethod.GPC,
        this.activeChildTab.chart.componentKey
      ) || NoDisplayComponent;
    this.activeChildTab.active = true;
  }

  /**
   * This method searches for the "average" and "difference_to_average" kpis in
   * the activeChildTab and returns their translations.
   */
  public getAverageAndDifferenceTranslationParams(): null | { average: string; difference_to_average: string } {
    const kpis = this.activeChildTab.kpi[0].items;
    const averageKpi = kpis.find((item) => item.mapping === "average");
    const differenceKpi = kpis.find((item) => item.mapping === "difference_to_average");
    if (!averageKpi?.title || !differenceKpi?.title) {
      return null;
    }
    return {
      average: this.translateService.instant(averageKpi.title),
      difference_to_average: this.translateService.instant(differenceKpi.title)
    };
  }

  private activateCertainTab(tabName: string, tabs: Array<IExtendedCockpitTab>): void {
    tabs.forEach((tab: IExtendedCockpitTab) => {
      if (tab.active === true) {
        tab.active = false;
      }
      if (tab.name === tabName) {
        tab.active = true;
      }
    });
  }

  private handleClientConfig(): void {
    this.tabs = getTabsConfig(this.platformEnvFile);
    this.activeParentTab$.next(this.tabs[0]);
  }

  private handleParentTabChanges(): void {
    this.activeParentTab$.pipe(takeUntil(this.destroy$)).subscribe((tab: IExtendedCockpitTab) => {
      this.activeParentTab = tab;
      if (tab.tabs) {
        tab.tabs.forEach((subtab: IExtendedCockpitTab) => {
          if (subtab.active) {
            subtab.chart.component =
              this.entryComponents.getComponent(
                this.selectedAccountingMethod || AccountingMethod.GPC,
                subtab.chart.componentKey
              ) || NoDisplayComponent;
            this.activeChildTab = subtab;
          }
        });
      } else {
        tab.chart.component =
          this.entryComponents.getComponent(
            this.selectedAccountingMethod || AccountingMethod.GPC,
            tab.chart.componentKey
          ) || NoDisplayComponent;
        this.activeChildTab = tab;
      }
    });
  }

  private handleAccountingMethod(): void {
    this.accountingMethodService.selectedAccountingMethod$
      .pipe(takeUntil(this.destroy$))
      .subscribe((method: AccountingMethod) => {
        this.selectedAccountingMethod = method;
        this.activeParentTab$.next(this.activeParentTab);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
