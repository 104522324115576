/**
 * # Template examples:
 *
 * <app-dqi-view value="3"></app-dqi-view>
 *
 *
 * <app-dqi-view value="3">
 *   <div>
 *     <div>Dqi</div>
 *     <div>3</div>
 *   </div>
 *  </app-dqi-view>
 *
 * # Css override:
 *
 * :host ::ng-deep .dqi-view {
 *   height: 1.7em;
 *   width: 2em;
 * }
 *
 * # Module import:
 *
 * @NgModule({
 *  imports: [
 *    SharedModule
 *  ]
 * })
 * export class Module { }
 */

import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation, OnInit } from "@angular/core";
import { assetUrl } from "../../share/asset-url";

@Component({
  selector: "app-dqi-view",
  templateUrl: "./dqi-view.component.html",
  styleUrls: ["./dqi-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:use-host-property-decorator no-host-metadata-property
  host: {
    class: "dqi-container"
  }
})
export class DqiViewComponent implements OnInit {
  public items = [0, 1, 2, 3, 4];
  public locale = "1.0-0";
  public assetUrl = "";

  @Input() public value = 0;

  ngOnInit(): void {
    this.assetUrl = assetUrl("");
  }
}
