import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { API_ENDPOINT_SERVICE_TOKEN, IApiEndpointService } from "../../../common/utils/api-endpoint.interface";
import { ICo2EmissionSectorResultDto } from "./dto/co2-emission-dto.interface";
import { INh3EmissionSectorResultDto } from "./dto/nh3-emission-dto.interface";
@Injectable({
  providedIn: "root"
})
export class EmissionDataService {
  constructor(
    private http: HttpClient,
    @Inject(API_ENDPOINT_SERVICE_TOKEN) private apiEndpointService: IApiEndpointService
  ) {}

  getEmissions(url: string, params: HttpParams): Observable<ICo2EmissionSectorResultDto> {
    return this.http
      .get<ICo2EmissionSectorResultDto>(`${this.apiEndpointService.getRegionPropertyApi()}${url}`, { params })
      .pipe(
        catchError(() => {
          console.error("Error querying emissions from BE");
          return of({ result: [] });
        })
      );
  }

  getNh3Emissions(url: string, params: HttpParams): Observable<INh3EmissionSectorResultDto> {
    return this.http
      .get<INh3EmissionSectorResultDto>(`${this.apiEndpointService.getRegionPropertyApi()}${url}`, { params })
      .pipe(
        catchError(() => {
          console.error("Error querying emissions from BE");
          return of({ result: [] });
        })
      );
  }
}
