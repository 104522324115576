import { ResetActionType, ResetActions } from "./reset-actions";

export function reducer(state = [], action: ResetActions) {
  switch (action.type) {
    case ResetActionType.RESET_UPDATE:
      return action;
    case ResetActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
