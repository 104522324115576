import { ICo2EmissionSectorResult } from "../../accounting-common/model/sectors/main-ghg-sector.interface";
import { ICo2EmissionSectorNodeDto } from "../data/dto/co2-emission-dto.interface";

export class EmissionsPerSector {
  public emissionsPerSector: ICo2EmissionSectorResult[];

  constructor(sectors: ICo2EmissionSectorResult[]) {
    this.emissionsPerSector = sectors;
  }

  /**
   * getSectorEmissionsForChart transforms data so it is usable by gp-chart-emission-sunburst
   * @returns SectorEmissionTree
   */
  public getSectorEmissionsForChart(): ICo2EmissionSectorNodeDto {
    return this.emissionsPerSector.reduce((accumulatedSectorData: ICo2EmissionSectorNodeDto, sectorObject) => {
      let sectorDataEntrys = sectorObject.result.reduce((accu, subSector) => ({ ...accu, ...subSector }));
      return { ...accumulatedSectorData, [sectorObject.sector]: sectorDataEntrys };
    }, {});
  }
}
