import { GlobalConfig, DefaultNoComponentGlobalConfig } from "ngx-toastr";
import { EneSnackbarComponent } from "../components/snackbar.component";

export const defaultConfig: GlobalConfig = {
  ...DefaultNoComponentGlobalConfig,
  toastComponent: EneSnackbarComponent
};

export const customConfig: Partial<GlobalConfig> = {
  iconClasses: {
    error: "snackbar-error",
    success: "snackbar-success",
    info: "snackbar-info",
    warning: "snackbar-pending"
  },
  toastClass: "ene-snackbar",
  positionClass: "snackbar-top-right",
  titleClass: "snackbar-title",
  messageClass: "snackbar-message",
  closeButton: true,
  autoDismiss: true,
  tapToDismiss: false,
  timeOut: 3000,
  maxOpened: 2
};
