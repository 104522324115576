<div class="energy-header__container">
  <app-radio-group (change)="onChange($event)" class="switcher" *ngIf="config?.switcher">
    <app-radio-button [value]="energyTypeFinal" checked="true">
      {{ "CHARTS.FINAL_ENERGY" | translate }}
    </app-radio-button>
    <app-radio-button [value]="energyTypePrimary" [disabled]="disabledPrimary">
      {{ "CHARTS.PRIMARY_ENERGY" | translate }}
    </app-radio-button>
  </app-radio-group>
  <div class="energy-header__container__info">
    <ene-icon *ngIf="weatherCorrection$ | async" icon="ec_witterungskorrektur"></ene-icon>
    <ng-container *ngIf="accMethod$ | async as method">
      <img
        *ngIf="config?.showMethod"
        class="energy-header__method"
        alt="method"
        [src]="getAssetUrl('/images/info/methods/'+ method + '.svg' | lowercase)"
      >
    </ng-container>
    <app-dqi-view [value]="dqi">
      <div class="dqi-content">
        <span>{{ "TDI.DQI.ABBR" | translate }}</span>
        <span>{{ dqi | number: locale }}</span>
      </div>
    </app-dqi-view>
  </div>
</div>
<ng-content></ng-content>
