import { trackEvent } from "@redux-beacon/google-analytics";

export const appReady = trackEvent((action: any) => {
  return {
    category: "app",
    action: "client_config",
    label: "loaded"
  };
});

export const appLogin = trackEvent((action: any, { app: { auth: { payload: { level } } } }) => {
  let levelName: string;

  switch (level) {
    case 20: {
      levelName = "Bundesland";
      break;
    }
    case 40: {
      levelName = "Kreis";
      break;
    }
    case 50: {
      levelName = "Amt";
      break;
    }
    case 60: {
      levelName = "Gemeinde";
      break;
    }
  }

  return {
    category: "app",
    action: "login",
    label: levelName
  };
});
