import { getLocaleNumberSymbol, NumberSymbol, registerLocaleData } from "@angular/common";
import { Injectable } from "@angular/core";

import localeFr from "@angular/common/locales/fr";
import localeDe from "@angular/common/locales/de";
import localeDeCH from "@angular/common/locales/de-CH";
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeDeCH);

function escapeRegExp(text: string): string {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, `\$&`);
}

@Injectable({
  providedIn: "root"
})
export class LocaleService {
  private registeredLocale: Array<string> = ["de-ch", "de-de", "fr-fr", "en-us"];

  constructor() {}

  /** This method attempts to determine the locale of the user's browser or
   * system. The locale is used to format numbers, dates (and currency), but it
   * is NOT necessarily the same as the chosen displayed language that is used
   * for translation.
   */
  public getUserLocale(defaultValue: string = "de-DE"): string {
    if (typeof window === "undefined" || typeof window.navigator === "undefined") {
      return defaultValue;
    }
    const wn = window.navigator as any;
    const lang = wn.languages?.[0] ?? wn.language ?? wn.browserLanguage ?? wn.userLanguage ?? defaultValue;

    return this.registeredLocale.some((item) => item === lang.toLowerCase()) ? lang : defaultValue;
  }

  public getDecimalSign(locale: string): string {
    return getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
  }

  public getLocalNumberRegex(locale: string): RegExp {
    const decimalSignEscaped = escapeRegExp(this.getDecimalSign(locale));
    return new RegExp(`^[-+]?[0-9]*${decimalSignEscaped}?[0-9]+([eE][-+]?[0-9]+)?$`);
  }

  public isValidLocalNumber(input: string, locale: string = this.getUserLocale()) {
    const result = this.parseLocalNumber(input, locale);
    return typeof result === "number" && !Number.isNaN(result) && Number.isFinite(result);
  }

  public parseLocalNumber(input: string, locale: string = this.getUserLocale(), logger?: any): number | null {
    if (typeof input === "number") {
      return input;
    } else if (typeof input !== "string") {
      return null;
    }

    const groupSign = getLocaleNumberSymbol(locale, NumberSymbol.Group);
    const decimalSign = this.getDecimalSign(locale);
    const withoutWhitespace = input.replace(/\s/g, "");
    const onlyValidCharactersRegex = new RegExp(`^[${escapeRegExp(groupSign)}${escapeRegExp(decimalSign)}\\deE+-]+$`);
    if (!onlyValidCharactersRegex.test(withoutWhitespace)) {
      return null;
    }
    const sanitizedInput = withoutWhitespace.split(groupSign).join("").split(decimalSign).join(".");
    const isValidNumber = /^[+-]?((\d+(\.\d*)?)|(\.\d+))([eE][+-]?\d+)?$/.test(sanitizedInput); // allows "123.456", "123." and ".456", also with added "e-789"

    return isValidNumber ? parseFloat(sanitizedInput) : null;
  }

  public toLocalString(value: number | null, locale: string = this.getUserLocale()): string {
    const decimalSign = this.getDecimalSign(locale);
    return value?.toString().replace(".", decimalSign) ?? "";
  }
}
