import {
  Component,
  OnInit,
  HostBinding,
  Input,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { EneUiService } from "@energy-city/ui/helper";
import { Active } from "../../models/forms";

@Component({
  selector: "ene-slide-toggle",
  templateUrl: "./slide-toggle.component.html",
  styleUrls: ["./slide-toggle.component.scss"],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-slide-toggle"
  }
})
export class EneSlideToggleComponent implements OnInit, OnChanges {
  @HostBinding("class.active")
  public isActive: boolean = false;

  @HostBinding("class.disabled")
  @Input()
  public disabled: boolean = false;

  @HostBinding("class.labelclick")
  @Input()
  public labelclick: boolean = true;

  @HostBinding("class.showText")
  @Input()
  public showText: boolean = false;

  @Input()
  public active: Active;

  @Input()
  public item: any;

  @Input()
  public type: "string" | "boolean" | "number";

  @Output()
  public change: EventEmitter<Active> = new EventEmitter();

  public text: any;

  constructor(private eneUiService: EneUiService) {}

  public ngOnInit() {
    this.eneUiService.textChange$.subscribe((text: any) => {
      this.text = {
        true: text.true,
        false: text.false
      };
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.active) {
      this.setActiveStatus(changes.active.currentValue);
    }
  }

  // onAllClick only does something if labelclick = true
  @HostListener("click") public onAllClick() {
    if (this.labelclick) {
      this.onItemClick();
    }
  }

  // onItemClick only does something if enabled = true
  public onItemClick(state?: boolean) {
    if (!this.disabled) {
      let newState: boolean;
      if (typeof state === "boolean") {
        // abort if same, eg. no chance
        if (state === this.isActive) {
          return;
        }
        newState = state;
      } else {
        newState = !this.isActive;
      }
      this.setActiveStatus(newState);
      this.change.emit(this.getActiveStatus());
    }
  }

  public changeValue(value: boolean) {
    this.setActiveStatus(value);
  }

  public setActiveStatus(val: Active): void {
    try {
      if (typeof val === "number" && (val === 1 || val === 0)) {
        this.isActive = Boolean(val);
      } else if (typeof val === "string" && (val === "1" || val === "0")) {
        this.isActive = Boolean(Number(val));
      } else if (typeof val === "boolean") {
        this.isActive = val;
      } else {
        this.isActive = false;
      }
    } catch (error) {
      throw error;
    }
  }

  public getActiveStatus(): Active {
    const type = this.type ? this.type : typeof this.active;
    if (type === "string") {
      return <"1" | "0">String(Number(this.isActive));
    } else if (type === "number") {
      return <1 | 0>Number(this.isActive);
    } else {
      return this.isActive;
    }
  }
}
