import { ICo2EmissionSectorResult } from "../../accounting-common/model/sectors/main-ghg-sector.interface";
import { Sector } from "../../accounting-common/model/sectors/sector.enum";
import { TotalEmission } from "./total-emission.interface";

export class TotalEmissionsPerSector {
  public emissionsPerSector: Array<TotalEmission>;

  constructor(sectors: ICo2EmissionSectorResult[]) {
    this.emissionsPerSector = sectors.reduce((prevSector, currSector) => {
      if (currSector.result.length) {
        prevSector.push({
          sector: currSector.sector,
          emissions: currSector.result.reduce((prev, curr) => prev + curr["value"], 0)
        });
      }

      return prevSector;
    }, [] as Array<TotalEmission>);
  }

  public getPerSector(): Array<TotalEmission> {
    throw new Error("not implemented");
  }

  public getTotal(): number {
    return this.emissionsPerSector.reduce((prev, curr) => prev + curr.emissions, 0);
  }

  public getSector(sector: Sector): TotalEmission {
    throw new Error("not implemented" + sector);
  }
}
