<header ene-header role="header">
  <ene-header-row>
    <a ene-header-left>
      <img [src]="logo" alt="Logo" (click)="openStaticDashboard()" />
      <span class="municipality-name">{{ municipalityName }}</span>
    </a>
    <a ene-header-center>
      <div id="headerlogo" (click)="reloadPage()"></div>
    </a>
    <ul ene-header-right>
      <searchbox mode="max" *ngIf="!showMinSearchbox; else minSearchbox" id="maxBox"></searchbox>
      <ng-template #minSearchbox>
        <li>
          <searchbox mode="min"></searchbox>
        </li>
      </ng-template>
      <li *ngIf="launchpadLinks.length">
        <app-launchpad-popover [links]="launchpadLinks"></app-launchpad-popover>
      </li>
      <li *ngIf="showHelp">
        <app-help-popover></app-help-popover>
      </li>
      <li>
        <app-default-user-popover [user]="user"></app-default-user-popover>
      </li>
    </ul>
  </ene-header-row>
  <ene-modal *ngIf="!!staticDashboardImage" class="static-dashboard" id="static-dashboard" sizing="large">
    <app-dashboard-hansewerk [fileName]="staticDashboardImage"></app-dashboard-hansewerk>
  </ene-modal>
</header>
