import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { IEnvDto, ITenantInfoDto, PLATFORM_ENV_FILE } from "@co2-shared/common";
import { environment } from "@energy-city/environments";
import { IEnvironment } from "@energy-city/components";
import { isObject } from "lodash";
import { PlatformEnvironmentService } from "../services/platform-environment.service";

/**
 * Even running the MFE, a separate BE is deployed for each tenant. Therefore,
 * URLs the the API need to be changed based on the user's tenant that is using
 * the FE.
 * */
export function mapEnvironmentConfig(envFile: IEnvDto, tenantInfo: ITenantInfoDto) {
  const dynamicVariables = [
    { name: "<%TENANT%>", value: tenantInfo.tenant },
    { name: "<%ENV%>", value: tenantInfo.environment }
  ];

  const replaceVariables = (envVariable: string): string => {
    dynamicVariables.forEach(({ name, value }) => (envVariable = envVariable.replace(name, value)));
    return envVariable;
  };

  // apiBaseUrl will be available as <%API_BASEURL%> replacement variable, so let's replace it first.
  environment.apiBaseUrl = envFile?.co2balance?.["serviceHostUrls"]
    ? envFile.co2balance["serviceHostUrls"][tenantInfo.environment]
    : replaceVariables(environment.apiBaseUrl);
  dynamicVariables.push({ name: "<%API_BASEURL%>", value: environment.apiBaseUrl });

  const mapProps = (environmentConfig: IEnvironment) => {
    Object.keys(environmentConfig).forEach((key) => {
      if (isObject(environmentConfig[key])) {
        mapProps(environmentConfig[key]);
        return;
      }

      if (typeof environmentConfig[key] === "string") {
        environmentConfig[key] = replaceVariables(environmentConfig[key]);
      }
    });
  };
  mapProps(environment);
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (service: PlatformEnvironmentService) => {
        return () =>
          PlatformEnvironmentService.loadFiles().then(() => mapEnvironmentConfig(service.envFile, service.tenantInfo));
      },
      deps: [PlatformEnvironmentService, HttpClient],
      multi: true
    },
    {
      provide: PLATFORM_ENV_FILE,
      useFactory: (service: PlatformEnvironmentService) => {
        return service.envFile;
      },
      deps: [PlatformEnvironmentService]
    }
  ]
})
export class PlatformEnvFileModule {}
