import { MeasuresActionTypes, MeasuresActions } from "./measures.actions";
import { IBenchmarkData } from "../../models/benchmark-data-interface";
import {
  IMPLEMENTATION_PERIOD_END,
  IMPLEMENTATION_PERIOD_START,
  SAVE_MEASURE_ERROR,
  SAVE_MEASURE_SUCCESS
} from "../../constants/measures";

export interface MeasuresState {
  measures?: IBenchmarkData;
  error?: { message: string };
  success?: { message: string };
  editingMeasure?: any;
}

const initialState: MeasuresState = {};

export function reducer(state: MeasuresState = initialState, action: MeasuresActions): MeasuresState {
  switch (action.type) {
    case MeasuresActionTypes.GET_MEASURES_SUCCESS:
      return {
        ...state,
        measures: action.payload,
        error: null
      };
    case MeasuresActionTypes.GET_MEASURES_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case MeasuresActionTypes.SET_EDITING_MEASURE:
      if (!action.measure) {
        return {
          ...state,
          editingMeasure: null
        };
      }
      const item = { ...action.measure };
      const timerange = [];
      if (!item.minYear || item.minYear < IMPLEMENTATION_PERIOD_START) {
        timerange[0] = IMPLEMENTATION_PERIOD_START;
      } else {
        timerange[0] = item.minYear;
      }

      if (!item.maxYear || item.maxYear > IMPLEMENTATION_PERIOD_END) {
        timerange[1] = IMPLEMENTATION_PERIOD_END;
      } else {
        timerange[1] = item.maxYear;
      }
      return {
        ...state,
        editingMeasure: { ...item, timerange }
      };
    case MeasuresActionTypes.SAVE_MEASURE_SUCCESS:
      return {
        ...state,
        success: { message: SAVE_MEASURE_SUCCESS }
      };
    case MeasuresActionTypes.SAVE_MEASURE_ERROR:
      return {
        ...state,
        error: { message: SAVE_MEASURE_ERROR }
      };
    default:
      return state;
  }
}
