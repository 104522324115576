import { ChartLocalEnergyMixActionType, LocalEnergyMixActions } from "./local-energy-mix-actions";

export function reducer(state = [], action: LocalEnergyMixActions) {
  switch (action.type) {
    case ChartLocalEnergyMixActionType.GET_ENERGY_CONSUMPTION:
    case ChartLocalEnergyMixActionType.GET_ENERGY_CONSUMPTION_SUCCESS:
    case ChartLocalEnergyMixActionType.GET_RENEWABLE:
    case ChartLocalEnergyMixActionType.GET_RENEWABLE_SUCCESS:
      return action;
    case ChartLocalEnergyMixActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
