import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { Accounting, Difference } from "../../../../accounting-common";
import { inputIsNotNullOrUndefined } from "../../../../../common/utils/util-rxjs";

@Component({
  selector: "co2-kpi-emissions-absolute-change",
  templateUrl: "./kpi-emissions-absolute-change.component.html",
  styleUrls: ["../kpi.component.scss"]
})
export class KpiEmissionsAbsoluteChangeComponent implements OnInit {
  @Input() public set accounting(accounting: Accounting | null) {
    this.accounting$.next(accounting);
  }

  @Input() public set accounting1990(accounting1990: Accounting | null) {
    this.accounting1990$.next(accounting1990);
  }

  public absoluteChange$: Observable<number> | undefined;

  public accounting$ = new BehaviorSubject<Accounting | null>(null);
  private accounting1990$ = new BehaviorSubject<Accounting | null>(null);

  ngOnInit() {
    this.absoluteChange$ = combineLatest([
      this.accounting$.pipe(filter(inputIsNotNullOrUndefined)),
      this.accounting1990$.pipe(filter(inputIsNotNullOrUndefined))
    ]).pipe(
      switchMap(([activeAccounting, activeAccounting1990]) => {
        return combineLatest([
          activeAccounting.getTotalEmissionsPerSector(),
          activeAccounting1990.getTotalEmissionsPerSector()
        ]);
      }),
      map(([activeAccounting, activeAccounting1990]) =>
        new Difference(activeAccounting.getTotal(), activeAccounting1990.getTotal()).getAbsoluteDifference()
      )
    );
  }
}
