import { GasNetworkActionsUnion, GasNetworkActionTypes } from "./network-actions";
import { State } from "./index";

export const initialState: State = {
  supplySegmentLength: 0,
  totalLength: 0
};

export function reducer(state: State = initialState, action: GasNetworkActionsUnion): State {
  switch (action.type) {
    case GasNetworkActionTypes.GET_GAS_NETWORK_LENGTH: {
      return {
        loading: true
      };
    }
    case GasNetworkActionTypes.GET_GAS_NETWORK_LENGTH_SUCCESS: {
      return {
        loading: false,
        ...state,
        ...action.data
      };
    }
    case GasNetworkActionTypes.GET_GAS_NETWORK_LENGTH_ERROR: {
      return {
        loading: false,
        ...state,
        error: action.error
      };
    }
    default: {
      return state;
    }
  }
}
