import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExportButtonDirective } from "./export-button.directive";

@NgModule({
  declarations: [ExportButtonDirective],
  imports: [CommonModule],
  exports: [ExportButtonDirective]
})
export class DirectivesModule {}
