import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from "@angular/core";
import { DecimalPipe, CurrencyPipe } from "@angular/common";
import { Subject } from "rxjs";
import { EneFormatUnitPipe } from "@energy-city/ui/pipes";
import { EneUiService } from "@energy-city/ui/helper";
import { EneKpisService } from "./ui-kpis.service";
import { IKpiBlock, IKpiItem, KpiStates } from "./ui-kpis.interface";

@Component({
  selector: "ene-kpis",
  templateUrl: "./ui-kpis.component.html",
  styleUrls: ["./ui-kpis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EneKpisComponent implements OnInit, OnChanges, OnDestroy {
  // public / inputs
  @Input() public namespace: string;
  public onReady$: Subject<boolean> = new Subject();

  // private / used only in template
  public _kpiStates: typeof KpiStates = KpiStates;
  public _data: IKpiBlock[] = [];
  public _iconsProject: string = this._eneKpisService.settings.iconsProject;
  private sharedLanguage: string;
  private sharedLanguageSubscription: any;

  constructor(
    public _eneKpisService: EneKpisService,
    private pipeEneFormatUnit: EneFormatUnitPipe,
    private pipeDecimal: DecimalPipe,
    private pipeCurreny: CurrencyPipe,
    private uiService: EneUiService,
    private cd: ChangeDetectorRef
  ) {}

  // -------------------------------------------------------------------------------
  // ANGULAR LIFECYCLE HOOKS
  // -------------------------------------------------------------------------------

  public ngOnInit() {
    if (typeof this.namespace === "undefined" || this.namespace === "") {
      console.warn("no or empty namespace defined, this will may cause problems!");
      return;
    }
    this._eneKpisService._initNamespace(this.namespace, this);
    this.onReady$.next(true);

    // initialize immediately if there is already data
    if (this._data && this._data.length > 0) {
      this.initBlocks(this._data);
    }

    // listen to languagechanges
    this.sharedLanguageSubscription = this.uiService.languageChange$.subscribe((language: string) => {
      this.sharedLanguage = language;
      this.cd.detectChanges();
    });
  }

  /**
   * angular lifecycle hook
   * does unsubscribe everything and calling removeNamespace() in the kpis.service
   */
  public ngOnDestroy() {
    this.sharedLanguageSubscription.unsubscribe();
    this._eneKpisService.removeNamespace(this.namespace);
  }
  /**
   * Executing the change Detection (because of changeStrategy.onPush)
   * @param changes delivered by angular
   */
  public ngOnChanges(changes: SimpleChanges) {
    this.cd.detectChanges();
  }

  // -------------------------------------------------------------------------------
  // PUBLIC FUNCTIONS
  // -------------------------------------------------------------------------------

  /**
   *  execute fn in ALL kpis defined with property function like IKpiFunction
   * @returns void
   */
  public runFunctions(): void {
    this._data.forEach((block: IKpiBlock) => {
      block.items.forEach((item: IKpiItem) => {
        this.runFunctionByItem(item);
      });
    });
    this.cd.detectChanges();
  }

  /**
   *  execute fn defined with property function like IKpiFunction
   * @param id id of kpi
   * @param fn optional: execute passed function if any
   */
  public runFunction(id: string | number, fn?: any) {
    const item = this.getItemById(id);
    if (item) {
      this.runFunctionByItem(item, fn);
      this.cd.detectChanges();
    }
  }

  /**
   * sets a function to a specific KPI.
   * @param id id of kpi
   * @param fn a function or a string to a function in _eneKpisService
   */
  public setFunction(id: string | number, fn: any): void {
    const item = this.getItemById(id);
    if (!item.hasOwnProperty("function")) {
      item.function = { reference: fn };
    } else {
      item.function.reference = fn;
    }
  }

  /**
   *  check for matching parameter "mapping" and updating it with passed [results], may filtered by [blockIds]
   * @param results any object which contains properties to match
   * @param @blockIds array of block ids to be filtered with (increases performance if used)
   * @returns void
   */
  public runMapping(results: any, blockIds?: string[]): void {
    // this function will be called if the mapping of a function could not be finished successfully
    const fireAfterMappingFailed = (block: IKpiBlock, item: IKpiItem) => {
      if (item.hasOwnProperty("afterMappingFailed")) {
        this.updateStateByItem(item, item.afterMappingFailed);
      } else {
        if (block.hasOwnProperty("afterMappingFailed")) {
          this.updateStateByItem(item, block.afterMappingFailed);
        } else {
          // DEFAULT afterMappingFailed
          // do nothing :))
        }
      }
    };

    // will be executed for each provided block (executed started at the end of this function)
    const mapperFn = (block: IKpiBlock) => {
      block.items.forEach((item: IKpiItem) => {
        if (item.hasOwnProperty("mapping")) {
          const mapper = item.mapping.split(".");
          let result = results;
          let index = 0;
          for (const m of mapper) {
            if (result.hasOwnProperty(m)) {
              result = result[m];
              if (index === mapper.length - 1) {
                this.updateValueByItem(item, result);
              }
              index++;
            } else {
              fireAfterMappingFailed(block, item);
            }
          }
        } else {
          fireAfterMappingFailed(block, item);
        }
      });
    };

    this._data.forEach((block: IKpiBlock) => {
      if (typeof blockIds === "object" && blockIds.length > 0) {
        if (block.hasOwnProperty("id") && blockIds.indexOf(block.id as string) >= 0) {
          mapperFn(block);
        }
      } else {
        mapperFn(block);
      }
    });
    this.cd.detectChanges();
  }

  /**
   * public accessor to update a singleKpi. states etc. will be set automatically here
   * to update multiple, use the mapping funcitonality (parameter "mapping" & public fn run.Mapping)
   * @param id id of kpi
   * @param newValue new value
   */
  public update(id: string | number, newValue: any): void {
    this.updateValueByItem(this.getItemById(id), newValue);
    this.cd.detectChanges();
  }

  /**
   * function to clear all or selected kpis.
   * @param ids optional: an array of ids to clear. if no param passed, it will clear all.
   */
  public clear(ids?: (string | number)[]): void {
    this.updateStateByIds(this._kpiStates.CLEARED, ids);
    this.cd.detectChanges();
  }

  /**
   * function to clear all or selected kpis.
   * @param ids optional: an array of ids to clear. if no param passed, it will clear all.
   */
  public pending(ids?: (string | number)[]) {
    this.updateStateByIds(this._kpiStates.PENDING, ids);
    this.cd.detectChanges();
  }

  /**
   * function to set all or selected kpis to NOTAVAILABLE.
   * @param ids optional: an array of ids to clear. if no param passed, it will clear all.
   */
  public notavailable(ids?: (string | number)[]) {
    this.updateStateByIds(this._kpiStates.NOTAVAILABLE, ids);
    this.cd.detectChanges();
  }

  /**
   * function to set all or selected kpis to UNKNOWN.
   * @param ids optional: an array of ids to clear. if no param passed, it will clear all.
   */
  public unknown(ids?: (string | number)[]) {
    this.updateStateByIds(this._kpiStates.UNKNOWN, ids);
    this.cd.detectChanges();
  }

  /**
   * Add Data to the KpiComponent (and also initialize the data) - entry point for usage.
   * @param blocks array of blocks like IKpiBlock
   * @param position determines where the new data should be placed. fallback "append" options: "append" | "prepend" | number | undefined
   */
  public addBlocks(blocks: IKpiBlock[], position?: "append" | "prepend" | number | undefined): void {
    const clonedBlocks: IKpiBlock[] = this.removeReference(blocks);
    this.initBlocks(clonedBlocks);
    this.populateArray(this._data, clonedBlocks, position);
    this.cd.detectChanges();
  }

  /**
   * adds an array of items to a block. if no blockId is passed, it will use the primary block.
   * @param items array of items like IKpiItem
   * @param BlckId if id
   * @param position determines where the new data should be placed. fallback "append" options: "append" | "prepend" | number | undefined
   */
  public addItems(items: IKpiItem[], blockId?: any, position?: "append" | "prepend" | number | undefined): void {
    const targetedBlock: IKpiBlock = typeof blockId !== "undefined" ? this.getBlockById(blockId) : this._data[0];
    const clonedItems: IKpiItem[] = this.removeReference(items);
    this.initItems(clonedItems);
    this.populateArray(targetedBlock.items, clonedItems, position);
    this.cd.detectChanges();
  }

  /**
   * removes multiple blocks specified in the array to pass. if no specified, all blocks will be removed (which means it has no data at all)
   * @param ids optional: array of ids from blocks. if undefined, all will be deleted.
   */
  public removeBlocks(blockIds?: any[]): void {
    if (typeof blockIds === "undefined") {
      this._data = [];
    }
    this._data = this._data.filter((block: IKpiBlock) => {
      return blockIds.indexOf(block.id) === -1 ? true : false;
    });
    this.cd.detectChanges();
  }

  public consolelog(item: any): boolean {
    console.log(item);
    return false;
  }

  /**
   * removes a single block
   * @param id id of block
   */
  public removeBlock(id: string | number): void {
    this._data = this._data.filter((block: IKpiBlock) => {
      return block.id !== id;
    });
    this.cd.detectChanges();
  }

  /**
   * removes multiples or all items in a specific block
   * @param blockId id of IKpiBlock
   * @param itemIds optional array ids of IKpiItem. if undefined, all Items gets removed.
   */
  public removeItems(blockId: string | number, itemIds?: (string | number)[]): void {
    const targetedBlock: IKpiBlock = this.getBlockById(blockId);

    if (typeof targetedBlock !== "undefined") {
      if (typeof itemIds === "undefined") {
        targetedBlock.items = [];
      } else {
        targetedBlock.items = targetedBlock.items.filter((item: IKpiItem) => {
          return itemIds.indexOf(item.id) === -1 ? true : false;
        });
      }
      this.cd.detectChanges();
    } else {
      console.warn("removeItems failed, because blockId could not be found", blockId);
    }
  }

  /**
   * removes a single Item from a specifid block
   * @param blockId id of a block
   * @param itemId id of an item.
   */
  public removeItem(blockId: string | number, itemId: string | number): void {
    const targetedBlock: IKpiBlock = this.getBlockById(blockId);
    if (typeof targetedBlock !== "undefined") {
      targetedBlock.items = targetedBlock.items.filter((item: IKpiItem) => {
        return item.id !== itemId;
      });
      this.cd.detectChanges();
    } else {
      console.warn("removeItem failed, because blockId could not be found", blockId);
    }
  }

  /**
   * public accessor to add a dataset to a kpi
   * to update multiple, use the mapping funcitonality (parameter "mapping" & public fn run.Mapping)
   * @param id id of kpi
   * @param newValue new value
   */
  public setDataset(id: string | number, dataset: any): void {
    const targetItem = this.getItemById(id);
    if (targetItem) {
      targetItem.dataset = dataset;
      this.cd.detectChanges();
    }
  }

  /**
   * toggles the disabled state
   * @param newState boolean -> true means disabled style, false is normal
   * @param ids array of ids of IKpiBlock or IKpiItem
   */
  public setDisabled(newState: boolean, ids?: (string | number)[]) {
    this._data.forEach((block) => {
      if (typeof ids !== "undefined" && block.hasOwnProperty("id")) {
        if (ids.indexOf(block.id) >= 0) {
          block.disabled = newState;
        }
      } else {
        if (block.hasOwnProperty("items")) {
          block.items.forEach((item) => {
            if (typeof ids !== "undefined") {
              if (ids.indexOf(item.id) >= 0) {
                block.disabled = newState;
              }
            } else {
              block.disabled = newState;
            }
          });
        }
      }
    });
    this.cd.detectChanges();
  }

  // -------------------------------------------------------------------------------
  // PRIVATE FUNCTIONS OR ONLY USED IN TEMPLATE
  // -------------------------------------------------------------------------------

  /**
   * will be called once per added / manipulated blocks -> ngOnInit, addBlocks()
   * it wil lextend the passed array with needed properties like inital states etc.
   * @param blocks array of blocks like IKpiBlock
   */
  private initBlocks(blocks: IKpiBlock[]) {
    blocks.forEach((block) => {
      // if there is a initialState set on blocklevel, use this value. if not the default to CLEARED
      const initialState = block.hasOwnProperty("initialState") ? block.initialState : this._kpiStates.CLEARED;

      // if there is a header, check if we need to set fallback for translate
      if (block.hasOwnProperty("header") && !block.header.hasOwnProperty("translate")) {
        block.header.translate = true;
      }

      this.initItems(block.items, initialState);
    });
  }

  /**
   * will be called once per added / manipulated blocks -> initBlocks, addItems()
   * @param items array of items like IKpiItem
   * @param initialState an initialState like ENUM KpiStates
   */
  private initItems(items: IKpiItem[], initialState?: KpiStates) {
    if (items && items.length > 0) {
      items.forEach((item) => {
        // add initial state if not set
        if (!item.hasOwnProperty("state")) {
          if (item.hasOwnProperty("value")) {
            // if we have a value, the state differs on the content
            this.updateStateByItem(item, this.stateByType(item.value));
          } else {
            this.updateStateByItem(item, initialState);
          }
        }

        // add fallback on translate if not set
        if (!item.hasOwnProperty("translate")) {
          item.translate = true;
        }

        // runOnInit
        if (item.hasOwnProperty("function")) {
          if (!item.function.hasOwnProperty("runOnInit") || item.function.runOnInit !== false) {
            this.runFunctionByItem(item);
          }
        }
      });
    }
  }

  /**
   * called by the template, prepares and uses different pipes as defineable by IKpiPipeOptions
   * @param item passed by template from IKpiBlocks.items
   */
  public _pipedOutput(item: IKpiItem) {
    let output;
    // use shared language if no special one is set - in a variable to be able to set again on language change
    const language = !item.pipe.hasOwnProperty("localization") ? this.sharedLanguage : item.pipe.localization;
    switch (item.pipe.type) {
      case "eneFormatUnit":
        // fallbacks for mandatory values
        if (!item.pipe.hasOwnProperty("category")) {
          item.pipe.category = "decimal";
        }
        if (!item.pipe.hasOwnProperty("unit")) {
          item.pipe.unit = "";
        }
        if (!item.pipe.hasOwnProperty("container")) {
          item.pipe.container = "label";
        }
        output = this.pipeEneFormatUnit.transform(item.value as number, item.pipe.category, item.pipe.unit, item.pipe);
        break;
      case "decimal":
        if (!item.pipe.hasOwnProperty("digitsInfo")) {
          item.pipe.digitsInfo = "";
        }
        output = this.pipeDecimal.transform(item.value as number, item.pipe.digitsInfo, language);
        break;
      case "toLocaleString":
        const number = Number(item.value);
        output = number.toLocaleString(language);
        break;
      case "currency":
        // fallbacks for mandatory values
        if (!item.pipe.hasOwnProperty("currencyCode")) {
          item.pipe.currencyCode = undefined as any;
        }
        if (!item.pipe.hasOwnProperty("display")) {
          item.pipe.display = undefined as any;
        }
        if (!item.pipe.hasOwnProperty("digitsInfo")) {
          item.pipe.digitsInfo = undefined as any;
        }
        output = this.pipeCurreny.transform(
          item.value,
          item.pipe.currencyCode,
          item.pipe.display,
          item.pipe.digitsInfo,
          language
        );
        break;
      default:
        console.warn("pipe.name not recognized -> value is outputed as it is", item);
        output = item.value;
    }
    return output;
  }

  /**
   * Internally used to set any value and a detected state
   * @param item iKpiItem
   * @param value the value you want to insert
   */
  private updateValueByItem(item: IKpiItem, value: any) {
    // runAfterUpdate
    if (item.hasOwnProperty("function")) {
      item.value = value;
      if (!item.function.hasOwnProperty("runAfterUpdate") || item.function.runAfterUpdate !== false) {
        this.runFunctionByItem(item);
      }
    } else {
      item.state = this.stateByType(value);
      if (item.state !== this._kpiStates.LOADED) {
        delete item.value;
      } else {
        item.value = value;
      }
    }
  }

  /**
   * executes a function. if no function is passed, it will lok up the property function as define like IKpiFunction
   * @param item instance of IKpiItem
   * @param fn optional: a function, if no fn passed it will check the property function
   */
  private runFunctionByItem(item: IKpiItem, fn?: any) {
    if (item) {
      if (fn instanceof Function) {
        fn(item, this);
      } else {
        if (item.hasOwnProperty("function")) {
          const data = item.function.hasOwnProperty("data") ? item.function.data : undefined;
          if (item.function.reference instanceof Function) {
            item.function.reference(item, this, data);
          } else {
            if (this._eneKpisService.functions.hasOwnProperty(item.function.reference)) {
              this._eneKpisService.functions[item.function.reference](item, this, data);
            } else {
              console.warn(
                "following kpi has a function name [",
                item.function.reference,
                "] defined which can not be found in kpi.service.ts",
                item
              );
            }
          }
        }
      }
    }
  }

  /**
   * Internally used to set any state
   * @param item iKpiItem
   * @param state the state you want to insert
   */
  private updateStateByItem(item: IKpiItem, state: KpiStates) {
    item.state = state;
    if (state !== this._kpiStates.LOADED && item.hasOwnProperty("value")) {
      delete item.value;
    }
  }

  /**
   * @returns IKpiBlock (or undefined if not found)
   * @param id string of the id of a block
   * @returns IKpiItem | undefined
   */
  private getBlockById(id: string | number): IKpiBlock | undefined {
    const obj = this._data.find(function (elem) {
      return elem.id === id;
    });
    return typeof obj !== "undefined" ? obj : undefined;
  }

  /**
   * @returns IKpiItem (or undefined if not found)
   * @param id string of the id of a KPI
   * @returns IKpiItem | undefined
   */
  private getItemById(id: string | number): IKpiItem | undefined {
    let obj;
    this._data.some(function (block: IKpiBlock, index, arr) {
      obj = block.items.find(function (elem) {
        return elem.id === id;
      });
      return typeof obj !== "undefined" ? true : false;
    });
    return obj;
  }

  /**
   * updates the state of all, multiple or single item
   * @param typeOfUpdate a strin
   * @param input anything (usually number, string or ENUM _kpiStates)
   * @param ids array of strings which are correlated to property "id" on IKpiItem or IKpiBlock. if id==blockID, it will set all items within.
   */
  private updateStateByIds(input: any, ids?: (string | number)[]): void {
    this._data.forEach((block) => {
      if (typeof ids !== "undefined" && block.hasOwnProperty("id")) {
        if (ids.indexOf(block.id) >= 0) {
          block.items.forEach((item) => {
            this.updateStateByItem(item, input);
          });
        }
      }
      if (block.hasOwnProperty("items")) {
        block.items.forEach((item) => {
          if (typeof ids !== "undefined") {
            if (ids.indexOf(item.id) >= 0) {
              this.updateStateByItem(item, input);
            }
          } else {
            this.updateStateByItem(item, input);
          }
        });
      }
    });
  }

  /**
   * helper function to manipulate a given array (eg. the blocks array or the items array) and insert the [input] into the given [target]
   * @param target array of things in which the new [input] will be inserted
   * @param input IKpiBlock[]|IKpiItem[]
   * @param position "append"|"prepend"|number|undefined
   * @returns modified array
   */
  private populateArray(
    target: any[],
    input: IKpiBlock[] | IKpiItem[],
    position?: "append" | "prepend" | number | undefined
  ): any[] {
    switch (typeof position) {
      case "string":
      default:
        // a.k.a case "undefined"
        if (position === "prepend") {
          target.unshift(...input);
        } else {
          target.push(...input);
        }
        break;
      case "number":
        target.splice(position as number, 0, ...input);
        break;
    }
    return target;
  }

  /**
   * outputs a ENUM of KpiStates depending on the entered value
   * @param value any value
   * @returns KpiStates
   */
  private stateByType(value: any): KpiStates {
    switch (typeof value) {
      case "string":
      case "number":
        return this._kpiStates.LOADED;
      case "object":
        return value === null ? this._kpiStates.NOTAVAILABLE : this._kpiStates.UNKNOWN;
      case "undefined":
      default:
        return this._kpiStates.UNKNOWN;
    }
  }

  /**
   * creates a deep copy
   * https://www.codementor.io/avijitgupta/deep-copying-in-js-7x6q8vh5d
   * @param o object or array to deepcopy
   */
  private removeReference(o: any) {
    let output: any, v: any, key: any;
    output = Array.isArray(o) ? [] : {};
    for (key in o) {
      if (o.hasOwnProperty(key)) {
        v = o[key];
        output[key] = typeof v === "object" ? this.removeReference(v) : v;
      }
    }
    return output;
  }

  /**
   * helper function for template to check if a type is matching the value
   * @param type type to be checked if value is equal to
   * @param value any value
   */
  public _isTypeof(type: string, value: any) {
    return typeof value === type ? true : false;
  }
}
