import { createSelector } from "@ngrx/store";
import { IApplicationState } from "../..";
import { IBenchmarkState } from "./benchmark-reducer";

const selectBenchmark = (state: IApplicationState) => state.app.benchmark;

export const getQualitiveChartData = createSelector(
  selectBenchmark,
  (state: IBenchmarkState) => state.qualitiveChartData
);

export const getQuantitiveChartData = createSelector(
  selectBenchmark,
  (state: IBenchmarkState) => state.quantitiveChartData
);
