import { Action } from "@ngrx/store";
import { RegionType } from "../../../services/util.service";
import { IApiConfig } from "../../../models/charts.models";

export enum MainPolluterActionTypes {
  GET_MAIN_POLLUTER_ALL_DATA = "[MainPolluter] Get all data",
  GET_MAIN_POLLUTER_STATIONARY_ENERGY_DATA = "[MainPolluter] Get data stationary energy",
  GET_MAIN_POLLUTER_STATIONARY_ENERGY_DATA_SUCCESS = "[MainPolluter] Get stationary energy data successfully",
  GET_MAIN_POLLUTER_STATIONARY_ENERGY_DATA_ERROR = "[MainPolluter] Get stationary energy data with error",
  GET_MAIN_POLLUTER_TRANSPORTATION_DATA = "[MainPolluter] Get transportation energy data",
  GET_MAIN_POLLUTER_TRANSPORTATION_DATA_SUCCESS = "[MainPolluter] Get transportation data successfully",
  GET_MAIN_POLLUTER_TRANSPORTATION_DATA_ERROR = "[MainPolluter] Get transportation data with error"
}

export interface IProps {
  api: IApiConfig;
  weatherCorrection: boolean;
  stationaryEnergy: string;
  regionId: string;
  regionType: RegionType;
  year: number;
}

export class GetMainPolluterAllData implements Action {
  public readonly type = MainPolluterActionTypes.GET_MAIN_POLLUTER_ALL_DATA;

  constructor(public props: IProps) {}
}

export class GetMainPolluterStationaryEnergyData implements Action {
  public readonly type = MainPolluterActionTypes.GET_MAIN_POLLUTER_STATIONARY_ENERGY_DATA;

  constructor(public props: IProps) {}
}

export class GetMainPolluterStationaryEnergyDataSuccess implements Action {
  public readonly type = MainPolluterActionTypes.GET_MAIN_POLLUTER_STATIONARY_ENERGY_DATA_SUCCESS;

  constructor(public payload: any) {}
}

export class GetMainPolluterStationaryEnergyDataError implements Action {
  public readonly type = MainPolluterActionTypes.GET_MAIN_POLLUTER_STATIONARY_ENERGY_DATA_ERROR;

  constructor(public error: any) {}
}

export class GetMainPolluterTransportationData implements Action {
  public readonly type = MainPolluterActionTypes.GET_MAIN_POLLUTER_TRANSPORTATION_DATA;

  constructor(public props: IProps) {}
}

export class GetMainPolluterTransportationDataSuccess implements Action {
  public readonly type = MainPolluterActionTypes.GET_MAIN_POLLUTER_TRANSPORTATION_DATA_SUCCESS;

  constructor(public payload: any) {}
}

export class GetMainPolluterTransportationDataError implements Action {
  public readonly type = MainPolluterActionTypes.GET_MAIN_POLLUTER_TRANSPORTATION_DATA_ERROR;

  constructor(public error: any) {}
}

export type MainPolluterActions =
  | GetMainPolluterAllData
  | GetMainPolluterStationaryEnergyData
  | GetMainPolluterStationaryEnergyDataSuccess
  | GetMainPolluterStationaryEnergyDataError
  | GetMainPolluterTransportationData
  | GetMainPolluterTransportationDataSuccess
  | GetMainPolluterTransportationDataError;
