import { GlossarActionType, GlossarActions } from "./glossar-actions";

export function reducer(state = [], action: GlossarActions) {
  switch (action.type) {
    case GlossarActionType.GLOSSAR_TAB_CHANGE:
      return action;
    default:
      return state;
  }
}
