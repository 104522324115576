<div
  #popperViewRef
  role="popper"
  [class.ngxp__container]="!popperOptions.disableDefaultStyling"
  [class.ngxp__animation]="!popperOptions.disableAnimation"
  [style.display]="displayType"
  [style.opacity]="opacity"
>
  <div class="ngxp__inner">
    <ng-content></ng-content>
    {{ text }}
  </div>
  <div class="ngxp__arrow"></div>
</div>
