import { Injectable } from "@angular/core";
import { postEvent } from "@enersis/gp-components/events";

export type CookieVersion = "v2";
export const CURRENT_COOKIE_VERSION: CookieVersion = "v2" as const;

export interface CookieSettings {
  functional?: boolean;
  statistical?: boolean | null;
  comfort?: boolean | null;
  dsgvo?: boolean | null;
}

@Injectable({ providedIn: "root" })
export class CookieSettingsService {
  public readCookieSettings(): CookieSettings {
    const readBoolOrNull = (name: string): boolean | null => {
      const value = localStorage.getItem(name);
      switch (value?.toLowerCase()) {
        case "false":
          return false;
        case "true":
          return true;
        default:
          return null;
      }
    };
    return {
      functional: localStorage.getItem("functionalCookies") === CURRENT_COOKIE_VERSION,
      statistical: readBoolOrNull("statisticalCookies"),
      comfort: readBoolOrNull("comfortCookies"),
      dsgvo: readBoolOrNull("dsgvo")
    };
  }

  public storeCookieSettings(settings: CookieSettings): void {
    const storeSetting = (cookieName: string, state: boolean | string | null) => {
      if (state === null || state === undefined) {
        localStorage.removeItem(cookieName);
        return;
      }
      localStorage.setItem(cookieName, String(state));
    };

    storeSetting("functionalCookies", settings.functional ? CURRENT_COOKIE_VERSION : settings.functional);
    storeSetting("statisticalCookies", settings.statistical);
    storeSetting("comfortCookies", settings.comfort);
    storeSetting("dsgvo", settings.dsgvo);

    // Notify other components (especially tracking) that the cookie settings have changed.
    postEvent("cookies: settings-changed", settings);
  }
}
