import { OnInit, Component } from "@angular/core";
import { EneModalService } from "@energy-city/ui/modal";
import { CommentService } from "./comment.service";
import { EneSnackbarService } from "@energy-city/ui/snackbar";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.scss"]
})
export class CommentComponent implements OnInit {
  public maxCount = 60000;
  public editing = false;
  public message = "";
  public commentDate;
  public commentPerson;
  public topLine;

  constructor(private modalService: EneModalService, private commentService: CommentService) {}

  public ngOnInit(): void {
    this.commentService.comment$.subscribe((comment) => {
      if (!Object.keys(comment).length) {
        return;
      }
      this.message = comment.userNotes;
      this.commentDate = new Date(comment.lastChange).toLocaleDateString();

      if (comment.givenName || comment.familyName) {
        this.commentPerson = "";
        if (comment.givenName) {
          this.commentPerson += ` ${comment.givenName}`;
        }
        if (comment.familyName) {
          this.commentPerson += ` ${comment.familyName}`;
        }
      }
    });
  }

  public closeModal() {
    this.editing = false;
    this.modalService.close("data-input_comment");
  }

  public edit() {
    this.editing = true;
  }

  public send() {
    this.commentService.send(this.message);
    this.editing = false;
  }
}
