<div>
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="generalFormGroup">
      <form [formGroup]="generalFormGroup" class="form">
        <ng-template matStepLabel>{{ "MEASURE.EDITOR.GENERAL.TITLE" | translate }}</ng-template>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'MEASURE.EDITOR.GENERAL.NAME' | translate }}"
            formControlName="title"
            autocomplete="off"
            required
            [readonly]="model.default"
          />
        </mat-form-field>
        <mat-form-field>
          <textarea
            matInput
            placeholder="{{ 'MEASURE.EDITOR.GENERAL.DESCRIPTION' | translate }}"
            formControlName="description"
            autocomplete="off"
            required
            maxLength="1000"
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            [readonly]="model.default"
          ></textarea>
        </mat-form-field>
        <div class="step_controls">
          <button mat-button matStepperNext class="step_controls_light">
            {{ "MEASURE.EDITOR.GO_TO_NEXT_STEP" | translate | uppercase }}
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="co2SavingFormGroup">
      <form [formGroup]="co2SavingFormGroup" class="form">
        <ng-template matStepLabel>{{ "MEASURE.EDITOR.C02_SAVING_SECTORS.TITLE" | translate }}</ng-template>
        <div class="radio-button">
          <input
            id="sectorRadioButton"
            type="radio"
            value="{{ co2SavingType.SECTOR }}"
            formControlName="sector"
            [attr.disabled]="isRadioButtonDisabled()"
          />
          <label for="sectorRadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.C02_SAVING_SECTORS.SECTOR" | translate
          }}</label>
        </div>
        <div class="radio-button">
          <input
            id="stationaryEnergyRadioButton"
            type="radio"
            value="{{ co2SavingType.STATIONARY_ENERGY }}"
            formControlName="sector"
            [attr.disabled]="isRadioButtonDisabled()"
          />
          <label for="stationaryEnergyRadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.C02_SAVING_SECTORS.STATIONARY_ENERGY" | translate
          }}</label>
        </div>
        <div class="radio-button">
          <input
            id="trafficRadioButton"
            type="radio"
            value="{{ co2SavingType.TRAFFIC }}"
            formControlName="sector"
            [attr.disabled]="isRadioButtonDisabled()"
          />
          <label for="trafficRadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.C02_SAVING_SECTORS.TRAFFIC" | translate
          }}</label>
        </div>
        <div class="radio-button">
          <input
            id="agricultureRadioButton"
            type="radio"
            value="{{ co2SavingType.AGRICULTURE }}"
            formControlName="sector"
            [attr.disabled]="isRadioButtonDisabled()"
          />
          <label for="agricultureRadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.C02_SAVING_SECTORS.AGRICULTURE" | translate
          }}</label>
        </div>
        <div class="radio-button">
          <input
            id="industryRadioButton"
            type="radio"
            value="{{ co2SavingType.INDUSTRY }}"
            formControlName="sector"
            [attr.disabled]="isRadioButtonDisabled()"
          />
          <label for="industryRadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.C02_SAVING_SECTORS.INDUSTRY" | translate
          }}</label>
        </div>
        <div class="radio-button">
          <input
            id="wasteAndSewageRadioButton"
            type="radio"
            value="{{ co2SavingType.WASTE_AND_SEWAGE }}"
            formControlName="sector"
            [attr.disabled]="isRadioButtonDisabled()"
          />
          <label for="wasteAndSewageRadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.C02_SAVING_SECTORS.WASTE_AND_SEWAGE" | translate
          }}</label>
        </div>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'MEASURE.EDITOR.C02_SAVING_SECTORS.AMOUNT' | translate }}"
            formControlName="co2Saving"
            required
            autocomplete="off"
          />
        </mat-form-field>

        <div class="step_controls">
          <button mat-button matStepperPrevious class="step_controls_light">
            {{ "MEASURE.EDITOR.GO_TO_PREVIOUS_STEP" | translate | uppercase }}
          </button>
          <button mat-button matStepperNext class="step_controls_light">
            {{ "MEASURE.EDITOR.GO_TO_NEXT_STEP" | translate | uppercase }}
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ "MEASURE.EDITOR.IMPLEMENTATION_PERIOD.TITLE" | translate }}</ng-template>
      <ene-range-slider
        class="novalue circles handle-circle"
        [config]="sliderConfig"
        [connect]="[false, true, false]"
        [tooltips]="true"
        [min]="IMPLEMENTATION_PERIOD_START"
        [max]="IMPLEMENTATION_PERIOD_END"
        [step]="1"
        [(ngModel)]="model.timerange"
        ngDefaultControl
        (change)="onChangeRangeSlider($event)"
      >
      </ene-range-slider>

      <div class="step_controls period">
        <button mat-button matStepperPrevious class="step_controls_light">
          {{ "MEASURE.EDITOR.GO_TO_PREVIOUS_STEP" | translate | uppercase }}
        </button>
        <button mat-button matStepperNext class="step_controls_light">
          {{ "MEASURE.EDITOR.GO_TO_NEXT_STEP" | translate | uppercase }}
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="implementationStatusFormGroup">
      <form [formGroup]="implementationStatusFormGroup" class="form">
        <ng-template matStepLabel>{{ "MEASURE.EDITOR.IMPLEMENTATION_STATUS.TITLE" | translate }}</ng-template>
        <div class="radio-button">
          <input
            id="step1RadioButton"
            type="radio"
            value="{{ implementationStatusType.STEP_0 }}"
            formControlName="status"
            autocomplete="off"
          />
          <label for="step1RadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.IMPLEMENTATION_STATUS.STEP_0" | translate
          }}</label>
        </div>
        <div class="radio-button">
          <input
            id="step2RadioButton"
            type="radio"
            value="{{ implementationStatusType.STEP_1 }}"
            formControlName="status"
            autocomplete="off"
          />
          <label for="step2RadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.IMPLEMENTATION_STATUS.STEP_1" | translate
          }}</label>
        </div>
        <div class="radio-button">
          <input
            id="step3RadioButton"
            type="radio"
            value="{{ implementationStatusType.STEP_2 }}"
            formControlName="status"
            autocomplete="off"
          />
          <label for="step3RadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.IMPLEMENTATION_STATUS.STEP_2" | translate
          }}</label>
        </div>
        <div class="radio-button">
          <input
            id="step4RadioButton"
            type="radio"
            value="{{ implementationStatusType.STEP_3 }}"
            formControlName="status"
            autocomplete="off"
          />
          <label for="step4RadioButton" class="ene-radio-button">{{
            "MEASURE.EDITOR.IMPLEMENTATION_STATUS.STEP_3" | translate
          }}</label>
        </div>

        <div class="step_controls">
          <button mat-button matStepperPrevious class="step_controls_light">
            {{ "MEASURE.EDITOR.GO_TO_PREVIOUS_STEP" | translate | uppercase }}
          </button>
          <button mat-button matStepperNext class="step_controls_light">
            {{ "MEASURE.EDITOR.GO_TO_NEXT_STEP" | translate | uppercase }}
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ "MEASURE.EDITOR.SAVE_OR_RESET_ENTRIES.TITLE" | translate }}</ng-template>
      <span>
        {{ "MEASURE.EDITOR.SAVE_OR_RESET_ENTRIES.DESCRIPTION" | translate }}
      </span>

      <div class="step_controls">
        <button mat-button class="step_controls_save" (click)="save()">
          <ene-icon icon="ec_save"></ene-icon>
          <span>{{ "MEASURE.EDITOR.SAVE" | translate | uppercase }}</span>
        </button>
        <button mat-button class="step_controls_light" (click)="close()">
          {{ "MEASURE.EDITOR.RESET_TO_DEFAULT" | translate | uppercase }}
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
  <p>{{ "MEASURE.EDITOR.REQUIRED.EXPLANATION" | translate }}</p>
</div>
