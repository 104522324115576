import { enableProdMode, NgZone } from "@angular/core";

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { Router, NavigationStart } from "@angular/router";

import { singleSpaAngular, getSingleSpaExtraProviders } from "single-spa-angular";

import { AppModule } from "./app/app.module";
import { singleSpaPropsSubject } from "./single-spa/single-spa-props";
import { environment } from "@energy-city/environments";
import { PlatformEnvironmentService } from "@energy-city/co2balance-components/services";

if (environment.production) {
  enableProdMode();
}

const lifecycles = singleSpaAngular({
  bootstrapFunction: (singleSpaProps) => {
    singleSpaPropsSubject.next(singleSpaProps);

    return PlatformEnvironmentService.loadFiles().then(() =>
      platformBrowserDynamic(getSingleSpaExtraProviders()).bootstrapModule(AppModule)
    );
  },
  template: "<co2balance-root />",
  Router,
  NavigationStart,
  NgZone
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
