import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { UnitsFilterComponent } from "./units-filter.component";

@NgModule({
  declarations: [UnitsFilterComponent],
  imports: [CommonModule, TranslateModule, MatFormFieldModule, MatSelectModule],
  exports: [UnitsFilterComponent]
})
export class UnitsFilterModule {}
