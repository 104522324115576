import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subject, Subscription, merge, of } from "rxjs";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { EneSnackbarService } from "libs/ui/src/lib/snackbar";
import { map } from "rxjs/operators";
import { AuthenticationService } from "../../../authentication/services/exported/authentication.service";
import { CookieSettings, CookieSettingsService } from "../../services/popup/cookie-settings.service";

export interface IDataPrivacyData {
  title: string;
  icon: string;
  sections: Array<IDataPrivacyTabData>;
  initialTab?: number;
  initialAnchor?: string;
}

export interface IDataPrivacyTabData {
  id: string | number;
  title?: string;
  content?: string;
  contentIsTenantSpecific?: boolean;
}

@Component({
  selector: "app-data-privacy",
  templateUrl: "./data-privacy.component.html",
  styleUrls: ["./data-privacy.component.scss"]
})
export class DataPrivacyComponent implements OnDestroy, AfterViewInit {
  @ViewChild("dataPrivacyContent", { static: false }) public glossaryContent: ElementRef;

  protected subscriptionCollection: Array<Subscription> = [];

  public dataPrivacy_data: IDataPrivacyData;
  public dataPrivacy_tabs: Array<IDataPrivacyTabData> = [];
  public dataPrivacy_activeTab: number = 0;
  public dataPrivacy_title: string = "";
  public canAbort = false;
  public policyForm: FormGroup;
  public form: FormGroup;
  public isPolicyAccepted$: Observable<boolean>;
  public isRejecting = false;
  public acceptedCookies: CookieSettings;
  public allCookiesSelected$: Observable<boolean>;

  private destroy$ = new Subject();
  private resetValues$: BehaviorSubject<{ statisticalCookies: boolean; comfortCookies: boolean }>;

  constructor(
    private dialogRef: MatDialogRef<DataPrivacyComponent>,
    private authService: AuthenticationService,
    private cookieSettingsService: CookieSettingsService,
    private fb: FormBuilder,
    private snackbar: EneSnackbarService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data: IDataPrivacyData
  ) {
    this.assignSectionsIds(data);

    this.acceptedCookies = this.cookieSettingsService.readCookieSettings();

    const hasAcceptedTermsAndFunctionalCookiesBefore = !!this.acceptedCookies?.functional;
    this.canAbort = hasAcceptedTermsAndFunctionalCookiesBefore;

    this.policyForm = this.fb.group({
      terms: [hasAcceptedTermsAndFunctionalCookiesBefore, Validators.requiredTrue],
      acceptCookies: [this.acceptedCookies?.functional, Validators.requiredTrue],
      functionalCookies: [true, Validators.requiredTrue],
      dsgvo: [this.acceptedCookies?.dsgvo, Validators.requiredTrue],
      statisticalCookies: [this.acceptedCookies?.statistical ?? true],
      comfortCookies: [this.acceptedCookies?.comfort ?? true],
    });

    this.resetValues$ = new BehaviorSubject({
      statisticalCookies: this.policyForm.value.statisticalCookies,
      comfortCookies: this.policyForm.value.comfortCookies
    });

    this.allCookiesSelected$ = merge(this.resetValues$, this.policyForm.valueChanges).pipe(
      map((formValues) => {
        return formValues?.statisticalCookies && formValues?.comfortCookies;
      })
    );

    this.dataPrivacy_data = data;
    this.dataPrivacy_activeTab = data.initialTab || 0;

    if (!this.dataPrivacy_data) {
      return;
    }

    this.dataPrivacy_tabs = this.dataPrivacy_data.sections;
    this.dataPrivacy_title = this.dataPrivacy_data.title;

    this.isPolicyAccepted$ = of(true);
  }

  public ngAfterViewInit(): void {
    this.scrollToTheTop();
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach((subscr) => subscr.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }

  private assignSectionsIds(glossary_data: IDataPrivacyData) {
    if (glossary_data && glossary_data.sections) {
      // check if ids are set. if not set it by number
      let i = 0;
      for (const section of glossary_data.sections) {
        if (section.id == null) {
          section.id = i++;
        }
      }
    }
  }

  public getTenantTranslation(): string {
    const tenant = this.authService.tokenParsed.tenant.toUpperCase();
    const activeTab = this.dataPrivacy_tabs[this.dataPrivacy_activeTab];

    const translationKey = activeTab.contentIsTenantSpecific ? `${activeTab.content}_${tenant}` : activeTab.content;
    const translation = this.translate.instant(translationKey);

    if (translationKey === translation) {
      return this.translate.instant(`${this.dataPrivacy_tabs[this.dataPrivacy_activeTab].content}`);
    }

    return translation;
  }

  public changeTab(tabIndex: number) {
    this.dataPrivacy_activeTab = tabIndex;
    this.scrollToTheTop();
  }

  private scrollToTheTop(): void {
    const natElem = this.glossaryContent?.nativeElement;
    if (!natElem) {
      return;
    }
    natElem.scrollTop = 0;
  }

  public changeToCookieTab(): boolean {
    if (this.dataPrivacy_activeTab === 2) return;
    this.dataPrivacy_activeTab = 2;

    const current = this.cookieSettingsService.readCookieSettings();

    // set the most privacy friendly settings when the user wants to change the
    // cookie settings
    this.policyForm.patchValue({
      statisticalCookies: current.statistical ?? false,
      comfortCookies: current.comfort ?? false
    });

    return false; // return false to prevent the click event to propagate to the checkbox
  }

  public onDeleteCookies() {
    this.canAbort = false;
    this.isPolicyAccepted$ = of(false);
    this.acceptedCookies = {};
    this.policyForm.reset({
      terms: false,
      acceptCookies: false,
      functionalCookies: true,
      statisticalCookies: false,
      comfortCookies: false,
      dsgvo: false
    });
    this.cookieSettingsService.storeCookieSettings(this.acceptedCookies);
    this.resetValues$.next({
      statisticalCookies: false,
      comfortCookies: false
    });

    this.snackbar.info("Cookies wurden gelöscht.", {
      timeOut: 3000
    });
  }

  public onRejectClicked() {
    this.isRejecting = !this.isRejecting;
  }

  private submitPolicy(cookies: CookieSettings): void {
    const wasDeleted = (cookieType: keyof CookieSettings) =>
      this.acceptedCookies?.[cookieType] && !cookies?.[cookieType] ? cookieType : false;

    const deleted = [wasDeleted("functional"), wasDeleted("comfort"), wasDeleted("statistical")].filter((del) => !!del);

    if (deleted.length > 0) {
      this.snackbar.info("Unerwünschte Cookies wurden gelöscht.", {
        timeOut: 3000
      });
    }

    this.acceptedCookies = cookies;
  }

  /**
   * Called when the submit button pressed
   * @returns
   */
  public submit(): void {
    if (this.policyForm.invalid) {
      return;
    }
    const values = this.policyForm.value;
    const acceptedCookies: CookieSettings = {
      functional: values.functionalCookies,
      statistical: values.acceptCookies && values.statisticalCookies,
      comfort: values.acceptCookies && values.comfortCookies,
      dsgvo: values.dsgvo
    };

    this.cookieSettingsService.storeCookieSettings(acceptedCookies);
    this.submitPolicy(acceptedCookies);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
