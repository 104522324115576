import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit } from "@angular/core";
import { NgScrollbar } from "ngx-scrollbar";

@Component({
  selector: "app-data-input",
  templateUrl: "./data-input.component.html",
  styleUrls: ["./data-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataInputComponent implements AfterViewInit {
  @ViewChild("scrollBar", { static: true }) scrollBar: NgScrollbar;

  ngAfterViewInit() {
    this.scrollBar.updated.subscribe(() => this.scrollToRight());
  }

  private scrollToRight() {
    this.scrollBar.scrollTo({ right: 0, duration: 1 });
  }
}
