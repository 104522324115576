import { Pipe, PipeTransform } from "@angular/core";
import { LocaleService } from "./locale.service";

/**
 * Quote from MDN:
 * The following properties fall into two groups: minimumIntegerDigits,
 * minimumFractionDigits, and maximumFractionDigits in one group,
 * minimumSignificantDigits and maximumSignificantDigits in the other. **If at
 * least one property from the second group is defined, then the first group is
 * ignored.**
 */
export interface ILocalNumberPipeOptions extends Intl.NumberFormatOptions {
  locale?: string;
  /** If the displayed is smaller than 1.0, a different set of rules can apply
   * (if defined).
   * @summary This rule allows us to display
   * - `12345.6789` as `"12345.68"` (only
   * two **fraction** digits),
   * - `0.005678` as `"0.0057"` (two **significant** digits), rather than just `"0.00"`.
   */
  smallerThanOneRules?: Intl.NumberFormatOptions;
}

@Pipe({
  name: "localNumber"
})
export class LocalNumberPipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  public transform(value: number, options?: ILocalNumberPipeOptions): unknown {
    const { locale, smallerThanOneRules, ...args } = options ?? {};

    const ruleSet = !!smallerThanOneRules && value < 1.0 ? smallerThanOneRules : args;

    return value.toLocaleString(locale ?? this.localeService.getUserLocale(), ruleSet);
  }
}
