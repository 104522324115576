import { IUnitOption } from "../../../../../configs/data-panel";
import { IDataInputTable } from "@energy-city/components";
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { isEqual } from "lodash";
import { combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, takeUntil } from "rxjs/operators";
import { DataInputService } from "../data-input.service";

@Component({
  selector: "app-data-input-table",
  templateUrl: "./data-input-table.component.html",
  styleUrls: ["./data-input-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataInputTableComponent implements OnInit, OnDestroy {
  public asides: Array<any>;
  public columns: Array<string>;
  public dataSource: Array<any>;
  public isLoading$: Observable<boolean>;
  public errorMessage$: Observable<string>;
  public units: Array<IUnitOption>;
  public selectedUnit: string;
  private destroy$ = new Subject();

  constructor(private cdr: ChangeDetectorRef, private dataInputService: DataInputService) {}

  public ngOnInit(): void {
    this.handleDataAndUnitChange();
    this.isLoading$ = this.dataInputService.selectLoadingState();
    this.errorMessage$ = this.dataInputService.selectErrorMessage();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public trackByFn(_: any, value: string): string {
    return value;
  }

  private handleDataAndUnitChange(): void {
    // the unit changes either by switching a tab or by manually selecting the
    // unit in the drop-down.
    const unitChange$ = this.dataInputService.selectUnit();

    // data changes either by backend request or by input. The units will always
    // be the standard units in which the data is stored in the backend (e.g
    // "km" instead of "m").
    const dataChange$ = this.dataInputService.selectDataSource();

    combineLatest([dataChange$, unitChange$])
      .pipe(
        filter(([data]) => Boolean(data)),
        map(([data, selectedUnit]) => ({
          data,
          units: {availableUnits: data.units, selectedUnit: this.chooseUnit(data.units, selectedUnit)}
        })),
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe(({ data, units }) => {
        this.updateComponentProps(data, units);
        this.cdr.markForCheck();
      });
  }

  private chooseUnit(availableUnits: IUnitOption[], previouslySelectedUnit: IUnitOption): IUnitOption {
    if (availableUnits.find(u => u.id === previouslySelectedUnit?.id) !== undefined) {
      return previouslySelectedUnit;
    } else {
      return availableUnits[0];
    }
  }

  private updateComponentProps(
    data: IDataInputTable,
    units: {
      availableUnits: Array<IUnitOption>;
      selectedUnit: IUnitOption;
    }
  ): void {
    this.units = units.availableUnits;
    this.selectedUnit = units.selectedUnit.id;
    // TODO: This needs refactoring -> probably move responsibility to cell
    this.dataInputService.setActiveUnit(units.selectedUnit);

    this.columns = data.columns;
    this.dataSource = this.dataInputService.convertDataByUnit(data.dataSource, units.selectedUnit.scale);
    let flip = 0;
    this.asides = data.aside.map((item, idx, arr) => {
      if (arr[idx - 1] && arr[idx - 1].dependency === false) {
        flip = idx % 2;
      }
      const isOdd = Boolean(flip - (idx % 2));

      return { ...item, isOdd };
    });
  }
}
