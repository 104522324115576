// tslint:disable:directive-selector
import { Directive } from "@angular/core";

@Directive({
  selector: "[ene-footer-right]",
  exportAs: "eneFooterRightDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-footer-right"
  }
})
export class EneFooterRightDirective {}
