import { Action } from "@ngrx/store";

export enum TimelineActionType {
  TIMELINE_INIT = "[Timeline] TIMELINE_INIT",
  TIMELINE_UPDATE = "[Timeline] TIMELINE_UPDATE"
}

export class TimelineInit implements Action {
  public readonly type = TimelineActionType.TIMELINE_INIT;

  constructor(public year: number) {}
}

export class TimelineUpdate implements Action {
  public readonly type = TimelineActionType.TIMELINE_UPDATE;

  constructor(public year: number) {}
}

export type TimelineAction = TimelineInit | TimelineUpdate;
