import { ofType } from "@ngrx/effects";
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { EneModalService } from "@energy-city/ui/modal";
import { PopperContentComponent } from "@energy-city/ui/popper";

// ngrx
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";

// state
import * as fromRoot from "../../../../state/index";
import { TourActionType, HelpTabChange } from "../../../../state/index";
import { PopupService } from "../../../../modules/pop-up/services/popup/popup.service";
import { UtilService } from "@energy-city/components";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-help-popover",
  templateUrl: "./help-popover.component.html",
  styleUrls: ["./help-popover.component.scss"]
})
export class HelpPopoverComponent implements OnInit, OnDestroy {
  @ViewChild("Help", { static: true }) private popperContent: PopperContentComponent;
  @ViewChild("helpContent", { static: false }) public helpContent: ElementRef;
  private destroy$ = new Subject();
  public help: any = {};
  public help_tabs: { id: string | number; content?: string; title?: string }[] = [];
  public help_activeTab: number;
  public help_title: string = "";

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private eneModalService: EneModalService,
    private cdr: ChangeDetectorRef,
    private popupService: PopupService,
    private utilService: UtilService
  ) {}

  public ngOnInit() {
    this.utilService.clientConfig$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res?.help) {
        this.checkHelpConfig(res.help);
        this.help = res.help;
        // init content variables so the modal content is also there when the modal is opened
        // from outside of this component with the modal service
        this.help_activeTab = 0;
        if (this.help.glossary && this.help.glossary.sections && this.help.glossary.title) {
          this.help_tabs = this.help.glossary.sections;
          this.help_title = this.help.glossary.title;
        }
      }
    });
    this.store
      .pipe(select(fromRoot.GetTourState), ofType(TourActionType.HELP_TAB_CHANGE), takeUntil(this.destroy$))
      .subscribe((res: HelpTabChange) => {
        this.goToAnchorByName(res.anchor);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkHelpConfig(help) {
    if (help.glossary && help.glossary.sections) {
      // check if ids are set. if not set it by number
      let i = 0;
      for (const section of help.glossary.sections) {
        if (section.id == null) {
          section.id = i++;
        }
      }
    }
  }

  /**
   * executed on (click) action for entry in help popup
   */
  public openContent(title: string, tabs: any[], activeTab: number = 0) {
    this.popperContent.hide();
    this.help_activeTab = activeTab;
    this.help_tabs = tabs;
    this.help_title = title;
    this.eneModalService.open("help_information");
  }

  /**
   * executed on (click) action for entry in help popup
   */
  public openGlossary(activeTab: number = 0) {
    this.popupService.openGlossaryAtTab(activeTab);
    this.popperContent.hide();
  }

  public changeTab(tabIndex) {
    this.help_activeTab = tabIndex;

    // scroll to top
    const natElem = this.helpContent?.nativeElement;
    if (!natElem) {
      return;
    }
    natElem.scrollTop = 0;
  }

  /**
   * Jump to anchor, maybe also change tab
   * the click needs to be on a <a data-anchor="(<tabId>.)<#anchorId>">
   * @param event
   */
  public goToAnchor(event) {
    if (event.srcElement.tagName !== "A") {
      return;
    }
    let anchors = event.srcElement.attributes.getNamedItem("data-anchor");
    if (!anchors || !anchors.nodeValue) {
      return;
    }
    anchors = anchors.nodeValue.split(".");

    // set tab
    if (anchors.length === 2) {
      // find index of tab by id
      const tabIndex = this.help_tabs.findIndex((tab) => tab.id === anchors[0]);
      if (tabIndex < 0) {
        return;
      }
      this.help_activeTab = tabIndex;
    }

    // scroll to section
    const anchor = anchors[anchors.length - 1]; // could be 0 or 1 index
    if (anchor) {
      // if tab change, the content needs time to load
      // @TODO: Maybe better to use here the translation service and wait for finish
      setTimeout(() => {
        const natElem = this.helpContent?.nativeElement;
        if (!natElem) {
          return;
        }
        const anchorElem = natElem.querySelector(anchor);
        if (!anchorElem) {
          return;
        }
        anchorElem.scrollIntoView();
      }, 100);
    }
  }

  public goToAnchorByName(anchor: string) {
    const anchors = anchor.split(".");
    // set tab
    if (anchors.length === 2) {
      // find index of tab by id
      const tabIndex = this.help_tabs.findIndex((tab) => tab.id === anchors[0]);
      if (tabIndex < 0) {
        return;
      }
      this.help_activeTab = tabIndex;
      this.cdr.markForCheck();
    }

    // scroll to section
    const section = anchors[anchors.length - 1]; // could be 0 or 1 index
    if (section) {
      // if tab change, the content needs time to load
      // @TODO: Maybe better to use here the translation service and wait for finish
      setTimeout(() => {
        const natElem = this.helpContent?.nativeElement;
        if (!natElem) {
          return;
        }
        const anchorElem = natElem.querySelector(section);
        if (!anchorElem) {
          return;
        }
        anchorElem.scrollIntoView();
      }, 100);
    }
  }
}
