<div class="navigation__categories">
  <app-radio-group *ngIf="categories && categories.length" name="dataInputCategory" (change)="onChangeCategory($event)">
    <app-radio-button
      *ngFor="let category of categories; trackBy: trackByFn"
      [value]="category.id"
      [disabled]="category.disabled"
      [checked]="category.id === initialCategory"
    >
      <ene-icon [icon]="category.icon"></ene-icon>
      <span>{{ category.name | translate }}</span>
    </app-radio-button>
  </app-radio-group>
</div>
<div class="navigation__nested-categories">
  <app-radio-group
    *ngIf="nestedCategories && nestedCategories.length"
    name="dataInputNestedCategory"
    (change)="onChangeNestedCategory($event)"
  >
    <app-radio-button
      *ngFor="let category of nestedCategories; trackBy: trackByFn"
      [value]="category.id"
      [disabled]="category.disabled"
      [checked]="category.id === initialNestedCategory"
    >
      <span>{{ category.name | translate }}</span>
    </app-radio-button>
  </app-radio-group>
  <button class="button" [disabled]="false" (click)="onExport()">
    <ene-icon class="icon-padding" icon="ec_data-export"></ene-icon>
    <span class="span-padding">{{ "DATA_PANEL.EXPORT" | translate }}</span>
  </button>
  <app-toggle-button
    class="lock-button"
    [disabled]="editting$ | async"
    [checked]="true"
    [icons]="lockIcons"
    (change)="onLock($event)"
  ></app-toggle-button>
  <button class="button" [disabled]="locked$ | async" (click)="onImport()">
    <ene-icon class="icon-padding" icon="ec_data-import"></ene-icon>
    <span class="span-padding">{{ "DATA_PANEL.IMPORT" | translate }}</span>
  </button>
  <button class="button" [disabled]="locked$ | async" (click)="onSave()">
    <ene-icon icon="ec_save"></ene-icon>
  </button>
</div>
