import { ActionReducerMap } from "@ngrx/store/src/models";
import { reducer } from "./network-reducer";

export const networkReducers: ActionReducerMap<any> = {
  network: reducer
};

export interface INetworkState {
  payload?: any;
  type?: string;
}
