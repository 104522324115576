import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EneFormatUnitPipe } from "@energy-city/ui/pipes";
import { EneChartService } from "@energy-city/ui/chart";
import { UtilService } from "./util.service";
import * as d3Format from "d3-format";

@Injectable({
  providedIn: "root"
})
export class ChartsService {
  private debug: boolean = true;
  public chartsSettings: any = {
    synthesis_energyconsumption_cockpit: {
      subtitle: {
        text: "",
        translation: "BUILDING.TABS.KPI.CHART_SYNTHESIS_ENERGYCONSUMPTION.DESCRIPTION"
      },
      legend: {
        enabled: false
      },
      chart: {
        unit: " [kWh]",
        marginBottom: 90
      },
      tooltip: {
        enabled: true,
        formatter: this.formatter()
      }
    }
  };

  public chartsData: any = {};

  constructor(
    private translate: TranslateService,
    private eneChartService: EneChartService,
    private eneFormatUnitPipe: EneFormatUnitPipe,
    private utilService: UtilService
  ) {
    this.translateSettings();
    this.translateOptions();
  }

  public formatter() {
    const eneFormatUnitPipe = this.eneFormatUnitPipe;
    const format = d3Format.format(",.0f");
    return function () {
      return `<b>${this.point.name}</b><br>
      <hr/>
      ${eneFormatUnitPipe.transform(this.point.value, "decimal", "kWh", { localization: "de-DE", prefix: " " })}<br>
      ${format(this.point.percentage)} %`;
    };
  }

  public getChartSettings(name: string) {
    if (this.chartsSettings.hasOwnProperty(name)) {
      return this.chartsSettings[name];
    }
  }
  public getChartData(name: string) {
    if (this.chartsData.hasOwnProperty(name)) {
      return this.chartsData[name];
    }
  }

  public setChartData(name: string, data?: any) {
    this.chartsData[name] = this.transformChartData(data);
    this.chartsData = Object.assign({}, this.chartsData);
  }

  public initChart(name: string) {
    if (this.chartsData.hasOwnProperty(name)) {
      if (this.debug) {
        console.error("initChart: could not initialize [" + name + "] because it is already initialized!");
      }
    } else {
      this.chartsData[name] = [];
    }
  }

  public removeChart(name: string) {
    if (this.chartsData.hasOwnProperty(name)) {
      delete this.chartsData[name];
    }
  }

  private translateSettings() {
    for (const key in this.chartsSettings) {
      if (this.chartsSettings.hasOwnProperty(key)) {
        const chart = this.chartsSettings[key];

        // subtitle
        if (chart.hasOwnProperty("subtitle")) {
          this.translate.get(chart.subtitle.translation).subscribe((res: string) => {
            chart.subtitle.text = res;
          });
        }
      }
    }
  }

  public transformAndTranslate(data: any) {
    const result = this.transformChartData(data);
    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < result[i].data.length; j++) {
        result[i].data[j].translationKey = result[i].data[j].name;
        this.translate.get(result[i].data[j].translationKey).subscribe((translation) => {
          result[i].data[j].name = translation;
        });
      }
    }
    return result;
  }

  /**
   * data for the charts comes with a code, this function will look up the corresponding entry in the static datalayerClasses
   * @param data sarray of data series
   */
  public transformChartData(data: any[]) {
    if (data && data.length > 0) {
      const outputBuffer: any[] = [];
      const inLegend: string[] = [];
      data.forEach((serie) => {
        if (serie.data.length > 0) {
          // TODO MAKE IT UNIVERSAL FOR ALL CHARTS
          // translate category
          const dataLayerCategory = serie.name === "ENERGY_SERVICES" ? "energy_services" : "heater_energy_source";
          let title: string = "";
          this.translate
            .get("BUILDING.TABS.KPI.CHART_SYNTHESIS_ENERGYCONSUMPTION." + serie.name)
            .subscribe((res: string) => {
              title = res;
            });

          let firstRound = true;
          serie.data.forEach((point) => {
            const datalayer = this.utilService.getDataLayerClass(dataLayerCategory, point.code);
            point.color = datalayer.color;
            point.name = datalayer.label;
            point.kWh = this.eneFormatUnitPipe.transform(point.value, "decimal", "kWh");
            if (firstRound) {
              point.showTitle = title;
              firstRound = false;
            }
            // check if already in legend or not
            if (inLegend.indexOf(point.name) === -1) {
              inLegend.push(point.name);
              point.showInLegend = true;
            } else {
              point.showInLegend = false;
            }
          });
          outputBuffer.push(serie);
        }
      });
      return outputBuffer.length > 0 ? outputBuffer : [];
    } else {
      return [];
    }
  }

  public hexToRgb(hex) {
    hex = hex.replace("#", "");
    const step = hex.length === 3 ? 1 : 2;
    const re = new RegExp(`(.{${step}})`, "g");
    let rgbMatches = hex.match(re);
    if (rgbMatches.length === 3) {
      rgbMatches = rgbMatches.map((item) => parseInt(item, 16));
    } else {
      rgbMatches = [0, 0, 0];
    }
    return rgbMatches;
  }

  private translateOptions() {
    const chartsOptions: any = { lang: {} };
    this.translate.get(["TDI.NO_DATA_AVAILABLE.LONG", "TDI.LOADING.LONG"]).subscribe((res) => {
      chartsOptions.lang.noData = res["TDI.NO_DATA_AVAILABLE.LONG"];
      chartsOptions.lang.loading = res["TDI.LOADING.LONG"];
      this.eneChartService.setOptions(chartsOptions);
    });
  }

  public setSerieNames(series: any) {
    series.forEach((serie) => {
      serie.name = this.translate.instant(serie.translationKey);
    });

    return series;
  }

  public setCategoryNames(categories: any): Array<any> {
    const translatedCategories = [];
    categories.forEach((category) => {
      this.translate.get(category).subscribe((translation) => {
        translatedCategories.push(translation);
      });
    });
    return translatedCategories;
  }
}
