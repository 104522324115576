import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneIconComponent } from "./components/ui-icon.component";
import { EneIconService } from "./services/ui-icon.service";

@NgModule({
  declarations: [EneIconComponent],
  imports: [CommonModule],
  exports: [EneIconComponent],
  providers: [EneIconService]
})
export class EneIconModule {}
