import { NgModule } from "@angular/core";
import { AuthService } from "./services/exported/auth.service";

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [AuthService]
})
export class AuthenticationModule {}
