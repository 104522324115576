import { Action } from "@ngrx/store";
import { ICo2SavingForAllYearsData } from "./co2-saving-reducer";

export enum Co2SavingForAllYearsActionType {
  GET_CO2_SAVING_FOR_ALL_YEARS_DATA = "[energy-publicSector] GET_CO2_SAVING_FOR_ALL_YEARS_DATA",
  GET_CO2_SAVING_FOR_ALL_YEARS_DATA_SUCCESS = "[energy-publicSector] GET_CO2_SAVING_FOR_ALL_YEARS_DATA_SUCCESS",
  GET_CO2_SAVING_FOR_ALL_YEARS_DATA_ERROR = "[energy-publicSector] GET_CO2_SAVING_FOR_ALL_YEARS_DATA_ERROR"
}

export class GetCo2SavingForAllYearsData implements Action {
  public readonly type = Co2SavingForAllYearsActionType.GET_CO2_SAVING_FOR_ALL_YEARS_DATA;

  constructor(public regionId: string, public scenarioId: string) {}
}

export class GetCo2SavingForAllYearsDataSuccess implements Action {
  public readonly type = Co2SavingForAllYearsActionType.GET_CO2_SAVING_FOR_ALL_YEARS_DATA_SUCCESS;

  constructor(public data: ICo2SavingForAllYearsData[]) {}
}

export class GetCo2SavingForAllYearsDataError implements Action {
  public readonly type = Co2SavingForAllYearsActionType.GET_CO2_SAVING_FOR_ALL_YEARS_DATA_ERROR;

  constructor(public error: string) {}
}

export type Co2SavingForAllYearsAction =
  | GetCo2SavingForAllYearsData
  | GetCo2SavingForAllYearsDataSuccess
  | GetCo2SavingForAllYearsDataError;
