import { Injectable } from "@angular/core";
import { EnergyType } from "../../../../../models/charts.models";
import { RegionPropertyEnergyService } from "../../../../../services/region-property/region-property-energy.service";
import { IRegionIdentifier, UtilService } from "../../../../../services/util.service";

import { omit } from "lodash";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { EnergyTypes, MixType } from "../../../../../configs/accounting-method";
import { IDataItems } from "../../models/final-energy-data.interface";
import { energySectors, energyTypeMap } from "../final-energy.model";
import { FinalEnergyService } from "../final-energy.service";

@Injectable()
export class EnergyCarrierService {
  constructor(
    private utilService: UtilService,
    private finalEnergyService: FinalEnergyService,
    private regionPropertyEnergyService: RegionPropertyEnergyService
  ) {}
  public getEnergyData(
    regionIdentifier: IRegionIdentifier,
    energyType: EnergyType,
    reportYear: number,
    weatherCorrection: boolean,
    localMix: MixType,
  ): Observable<{ [key: string]: IDataItems }> {
    const sectors = energySectors.map((sector) => ({
      ...sector,
      weatherCorrection,
      energyType,
      localMix: Boolean(localMix)
    }));

    return this.regionPropertyEnergyService
      .getEnergySectorData(regionIdentifier, sectors, reportYear || this.utilService.getSelectedYear())
      .pipe(
        map((energy) =>
          energy.reduce((acc, energySector) => {
            if (energySector.sector.type === EnergyTypes.STATIONARY_ENERGY) {
              return { ...acc, ...omit(energySector.result[0], "year") };
            } else {
              return {
                ...acc,
                transportation: this.finalEnergyService.joinGrouped(
                  this.finalEnergyService.groupEnergyTypes(
                    this.finalEnergyService.pluckToLevelWithKey(energySector.result[0], "value"),
                    energyTypeMap
                  )
                )
              };
            }
          }, {})
        )
      );
  }

  public getDqi(
    year: number,
    weatherCorrection: boolean,
    localMix: MixType,
    energyType: EnergyType,
    energyValues?: { [key: string]: IDataItems }
  ): Observable<number> {
    if (energyType === EnergyType.FINAL && energyValues) {
      return of(this.finalEnergyService.calculateDQI(energyValues as any));
    }

    return this.getEnergyData(
      this.utilService.getRegionIdentifier(),
      EnergyType.FINAL,
      year,
      weatherCorrection,
      localMix
    ).pipe(map((energyValuesFinal) => this.finalEnergyService.calculateDQI(energyValuesFinal as any)));
  }
}
