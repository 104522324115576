<app-page-wrapper [title]="config?.title | translate" [chartTitle]="config?.chartTitle | translate: { year: year }">
  <div class="avoid-break-inside">
    <ng-container *ngComponentOutlet="component; injector: chartInjector"></ng-container>
    <section>
      <div class="textbox" [innerHTML]="config?.text | translate"></div>
    </section>
  </div>

  <div class="avoid-break-inside">
    <h2 class="table-title">{{ "PRINT_VIEW.TABLE.TITLE" | translate }}</h2>
    <div class="table-subtitle">{{ config?.tableFooter | translate: { year: year } }}</div>
    <table cdk-table [dataSource]="data$ | async">
      <ng-container *ngFor="let column of displayedColumns" [cdkColumnDef]="column">
        <th cdk-header-cell *cdkHeaderCellDef>
          {{ column | nameById: columns }}
        </th>
        <td cdk-cell *cdkCellDef="let element" [class.numeric]="column !== tableColumns.NAME">
          <span *ngIf="column === tableColumns.NAME; else numericCell">
            {{ "RESOURCES." + element.name | uppercase | translate }}
          </span>
          <ng-template #numericCell>
            <span *ngIf="!!element[column] || element[column] === 0; else emptyCell">
              {{ element[column] | number: numberFormat: locale }}
            </span>
          </ng-template>
          <ng-template #emptyCell>&ndash;</ng-template>
        </td>
      </ng-container>
      <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
      <tr cdk-row *cdkRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</app-page-wrapper>
