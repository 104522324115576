<div class="person">
  <div class="head">
    <b>{{ "APP.CONTACTS" | translate }}</b>
    <p>{{ person?.name }}, <span [innerHTML]="person?.title"></span></p>
  </div>
  <div class="contacts">
    <p>{{ "APP.PHONE" | translate }}: {{ person?.phone }}</p>
    <p>
      {{ "APP.EMAIL" | translate }}:
      <a class="mailto" href="mailto:{{ person?.email }}" target="_blank">{{ person?.email }}</a>
    </p>
    <p *ngIf="person?.address">{{ person.address }}</p>
  </div>
</div>
