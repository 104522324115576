<div [popper]="Help" [popperTrigger]="'click'" [popperPlacement]="'top'" [popperTimeout]="200">
  <ene-icon icon="ec_header_help"></ene-icon>
</div>
<popper-content #Help>
  <div class="group" *ngIf="help.glossary && help.glossary.sections && help.glossary.sections.length">
    <section class="header clickable" (click)="openGlossary()">
      <ene-icon *ngIf="help.glossary.icon" [icon]="help.glossary.icon"></ene-icon>
      <span class="title__main">{{ help.glossary.title | translate }}</span>
    </section>
    <section class="sub-section clickable" *ngFor="let section of help.glossary.sections; let i = index">
      <span *ngIf="section.title" (click)="openGlossary(i)">{{ section.title | translate }}</span>
    </section>
  </div>
  <div class="group" *ngIf="help.businessTerms">
    <section class="header clickable" (click)="openContent(help.businessTerms.title, help.businessTerms.sections)">
      <ene-icon *ngIf="help.businessTerms.icon" [icon]="help.businessTerms.icon"></ene-icon>
      <span class="title__main">{{ help.businessTerms.title | translate }}</span>
    </section>
  </div>
</popper-content>

<ene-modal id="help_information" sizing="oversize" [title]="help_title | translate" [disableScroll]="true">
  <hr *ngIf="help_tabs.length > 1" />
  <ene-tabs *ngIf="help_tabs.length > 1" (currentTabChange)="changeTab($event.tabName)" class="pill">
    <ene-tab
      *ngFor="let tab of help_tabs; let i = index"
      [tabTitle]="tab.title | translate"
      [tabName]="i"
      [active]="i === help_activeTab"
    >
    </ene-tab>
  </ene-tabs>
  <hr />
  <div
    #helpContent
    *ngIf="help_activeTab >= 0 && help_tabs[help_activeTab]"
    (click)="goToAnchor($event)"
    class="html-content"
    [innerHTML]="help_tabs[help_activeTab].content | translate | trustHtml"
  ></div>
</ene-modal>
