<ng-content></ng-content>
<img [src]="url" [title]="title" [alt]="title" aria-hidden="true" *ngIf="url" />
<i [ngClass]="iconClass"></i>
<i *ngIf="dropdown" class="dropdown icon icon-dropdown"></i>
<div
  class="notification"
  *ngIf="notifications > 0"
  [ngClass]="{ animate: animateNotification, init: animateInit }"
  (animationend)="notificationAnimationComplete($event)"
>
  {{ notifications }}
</div>
