import { Action } from "@ngrx/store";
import { IParameterModel } from "../../../../models/accounting-method";

export enum PutAccountingMethodsConfigActionType {
  PUT_ACCOUNTING_METHODS_CONFIG = "[config] PUT_ACCOUNTING_METHODS_CONFIG",
  PUT_ACCOUNTING_METHODS_CONFIG_SUCCESS = "[config] PUT_ACCOUNTING_METHODS_CONFIG_SUCCESS",
  PUT_ACCOUNTING_METHODS_CONFIG_ERROR = "[config] PUT_ACCOUNTING_METHODS_CONFIG_ERROR"
}

export class PutAccountingMethodsConfig implements Action {
  public readonly type: PutAccountingMethodsConfigActionType =
    PutAccountingMethodsConfigActionType.PUT_ACCOUNTING_METHODS_CONFIG;
  constructor(public regionId: string, public model: IParameterModel) {}
}

export class PutAccountingMethodsConfigSuccess implements Action {
  public readonly type: PutAccountingMethodsConfigActionType =
    PutAccountingMethodsConfigActionType.PUT_ACCOUNTING_METHODS_CONFIG_SUCCESS;
  constructor(public response: string) {}
}

export class PutAccountingMethodsConfigError implements Action {
  public readonly type: PutAccountingMethodsConfigActionType =
    PutAccountingMethodsConfigActionType.PUT_ACCOUNTING_METHODS_CONFIG_ERROR;
  constructor(public error: string) {}
}

export type PutAccountingMethodsConfigAction =
  | PutAccountingMethodsConfig
  | PutAccountingMethodsConfigSuccess
  | PutAccountingMethodsConfigError;
