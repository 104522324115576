import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedModule } from "@energy-city/components";
import { EneIconModule } from "@energy-city/ui/icon";
import { TranslateModule } from "@ngx-translate/core";
import { CsvTransformerService } from "../data-input/csv-transformer.service";
import { FactorManagementComponent } from "./factor-management.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { NotSavedDialogComponent } from "./not-saved-dialog/not-saved-dialog.component";
import { TableModule } from "./table/table.module";

@NgModule({
  declarations: [FactorManagementComponent, NavigationComponent, NotSavedDialogComponent],
  providers: [CsvTransformerService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    TranslateModule,
    TableModule,
    SharedModule,
    EneIconModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatDialogModule
  ],
  exports: [FactorManagementComponent, NavigationComponent]
})
export class FactorManagementModule {}
