/* tslint:disable:member-access */

import { Action } from "@ngrx/store";
import { INetworkDatachangeAction } from "../../core/interfaces/network.interface";
import { INetworkMapRequest } from "../../core/interfaces/map.interface";

export enum NetworkActionType {
  // utility
  UPDATE_UTILITY = "[Network] UPDATE_UTILITY",
  UPDATE_UTILITY_SUCCESS = "[Network] UPDATE_UTILITY_SUCCESS",

  // network
  GET_NETWORK = "[Network] GET_NETWORK_DATA",
  GET_NETWORK_SUCCESS = "[Network] GET_NETWORK_DATA_SUCCESS",
  UPDATE_NETWORK = "[Network] UPDATE_NETWORK",
  UPDATE_NETWORK_SUCCESS = "[Network] UPDATE_NETWORK_SUCCESS",

  // mix
  CREATE_MIX = "[Network] CREATE_ENERGY_MIX",
  CREATE_MIX_SUCCESS = "[Network] CREATE_ENERGY_MIX_SUCCESS",
  UPDATE_MIX = "[Network] UPDATE_ENERGY_MIX",
  UPDATE_MIX_SUCCESS = "[Network] UPDATE_ENERGY_MIX_SUCCESS",
  DELETE_MIX = "[Network] DELETE_ENERGY_MIX",
  DELETE_MIX_SUCCESS = "[Network] DELETE_ENERGY_MIX_SUCCESS",

  // mix_component
  UPDATE_COMPONENT = "[Network] UPDATE_ENERGY_MIX_COMPONENT",
  UPDATE_COMPONENT_SUCCESS = "[Network] UPDATE_ENERGY_MIX_COMPONENT_SUCCESS",
  CREATE_COMPONENT = "[Network] CREATE_ENERGY_MIX_COMPONENT",
  CREATE_COMPONENT_SUCCESS = "[Network] CREATE_ENERGY_MIX_COMPONENT_SUCCESS",
  DELETE_COMPONENT = "[Network] DELETE_ENERGY_MIX_COMPONENT",
  DELETE_COMPONENT_SUCCESS = "[Network] DELETE_ENERGY_MIX_COMPONENT_SUCCESS",

  // emission_factor
  GET_EMISSION_FACTOR = "[Network] GET_EMISSION_FACTORS",
  GET_EMISSION_FACTOR_SUCCESS = "[Network] GET_EMISSION_FACTORS_SUCCESS",

  GET_INVEST_CHART_DATA = "[Network] GET_INVEST_CHART_DATA",
  GET_INVEST_CHART_DATA_SUCCESS = "[Network] GET_INVEST_CHART_DATA_SUCCESS",

  // segments
  GET_NETWORK_DATA_BY_TILE = "[Network] GET_NETWORK_DATA_BY_TILE",
  GET_NETWORK_DATA_BY_TILE_SUCCESS = "[Network] GET_NETWORK_DATA_BY_TILE_SUCCESS",

  // stations
  GET_STATION_INFORMATION = "[Network] GET_STATION_INFORMATION",
  GET_STATION_INFORMATION_SUCCESS = "[Network] GET_STATION_INFORMATION_SUCCESS",
  GET_ALL_STATIONS = "[Network] GET_ALL_STATIONS",
  GET_ALL_STATIONS_SUCCESS = "[Network] GET_ALL_STATIONS_SUCCESS",

  // invest
  GET_CUMULATIVE_INVESTS = "[Network] GET_CUMULATIVE_INVESTS",
  GET_CUMULATIVE_INVESTS_SUCCESS = "[Network] GET_CUMULATIVE_INVESTS_SUCCESS",

  // map
  GET_BUILDINGS_DATA_BY_TILE = "[Building] GET_BUILDINGS_DATA_BY_TILE",
  GET_BUILDINGS_DATA_BY_TILE_SUCCESS = "[Building] GET_BUILDINGS_DATA_BY_TILE_SUCCESS",
  GET_BUILDINGS_RASTERS_BY_TILE = "[Building] GET_BUILDINGS_RASTERS_BY_TILE",
  GET_BUILDINGS_RASTERS_BY_TILE_SUCCESS = "[Building] GET_BUILDINGS_RASTERS_BY_TILE_SUCCESS",

  ERROR = "[Network] ERROR",

  // GAslänge

  GET_NETWORK_ID = "[Network] GET_NETWORK_ID",
  GET_NETWORK_ID_SUCCESS = "[Network] GET_NETWORK_ID_SUCCESS",
  GET_GAS_NETWORK_LENGTH = "[Network] GET_GAS_NETWORK_LENGTH",
  GET_GAS_NETWORK_LENGTH_SUCCESS = "[Network] GET_GAS_NETWORK_LENGTH_SUCCESS",
  GET_GAS_COCKPIT_KPI = "[Cockpit] GET_GAS_COCKPIT_KPI",
  GET_GAS_COCKPIT_KPI_SUCCESS = "[Cockpit] GET_GAS_COCKPIT_KPI_SUCCESS",
  GET_GAS_COCKPIT_VALUES_FOR_ALL_YEARS = "[Cockpit] GET_GAS_COCKPIT_VALUES_FOR_ALL_YEARS",
  GET_GAS_COCKPIT_VALUES_FOR_ALL_YEARS_SUCCESS = "[Cockpit] GET_GAS_COCKPIT_VALUES_FOR_ALL_YEARS_SUCCESS"
}

// Utility
export class UpdateUtility implements Action {
  public readonly type = NetworkActionType.UPDATE_UTILITY;
  constructor(public payload: INetworkDatachangeAction) {}
}
export class UpdateUtilitySuccess implements Action {
  public readonly type = NetworkActionType.UPDATE_UTILITY_SUCCESS;
  constructor(public payload: any) {}
}

// Network
export class UpdateNetwork implements Action {
  public readonly type = NetworkActionType.UPDATE_NETWORK;
  constructor(public payload: INetworkDatachangeAction) {}
}
export class UpdateNetworkSuccess implements Action {
  public readonly type = NetworkActionType.UPDATE_NETWORK_SUCCESS;
  constructor(public payload: any) {}
}
export class GetNetwork implements Action {
  public readonly type = NetworkActionType.GET_NETWORK;
  constructor(public payload: { network_type: any }) {}
}
export class GetNetworkSuccess implements Action {
  public readonly type = NetworkActionType.GET_NETWORK_SUCCESS;
  constructor(public payload: any) {}
}

// mixes
export class UpdateMix implements Action {
  public readonly type = NetworkActionType.UPDATE_MIX;
  constructor(public payload: INetworkDatachangeAction) {}
}
export class UpdateMixSuccess implements Action {
  public readonly type = NetworkActionType.UPDATE_MIX_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateMix implements Action {
  public readonly type = NetworkActionType.CREATE_MIX;
  constructor(public payload: INetworkDatachangeAction) {}
}
export class CreateMixSuccess implements Action {
  public readonly type = NetworkActionType.CREATE_MIX_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteMix implements Action {
  public readonly type = NetworkActionType.DELETE_MIX;
  constructor(public payload: INetworkDatachangeAction) {}
}
export class DeleteMixSuccess implements Action {
  public readonly type = NetworkActionType.DELETE_MIX_SUCCESS;
  constructor(public payload: any) {}
}

// mix_component
export class UpdateComponent implements Action {
  public readonly type = NetworkActionType.UPDATE_COMPONENT;
  constructor(public payload: INetworkDatachangeAction) {}
}
export class UpdateComponentSuccess implements Action {
  public readonly type = NetworkActionType.UPDATE_COMPONENT_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateComponent implements Action {
  public readonly type = NetworkActionType.CREATE_COMPONENT;
  constructor(public payload: INetworkDatachangeAction) {}
}
export class CreateComponentSuccess implements Action {
  public readonly type = NetworkActionType.CREATE_COMPONENT_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteComponent implements Action {
  public readonly type = NetworkActionType.DELETE_COMPONENT;
  constructor(public payload: INetworkDatachangeAction) {}
}
export class DeleteComponentSuccess implements Action {
  public readonly type = NetworkActionType.DELETE_COMPONENT_SUCCESS;
  constructor(public payload: any) {}
}

// emission factor
export class GetNetworkEmissionFactor implements Action {
  public readonly type = NetworkActionType.GET_EMISSION_FACTOR;
}
export class GetNetworkEmissionFactorSuccess implements Action {
  public readonly type = NetworkActionType.GET_EMISSION_FACTOR_SUCCESS;
  constructor(public payload: any) {}
}

export class GetNetworkInvestChartData implements Action {
  type = NetworkActionType.GET_INVEST_CHART_DATA;
  payload = null;
  constructor() {}
}
export class GetNetworkInvestChartDataSuccess implements Action {
  type = NetworkActionType.GET_INVEST_CHART_DATA_SUCCESS;
  constructor(public payload: any) {}
}

// segments
export class GetNetworkDataByTile implements Action {
  type = NetworkActionType.GET_NETWORK_DATA_BY_TILE;
  constructor(public payload: INetworkMapRequest) {}
}
export class GetNetworkDataByTileSuccess implements Action {
  type = NetworkActionType.GET_NETWORK_DATA_BY_TILE_SUCCESS;
  constructor(public payload: any) {}
}

// stations

export class GetNetworkStationInformation implements Action {
  type = NetworkActionType.GET_STATION_INFORMATION;
  public payload;
  constructor(
    public ags: string,
    public level: number,
    public year: number,
    public scenario: number,
    public segment_type: number,
    public station_id: string
  ) {}
}
export class GetNetworkStationInformationSuccess implements Action {
  type = NetworkActionType.GET_STATION_INFORMATION_SUCCESS;
  constructor(public payload: any) {}
}

export class GetNetworkAllStations implements Action {
  type = NetworkActionType.GET_ALL_STATIONS;
  public payload;
  constructor(
    public ags: string,
    public level: number,
    public year: number,
    public scenario: number,
    public segment_type: number
  ) {}
}
export class GetNetworkAllStationsSuccess implements Action {
  type = NetworkActionType.GET_ALL_STATIONS_SUCCESS;
  constructor(public payload: any) {}
}

// invest
export class GetCumulativeInvests implements Action {
  type = NetworkActionType.GET_CUMULATIVE_INVESTS;
  constructor(public payload: any, public ags: number, public year_min: number, public year_max: number) {}
}

export class GetCumulativeInvestsSuccess implements Action {
  type = NetworkActionType.GET_CUMULATIVE_INVESTS_SUCCESS;
  constructor(public payload: any) {}
}

// general
export class NetworkError implements Action {
  readonly type = NetworkActionType.ERROR;
  constructor(public payload: any) {}
}

export class GetNetworkId implements Action {
  type = NetworkActionType.GET_NETWORK_ID;
  constructor(public payload: string) {}
}

export class GetNetworkIdSuccess implements Action {
  type = NetworkActionType.GET_NETWORK_ID_SUCCESS;
  constructor(public payload: any) {}
}

export class GetGasNetworkLength implements Action {
  type = NetworkActionType.GET_GAS_NETWORK_LENGTH;
  constructor(public payload: string[]) {}
}

export class GetGasNetworkLengthSuccess implements Action {
  type = NetworkActionType.GET_GAS_NETWORK_LENGTH_SUCCESS;
  constructor(public payload: any) {}
}

export class GetGasCockpitKpi implements Action {
  type = NetworkActionType.GET_GAS_COCKPIT_KPI;
  constructor(public payload: any, public year: number) {}
}

export class GetGasCockpitKpiSuccess implements Action {
  type = NetworkActionType.GET_GAS_COCKPIT_KPI_SUCCESS;
  constructor(public payload: any) {}
}

export class GetGasCockpitValuesForAllYears implements Action {
  type = NetworkActionType.GET_GAS_COCKPIT_VALUES_FOR_ALL_YEARS;
  constructor(public payload: any, public timerange: number[]) {}
}

export class GetGasCockpitValuesForAllYearsSuccess implements Action {
  type = NetworkActionType.GET_GAS_COCKPIT_VALUES_FOR_ALL_YEARS_SUCCESS;
  constructor(public payload: any) {}
}

export type NetworkActions =
  | UpdateUtility
  | UpdateUtilitySuccess
  | UpdateMix
  | UpdateMixSuccess
  | CreateMix
  | CreateMixSuccess
  | DeleteMix
  | DeleteMixSuccess
  | UpdateComponent
  | UpdateComponentSuccess
  | CreateComponent
  | CreateComponentSuccess
  | DeleteComponent
  | DeleteComponentSuccess
  | GetNetwork
  | GetNetworkSuccess
  | UpdateNetwork
  | UpdateNetworkSuccess
  | GetNetworkEmissionFactor
  | GetNetworkEmissionFactorSuccess
  | GetNetworkInvestChartData
  | GetNetworkInvestChartDataSuccess
  | GetNetworkDataByTile
  | GetNetworkDataByTileSuccess
  | GetNetworkStationInformation
  | GetNetworkStationInformationSuccess
  | GetNetworkAllStations
  | GetNetworkAllStationsSuccess
  | GetCumulativeInvests
  | GetCumulativeInvestsSuccess
  | GetNetworkId
  | GetNetworkIdSuccess
  | GetGasNetworkLength
  | GetGasNetworkLengthSuccess
  | GetGasCockpitKpi
  | GetGasCockpitKpiSuccess
  | GetGasCockpitValuesForAllYears
  | GetGasCockpitValuesForAllYearsSuccess
  | NetworkError;
