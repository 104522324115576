<div class="navigation__categories">
  <app-radio-group *ngIf="categories && categories.length" name="category" (change)="onChangeCategory($event)">
    <app-radio-button
      *ngFor="let category of categories; trackBy: trackByFn"
      [value]="category.id"
      [checked]="category.id === initialCategory"
    >
      <ene-icon [icon]="category.icon"></ene-icon>
      <span>{{ category.name | translate }}</span>
    </app-radio-button>
  </app-radio-group>
</div>
<div class="navigation__nested-categories">
  <app-radio-group
    *ngIf="subCategories && subCategories.length"
    name="subCategory"
    (change)="onChangeNestedCategory($event)"
  >
    <app-radio-button
      *ngFor="let category of subCategories; trackBy: trackByFn"
      [value]="category.id"
      [checked]="category.id === initialSubCategory"
    >
      <span>{{ category.name | translate }}</span>
    </app-radio-button>
  </app-radio-group>

  <app-toggle-button
    class="lock-button"
    [disabled]="editable$ | async"
    [checked]="this.isLockButtonChecked$ | async"
    [icons]="lockIcons"
    (change)="onLock($event)"
  ></app-toggle-button>

  <button class="button" [disabled]="!(factorManagementService.tableDataChanges$ | async)" (click)="onSave()">
    <ene-icon icon="ec_save"></ene-icon>
  </button>
</div>
