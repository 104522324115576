<ng-container *ngIf="table">
  <table class="factors" cdk-table [dataSource]="table.rows">
    <!-- Key Column -->
    <ng-container [cdkColumnDef]="displayedColumns[0]">
      <th *cdkHeaderCellDef>{{ table.keyHeader | translate }}</th>
      <td *cdkCellDef="let element" [class.header]="isHeader(element)" [ngClass]="getLevelClass(element)">
        {{ element.description | translate }}
      </td>
    </ng-container>
    <!-- Value Column -->
    <ng-container [cdkColumnDef]="displayedColumns[1]">
      <th *cdkHeaderCellDef>
        {{ table.valueHeader | translate }}
        <ng-container *ngIf="hasUnit(table)">[{{ table.unit | translate }}]</ng-container>
      </th>
      <td *cdkCellDef="let element">
        <ng-container *ngIf="hasValue(element)">{{ element.value | number: "1.2-2":currentLang }}</ng-container>
        <ng-container *ngIf="hasPercentage(element)"
          >{{ element.percentage | number: "1.2-2":currentLang }}%</ng-container
        >
        <ng-container *ngIf="element.hasFootnote">{{ "*".repeat(element.footnoteIndex + 1) }}</ng-container>
      </td>
    </ng-container>
    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <ng-template ngFor let-note [ngForOf]="table.footnotes" let-i="index">
    <!-- first star is port of the translation -->
    <div class="note">{{ "*".repeat(i) }}{{ note | translate }}</div>
  </ng-template>
</ng-container>
