export class TokenFormatter {
  /**
   * accepts a decoded token and changes properties so the rest of the application can stay same
   * The changes are only made, if the token is for the tenant Hansewerk
   * @param token decoded token to be "formatted"
   */
  public static format(token: any): any {
    if (token.hasOwnProperty("tenant")) {
      token.clientId = token.tenant;
      token.logo_footer = token.logo?.footer || "blank.gif";
      token.logo_main = token.logo?.main || "Klimanavi-Logo.svg";
      token.timelineRange = [token.timelineFrom, token.timelineTo];
      token.customer_support_id = token.customerSupportId;
      // For some realms parsedToken.municipality_name is an Array (containing all groups the user is in)
      token.municipality_name = [token.mainRegion.name];
      //  token.map_config
      //  token.level
      //  token.ags
      //  token.accessIdAgs
    }
    return token;
  }
}
