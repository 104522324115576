import { BenchmarkActionTypes, BenchmarkActions } from "./benchmark-actions";

export interface IBenchmarkState {
  qualitiveChartData: {
    custom: any;
    average: any;
  };
  quantitiveChartData: any;
}

const initialState: IBenchmarkState = {
  qualitiveChartData: null,
  quantitiveChartData: null
};

export function reducer(state = initialState, action: BenchmarkActionTypes): IBenchmarkState {
  switch (action.type) {
    case BenchmarkActions.GET_QUALITIVE_DATA_SUCCESS:
      return {
        ...state,
        qualitiveChartData: action.payload.data
      };
    case BenchmarkActions.GET_QUANTITIVE_DATA_SUCCESS:
      return {
        ...state,
        quantitiveChartData: action.payload.data
      };
    default:
      return state;
  }
}
