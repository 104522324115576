import { ActionReducerMap } from "@ngrx/store/src/models";
import { IApplicationState } from "../";

import { reducer } from "./co2-cockpit-reducer";

export const co2Reducers: ActionReducerMap<any> = {
  co2: reducer
};

export interface ICo2 {
  emission_absolute: any;
  reduction_absolute: any;
}

export const getCo2 = (state: IApplicationState) => state && state.app && state.app.co2;
