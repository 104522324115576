import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneFooterModule } from "@energy-city/ui/footer";
import { FooterComponent } from "./footer.component";
import { FooterService } from "./footer.service";
import { DefaultFooterComponent } from "./components";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [FooterComponent, DefaultFooterComponent],
  entryComponents: [DefaultFooterComponent],
  providers: [FooterService],
  imports: [CommonModule, RouterModule, TranslateModule.forChild(), EneFooterModule],
  exports: [FooterComponent]
})
export class FooterModule {}
