import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { EneFormsModule } from "@energy-city/ui/forms";
import { SharedModule } from "@energy-city/components";
import { TableCellComponent } from "./cell.component";
import { TableCellService } from "./cell.service";
import { EnePopperModule } from "@energy-city/ui/popper";
import { LocaleConversionsModule } from "@energy-city/locale-conversions";

@NgModule({
  declarations: [TableCellComponent],
  providers: [TableCellService],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    EneFormsModule,
    EnePopperModule,
    LocaleConversionsModule
  ],
  exports: [TableCellComponent]
})
export class TableCellModule {}
