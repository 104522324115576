import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// shared modules
import { DqiViewModule } from "./dqi-view/dqi-view.module";
import { RadioModule } from "./radio/radio.module";
import { ToggleButtonModule } from "./toggle-button/toggle-button.module";

@NgModule({
  imports: [CommonModule, DqiViewModule, RadioModule, ToggleButtonModule],
  exports: [CommonModule, DqiViewModule, RadioModule, ToggleButtonModule]
})
export class SharedModule {}
