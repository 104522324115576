import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimelineComponent } from "./timeline.component";
import { DeprecatedEneRangeSliderModule } from "libs/ui/src/lib/forms";

@NgModule({
  declarations: [TimelineComponent],
  imports: [CommonModule, DeprecatedEneRangeSliderModule],
  exports: [TimelineComponent]
})
export class DeprecatedTimelineModule {}
