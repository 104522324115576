export enum CO2SavingType {
  SECTOR = "global",
  STATIONARY_ENERGY = "stationaryEnergy",
  TRAFFIC = "transportation",
  AGRICULTURE = "afolu",
  INDUSTRY = "ippu",
  WASTE_AND_SEWAGE = "waste"
}
export const colors = {
  stationaryEnergy: "#5D2F57",
  global: "#096AB1",
  transportation: "#028697",
  afolu: "#8AAE1C",
  ippu: "#4A4A4A",
  waste: "#EC7E31"
};
export enum ImplementationStatusType {
  STEP_0 = "0",
  STEP_1 = "1",
  STEP_2 = "2",
  STEP_3 = "3"
}
// {"waste":"Landwirtschaft","ippu":"Industrie","transportation":"Verkehr","global":"Sektorenübergreifend","afolu":"Abfall und Abwasser","stationaryEnergy":"Stationäre Energie"}
export const translateMap = {
  global: "MEASURE.SECTORS.global",
  ippu: "MEASURE.SECTORS.ippu",
  afolu: "MEASURE.SECTORS.afolu",
  waste: "MEASURE.SECTORS.waste",
  transportation: "MEASURE.SECTORS.transportation",
  stationaryEnergy: "MEASURE.SECTORS.stationary_energy"
};

export enum MODES {
  READ,
  EDIT,
  CREATE
}

export const IMPLEMENTATION_PERIOD_END = 2050;
export const IMPLEMENTATION_PERIOD_START = 2000;

export const SAVE_MEASURE_SUCCESS = "Maßnahme gespeichert";
export const SAVE_MEASURES_SET_SUCCESS = "Set gespeichert";
export const SAVE_MEASURES_SET_ERROR = "Fehler";
export const SAVE_MEASURE_ERROR = "Fehler";
export const DELETE_MEASURES_SET_SUCCESS = "Set gelöscht";
export const DELETE_MEASURES_SET_ERROR = "Fehler";
