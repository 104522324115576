import { Action } from "@ngrx/store";
import { ICo2SavingData } from "../common";

export enum Co2SavingActionType {
  GET_CO2_SAVING_DATA = "[energy-publicSector] GET_CO2_SAVING_DATA",
  GET_CO2_SAVING_DATA_SUCCESS = "[energy-publicSector] GET_CO2_SAVING_DATA_SUCCESS",
  GET_CO2_SAVING_DATA_ERROR = "[energy-publicSector] GET_CO2_SAVING_DATA_ERROR"
}

export class GetCo2SavingData implements Action {
  public readonly type = Co2SavingActionType.GET_CO2_SAVING_DATA;

  constructor(public regionId: string, public year: number, public scenarioId: string) {}
}

export class GetCo2SavingDataSuccess implements Action {
  public readonly type = Co2SavingActionType.GET_CO2_SAVING_DATA_SUCCESS;

  constructor(public data: ICo2SavingData) {}
}

export class GetCo2SavingDataError implements Action {
  public readonly type = Co2SavingActionType.GET_CO2_SAVING_DATA_ERROR;

  constructor(public error: string) {}
}

export type Co2SavingAction = GetCo2SavingData | GetCo2SavingDataSuccess | GetCo2SavingDataError;
