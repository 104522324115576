<div class="dashboard-container">
  <ul class="flex-container">
    <li class="flex-item" routerLink="/map">Map</li>
    <li class="flex-item" disabled="true">User Profile</li>
    <li class="flex-item" disabled="true">Settings</li>
    <li class="flex-item" disabled="true">Input Management</li>
    <li class="flex-item" disabled="true">Scenario Management</li>
    <li class="flex-item" disabled="true">Report Management</li>
    <li class="flex-item" disabled="true">About</li>
  </ul>
</div>
