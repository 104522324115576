import { Directive } from "@angular/core";

@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: "[ene-header-center]",
  exportAs: "eneHeaderCenterDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-header-center"
  }
})
export class EneHeaderCenterDirective {}
