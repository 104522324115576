import { Component, OnInit, Input } from "@angular/core";
import { AccountingMethodService } from "@energy-city/components";

@Component({
  selector: "app-status-bar",
  templateUrl: "./status-bar.component.html",
  styleUrls: ["./status-bar.component.scss"]
})
export class StatusBarComponent implements OnInit {
  @Input() public dqi: number = 0;
  @Input() public bisko: boolean;

  constructor(public accountingMethodService: AccountingMethodService) {}

  public ngOnInit() {}
}
