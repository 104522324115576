import { Component, Input, Output, EventEmitter, forwardRef } from "@angular/core";
import { EneEditComponentMode } from "../core/classes/abstract/EneEditComponentMode.abstract.class";

@Component({
  // tslint:disable-next-line
  selector: "ene-add-remove-button",
  templateUrl: "./addRemoveButton.component.html",
  providers: [
    {
      provide: EneEditComponentMode,
      useExisting: forwardRef(() => AddRemoveButtonComponent)
    }
  ]
})
export class AddRemoveButtonComponent<T> implements EneEditComponentMode<T> {
  @Input() public rowData: { isNew: boolean; data: any };
  @Output() public newRow: EventEmitter<any> = new EventEmitter();
  @Output() public deleteRow: EventEmitter<any> = new EventEmitter();

  public _editModeActive: boolean = false;

  public get editModeActive(): boolean {
    return this._editModeActive;
  }
  public set editModeActive(editModeActive: boolean) {
    this._editModeActive = editModeActive;
  }

  public onChange(event: any): void {
    try {
      if (this.rowData.isNew === false) {
        this.deleteRow.emit(this.rowData.data);
      } else if (this.rowData.isNew === true) {
        this.newRow.emit(this.rowData.data);
      } else {
        throw new Error("status not defined");
      }
    } catch (error) {
      // do smonething
    }
  }

  public getName() {
    if (this.rowData.isNew === false) {
      return "delete";
    } else if (this.rowData.isNew === true) {
      return "add";
    } else {
      throw new Error("status not defined");
    }
  }
}
