import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { switchMap, map, catchError, withLatestFrom, mergeMap } from "rxjs/operators";
import {
  DatabaseChartActionTypes,
  GetDatabaseChartDataSuccess,
  GetSpecificEmissionFactor,
  GetSpecificEmissionFactorSuccess,
  GetSpecificEmissionFactorError,
  GetDatabaseChartData
} from "./database-chart.actions";

import { environment } from "../../../../../environments/environment";
import { forkJoin, of } from "rxjs";
import { EnergyType } from "../../../../models/charts.models";
import { ChartTypes, FactorResponse } from "../../../../models/charts.model";
import { AccountingMethodService } from "../../../../services/accounting-method.service";

@Injectable()
export class DatabaseChartEffects {
  @Effect() public DatabaseChartData$ = this.actions$.pipe(
    ofType(DatabaseChartActionTypes.GET_DATA),
    switchMap(({ regionIdentifier }: GetDatabaseChartData) => {
      return this.getChartData(regionIdentifier).pipe(map((data) => new GetDatabaseChartDataSuccess(data)));
    })
  );

  @Effect() public getSpecificEmissionFactor$ = this.actions$.pipe(
    ofType<GetSpecificEmissionFactor>(DatabaseChartActionTypes.GET_KPI),
    withLatestFrom(this.accountingMethodService.useUserFactors$),
    mergeMap(([action, useUserFactors]) => {
      return this.getFactor(action, useUserFactors).pipe(
        map(({ result }) => new GetSpecificEmissionFactorSuccess(action.regionIdentifier.regionId, result)),
        catchError(() => of(new GetSpecificEmissionFactorError(action.regionIdentifier.regionId)))
      );
    })
  );

  private getFactor({ regionIdentifier: { regionId, regionType }, chartType }, useUserFactors) {
    const params = new HttpParams({
      fromObject: {
        localMix: String(chartType === ChartTypes.LOCAL),
        regionId,
        regionType,
        energyType: EnergyType.FINAL,
        useUserFactors
      }
    });
    const url = `${environment.regionPropertyApi}/emissions/co2/stationary-energy/factors/specific`;

    return this.http.get<FactorResponse>(url, { params });
  }

  private getChartData({ regionId, regionType }) {
    const params = new HttpParams({
      fromObject: {
        regionId,
        regionType
      }
    });
    const urls = [
      `${environment.regionPropertyApi}/energy/production`,
      `${environment.regionPropertyApi}/energy/consumption`
    ];

    return forkJoin(
      urls.reduce((acc: any, url) => {
        acc.push(this.http.get(url, { params }));
        return acc;
      }, [])
    ).pipe(
      map(([data, consumption]) => {
        return { chartData: data["result"], consumption: consumption["result"] };
      })
    );
  }

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private accountingMethodService: AccountingMethodService
  ) {}
}
