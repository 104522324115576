/* tslint:disable:member-access */

import { Action } from "@ngrx/store";

export enum TourActionType {
  GET_TOURS = "[Tour] GET_TOURS",
  GET_TOURS_SUCCESS = "[Tour] GET_TOURS_SUCCESS",
  HELP_TAB_CHANGE = "[Tour] HELP_TAB_CHANGE",
  ERROR = "[Tour] ERROR"
}

export class GetTour implements Action {
  type = TourActionType.GET_TOURS;
  payload = null;
  constructor(public tour: string, public ags: string, public tourTemplate: string, public clientId: string) {}
}

export class GetTourSuccess implements Action {
  type = TourActionType.GET_TOURS_SUCCESS;
  constructor(public payload: any) {}
}

export class HelpTabChange implements Action {
  type = TourActionType.HELP_TAB_CHANGE;
  constructor(public anchor: string) {}
}

export class TourError implements Action {
  type = TourActionType.ERROR;
  constructor(public payload: any) {}
}

export type TourActions = GetTour | GetTourSuccess | TourError;
