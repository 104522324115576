import { OnInit, Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { WeatherCorrection } from "@co2-shared/accounting-common";

@Component({
  selector: "app-weather-correction-indicator",
  templateUrl: "./weather-correction-indicator.component.html",
  styleUrls: ["./weather-correction-indicator.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeatherCorrectionIndicatorComponent implements OnInit {
  @Input() public weatherCorrection?: boolean | WeatherCorrection = false;
  constructor() {}

  public ngOnInit(): void {}
}
