import { InjectionToken } from "@angular/core";

export interface IApiEndpointService {
  assetUrl(url: string): string;
  getConfigApi(): string;
  getRegionPropertyApi(): string;
  getSimulationPropertyApi(): string;
}

export const API_ENDPOINT_SERVICE_TOKEN = new InjectionToken<IApiEndpointService>("API_ENDPOINT_SERVICE_TOKEN");
