<footer class="footer" ene-footer>
  <section ene-footer-left>
    <img [src]="logo" alt="" />
  </section>
  <section ene-footer-center (onclick)="onClick($event)">
    <ul>
      <li *ngFor="let item of links; trackBy: trackByFn">
        <ng-container [ngSwitch]="item.itemType">
          <ng-container *ngSwitchCase="'route'">
            <a [routerLink]="item.link">{{ item.name | translate }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'modal'">
            <a (click)="onClick(item)">{{ item.name | translate }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'website'">
            <a [href]="item.website" target="_blank">{{ item.name | translate }}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'link'">
            <a>{{ item.name | translate }}</a>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span>{{ item.name | translate }}</span>
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </section>
  <section ene-footer-right>
    powered by&nbsp;<a class="enersislink" target="_blank" [href]="copyrightLink">{{ companyNameFooter }}</a>
  </section>
</footer>
