import { environment } from "@energy-city/environments";
import { IOptions } from "@enersis/search/lib/core/interfaces/options.interace";

export class SearchboxOptions {
  private constructor() {}

  public static create(searchId: string, suggestId: string, callback: (value) => void): IOptions {
    return {
      contentStyle: "empty",
      serviceConfig: {
        API_KEY: environment.searchApiKey,
        maxResults: 5,
        autosuggest: {
          searchContainer: `#${searchId}`,
          suggestContainer: `#${suggestId}`,
          callback
        }
      }
    };
  }
}
