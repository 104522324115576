import { InjectionToken } from "@angular/core";
import { Region } from "@enersis/gp-components/interfaces";
import { Observable } from "rxjs";
import { IRegion, IRegionIdentifier } from "../model/region-identifier";

export const SELECTED_REGION_SERVICE_TOKEN = new InjectionToken<ISelectedRegionService>(
  "SELECTED_REGION_SERVICE_TOKEN"
);

export interface ISelectedRegionService {
  getSelectedRegion(): Observable<IRegion>;
  getSelectedRegionIdentifier(): Observable<IRegionIdentifier>;
  setSelectedRegion(regionIdentifier: IRegionIdentifier): void;
  getRegionChangedInHeader(): Observable<Region>;
}
