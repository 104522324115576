import { Action } from "@ngrx/store";
export enum ExportActionType {
  EXPORT_TO_CSV = "[Export] EXPORT_TO_CSV",
  EXPORT_TO_CSV_SUCCESS = "[Export] EXPORT_TO_CSV_SUCCESS",
  ERROR = "[Export] ERROR"
}

export class ExportToCsv implements Action {
  public type = ExportActionType.EXPORT_TO_CSV;
  constructor(public payload: any) {}
}
export class ExportToCsvSuccess implements Action {
  public type = ExportActionType.EXPORT_TO_CSV_SUCCESS;
  constructor(public payload: any) {}
}
export class ExportError implements Action {
  public type = ExportActionType.ERROR;
  constructor(public payload: any) {}
}

export type ExportActions = ExportToCsv | ExportToCsvSuccess | ExportError;
