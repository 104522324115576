import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";

import { combineLatest, Subject } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { filter, takeUntil } from "rxjs/operators";
import {
  DistrictActionType,
  AppActionType,
  GetAppState,
  GetDistrictState,
  IApplicationState
} from "../../../../state/index";

@Component({
  selector: "app-chart-energy-efficiency",
  templateUrl: "./chart-energy-efficiency.component.html",
  styleUrls: ["./chart-energy-efficiency.component.scss"]
})
export class ChartEnergyEfficiencyComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  // MockData
  public chartMockData: any = [
    {},
    {
      data: [
        { name: "A+", color: "#89D824", percent: 15, y: null },
        { name: "A", color: "#CAF81C", percent: 25, y: null },
        { name: "B", color: "#F8EA1C", percent: 25, y: null },
        { name: "C", color: "#F6AF26", percent: 23, y: null },
        { name: "D", color: "#FE4C00", percent: 5, y: null },
        { name: "E", color: "#CC0200", percent: 8, y: null },
        { name: "F", color: "#B00100", percent: 0, y: null },
        { name: "G", color: "#930002", percent: 0, y: null },
        { name: "H", color: "#770001", percent: 0, y: null }
      ]
    }
  ];

  public legendItems = [
    { id: "ap", name: "A+", width: "32%", color: "#89D824", y: null, tooltip: "<30 kWh/(m²a)" },
    { id: "a", name: "A", width: "40%", color: "#CAF81C", y: null, tooltip: "30 - <50 kWh/(m²a)" },
    { id: "b", name: "B", width: "48%", color: "#F8EA1C", y: null, tooltip: "50 - <75 kWh/(m²a)" },
    { id: "c", name: "C", width: "56%", color: "#F6AF26", y: null, tooltip: "75 - <100 kWh/(m²a)" },
    { id: "d", name: "D", width: "64%", color: "#FE4C00", y: null, tooltip: "100 - <130 kWh/(m²a)" },
    { id: "e", name: "E", width: "70%", color: "#CC0200", y: null, tooltip: "130 - <160 kWh/(m²a)" },
    { id: "f", name: "F", width: "78%", color: "#B00100", y: null, tooltip: "160 - <200 kWh/(m²a)" },
    { id: "g", name: "G", width: "86%", color: "#930002", y: null, tooltip: "200 - <250 kWh/(m²a)" },
    { id: "h", name: "H", width: "94%", color: "#770001", y: null, tooltip: ">250 kWh/(m²a)" }
  ];
  public legendWidthStart = 32;
  public legendWidthSize = 8;

  public chartData: any = [];
  public chartSettings: any = {
    legend: {
      enabled: false
    },
    chart: {
      height: "100%"
    },
    tooltip: {
      enabled: true,
      pointFormat: "",
      padding: 1,
      borderWidth: 1,
      shadow: false,
      useHTML: true
    }
  };

  public tooltipOptions = {
    theme: "light",
    "hide-delay": 0,
    "animation-duration": 0,
    shadow: false
  };

  constructor(
    private store: Store<IApplicationState>,
    public translate: TranslateService,
    public sanitizer: DomSanitizer // is needed to set css variables
  ) {}

  public ngOnInit() {
    this.store
      .select(GetAppState)
      .pipe(
        filter((res) => res.type === AppActionType.UPDATE_TRANSLATIONS_SUCCESS),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.updateChartTooptipTranslation();
      });

    this.store
      .select(GetDistrictState)
      .pipe(
        filter((res) => res.type === DistrictActionType.GET_DISTRICT_DATA_SUCCESS),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        this.updateMockData(res.payload.buildings_count);
      });
  }

  /**
   * calculate item values
   * @param buildingcount
   */
  private updateMockData(buildingcount: number) {
    if (!buildingcount) {
      this.chartData = [];
      return;
    }
    for (const entry of this.chartMockData[1].data) {
      entry.y = Math.round((buildingcount / 100) * entry.percent);
    }
    this.chartData = [...this.chartMockData]; // only a new object will trigger OnChangeEvent
  }

  private updateChartTooptipTranslation() {
    combineLatest(
      this.translate.get("COCKPIT.CHARTS.ENERGY_EFFECIENCY.CLASS"),
      this.translate.get("MAP2.LAYERS.BUILDINGS.LABEL")
    ).subscribe((res) => {
      this.chartSettings.tooltip.pointFormat = `<b>${res[0]} {point.name}</b><br>{point.y} ${res[1]} ({point.percent}%)`;
      this.chartSettings = { ...this.chartSettings }; // only a new object will trigger OnChangeEvent
    });
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
