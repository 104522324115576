import { ContactActionType, ContactActions } from "./contact-actions";

export function reducer(state = [], action: ContactActions) {
  switch (action.type) {
    case ContactActionType.SEND_EMAIL:
    case ContactActionType.SEND_EMAIL_SUCCESS:
    case ContactActionType.SEND_EMAIL_ERROR:
      return action;
    default:
      return state;
  }
}
