<div class="mainflex">
  <app-accounting-info-wrapper class="dqi" [dqi]="dqi" [config]="displayConfig || defaultDisplayConfig">
    <div style="margin-right: 1em; position: relative;" *ngIf="!hideExport">
      <gp-chart-export-menu [chartRef]="chart?.chart"></gp-chart-export-menu>
    </div>
  </app-accounting-info-wrapper>
  <div class="chart-container-override">
    <ene-chart type="sunburst" [data]="chartData" [settings]="chartConfig" [defaultColors]="false" #chart></ene-chart>
    <div class="sunburst-legend">
      <ul class="sunburst-legend-list">
        <li *ngIf="isLegendButtonEnabled()">
          <button
            class="ene-button-modal-primary"
            (click)="drillUp()"
            [ngStyle]="{ background: parentOfCurrentHierachyPoint.color }"
            [ngClass]="{ rootLevel: parentOfCurrentHierachyPoint.id === '0.0' }"
          >
            <ene-icon icon="ec_networks_carousel-arrow"></ene-icon>
            <span>{{ parentOfCurrentHierachyPoint.name }}</span>
          </button>
        </li>
        <li *ngFor="let item of customLegendItemList">
          <span class="hc-legend-box" [ngStyle]="{ backgroundColor: item.color }"></span>
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
