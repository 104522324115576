import { Component, ChangeDetectionStrategy } from "@angular/core";
import { MainPolluterChartComponent } from "../main-polluter.component";
import { MAIN_POLLUTER_APIS } from "../../../../../models/charts.models";

@Component({
  selector: "app-public-sector-main-polluter-chart",
  templateUrl: "../main-polluter.component.html",
  styleUrls: ["../main-polluter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicSectorMainPolluterChartComponent extends MainPolluterChartComponent {
  protected chartApi = MAIN_POLLUTER_APIS.PUBLIC_SECTOR;
}
