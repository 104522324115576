import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounce } from "lodash";
import { ITableCell } from "@energy-city/components";
import { TableCellService } from "./cell.service";
import { PopperDirective } from "@energy-city/ui/popper";
import { TranslateService } from "@ngx-translate/core";
import { ILocalNumberPipeOptions } from "@energy-city/locale-conversions";
import { takeUntil } from "rxjs/operators";

interface IPlausibility {
  value: boolean;
  quality: boolean;
}
@Component({
  selector: "app-table-cell",
  templateUrl: "./cell.component.html",
  styleUrls: ["./cell.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellComponent implements OnInit, OnDestroy {
  public quality: number;
  public originalQuality: number;
  public value: number;
  public originalValue: number;
  public locked$: Observable<boolean>;
  public changeValue = this._changeValue;
  public changeQuality = debounce(this._changeQuality, 500);
  public isPlausible: IPlausibility;
  public popper: {
    context?: keyof IPlausibility;
    infoText$?: Observable<string>;
  } = {};

  public numberFormatOptions: ILocalNumberPipeOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    smallerThanOneRules: {
      maximumSignificantDigits: 2,
      minimumSignificantDigits: 2
    },
    locale: null
  };

  private data: ITableCell;
  private destroy$ = new Subject<void>();

  @Input()
  public set element(data: ITableCell) {
    this.data = data;
    this.originalValue = this.value = data.value;
    this.originalQuality = this.quality = data.quality;
    this.isPlausible = { value: true, quality: true };
  }

  @ViewChild(PopperDirective, { static: false }) private popperDirective: PopperDirective;

  constructor(
    private cellService: TableCellService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.locked$ = this.cellService.getLockedState();
    this.numberFormatOptions = { ...this.numberFormatOptions, locale: this.translate.currentLang };
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.numberFormatOptions = { ...this.numberFormatOptions, locale: this.translate.currentLang };
      }
    );
  }

  public popperConfim(): void {
    this.popperDirective?.hide();
    this.setData(this.value, this.quality);
    this.isPlausible[this.popper.context] = true;
    this.originalQuality = this.quality;
    this.originalValue = this.value;
    this.cdr.detectChanges();
  }

  public popperRevert(): void {
    switch (this.popper.context) {
      case "value":
        this.value = this.originalValue;
        break;
      case "quality":
        this.quality = this.originalQuality;
        break;
    }
    // reverted back, now it's plausible again.
    this.isPlausible[this.popper.context] = true;
    this.popperDirective?.hide();
    this.cdr.detectChanges();
  }

  private _changeValue(value: number): void {
    this.value = value;
    if (value !== this.originalValue) {
      if (!this.cellService.validateValuePlausibility(this.originalValue, value)) {
        // not plausible, data changed a lot:
        this.isPlausible.value = false;
        this.showPopper("value", this.translate.stream("DATA_PANEL.Value_Plausibility_Message"));
        return;
      }
    }

    // entered data is plausible:
    this.isPlausible.value = true;
    this.hidePopper();
    if (this.value != null && this.quality == null) {
      this.quality = 0;
    }
    this.setData(this.value, this.quality);
  }

  private _changeQuality(quality: number): void {
    this.quality = quality;
    if (quality < this.originalQuality) {
      this.isPlausible.quality = false;
      this.showPopper("quality", this.translate.stream("DATA_PANEL.Quality_Plausibility_Message"));
      return;
    }
    this.isPlausible.quality = true;
    if (this.quality != null && this.value == null) {
      this.value = 0;
    }
    this.setData(this.value, this.quality);
  }

  private showPopper(context: "value" | "quality", infoText$: Observable<string>): void {
    this.popper.infoText$ = infoText$;
    this.popper.context = context;
    this.popperDirective.scheduledShow();
  }

  private hidePopper(): void {
    this.popperDirective.scheduledHide();
  }

  private setData(value: number, quality: number): void {
    const data = {
      ...this.data,
      value,
      quality
    };
    this.quality = quality;
    this.value = value;
    this.cellService.setData(data);
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
