import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { inputIsNotNullOrUndefined } from "../../../../../common";
import { ICo2EmissionSectorNodeDto } from "../../../data/dto/co2-emission-dto.interface";
import { ActiveAccountingService } from "../../../../accounting-common/services/active-accounting.service";
import { Accounting } from "../../../../accounting-common/model/accounting";
import { ACTIVE_ACCOUNTING_SERVICE_TOKEN } from "../../../../accounting-common/services/active-accounting.interface";
import { IChartTree } from "../../../../../../../../../libs/components/src/lib/app/modules/co2-cockpit/charts/greenhouse-gases/sectors/shared/chart-co2-sectors.helper.class";

import { Highcharts } from "@enersis/gp-components/gp-chart";

@Component({
  selector: "co2-chart-emissions-sunburst",
  templateUrl: "./chart-emissions-sunburst.component.html",
  styleUrls: ["./chart-emissions-sunburst.component.scss"]
})
export class ChartEmissionsComponent {
  @Input() public legendPosition: "bottom" | "bottom-centered" |"right" | undefined;
  @Output() public gpChartRef = new EventEmitter<Highcharts.Chart>();

  constructor(@Inject(ACTIVE_ACCOUNTING_SERVICE_TOKEN) private activeAccountingService: ActiveAccountingService) {}
  public accounting$!: Observable<Accounting>;
  public chartDataFunction = this.getDataFunction();
  public nh3DataFunction = this.getNh3DataFunction();

  public getDataFunction(): () => Observable<ICo2EmissionSectorNodeDto> {
    const accounting$ = this.activeAccountingService.getActiveAccounting();
    return () => {
      const chartData$ = accounting$.pipe(
        filter(inputIsNotNullOrUndefined),
        switchMap((accounting) => {
          return accounting.getEmissionsPerSector().pipe(map((data) => data.getSectorEmissionsForChart()));
        })
      );
      return chartData$;
    };
  }
  public getNh3DataFunction(): () => Observable<IChartTree | undefined> {
    const accounting$ = this.activeAccountingService.getActiveAccounting();
    return () => {
      const nh3Data$ = accounting$.pipe(
        filter(inputIsNotNullOrUndefined),
        switchMap((accounting) => {
          return accounting.getNh3PerSector().pipe(map((data) => data.getNh3ForChart()));
        })
      );
      return nh3Data$;
    };
  }

  public setChartRef(gpChartRef: Highcharts.Chart) {
    this.gpChartRef.emit(gpChartRef);
  }
}
