import { Action } from "@ngrx/store";
export enum ContactNewActionType {
  SEND_EMAIL = "[Contact NEW] SEND_EMAIL",
  SEND_EMAIL_SUCCESS = "[Contact NEW] SEND_EMAIL_SUCCESS",
  SEND_EMAIL_ERROR = "[Contact NEW] SEND_EMAIL_ERROR",
  SET_CURRENT_CONTACT_COMPONENT = "[Contact NEW] SET_CURRENT_CONTACT_COMPONENT"
}
export class SendEmailNew implements Action {
  public type = ContactNewActionType.SEND_EMAIL;
  constructor(public payload: any, public contactType: ContactType) {}
}
export class SendEmailNewSuccess implements Action {
  public readonly type = ContactNewActionType.SEND_EMAIL_SUCCESS;
  constructor(public payload: any) {}
}

export class SendEmailNewError implements Action {
  public readonly type = ContactNewActionType.SEND_EMAIL_ERROR;
  constructor(public payload: any) {}
}

export class SetCurrentContactComponent implements Action {
  public readonly type = ContactNewActionType.SET_CURRENT_CONTACT_COMPONENT;
  constructor(public payload: ContactType) {}
}

export type ContactNewActions = SendEmailNew | SendEmailNewSuccess | SetCurrentContactComponent | SendEmailNewError;

export enum ContactType {
  ERROR = "Fehler",
  FEEDBACK = "Feedback",
  CONTACTS = "Contacts"
}
