import { Component, Inject, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { startWith } from "rxjs/operators";

import { Co2CockpitFixedService } from "../services/co2-cockpit-fixed.service";
import {
  Accounting,
  ACTIVE_ACCOUNTING_SERVICE_TOKEN,
  CockpitConfigService,
  IActiveAccountingService
} from "@co2-shared/accounting-common";

@Component({
  selector: "app-co2-cockpit-fixed",
  templateUrl: "./co2-cockpit-fixed.component.html",
  styleUrls: ["./co2-cockpit-fixed.component.scss"],
  providers: [Co2CockpitFixedService]
})
export class Co2CockpitFixedComponent implements OnInit {
  public rankData$!: Observable<string>;
  public isRankEnabled$!: Observable<boolean>;
  public accounting$!: Observable<Accounting>;
  public accounting1990$!: Observable<Accounting>;

  constructor(
    private cockpitConfigService: CockpitConfigService,
    @Inject(ACTIVE_ACCOUNTING_SERVICE_TOKEN) private activeAccountingService: IActiveAccountingService
  ) {}

  public ngOnInit() {
    this.isRankEnabled$ = this.cockpitConfigService.isRankingEnabled().pipe(startWith(false));
    this.accounting$ = this.activeAccountingService.getActiveAccounting();
    this.accounting1990$ = this.activeAccountingService.getActiveAccounting1990();
  }
}
