/* tslint:disable:component-selector */
/* tslint:disable:component-class-suffix */
/* tslint:disable:no-unused-expression */
import { Component, ElementRef, OnDestroy, ViewChild, EventEmitter, HostListener, Renderer2 } from "@angular/core";
import Popper from "popper.js";
import { Placements, Triggers, PopperContentOptions } from "../models/popper.model";

@Component({
  selector: "popper-content",
  templateUrl: "./popper-content.component.html",
  styleUrls: ["./popper-content.component.scss"]
})
export class PopperContentComponent implements OnDestroy {
  public popperOptions: PopperContentOptions = <PopperContentOptions>{
    disableAnimation: false,
    disableDefaultStyling: false,
    placement: Placements.Auto,
    boundariesElement: "",
    trigger: Triggers.HOVER,
    positionFixed: false,
    popperModifiers: {}
  };

  public referenceObject: HTMLElement;

  public isMouseOver: boolean = false;

  public onHidden = new EventEmitter();

  public text: string;

  public popperInstance: Popper;

  public displayType: string = "none";

  public opacity: number = 0;

  private globalResize: any;

  @ViewChild("popperViewRef", { static: true })
  public popperViewRef: ElementRef;

  @HostListener("mouseover")
  public onMouseOver() {
    this.isMouseOver = true;
  }

  @HostListener("mouseleave")
  public showOnLeave() {
    this.isMouseOver = false;
    if (this.popperOptions.trigger !== Triggers.HOVER) {
      return;
    }
    this.hide();
  }

  public onDocumentResize() {
    this.update();
  }

  constructor(private renderer: Renderer2) {}

  public ngOnDestroy() {
    if (!this.popperInstance) {
      return;
    }
    (this.popperInstance as any).disableEventListeners();
    this.popperInstance.destroy();
  }

  public show(): void {
    if (!this.referenceObject) {
      return;
    }

    const popperOptions: Popper.PopperOptions = <Popper.PopperOptions>{
      placement: this.popperOptions.placement,
      positionFixed: this.popperOptions.positionFixed,
      modifiers: {
        arrow: {
          element: this.popperViewRef.nativeElement.querySelector(".ngxp__arrow")
        }
      }
    };

    const boundariesElement =
      this.popperOptions.boundariesElement && document.querySelector(this.popperOptions.boundariesElement);

    if (popperOptions.modifiers && boundariesElement) {
      popperOptions.modifiers.preventOverflow = { boundariesElement };
    }

    popperOptions.modifiers = Object.assign(popperOptions.modifiers, this.popperOptions.popperModifiers);

    this.popperInstance = new Popper(this.referenceObject, this.popperViewRef.nativeElement, popperOptions);
    (this.popperInstance as any).enableEventListeners();
    this.scheduleUpdate();
    this.toggleVisibility(true);
    this.globalResize = this.renderer.listen("document", "resize", this.onDocumentResize.bind(this));

    requestAnimationFrame(() => {
      this.scheduleUpdate();
    });
  }

  public update(): void {
    this.popperInstance && (this.popperInstance as any).update();
  }

  public scheduleUpdate(): void {
    this.popperInstance && (this.popperInstance as any).scheduleUpdate();
  }

  public hide(): void {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
    this.toggleVisibility(false);
    this.onHidden.emit();
  }

  public toggleVisibility(state: boolean) {
    if (!state) {
      this.opacity = 0;
      this.displayType = "none";
    } else {
      this.opacity = 1;
      this.displayType = "block";
    }
  }
}
