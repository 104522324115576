<div
  [popper]="headerLaunchpad"
  [popperTrigger]="popper.trigger"
  [popperPlacement]="popper.placement"
  [popperTimeout]="popper.timeout"
>
  <ene-icon icon="ec_header_launchpad"></ene-icon>
</div>
<popper-content #headerLaunchpad class="launchpad">
  <ul class="ene-list minipad">
    <li *ngFor="let item of links; trackBy: trackByFn" [class.active]="item.active">
      <a *ngIf="item.url" [href]="item.url" target="_blank">
        <ng-container *ngTemplateOutlet="launchpadItem"></ng-container>
      </a>
      <div *ngIf="!item.url">
        <ng-container *ngTemplateOutlet="launchpadItem"></ng-container>
      </div>
      <ng-template #launchpadItem>
        <ene-icon *ngIf="item.icon" [icon]="item.icon"></ene-icon>
        <img *ngIf="item.image" [src]="folderPath + item.image" [alt]="item.label" />
        <label>{{ item.label }}</label>
      </ng-template>
    </li>
  </ul>
</popper-content>
