/* tslint:disable:member-access */

import { Action } from "@ngrx/store";
export enum DistrictActionType {
  GET_DISTRICT_DATA = "[District] GET_DISTRICT_DATA",
  GET_DISTRICT_DATA_SUCCESS = "[District] GET_DISTRICT_DATA_SUCCESS",
  GET_DISTRICT_BASIC_DATA = "[District] GET_DISTRICT_BASIC_DATA",
  GET_DISTRICT_BASIC_DATA_SUCCESS = "[District] GET_DISTRICT_BASIC_DATA_SUCCESS",
  ERROR = "[District] ERROR"
}

export class GetDistrictData implements Action {
  type = DistrictActionType.GET_DISTRICT_DATA;
  constructor(public payload: any) {}
}
export class GetDistrictDataSuccess implements Action {
  type = DistrictActionType.GET_DISTRICT_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class GetDistrictBasicData implements Action {
  type = DistrictActionType.GET_DISTRICT_BASIC_DATA;
  constructor(public payload: any) {}
}
export class GetDistrictBasicDataSuccess implements Action {
  type = DistrictActionType.GET_DISTRICT_BASIC_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class DistrictError implements Action {
  type = DistrictActionType.ERROR;
  constructor(public payload: any) {}
}

export type DistrictActions =
  | GetDistrictData
  | GetDistrictDataSuccess
  | GetDistrictBasicData
  | GetDistrictBasicDataSuccess
  | DistrictError;
