<label class="toggle-button" [class.disabled]="disabled">
  <input
    type="checkbox"
    name="toggleButton"
    role="switch"
    [disabled]="disabled"
    [checked]="_checked"
    [(ngModel)]="_checked"
    (change)="onChange($event)"
  />
  <ene-icon *ngIf="_icon" [icon]="_icon"></ene-icon>
</label>
