import { ActionReducerMap } from "@ngrx/store/src/models";

import { reducer } from "./tour-reducer";
import { TourActionType } from "./tour-actions";

export const tourReducers: ActionReducerMap<any> = {
  tour: reducer
};

export interface ITourState {
  type: TourActionType;
  payload: any;
}
