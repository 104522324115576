import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UserPopover } from "../../user-popover.class";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "../../../../../../services/util.service";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../../../../../state/index";
import { IUserConfigs } from "../../user-popover.models";

@Component({
  selector: "app-netzebw-user-popover",
  templateUrl: "./netzebw-user-popover.component.html",
  styleUrls: ["./netzebw-user-popover.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NetzeBWUserPopoverComponent extends UserPopover {
  constructor(translate: TranslateService, utilService: UtilService, store: Store<fromRoot.IApplicationState>) {
    super(translate, utilService, store);
  }

  @Input() public set user(value: IUserConfigs) {
    if (!value) {
      return;
    }

    this.userInfo = value;
    this.showUserInfo = false;
    this.languages = super.getAllowedLanguages(value.allowedLanguages);

    this.switchLanguage(value.langCode);
  }
}
