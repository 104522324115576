import { Directive, HostBinding, Input } from "@angular/core";

@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: "ene-header-row",
  exportAs: "eneHeaderRowDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-header-row"
  }
})
export class EneHeaderRowDirective {
  @HostBinding("class.visible")
  @Input()
  public visible: boolean = true;

  @HostBinding("class.ene-header-secondary")
  @Input()
  public secondary: boolean = false;

  @HostBinding("style.height")
  @Input()
  public height: any;
}
