<app-page-wrapper [title]="config?.title | translate" [chartTitle]="config?.chartTitle | translate: { year: year }">
  <div class="avoid-break-inside">
    <ng-container *ngComponentOutlet="component; injector: propsInjector"></ng-container>
    <section>
      <div class="textbox">
        <span [innerHTML]="config?.text | translate"></span>
        <span
          *ngIf="isBisko"
          [innerHTML]="'PRINT_VIEW.CHART_DESCRIPTION.FINAL_ENERGY_TRAFFIC_BISKO_ADDON' | translate"
        ></span>
      </div>
    </section>
  </div>

  <div class="avoid-break-inside">
    <h2 class="table-title">{{ "PRINT_VIEW.TABLE.TITLE" | translate }}</h2>
    <div class="table-subtitle">{{ config?.tableFooter | translate: { year: year } }}</div>
    <div class="table-note"> {{ "PRINT_VIEW.TABLE.FOOTER.TEXT_4_REMARK" | translate }}</div>
    <table cdk-table [dataSource]="data$ | async">
      <ng-container *ngFor="let column of displayedColumns" [cdkColumnDef]="column">
        <th cdk-header-cell *cdkHeaderCellDef>
          {{ column | nameById: columns }}
        </th>
        <td cdk-cell *cdkCellDef="let element" [ngClass]="column">
          <span *ngIf="column === tableColumns.SECTOR">{{ "RESOURCES." + element.name | uppercase | translate }}</span>
          <span *ngIf="column === tableColumns.ENERGY">
            {{ element.value | number: numberFormat:locale }} ({{ element.percent | number: numberFormat:locale }}%)
          </span>
        </td>
      </ng-container>
      <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
      <tr
        cdk-row
        *cdkRowDef="let row; columns: displayedColumns"
        [ngClass]="{ parent: row.parent, child: row.child }"
      ></tr>
    </table>
  </div>
</app-page-wrapper>
