import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject, Observable, combineLatest, asyncScheduler } from "rxjs";
import { EneKpisV2Service, KpiOptions } from "@energy-city/ui/kpis-v2";
import {
  AccountingMethodService,
  RegionService,
  IApplicationState,
  IExtendedCockpitTab,
  getQuantitiveChartData,
  getMeasures
} from "@energy-city/components";
import { takeUntil, filter, map, observeOn } from "rxjs/operators";
import { AccountingMethod } from "../../../configs/accounting-method";
import { select, Store } from "@ngrx/store";
import { UnitConfig } from "@enersis/gp-components/gp-kpi";

enum Levels {
  STATE = 20,
  CIRCLE = 40,
  AMT = 50,
  MUNICIPALITY = 60
}

const tonsCo2PerCapita: UnitConfig = {
  category: "weight",
  base: "t",
  onlyUnits: ["t"],
  unitSuffix: "\xA0APP.CO2E_PER_CAPITA",
  numberFormat: {
    maximumFractionDigits: 2
  }
};

const kpis: { [key: string]: KpiOptions } = {
  selection_co2_emissions: {
    id: "selection_co2_emissions",
    unitConfig: tonsCo2PerCapita,
    icon: "gpi-filled-pin",
    title: "KPI.SELECTION_CO2_EMISSIONS",
    mapping: "emissionsSelection"
  },
  selection_co2_emissions_region: {
    id: "selection_co2_emissions_region",
    unitConfig: tonsCo2PerCapita,
    icon: "gpi-filled-pin-area",
    title: "KPI.SELECTION_CO2_EMISSIONS_REGION",
    mapping: "emissionsCircle"
  },
  selection_co2_emissions_state: {
    id: "selection_co2_emissions_state",
    unitConfig: tonsCo2PerCapita,
    icon: "gpi-filled-schleswig-holstein",
    title: "KPI.SELECTION_CO2_EMISSIONS_STATE",
    mapping: "emissionsState"
  }
};

@Component({
  /* tslint:disable:component-selector */
  selector: "panel-benchmarking",
  templateUrl: "./benchmarking.component.html",
  styleUrls: ["./benchmarking.component.scss"]
})
export class BenchmarkingPanelComponent implements OnInit, OnDestroy {
  public activeTabIndex = 0;
  public descriptionText = "";
  public tabs: Array<IExtendedCockpitTab> = [
    {
      name: "BENCHMARKING.QUANTITATIVE_BENCHMARKING",
      text: "BENCHMARKING.QUANTITATIVE_BENCHMARKING.TEXT",
      description: "BENCHMARKING.QUANTITATIVE_BENCHMARKING.DESCRIPTION",
      kpi: [
        {
          id: "benchmark_kpi",
          items: [kpis.selection_co2_emissions, kpis.selection_co2_emissions_region, kpis.selection_co2_emissions_state]
        }
      ]
    },
    {
      name: "BENCHMARKING.QUALITATIVE_ACTIVITY_PROFILE",
      text: "BENCHMARKING.QUALITATIVE_ACTIVITY_PROFILE.TEXT"
    }
  ];
  public get activeTab(): IExtendedCockpitTab {
    return this.tabs[this.activeTabIndex];
  }
  public AccountingMethod = AccountingMethod;
  public selectedAccountingMethod: string;
  public weatherCorrection$ = this.accountingMethodService.weatherCorrection$;

  private kpiData: any = {};
  private destroy$: Subject<void> = new Subject();

  constructor(
    private eneKpisV2Service: EneKpisV2Service,
    private accountingMethodService: AccountingMethodService,
    private store: Store<IApplicationState>,
    private regionService: RegionService
  ) {}

  public activeTabChange(event: CustomEvent<{ index: number; label: string }>) {
    this.activeTabIndex = event.detail.index;
  }

  public ngOnInit(): void {
    this.handleAccountingMethod();
    this.combineResults();

    this.eneKpisV2Service.pending$.pipe(observeOn(asyncScheduler)).subscribe(() => {
      this.eneKpisV2Service.runMapping(this.kpiData, "benchmark_kpi");
    });
  }

  private combineResults(): void {
    combineLatest([this.selectQuantitiveBenchmarking(), this.selectMeasures()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([quantitiveBenchmarkKpi, measuresCount]) => {
        this.kpiData = {
          ...quantitiveBenchmarkKpi,
          ...measuresCount
        };
        this.runKpiMapping(this.kpiData);
      });
  }

  private handleAccountingMethod(): void {
    this.accountingMethodService.selectedAccountingMethod$.pipe(takeUntil(this.destroy$)).subscribe((method) => {
      this.selectedAccountingMethod = method;
    });
  }

  private selectQuantitiveBenchmarking(): Observable<any> {
    return this.store.pipe(
      select(getQuantitiveChartData),
      filter((data) => !!data),
      map((data) => this.aggregateQuantitiveData(data)),
      takeUntil(this.destroy$)
    );
  }

  private selectMeasures(): Observable<any> {
    return this.store.pipe(
      select(getMeasures),
      filter((data) => !!data),
      map((data) => this.filterActiveMeasuresCount(data)),
      takeUntil(this.destroy$)
    );
  }

  private aggregateQuantitiveData(data: any): Object {
    const emissionsMunicipality = this.aggregate(data.filter((item) => item.level === Levels.MUNICIPALITY)[0]);
    const emissionsAmt = this.aggregate(data.filter((item) => item.level === Levels.AMT)[0]);
    const emissionsCircle = this.aggregate(data.filter((item) => item.level === Levels.CIRCLE)[0]);
    const emissionsState = this.aggregate(data.filter((item) => item.level === Levels.STATE)[0]);

    let emissionsSelection: number;

    switch (this.regionService.selectedRegionDetails.level) {
      case Levels.MUNICIPALITY:
        emissionsSelection = emissionsMunicipality;
        break;
      case Levels.AMT:
        emissionsSelection = emissionsAmt;
        break;
      case Levels.CIRCLE:
        emissionsSelection = emissionsCircle;
        break;
      case Levels.STATE:
        emissionsSelection = emissionsState;
        break;
    }

    return {
      emissionsSelection,
      emissionsCircle,
      emissionsState
    };
  }

  private aggregate(dataObject: any): number {
    if (!dataObject) {
      return undefined;
    }
    dataObject = dataObject.data;
    let returnable = 0;
    for (const sector of Object.keys(dataObject)) {
      if (dataObject[sector].value && dataObject[sector].value >= 0) {
        returnable += dataObject[sector].value;
      }
    }
    return returnable;
  }

  private filterActiveMeasuresCount(data: any): { total: number } {
    return { total: data.measures.filter((measure) => measure.active === true).length };
  }

  private runKpiMapping(data: any): void {
    this.eneKpisV2Service.runMapping(data, "benchmark_kpi");
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
