import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataInputConfigService } from "./config/data-input-config.service";
import { ConfigModule } from "../common";

@NgModule({
  imports: [CommonModule, ConfigModule],
  providers: [DataInputConfigService]
})
export class DataInputModule {}
