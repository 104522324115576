import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AccountingMethodService } from "../../../services/accounting-method.service";
import { acceptJsonWithoutCache } from "../../common";
import {
  GetMainPolluterAllData,
  GetMainPolluterStationaryEnergyData,
  GetMainPolluterStationaryEnergyDataError,
  GetMainPolluterStationaryEnergyDataSuccess,
  GetMainPolluterTransportationData,
  GetMainPolluterTransportationDataError,
  GetMainPolluterTransportationDataSuccess,
  IProps,
  MainPolluterActionTypes
} from "./main-polluter.actions";

@Injectable()
export class MainPolluterEffects {
  @Effect()
  public getAllData$: Observable<Action> = this.actions$.pipe(
    ofType(MainPolluterActionTypes.GET_MAIN_POLLUTER_ALL_DATA),
    switchMap((action: GetMainPolluterAllData) => {
      return [
        new GetMainPolluterStationaryEnergyData(action.props),
        new GetMainPolluterTransportationData(action.props)
      ];
    })
  );

  @Effect()
  public getStationaryEnergyData$: Observable<Action> = this.actions$.pipe(
    ofType(MainPolluterActionTypes.GET_MAIN_POLLUTER_STATIONARY_ENERGY_DATA),
    switchMap((action: GetMainPolluterStationaryEnergyData) => {
      const {
        api: { env, path },
        ...props
      } = action.props;
      const url = `${env()}/${path}/stationary-energy/cause`;
      const headers = new HttpHeaders(acceptJsonWithoutCache);
      const params = this.getParams(props);

      return this.http.get(url, { params, headers }).pipe(
        map((data: any) => new GetMainPolluterStationaryEnergyDataSuccess(data)),
        catchError((error: any) => of(new GetMainPolluterStationaryEnergyDataError(error)))
      );
    })
  );

  @Effect()
  public getTransportationData$: Observable<any> = this.actions$.pipe(
    ofType(MainPolluterActionTypes.GET_MAIN_POLLUTER_TRANSPORTATION_DATA),
    switchMap((action: GetMainPolluterTransportationData) => {
      const {
        api: { env, path },
        ...props
      } = action.props;
      const url = `${env()}/${path}/transportation/cause`;
      const headers = new HttpHeaders(acceptJsonWithoutCache);
      const params = this.getParams(props);

      return this.http.get(url, { params, headers }).pipe(
        map((data) => new GetMainPolluterTransportationDataSuccess(data)),
        catchError((error) => of(new GetMainPolluterTransportationDataError(error)))
      );
    })
  );

  private getParams({ stationaryEnergy, weatherCorrection, regionId, regionType, year }: Partial<IProps>): HttpParams {
    return new HttpParams({
      fromObject: {
        regionId,
        regionType,
        year: String(year),
        weatherCorrection: String(weatherCorrection),
        energyType: stationaryEnergy,
        useUserFactors: this.accountingMethodService.useUserFactors$.getValue()
      }
    });
  }

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private accountingMethodService: AccountingMethodService
  ) {}
}
