import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AccountingMethodService,
  EntryComponents,
  RegionPropertyEmissionService,
  UtilService,
  sectorConfig
} from "@energy-city/components";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { NUMBER_FORMAT } from "../../reports.config";
import { PrintService } from "../../services/print.service";

enum TableColumns {
  SECTOR = "sector",
  EMISSION = "emission"
}

@Component({
  selector: "app-trend-page",
  templateUrl: "./trend.component.html",
  styleUrls: ["./trend.component.scss"]
})
export class TrendPageComponent implements OnInit, OnDestroy {
  @Input() public config;

  public numberFormat = NUMBER_FORMAT;
  public locale: string;
  public columns: Array<any>;
  public displayedColumns: Array<string>;
  public data$: Observable<any>;
  public year: number;
  public component: any;
  public tableColumns = TableColumns;

  private destroy$ = new Subject<void>();

  constructor(
    private utilService: UtilService,
    private printService: PrintService,
    private accService: AccountingMethodService,
    private emissionService: RegionPropertyEmissionService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.locale = this.translate.currentLang;
    this.year = this.printService.selectedReportYear;
    this.columns = this.getColumns();
    this.displayedColumns = this.columns.map((item) => item.id);
    this.data$ = this.getData();
    this.component = EntryComponents.charts.ChartGreenhouseGasesTrendComponent;

    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.locale = this.translate.currentLang);
  }

  private getColumns(): Array<any> {
    return [
      {
        id: TableColumns.SECTOR,
        name: this.translate.instant("APP.SECTOR")
      },
      {
        id: TableColumns.EMISSION,
        name: `${this.translate.instant("APP.EMISSIONS")} [t CO₂e]`
      }
    ];
  }

  private getData(): Observable<any> {
    const sectorQuery = sectorConfig.sectors
      .filter((sector) => sector.methods.includes(this.accService.selectedAccountingMethod$.value))
      .map((sector) => ({
        ...sector,
        level: 0
      }));

      

    return this.utilService.regionIdentifier$
      .pipe(
        switchMap(regionIdentifier => this.emissionService.getCo2EmissionSectorData(regionIdentifier, sectorQuery, this.year)),
        map(emissionsSectors =>
          emissionsSectors
            .filter(sectorResult => Boolean(sectorResult.result.length))
            .map((emission) => ({
              name: `COCKPIT.CO2.TABS.TREND.LEGEND.${emission.sector.type}`,
              value: emission.result[0].value
            })))
      );
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
