<ng-container *ngIf="table">
  <table class="factors" cdk-table [dataSource]="table.rows">
    <ng-container *ngFor="let column of table.columnHeaders; index as i; first as isFirst" [cdkColumnDef]="column">
      <th *cdkHeaderCellDef>{{ table.columnHeaders[i] | translate }}</th>
      <td *cdkCellDef="let element">
        <ng-container *ngIf="isFirst; else value">{{ element.description | translate }}</ng-container>
        <ng-template #value>
          <ng-container *ngIf="element.cells[i - 1].hasValue; else dash">
            {{ element.cells[i - 1].value | number: "1.2-2":currentLang }}
          </ng-container>
          <ng-container *ngIf="element.cells[i - 1].hasFootnote">
            {{ "*".repeat(element.cells[i - 1].footnoteIndex + 1) }}
          </ng-container>
        </ng-template>
      </td>
    </ng-container>
    <tr cdk-header-row *cdkHeaderRowDef="table.columnHeaders"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: table.columnHeaders"></tr>
  </table>
  <ng-template ngFor let-note [ngForOf]="table.footnotes" let-i="index">
    <!-- first star is port of the translation -->
    <div class="note">{{ "*".repeat(i) }}{{ note | translate }}</div>
  </ng-template>

  <ng-template #dash> - </ng-template>
</ng-container>
