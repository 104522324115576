import { Component } from "@angular/core";

import { TimelineService } from "@co2-shared/common"

@Component({
  selector: "co2-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"]
})
export class TimelineComponent {
  public initialYear = this.timelineService.getInitialYear();
  public startYear = this.timelineService.getStartYear();
  public endYear = this.timelineService.getEndYear();
  public yearsWithData = this.timelineService.getYearsWithData();

  constructor(
    private timelineService: TimelineService
  ) {}

  public onSliderChange(year: number): void {
    this.timelineService.setSelectedYear(year);
  }
}
