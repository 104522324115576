import { Component, ChangeDetectionStrategy, Inject, OnInit } from "@angular/core";
import { Header, IHeaderConfigs, DYNAMIC_HEADER_DATA } from "../../models";
import { EneModalService } from "@energy-city/ui/modal";
import { AuthenticationService } from "../../../../modules/authentication/services/exported/authentication.service";

@Component({
  selector: "app-hansewerk-header",
  templateUrl: "./hansewerk-header.component.html",
  styleUrls: ["./hansewerk-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HansewerkHeaderComponent extends Header implements OnInit {
  public hasCoatOfArms = false;
  public staticDashboardImage?: string = null;

  constructor(
    private modal: EneModalService,
    @Inject(DYNAMIC_HEADER_DATA) headerData: IHeaderConfigs,
    private authService: AuthenticationService
  ) {
    super(headerData);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.staticDashboardImage = this.authService.tokenParsed.static_dashboard || null;
  }

  public openStaticDashboard(): void {
    if (this.staticDashboardImage) {
      this.modal.open("static-dashboard");
    }
  }
}
