import { Action } from "@ngrx/store";
import { ContactType } from "../contact-new/contact.actions";
export enum ContactActionType {
  SEND_EMAIL = "[Contact] SEND_EMAIL",
  SEND_EMAIL_SUCCESS = "[Contact] SEND_EMAIL_SUCCESS",
  SEND_EMAIL_ERROR = "[Contact] SEND_EMAIL_ERROR"
}
export class SendEmail implements Action {
  public type = ContactActionType.SEND_EMAIL;
  constructor(
    public customerSupportId: string,
    public municipality: string,
    public contactType: ContactType,
    public url: string,
    public contactName: string,
    public email: string,
    public phone: string,
    public message: string,
    public ags: string
  ) {}
}
export class SendEmailSuccess implements Action {
  public type = ContactActionType.SEND_EMAIL_SUCCESS;
  constructor(public payload: any) {}
}

export class SendEmailError implements Action {
  public type = ContactActionType.SEND_EMAIL_ERROR;
  constructor(public payload: any) {}
}

export type ContactActions = SendEmail | SendEmailSuccess | SendEmailError;
