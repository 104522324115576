<h3 [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.DATASOURCE.TITLE') | translate"></h3>
<p [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.DATASOURCE.PARAGRAPH_1') | translate"></p>
<p class="info-padded-box"><img src="../../../../../../assets/images/info/climate/rechts.png" alt="" /></p>
<p
  [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.DATASOURCE.PARAGRAPH_2') | translate"
  class="info-padded-box"
></p>
<p
  [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.DATASOURCE.PARAGRAPH_3') | translate"
  class="info-padded-box"
></p>
<p
  [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.DATASOURCE.PARAGRAPH_4') | translate"
  class="info-padded-box"
></p>
<p class="button">
  <button class="ene-button-pill-primary" (click)="goToGlossary()">
    <ene-icon icon="ec_side_report"></ene-icon>
    {{ getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.DATASOURCE.GOTOGLOSSAR') | translate }}
  </button>
</p>
