<ul class="sunburst-legend" *ngIf="items?.length">
  <li *ngIf="true">
    <button class="drillup-button" *ngIf="drillUpButton" (click)="drillUp()">
      <ene-icon icon="ec_controls_back"></ene-icon>
      <span>{{ drillUpButton | translate }}</span>
    </button>
  </li>
  <li class="legend-item" *ngFor="let item of items; trackBy: trackByFn">
    <span class="legend-box" [style.backgroundColor]="item.color"></span>
    <span>{{ item.name | translate }}</span>
  </li>
</ul>
