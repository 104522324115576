import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneUiPipesModule } from "@energy-city/ui/pipes";
import { EneKpisV2Component } from "./components/kpis.component";
import { EneUnitPipe } from "./pipes/unit.pipe";
import { EneKpisV2Service } from "./services/kpis.service";
import { WeatherCorrectionIndicatorModule } from "../weather-correction-indicator";
import "@enersis/gp-components/gp-kpi";
import "@enersis/gp-components/gp-kpis-box";
import "@enersis/gp-components/gp-icon";

@NgModule({
  declarations: [EneKpisV2Component, EneUnitPipe],
  imports: [CommonModule, TranslateModule, EneIconModule, EneUiPipesModule, WeatherCorrectionIndicatorModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [EneKpisV2Component, EneUnitPipe]
})
export class EneKpisV2Module {
  public static forRoot(): ModuleWithProviders<EneKpisV2Module> {
    return {
      ngModule: EneKpisV2Module,
      providers: [EneKpisV2Service]
    };
  }
}
