<div
  [popper]="userNavigation"
  [popperTrigger]="popper.trigger"
  [popperPlacement]="popper.placement"
  [popperTimeout]="popper.timeout"
>
  <ene-icon icon="ec_header_profile"></ene-icon>
</div>

<popper-content #userNavigation class="user-navigation">
  <div
    class="user-navigation__item user-navigation__clickable language-switcher"
    [popper]="languageSwitcher"
    [popperTrigger]="popper.trigger"
    [popperPlacement]="'bottom'"
    [popperTimeout]="popper.timeout"
  >
    <ene-icon icon="ec_header_language" class="user-navigation__item-icon"> </ene-icon>
    <div class="user-navigation__item-text user-navigation__language">
      {{ "HEADER.USER.LANGUAGE" | translate }}: {{ activeLanguage.name }}
    </div>
    <ene-icon icon="ec_controls_closed_dropdown" class="user-navigation__icon-close"> </ene-icon>
  </div>
  <ng-container *ngIf="isPublic; else notPublic">
    <div class="user-navigation__item user-navigation__clickable" (click)="login()">
      <ene-icon icon="ec_header_logout" class="user-navigation__item-icon"> </ene-icon>
      <div class="user-navigation__item-text">
        {{ "HEADER.USER.LOGIN" | translate }}
      </div>
    </div>
  </ng-container>
  <ng-template #notPublic>
    <div class="user-navigation__item user-navigation__clickable" (click)="logout()">
      <ene-icon icon="ec_header_logout" class="user-navigation__item-icon"> </ene-icon>
      <div class="user-navigation__item-text">
        {{ "HEADER.USER.LOGOUT" | translate }}
      </div>
    </div>
  </ng-template>
</popper-content>

<popper-content #languageSwitcher class="user-navigation__language-list">
  <ul class="ene-list nav-list">
    <li
      *ngFor="let item of languages; trackBy: trackByFn"
      class="ene-checkbox"
      [class.checked]="item.code === activeLanguage.code"
      (click)="switchLanguage(item.code)"
    >
      {{ item.name }}
    </li>
  </ul>
</popper-content>
