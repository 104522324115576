import { hierarchy } from "d3-hierarchy";
import { color } from "d3-color";
import { sectorConfig } from "../../../../../../models/co2-sectors-config.model";
import { ColorHelper } from "../../../../../../share/color";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep, isEmpty } from "lodash";
import { EnergyTypes } from "../../../../../../configs/accounting-method";

export interface ICo2EmissionSectorLeaf {
  value: number;
  quality?: number;
}

export interface ICo2EmissionSectorNode {
  [id: string]: ICo2EmissionSectorNode | ICo2EmissionSectorLeaf;
}

export interface ICo2EmissionSectorRoot {
  year: number;
  [id: string]: ICo2EmissionSectorNode | ICo2EmissionSectorLeaf | any;
}
export interface ICo2EmissionSectorResult {
  result: ICo2EmissionSectorRoot[];
  sector: { type: EnergyTypes; level: number };
}

export class ChartCo2SectorsHelper {
  private sectorConfig = sectorConfig;
  private sortedSectors = [EnergyTypes.STATIONARY_ENERGY, EnergyTypes.TRANSPORTATION];
  private sortOrders = {
    [EnergyTypes.STATIONARY_ENERGY]: ["privateHousehold", "commerce", "industry", "communityFacilities"],
    [EnergyTypes.TRANSPORTATION]: ["onRoad", "roadTransportation", "railway", "waterTransport", "aviation"]
  };

  constructor() {}

  /**
   * Transform the data into the highcharts hierarchy structure
   * Create colors: on first level color need to be defined
   * Translate values: first level= + _TOT
   * @param data
   * @param deep
   * @param accountingMethod
   * @param translate
   */
  public getChartSectorData(data: ICo2EmissionSectorResult[], deep: number, translate: TranslateService) {
    const aggregatedData = this.aggregateData(data);
    const chartTree = this.getChartTree(aggregatedData);
    const sortedChartTree = this.sortSubsectors(chartTree);
    const chartRes = this.createHighchartsObject(sortedChartTree, deep);

    this.translate(chartRes.highchartsData, translate);

    return chartRes;
  }

  private aggregateData(data: ICo2EmissionSectorResult[]): ICo2EmissionSectorResult[] {
    const aggregatedData = cloneDeep(data);
    return aggregatedData
      .filter((sectorResult) => Boolean(sectorResult.result.length))
      .map((sectorResult) => {
        const result = sectorResult.result.map((sectorRoot) =>
          this.mergePropsRecursive(sectorRoot, ["urban", "rural", "highway"])
        );
        return { ...sectorResult, result };
      });
  }

  private mergePropsRecursive(data: any, props: string[]) {
    // return true if all keys are inside libary
    const keysInsideArray = (keys: string[], library: string[]) => keys.every((val) => library.includes(val));

    const mergedObject: any = {};
    if (!data || data?.value <= 0) {
      return mergedObject;
    }
    if (keysInsideArray(Object.keys(data), props)) {
      for (const innerObject of Object.values(data)) {
        for (const [propKey, propValue] of Object.entries(innerObject)) {
          if (!mergedObject[propKey]) {
            mergedObject[propKey] = { value: 0, quality: 0 };
          }
          mergedObject[propKey].value += propValue.value;
          mergedObject[propKey].quality += propValue.quality * propValue.value;
        }
      }
      Object.values(mergedObject).forEach((newPropVal: any) => {
        newPropVal.quality = newPropVal.quality / newPropVal.value;
      });
    } else {
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === "object" && value !== null) {
          const mergedValue = this.mergePropsRecursive(value, props);
          if (!isEmpty(mergedValue)) {
            mergedObject[key] = mergedValue;
          }
        } else {
          mergedObject[key] = value;
        }
      }
    }

    return mergedObject;
  }

  private translate(highchartsData, translate: TranslateService) {
    highchartsData.forEach((element) => {
      const translationKey = `RESOURCES.${
        element.name === "waste" && element.parent === "0" ? "WASTEANDWASTEWATER" : element.name
      }`
        .toUpperCase()
        .replace("-", "_");

      element.name = translate.instant(translationKey);
      element.translationKey = translationKey;
    });
  }

  /**
   *  return the D3 hierarchy structure with children instead of any key as child
   * @param data
   */
  public getChartTree(data: ICo2EmissionSectorResult[]): IChartTree {
    const newData = [];

    for (const sector of this.sectorConfig.sectors) {
      const dataOfSector = data.filter((e) => e.sector.type === sector.type)[0];
      if (!dataOfSector) {
        continue;
      }
      newData.push({
        color: sector.color,
        field: `${sector.type}`,
        ...this.getChartTreeRecursive(dataOfSector.result[0])
      });
    }

    return { field: "co2Total", children: newData.filter((item) => item.children) };
  }

  /**
   *
   * @param data Input Data: [{ <childKey>:{value, quality}}]
   * @param deep
   */
  public getChartTreeRecursive(data: ICo2EmissionSectorNode | ICo2EmissionSectorLeaf): IChartTree {
    if (!data) {
      return {};
    }
    // it is leaf
    if (data["value"] !== undefined) {
      return { value: (data as ICo2EmissionSectorLeaf).value, quality: (data as ICo2EmissionSectorLeaf).quality };
    }

    const childrenNames = Object.keys(data).filter((e) => e !== "value" && e !== "quality" && e !== "year");
    // its a node
    if (childrenNames.length) {
      const newEntry = { children: [] };
      for (const childName of childrenNames) {
        newEntry.children.push({ field: `${childName}`, ...this.getChartTreeRecursive(data[childName]) });
      }
      return newEntry;
    }
    // fallback
    return {};
  }

  private createHighchartsObject(tree: any, maxDepth: number): { valueSum: number; highchartsData: any[] } {
    const highchartsData = [];

    const colorCalculator = function (maxShadeAtLevel: number, shadeBetweenLevel: number): string {
      // using old notation so our compiler thinks that this is any
      // if not the first layer
      if (this.depth > 1) {
        // if 1. element take parent - 1
        const index = this.parent.children.indexOf(this);
        if (index === 0) {
          this._color = color(ColorHelper.shadeColor(this.parent._color.hex(), shadeBetweenLevel));
        } else {
          // if nth element it is parent  - n
          const shadeFactor = (maxShadeAtLevel * (index + 1)) / this.parent.children.length;
          this._color = color(ColorHelper.shadeColor(this.parent.children[0]._color.hex(), shadeFactor));
        }
        return this._color.toString();
      }
      return this._color.toString();
    };

    const treeObj = hierarchy(tree).sum((d: any) => d.value);

    treeObj.children.forEach((node: any) => {
      // set the startColor
      const startColor = color(node.data.color);
      startColor.opacity = 1;
      node._color = startColor;

      // set node color
      node.eachBefore((child: any) => (child.color = colorCalculator.apply(child, [80, -20])));
    });

    treeObj.eachBefore((node: any) => {
      // https://en.wikipedia.org/wiki/Tree_traversal#Pre-order -> in this case we always go in a descending order
      if (node.depth > maxDepth) {
        return;
      }
      const slice: any = {};

      if (node.parent) {
        // add parent id
        node._parentId = node.parent._id;
        slice.parent = node._parentId;
        slice.parentValue = node.parent.value;
      }
      node._id = node._parentId ? node._parentId + "-" + node.parent.children.indexOf(node) : "0";
      slice.id = node._id;
      if (node.children) {
        // create id
        if (node.depth === maxDepth) {
          slice.value = node.value;
        }
      } else {
        slice.value = node.data.value;
      }

      // also add the name & color
      slice.color = node.color;
      slice.name = node.data.field;
      slice.key = node.data.field;
      // highcharts renders sectors with value 0 in some cases
      // as quarters. To avoid this problem, we only add sectors
      // where value is different from 0
      if (slice.value !== 0) {
        highchartsData.push(slice);
      }
    });

    // create parent reference
    const highchartMap = Object.assign({}, ...highchartsData.map((e) => ({ [e.id]: e })));
    highchartsData.forEach((e) => {
      e.parentObj = highchartMap[e.parent];
    });

    return {
      valueSum: treeObj.value,
      highchartsData
    };
  }

  private sortSubsectors(data: IChartTree): IChartTree {
    const sortedData: IChartTree[] = data.children.map((sector) => {
      if (this.sortedSectors.includes(sector.field as EnergyTypes)) {
        const sortedSubsectors = [];
        this.sortOrders[sector.field].forEach((subsector: string) => {
          const child = sector.children.find((child) => child.field === subsector);
          if (child !== undefined) {
            sortedSubsectors.push(child);
          }
        });

        return { ...sector, children: sortedSubsectors };
      }

      return sector;
    });

    return {
      field: data.field,
      children: sortedData
    };
  }
}

export interface IChartTree {
  field?: string;
  value?: number;
  quality?: number;
  color?: string;
  children?: IChartTree[];
}
