import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { trigger, transition, useAnimation } from "@angular/animations";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil, withLatestFrom } from "rxjs/operators";

import { PrintService } from "../../services/print.service";

import { ENTER_ANIMATION, LEAVE_ANIMATION } from "../../print.const";
import { AccountingMethodService, GetAuthState, AuthActionType, IApplicationState } from "@energy-city/components";
import { cloneDeep } from "lodash";
import { select, Store } from "@ngrx/store";

@Component({
  selector: "app-print-wrapper",
  templateUrl: "./print-wrapper.component.html",
  styleUrls: ["./print-wrapper.component.scss"],
  animations: [
    trigger("print", [
      transition(":enter", [useAnimation(ENTER_ANIMATION)]),
      transition(":leave", [useAnimation(LEAVE_ANIMATION)])
    ])
  ]
})
export class PrintWrapperComponent implements OnInit, OnDestroy {
  @Input() public sizing: string = "origin";

  public generalPageText = "PRINT_VIEW.GENERAL_PAGE";
  public tenant = "";

  public link = `${window.location.protocol}//${window.location.host}`;
  public createDate = Date.now();
  public active$: Observable<boolean>;
  public pagesConfig: Array<any> = [];
  public rawPagesConfig: Array<any> = [];
  private destroy$ = new Subject();
  public accountingMethod = "";

  constructor(
    private printService: PrintService,
    private acc: AccountingMethodService,
    private store: Store<IApplicationState>
  ) {}

  public ngOnInit() {
    this.active$ = this.printService.printWin$;

    this.printService
      .getPagesData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((pages) => {
        this.rawPagesConfig = pages;
      });

    this.acc.selectedAccountingMethod$
      .pipe(
        withLatestFrom(
          this.store.pipe(
            select(GetAuthState),
            filter((authState) => authState.type === AuthActionType.LOGIN_SUCCESS)
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([accountingMethod, authState]) => {
        this.tenant = ((authState as any).payload.tenant as string).toUpperCase();
        this.accountingMethod = "." + accountingMethod;

        this.pagesConfig = cloneDeep(this.rawPagesConfig);
      });
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public print(): void {
    window.print();
  }

  public close(): void {
    this.printService.hidePrintPreview();
  }

  public trackByFn(index: number, congif: any): string {
    return congif.name;
  }
}
