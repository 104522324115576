import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CdkTableModule } from "@angular/cdk/table";
import { TranslateModule } from "@ngx-translate/core";
import { DataInputNavigationModule } from "./navigation/navigation.module";
import { DataInputTableModule } from "./table/data-input-table.module";
import { DataInputFooterModule } from "./footer/footer.module";
import { DataInputModalsModule } from "./modals/modals.module";
import { DataInputService } from "./data-input.service";
import { DataInputComponent } from "./data-input.component";
import { EneModalModule } from "@energy-city/ui/modal";
import { FormsModule } from "@angular/forms";
import { EneIconModule } from "@energy-city/ui/icon";
import { CsvTransformerService } from "./csv-transformer.service";
import { CommentModule } from "../../../../components/comment/comment.module";
import { NgScrollbarModule } from "ngx-scrollbar";
import { DataInputModule as DataInputModule2 } from "@co2-shared/data-input";

@NgModule({
  declarations: [DataInputComponent],
  providers: [DataInputService, CsvTransformerService],
  imports: [
    CommonModule,
    CommentModule,
    TranslateModule,
    CdkTableModule,
    DataInputNavigationModule,
    DataInputTableModule,
    DataInputFooterModule,
    DataInputModalsModule,
    EneModalModule,
    EneIconModule,
    FormsModule,
    NgScrollbarModule,
    DataInputModule2
  ],
  exports: [DataInputComponent],
  entryComponents: [DataInputComponent]
})
export class DataInputModule {}
