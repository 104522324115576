import { Injectable } from "@angular/core";
import { ToastrService, IndividualConfig } from "ngx-toastr";

@Injectable()
export class EneSnackbarService {
  constructor(private toastr: ToastrService) {}

  public info(message: string, options?: Partial<IndividualConfig>): void {
    this.toastr.info(message, undefined, options);
  }

  public pending(message: string, options?: Partial<IndividualConfig>): void {
    // was overridden it for pending use
    this.toastr.warning(message, undefined, options);
  }

  public error(message: string, options?: Partial<IndividualConfig>): void {
    this.toastr.error(message, undefined, options);
  }

  public success(message: string, options?: Partial<IndividualConfig>): void {
    this.toastr.success(message, undefined, options);
  }
}
