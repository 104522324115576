import { ContactType } from "@energy-city/components";

export const ContactFormSelectOptions = {
  [ContactType.ERROR]: {
    label: "APP.REPORT_ERROR_SELECT_LABEL",
    options: [
      {
        id: "APP.ERROR_FORM_SELECT_ISSUE.COCKPIT",
        value: "APP.ERROR_FORM_SELECT_ISSUE.COCKPIT"
      },
      {
        id: "APP.ERROR_FORM_SELECT_ISSUE.MAPTOPICS",
        value: "APP.ERROR_FORM_SELECT_ISSUE.MAPTOPICS"
      },
      {
        id: "APP.ERROR_FORM_SELECT_ISSUE.SELECTION",
        value: "APP.ERROR_FORM_SELECT_ISSUE.SELECTION"
      },
      {
        id: "APP.ERROR_FORM_SELECT_ISSUE.TIMELINE",
        value: "APP.ERROR_FORM_SELECT_ISSUE.TIMELINE"
      },
      {
        id: "APP.ERROR_FORM_SELECT_ISSUE.SEARCH",
        value: "APP.ERROR_FORM_SELECT_ISSUE.SEARCH"
      },
      {
        id: "APP.ERROR_FORM_SELECT_ISSUE.CHARTS",
        value: "APP.ERROR_FORM_SELECT_ISSUE.CHARTS"
      },
      {
        id: "APP.ERROR_FORM_SELECT_ISSUE.MISCELLANEOUS",
        value: "APP.ERROR_FORM_SELECT_ISSUE.MISCELLANEOUS"
      }
    ]
  },
  [ContactType.FEEDBACK]: {
    label: "APP.FEEDBACK_FORM_SELECT_LABEL",
    options: [
      {
        id: "APP.FEEDBACK_FORM_SELECT_ISSUE.PRAISE",
        value: "APP.FEEDBACK_FORM_SELECT_ISSUE.PRAISE"
      },
      {
        id: "APP.FEEDBACK_FORM_SELECT_ISSUE.IMPROVEMENTS",
        value: "APP.FEEDBACK_FORM_SELECT_ISSUE.IMPROVEMENTS"
      },
      {
        id: "APP.FEEDBACK_FORM_SELECT_ISSUE.MISCELLANEOUS",
        value: "APP.FEEDBACK_FORM_SELECT_ISSUE.MISCELLANEOUS"
      }
    ]
  }
};
