import { get } from "lodash";
import { IUnitOption, UnitType } from "../../configs/data-panel";

export interface ITableCell {
  id: string;
  year: number;
  dependency: boolean;
  value?: number;
  quality?: number;
  name?: string;
  parentName?: string;
}

export interface ITableRow {
  [key: string]: ITableCell;
}

export interface IAsideCell {
  name: string;
  dependency: boolean;
}

interface IResponseItem {
  [key: string]: object | number;
  year: number;
}

export interface IKey {
  id: string;
  name: string;
  parent?: boolean;
}

export interface IKeysMap {
  [key: string]: {
    [key: string]: Array<IKey>;
  };
}

class Cell implements ITableCell {
  public readonly id: string;
  public readonly name: string;
  public readonly year: number;
  public readonly dependency: boolean;
  public value: number;
  public quality: number;

  constructor(key: IKey, data: IResponseItem) {
    this.id = key.id;
    this.name = key.name;
    this.year = data.year;
    this.dependency = key.parent;

    if (this.dependency !== false) {
      this.value = get(data, `${this.id}.value`, null) as number;
      this.quality = get(data, `${this.id}.quality`, 0) as number;
    }
  }
}

class AsideCell implements IAsideCell {
  public readonly name: string;
  public readonly dependency: boolean;

  constructor(key: IKey) {
    this.name = key.name;
    this.dependency = key.parent;
  }
}

export interface IInputTable {
  columns: Array<string>;
  dataSource: Array<ITableRow>;
  aside: Array<IAsideCell>;
}

export class Unit implements IUnitOption {
  public scale: number;

  public constructor(public name: string, public id: string, private factorFromBackend: number) {
    this.scale = factorFromBackend;
  }

  public fromBackendToUnit(value: number): number {
    return value * this.factorFromBackend;
  }

  public toBackendFromUnit(value: number): number {
    return value / this.factorFromBackend;
  }
}

export interface IDataInputTable extends IInputTable {
  units: Unit[];
}

export class DataInputTable {
  public static toDataSource(data: Array<IResponseItem>, keys: Array<any>, yearRange: Array<number>): IInputTable {
    const _data = yearRange.map((year) => {
      const foundItem = data.find((item) => item.year === year);

      return foundItem || { year };
    });

    // Sort response data
    _data.sort((curr, next) => curr.year - next.year);

    // Transpose and formation data in supported format
    const dataSource = this.getTransposedData(_data, keys);
    const columns = _data.map((item) => String(item.year));
    const aside = this.getAsideCells(keys);

    return {
      columns,
      dataSource,
      aside
    };
  }

  private static getTransposedData(data: Array<IResponseItem>, keys: Array<IKey>): Array<ITableRow> {
    return keys.reduce((acc, key) => {
      const rowObject = data.reduce((nestedAcc, item) => {
        const id = item.year;

        return {
          ...nestedAcc,
          [id]: new Cell(key, item)
        };
      }, {});

      return [...acc, rowObject];
    }, []);
  }

  private static getAsideCells(keys: Array<IKey>): Array<IAsideCell> {
    return keys.map((key) => new AsideCell(key));
  }
}
