import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneFooterComponent } from "./components/footer.component";
import { EneFooterLeftDirective } from "./directives/footer-left.directive";
import { EneFooterRightDirective } from "./directives/footer-right.directive";
import { EneFooterCenterDirective } from "./directives/footer-center.directive";

@NgModule({
  declarations: [EneFooterComponent, EneFooterLeftDirective, EneFooterRightDirective, EneFooterCenterDirective],
  imports: [CommonModule],
  exports: [EneFooterComponent, EneFooterLeftDirective, EneFooterRightDirective, EneFooterCenterDirective]
})
export class EneFooterModule {}
