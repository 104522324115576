import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { of, Observable, EMPTY, throwError } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { EneSnackbarService } from "@energy-city/ui/snackbar";
import { Action } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";
import {
  DataInputActionTypes,
  GetTableData,
  GetTableDataError,
  GetTableDataSuccess,
  ICategory,
  PatchTableData,
  UpdateTableData
} from "./data-input.actions";
import { AccountingMethodService, acceptJsonWithoutCache, environment } from "@energy-city/components";
import { IDataInputDto } from "../../interfaces/data-input-dto";
import { TranslateService } from "@ngx-translate/core";

interface IApiMap {
  [key: string]: string;
}

export const DATA_INPUT_API_MAP: IApiMap = {
  afolu: environment.emission.calculationApi,
  ippu: environment.emission.calculationApi,
  waste: environment.emission.calculationApi,
  "stationary-energy": `${environment.emission.calculationApi}/publicsector`,
  transportation: `${environment.emission.calculationApi}/publicsector`
};

@Injectable()
export class DataInputEffects {
  @Effect()
  public getTableData$: Observable<Action> = this.actions$.pipe(
    ofType(DataInputActionTypes.GET_TABLE_DATA),
    switchMap((action: GetTableData) => {
      const { category, nestedCategory } = action.props;
      const api: string = DATA_INPUT_API_MAP[category];

      if (!api) {
        return of(new GetTableDataError("DATA_PANEL.INVALID_CATEGORY"));
      }

      const url: string = `${api}/data-input/regions/${action.regionId}/sectors/${category}/${nestedCategory}`;
      const headers = new HttpHeaders(acceptJsonWithoutCache);

      return this.http
        .get<{ result: IDataInputDto }>(url, { headers, observe: "response" })
        .pipe(
          map(
            (response) =>
              response.status === 200
                ? new GetTableDataSuccess(response.body.result)
                : new GetTableDataError("DATA_PANEL.NO_DATA") // status 204 means "no data"
          ),
          catchError(() => of(new GetTableDataError("DATA_PANEL.LOAD_ERROR")))
        );
    })
  );

  @Effect()
  public patchTableData$: Observable<any> = this.actions$.pipe(
    ofType(DataInputActionTypes.PATCH_TABLE_DATA),
    switchMap((action: PatchTableData) => this.sendUpdate("PATCH", action.regionId, action.props, action.payload))
  );

  @Effect()
  public updateTableData$: Observable<any> = this.actions$.pipe(
    ofType(DataInputActionTypes.UPDATE_TABLE_DATA),
    switchMap((action: UpdateTableData) => this.sendUpdate("PUT", action.regionId, action.props, action.payload))
  );

  private sendUpdate(method: "PUT" | "PATCH", regionId: string, props: ICategory, payload: IDataInputDto) {
    const { category, nestedCategory } = props;
    const api: string = DATA_INPUT_API_MAP[category];

    if (!api) {
      return throwError(new Error("invalid category was selected"));
    }

    const url = `${api}/data-input/regions/${regionId}/sectors/${category}/${nestedCategory}`;
    const headers = new HttpHeaders(acceptJsonWithoutCache);

    return this.http.request(method, url, { headers, body: payload }).pipe(
      map(() => {
        this.snackbar.success(this.translate.instant("DATA_PANEL.SNACKBAR.UPDATE_SUCCESS"));
        return new GetTableData(regionId, { category, nestedCategory });
      }),
      catchError((error: Error) => {
        console.error(error.message);
        this.snackbar.error(this.translate.instant("DATA_PANEL.SNACKBAR.UPDATE_ERROR"));
        return EMPTY;
      })
    );
  }

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private snackbar: EneSnackbarService,
    private translate: TranslateService
  ) {}
}
