import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { UtilService } from "../util.service";
import { ModulesService } from "../modules.service";
import { IGetReportScenarioSuccess, IGetScenariosSuccess, ISimulationScenario } from "../../models/simulation-model";

@Injectable()
export class SimulationService {
  constructor(private http: HttpClient, private utilService: UtilService, private modulesService: ModulesService) {}

  public getReportScenario(): Observable<IGetReportScenarioSuccess> {
    return this.http.get<IGetReportScenarioSuccess>(`${environment.simulation}/report/scenario/default`);
  }
  public getScenarios(): Observable<IGetScenariosSuccess> {
    return this.http.get<IGetScenariosSuccess>(
      `${environment.simulation}/regions/${this.utilService.getRegionId()}/scenarios`
    );
  }

  public addScenario(scenario: ISimulationScenario): Observable<any> {
    return this.http.put(`${environment.simulation}/regions/${this.utilService.getRegionId()}/scenarios`, scenario);
  }

  public updateScenario(scenario: ISimulationScenario): Observable<any> {
    return this.http.put(`${environment.simulation}/regions/${this.utilService.getRegionId()}/scenarios`, scenario, {
      params: {
        scenarioId: scenario.scenarioId
      }
    });
  }

  public deleteScenario(scenarioId: string): Observable<any> {
    return this.http.delete(`${environment.simulation}/regions/${this.utilService.getRegionId()}/scenarios`, {
      params: {
        scenarioId
      }
    });
  }
}
