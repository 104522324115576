<div class="contacts">
  <div class="contacts_body">
    <p [innerHTML]="'APP.CONTACT_INFO' | translate | trustHtml"></p>
    <div class="info">
      <p>{{ 'APP.HAVE_QUESTIONS' | translate }}</p>
    </div>
  </div>

  <div class="contact_persons">
    <app-contact-person [person]="contactInfo"></app-contact-person>
  </div>

  <div class="contacts_footer">
    <button class="close" (click)="close()">{{ 'APP.CLOSE' | translate }}</button>
  </div>
</div>
