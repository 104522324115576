import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewEncapsulation,
  Input,
  OnDestroy
} from "@angular/core";
import { Subject, combineLatest } from "rxjs";
import { takeUntil, filter, map, tap, startWith } from "rxjs/operators";
import { mapValues, get } from "lodash";
import {
  IKpis,
  IStatesMap,
  States,
  IMapping,
  IPending,
  IValues,
  INotAvailableText,
  KpiOptions
} from "../interfaces/kpis";
import { EneKpisV2Service } from "../services/kpis.service";
import { TranslateService } from "@ngx-translate/core";
import { UnitConfig } from "@enersis/gp-components/gp-kpi";

@Component({
  selector: "ene-kpis-v2",
  templateUrl: "./kpis.component.html",
  styleUrls: ["./kpis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-kpis"
  }
})
export class EneKpisV2Component implements OnInit, OnDestroy {
  public _items: Array<IKpis>;
  public statesMap: IStatesMap;
  public itemsMap: IValues = {};
  public iconsMap: IValues = {};
  public locale: string;
  private itemsConfigs: Array<IKpis>;
  private destroy$ = new Subject();

  @Input() public namespace: string;
  @Input() public weatherCorrection: boolean;

  @Input()
  public set items(list: Array<IKpis>) {
    list = list || [];
    this.itemsConfigs = list;
    this._items = this.getFilledValues(this.itemsConfigs);
    this.statesMap = this.getStatesMap(this.itemsConfigs);
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private eneKpisService: EneKpisV2Service,
    private translate: TranslateService
  ) {}

  public ngOnInit() {
    this.handlePending();
    this.handleValuesAndDescriptionIcons();
    this.handleIcons();
    this.locale = this.translate.currentLang;

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(({ lang }) => (this.locale = lang));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public trackByFn(index: number, value: KpiOptions): string {
    return value.id;
  }

  public hasWeatherCorrection(subItem: KpiOptions): boolean {
    return this.weatherCorrection && subItem.describing_icons?.some((icon) => icon.name === "ec_witterungskorrektur");
  }

  public getTranslatedUnitConfig(unitConfig: UnitConfig): UnitConfig {
    // extract and translate translation strings from unitSuffix
    // (like "CO2e APP.PER_CAPITA" => "CO2e pro Kopf")
    const unitSuffix = unitConfig.unitSuffix?.replace(/(\w+(\.\w)*)+/, (substring) =>
      this.translate.instant(substring)
    );

    return {
      ...unitConfig,
      unitSuffix
    };
  }

  private getFilledValues(list: Array<IKpis> = [], values?: IValues): Array<IKpis> {
    const _values = values || {};

    const kpis: Array<IKpis> = list.reduce((acc, item: IKpis) => {
      const items: Array<KpiOptions> = item.items.map((insertItem: KpiOptions) => {
        const value = get(_values, insertItem.mapping);
        const disabled = insertItem.disabled || value === "disabled";
        return {
          ...insertItem,
          disabled,
          value: disabled ? undefined : value
        };
      });
      return [...acc, { ...item, items }];
    }, [] as Array<IKpis>);
    return kpis;
  }

  private getStatesMap(list: Array<IKpis> = []): IStatesMap {
    return list.reduce((acc, { id }) => ({ ...acc, [id]: States.PENDING }), {});
  }

  private updateState(state: any): void {
    this.statesMap = mapValues(this.statesMap, () => state);
  }

  private filterNamespace({ namespace }: IMapping | IPending | INotAvailableText): boolean {
    return !namespace || namespace === this.namespace;
  }

  private handleIcons(): void {
    this.eneKpisService.icons$
      .pipe(
        filter((value) => this.filterNamespace(value)),
        map(({ values }) => values),
        tap((values) => {
          this.iconsMap = values;
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private handlePending(): void {
    this.eneKpisService.pending$
      .pipe(
        filter((value) => this.filterNamespace(value)),
        tap(() => {
          this.updateState(States.PENDING);
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private handleValuesAndDescriptionIcons(): void {
    combineLatest([
      this.eneKpisService.values$.pipe(
        filter((value) => this.filterNamespace(value)),
        map(({ values }) => values)
      ),
      this.eneKpisService.describingIcons$.pipe(startWith(null))
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([values, changeIcon]) => {
        this._items = this.getFilledValues(this.itemsConfigs, values);
        if (changeIcon) {
          for (const kpis of this._items) {
            for (const kpi of kpis.items) {
              if (kpi.describing_icons) {
                for (const icon of kpi.describing_icons) {
                  if (icon && icon.name === changeIcon.name) {
                    icon.active = changeIcon.active;
                  }
                }
              }
            }
          }
        }
        this.updateState(States.RESOLVE);
        this.cdr.markForCheck();
      });
  }
}
