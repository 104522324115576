import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../modules/authentication/services/exported/authentication.service";

@Injectable({
  providedIn: "root"
})
export class TenantInfoService {
  private baseDefault = `CLIENTS.DEFAULT`;

  constructor(private translate: TranslateService, private authService: AuthenticationService) {}

  public getTenantLogo(tenant: string): string {
    switch (tenant) {
      case "KLIMA-NAVI-PARCHIM":
      case "HANSEWERK":
        return "HW-Logo";
      case "DENA":
        return "dena";
      case "HOF":
        return "Hof-Logo";
      case "ENERSIS-CO2":
        return "co2balance";
      case "GREENITED":
        return "greenited";
      case "TENANT-0002":
        return "e360-co2balance";
      default:
        return "co2balance";
    }
  }

  public getTranslation(path: string): string {
    const translationKey = `CLIENTS.${this.authService.tokenParsed.tenant.toUpperCase()}.${path}`;
    const translation = this.translate.instant(translationKey);

    if (translationKey === translation) {
      return this.translate.instant(`${this.baseDefault}.${path}`);
    }

    return translation;
  }

  public getTenant(): string {
    return this.authService.tokenParsed.tenant;
  }
}
