export class Difference {
  constructor(public value: number, public reference: number) {}

  public getRelativeDifferencePercent(): number {
    return this.getAbsoluteDifference() / this.reference * 100;
  }

  public getAbsoluteDifference(): number {
    return this.value - this.reference;
  }
}
