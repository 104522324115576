import { Component, Input, ElementRef, ContentChildren, QueryList } from "@angular/core";
import { TabsContainerComponent } from "../tabs/tabs.component";

@Component({
  selector: "ene-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"],
  host: {
    "[class.hidden]": "!active"
  }
})
export class TabComponent {
  public elementRef: ElementRef;

  @ContentChildren("process")
  public elementsToProcess: QueryList<ElementRef>;

  @Input() public active: boolean;
  @Input() public tabName: string;
  @Input() public tabTitle: string;
  @Input() public icon: string | boolean = false;
  @Input() public id?: number;
  @Input() public disabled?: boolean = false;
  @Input() public hidden?: boolean = false;

  constructor(tabs: TabsContainerComponent) {
    tabs.addTab(this);
  }

  public getTabTitle() {
    return this.tabTitle;
  }

  public getTabName() {
    return this.tabName;
  }
}
