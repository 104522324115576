<ene-tabs #tabs styling="topics" mode="horizontal">
  <ene-tab
    *ngFor="let tab of tabsConfig; let i = index; trackBy: tracker"
    [tabTitle]="tab.title | translate"
    [active]="i === 0"
    [disabled]="tab.disabled"
    [hidden]="tab.hidden"
  >
    <ng-container *ngIf="tabs.tabs[i].active">
      <ng-container *ngComponentOutlet="entryComponents[tab.component]"></ng-container>
    </ng-container>
  </ene-tab>
</ene-tabs>
