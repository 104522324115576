export class TokenFormatter {
  /**
   * accepts a decoded token and changes properties so the rest of the application can stay same
   * The changes are only made, if the token is for the tenant Hansewerk
   * @param token decoded token to be "formatted"
   */
  public static format(token: any): any {
    if (token.hasOwnProperty("tenant")) {
      token.clientId = token.tenant;
      token.timelineRange = [token.timelineFrom, token.timelineTo];
      token.municipality_name = [token.mainRegion.name];
    }
    return token;
  }
}
