import { Difference } from "../difference/difference";
import { LinearTrend } from "./linear-trend";

export interface YearlyValue {
  year: number;
  value: number;
}

export interface YearlyValueRelative extends YearlyValue {
  relativeValue: number;
}

export class TimeSeries {
  constructor(public readonly data: Array<YearlyValue>, public readonly name: string) {}

  /**
   * getForecast - get forecast using Holt's linear trend
   * @param maxYear max year to be forecasted
   * @returns TimeSeries -- a time series containing the forecasted values
   */
  getForecast(maxYear: number): TimeSeries {
    const model = new LinearTrend(this.data);
    return new TimeSeries(model.getForecast(maxYear), "forecast");
  }

  /**
   * addRelativeDifference calculates the difference of each value in TimeSeries against reference
   * @param reference number
   * @returns Array<YearlyValueRelative> -- an array of objects containing the year,
   * the absolute value and the relative value in percent
   */
  decorateRelativeDifference(reference: number): Array<YearlyValueRelative> {
    return this.data.map((dataEntry) => ({
      value: dataEntry.value,
      year: dataEntry.year,
      relativeValue: new Difference(dataEntry.value, reference).getRelativeDifferencePercent()
    }));
  }

  /**
   * subtract Subtracts this object data from supplied object and returns a new TimeSeries Object
   * @param SubtractTimeSeries TimeSeries
   * @returns TimeSeries -- a new TimeSeries object with the subtracted values for each year
   */
  subtract(subtractTimeSeries: TimeSeries): TimeSeries {
    const associatedYears = new Set([...this.getYears(), ...subtractTimeSeries.getYears()].sort());
    const subtractedTimeSeries = new TimeSeries([], `${this.name} - ${subtractTimeSeries.name}`);
    for (const year of associatedYears) {
      subtractedTimeSeries.data.push({
        year: year,
        value: (this.getValueOfYear(year) || 0) - (subtractTimeSeries.getValueOfYear(year) || 0)
      });
    }
    return subtractedTimeSeries;
  }

  add(addTimeSeries: TimeSeries): TimeSeries {
    const associatedYears = new Set([...this.getYears(), ...addTimeSeries.getYears()].sort());
    const addedTimeSeries = new TimeSeries([], `${this.name} + ${addTimeSeries.name}`);
    for (const year of associatedYears) {
      addedTimeSeries.data.push({
        year: year,
        value: (this.getValueOfYear(year) || 0) + (addTimeSeries.getValueOfYear(year) || 0)
      });
    }
    return addedTimeSeries;
  }

  hasDataForEveryYear(years: Array<number>): boolean {
    let result = true;
    for (const year of years) {
      const dataForYear = this.data.find((dataEntry) => dataEntry.year === year);
      if (!dataForYear) {
        result = false;
      }
    }
    return result;
  }

  getValueOfYear(year: number): number | undefined {
    return this.data.find((entry) => entry.year === year)?.value;
  }

  private getYears(): Array<number> {
    return this.data.map((entry) => entry.year);
  }
}
