<header>
  <div class="tabs-container">
    <div class="parent-tabs">
      <div
        *ngFor="let tab of tabs; let i = index; trackBy: tracker"
        class="tab parent-tab"
        [class.active]="tab.active"
        (click)="switchParentTab(tab.name, i)"
      >
        <span class="title" [innerHTML]="'cockpit.co2.tabs.' + tab.name + '.title' | uppercase | translate"></span>
      </div>
    </div>
    <div class="child-tabs" *ngIf="activeParentTab && activeParentTab.tabs">
      <div class="offset">
        <div
          *ngFor="let childTab of activeParentTab.tabs; let i = index"
          class="tab child-tab"
          (click)="switchChildTab(i)"
          [class.active]="childTab.active"
        >
          <span
            class="title"
            *ngIf="childTab.name"
            [innerHTML]="
              'cockpit.co2.tabs.' + activeParentTab.name + '.tabs.' + childTab.name + '.title' | uppercase | translate
            "
          ></span>
        </div>
      </div>
    </div>
  </div>
</header>
<main *ngIf="activeChildTab">
  <section class="chart-container">
    <ng-container *ngComponentOutlet="activeChildTab.chart?.component"></ng-container>
  </section>
  <section class="kpi-container" [ngClass]="{ empty: !activeChildTab.kpi }">
    <ene-kpis-v2
      #eneKpis
      [namespace]="ns"
      [items]="activeChildTab.kpi"
      [weatherCorrection]="weatherCorrection$ | async"
    ></ene-kpis-v2>
  </section>
  <section class="description-container" *ngIf="activeChildTab.description">
    <article class="description-container__article">
      <p
        class="description-container__article__introduction"
        [innerHTML]="
          activeChildTab.description + '.' + accountingMethodService.selectedAccountingMethod$.value | translate
        "
      ></p>
      <p
        class="description-container__article__introduction"
        *ngIf="getAverageAndDifferenceTranslationParams() as translationParams"
      >
        {{ "COCKPIT.CO2.NOTICE_AVERAGE_AND_DIFFERENCE" | translate: translationParams }}
      </p>
      <ng-container *ngIf="accountingMethodService.selectedAccountingMethod$.value === AccountingMethod.BISKO">
        <p class="description-container__article__notice">{{ "COCKPIT.CO2.NOTICE" | translate | uppercase }}</p>
        <p class="description-container__article__notice-body">{{ "COCKPIT.CO2.NOTICE_BISKO" | translate }}</p>
      </ng-container>
    </article>
  </section>
</main>
