import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { IEnvDto, PLATFORM_ENV_FILE } from "@co2-shared/common";
import { environment } from "@energy-city/environments";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { FactorEndpointConf, FactorEndpointId, IFactorResponseDtoMap } from "./factor-management.endpoint-config";
import { TableData } from "./table/table.interface";

interface IFactorResponse<T> {
  result: T;
}

@Injectable({ providedIn: "root" })
export class FactorManagementService {
  public tableDataChanges$ = new BehaviorSubject<TableData>(null);
  public isEditing$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, @Optional() @Inject(PLATFORM_ENV_FILE) protected platformEnvFile: IEnvDto) {}

  public getFactors<T extends FactorEndpointId, V extends IFactorResponseDtoMap[T]>(
    endpointId: T,
    regionId: string
  ): Observable<IFactorResponse<V>> {
    const url = `${environment.regionPropertyApi}/regions/${regionId}/${FactorEndpointConf[endpointId].url}`;
    return this.http.get<IFactorResponse<V>>(url).pipe(
      catchError(() => {
        console.error("Error querying emissions from BE");
        return of({ result: undefined });
      })
    );
  }

  public patchFactors<T extends FactorEndpointId>(
    endpointId: T,
    regionId: string,
    factors: IFactorResponseDtoMap[T]
  ): Observable<Object> {
    const url = `${environment.regionPropertyApi}/regions/${regionId}/${FactorEndpointConf[endpointId].url}`;
    return this.http.post(url, factors).pipe(
      catchError((error) => {
        console.error("Error updating emissions from BE");
        return of({ result: undefined, isError: true, error });
      })
    );
  }

  public getTenantCountry(): "de" | "ch" {
    return this.platformEnvFile?.country;
  }

  public showFactorManagement(): boolean {
    return (
      this.getTenantCountry() === "de" && Boolean(this.platformEnvFile?.co2balance?.featureFlags?.factorManagement)
    );
  }
}
