import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DqiViewComponent } from "./dqi-view.component";
import { EneTooltipModule } from "@energy-city/ui/tooltip";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [DqiViewComponent],
  imports: [CommonModule, EneTooltipModule, TranslateModule],
  exports: [DqiViewComponent]
})
export class DqiViewModule {}
