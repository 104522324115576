import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Co2CockpitChartsModule } from "../../../modules/co2-cockpit/charts/co2-cockpit-charts.module";
import { BenchmarkingPanelComponent } from "./benchmarking.component";
import { TranslateModule } from "@ngx-translate/core";
import { EneKpisV2Module } from "@energy-city/ui/kpis-v2";

@NgModule({
  declarations: [BenchmarkingPanelComponent],
  imports: [CommonModule, Co2CockpitChartsModule, TranslateModule, EneKpisV2Module],
  exports: [BenchmarkingPanelComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BenchmarkingModule {}
