import { Component } from "@angular/core";
import { Observable, Subscription } from "rxjs";

export interface ILanguage {
  name: string;
  code: string;
}

@Component({
  template: ""
})
export class BasicAppComponent {
  public contactModalType;

  /* ngrx */
  protected authState: any;
  protected auth$: Observable<any>;
  protected authSubscription: Subscription;
  protected app$: Observable<any>;
  protected appSubscription: Subscription;
}
