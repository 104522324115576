import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { IUnitOption } from "../../../../configs/data-panel";
import { select, Store } from "@ngrx/store";
import * as fromDataPanel from "../../state";
import { CsvTransformerService } from "./csv-transformer.service";
import * as Big from "big.js";
import {
  AccountingMethodService,
  IDataInputTable,
  IInputTable,
  IKey,
  IRegionIdentifier,
  ITableCell,
  ITableRow,
  UtilService
} from "@energy-city/components";
import { IDataInputSector } from "../../../../services/region-property/interfaces/region-property-data-input.interface";
import { range } from "lodash";
import {
  RegionPropertyDataInputService,
  Result
} from "../../../../services/region-property/region-property-data-input.service";
import { DataInputServiceGenericCore } from "./data-input-service-generic.core";
import { MainTableCategories } from "../../../../../../../../shared/src/lib/data-input/model/main-table-categories.enum";
import { notification } from "@energy-city/ui/notification";

@Injectable()
export class DataInputService {
  private readonly genericCore: DataInputServiceGenericCore;

  constructor(
    private store: Store<fromDataPanel.DataPanelState>,
    private utilService: UtilService,
    private csvTransformer: CsvTransformerService,
    private regionPropDataInputService: RegionPropertyDataInputService,
    private accountingMethodService: AccountingMethodService,
    private translate: TranslateService
  ) {
    this.genericCore = new DataInputServiceGenericCore(this.regionPropDataInputService);
  }

  private set category(value: MainTableCategories) {
    this.activeCategory = value;
  }

  private set nestedCategory(value: string) {
    this.activeNestedCategory = value;
  }

  private get yearRange(): Array<number> {
    return range(this.utilService.timelineFirstYear, this.utilService.timelineLastYear + 1);
  }

  private activeCategory: MainTableCategories;
  private activeNestedCategory: string;

  public setCategoryConfig(config: IDataInputSector) {
    this.genericCore.setCategoryConfig(config);
  }

  public async save(): Promise<void> {
    const category = this.activeCategory;
    const nestedCategory = this.activeNestedCategory;

    const regionIdentifier: IRegionIdentifier = this.utilService.getRegionIdentifier();

    const result = await this.genericCore.save(regionIdentifier, category, nestedCategory);
    if (result === Result.success) {
      // TODO: check if this is the right place for this
      this.accountingMethodService.forceReload();
      notification.success(this.translate.instant("DATA_PANEL.SNACKBAR.UPDATE_SUCCESS"));
    }
  }

  public export(): void {
    const [data, unit]: [IInputTable, string] = this.genericCore.export(
      this.activeCategory,
      this.activeNestedCategory,
      this.yearRange
    );
    const translatedUnit = this.translate.instant(unit);

    if (!data.columns) {
      notification.warning(this.translate.instant("DATA_PANEL.SNACKBAR.NO_DATA_TO_EXPORT"));
      return;
    }

    const csvContent = this.csvTransformer.prepareForExport(data, translatedUnit);
    const filename = `export_${this.activeCategory}-${this.activeNestedCategory}_${translatedUnit}.csv`;
    this.csvTransformer.downloadAsFile(csvContent, filename);
  }

  public async import(file: any) {
    const regionId = this.utilService.getRegionIdentifier();
    try {
      const keys: IKey[] = this.genericCore.getKeys(this.activeCategory, this.activeNestedCategory);
      const payload = await this.csvTransformer.importFromCsv(file, keys);

      const result = await this.genericCore.import(payload, regionId, this.activeCategory, this.activeNestedCategory);
      if (result === Result.success) {
        // TODO: check if this is the right place for this
        this.accountingMethodService.forceReload();
        notification.success(this.translate.instant("DATA_PANEL.SNACKBAR.UPDATE_SUCCESS"));
      } else if (result === Result.failureRowMissing) {
        notification.warning(this.translate.instant("DATA_PANEL.INPUT_DATA.MODAL.ERROR.STRUCTURE_SECTOR"));
      } else {
        notification.warning(this.translate.instant("DATA_PANEL.SNACKBAR.UPDATE_ERROR"));
      }
    } catch (error) {
      notification.warning(error.message);
    }
  }

  public loadData(category: MainTableCategories, nestedCategory: string): void {
    // To be sure that regionId exist!
    this.category = category;
    this.nestedCategory = nestedCategory;

    this.genericCore.loadData(category, nestedCategory);
  }

  public selectUnit(): Observable<IUnitOption> {
    return this.store.pipe(select(fromDataPanel.getUnit));
  }

  public selectDataSource(): Observable<IDataInputTable> {
    return this.utilService.regionIdentifier$.pipe(
      switchMap((regionIdentifier) => this.genericCore.selectDataSource(regionIdentifier, this.yearRange))
    );
  }

  public convertDataByUnit(dataSource: Array<ITableRow>, scale: number): any {
    return this.getFormattedByUnit(dataSource, scale);
  }

  public updateTableById(data: Partial<ITableCell>): void {
    this.genericCore.updateTableById(data);
  }

  public selectLockedState(): Observable<boolean> {
    return this.store.pipe(select(fromDataPanel.getLockedState));
  }

  public selectLoadingState(): Observable<boolean> {
    return this.genericCore.selectLoadingState();
  }

  public selectErrorMessage(): Observable<string> {
    return this.store.pipe(select(fromDataPanel.getErrorMessage));
  }

  private getFormattedByUnit(dataSource: Array<any>, scale: number): any {
    return dataSource.map((item) => {
      return Object.keys(item).reduce((acc, key) => {
        if (!item[key].value) {
          return { ...acc, [key]: item[key] };
        }
        const value = +String(new Big(+item[key].value).times(scale));
        return {
          ...acc,
          [key]: {
            ...item[key],
            value
          }
        };
      }, {});
    });
  }

  public setActiveUnit(selectedUnit: IUnitOption) {
    this.genericCore.setActiveUnit(selectedUnit);
  }
}
