import { SimulationActionTypes, SimulationActions } from "./simulation-actions";
import { ISimulationScenario } from "../../models/simulation-model";

export interface ISimulationState {
  visible: boolean;
  currentScenarioId: string;
  scenarios: ISimulationScenario[];
  measurments: {};
  measurment: string;
  chartData: any;
  error: any;
}

export const initialState: ISimulationState = {
  scenarios: null,
  measurments: null,
  visible: false,
  currentScenarioId: null,
  measurment: null,
  chartData: null,
  error: null
};

export function reducer(state: ISimulationState = initialState, action: SimulationActions): ISimulationState {
  switch (action.type) {
    case SimulationActionTypes.GET_SCENARIOS_SUCCESS:
      return {
        ...state,
        scenarios: action.payload.scenarios,
        currentScenarioId: action.payload.scenarios[0].scenarioId
      };
    case SimulationActionTypes.GET_SCENARIOS_FAILED:
      return {
        ...state,
        scenarios: []
      };
    case SimulationActionTypes.UPDATE_SCENARIO_FAILED:
    case SimulationActionTypes.DELETE_SCENARIO_FAILED:
    case SimulationActionTypes.ADD_SCENARIO_FAILED:
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
}
