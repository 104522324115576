<div class="factor-management">
  <div class="factor-management__controls">
    <mat-slide-toggle *ngIf="showCustom$ | async" [formControl]="showBaseDataControl">
      {{ "DATA_PANEL.FACTOR_MANAGEMENT.SHOW_BASE_DATA" | translate }}
    </mat-slide-toggle>
  </div>

  <div class="factor-management__navigation">
    <app-navigation (onCategoryChange)="changeCategory($event)" (onSaveChange)="save()" (onResetChanges)="resetChanges()"></app-navigation>
  </div>
  <app-table *ngIf="vm$ | async as vm" [columnsConfig]="vm.config" [data]="vm.data"></app-table>
</div>
