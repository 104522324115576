import { Injectable } from "@angular/core";
import { InternalAuthenticationService } from "../internal/internal.authentication.service";
import { IAuthenticationObject, IConnect } from "../../core/global.interface";
import { TokenFormatter } from "../../core/classes/tokenFormatter";

export interface IAuthenticationService {
  roles: any;
  tokenParsed: any;
  accessToken: string;
  loggedIn: boolean;
}
@Injectable({
  providedIn: "root"
})
export class AuthenticationService implements IAuthenticationService {
  private noIdpHint = Boolean(new URLSearchParams(window.location.search).get("noIdpHint"));
  private idp_hint = new URLSearchParams(window.location.search).get("idp_hint");

  constructor(private internalAuthService: InternalAuthenticationService) {}

  public get roles(): any {
    try {
      return this.tokenParsed.realm_access && this.tokenParsed.realm_access.roles
        ? this.tokenParsed.realm_access.roles
        : [];
    } catch (e) {
      throw e;
    }
  }

  public get authenticationObject(): IAuthenticationObject {
    try {
      return JSON.parse(localStorage.getItem("authenticationObject"));
    } catch (e) {
      throw e;
    }
  }

  public get tokenParsed(): any {
    try {
      // FIXME: since hansewerk has a different structures in the token, we do a conversion here
      // the same conversion is also done in loginclass
      // we should rather change the way how we access the data and what we really read from it, this is a shortcut implemented for the sole purpose of fast onboarding
      return TokenFormatter.format(this.authenticationObject.accessTokenDecoded);
    } catch (e) {
      throw e;
    }
  }

  public get accessToken(): string {
    try {
      return this.authenticationObject.accessToken;
    } catch (e) {
      throw e;
    }
  }

  public get loggedIn(): boolean {
    try {
      return this.internalAuthService.loggedIn;
    } catch (e) {
      return false;
    }
  }

  public connect(): IConnect {
    return {
      login: () => this.internalAuthService._login({ noIdpHint: this.noIdpHint, idp_hint: this.idp_hint }),
      logout: () => this.internalAuthService._logout()
    };
  }
}
