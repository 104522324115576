import { Component, Input, OnInit } from "@angular/core";
import { EneModalService } from "@energy-city/ui/modal";
import { IContactsInfo, Person } from "../contacts.model";

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts-component.html",
  styleUrls: ["./contacts-component.scss"]
})
export class ContactsComponent {
  @Input() public contactInfo: Person;

  constructor(private modalService: EneModalService) {}

  public close(): void {
    this.modalService.close("contact");
  }
}
