<label class="title">
  <ng-content></ng-content>
</label>
<span *ngIf="showText" class="false" (click)="onItemClick(false)">
  {{ text.false }}
</span>
<div class="toggle-container" (click)="onItemClick()">
  <div class="toggle-button"></div>
</div>
<span *ngIf="showText" class="true" (click)="onItemClick(true)">
  {{ text.true }}
</span>
