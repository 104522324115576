import { Action } from "@ngrx/store";

export enum MeasuresActionTypes {
  EDIT_MEASURE = "[MEASURES] EDIT_MEASURE",
  CREATE_MEASURE = "[MEASURES] CREATE_MEASURE",
  GET_MEASURES = "[MEASURES] GET_MEASURES",
  GET_MEASURES_SUCCESS = "[MEASURES] GET_MEASURES_SUCCESS",
  GET_MEASURES_ERROR = "[MEASURES] GET_MEASURES_ERROR",
  SAVE_MEASURE_SUCCESS = "[MEASURES] SAVE_MEASURE_SUCCESS",
  SAVE_MEASURE_ERROR = "[MEASURES] SAVE_MEASURE_ERROR",
  SET_EDITING_MEASURE = "[MEASURES] SET_EDITING_MEASURE",
  CLEAR_EDITING_MEASURE = "[MEASURES] CLEAR_EDITING_MEASURE",
  DELETE_MEASURE = "[MEASURES] DELETE_MEASURE",
  DELETE_MEASURE_SUCCESS = "[MEASURES] DELETE_MEASURE_SUCCESS",
  DELETE_MEASURE_ERROR = "[MEASURES] DELETE_MEASURE_ERROR"
}

export class GetMeasures implements Action {
  public readonly type = MeasuresActionTypes.GET_MEASURES;
  constructor(public setId: string, public regionId: string) {}
}

export class GetMeasuresSuccess implements Action {
  public readonly type = MeasuresActionTypes.GET_MEASURES_SUCCESS;
  constructor(public payload: any) {}
}

export class GetMeasuresError implements Action {
  public readonly type = MeasuresActionTypes.GET_MEASURES_ERROR;
  constructor(public payload: any) {}
}

export class SetEditingMeasure implements Action {
  public readonly type = MeasuresActionTypes.SET_EDITING_MEASURE;
  constructor(public measure: any) {}
}

export class EditMeasure implements Action {
  public readonly type = MeasuresActionTypes.EDIT_MEASURE;
  constructor(public measure: any, public setId, public regionId) {}
}

export class SaveMeasureSuccess implements Action {
  public readonly type = MeasuresActionTypes.SAVE_MEASURE_SUCCESS;
}
export class SaveMeasureError implements Action {
  public readonly type = MeasuresActionTypes.SAVE_MEASURE_ERROR;
  constructor(public payload: any) {}
}

export class CreateMeasure implements Action {
  public readonly type = MeasuresActionTypes.CREATE_MEASURE;
  constructor(public measure: any, public setId, public regionId) {}
}

export class DeleteMeasure implements Action {
  public readonly type = MeasuresActionTypes.DELETE_MEASURE;
  constructor(public setId, public measureId: any, public regionId) {}
}

export type MeasuresActions =
  | GetMeasures
  | GetMeasuresSuccess
  | GetMeasuresError
  | SaveMeasureError
  | SaveMeasureSuccess
  | SetEditingMeasure;
