<section ene-sidenav-start>
  <section class="content">
    <ngu-carousel #carousel [inputs]="carouselConfig" [dataSource]="sets" (onMove)="onMove($event)">
      <ngu-item *nguCarouselDef="let set; index as i">
        <form [formGroup]="measureSetFormGroup" *ngIf="!isReadMode(set)">
          <div class="ngu-header edit">
            <div class="ngu-header-info" *ngIf="!isCreateMode()">
              <span class="ngu-header-info-counter">{{ i + 2 }}</span>
            </div>
            <div class="ngu-header-edit_title">
              <mat-form-field>
                <input
                  matInput
                  [placeholder]="'MEASURE.CONTROLS.NEW_MEASURE_SET_TITLE' | translate"
                  formControlName="title"
                  autocomplete="off"
                  required
                />
              </mat-form-field>
            </div>
          </div>
          <article class="ngu-item_measure-description edit">
            <mat-form-field>
              <input
                matInput
                [placeholder]="'MEASURE.CONTROLS.NEW_MEASURE_SET_DESCRIPTION' | translate"
                formControlName="description"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </article>
        </form>
        <ng-container *ngIf="isReadMode(set)">
          <div class="ngu-header">
            <div class="ngu-header-info">
              <span class="ngu-header-info-counter">{{ i + 1 }}/{{ sets.length }}</span>
              <span class="ngu-header-info-title">{{ set.title | translate }}</span>
              <span class="ngu-header-info-type" *ngIf="set.default">{{
                "MEASURE.MEASURE_SET_STANDARD" | translate
              }}</span>
            </div>
            <div class="ngu-header-options">
              <div class="option" [class.disabled]="set.default" (click)="onEditSet(set)">
                <ene-icon icon="ec_edit"></ene-icon>
              </div>
              <div class="option" [class.disabled]="set.default" (click)="onDeleteSet(set, i)">
                <ene-icon icon="ec_trash"></ene-icon>
              </div>
            </div>
          </div>
          <article class="ngu-item_measure-description">
            <span>{{ set.description }}</span>
          </article>
        </ng-container>
        <div class="creation-set-info" *ngIf="isCreateMode()">
          <ene-icon icon="ec_info"></ene-icon>
          <div>{{ "MEASURE.CONTROLS.NEW_MEASURE_INFO" | translate }}</div>
        </div>
        <ene-accordeon *ngIf="!isCreateMode()">
          <div class="loader" *ngIf="set.measuresLoading">
            <div class="ene-spinner">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <ng-container *ngIf="!set.measuresLoading">
            <ene-accordeon-topic
              [title]="translateMap[measure.sector] | translate"
              [measures]="measure.items | countOf: 'active'"
              [labelColor]="measure.color"
              *ngFor="let measure of measures; trackBy: trackByMeasures"
            >
              <table>
                <tr *ngFor="let item of measure.items; trackBy: trackByMeasureItem">
                  <td class="name">{{ item.title | translate }}</td>
                  <td class="active">
                    <ene-slide-toggle
                      [active]="item.active"
                      [labelclick]="false"
                      (change)="onChangeSlidetoggle($event, item)"
                    >
                    </ene-slide-toggle>
                  </td>
                  <td class="edit">
                    <ene-icon icon="ec_trash" (click)="onDeleteMeasure(item)"></ene-icon>
                  </td>
                  <td class="edit">
                    <ene-icon icon="ec_edit" (click)="openMeasureEditor(item)"></ene-icon>
                  </td>
                </tr>
              </table>
            </ene-accordeon-topic>
          </ng-container>
        </ene-accordeon>
        <article class="ngu-item_controls" *ngIf="!isReadMode(set)">
          <button class="ngu-item_controls_light" (click)="setReadMode()">
            {{ "MEASURE.CONTROLS.ABORT" | translate | uppercase }}
          </button>
          <button class="ngu-item_controls_save" (click)="saveMeasureSet()">
            <ene-icon icon="ec_save"></ene-icon>
            <span>{{ "MEASURE.CONTROLS.SAVE_SET" | translate | uppercase }}</span>
          </button>
        </article>
        <article class="infotext" *ngIf="!isReadMode(set)">
          {{ "MEASURE.EDITOR.REQUIRED.EXPLANATION" | translate }}
        </article>
        <article class="ngu-item_controls" *ngIf="isReadMode(set)">
          <button class="ngu-item_controls_dark" (click)="openMeasureEditor()">
            <ene-icon icon="ec_maptools_zoomin"></ene-icon>
            <span>{{ "MEASURE.CONTROLS.CREATE_NEW_MEASURE" | translate | uppercase }}</span>
          </button>
          <button class="ngu-item_controls_light" (click)="onCreateSet()">
            <ene-icon icon="ec_maptools_zoomin"></ene-icon>
            <span>{{ "MEASURE.CONTROLS.ADD_MEASURE_SET" | translate | uppercase }}</span>
          </button>
        </article>
      </ngu-item>
      <button
        NguCarouselPrev
        class="carousel-arrow carousel__arrow--left"
        [disabled]="!isReadMode(activeSet) || sets?.length === 1"
      >
        <ene-icon icon="ec_networks_carousel-arrow"></ene-icon>
      </button>
      <button
        NguCarouselNext
        class="carousel-arrow carousel__arrow--right"
        [disabled]="!isReadMode(activeSet) || sets?.length === 1"
      >
        <ene-icon icon="ec_networks_carousel-arrow"></ene-icon>
      </button>

      <ul class="ngucarouselPoint" NguCarouselPoint>
        <li
          *ngFor="let i of carousel.pointNumbers"
          [class.active]="i == carousel.activePoint"
          (click)="carousel.moveTo(i)"
        ></li>
      </ul>
    </ngu-carousel>
  </section>
</section>
<ene-modal id="measure_editor" title="{{ 'MEASURE.EDITOR.TITLE' | translate }}">
  <measure-editor (onSave)="onMeasureSave($event)"></measure-editor>
</ene-modal>
