<app-page-wrapper [title]="config.title | translate" [chartTitle]="config?.chartTitle | translate: { year: year }">
  <div class="avoid-break-inside">
    <ng-container *ngComponentOutlet="component"></ng-container>
    <section>
      <div class="textbox" [innerHTML]="config.text | translate"></div>
    </section>
  </div>

  <div class="avoid-break-inside">
    <h2 class="table-title">{{ "PRINT_VIEW.TABLE.TITLE" | translate }}</h2>
    <div class="table-subtitle">{{ config?.tableFooter | translate: { year: year } }}</div>
    <table cdk-table [dataSource]="data$ | async">
      <ng-container *ngFor="let column of displayedColumns" [cdkColumnDef]="column">
        <th cdk-header-cell *cdkHeaderCellDef>
          {{ column | nameById: columns }}
        </th>
        <td cdk-cell *cdkCellDef="let element" [ngClass]="column">
          <span *ngIf="column === tableColumns.SECTOR">{{ element.name | translate }}</span>
          <span *ngIf="column === tableColumns.EMISSION">{{ element.value | number: numberFormat: locale }}</span>
        </td>
      </ng-container>
      <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
      <tr cdk-row *cdkRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</app-page-wrapper>
