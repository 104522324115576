import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { SafeResourceUrl, Title } from "@angular/platform-browser";
import { Observable, Subject } from "rxjs";
import { UtilService, RegionPropertyKpiService, IRegionProperties, RegionService } from "@energy-city/components";
import { PrintService } from "../../services/print.service";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import "@enersis/gp-components/gp-editable-text";

@Component({
  selector: "app-print-header",
  templateUrl: "./print-header.component.html",
  styleUrls: ["./print-header.component.scss"]
})
export class PrintHeaderComponent implements OnInit, OnDestroy {
  public logoPath = "/assets/images/logos/";
  public year: number;
  public buildings$: Observable<number>;
  public regionName: string;
  public coatOfArmsUrl$: Observable<SafeResourceUrl | null>;
  public coatWasLoaded: boolean;

  public pipeDecimal = {
    type: "decimal",
    localization: null
  };

  public pipeArea = {
    type: "eneFormatUnit",
    category: "area",
    unit: "m²",
    localization: null
  };

  public regionDetails$: Observable<IRegionProperties>;
  private coaLoadError$ = new Subject<string>();
  private appTitle: string;
  private destroy$ = new Subject<void>();

  public coatLoaded(): void {
    this.coatWasLoaded = true;
  }

  constructor(
    private utilService: UtilService,
    private titleService: Title,
    private printService: PrintService,
    private kpisService: RegionPropertyKpiService,
    private regionService: RegionService,
    private translate: TranslateService
  ) {}

  @HostListener("window:beforeprint")
  public onBeforePrint() {
    this.titleService.setTitle(this.regionName);
  }

  @HostListener("window:afterprint")
  public onAfterPrint() {
    this.titleService.setTitle(this.appTitle);
  }

  public ngOnInit() {
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.pipeDecimal = {
        ...this.pipeDecimal,
        localization: this.translate.currentLang
      };
      this.pipeArea = {
        ...this.pipeArea,
        localization: this.translate.currentLang
      };
    });

    const regionIdentifier = this.utilService.getRegionIdentifier();
    const locale = this.translate.currentLang;

    this.appTitle = this.titleService.getTitle();
    this.year = this.printService.selectedReportYear;
    this.buildings$ = this.kpisService.getRegionBuildings(regionIdentifier, this.year);
    this.regionDetails$ = this.kpisService.getRegionProperties(regionIdentifier, this.year);
    this.coatOfArmsUrl$ = this.utilService.getCoatOfArmsUrl(this.coaLoadError$);
    this.pipeDecimal.localization = locale;
    this.pipeArea.localization = locale;
    this.regionName = this.regionService.selectedRegionDetails.name;
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public async onCoaLoadError(e: ErrorEvent) {
    const imgTag = e.target as HTMLImageElement;
    this.coaLoadError$.next(imgTag.src);
  }
}
