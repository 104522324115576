import { TimelineAction, TimelineActionType } from "./timeline-actions";
import { ITimelineState } from "./index";

export function reducer(state: ITimelineState = {}, action: TimelineAction) {
  switch (action.type) {
    case TimelineActionType.TIMELINE_INIT:
      return {
        ...state,
        year: action.year
      };
    case TimelineActionType.TIMELINE_UPDATE:
      return {
        ...state,
        payload: {
          year: action.year
        },
        type: action.type,
        year: action.year
      };
    default:
      return state;
  }
}
