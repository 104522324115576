import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe, CurrencyPipe } from "@angular/common";
import { EneFormatUnitPipe } from "@energy-city/ui/pipes";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "eneUnit"
})
export class EneUnitPipe implements PipeTransform {
  public language = "de";

  constructor(
    private decimal: DecimalPipe,
    private currency: CurrencyPipe,
    private eneFormatUnitPipe: EneFormatUnitPipe,
    private translate: TranslateService
  ) {}

  public transform(value: number, pipe: any, locale?: string): string {
    const language = locale || pipe.localization || this.language;

    switch (pipe.type) {
      case "eneFormatUnit": {
        const unit = this.translate.instant(pipe.unit) || "";
        pipe.category = pipe.category || "decimal";
        pipe.container = pipe.container || "label";
        pipe.localization = language;

        return this.eneFormatUnitPipe.transform(value, pipe.category, unit, pipe);
      }
      case "decimal": {
        return this.decimal.transform(value, pipe.digitsInfo || "", language);
      }
      case "toLocaleString": {
        return Number(value).toLocaleString(language);
      }
      case "currency": {
        return this.currency.transform(value, pipe.currencyCode, pipe.display, pipe.digitsInfo, language);
      }
      default: {
        console.warn("pipe.type not recognized -> value is outputed as it is", pipe);
        return String(value);
      }
    }
  }
}
