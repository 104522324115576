import { MainPolluterActions, MainPolluterActionTypes } from "./main-polluter.actions";

export interface MainPolluterState {
  stationaryEnergy: object;
  transportation: object;
  error?: any;
}

export interface IPayload {
  result: [
    {
      year: number;
      data: {
        [key: string]: object;
      };
    }
  ];
}

const initialState: MainPolluterState = {
  stationaryEnergy: {},
  transportation: {},
  error: null
};

export function reducer(state = initialState, action: MainPolluterActions): MainPolluterState {
  switch (action.type) {
    case MainPolluterActionTypes.GET_MAIN_POLLUTER_STATIONARY_ENERGY_DATA_SUCCESS: {
      return {
        ...state,
        stationaryEnergy: getData(action.payload)
      };
    }
    case MainPolluterActionTypes.GET_MAIN_POLLUTER_STATIONARY_ENERGY_DATA_ERROR: {
      return {
        ...state,
        stationaryEnergy: {},
        error: action.error
      };
    }
    case MainPolluterActionTypes.GET_MAIN_POLLUTER_TRANSPORTATION_DATA_SUCCESS: {
      return {
        ...state,
        transportation: getData(action.payload)
      };
    }
    case MainPolluterActionTypes.GET_MAIN_POLLUTER_TRANSPORTATION_DATA_ERROR: {
      return {
        ...state,
        transportation: {},
        error: action.error
      };
    }
    default: {
      return state;
    }
  }
}

function getData(payload: IPayload): object {
  if (!payload || !payload.result) {
    return {};
  }

  const [{ data }] = payload.result;

  return data;
}
