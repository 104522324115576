import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Actions, Effect, ofType } from "@ngrx/effects";
import {
  PutAccountingMethodsConfigActionType,
  PutAccountingMethodsConfig,
  PutAccountingMethodsConfigSuccess,
  PutAccountingMethodsConfigError
} from "./put-accounting-methods-config-actions";
import { catchError, map, switchMap } from "rxjs/operators";
import { acceptJsonWithoutCache } from "../../../common";
import { Observable, of } from "rxjs";
import { Action } from "@ngrx/store";

@Injectable()
export class PutAccountingMethodsConfigEffects {
  constructor(private http: HttpClient, private actions$: Actions) {}

  @Effect() public putAccountingMethodsConfigEffects$: Observable<Action> = this.actions$.pipe(
    ofType(PutAccountingMethodsConfigActionType.PUT_ACCOUNTING_METHODS_CONFIG),
    switchMap((action: PutAccountingMethodsConfig) => {
      const url: string = "";
      return this.http
        .get<any>(url, {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams().set("regionId", action.regionId)
        })
        .pipe(
          map((response: any) => new PutAccountingMethodsConfigSuccess(response)),
          catchError(
            (error): Observable<PutAccountingMethodsConfigError> => of(new PutAccountingMethodsConfigError(error))
          )
        );
    })
  );
}
