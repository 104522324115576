import { IApplicationState } from "..";
import { createSelector } from "@ngrx/store";
import { ContactState } from "./contact.reducer";

const GetContactsState = (state: IApplicationState) => state.app.newContact;

export const getCurrrentContactComponent = createSelector(
  GetContactsState,
  (state: ContactState) => state.contactComponent
);
export const getSendStatus = createSelector(GetContactsState, (state: ContactState) => state.status);
