import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../../../../state/index";
import { Subject, Observable, merge } from "rxjs";
import { ofType } from "@ngrx/effects";
import { TimelineActionType } from "../../../../../state/index";
import { AccountingMethodService } from "../../../../../services/accounting-method.service";
import { ChartsService } from "../../../../../services/charts.service";
import { UtilService } from "../../../../../services/util.service";
import { RegionService } from "../../../../../services/region.service";
import { EneChartComponent } from "@energy-city/ui/chart";
import { TranslateService } from "@ngx-translate/core";
import { EneKpisV2Service } from "@energy-city/ui/kpis-v2";
import { energyTypes } from "../final-energy.model";
import { takeUntil } from "rxjs/operators";
import { ChartFinalEnergySectorsService } from "./chart-final-energy-sectors.service";
import { EnergySectorsService } from "./energy-sectors.service";
import { AccountingMethod } from "../../../../../configs/accounting-method";
import { Co2CockpitKPIService } from "../../services/co2-cockpit-kpi.service";

@Component({
  selector: "app-chart-final-energy-sectors",
  templateUrl: "./chart-final-energy-sectors.component.html",
  styleUrls: ["./chart-final-energy-sectors.component.scss"],
  providers: [ChartFinalEnergySectorsService]
})
export class ChartFinalEnergySectorsComponent implements OnInit, OnDestroy {
  public chartData: any = [];
  public chartConfig: any;
  public dqi: number;
  public energyTypes = energyTypes;
  public energyType: string = energyTypes.FINAL;
  public weatherCorrection$: Observable<boolean>;
  public isBisko: boolean;
  @ViewChild("chart", { static: true }) public chart: EneChartComponent;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private accountingMethodService: AccountingMethodService,
    private store: Store<fromRoot.IApplicationState>,
    private translate: TranslateService,
    private eneKpisV2Service: EneKpisV2Service,
    private chartsService: ChartsService,
    private energySectorsService: EnergySectorsService,
    private chartFinalEnergySectorsService: ChartFinalEnergySectorsService,
    private co2CockpitKPIService: Co2CockpitKPIService,
    private regionService: RegionService
  ) {
    this.weatherCorrection$ = this.accountingMethodService.weatherCorrection$.asObservable();
  }

  public ngOnInit() {
    this.accountingMethodService.selectedAccountingMethod$.pipe(takeUntil(this.destroy$)).subscribe((method) => {
      this.isBisko = method === AccountingMethod.BISKO;
    });

    this.eneKpisV2Service.pending("co2_cockpit_extended");
    merge(
      this.store.select(fromRoot.GetTimelineState).pipe(ofType(TimelineActionType.TIMELINE_UPDATE)),
      this.regionService.selectedRegionUpLevels$
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.requestChartData();
      });

    this.chartFinalEnergySectorsService.initConfig();
    this.chartConfig = this.chartFinalEnergySectorsService.config;

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.chartData = [...this.chartsService.setSerieNames(this.chartData)];
    });
  }

  public setFinalEnergy(res) {
    if (res.result === null) {
      return;
    }
    const [data, dqi] = this.energySectorsService.getChartData(res.result);
    this.dqi = dqi;
    data.series = data.series.filter((serie) => {
      const sum = serie.data.reduce((acc, item) => (acc += item), 0);
      return Boolean(sum);
    });

    this.chartData = this.chartsService.setSerieNames(data.series);
    this.chartFinalEnergySectorsService.setCategories(data.categories);

    if (!this.chartData.length) {
      this.chart.showNoData();
    }
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public onChange(type: string) {
    this.energyType = type;
    this.requestChartData();
  }

  private calculateTotal(total, year: number) {
    const objectForYear = total.result.filter((item) => item.year === year)[0];
    if (!objectForYear) {
      return 0;
    }
    return Object.keys(objectForYear).reduce((acc, key) => acc + (objectForYear[key].value || 0), 0);
  }

  private requestChartData() {
    this.chart.chart && this.chart.showLoading();
    const selectedRegionDetails = this.regionService.selectedRegionDetails;
    const chartData$ = this.energySectorsService.getEnergyValues(selectedRegionDetails);
    chartData$.subscribe((t) => this.setFinalEnergy(t));
    const regionIdentifier20 = this.regionService.getSelectedRegionLevelId(20);

    if (!regionIdentifier20) {
      return;
    }

    const chartData20$ = this.energySectorsService.getEnergyValues(regionIdentifier20);
    const childLevels$ = this.regionService.getRegionChildLevels(regionIdentifier20.regionId);

    this.co2CockpitKPIService.requestChartData({
      selectedRegionLevel: selectedRegionDetails.level,
      regionType: selectedRegionDetails.regionType,
      chartData$,
      chartData20$,
      childLevels$,
      calculateTotal: this.calculateTotal
    });
  }
}
