import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "nameById"
})
export class NameByIdPipe implements PipeTransform {
  public transform(id: string, list: Array<any>): string {
    const found = list.find((item) => item.id === id);

    if (found) {
      return found.name;
    }

    return id;
  }
}
