import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DqiComponent } from "./dqi/dqi.component";
import { EnePopperModule } from "@energy-city/ui/popper";
import { DeprecatedEneRangeSliderModule, EneFormsModule } from "@energy-city/ui/forms";

@NgModule({
  declarations: [DqiComponent],
  imports: [CommonModule, EnePopperModule, EneFormsModule, DeprecatedEneRangeSliderModule],
  exports: [DqiComponent]
})
export class AppDqiModule {}
