/* tslint:disable:member-access */

import { Action } from "@ngrx/store";

export enum ChartLocalEnergyMixActionType {
  GET_ENERGY_CONSUMPTION = "[LocalEnergyMix] GET_ENERGY_CONSUMPTION",
  GET_ENERGY_CONSUMPTION_SUCCESS = "[LocalEnergyMix] GET_ENERGY_CONSUMPTION_SUCCESS",
  GET_RENEWABLE = "[LocalEnergyMix] GET_RENEWABLE",
  GET_RENEWABLE_SUCCESS = "[LocalEnergyMix] GET_RENEWABLE_SUCCESS",
  ERROR = "[LocalEnergyMix] ERROR"
}

export class GetLocalEnergyMixEnergyConsumption implements Action {
  public readonly type = ChartLocalEnergyMixActionType.GET_ENERGY_CONSUMPTION;
  payload = null;
  constructor(public ags: string, public year: number) {}
}
export class GetLocalEnergyMixEnergyConsumptionSuccess implements Action {
  public readonly type = ChartLocalEnergyMixActionType.GET_ENERGY_CONSUMPTION_SUCCESS;
  constructor(public payload: any) {}
}
export class GetLocalEnergyMixRenewable implements Action {
  public readonly type = ChartLocalEnergyMixActionType.GET_RENEWABLE;
  payload = null;
  constructor(public ags: string, public year: number) {}
}
export class GetLocalEnergyMixRenewableSuccess implements Action {
  public readonly type = ChartLocalEnergyMixActionType.GET_RENEWABLE_SUCCESS;
  constructor(public payload: any) {}
}

export class LocalEnergyMixError implements Action {
  public readonly type = ChartLocalEnergyMixActionType.ERROR;
  constructor(public payload: any) {}
}

export type LocalEnergyMixActions =
  | GetLocalEnergyMixEnergyConsumption
  | GetLocalEnergyMixEnergyConsumptionSuccess
  | GetLocalEnergyMixRenewable
  | GetLocalEnergyMixRenewableSuccess
  | LocalEnergyMixError;
