import { NgModule, ModuleWithProviders } from "@angular/core";
import { EneFormatUnitPipe } from "./pipes/format-unit.pipe";

@NgModule({
  declarations: [EneFormatUnitPipe],
  providers: [EneFormatUnitPipe],
  exports: [EneFormatUnitPipe]
})
export class EneUiPipesModule {
  public static forRoot(initialConfig?: any): ModuleWithProviders<EneUiPipesModule> {
    return {
      ngModule: EneUiPipesModule,
      providers: [
        {
          provide: "initialConfig",
          useValue: initialConfig
        }
      ]
    };
  }
}
