import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneIconModule } from "@energy-city/ui/icon";
import { SharedModule } from "../../../../shared/shared.module";
import { AccountingInfoWrapperComponent } from "./accounting-info-wrapper.component";

@NgModule({
  declarations: [AccountingInfoWrapperComponent],
  imports: [CommonModule, TranslateModule, EneIconModule, SharedModule],
  exports: [AccountingInfoWrapperComponent]
})
export class AccountingInfoWrapperModule {}
