import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, forkJoin } from "rxjs";
import { RegionType, UtilService } from "../../../../services/util.service";
import { EneKpisV2Service } from "@energy-city/ui/kpis-v2";
import { IRegionChildLevels } from "@energy-city/components";
@Injectable()
export class Co2CockpitKPIService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();
  public kpiAverage: number;

  constructor(private utilService: UtilService, private eneKpisV2Service: EneKpisV2Service) {}

  public requestChartData<T>(options: {
    selectedRegionLevel: number;
    regionType: RegionType;
    chartData$: Observable<T>;
    chartData20$: Observable<T>;
    childLevels$: Observable<IRegionChildLevels>;
    calculateTotal: (data: T, year: number) => number;
  }) {
    forkJoin([options.chartData$, options.chartData20$, options.childLevels$]).subscribe((result) => {
      const [total, total20, childLevels] = result;
      const totalNumber = options.calculateTotal(total, this.utilService.getSelectedYear());
      if (options.regionType === "union") {
        this.eneKpisV2Service.runMapping(
          { total: totalNumber, average: "disabled", difference_to_average: "disabled" },
          "co2_cockpit_extended"
        );
        return;
      }
      const total20Number = options.calculateTotal(total20, this.utilService.getSelectedYear());

      const currentLevelStats =
        options.selectedRegionLevel === 20
          ? { level: 20, value: 1 }
          : childLevels.child.find((i) => String(i.level) === String(options.selectedRegionLevel));

      if (!currentLevelStats?.value) {
        return;
      }
      const kpiAverage = total20Number / currentLevelStats.value;
      this.kpiAverage = kpiAverage;
      this.eneKpisV2Service.runMapping(
        { total: totalNumber, average: kpiAverage, difference_to_average: totalNumber - kpiAverage },
        "co2_cockpit_extended"
      );
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
