/*
 Here we map event definitions to Action types. Basically, we are
 exporting an object that tells Redux Beacon what events to fire when
 a given Action is dispatched.
*/

import { EventsMap } from "redux-beacon";
import ensure from "@redux-beacon/ensure";
import combineEvents from "@redux-beacon/combine-events";

import { panelChange, panelChangeValidator, appReady, appLogin, onClick, timelineChange, guidedTour } from "./events";
import { SideNavigationActionType } from "../state/sidenavigation/sidenavigation-actions";
import { TrackingActionType } from "../state/tracking/tracking-actions";
import { AppActionType } from "../state/app/app-actions";
import { TimelineActionType } from "../state/timeline/timeline-actions";
import { TourActionType } from "../state/tour/tour-actions";

const eventsMap: EventsMap = {
  [SideNavigationActionType.SIDE_NAVIGATION_EVENT]: ensure(panelChangeValidator, panelChange),
  [AppActionType.GET_CLIENT_CONFIG_SUCCESS]: combineEvents(appReady, appLogin), // we can't use LOGIN_SUCCESS since this code can only run after that action is only triggered, we just use this event here to mock it
  [TrackingActionType.TRACKING_ONCLICK_EVENT]: onClick,
  [TimelineActionType.TIMELINE_UPDATE]: timelineChange,
  [TourActionType.GET_TOURS]: guidedTour
};

export default eventsMap;
