import { ITooltipOptions } from "./tooltip.interface";
import { TooltipPlacement, TooltipTrigger, TooltipTheme } from "./tooltip.enum";

export const defaultOptions: ITooltipOptions = {
  placement: TooltipPlacement.TOP,
  delay: 0,
  "show-delay": 0,
  "hide-delay": 100,
  "hide-delay-mobile": 9901,
  "z-index": 0,
  "animation-duration": 300,
  trigger: TooltipTrigger.HOVER,
  "tooltip-class": "",
  display: true,
  "display-mobile": true,
  shadow: true,
  theme: TooltipTheme.DARK,
  offset: 8,
  "max-width": "",
  id: false
};
