<main
  class="panel-content"
  *ngIf="{
    config: accountingConfig$ | async,
    currentSelection: currentSelection$ | async,
    selectedFactor: selectedFactor$ | async
  } as model"
>
  <section class="panel-content__header-container">
    <h3>{{ "DATA_PANEL.ACCOUNTING_PARAMETERS.HEADING.TITLE" | translate }}</h3>
    <p [innerHTML]="'DATA_PANEL.ACCOUNTING_PARAMETERS.HEADING.DESCRIPTION' | translate"></p>
    <p
      [innerHTML]="'DATA_PANEL.ACCOUNTING_PARAMETERS.HEADING.DESCRIPTION_USER_FACTORS' | translate"
      *ngIf="!(isRegionTypeUnion$ | async) && factorManagementService.showFactorManagement()"
    ></p>
  </section>
  <section
    *ngIf="!!model.config && !!model.currentSelection"
    class="panel-content__control-panel"
    [class.two-columns]="!(isRegionTypeUnion$ | async) && factorManagementService.showFactorManagement()"
  >
    <section class="panel-content__control-panel__control-container">
      <h3>{{ "DATA_PANEL.ACCOUNTING_PARAMETERS.ACCOUNTING_METHOD" | translate }}</h3>
      <section class="panel-content__control-panel__control-container__form">
        <article class="panel-content__control-panel__control-container__row">
          <div
            class="panel-content__control-panel__control-container__row__control-item"
            *ngFor="let accountingMethod of model.config.availableMethods"
            [ngClass]="{
              active: model.currentSelection.accountingMethod === accountingMethod.type,
              disabled: accountingMethod.disabled
            }"
            (click)="updateAccountingMethod(accountingMethod)"
          >
            <label for="{{ accountingMethod.type }}">{{
              "DATA_PANEL.ACCOUNTING_PARAMETERS." + accountingMethod.type | translate | uppercase
            }}</label>
            <input
              type="radio"
              id="{{ accountingMethod.type }}"
              [checked]="model.currentSelection.accountingMethod === accountingMethod.type"
            />
          </div>
        </article>

        <article class="panel-content__control-panel__control-container__row">
          <div
            class="panel-content__control-panel__control-container__row__control-item"
            (click)="toggleWeatherCorrection()"
            [class.active]="model.currentSelection.weatherCorrection"
            [class.disabled]="isWeatherCorrectionDisabled$ | async"
          >
            <ene-icon icon="ec_witterungskorrektur"></ene-icon>
            <span class="weather">{{
              "DATA_PANEL.ACCOUNTING_PARAMETERS.WEATHER_CORRECTION" | translate | uppercase
            }}</span>
            <span class="indicator" [class.active]="model.currentSelection.weatherCorrection"></span>
          </div>
        </article>

        <article class="panel-content__control-panel__control-container__row">
          <div
            class="panel-content__control-panel__control-container__row__control-item"
            [ngClass]="{ active: model.currentSelection.mixType === mixType.FEDERAL }"
            (click)="updateMixType(mixType.FEDERAL)"
          >
            <ene-icon icon="ec_filled-national"></ene-icon>
            <label for="federal">{{ "DATA_PANEL.ACCOUNTING_PARAMETERS.FEDERAL_MIX" | translate | uppercase }}</label>
            <input type="radio" id="federal" [checked]="model.currentSelection.mixType === mixType.FEDERAL" />
          </div>

          <div
            class="panel-content__control-panel__control-container__row__control-item"
            (click)="updateMixType(mixType.LOCAL)"
            [class.active]="model.currentSelection.mixType === mixType.LOCAL"
            [class.disabled]="isLocalMixDisabled$ | async"
          >
            <ene-icon icon="ec_filled-local"></ene-icon>
            <label for="local">{{ "DATA_PANEL.ACCOUNTING_PARAMETERS.LOCAL_MIX" | translate | uppercase }}</label>
            <input type="radio" id="local" [checked]="model.currentSelection.mixType === mixType.LOCAL" />
          </div>
        </article>
      </section>
    </section>

    <section *ngIf="!(isRegionTypeUnion$ | async)" class="panel-content__control-panel__control-container">
      <h3>{{ "DATA_PANEL.ACCOUNTING_PARAMETERS.FACTORS.TITLE" | translate }}</h3>
      <section class="panel-content__control-panel__control-container__form">
        <div class="panel-content__control-panel__control-container__form-group">
          <article class="panel-content__control-panel__control-container__row">
            <div
              class="panel-content__control-panel__control-container__row__control-item"
              *ngFor="let factor of factors"
              [class.active]="model.selectedFactor === factor.type"
              [class.disabled]="isUserFactorsDisabled$ | async"
              (click)="updateFactor(factor.type)"
            >
              <label for="{{ factor.type }}">{{
                "DATA_PANEL.ACCOUNTING_PARAMETERS.FACTORS." + factor.type | translate | uppercase
              }}</label>
              <input type="radio" id="{{ factor.type }}" [checked]="model.selectedFactor === factor.type" />
            </div>
          </article>
        </div>
      </section>
    </section>
    <div class="panel-content__union-info" *ngIf="isRegionTypeUnion$ | async">
      <ene-icon icon="ec_info"></ene-icon>
      <div>
        <p>
          {{ "DATA_PANEL.ACCOUNTING_PARAMETERS.UNION_INFO" | translate }}
          {{ "DATA_PANEL.ACCOUNTING_PARAMETERS.UNION_INFO_SECTORS_DISABLED_EXPLANATION" | translate }}
        </p>
      </div>
    </div>
  </section>
</main>
