import { ChartBenchmarkingActionType, ChartBenchmarkingActions } from "./benchmarking-actions";

export function reducer(state = [], action: ChartBenchmarkingActions) {
  switch (action.type) {
    case ChartBenchmarkingActionType.GET_DATA:
    case ChartBenchmarkingActionType.GET_DATA_SUCCESS:
    case ChartBenchmarkingActionType.GET_KPI:
    case ChartBenchmarkingActionType.GET_KPI_SUCCESS:
      return action;
    case ChartBenchmarkingActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
