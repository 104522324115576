import { ChartCo2EmissionSectorsActionType, ChartCo2EmissionSectorsActions } from "./emission-sectors-actions";

export function reducer(state = [], action: ChartCo2EmissionSectorsActions) {
  switch (action.type) {
    case ChartCo2EmissionSectorsActionType.GET_DATA:
    case ChartCo2EmissionSectorsActionType.GET_DATA_SUCCESS:
    case ChartCo2EmissionSectorsActionType.GET_KPI:
    case ChartCo2EmissionSectorsActionType.GET_KPI_SUCCESS:
      return action;
    case ChartCo2EmissionSectorsActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
