export const energyTypeColorMap = {
  solarHeat: {
    color: "#FFF27E",
    legendIndex: 19
  },
  blackCoal: {
    color: "#594637",
    legendIndex: 20
  },
  brownCoal: {
    color: "#8F582B",
    legendIndex: 4
  },
  biomass: {
    color: "#87C113",
    legendIndex: 3
  },
  naturalGas: {
    color: "#FFE000",
    legendIndex: 6
  },
  districtHeating: {
    color: "#E05AA8",
    legendIndex: 8
  },
  environmentalHeat: {
    color: "#922424",
    legendIndex: 22
  },
  electricity: {
    color: "#5C7D1B",
    legendIndex: 21
  },
  fuelOil: {
    color: "#C65D5D",
    legendIndex: 11
  },
  diesel: {
    color: "#D49E96",
    legendIndex: 5
  },
  gasoline: {
    color: "#FF922F",
    legendIndex: 16
  },
  liquidGas: {
    color: "#FFBD35",
    legendIndex: 9
  },
  fuelGrouped: {
    color: "#576CE0",
    legendIndex: 15
  },
  nuclear: {
    color: "#85FF66",
    legendIndex: 14
  },
  photovoltaics: {
    color: "#9FA7E8",
    legendIndex: 18
  },
  water: {
    color: "#39A9E3",
    legendIndex: 23
  },
  wind: {
    color: "#CDEEF3",
    legendIndex: 24
  },
  gas: {
    color: "#FFBD35",
    legendIndex: 10
  },
  oil: {
    color: "#2338B2",
    legendIndex: 7
  },
  other: {
    color: "#542105",
    legendIndex: 1
  },
  biogas: {
    color: "#3AC33A",
    legendIndex: 2
  },
  heavyFuelOil: {
    color: "#8C4242",
    legendIndex: 13
  },
  lightFuelOil: {
    color: "#C65D5D",
    legendIndex: 12
  },
  otherPetroleumProducts: {
    color: "#F4B100",
    legendIndex: 25
  },
  petroleumCoke: {
    color: "#1A544A",
    legendIndex: 17
  }

};

export const energyTypeMap = {
  fuelGrouped: [
    "dieselFossil",
    "dieselBio",
    "gasolineFossil",
    "gasolineBio",
    "kerosene",
    "liquefiedPetroleumGas",
    "biogas",
    "naturalGas",
    "oil",
    "gas",
    "hydrogen",
    "bioethanol",
    "gasoline",
    "gasoil",
    "biodiesel",
    "compressedNaturalGas",
    "gasolineFourStroke",
    "hybridDiesel",
    "naturalGas",
    "gasolineTwoStroke",
    "compressedNaturalGasGasoline",
    "liquefiedPetroleumGasGasoline",
    "hybridGasoline",
    "gasolineE85",
    "diesel"
  ]
};

export const greenhouseGasesenergyTypes = {
  FINAL: "final",
  PRIMARY: "primary"
};
