import { InjectionToken } from "@angular/core";

export const PLATFORM_ENV_FILE = new InjectionToken<IEnvDto>("platform env file");

export interface ITenantInfoDto {
  tenant: string;
  environment: "development" | "staging" | "production";
  country: "de" | "ch";
}

export interface IEnvDto {
  co2balance?: ICo2Env;
  country?: "de" | "ch";
  isBenchmarkingDisabled?: boolean; // this feature flag should be under co2balance.featureFlags
}

export interface ICo2Env {
  tenantMapConfig?: ITenantMapConfigDto;
  featureFlags?: ICo2FeatureFlags;
}

export interface ICo2FeatureFlags {
  hidePrimaryEnergy?: boolean;
  hideMainIssuerCharts?: boolean;
  factorManagement?: boolean;
}

export interface ITenantMapConfigDto {
  regionLayers: Array<IRegionLayerConfigDto | IUnionLayerConfigDto>;
  emissionLayerColors: IEmissionLayerColorsDto;
  minCo2EmissionsTonsPerCapita: number;
  maxCo2EmissionsTonsPerCapita: number;
  buildingYear?: number;
}

export interface IRegionLayerConfigDto {
  level: string;
  minZoom: number;
  maxZoom: number;
  minCo2EmissionsTons: number;
  maxCo2EmissionsTons: number;
}

export interface IUnionLayerConfigDto {
  level: string;
  minZoom: number;
  maxZoom: number;
}

export interface IEmissionLayerColorsDto {
  colorSteps: Array<string>;
  domain: Array<number>;
}
