import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  AccountingMethod,
  AccountingMethodService,
  EntryComponents,
  GetClientConfigSuccess,
  IRegionIdentifier,
  ITabsConfig,
  RegionType,
  RegionTypes,
  UtilService
} from "@energy-city/components";
import { TabsContainerComponent } from "@energy-city/ui/tabs";
import { get } from "lodash";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FactorManagementService } from "./components/factor-management/factor-management.service";

@Component({
  selector: "app-data-panel",
  templateUrl: "./data-panel.component.html",
  styleUrls: ["./data-panel.component.scss"]
})
export class DataPanelComponent implements OnInit, OnDestroy {
  @ViewChild(TabsContainerComponent) private tabs: TabsContainerComponent;

  constructor(
    private utilService: UtilService,
    private readonly factorManagementService: FactorManagementService,
    private accountingMethodService: AccountingMethodService
  ) {}

  public entryComponents: any = EntryComponents.dataPanel;

  public tabsConfig: ITabsConfig[];

  private destroy$: Subject<undefined> = new Subject();

  public ngOnInit() {
    this.handleClientConfig();
  }

  private handleClientConfig = (): void => {
    const isTabDisabled = (
      tab: ITabsConfig,
      params: {
        regionType: RegionType;
        accountingMethod: AccountingMethod;
        useUserFactors: boolean;
      }
    ): boolean => {
      switch (tab.component) {
        case "DataInput":
          return params.regionType === RegionTypes.UNION;
        case "FactorManagement":
          return params.regionType === RegionTypes.UNION;
        default:
          return false;
      }
    };

    const isTabShown = (tab: ITabsConfig, params: { regionType: RegionType }): boolean => {
      switch (tab.component) {
        case "FactorManagement":
          return params.regionType !== RegionTypes.UNION && this.factorManagementService.showFactorManagement();
        default:
          return true;
      }
    };

    combineLatest([
      this.utilService.clientConfig$,
      this.utilService.regionIdentifier$,
      this.accountingMethodService.selectedAccountingMethod$,
      this.accountingMethodService.useUserFactors$,
      this.accountingMethodService.timestamp$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([clientConfig, { regionType }, accountingMethod, useUserFactors, _]: [
          GetClientConfigSuccess,
          IRegionIdentifier,
          AccountingMethod,
          boolean,
          number
        ]) => {
          const isRegionTypeUnion = regionType === RegionTypes.UNION;
          this.tabsConfig = get(clientConfig, "module[0].panels.data.tabs").map((tab: ITabsConfig) => ({
            ...tab,
            hidden: !isTabShown(tab, { regionType }),
            disabled: isTabDisabled(tab, { regionType, accountingMethod, useUserFactors })
          }));

          if (isRegionTypeUnion) {
            this.tabs?.selectTab(0);
          }
        }
      );
  };

  public tracker(index: number, { title }: ITabsConfig): string {
    return title;
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
