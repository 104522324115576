import { Component, Input } from "@angular/core";
import { EneAccordeonComponent } from "../accordeon/accordeon.component";

@Component({
  selector: "ene-accordeon-topic",
  templateUrl: "./topic.component.html",
  styleUrls: ["./topic.component.scss"]
})
export class EneTopicComponent {
  @Input() public title: string;
  @Input() public measures: number;
  @Input() public labelColor: string;
  @Input() public active: boolean;
  @Input() public arrowPosition: string = "start";
  @Input() public icon: string;
  constructor(private accordeon: EneAccordeonComponent) {
    this.accordeon.addTopic(this);
  }

  public changeTopic() {
    this.accordeon.changeTopic(this);
  }
}
