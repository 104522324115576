import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { API_ENDPOINT_SERVICE_TOKEN, IApiEndpointService } from "../../../common/utils/api-endpoint.interface";
import { Savings } from "../../accounting-simulation/model/savings/savings";
import { YearlyValue, TimeSeries } from "../../accounting-common/model/time-series/time-series";
import { Scenario } from "../model/scenario/scenario";
import { ISavingsResultDto } from "./dto/savings-dto.interface";
import { IScenarioResultDto } from "./dto/scenario-dto.interface";

@Injectable({
  providedIn: "root"
})
export class SimulationDataService {
  constructor(
    private http: HttpClient,
    @Inject(API_ENDPOINT_SERVICE_TOKEN) public apiEndpointService: IApiEndpointService
  ) {}

  /**
   * requestScenearios requests all Scenarios from backend sets the result in the scenario Observable which can be gathered through getScenario
   */
  public requestScenarios(regionId: string): Observable<Scenario[] | null> {
    return this.http
      .get<IScenarioResultDto>(`${this.apiEndpointService.getSimulationPropertyApi()}/regions/${regionId}/scenarios`)
      .pipe(
        map((data) =>
          data.result.map(
            (scenarioData) =>
              new Scenario(
                scenarioData.default,
                scenarioData.title,
                scenarioData.description,
                scenarioData.regionId,
                scenarioData.scenarioId,
                scenarioData.values.map((goal) => ({ value: goal.goal, year: goal.year } as YearlyValue))
              )
          )
        ),
        catchError(() => {
          console.error(`Error querying savings for ${regionId}`);
          return of(null);
        })
      );
  }

  /**
   * requestSavingsData querys backend for the measurement specific savings Data and returns them as an observable Savings object
   * @param measurementId
   * @returns
   */
  public requestSavingsData(measurementId: string): Observable<Savings | null> {
    return this.http
      .get<ISavingsResultDto>(
        `${this.apiEndpointService.getSimulationPropertyApi()}/emissions/co2/savings/set/${measurementId}`
      )
      .pipe(
        map((data) => {
          return new Savings(
            new TimeSeries(
              data.result.totalSavings.map((saving) => ({ value: saving.co2Saving, year: saving.year } as YearlyValue)),
              "savings"
            )
          );
        }),
        catchError(() => {
          console.error(`Error querying savings for ${measurementId}`);
          return of(null);
        })
      );
  }
}
