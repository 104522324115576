import { IconName } from "@enersis/gp-components/src/gp-icon";
import { UnitConfig } from "@enersis/gp-components/gp-kpi";

export interface IKpis {
  id: string;
  type: string;
  state: any;
  items: Array<KpiOptions>;
  value?: number;
}

type Diff<T, U> = T extends U ? never : T;
type RequiredExceptFor<T, TOptional extends keyof T> = Pick<T, Diff<keyof T, TOptional>> & Partial<T>;

export type IKpisConfig = RequiredExceptFor<IKpis, "state" | "type">;

export interface KpiOptions {
  id: string;
  icon: IconName | (string & {}); // typescript magic to get IconName autocomplete but also allow string
  title: string;
  mapping: string;
  describing_icons?: IDescribingIcon[];
  unitConfig: UnitConfig;
  disabled?: boolean;
}
export interface IDescribingIcon {
  name: String;
  icon?: String;
  active: boolean;
}

export interface INotAvailableText {
  text: string;
  namespace?: string;
}

export interface IKpiPipe {
  type: string;
  digitsInfo: string;
}

export interface IValues {
  [key: string]: number | null | undefined | "disabled";
}

export interface IStatesMap {
  [key: string]: States;
}

export enum States {
  PENDING,
  RESOLVE,
  CLEARED,
  NOTAVAILABLE,
  UNKNOWN
}

export interface IMapping {
  values: IValues;
  namespace?: string;
  blocks?: Array<string>;
}

export interface IPending {
  namespace?: string;
  blocks?: Array<string>;
}
