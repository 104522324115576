/* tslint:disable:component-selector */

import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  OnInit,
  HostBinding,
  ViewEncapsulation
} from "@angular/core";
import { TabComponent } from "../tab/tab.component";

@Component({
  selector: "ene-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-tabs"
  }
})
export class TabsContainerComponent implements OnChanges, OnInit {
  private ifTabSelected: boolean = false;
  public tabs: TabComponent[] = [];

  // tslint:disable-next-line: no-input-rename
  @HostBinding("class")
  @Input("class")
  public class: string = "";

  @Input() public mode: string = "horizontal";
  @Input() public styling: string = "folder";
  @Input() public disabled: boolean;
  @Input() public iconsProject: string = "energycity";
  @Output() public currentTabChange = new EventEmitter<TabComponent>();

  public ngOnInit() {
    this.class = this.class + " " + this.mode + " " + this.styling;
    this.checkState();

    if (!this.ifTabSelected) {
      this.selectTab(this.tabs[0]);
    }
  }

  public addTab(tab: TabComponent) {
    this.tabs.push(tab);
  }

  /**
   *
   * @param tab Takes a template ref or a the index of the tab to show
   */
  public selectTab(tab: TabComponent | number): void {
    try {
      if (typeof tab === "number") {
        tab = this.tabs[tab];
      }
      this.tabs.forEach((elem) => {
        elem.active = false;
      });
      tab.active = true;
      this.currentTabChange.emit(tab);
    } catch (error) {
      // probably first call where the tab is undefined... just "still" run the code
    }
  }

  public isDisabled() {
    if (this.disabled) {
      return "block";
    } else {
      return "none";
    }
  }

  public checkState() {
    this.tabs.forEach((tab) => {
      if (tab.active) {
        this.selectTab(tab);
        this.ifTabSelected = true;
      }
    });
  }

  public ngOnChanges() {
    this.checkState();
  }
}
