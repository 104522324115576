import { ILoadingBehavior } from "../../../common";
import { PutAccountingMethodsConfigActionType } from "./put-accounting-methods-config-actions";

export const reducer = (state: IPutAccountingMethodsConfigState, action: any) => {
  switch (action.type) {
    case PutAccountingMethodsConfigActionType.PUT_ACCOUNTING_METHODS_CONFIG:
      return {
        ...state,
        loading: true
      };
    case PutAccountingMethodsConfigActionType.PUT_ACCOUNTING_METHODS_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        config: action.config
      };
    case PutAccountingMethodsConfigActionType.PUT_ACCOUNTING_METHODS_CONFIG_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};

export interface IPutAccountingMethodsConfigState extends ILoadingBehavior {
  payload: any;
}
