import { DatabaseChartActions, DatabaseChartActionTypes } from "./database-chart.actions";

export interface DatabaseChartState {
  data?: any;
  specificEmissionFactor?: { [key: number]: number };
}

export function reducer(state: DatabaseChartState = {}, action: DatabaseChartActions): DatabaseChartState {
  switch (action.type) {
    case DatabaseChartActionTypes.GET_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case DatabaseChartActionTypes.CLEAR_DATA:
      return {
        ...state,
        data: null
      };
    case DatabaseChartActionTypes.GET_KPI_SUCCESS:
      return {
        ...state,
        specificEmissionFactor: {
          ...state.specificEmissionFactor,
          [action.regionId]: action.payload
        }
      };
    case DatabaseChartActionTypes.GET_KPI_ERROR:
      return {
        ...state,
        specificEmissionFactor: {
          ...state.specificEmissionFactor,
          [action.regionId]: null
        }
      };
    default:
      return state;
  }
}
