import { DistrictActionType } from "./district-actions";
import { DistrictActions } from "./district-actions";

export function reducer(state = [], action: DistrictActions) {
  switch (action.type) {
    case DistrictActionType.GET_DISTRICT_DATA:
    case DistrictActionType.GET_DISTRICT_DATA_SUCCESS:
    case DistrictActionType.GET_DISTRICT_BASIC_DATA:
    case DistrictActionType.GET_DISTRICT_BASIC_DATA_SUCCESS:
      return action;
    case DistrictActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
