<section ene-sidenav-start>
  <div class="content__wrapper">
    <section class="ene-surface-box-flat">
      <h3>{{ "REPORTS.REPORT_FORMAT" | translate }}</h3>
      <p class="paragraph">{{ "REPORTS.REPORT_FORMAT_DESCRIPTION" | translate }}</p>
      <app-report-preview></app-report-preview>
    </section>
    <section class="ene-surface-box-flat content">
      <h3>{{ "REPORTS.ACC_METH_YEAR_BALANCE" | translate }}</h3>
      <p class="paragraph">{{ "REPORTS.ACC_METH_YEAR_BALANCE_DESCRIPTION" | translate }}</p>
      <ene-accordeon *ngIf="reportMethods">
        <ene-accordeon-topic
          *ngFor="let report of reportMethods; let i = index"
          [title]="'DATA_PANEL.ACCOUNTING_PARAMETERS.' + report.method | translate | uppercase"
          [active]="i === reportMethods.length - 1"
        >
          <div class="method-icon">
            <img
              [src]="getAssetUrl('/images/info/methods/') + report.method + '.svg' | lowercase"
              alt="{{ report.method }}"
            />
            <p [innerHTML]="'REPORTS.' + report.method + '_DESCRIPTION' | translate"></p>
          </div>

          <div class="carousel-container">
            <ngu-carousel #carousel [inputs]="carouselConfig" [dataSource]="report.buttons">
              <ngu-item *nguCarouselDef="let buttons; index as i">
                <app-radio-group
                  name="{{ report.method }}"
                  [options]="radioOptions"
                  (change)="onChangeYear(report, $event)"
                >
                  <app-radio-button
                    *ngFor="let button of buttons; trackBy: trackByFn"
                    [disabled]="button.disabled"
                    [checked]="button.value === report.selectedYear"
                    [value]="button.value"
                  >
                    {{ button.value }}
                  </app-radio-button>
                </app-radio-group>
              </ngu-item>

              <button
                NguCarouselPrev
                class="carousel-arrow carousel__arrow--left"
                [disabled]="carousel.activePoint === 0"
              >
                <ene-icon icon="ec_networks_carousel-arrow"></ene-icon>
              </button>
              <button
                NguCarouselNext
                class="carousel-arrow carousel__arrow--right"
                [disabled]="carousel.activePoint === 2"
              >
                <ene-icon icon="ec_networks_carousel-arrow"></ene-icon>
              </button>

              <ul class="ngucarouselPoint" NguCarouselPoint>
                <li
                  *ngFor="let i of carousel.pointNumbers"
                  [class.active]="i == carousel.activePoint"
                  (click)="carousel.moveTo(i)"
                ></li>
              </ul>
            </ngu-carousel>
          </div>

          <button
            class="button report"
            (click)="openReport(report.method, report.selectedYear)"
            [disabled]="report.allYearsDisabled"
          >
            {{ "REPORTS.REPORT_BUTTON_TITLE" | translate }}
          </button>
        </ene-accordeon-topic>
      </ene-accordeon>
    </section>
    <section class="ene-surface-box-flat">
      <h3>{{ "REPORTS.REQUIREMENTS" | translate }}</h3>
      <p class="paragraph">{{ "REPORTS.REQUIREMENTS_DESCRIPTION" | translate }}</p>
      <div class="glossary__wrapper">
        <p [innerHTML]="'REPORTS.GLOSSAR_DESCRIPTION' | translate"></p>
        <button class="button glossary" (click)="openGlossary()">
          {{ "REPORTS.GLOSSARY_BUTTON_TITLE" | translate }}
        </button>
      </div>
    </section>
  </div>
</section>
