import { ContactNewActionType, ContactNewActions, ContactType } from "./contact.actions";
import { BASIC_ERROR, SEND_SUCCESS } from "../../configs/snackbar";

export interface ContactState {
  contactComponent: ContactType;
  status?: {
    error: boolean;
    message: string;
  };
}

export const initialState: ContactState = {
  contactComponent: ContactType.CONTACTS
};

export function reducer(state: ContactState = initialState, action: ContactNewActions): ContactState {
  switch (action.type) {
    case ContactNewActionType.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        status: {
          error: false,
          message: SEND_SUCCESS
        }
      };
    case ContactNewActionType.SEND_EMAIL_ERROR:
      return {
        ...state,
        status: {
          error: true,
          message: BASIC_ERROR
        }
      };
    case ContactNewActionType.SET_CURRENT_CONTACT_COMPONENT:
      return {
        ...state,
        contactComponent: action.payload
      };
    default:
      return state;
  }
}
