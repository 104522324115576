import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, Subject, combineLatest } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Helper } from "../share/helper";
import { acceptJsonWithoutCache } from "../state/common";
import { RegionPropertyKpiService } from "./region-property/region-property-kpi.service";
import { IRegionIdentifier, RegionType, UtilService } from "./util.service";

const hasValue = (value) => value != null;

@Injectable({
  providedIn: "root"
})
export class RegionService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  // sorted array beginns at selected level to top; [0] will be the selected region
  private _selectedRegionUpLevels: BehaviorSubject<IRegionLevel[]> = new BehaviorSubject(undefined);
  public readonly selectedRegionUpLevels$: Observable<
    IRegionLevel[]
  > = this._selectedRegionUpLevels.asObservable().pipe(filter(hasValue));
  public get selectedRegionUpLevels(): IRegionLevel[] {
    return this._selectedRegionUpLevels.getValue();
  }

  // Details for the selected region, will be updated on region and year change
  private _selectedRegionDetails: BehaviorSubject<IRegionDetails> = new BehaviorSubject(undefined);
  public readonly selectedRegionDetails$: Observable<IRegionDetails> = this._selectedRegionDetails
    .asObservable()
    .pipe(filter(hasValue));
  public get selectedRegionDetails(): IRegionDetails {
    return this._selectedRegionDetails.getValue();
  }

  // Buildings count for the selected region, will be updated on region and year change
  private _selectedRegionCountBuildings: BehaviorSubject<number> = new BehaviorSubject(undefined);
  public readonly selectedRegionCountBuildings$: Observable<
    number
  > = this._selectedRegionCountBuildings.asObservable().pipe(filter(hasValue));
  public get selectedRegionCountBuildings(): number {
    return this._selectedRegionCountBuildings.getValue();
  }

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private regionPropertyKpi: RegionPropertyKpiService
  ) {
    const regionIdentifier$ = this.utilService.regionIdentifier$;

    combineLatest([this.utilService.selectedYear$, regionIdentifier$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([year, regionIdentifier]) => {
        await this.updateSelectedRegionDetails(year, regionIdentifier);
        await this.updateSelectedRegionUpLevels(regionIdentifier);
      });
  }

  /**
   * Get the id of a specific level depending on the actual selection
   * @param level
   */
  public getSelectedRegionLevelId(level: number): IRegionIdentifier {
    if (!this.selectedRegionUpLevels) {
      return;
    }
    const regionLevel = this.selectedRegionUpLevels.find((region) => region.level === level);
    if (regionLevel) {
      return {
        regionType: regionLevel.region_type,
        regionId: regionLevel.region_id
      };
    }
  }

  /**
   * Will return all parent region levels sort by level, begin by max, because max is start
   * @param regionId
   * @param regionType
   */
  public getRegionUpLevels({ regionId, regionType }: IRegionIdentifier): Observable<IRegionLevel[]> {
    if (!regionId) {
      return EMPTY;
    }

    const url = `${environment.infrastructure.regionApi}/regions/${regionId}/parents/id`;
    const params = new HttpParams({ fromObject: { regionType } });

    return this.http
      .get<IResponse<IRegionLevel[]>>(url, { headers: new HttpHeaders(acceptJsonWithoutCache), params })
      .pipe(
        map((res) =>
          Helper.objToArrayDeep(res.result, { remRef: true })
            .map((e) => e.element)
            .sort((a, b) => b.level - a.level)
        )
      );
  }

  public getRegionChildLevels(regionId: string): Observable<IRegionChildLevels> {
    if (!regionId) {
      return EMPTY;
    }
    const url = `${environment.infrastructure.regionApi}/regions/${regionId}/childlevel`;
    return this.http
      .get<IResponse<IRegionChildLevels>>(url, { headers: new HttpHeaders(acceptJsonWithoutCache) })
      .pipe(map((res) => res.result));
  }

  private async updateSelectedRegionDetails(year: number, regionIdentifier: IRegionIdentifier) {
    const regionPropertiesPromise = this.regionPropertyKpi.getRegionProperties(regionIdentifier, year).toPromise();
    const buildingsCountPromise = this.regionPropertyKpi.getRegionBuildings(regionIdentifier, year).toPromise();
    const [regionDetails, countBuildings] = await Promise.all([regionPropertiesPromise, buildingsCountPromise]);
    this._selectedRegionDetails.next({
      ...regionDetails,
      ...regionIdentifier
    });
    this._selectedRegionCountBuildings.next(countBuildings);
  }

  private async updateSelectedRegionUpLevels(regionIdentifier: IRegionIdentifier) {
    const regionUpLevels = await this.getRegionUpLevels(regionIdentifier).toPromise();
    if (regionUpLevels) {
      this._selectedRegionUpLevels.next(regionUpLevels);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}

export interface IRegionProperties {
  area_m2: number;
  population: number;
  name: string;
  level: number;
}

/** This interface combines the region Identifier (regionId, regionType) with the region properties (kpis, name, etc) */
export interface IRegionDetails extends IRegionIdentifier, IRegionProperties {}

export interface IResponse<T> {
  result: T;
}

export interface IRegionLevel {
  level: number;
  region_id: string;
  region_type: RegionType;
}

export interface IRegionChildLevels {
  regionId: string;
  level: number;
  child: { level: string; value: number }[];
}

export interface IRegionParentResponse {
  parent?: IRegionParentResponse;
  level: number;
  region_id: string;
}
