<gp-kpi
  data-test="kpi-co2ReductionGoal"
  [kpiTitle]="'KPI.CO2_REDUCTION_GOAL' | translate"
  icon="gpi-filled-co2-emissions-delta"
  [value]="relativeChange$ | async"
  [unitConfig]="{
    category: 'percentage',
    base: '%',
    numberFormat: {
      maximumSignificantDigits: 4,
      minimumSignificantDigits: 3,
      signDisplay: 'always'
    }
  }"
>
  <app-weather-correction-indicator
    slot="indicator"
    [weatherCorrection]="(accounting$ | async)?.weatherCorrection"
  ></app-weather-correction-indicator>
</gp-kpi>
