import { Observable } from "rxjs";
import { ICategory } from "../../../../../../shared/src/lib/data-input/model/category.interface";

export interface INestedCategory {
  id: string;
  name: string;
  disabled?: boolean;
}

export class NavigationCategory {
  public readonly id: string;
  public readonly name: string;
  public readonly icon: string;
  public readonly disabled: boolean;
  public readonly isShown: Observable<boolean>;

  constructor(category: ICategory) {
    this.id = category.id;
    this.name = category.name;
    this.icon = category.icon;
    this.disabled = category.disabled;
  }
}
