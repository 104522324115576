import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { NotSavedAction } from "../navigation/navigation.component";

@Component({
  selector: "app-not-saved-dialog",
  templateUrl: "./not-saved-dialog.component.html",
  styleUrls: ["./not-saved-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotSavedDialogComponent {
  constructor(private dialogRef: MatDialogRef<NotSavedDialogComponent, NotSavedAction>) {}

  public undoChanges() {
    this.dialogRef.close("undo");
  }

  public save() {
    this.dialogRef.close("save");
  }

  public close() {
    this.dialogRef.close("back");
  }
}
