import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { get } from "lodash";
import { combineLatest, Subject } from "rxjs";
import { filter, map, startWith, takeUntil } from "rxjs/operators";
import { UtilService } from "../../../../services/util.service";
import { assetUrl } from "../../../../share/asset-url";

@Component({
  selector: "app-report-preview",
  templateUrl: "./report-preview.component.html",
  styleUrls: ["./report-preview.component.scss"]
})
export class ReportPreviewComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private utilService: UtilService,
    private translate: TranslateService,
    private ref: ChangeDetectorRef
  ) {}

  public url?: string;
  public image?: SafeHtml;
  private destroy$ = new Subject();

  ngOnInit(): void {
    combineLatest([
      this.utilService.clientConfig$,
      this.translate.onLangChange.pipe(
        map((langEvent) => langEvent.lang.split("-")[0]),
        startWith("de")
      )
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([state]) => Boolean(state)),
        map(([clientConfig, lang]: Array<any>) => {
          if (get(clientConfig, "module[0].panels.reports.reportDemo", "") === "generic") {
            const svgUrl = assetUrl(
              `/images/info/sectors/report-demo-${get(
                clientConfig,
                "module[0].panels.reports.reportDemo",
                ""
              )}-${lang}.svg`
            );
            const headers = new HttpHeaders();
            headers.set("Accept", "image/svg+xml");
            this.http.get(svgUrl, { headers, responseType: "text" }).subscribe((image: string) => {
              this.image = this.sanitizer.bypassSecurityTrustHtml(image);
              this.ref.detectChanges();
            });
          } else {
            this.url = assetUrl(
              `/images/info/sectors/report-demo-${get(
                clientConfig,
                "module[0].panels.reports.reportDemo",
                ""
              )}-${lang}.png`
            );
          }
        })
      )
      .subscribe();
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
