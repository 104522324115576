import { Component, Inject, Input, NgZone, OnDestroy, OnInit } from "@angular/core";
import { unsubscribeFromEvent } from "@enersis/gp-components/events";
import { IGpMapOptions, IGpViewerFacade, ISelectionResult, MapConfig } from "@enersis/gp-components/gp-map2-viewer";
import {
  EventSubscriptionToken,
  Extension,
  SliceSubscription,
  getExtension,
  subscribeToMapEvent
} from "@enersis/gp-components/map";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subject, combineLatest } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  switchMap,
  takeUntil
} from "rxjs/operators";
import { ElectricityMix } from "../../../accounting/accounting-common/model/electricity-mix.enum";
import { WeatherCorrection } from "../../../accounting/accounting-common/model/weather-correction.enum";
import {
  ACTIVE_ACCOUNTING_SERVICE_TOKEN,
  IActiveAccountingService
} from "../../../accounting/accounting-common/services/active-accounting.interface";
import { ISelectedRegionService, SELECTED_REGION_SERVICE_TOKEN } from "../../../common/state/selected-region.interface";
import { MapConfigService } from "../../services/map-config.service";
@Component({
  selector: "co2-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"]
})
export class MapComponent implements OnInit, OnDestroy {
  @Input() public panelsWidth!: number;

  public mapName = "co2balance-map";
  public mapConfig!: MapConfig;
  public mapOptions!: IGpMapOptions;
  public mapLayers: any | undefined = [];
  public legendTranslationMap$!: Observable<Record<string, string>>;

  private destroy$ = new Subject();
  private eventSubscriptions = new Set<EventSubscriptionToken>();
  private extensionSubscriptions: Array<SliceSubscription> = [];
  private gpViewer!: IGpViewerFacade;
  private mapZoom$!: BehaviorSubject<number>;
  private filterExtension!: Extension<"filterControl">;

  constructor(
    @Inject(ACTIVE_ACCOUNTING_SERVICE_TOKEN) private activeAccountingService: IActiveAccountingService,
    @Inject(SELECTED_REGION_SERVICE_TOKEN) private selectedRegionService: ISelectedRegionService,
    private mapConfigService: MapConfigService,
    private translate: TranslateService,
    private zone: NgZone
  ) {}

  public ngOnInit() {
    this.loadInitialConfig();
    this.subscribeToMapEvents();
    this.handleLegendTranslations();
    this.extensionSubscriptions.push(
      getExtension(this.mapName, "filterControl").subscribe((extension) => (this.filterExtension = extension))
    );
  }

  private handleLegendTranslations() {
    this.legendTranslationMap$ = combineLatest([
      this.translate.onLangChange.pipe(
        map((event) => event.translations),
        startWith(this.translate.translations[this.translate.currentLang]),
        distinctUntilChanged()
      ),
      this.translate.onDefaultLangChange.pipe(
        map((event) => event.translations),
        startWith(this.translate.translations[this.translate.getDefaultLang()]),
        distinctUntilChanged()
      )
    ]).pipe(
      map(([currentTranslations, fallBackTranslations]) => ({
        ...fallBackTranslations,
        ...currentTranslations
      })),
      takeUntil(this.destroy$)
    );
  }

  private loadInitialConfig(): void {
    combineLatest([this.selectedRegionService.getSelectedRegion(), this.activeAccountingService.getActiveAccounting()])
      .pipe(
        // Using debounceTime because sometimes both region and year are changed at the same time
        debounceTime(50),
        map(([region, accounting]) => {
          return {
            region,
            accounting
          };
        }),
        filter(({ region, accounting }) => region.regionId === accounting.region.regionId),
        takeUntil(this.destroy$)
      )
      .pipe(
        first(),
        switchMap(({ region, accounting }) => this.mapConfigService.getMapConfig(region, accounting))
      )
      .subscribe((config) => {
        this.mapConfig = config;
        this.mapZoom$ = new BehaviorSubject(config.viewer.zoom || 0);
      });
    this.mapOptions = this.mapConfigService.getMapOptions();
  }

  private subscribeToMapEvents() {
    this.eventSubscriptions.add(
      subscribeToMapEvent(this.mapName, "stateChanged", (event) => {
        if (event.state === "mapReady") {
          this.gpViewer = event.viewer;
          this.updateSourceParamsUponChange();
          this.updateFiltersUponAccountingMethodChange();
          this.flyToRegionUponRegionChangeInHeader();
        }
      })
    );

    this.eventSubscriptions.add(
      subscribeToMapEvent(this.mapName, "selected", (event) => {
        this.selection(event);
      })
    );
  }

  private flyToRegionUponRegionChangeInHeader(): void {
    this.selectedRegionService
      .getRegionChangedInHeader()
      .pipe(takeUntil(this.destroy$))
      .subscribe((region) => {
        this.gpViewer.setCamera({ center: region.coordinates });
        this.gpViewer.flyTo();
      });
  }

  private updateFiltersUponAccountingMethodChange(): void {
    this.activeAccountingService
      .getActiveAccounting()
      .pipe(
        distinctUntilChanged((x, y) => x.accountingMethod === y.accountingMethod),
        map((accounting) => accounting.getSectors()),
        takeUntil(this.destroy$)
      )
      .subscribe((sectors) => {
        this.filterExtension.setConfig(
          {
            filters: this.mapConfigService.getFilters(sectors)
          },
          false
        );
      });
  }

  private updateSourceParamsUponChange(): void {
    this.activeAccountingService
      .getActiveAccounting()
      .pipe(takeUntil(this.destroy$))
      .subscribe((accounting) => {
        this.updateSourceParam("year", accounting.year);
        this.updateSourceParam("regionId", accounting.region.regionId);
        this.updateSourceParam("accountingMethod", accounting.accountingMethod);
        this.updateSourceParam("useUserFactors", accounting.useUserFactors);
        this.updateSourceParam("timestamp", accounting.timestamp);
        this.updateSourceParam(
          "weatherCorrection",
          accounting.weatherCorrection === WeatherCorrection.ON ? true : false
        );
        this.updateSourceParam("localMix", accounting.electricityMix === ElectricityMix.REGION_SPECIFIC ? true : false);
      });
  }

  private updateSourceParam(name: string, value: string | number | boolean | Array<string>): void {
    Object.values(this.gpViewer?.gpSources || {})
      .filter(({ config }) => config.urlParams?.hasOwnProperty(name))
      .forEach((source) => source.setUrlParam(name, value));
  }

  private selection(selection: ISelectionResult) {
    const feature = selection?.layers?.[0];
    this.zone.run(() => {
      if (feature && feature.includeFeatures?.length) {
        this.selectedRegionService.setSelectedRegion({
          regionId: feature.includeFeatures[0].properties?.["region_id"],
          regionType: this.mapConfigService.getRegionType(feature.layerId)
        });
      }
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.extensionSubscriptions.forEach((subscription) => subscription.unsubscribe());
    this.eventSubscriptions.forEach((subscription) => unsubscribeFromEvent(subscription));
  }
}
