/** angular and third party imports */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { NguCarouselModule } from "@ngu/carousel";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { registerLocaleData, CommonModule } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeDe from "@angular/common/locales/de";
import localeDeCH from "@angular/common/locales/de-CH";
// en-US is imported as standard
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeDeCH);

/** ngrx */
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { isObject } from "lodash";

import {
  reducerToken,
  reducerProvider,
  environment,
  TokenInterceptor,
  AppEffects,
  FooterModule,
  Co2CockpitModule,
  ReportsPanelModule,
  PopUpModule,
  AppDqiModule,
  InfoPanelModule,
  DqiTableModule,
  DataPanelModule,
  CommentModule,
  ProgressBarModule,
  SearchboxModule,
  SimulationModule,
  DirectivesModule,
  PipesModule,
  BenchmarkingModule,
  EnergymixSelectComponent,
  ChartEnergyEfficiencyComponent,
  DashboardComponent,
  DownloadReportComponent,
  assetUrl,
  AuthenticationService,
  MeasuresModule,
  MeasuresEffects,
  SetsEffects,
  SimulationEffects,
  DatabaseChartEffects,
  BenchmarkEffects,
  ChartBenchmarkingEffects,
  ChartCo2EmissionSectorsEffects,
  Co2CockpitEffects,
  Co2SavingEffects,
  Co2SavingForAllYearsEffects,
  ConsumptionEffects,
  DistrictEffects,
  EMobilityEffects,
  GasCockpitKpiEffects,
  GasNetworkEffects,
  GetAccountingMethodsConfigEffects,
  HeatpumpEffects,
  LocalEnergyMixEffects,
  MainPolluterEffects,
  NetworkEffects,
  PutAccountingMethodsConfigEffects,
  RouterEffects,
  TourEffects,
  EneEditModule,
  IEnvironment
} from "@energy-city/components";

import {
  Co2balanceSidenavigationComponent,
  DiagramsPanelComponent,
  AuthenticationModule,
  AuthService,
  PlatformEnvFileModule
} from "@energy-city/co2balance-components";
import { GlossaryService } from "@energy-city/co2balance-components/services";

/** modules */
import { EneTooltipModule } from "@energy-city/ui/tooltip";
import { AppRoutingModule } from "./app-routing.module";

/* ENE UI MODULES  */
import { EneUiService } from "@energy-city/ui/helper";
import { EneKpisModule, EneKpisService } from "@energy-city/ui/kpis";
import { EnePopperModule } from "@energy-city/ui/popper";
import { EneModalModule, EneModalService } from "@energy-city/ui/modal";

import { CdkTableModule } from "@angular/cdk/table";
/** components */
import { AppComponent } from "./app.component";

import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { MatIconModule } from "@angular/material/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EmptyRouteComponent } from "./empty-route/empty-route.component";

import "@enersis/gp-components/gp-sidebar";
import "@enersis/gp-components/gp-kpis-box";
import "@enersis/gp-components/gp-kpi";
import "@enersis/gp-components/gp-timeline";
import "@enersis/gp-components/gp-icon";
import "@enersis/gp-components/gp-chart-emission-sunburst";
import "@enersis/gp-components/gp-chart-export-menu";
import "@enersis/gp-components/gp-tabs";
import "@enersis/gp-components/gp-spinner";

import {
  ConfigDataService,
  CONFIG_DATA_SERVICE_TOKEN,
  SelectedRegionService,
  SELECTED_REGION_SERVICE_TOKEN,
  StateModule,
  TokenService,
  TOKEN_SERVICE_TOKEN
} from "@co2-shared/common";
import { ComponentsModule } from "@co2-shared/common";
import {
  AccountingCommonModule,
  ActiveAccountingService,
  ACTIVE_ACCOUNTING_SERVICE_TOKEN
} from "@co2-shared/accounting-common";
import { AccountingEmissionModule } from "@co2-shared/accounting-emission";
import { AccountingEnergyModule } from "@co2-shared/accounting-energy";
import { MapModule } from "@co2-shared/map";
import { EneSnackbarModule } from "libs/ui/src/lib/snackbar";
import { EneFormatUnitPipe, EneUiPipesModule } from "libs/ui/src/lib/pipes";
import { EneIconModule } from "libs/ui/src/lib/icon";
import { EneAccordeonModule } from "libs/ui/src/lib/accordeon";
import { EneChartModule } from "libs/ui/src/lib/chart";
import { EneSearchModule } from "@enersis/search";
import { EneFilterModule } from "../../../../libs/ui/src/lib/filter";
import { EneFormsModule } from "../../../../libs/ui/src/lib/forms";
import { EneHeaderModule } from "../../../../libs/ui/src/lib/header";
import { EneKpisV2Module } from "../../../../libs/ui/src/lib/kpis-v2";
import { EneSidenavModule } from "../../../../libs/ui/src/lib/sidenav";
import { EneTabsModule } from "../../../../libs/ui/src/lib/tabs";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, assetUrl("i18n/"), ".json");
}

const ENE_MODULES = [
  EneHeaderModule,
  EneIconModule,
  EneSidenavModule,
  EneKpisModule,
  EneModalModule,
  EnePopperModule,
  EneTabsModule,
  EneFilterModule,
  EneFormsModule,
  EneSearchModule,
  EneTooltipModule,
  EneAccordeonModule,
  EneEditModule,
  EneSnackbarModule.forRoot({
    preventDuplicates: true
  }),
  EneKpisV2Module.forRoot(),
  EneChartModule.forRoot(),
  EneUiPipesModule.forRoot()
];

const EFFECTS = [
  AppEffects,
  RouterEffects,
  SimulationEffects,
  BenchmarkEffects,
  TourEffects,
  DistrictEffects,
  Co2SavingEffects,
  EMobilityEffects,
  HeatpumpEffects,
  Co2SavingForAllYearsEffects,
  ConsumptionEffects,
  NetworkEffects,
  ChartCo2EmissionSectorsEffects,
  LocalEnergyMixEffects,
  Co2CockpitEffects,
  ChartBenchmarkingEffects,
  GetAccountingMethodsConfigEffects,
  PutAccountingMethodsConfigEffects,
  GasCockpitKpiEffects,
  GasNetworkEffects,
  MainPolluterEffects,
  MeasuresEffects,
  DatabaseChartEffects,
  SetsEffects
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    Co2balanceSidenavigationComponent,
    EnergymixSelectComponent,
    ChartEnergyEfficiencyComponent,
    DownloadReportComponent,
    EmptyRouteComponent,
    DiagramsPanelComponent
  ],
  imports: [
    PlatformEnvFileModule,
    MeasuresModule,
    CommonModule,
    BenchmarkingModule,
    MapModule,
    StateModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AuthenticationModule,
    AppRoutingModule,
    StoreModule.forRoot(reducerToken, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot(EFFECTS),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CdkTableModule,
    MatStepperModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ...ENE_MODULES,
    NguCarouselModule,
    DirectivesModule,
    PipesModule,
    FooterModule,
    DqiTableModule,
    ProgressBarModule,
    SearchboxModule,
    DataPanelModule,
    ReportsPanelModule,
    InfoPanelModule,
    Co2CockpitModule,
    AppDqiModule,
    ReactiveFormsModule,
    CommentModule,
    SimulationModule,
    PopUpModule,
    AccountingCommonModule,
    AccountingEmissionModule,
    AccountingEnergyModule,
    ComponentsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: AuthenticationService, useClass: AuthService },
    { provide: TOKEN_SERVICE_TOKEN, useClass: TokenService },
    { provide: SELECTED_REGION_SERVICE_TOKEN, useClass: SelectedRegionService },
    { provide: ACTIVE_ACCOUNTING_SERVICE_TOKEN, useClass: ActiveAccountingService },
    { provide: CONFIG_DATA_SERVICE_TOKEN, useClass: ConfigDataService },
    reducerProvider,
    EneFormatUnitPipe,
    // services
    EneUiService,
    EneKpisService,
    EneModalService,
    GlossaryService
  ],
  entryComponents: [DownloadReportComponent, ChartEnergyEfficiencyComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
