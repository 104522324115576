import { Component, Input, OnInit, Injector, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { PrintService } from "../../services/print.service";
import {
  UtilService,
  RegionPropertyEmissionService,
  AccountingMethodService,
  EntryComponents,
  sectorConfig
} from "@energy-city/components";
import { NUMBER_FORMAT } from "../../reports.config";
import { HideExport, YEAR } from "../../../../modules/co2-cockpit/charts/const";
import { EnergyTypes } from "../../../../configs/accounting-method";
import { TranslateService } from "@ngx-translate/core";

export type IMaxValues = Partial<Record<EnergyTypes, number>>;
enum TableColumns {
  SECTOR = "sector",
  EMISSION = "emission"
}

@Component({
  selector: "app-sectors-emission-page",
  templateUrl: "./sectors-emission.component.html",
  styleUrls: ["./sectors-emission.component.scss"]
})
export class SectorsEmissionPageComponent implements OnInit, OnDestroy {
  @Input() public config;

  public numberFormat = NUMBER_FORMAT;
  public locale: string;
  public component: any;
  public columns: Array<any>;
  public displayedColumns: Array<string>;
  public data$: Observable<any>;
  public year: number;
  public propsInjector: Injector;
  public tableColumns = TableColumns;

  private destroy$ = new Subject<void>();

  constructor(
    private printService: PrintService,
    private utilService: UtilService,
    private injector: Injector,
    private accService: AccountingMethodService,
    private emissionService: RegionPropertyEmissionService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.locale = this.translate.currentLang;
    this.component = EntryComponents.charts.ChartCo2SectorsComponent;
    this.year = this.printService.selectedReportYear;
    this.columns = this.getColumns();
    this.displayedColumns = this.columns.map((item) => item.id);
    this.data$ = this.getData();
    this.propsInjector = Injector.create(
      [
        { provide: YEAR, useValue: this.year },
        { provide: HideExport, useValue: true }
      ],
      this.injector
    );

    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.locale = this.translate.currentLang));
  }

  private getColumns(): Array<any> {
    return [
      {
        id: TableColumns.SECTOR,
        name: this.translate.instant("APP.SECTOR")
      },
      {
        id: TableColumns.EMISSION,
        name: `${this.translate.instant("APP.EMISSIONS")} [t CO₂e]`
      }
    ];
  }

  private getData(): Observable<any> {
    const sectorQuery = sectorConfig.sectors
      .filter((sector) => sector.methods.includes(this.accService.selectedAccountingMethod$.value))
      .map((sector) => ({
        ...sector,
        level: 1,
        weatherCorrection: sector.weatherCorrection ? this.accService.weatherCorrection$.value : null
      }));

    return this.utilService.regionIdentifier$.pipe(
      switchMap((regionIdentifier) =>
        this.emissionService.getCo2EmissionSectorData(
          regionIdentifier,
          sectorQuery,
          this.printService.selectedReportYear
        )
      ),
      map((emissionsSectors) => {
        const getObjectKeys = (array: object) => Object.keys(array).filter((key) => key !== "year");
        const calculateMax = (keylist: string[], data: any) => keylist.reduce((acc, key) => acc + data[key].value, 0);
        let maxValue = 0;

        return emissionsSectors
          .filter(({ result: [result] }) => Boolean(result))
          .map(({ sector, result: [result] }) => {
            const keys = getObjectKeys(result);
            const maxSectorValue = calculateMax(keys, result);
            maxValue += maxSectorValue;

            return {
              result,
              sector,
              keys,
              maxSectorValue
            };
          })
          .sort((a, b) => b.maxSectorValue - a.maxSectorValue)
          .reduce((acc, emissions) => {
            const item = {
              name: emissions.sector.type,
              parent: true,
              value: emissions.maxSectorValue,
              percent: this.getPercent(emissions.maxSectorValue, maxValue)
            };

            const list = emissions.keys
              .map((key) => ({
                name: key,
                value: emissions.result[key].value,
                percent: this.getPercent(emissions.result[key].value, emissions.maxSectorValue)
              }))
              .sort((a, b) => b.value - a.value);

            return [...acc, item, ...list];
          }, []);
      })
    );
  }

  private getPercent(current: number, max: number): number {
    return (current / max) * 100;
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
