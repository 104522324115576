import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfigDataService } from "./config-data.service";
import { ConfigDataStandaloneService } from "./config-data-standalone.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ConfigDataService, ConfigDataStandaloneService]
})
export class ConfigModule {}
