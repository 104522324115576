import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { map, switchMap, catchError, withLatestFrom } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { of } from "rxjs";
import {
  MeasuresActionTypes,
  GetMeasures,
  GetMeasuresSuccess,
  GetMeasuresError,
  CreateMeasure,
  EditMeasure,
  SaveMeasureSuccess,
  SaveMeasureError
} from ".";
import { getEditingMeasure } from "./measures.selectors";
import { IApplicationState } from "..";
import { Store } from "@ngrx/store";
import { DeleteMeasure } from "./measures.actions";

@Injectable()
export class MeasuresEffects {
  constructor(private actions$: Actions, private store: Store<IApplicationState>, private http: HttpClient) {}

  @Effect()
  public getMeasures = this.actions$.pipe(
    ofType(MeasuresActionTypes.GET_MEASURES),
    switchMap((action: GetMeasures) => {
      return this.http
        .get<{ result: Array<any> }>(`${environment.simulation}/regions/${action.regionId}/sets/${action.setId}`)
        .pipe(
          map(({ result }) => new GetMeasuresSuccess(result)),
          catchError((err) => of(new GetMeasuresError(err)))
        );
    })
  );

  @Effect()
  public deleteMeasures = this.actions$.pipe(
    ofType(MeasuresActionTypes.DELETE_MEASURE),
    switchMap((action: DeleteMeasure) => {
      return this.http
        .delete<{ result: Array<any> }>(
          `${environment.simulation}/regions/${action.regionId}/sets/${action.setId}/measures/${action.measureId}`
        )
        .pipe(
          map(() => new SaveMeasureSuccess()),
          catchError((err) => of(new SaveMeasureError(err)))
        );
    })
  );

  @Effect()
  public createMeasure = this.actions$.pipe(
    ofType(MeasuresActionTypes.CREATE_MEASURE),
    switchMap((action: CreateMeasure) => {
      return this.http
        .post(`${environment.simulation}/regions/${action.regionId}/sets/${action.setId}/measures`, action.measure)
        .pipe(
          map(() => new SaveMeasureSuccess()),
          catchError((err) => of(new SaveMeasureError(err)))
        );
    })
  );

  @Effect()
  public editMeasure = this.actions$.pipe(
    ofType<EditMeasure>(MeasuresActionTypes.EDIT_MEASURE),
    withLatestFrom(this.store.select(getEditingMeasure)),
    switchMap(([action, { measure_id }]) => {
      return this.http
        .patch(
          `${environment.simulation}/regions/${action.regionId}/sets/${action.setId}/measures/${measure_id}`,
          action.measure
        )
        .pipe(
          map(() => new SaveMeasureSuccess()),
          catchError((err) => of(new SaveMeasureError(err)))
        );
    })
  );
}
