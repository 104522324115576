import { ActionReducerMap } from "@ngrx/store/src/models";

import { reducer } from "./glossar-reducer";
import { GlossarActionType } from "./glossar-actions";

export const glossarReducers: ActionReducerMap<any> = {
  glossar: reducer
};

export interface IGlossarState {
  type: GlossarActionType;
  payload: any;
}

export interface State {}
