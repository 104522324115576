<section *ngIf="sectors" class="sectors">
  <h3>
    {{ "CLIENTS.DEFAULT.INFO.DETAILS.DATA_FOUNDATION" | translate }}
  </h3>
  <ul>
    <li class="root">
      <h4>
        {{ "RESOURCES." + sectors.field | uppercase | translate }}
      </h4>
    </li>
    <li *ngFor="let sector of sectors.children">
      {{ "RESOURCES." + sector.field | uppercase | translate }}
    </li>
  </ul>
</section>

<section *ngIf="sources" class="sources info-padded-box">
  <h4>{{ "CLIENTS.DEFAULT.INFO.DETAILS.SOURCES" | translate }}</h4>
  <ul>
    <li *ngFor="let source of sources">
      <span>{{ source.name }}</span>
      <app-dqi [dqi]="source.dqi" [disabled]="true"></app-dqi>
    </li>
  </ul>
</section>

<section class="standards info-padded-box" *ngIf="accountingMethods">
  <span>{{ "CLIENTS.DEFAULT.INFO.DETAILS.METHODOLOGY" | translate }}</span>
  <img *ngIf="accountingMethods.bisko" [src]="getAssetUrl('/images/info/methods/bisko.svg')" alt="BISKO" />
  <img *ngIf="accountingMethods.bico2sh" [src]="getAssetUrl('/images/info/methods/bico2sh.svg')" alt="BICO2SH" />
  <img *ngIf="accountingMethods.gpc" [src]="getAssetUrl('/images/info/methods/gpc.svg')" alt="GPC" />
</section>
