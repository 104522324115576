import { EmissionDataService } from "../../../accounting-emission/data/emission-data.service";
import { AfoluSector } from "../sectors/afolu-sector";
import { IppuSector } from "../sectors/ippu-sector";
import { MainGhgSector } from "../sectors/main-ghg-sector.interface";
import { StationaryEnergySector } from "../sectors/stationary-energy-sector";
import { TransportationSector } from "../sectors/transportation-sector";
import { WasteAndWasteWaterSector } from "../sectors/waste-and-waste-water-sector";
import { AccountingMethod } from "./accounting-method.interface";

export class AccountingMethodGPC implements AccountingMethod {
  constructor(private emissionDataService: EmissionDataService) {}
  getSectors(): MainGhgSector[] {
    return [
      new StationaryEnergySector(this.emissionDataService),
      new TransportationSector(this.emissionDataService),
      new AfoluSector(this.emissionDataService),
      new WasteAndWasteWaterSector(this.emissionDataService),
      new IppuSector(this.emissionDataService)
    ];
  }
}
