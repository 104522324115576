import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { EneSearchModule } from "@enersis/search";
import { EneIconModule } from "@energy-city/ui/icon";
import { TranslateModule } from "@ngx-translate/core";
import { EnePopperModule } from "@energy-city/ui/popper";
import { SearchboxComponent } from "./searchbox.component";

@NgModule({
  declarations: [SearchboxComponent],
  imports: [CommonModule, EneSearchModule, EneIconModule, TranslateModule, EnePopperModule, ReactiveFormsModule],
  exports: [SearchboxComponent]
})
export class SearchboxModule {}
