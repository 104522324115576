import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromRoot from "@energy-city/components";
import { ContactType, SendEmailNew, getSendStatus, AuthActionType } from "@energy-city/components";
import { EneModalService } from "@energy-city/ui/modal";
import { FormControl } from "@angular/forms";
import { takeUntil, skip } from "rxjs/operators";
import { Subject } from "rxjs";
import { EneSnackbarService } from "@energy-city/ui/snackbar";
import { ofType } from "@ngrx/effects";
import { ContactFormSelectOptions } from "./contact-form.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-contact-form-new",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"]
})
export class ContactFormNewComponent implements OnInit, OnDestroy {
  @Input() public type: ContactType = ContactType.FEEDBACK;
  public message: FormControl;
  public select: FormControl;
  public selectOptions;
  private destroy$: Subject<void> = new Subject();
  private contactObject: any;
  constructor(
    private modalService: EneModalService,
    private store: Store<fromRoot.IApplicationState>,
    private snackbar: EneSnackbarService,
    private translation: TranslateService
  ) {}

  public ngOnInit() {
    this.store
      .select(fromRoot.GetAuthState)
      .pipe(ofType(AuthActionType.LOGIN_SUCCESS), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        const authState = res.payload;
        let ags = String(authState.ags);
        if (ags.length !== 8) {
          ags = `0${ags}`;
        }
        this.contactObject = {
          customerSupportId: `${authState.customer_support_id || ""}`,
          municipality: authState.municipality_name,
          userName: `${authState.given_name || ""} ${authState.family_name || ""}`.trim(),
          userEmail: authState.email || "noreply@enersis.ch",
          url: document.location.hostname,
          ags
        };
      });

    this.selectOptions = ContactFormSelectOptions[this.type];
    this.message = new FormControl();
    this.select = new FormControl(this.selectOptions.options[0].id);

    this.store
      .select(getSendStatus)
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.error) {
          this.snackbar.error(status.message);
        } else {
          this.snackbar.success(status.message);
          this.cancel();
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public cancel() {
    this.modalService.close("contact");
  }

  public send() {
    this.message.markAsTouched();
    if (!this.message.valid) {
      return;
    }
    this.store.dispatch(
      new SendEmailNew(
        {
          ...this.contactObject,
          text: this.message.value,
          topic: this.translation.instant(this.select.value)
        },
        this.type
      )
    );
  }
}
