import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { EneModalService } from "@energy-city/ui/modal";
import { TranslateService } from "@ngx-translate/core";
import { fromEvent, merge, Observable } from "rxjs";
import { map, skip } from "rxjs/operators";

const ASSETS_PATH = "/assets/images/";
@Component({
  selector: "app-dashboard-hansewerk",
  templateUrl: "./dashboard-hansewerk.component.html",
  styleUrls: ["./dashboard-hansewerk.component.scss"]
})
export class DashboardHansewerkComponent implements AfterViewInit {
  @Input() public fileName: string;

  public imageFile$: Observable<string>;

  @ViewChild("dashboardImage") private dashboardImageElement: ElementRef;

  constructor(private modal: EneModalService, private translate: TranslateService) {}

  public ngAfterViewInit() {
    const languageOnly = (locale: string) => locale.split("-")[0];
    const defaultLang = languageOnly(this.translate.defaultLang);

    // get the current language ("de", "en", ..) as an Observable.
    const currentLang$ = this.translate.onLangChange.pipe(map((langChangeEvent) => languageOnly(langChangeEvent.lang)));

    // get the "error" event from the img-element, and return the default language ("de") every time it emits.
    const loadError$ = fromEvent(this.dashboardImageElement.nativeElement, "error").pipe(
      skip(1), // skip the first error, which will emit before the first image source was set.
      map(() => defaultLang)
    );

    this.imageFile$ = merge(currentLang$, loadError$).pipe(
      map((lang) => {
        if (lang === defaultLang) {
          return ASSETS_PATH + this.fileName;
        }
        const translatedImage = this.fileName.replace(".", `_${lang}.`);
        return ASSETS_PATH + translatedImage;
      })
    );
  }

  public close(): void {
    this.modal.close("static-dashboard");
  }
}
