<ng-container *ngIf="!(isLoading$ | async); else dataLoadingTemplate">
  <ng-container *ngIf="!(errorMessage$ | async); else hasErrorTemplate">
    <div class="input-table table-container" *ngIf="dataSource && dataSource.length; else nodataTemplate">
      <!-- Unit Drop-down field -->
      <div class="input-table__unit-cell">
        <app-units-filter [options]="units" [value]="selectedUnit"></app-units-filter>
      </div>

      <!-- Column Headers -->
      <table class="input-table__header">
        <tr>
          <th *ngFor="let column of columns">{{ column }}</th>
        </tr>
      </table>

      <!-- Row Headers -->
      <table class="input-table__aside">
        <tr *ngFor="let item of asides; trackBy: trackByFn">
          <td
            [ngClass]="{
              root: item?.dependency === false,
              nested: item?.dependency
            }"
          >
            {{ "RESOURCES." + item.name | uppercase | translate }}
          </td>
        </tr>
      </table>

      <!-- Table Data -->
      <table cdk-table [dataSource]="dataSource" class="input-table__table">
        <ng-container *ngFor="let column of columns" [cdkColumnDef]="column">
          <th cdk-header-cell *cdkHeaderCellDef>{{ column }}</th>
          <td cdk-cell *cdkCellDef="let element">
            <ng-container *ngIf="element[column]?.dependency !== false">
              <app-table-cell [element]="element[column]"></app-table-cell>
            </ng-container>
          </td>
        </ng-container>
        <tr
          cdk-row
          *cdkRowDef="let row; columns: columns; let index = index"
          [ngClass]="{
            odd: asides[index].isOdd,
            root: asides[index]?.dependency === false
          }"
        ></tr>
      </table>
    </div>
  </ng-container>
</ng-container>
<!-- No data template -->
<ng-template #nodataTemplate>
  <div class="centered">{{ "DATA_PANEL.NO_DATA" | translate }}</div>
</ng-template>
<ng-template #hasErrorTemplate>
  <div class="centered">{{ errorMessage$ | async | translate }}</div>
</ng-template>
<ng-template #dataLoadingTemplate>
  <div class="centered">
    <div class="ene-spinner">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>
