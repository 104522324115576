import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class FooterService {
  public onclick$: Observable<any>;
  private onclick = new Subject();

  constructor() {
    this.onclick$ = this.onclick.asObservable();
  }

  public setOutputValue(value: any): void {
    this.onclick.next(value);
  }
}
