<main class="introduction-modal">
  <div class="info-header">
    <ene-icon icon="ec_info" class="icon"></ene-icon>
    <div class="title">
      <h2>{{ "INTRODUCTION_MODAL.TITLE" | translate }}</h2>
    </div>
  </div>

  <ene-tabs #tabs>
    <ene-tab [tabTitle]="'INTRODUCTION_MODAL.NAVIGATION_TABS.SUPPORTED_BROWSERS' | translate" [active]="true">
      <ng-container *ngTemplateOutlet="supportedBrowsers"></ng-container>
    </ene-tab>

    <ene-tab [tabTitle]="'INTRODUCTION_MODAL.NAVIGATION_TABS.ZOOM_CONTROLS' | translate">
      <ng-container *ngTemplateOutlet="zoomTab"></ng-container>
    </ene-tab>

    <ene-tab [tabTitle]="'INTRODUCTION_MODAL.NAVIGATION_TABS.GUIDED_TOUR' | translate">
      <ng-container *ngTemplateOutlet="guidedTour"></ng-container>
    </ene-tab>
  </ene-tabs>
</main>

<ng-template #supportedBrowsers>
  <section class="tab-description">
    <span>
      {{ "INTRODUCTION_MODAL.BROWSER_TAB.DESCRIPTION" | translate }}
    </span>
  </section>
  <section class="tab-title">{{ "INTRODUCTION_MODAL.BROWSER_TAB.OPTIMIZED_BROWSERS.TITLE" | translate }}</section>
  <section class="tab-options">
    <div class="option browser">
      <img [src]="getAssetUrl('/images/introduction-modal/logo_chrome.svg')" />
      Google Chrome
    </div>

    <div class="option browser">
      <img [src]="getAssetUrl('/images/introduction-modal/logo_firefox.svg')" />
      Mozilla Firefox
    </div>

    <div class="option browser">
      <img [src]="getAssetUrl('/images/introduction-modal/logo_edge.svg')" />
      Microsoft Edge
    </div>
  </section>
  <section class="tab-title">{{ "INTRODUCTION_MODAL.BROWSER_TAB.REDUCED_FUNC_BROWSER.TITLE" | translate }}</section>
  <section class="tab-options">
    <div class="option browser">
      <img [src]="getAssetUrl('/images/introduction-modal/logo_ie.svg')" />
      Microsoft Internet Explorer
    </div>
  </section>
  <section class="tab-note">
    <input
      type="checkbox"
      id="checkbox-first"
      class="ene-checkbox"
      [checked]="checkBoxState"
      (change)="changeChecked($event)"
    />
    <label for="checkbox-first" class="note">{{ "INTRODUCTION_MODAL.SHOW_NOTE" | translate }}</label>
  </section>

  <button class="ene-button-cta" (click)="changeTab(1)">
    <span>{{ "INTRODUCTION_MODAL.NEXT" | translate }}</span>
    <ene-icon icon="ec_controls_collapsed_dropdown"></ene-icon>
  </button>
</ng-template>

<ng-template #zoomTab>
  <section class="tab-description">
    <span>
      {{ "INTRODUCTION_MODAL.ZOOM_TAB.DESCRIPTION" | translate }}
    </span>
  </section>
  <section class="tab-title">{{ "INTRODUCTION_MODAL.ZOOM_TAB.CONTROLS_BROWSER.TITLE" | translate }}</section>
  <section class="tab-options">
    <div class="option browser">
      <img [src]="getAssetUrl('/images/introduction-modal/logo_chrome.svg')" />
      {{ "INTRODUCTION_MODAL.ZOOM_TAB.CONTROLS_BROWSER.CHROME_DESC" | translate }}
    </div>

    <div class="option browser">
      <img [src]="getAssetUrl('/images/introduction-modal/logo_firefox.svg')" />
      {{ "INTRODUCTION_MODAL.ZOOM_TAB.CONTROLS_BROWSER.FIREFOX_DESC" | translate }}
    </div>

    <div class="option browser">
      <img [src]="getAssetUrl('/images/introduction-modal/logo_ie.svg')" />
      {{ "INTRODUCTION_MODAL.ZOOM_TAB.CONTROLS_BROWSER.EDGE_DESC" | translate }}
    </div>
  </section>
  <section class="tab-title">{{ "INTRODUCTION_MODAL.ZOOM_TAB.CONTROLS_KEYBINDING.TITLE" | translate }}</section>
  <section class="tab-options">
    <article class="option-center keybindings">
      <img [src]="getAssetUrl('/images/introduction-modal/zoom_win.svg')" />
    </article>

    <article class="option-center keybindings">
      <img [src]="getAssetUrl('/images/introduction-modal/zoom_mac.svg')" />
    </article>
  </section>
  <section class="tab-note">
    <input
      type="checkbox"
      id="checkbox-second"
      class="ene-checkbox"
      [checked]="checkBoxState"
      (change)="changeChecked($event)"
      #showIntroduction
    />
    <label for="checkbox-second" class="note">{{ "INTRODUCTION_MODAL.SHOW_NOTE" | translate }}</label>
  </section>

  <button class="ene-button-cta" (click)="changeTab(2)">
    <span>{{ "INTRODUCTION_MODAL.NEXT" | translate }}</span>
    <ene-icon icon="ec_controls_collapsed_dropdown"></ene-icon>
  </button>
</ng-template>

<ng-template #guidedTour>
  <section class="tab-description">
    <span> {{ "INTRODUCTION_MODAL.GUIDED_TOUR_TAB.DESCRIPTION" | translate }}: </span>
  </section>

  <button class="ene-button-cta guided-tour" (click)="startTour()">
    <span>{{ "INTRODUCTION_MODAL.GUIDED_TOUR_TAB.GUIDED_TOUR_BUTTON" | translate }}</span>
    <ene-icon icon="ec_controls_collapsed_dropdown"></ene-icon>
  </button>

  <section class="link-button" (click)="closeIntroductionModal()">
    {{ "INTRODUCTION_MODAL.GUIDED_TOUR_TAB.CLOSE_BUTTON" | translate }}
  </section>
</ng-template>
