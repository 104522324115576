import { Injectable, ComponentFactoryResolver } from "@angular/core";
import { Subject, Observable } from "rxjs";

interface IClientConfigPanel {
  groupId?: number;
  tabs?: Array<{ title: string; component: string }>;
  enlarge?: boolean;
  extendable?: boolean;
  progressIndicator?: boolean;
  title?: string;
  buildings?: any; // TODO: I see the config service returning { enabled: boolean }, but some other part of this code expects an Array...
}

interface IClientConfigModule {
  name: string;
  panels: Record<string, IClientConfigPanel>;
}

@Injectable({
  providedIn: "root"
})
export class ModulesService {
  public activeRoute: string;

  public afterInit$: Observable<void>;
  public modules: Array<string> = [];

  private clientConfig: { module: Array<IClientConfigModule> };
  private afterInitSubject = new Subject<void>();
  private activeModule: IClientConfigModule;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.afterInit$ = this.afterInitSubject.asObservable();
  }

  // TODO: this belongs into the state. I just put it here so I don't have to refactor the whole project just to fix a simple bug.
  public setClientConfig(clientConfig: { module: Array<IClientConfigModule> }) {
    this.clientConfig = clientConfig;

    this.modules = clientConfig.module.map((module) => module.name);
    this.activeModule = clientConfig.module[0];
    this.activeRoute = this.activeModule.name + "/";
    this.afterInitSubject.next();
  }

  public getClientConfig(): any {
    return this.clientConfig;
  }

  /**
   * @param ContentTarget ViewChildren where Componemt should be loaded
   * @param Tabs Tabs with Name of Component which should be loaded
   * @param EntryComponents Components which could be loaded
   */
  public loadDynamicTabsContent(ContentTarget: any, Tabs: any, EntryComponents: any) {
    for (let i = 0; i < ContentTarget.toArray().length; i++) {
      const target = ContentTarget.toArray()[i];
      const component = EntryComponents[Tabs[i].component];
      if (typeof component !== "undefined") {
        const TargetComponent = this.componentFactoryResolver.resolveComponentFactory(component);
        const cmpRef: any = target.createComponent(TargetComponent);
      }
    }
  }

  /**
   * Get the config object for a panel provided by the active module
   *
   * @param {string} panelName name of panel, e.g. cockpit
   * @returns {*} config object
   * @memberof ModulesService
   */
  public getPanelOfActiveModule(panelName: string): any {
    return this.getActiveModule().panels.hasOwnProperty(panelName)
      ? this.getActiveModule().panels[panelName]
      : undefined;
  }

  /**
   * @returns active module
   * @memberof ModulesService
   */
  public getActiveModule(): IClientConfigModule {
    return this.activeModule;
  }
}
