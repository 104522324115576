import { Injectable } from "@angular/core";
import { store } from "@enersis/gp-components/store";
import { IAuthenticationService } from "@energy-city/components";
import jwt_decode from "jwt-decode";
import { IApplicationState, LoginSuccess, ILoginData } from "@energy-city/components";
import { Store } from "@ngrx/store";
import { TokenFormatter } from "../../core/classes/tokenFormatter";

export type TokenParsedType = Record<string, unknown>;

@Injectable({
  providedIn: "root"
})
export class AuthService implements IAuthenticationService {
  private tokenSlice = store.slice<string>({
    scope: "login",
    name: "token:co2balance"
  });

  public tokenParsed;

  constructor(public applicationStore: Store<IApplicationState>) {
    this._getTokenParsed();
  }

  public get roles(): any {
    try {
      return this.tokenParsed.realm_access && this.tokenParsed.realm_access.roles
        ? this.tokenParsed.realm_access.roles
        : [];
    } catch (e) {
      throw e;
    }
  }

  public get accessToken(): string {
    return this.tokenSlice.getInstantValue();
  }

  public get loggedIn(): boolean {
    // is now handled by the root-frontend
    return true;
  }

  private _getTokenParsed(): any {
    this.tokenParsed = TokenFormatter.format(jwt_decode(this.accessToken));
    const tokenSelected: ILoginData = {
      user: this.tokenParsed.preferred_username,
      language: this.tokenParsed.language,
      logo_main: this.tokenParsed.logo?.main || "Klimanavi-Logo.svg",
      logo_footer: undefined,
      family_name: this.tokenParsed.family_name,
      given_name: this.tokenParsed.given_name,
      email: this.tokenParsed.email,
      // For some realms parsedToken.municipality_name is an Array (containing all groups the user is in)
      // For NetzeBW realm this is just a string
      municipality_name: Array.isArray(this.tokenParsed.municipality_name)
        ? this.tokenParsed.municipality_name[0]
        : this.tokenParsed.municipality_name,
      customer_support_id: this.tokenParsed.customerSupportId,
      tenant: this.tokenParsed.tenant,
      level: undefined
    };
    this.applicationStore.dispatch(new LoginSuccess(tokenSelected));
  }
}
