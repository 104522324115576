// to avoid broken links, fonts need to load specially
// https://medium.com/@epavliy/css-isolation-with-angular-micro-frontends-eb3bd8261bb9
export function injectCssLinkToDocumentRoot(src: string, document: Document, loadTimeout = 5000): Promise<void> {
  return new Promise((resolve, reject) => {
    const links = Array.from(document.getElementsByTagName("link"));

    if (links.find((item) => item.href === src)) {
      resolve();

      return;
    }

    const newLink = document.createElement("link");

    /* eslint-disable */
    let isLoadCompleted = false;
    newLink.href = src;
    newLink.type = "text/css";
    newLink.rel = "stylesheet";
    newLink.onload = () => {
      resolve();
      isLoadCompleted = true;
    };
    newLink.onerror = () => {
      isLoadCompleted = true;
      reject();
    };

    document.head?.appendChild(newLink);
    setTimeout(() => {
      if (!isLoadCompleted) {
        reject("Failed to load resource because of timeout");
      }
    }, loadTimeout);
  });
}
