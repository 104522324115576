import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subject } from "rxjs";
import { ModuleSwitchService } from "../services/module-switch.service";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { EnvironmentType } from "../../../core/enum/environment.enum";
import { environment } from "@energy-city/environments";

@Component({
  selector: "app-module-switch",
  templateUrl: "./module-switch.component.html",
  styleUrls: ["./module-switch.component.scss"]
})
export class ModuleSwitchComponent implements OnInit, OnDestroy {
  public isActive: boolean;
  public isDisabled: boolean;

  private destroy$ = new Subject<void>();

  constructor(
    private moduleSwitchService: ModuleSwitchService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  public ngOnInit() {
    this.moduleSwitchService.isActive$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.isActive = state;
      this.cdr.detectChanges();
    });

    // TODO:
    // if city has only 1 active module, the module-switch will be disabled
    // this.isDisabled = true;
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public changeState(): void {
    this.moduleSwitchService.setActive(!this.isActive);

    if (this.isActive && environment.environment === EnvironmentType.DEVELOPMENT) {
      this.router.navigate([{ outlets: { slot: "launchpad" } }]);
    } else {
      this.dialog.closeAll();
    }
  }
}
