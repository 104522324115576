import { ILoadingBehavior } from "../../../common";
import { GetAccountingMethodsConfigActionType } from "./get-accounting-methods-config-actions";
import { IParameterModel } from "../../../../models/accounting-method";

export const reducer = (state: IGetAccountingMethodsConfigState, action: any) => {
  switch (action.type) {
    case GetAccountingMethodsConfigActionType.GET_ACCOUNTING_METHODS_CONFIG:
      return {
        loading: true
      };
    case GetAccountingMethodsConfigActionType.GET_ACCOUNTING_METHODS_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        config: action.payload
      };
    case GetAccountingMethodsConfigActionType.GET_ACCOUNTING_METHODS_CONFIG_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};

export interface IGetAccountingMethodsConfigState extends ILoadingBehavior {
  model: IParameterModel;
  payload: any;
}
