import { DecimalPipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import {
  AxisLabelsFormatterContextObject,
  Options,
  PointOptionsObject,
  SeriesLineOptions,
  TooltipFormatterContextObject
} from "highcharts";
import { Simulation } from "./simulation";

interface SimulationPointOptions extends PointOptionsObject {
  relativeChangeSince1990: number;
}

export class SimulationHighCharts {
  constructor(private simulation: Simulation, private decimalPipe: DecimalPipe, private translate: TranslateService) {}

  getSettings(): Options {
    return ({
      chart: {
        width: 570,
        unit: " [t]"
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        padding: 0,
        formatter: this.tooltipFormatter()
      },
      rangeSelector: { enabled: false },
      // rangeSelector: false,
      title: {
        text: this.translate.instant("SIMULATION.CHART.TITLE"),
        style: {
          "font-size": "13px",
          color: "var(--gp-simulation-chart-title-color, var(--ene-secondary-600))",
          "font-family": "var(--ene-font-family-primary)",
          "font-weight": "bold"
        },
        x: 10,
        align: "left"
      },
      navigator: {
        enabled: false
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal"
      },
      yAxis: [
        {
          tickPositions: this.getYAxis().map((entry) => entry.position),
          endOnTick: false,
          opposite: true,
          title: {
            text: undefined
          },
          labels: {
            useHTML: true,
            style: {
              "padding-right": "7px"
            },
            formatter: this.formatYAxisLabel()
          }
        },
        {
          title: {
            useHTML: true,
            text: this.translate.instant("SIMULATION.CHART.Y_AXIS_TITLE")
          }
        }
      ]
    } as any) as Options;
  }

  getChartSeries(): Array<SeriesLineOptions> {
    return [
      {
        type: "line",
        name: this.translate.instant("SIMULATION.CHART.HISTORY"),
        data: this.simulation.getHistory().map((value) => {
          return { x: value.year, y: value.value, relativeChangeSince1990: value.relativeValue };
        }),
        tooltip: {
          valueDecimals: 2
        },
        marker: {
          enabled: false
        },
        color: "var(--ene-primary-500)",
        events: {
          legendItemClick: function () {
            return false;
          }
        }
      },
      {
        type: "line",
        name: this.translate.instant("SIMULATION.CHART.TREND"),
        data: this.simulation.getModelForecast().map((value) => {
          return { x: value.year, y: value.value, relativeChangeSince1990: value.relativeValue };
        }),
        dashStyle: "Dash",
        tooltip: {
          valueDecimals: 2
        },
        marker: {
          enabled: false
        },
        color: "var(--ene-secondary-600)",
        events: {
          legendItemClick: function () {
            return false;
          }
        }
      },
      {
        type: "line",
        id: "simulationSeries",
        name: this.translate.instant("SIMULATION.CHART.MEASURES_SET"),
        data: this.simulation.getMeasureForecast().map((value) => {
          return { x: value.year, y: value.value, relativeChangeSince1990: value.relativeValue };
        }),
        color: "#28FAB4",
        tooltip: {
          valueDecimals: 2
        },
        marker: {
          enabled: false
        },
        events: {
          legendItemClick: function () {
            return false;
          }
        }
      }
    ];
  }

  private tooltipFormatter() {
    const locale = this.translate.currentLang;
    const format = this.decimalPipe.transform;
    return function (this: TooltipFormatterContextObject) {
      const relativeChangeSince1990 = (this.point.options as SimulationPointOptions).relativeChangeSince1990;
      return `<p class="tooltip-title">${this.x}</p><p class="tooltip-text">${format(
        this.point.y,
        "1.0-0",
        locale
      )} t CO<sub>2</sub> (${format(relativeChangeSince1990, "1.0-1", locale)}%)</p>`;
    };
  }

  private formatYAxisLabel() {
    const yAxis = this.getYAxis();
    return function (this: AxisLabelsFormatterContextObject<number>) {
      const yAxisEntry = yAxis.find((entry) => entry.position === this.value);
      if (!yAxisEntry) {
        throw Error("Unexpected value for yAxis label.");
      }
      return yAxisEntry?.label;
    };
  }

  private getYAxis(): Array<{ position: number; label: string }> {
    const yAxis = [];
    yAxis.push({ position: this.simulation.referenceValue1990, label: "100%" });
    const reductionTargets = this.simulation.getReductionTargets();
    reductionTargets.forEach((target) =>
      yAxis.push({
        position: Math.round(target.value),
        label: `${this.translate.instant("SIMULATION.CHART.TARGET")} ${target.year} <b>${Math.round(
          target.relativeValue
        )}%</b>`
      })
    );
    if (!yAxis.map((entry) => entry.position).includes(0)) {
      yAxis.push({ position: 0, label: "0%" });
    }
    return yAxis.reverse();
  }
}
