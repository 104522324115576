/* tslint:disable:member-access */

import { Action } from "@ngrx/store";

export enum ChartFinalEnergyActionType {
  ERROR = "[FinalEnergy] ERROR",
  GET_KPI = "[FinalEnergy] GET_KPI",
  GET_KPI_SUCCESS = "[FinalEnergy] GET_KPI_SUCCESS"
}

export class GetFinalEnergyPerEnergyKpi implements Action {
  public readonly type = ChartFinalEnergyActionType.GET_KPI;
  payload = null;
  constructor(public ags: number, public year: number) {}
}

export class GetFinalEnergyPerEnergyKpiSuccess implements Action {
  public readonly type = ChartFinalEnergyActionType.GET_KPI_SUCCESS;
  constructor(public payload: any) {}
}

export class FinalEnergyError implements Action {
  public readonly type = ChartFinalEnergyActionType.ERROR;
  constructor(public payload: any) {}
}

export type FinalEnergyActions = GetFinalEnergyPerEnergyKpi | GetFinalEnergyPerEnergyKpiSuccess | FinalEnergyError;
