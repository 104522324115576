import {
  ChangeDetectionStrategy,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Pipe,
  PipeTransform
} from "@angular/core";
import { UnitConfig, formatUnit } from "@enersis/gp-components/number-formatting";
import { FactorManagementService } from "../factor-management.service";
import { ColumnConfig, TableData } from "./table.interface";

const PRECISION = 10000000000;

const UNIT_DEFAULT: UnitConfig = {
  category: "decimal",
  base: "",
  onlyUnits: [""],
  numberFormat: {
    maximumSignificantDigits: 10
  }
};

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements AfterViewInit {
  @Input() public editable: boolean;
  @Input()
  public get columnsConfig() {
    return this._columnsConfig;
  }
  public set columnsConfig(columns: Array<ColumnConfig>) {
    this._columnsConfig = columns;
    this.displayedColumns = columns.map(({ id }) => id);
  }

  @Input() public data: TableData;
  @Output() public changed = new EventEmitter<any>();

  public displayedColumns: Array<string>;
  public dataSource: Array<any>;
  public unitConfig = UNIT_DEFAULT;

  constructor(private hostElement: ElementRef, public readonly factorManagementService: FactorManagementService) {}

  ngAfterViewInit() {
    this.scrollToRight();
  }

  updateValue(newValue: number, row: any, columnId: string) {
    row[columnId] = round(Number(newValue));
    this.changed.next(this.data);
    this.factorManagementService.tableDataChanges$.next(this.data);
  }

  private scrollToRight() {
    const tableElement = this.hostElement.nativeElement;
    tableElement.scrollLeft = tableElement.scrollWidth;
  }

  private _columnsConfig: Array<ColumnConfig>;
}

function round(value: number) {
  return Math.round(value * PRECISION) / PRECISION;
}

@Pipe({
  name: "formatUnit",
  pure: true
})
export class FormatUnitPipe implements PipeTransform {
  transform(value: number | string): string | number {
    if (typeof value !== "number") return value;
    return formatUnit(value, UNIT_DEFAULT);
  }
}
