import { ILoadingBehavior } from "../../../../common";
import { HeatpumpAction, HeatpumpActionType } from "./heatpump-actions";

export const reducer = (state: IHeatpumpState = {}, action: HeatpumpAction) => {
  switch (action.type) {
    case HeatpumpActionType.GET_HEATPUMP_DATA:
      return {
        loading: true,
        connectionGrouId: action.connectionGrouId,
        scenarioId: action.scenarioId,
        year: action.year
      };
    case HeatpumpActionType.GET_HEATPUMP_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false
      };
    case HeatpumpActionType.GET_HEATPUMP_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};

export interface IHeatpumpState extends ILoadingBehavior {
  count?: number;
  capacity?: number;
  co2Saving?: number;
  consumption?: number;
  year?: number;
}
