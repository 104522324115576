// tslint:disable:component-selector
import { Component, ViewEncapsulation, Input, HostBinding } from "@angular/core";

@Component({
  selector: "[ene-footer]",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-footer"
  }
})
export class EneFooterComponent {
  @HostBinding("class.visible")
  @Input()
  public visible: boolean = true;

  @HostBinding("style.height")
  @Input()
  public height: any;
}
