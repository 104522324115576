import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EMPTY, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { acceptJsonWithoutCache } from "../../state/common";
import { catchError, map } from "rxjs/operators";
import { IReportYear } from "./interfaces/region-property-report-years.interface";
import { AccountingMethod } from "../../configs/accounting-method";
import { IRegionIdentifier } from "../util.service";

@Injectable({
  providedIn: "root"
})
export class RegionPropertyReportYearsService {
  constructor(private http: HttpClient) {}

  public getReportYears(regionId: IRegionIdentifier, accountingMethod: AccountingMethod): Observable<IReportYear[]> {
    const params = new HttpParams({
      fromObject: {
        regionId: regionId.regionId,
        regionType: regionId.regionType,
        accountingMethod: AccountingMethod[accountingMethod]
      }
    });
    const url = `${environment.regionPropertyApi}/report/reportYears`;

    return this.http
      .get<IReportYear[]>(url, { headers: new HttpHeaders(acceptJsonWithoutCache), params })
      .pipe(
        map((res) => res),
        catchError(() => of([] as IReportYear[]))
      );
  }
}
