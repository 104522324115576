export interface IKpiBlock {
  items: IKpiItem[];

  id?: string | number; // unique name to access whole KPI Block. optional but useful
  header?: IKpiHeader;

  // visual
  type: "row" | "box" | "group";
  surface: "raised" | "flat";
  columns?: number;
  disabled?: boolean; // adds styling for disabled if true

  // behaviour settings
  initialState?: KpiStates; // Determines what to show until the first action has been executed. Can be overwritten by the KPIs itself.
  afterMappingFailed?: KpiStates;
}

export interface IKpiItem {
  // visual stuff
  title?: string; // displayed without translation
  icon?: string; // display icon
  label?: string; // underneath the value
  disabled?: boolean; // adds styling for disabled if true
  oneLine?: boolean; // if true they are displayed in a row; if false they are displayed in a column

  // values
  value?: number | string;
  pipe?: IKpiPipeOptions;
  dataset?: any[];

  // updating, reading and more
  id?: string | number; // necessary if we need the ability to single select a KPI
  mapping?: any; // reference to object, executed when calling runMapping()
  function?: IKpiFunction; // reference OR function to be executed when calling runFunctions()

  // settings
  raw?: boolean; // if true, then do not use any format options, instead display the value as it is.
  translate?: boolean; // if yes, try to translate the title and value

  // behaviour settings
  afterMappingFailed?: KpiStates; // if true, it will be cleared when a mapping operation is made on this element and/or block
  state?: KpiStates; // used to save a state per item.
  notAvailableText?: string; // override default "Not available" translation
}

export interface IKpiHeader {
  title?: string;
  icon?: string;
  translate?: boolean; // if yes, try to translate the title - fallback true
}
export interface IKpiFunction {
  reference: any; // function or string
  data?: any; // will be passed to function as 3rd argument
  runOnInit?: boolean; // fallback true
  runAfterMapping?: boolean; // default???
  runAfterUpdate?: boolean; // fallback on true
}

export enum KpiStates {
  LOADED, // set if everything is fine and value can be displayed
  CLEARED, // set initialy or if clearing action has been fired (displays empty value)
  PENDING, // set if operation is pending (display loading animation)
  NOTAVAILABLE, // set if no data is available (displays information N/A)
  UNKNOWN // set if value is undefined or invalid (display information unknown value)
}

export interface IKpiPipeOptions {
  /* SHARED BETWEEN PIPES */
  type?: "eneFormatUnit" | "decimal" | "toLocaleString" | "currency"; // type of requested Pipe
  localization?: string; // shared: overwrite to selected language (usually better do not provide and use out-of-the-box detection)
  digitsInfo?: string; // decimalPipe & currencyPipe: {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits} -> https://angular.io/api/common/DecimalPipe

  /* ONLY eneFormatUnit */
  category?: string; // only eneFormatUnit: category to match, fallback = decimal
  unit?: string; // only eneFormatUnit: unit, fallback = "" (empty string)
  digitsLength?: number; // only eneFormatUnit: digitsLength, no fallback (inherited from eneFormatUnitPipe)
  decimalPlaces?: number; // only eneFormatUnit: decimalPlaces, no fallback (inherited from eneFormatUnitPipe)
  SymAtZero?: boolean; // only eneFormatUnit: Symbol at zero, no fallback (inherited from eneFormatUnitPipe) - seems to not have any impact atm
  prefix?: string; // only eneFormatUnit: Prefix before label/unit (inherited from eneFormatUnitPipe)
  suffix?: string; // only eneFormatUnit: suffix after label/unit, no fallback (inherited from eneFormatUnitPipe) - seems to not have any impact atm
  container?: string | boolean; // only eneFormatUnit: change containing html tag for label/unit, fallback to "label"

  /* ONLY angular CurrencyPipe */
  currencyCode?: string; // only currencyPipe: https://angular.io/api/common/CurrencyPipe
  display?: boolean | "symbol" | "code" | "symbol-narrow"; // only currencyPipe: https://angular.io/api/common/CurrencyPipe
}

export interface IKpiSettingsI18n {
  UNKNOWN: string;
  CLEARED: string;
  NOTAVAILABLE: string;
  PENDING: string;
}
export interface IKpiSettings {
  i18n: IKpiSettingsI18n;
  allowIdDuplicates: boolean;
  iconsProject: string;
}
