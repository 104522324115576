import { environment } from "../../environments/environment";

export enum EnergyType {
  FINAL = "final",
  PRIMARY = "primary"
}

export interface DisplayChartConfig {
  switcher?: boolean;
  biskoDisabled?: boolean;
  showMethod?: boolean;
}

export const enum SectorType {
  TOTAL_BALANCE = "totalBalance",
  PRIVATE_HOUSEHOLD = "privateHousehold",
  COMMERCE = "commerce",
  INDUSTRY = "industry",
  COMMUNITY_FACILITIES = "communityFacilities",
  TRANSPORTATION = "transportation"
}

export enum MainPolluterDataType {
  CO2,
  PUBLIC_SECTOR
}

export const KPI_NAMESPACE = "co2_cockpit_extended";

export interface IFormatterResult {
  items: Array<any>;
  drillUpButton: string;
}

export interface IApiConfig {
  env: any;
  path: string;
  dataType: MainPolluterDataType;
}

export type MainPolutterApi = {
  [key in "CO2" | "PUBLIC_SECTOR"]: IApiConfig;
};

export const MAIN_POLLUTER_APIS: MainPolutterApi = {
  CO2: {
    env: () => environment.regionPropertyApi,
    path: "emissions/co2",
    dataType: MainPolluterDataType.CO2
  },
  PUBLIC_SECTOR: {
    env: () => environment.regionPropertyApi,
    path: "energy",
    dataType: MainPolluterDataType.PUBLIC_SECTOR
  }
};
