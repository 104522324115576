import { TourActionType, TourActions } from "./tour-actions";

export function reducer(state = [], action: TourActions) {
  switch (action.type) {
    case TourActionType.GET_TOURS:
    case TourActionType.GET_TOURS_SUCCESS:
    case TourActionType.HELP_TAB_CHANGE:
      return action;
    case TourActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
