import {
  Component,
  ElementRef,
  HostListener,
  HostBinding,
  Input,
  OnInit,
  EventEmitter,
  Renderer2,
  OnDestroy,
  SecurityContext
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TooltipEvents, TooltipPlacement, TooltipTheme } from "./tooltip.enum";

@Component({
  selector: "ene-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"]
})
export class EneTooltipComponent implements OnInit, OnDestroy {
  // accessed by the directive
  public events = new EventEmitter<TooltipEvents>();
  private _show = false;

  @Input() public data: any;
  @Input() public set show(value: boolean) {
    if (value) {
      this.setPosition();
    }
    this._show = this.hostClassShow = value;
  }

  @HostBinding("style.top") public hostStyleTop: string;
  @HostBinding("style.left") public hostStyleLeft: string;
  @HostBinding("style.z-index") public hostStyleZIndex: number;
  @HostBinding("style.transition") public hostStyleTransition: string;
  @HostBinding("style.max-width") public hostStyleMaxWidth: string;
  @HostBinding("class.tooltip-show") public hostClassShow: boolean;
  @HostBinding("class.tooltip-shadow") public hostClassShadow: boolean;
  @HostBinding("class.tooltip-dark") public hostClassDark: boolean;
  @HostBinding("class.tooltip-light") public hostClassLight: boolean;

  @HostListener("transitionend")
  public transitionEnd() {
    if (this.show) {
      this.events.emit(TooltipEvents.SHOWN);
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private sanitizer: DomSanitizer) {}

  public get show(): boolean {
    return this._show;
  }

  get placement() {
    return TooltipPlacement[this.data.options.placement].toLowerCase();
  }

  get element() {
    return this.data.element;
  }

  get elementPosition() {
    return this.data.elementPosition;
  }

  get options() {
    return this.data.options;
  }

  get value() {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.data.value);
  }

  get tooltipOffset(): number {
    return Number(this.data.options.offset);
  }

  public ngOnInit() {
    this.setPlacementClass();
    this.setZIndex();
    this.setCustomClass();
    this.setAnimationDuration();
    this.setStyles();
  }

  public ngOnDestroy(): void {
    this.events.emit(TooltipEvents.HIDDEN);
    this.events.complete();
  }

  public setPosition(): void {
    const isSvg = this.element instanceof SVGElement;
    const tooltip = this.elementRef.nativeElement;

    const elementHeight = isSvg ? this.element.getBBox().height : this.element.offsetHeight;
    const elementWidth = isSvg ? this.element.getBBox().width : this.element.offsetWidth;
    const tooltipHeight = tooltip.clientHeight;
    const tooltipWidth = tooltip.clientWidth;
    const scrollY = window.pageYOffset;

    let top: number = 0;
    let left: number = 0;

    if (this.placement === "top") {
      top = this.elementPosition.top + scrollY - (tooltipHeight + this.tooltipOffset);
    }

    if (this.placement === "bottom") {
      top = this.elementPosition.top + scrollY + elementHeight + this.tooltipOffset;
    }

    if (this.placement === "top" || this.placement === "bottom") {
      left = this.elementPosition.left + elementWidth / 2 - tooltipWidth / 2;
    }

    if (this.placement === "left") {
      left = this.elementPosition.left - tooltipWidth - this.tooltipOffset;
    }

    if (this.placement === "right") {
      left = this.elementPosition.left + elementWidth + this.tooltipOffset;
    }

    if (this.placement === "left" || this.placement === "right") {
      top = this.elementPosition.top + scrollY + elementHeight / 2 - tooltip.clientHeight / 2;
    }

    if (left <= 0) {
      left = this.tooltipOffset;
    } else if (this.elementPosition.left + tooltipWidth >= document.body.clientWidth) {
      left = document.body.clientWidth - tooltipWidth - this.tooltipOffset;
    }

    this.hostStyleLeft = `${left}px`;
    this.hostStyleTop = `${top}px`;
  }

  public setPlacementClass(): void {
    this.renderer.addClass(this.elementRef.nativeElement, `tooltip-${this.placement}`);
  }

  public setZIndex(): void {
    if (this.options["z-index"] !== 0) {
      this.hostStyleZIndex = this.options["z-index"];
    }
  }

  public setCustomClass(): void {
    if (this.options["tooltip-class"]) {
      this.renderer.addClass(this.elementRef.nativeElement, this.options["tooltip-class"]);
    }
  }

  public setAnimationDuration(): void {
    if (Number(this.options["animation-duration"]) !== this.options["animation-duration-default"]) {
      this.hostStyleTransition = `opacity ${this.options["animation-duration"]}ms`;
    }
  }

  public setStyles(): void {
    this.hostClassShadow = this.options["shadow"];
    this.hostClassDark = this.options["theme"] === TooltipTheme.DARK;
    this.hostClassLight = this.options["theme"] === TooltipTheme.LIGHT;
    this.hostStyleMaxWidth = this.options["max-width"];
  }
}
