<div class="contact-form">
  <div class="contact-form__body">
    <section class="contact-form__input">
      <label class="contact-form__input__label">{{ selectOptions.label | translate }}</label>
      <mat-form-field class="select contact-form__item">
        <mat-select [formControl]="select">
          <mat-option *ngFor="let option of selectOptions.options" [value]="option.id">
            {{ option.value | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <section class="contact-form__input">
      <label class="contact-form__input__label">{{ "APP.MESSAGE_REQUIRED" | translate }}</label>
      <textarea
        class="contact-form__item contact-form__input__contact-textarea"
        required
        [formControl]="message"
        placeholder="Bitte eingeben..."
      ></textarea>
    </section>
  </div>
  <div class="contact-form__footer">
    <button class="contact-form__contact-cancel" (click)="cancel()">
      {{ "APP.CANCEL" | translate }}
    </button>
    <button class="contact-form__contact-send" (click)="send()">
      {{ "FOOTER.KONTAKT.SEND" | translate }}
    </button>
  </div>
</div>
