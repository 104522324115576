import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneTabsModule } from "@energy-city/ui/tabs";
import { TranslateModule } from "@ngx-translate/core";
import { EneSnackbarModule } from "libs/ui/src/lib/snackbar";
import { DataPrivacyComponent } from "./components/data-privacy/data-privacy.component";
import { GlossaryComponent } from "./components/glossary/glossary.component";

@NgModule({
  declarations: [GlossaryComponent, DataPrivacyComponent],
  imports: [
    CommonModule,
    EneIconModule,
    EneTabsModule,
    MatDialogModule,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    EneSnackbarModule
  ],
  entryComponents: [GlossaryComponent, DataPrivacyComponent],
  exports: [GlossaryComponent, DataPrivacyComponent]
})
export class PopUpModule {}
