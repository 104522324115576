import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromDataPanel from "../index";
import {
  FactorEndpointId,
  IFactorResponseDtoMap
} from "../../components/factor-management/factor-management.endpoint-config";

export * from "./factor-emission.actions";
export * from "./factor-emission.effects";
export * from "./factor-emission.reducer";

export interface FactorEmissionState {
  data: Array<IFactorResponseDtoMap[FactorEndpointId]>;
  loading: boolean;
  errorMessage?: string;
}

const selectFactorEmissionState = createFeatureSelector<fromDataPanel.DataPanelState>("dataPanel");

export const getFactorEmissionData = createSelector(selectFactorEmissionState, (state) => state.factorEmission.data);
