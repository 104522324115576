import { Injectable } from "@angular/core";
import { ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { cloneDeep, get } from "lodash";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AccountingMethod, MixType } from "../../../configs/accounting-method";
import { IParameterModel } from "../../../models/accounting-method";
import { AccountingMethodService } from "../../../services/accounting-method.service";
import * as fromRoot from "../../../state/index";
import { AppActionType } from "../../../state/index";
import { PAGE_ORDER, getChartDescription } from "../print.const";

@Injectable()
export class PrintService {
  public printWin$: Observable<boolean>;
  private printWin: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private year: number;
  private accountingMethod: AccountingMethod;
  private useUserFactors: boolean;
  private systemBalanceModel = {
    accountingMethod: undefined,
    mixType: undefined,
    weatherCorrection: undefined,
    useUserFactors: undefined
  };

  constructor(private store: Store<fromRoot.IApplicationState>, private accMethodService: AccountingMethodService) {
    this.printWin$ = this.printWin.asObservable();
  }

  public showPrintPreview(accMethod: AccountingMethod): void {
    const accountingMethod = this.accMethodService.selectedAccountingMethod$.getValue();
    const mixType = this.accMethodService.selectedMixType$.getValue();
    const weatherCorrection = this.accMethodService.weatherCorrection$.getValue();
    const useUserFactors = this.accMethodService.useUserFactors$.getValue();

    this.systemBalanceModel = {
      accountingMethod,
      mixType,
      weatherCorrection,
      useUserFactors
    };
    this.accountingMethod = accMethod;
    this.useUserFactors = useUserFactors;

    const model: IParameterModel = {
      accountingMethod: accMethod,
      mixType: MixType.FEDERAL,
      weatherCorrection: false,
      useUserFactors: this.areUserFactorsAllowed() ? useUserFactors : false // use current setting for user emission Factors
    };

    this.accMethodService.updateParameters(model as any);
    this.printWin.next(true);
  }

  public hidePrintPreview(): void {
    this.accMethodService.updateParameters(this.systemBalanceModel);
    this.printWin.next(false);
  }

  public set selectedReportYear(value: number) {
    this.year = value;
  }

  public get selectedReportYear(): number {
    return this.year;
  }

  public get selectedAccountingMethod(): AccountingMethod {
    return this.accountingMethod;
  }

  public get selectedUseUserFactors(): boolean {
    return this.useUserFactors;
  }

  public getPagesData(): Observable<Array<any>> {
    return this.store.pipe(
      select(fromRoot.GetAppState),
      ofType(AppActionType.GET_CLIENT_CONFIG_SUCCESS),
      map(({ payload }: any) => get(payload, "module[0].panels.reports.charts")),
      map((charts: [Array<any>, string]) => this.getFormattedPagesConfig(charts))
    );
  }

  private getFormattedPagesConfig(charts: Array<any>): Array<any> {
    const formatted = [];
    const titlePrefix = "PRINT_VIEW.TITLE.PAGE_";
    const chartTitlePrefix = "PRINT_VIEW.CHART_TITLE.PAGE_";
    const tableFooterPrefix = "PRINT_VIEW.TABLE.FOOTER.TEXT_";

    // used forEach, because push page by index without additional loop
    charts.forEach((chart) => {
      const chartName = chart.chart.component;
      const index = PAGE_ORDER[chartName];
      const title = `${titlePrefix}${index}`;
      const chartTitle = `${chartTitlePrefix}${index}`;
      const tableFooter = `${tableFooterPrefix}${index}`;
      const text = getChartDescription(chartName);
      let trendCmpConfigs = {};

      // should add additional config for chart
      switch (chartName) {
        case "ChartGreenhouseGasesTrendComponent": {
          trendCmpConfigs = {
            name: "",
            dqiSubtitle: "PRINT_VIEW.TITLE.AFFECTS_FINANCIAL_YEAR"
          };

          break;
        }
        default: {
          break;
        }
      }

      formatted[index] = {
        ...chart,
        text,
        title,
        chartTitle,
        tableFooter,
        ...trendCmpConfigs
      };
    });

    return cloneDeep(formatted);
  }

  private areUserFactorsAllowed(): boolean {
    return this.accountingMethod !== "BISKO";
  }
}
