// angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// external
import { NouisliderModule } from "ng2-nouislider";
import { EnePopperModule } from "@energy-city/ui/popper";

// internal
import { EneSlideToggleComponent } from "./components/slide-toggle/slide-toggle.component";
import { EneDqiComponent } from "./components/dqi/dqi.component";
import { DeprecatedEneRangeSliderModule } from "./components/range-slider/deprecated-ene-range-slider.module";

@NgModule({
  declarations: [EneSlideToggleComponent, EneDqiComponent],
  imports: [CommonModule, NouisliderModule, EnePopperModule, DeprecatedEneRangeSliderModule],
  exports: [EneSlideToggleComponent, EneDqiComponent]
})
export class EneFormsModule {}
