import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneModalModule } from "@energy-city/ui/modal";
import { ImportNoticeModalComponent } from "./input-notice.component";

@NgModule({
  declarations: [ImportNoticeModalComponent],
  imports: [CommonModule, TranslateModule, EneModalModule],
  exports: [ImportNoticeModalComponent]
})
export class DataInputModalsModule {}
