import { Component, OnInit, Input } from "@angular/core";
import { RegionService } from "@energy-city/components";

@Component({
  selector: "app-co2-cockpit",
  templateUrl: "./co2-cockpit.component.html",
  styleUrls: ["./co2-cockpit.component.scss"]
})
export class Co2CockpitComponent implements OnInit {
  @Input() public column: string;
  public selectedRegionDetails$ = this.regionService.selectedRegionDetails$;

  constructor(public regionService: RegionService) {}

  public ngOnInit() {}
}
