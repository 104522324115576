import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  forwardRef
} from "@angular/core";
import { Subscription, BehaviorSubject, Observable } from "rxjs";
import { PopperContentComponent } from "@energy-city/ui/popper";
import { EneEditComponentChange } from "../../edit/core/classes/abstract/EneEditComponentChange.abstract.class";
import { EneEditComponentMode } from "../../edit/core/classes/abstract/EneEditComponentMode.abstract.class";
import { UtilService } from "../../../services/util.service";
import { AppActionType, IApplicationState, GetAppState } from "../../../state/index";

// ngrx
import { Store } from "@ngrx/store";
import { EneRangeSliderComponent } from "libs/ui/src/lib/forms/components/range-slider/range-slider.component";

@Component({
  selector: "app-dqi",
  templateUrl: "./dqi.component.html",
  styleUrls: ["./dqi.component.scss"],
  providers: [
    {
      provide: EneEditComponentChange,
      useExisting: forwardRef(() => DqiComponent)
    },
    {
      provide: EneEditComponentMode,
      useExisting: forwardRef(() => DqiComponent)
    }
  ],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-dqi"
  }
})
/**
 * TODO: remove deprecated stuff -> BREAKING CHANGE
 */
export class DqiComponent
  implements EneEditComponentMode<number>, EneEditComponentChange<number>, OnInit, OnChanges, OnDestroy {
  @ViewChild("dqiPopper", { static: true }) private dqiPopper: PopperContentComponent;
  @ViewChild("slider", { static: true }) private slider: EneRangeSliderComponent;
  @Input() public dqi: number;
  @Output() public dqiChange: EventEmitter<number> = new EventEmitter();
  @Input() public item: any;

  /**
   * still usable but input decorator should be removed, since editModeActive is implemented
   * @deprecated
   */
  @Input() public disabled: boolean = false;

  /**
   * @deprecated
   */
  @Output() public change: EventEmitter<number> = new EventEmitter();

  public cachedDQI: number;
  public sliderConfig: any;
  private _dataChange: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  // ngrx
  private app$: Observable<any>;
  private appSubscription: Subscription;

  public get editModeActive(): boolean {
    return this.disabled;
  }
  public set editModeActive(editModeActive: boolean) {
    this.disabled = !editModeActive;
  }
  public set value(value: number) {
    this.dqi = value;
  }
  public get value(): number {
    if (this._dataChange.value === undefined) {
      return this.dqi;
    } else {
      return this._dataChange.value;
    }
  }

  constructor(private utilService: UtilService, private store: Store<IApplicationState>) {}
  public F;
  public ngOnInit() {
    // update slider on language change
    this.app$ = this.store.select(GetAppState);
    this.appSubscription = this.app$.subscribe((res) => {
      switch (res.type) {
        case AppActionType.UPDATE_TRANSLATIONS_SUCCESS:
          // TODO: Dynamic language change for slider - had huge troubles and due to timelimits could not make it work.
          // this updateOptions somehow breaks the slider all the time...
          // this.generateSlider();
          break;
      }
    });

    this.dqi = this.checkDQIType(this.dqi);
    this.cachedDQI = this.dqi;
    this.generateSlider();
  }
  /**
   * triggered by range slider
   * @param event event from rangeslider (as number)
   */
  public onChange(event: any) {
    setTimeout(() => {
      this.dqiPopper.hide();
    }, 300);
    this.dqi = event;
    if (this.dqi !== this.cachedDQI) {
      this.change.emit(event);
      this.dqiChange.emit(event);
      this._dataChange.next(event);
      this.cachedDQI = this.dqi;
    }
  }
  /**
   * triggered by changes from angular and does check the type and update the cached value
   * @param changes delivered by angular
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.dqi !== undefined) {
      this.dqi = this.checkDQIType(changes.dqi.currentValue);
      this.cachedDQI = this.dqi;
    }
  }
  /**
   * check if it is a number and if not returns 0
   * @param dqi take any type
   */
  private checkDQIType(dqi: any) {
    if (!Number.isInteger(dqi)) {
      return 0;
    } else {
      return dqi;
    }
  }

  private generateSlider() {
    const format = this.utilService.stringFormatter(["TDI.DQI.0", "TDI.DQI.1", "", "", "", "TDI.DQI.5"]);
    this.sliderConfig = {
      pips: {
        mode: "count",
        values: 6,
        density: 100,
        format: format
      }
    };

    // check if exist (only after popup has been opened once) and if yes then updateOptions
    //
    // if(this.slider.nouislider.slider !== undefined) {
    //   this.slider.nouislider.slider.updateOptions({pips: {format: format }});
    // }
  }

  public changeValue(newValue: number) {
    this.dqi = this.checkDQIType(newValue);
    this.cachedDQI = this.dqi;
  }

  public connect(): Observable<number> {
    return this._dataChange;
  }

  public disconnect(): void {
    this._dataChange.complete();
  }

  public ngOnDestroy() {
    this.disconnect();
  }
}
