import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  /* tslint:disable-next-line:component-selector */
  selector: "[ene-header]",
  templateUrl: "./ui-header.component.html",
  styleUrls: ["./ui-header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-header"
  }
})
export class EneHeaderComponent {}
