import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ValueListTable } from "../factor-table.interface";

@Component({
  selector: "co2-value-list-table",
  templateUrl: "./value-list-table.component.html",
  styleUrls: ["./value-list-table.component.scss"]
})
export class ValueListTableComponent implements OnInit, OnDestroy {
  @Input() public table?: ValueListTable;

  public currentLang: string;
  public displayedColumns = ["description", "unit", "value"];

  private destroy$ = new Subject();

  constructor(private translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.subscribeLanguageChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private subscribeLanguageChanges(): void {
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.currentLang = event.lang;
    });
  }
}
