import { Component } from "@angular/core";
import { DatabaseChartService } from "../database-chart.service";
import { ChartTypes } from "../../../../../models/charts.model";
import { DatabaseChartComponent } from "../database-chart.component";

@Component({
  selector: "app-federal-mix",
  templateUrl: "../database-chart.component.html",
  styleUrls: ["../database-chart.component.scss"],
  providers: [DatabaseChartService]
})
export class FederalMixComponent extends DatabaseChartComponent {
  protected chartType = ChartTypes.FEDERAL;
}
