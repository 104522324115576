import { Component, OnInit, OnChanges, ViewEncapsulation, HostBinding, Input, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { EneIconService } from "../services/ui-icon.service";

@Component({
  selector: "ene-icon",
  templateUrl: "./ui-icon.component.html",
  styleUrls: ["./ui-icon.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-icon"
  }
})
export class EneIconComponent implements OnInit, OnChanges {
  /* Host Bindings */
  // tslint:disable-next-line: no-input-rename
  @HostBinding("class") @Input("class") public class: string = "";
  @HostBinding("class.style-circle") public styleIsCircle: boolean = false;
  @HostBinding("class.color-primary") public colorIsPrimary: boolean = false;
  @HostBinding("class.color-secondary")
  public colorIsSecondary: boolean = false;
  @HostBinding("class.has-dropdown") @Input() public dropdown: boolean = false;
  @HostBinding("style.font-size") @Input() public size: string;

  /* Inputs */
  @Input() public styling: string;
  @Input() public icon: string;
  @Input() public color: string;
  @Input() public notifications: number = 0;
  @Input() public project: string = "energycity";

  /* Public variables */
  public title: string;
  public url: string;
  public animateNotification: boolean = false;
  public animateInit: boolean = true;
  public iconClass: string;
  public font: SafeUrl;
  private availableProject: Array<IAvailableProject> = [
    {
      name: "energycity",
      font: "/assets/icons/energycity-icons/css/energycity-icons.css"
    },
    {
      name: "digikoo",
      font: "/assets/icons/digikoo-icons/css/digikoo-icons.css"
    }
  ];

  constructor(private sanitizer: DomSanitizer, private iconService: EneIconService) {}

  public ngOnInit() {
    this.class = this.class + " " + this.project;
    // register project in iconService and set boolean with the result if already set or not
    this.iconService.checkFont(this.project);

    // catch wrong specified project
    const projectIndexInArray = this.availableProject.map((e: any) => e.name).indexOf(this.project);
    if (projectIndexInArray < 0) {
      console.error("ene-icon: specified project " + this.project + " ist not known by ene-icon.");
      return false;
    }

    // icon is mandatory
    if (!this.icon) {
      console.error("No icon specified!");
      return false;
    }
    // Font
    const project = this.availableProject[projectIndexInArray];
    this.iconClass = this.icon;
    this.font = this.sanitizer.bypassSecurityTrustResourceUrl(project.font as string);

    // set style
    switch (this.styling) {
      case "circle":
        this.styleIsCircle = true;
        break;
    }

    // set color
    switch (this.color) {
      case "primary":
        this.colorIsPrimary = true;
        break;
      case "secondary":
        this.colorIsSecondary = true;
        break;
    }
  }

  /**
   * Will be called after a CSS3 animation is completed.
   * It ensures to remove animation-classes so it can be reanimated if the notifications changed
   * @param e event, not used yet
   */
  public notificationAnimationComplete(e: Event) {
    this.animateNotification = false;
    this.animateInit = false;
  }

  /**
   * ngOnChanges ensure that the notifications will be animated if the value change
   * @param changes delivered by angular
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.notifications !== undefined && changes.notifications.firstChange === false) {
      this.animateNotification = true;
    }
    if (changes.icon !== undefined && changes.icon.firstChange === false) {
      this.icon = changes.icon.currentValue;
      this.iconClass = this.icon;
    }
  }
}

export interface IAvilableIcon {
  name: string;
  title: string;
  file?: string;
}

export interface IAvailableProject {
  name: string;
  folder?: string;
  icons?: Array<IAvilableIcon>;
  font?: string;
}
