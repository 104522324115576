import { Component, OnInit, OnDestroy, ViewChild, Inject, Optional } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subject, combineLatest } from "rxjs";
import { EneChartComponent } from "@energy-city/ui/chart";
import * as fromRoot from "../../../../../state/index";
import { TranslateService } from "@ngx-translate/core";
import { DisplayChartConfig } from "../../../../../models/charts.models";
import { ChartsService } from "../../../../../services/charts.service";
import { UtilService } from "../../../../../services/util.service";
import { RegionService } from "../../../../../services/region.service";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { ChartFinalEnergyTraficService } from "./chart-final-energy-traffic.service";
import { EnergyTrafficService } from "./energy-traffic.service";
import { Co2CockpitKPIService } from "../../services/co2-cockpit-kpi.service";
import { DISPLAY_CONFIG, HideExport, YEAR } from "../../const";
import { FinalEnergyService } from "../final-energy.service";
import { isEqual } from "lodash";

@Component({
  selector: "app-chart-final-energy-traffic",
  templateUrl: "./chart-final-energy-traffic.component.html",
  styleUrls: [
    "./chart-final-energy-traffic.component.scss",
    "../../../../../../styles/chart.customlegend.include.scss"
  ],
  providers: [ChartFinalEnergyTraficService]
})
export class ChartFinalEnergyTrafficComponent implements OnInit, OnDestroy {
  public chartData: any = [];
  public chartConfig: any;

  public customLegendItemList: any[] = [];
  public dqi: number;
  public defaultDisplayConfig: DisplayChartConfig = {
    switcher: false,
    showMethod: true
  };

  private _currentHierachyPoint: any;
  private destroy$: Subject<void> = new Subject();
  public parentOfCurrentHierachyPoint: any;
  @ViewChild("chart", { static: true }) private chart: EneChartComponent;

  constructor(
    public store: Store<fromRoot.IApplicationState>,
    public utilService: UtilService,
    public translate: TranslateService,
    public chartsService: ChartsService,
    private chartFinalEnergyTraficService: ChartFinalEnergyTraficService,
    private energyTrafficService: EnergyTrafficService,
    private co2CockpitKPIService: Co2CockpitKPIService,
    private finalEnergyService: FinalEnergyService,
    private regionService: RegionService,
    @Optional() @Inject(YEAR) private reportYear: number,
    @Optional() @Inject(DISPLAY_CONFIG) public displayConfig: DisplayChartConfig,
    @Optional() @Inject(HideExport) public hideExport: boolean
  ) {}

  public ngOnInit() {
    this.translate.get("COCKPIT.TOTAL").subscribe(() => {
      this.chartFinalEnergyTraficService.initConfig();
      this.chartConfig = this.chartFinalEnergyTraficService.config;
    });

    this.chartFinalEnergyTraficService.onLoad$.pipe(takeUntil(this.destroy$)).subscribe((e) => {
      if (e.target.hasOwnProperty("series") && e.target.series.length > 0) {
        this.currentHierachyPoint = e.target.series[0].points[0];
        this.chartFinalEnergyTraficService.calculatedTotal = e.target.series[0].points[0].value;
        this.generateLegend();
      }
    });

    this.chartFinalEnergyTraficService.onSeriesClick$.pipe(takeUntil(this.destroy$)).subscribe((e) => {
      if (!e.hasOwnProperty("point") || !e.point.hasOwnProperty("parent") || typeof e.point.drillId === "undefined") {
        return;
      }
      if (this.currentHierachyPoint && this.currentHierachyPoint.id === e.point.id) {
        this.currentHierachyPoint = this.parentOfCurrentHierachyPoint;
      } else {
        this.currentHierachyPoint = e.point;
      }
      this.generateLegend();
    });

    combineLatest([this.regionService.selectedRegionUpLevels$, this.store.pipe(select(fromRoot.GetTimelineState))])
      .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe(() => {
        this.requestChartData();
      });

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.chartData = [...this.chartData];
      this.chartData[0].data = this.chartsService.setSerieNames(this.chartData[0].data);
    });
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  /**
   * creates the custom legend item list
   */
  private generateLegend() {
    if (!this.currentHierachyPoint) {
      return;
    }
    const filteredList = this.currentHierachyPoint.series.points.filter(
      (f) => f.parent === this.currentHierachyPoint.id
    );
    this.customLegendItemList = [...filteredList];
  }

  private requestChartData() {
    const calculateTotal = (total, _) => {
      const data = this.energyTrafficService.prepareChartData(total);
      return data.length ? data[0].sum : 0;
    };

    this.chart.chart && this.chart.showLoading();
    const year = this.reportYear || this.utilService.getSelectedYear();
    const selectedRegionDetails = this.regionService.selectedRegionDetails;
    const chartData$ = this.energyTrafficService.getEnergyData(selectedRegionDetails, year);
    chartData$.pipe(takeUntil(this.destroy$)).subscribe((energy) => this.updateData(energy));

    const regionIdentifier20 = this.regionService.getSelectedRegionLevelId(20);

    if (!regionIdentifier20) {
      return;
    }

    const chartData20$ = this.energyTrafficService.getEnergyData(regionIdentifier20, year);
    const childLevels$ = this.regionService.getRegionChildLevels(regionIdentifier20.regionId);

    this.co2CockpitKPIService.requestChartData({
      selectedRegionLevel: selectedRegionDetails.level,
      regionType: selectedRegionDetails.regionType,
      chartData$,
      chartData20$,
      childLevels$,
      calculateTotal
    });
  }

  public updateData(energy) {
    if (energy === null) {
      this.chart.showNoData();
      return;
    }

    this.dqi = this.finalEnergyService.calculateDQI(energy);
    this.chartData = [
      {
        type: "sunburst",
        data: this.energyTrafficService.prepareChartData(energy),
        allowDrillToNode: true,
        cursor: "pointer"
      }
    ];
    this.chartData[0].data = this.chartsService.setSerieNames(this.chartData[0].data);
  }

  private get currentHierachyPoint() {
    return this._currentHierachyPoint;
  }

  private set currentHierachyPoint(newPoint: any) {
    this._currentHierachyPoint = newPoint;
    if (newPoint === undefined) {
      this.parentOfCurrentHierachyPoint = undefined;
      return;
    }
    const findParentLevel = this.currentHierachyPoint.series.points.filter(
      (f) => f.id === this.currentHierachyPoint.parent
    );
    // there should be exactly 0 or 1 parent, no parent means top hierachy is reached (all sectors)
    if (findParentLevel.length === 1) {
      this.parentOfCurrentHierachyPoint = findParentLevel[0];
    } else {
      this.parentOfCurrentHierachyPoint = undefined;
    }
  }

  public drillUp(): void {
    if (this.parentOfCurrentHierachyPoint) {
      const nativeElement = this.chart.eneChartRef.nativeElement.querySelector(".highcharts-drillup-button");
      if (nativeElement !== null) {
        if (nativeElement.fireEvent) {
          nativeElement.fireEvent("onclick");
        } else {
          const evObj = document.createEvent("Events");
          evObj.initEvent("click", true, false);
          nativeElement.dispatchEvent(evObj);
        }
      }
      this.currentHierachyPoint = this.parentOfCurrentHierachyPoint;
      this.generateLegend();
    }
  }

  /**
   * Enable the button, if there is a higher hierachy
   */
  public isLegendButtonEnabled(): boolean {
    return this.parentOfCurrentHierachyPoint !== undefined;
  }
}
