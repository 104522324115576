/* tslint:disable:member-access */

import { Action } from "@ngrx/store";

export enum TrackingActionType {
  TRACKING_ONCLICK_EVENT = "[TRACKING] ONCLICK_EVENT"
}

export class TrackingOnClickEvent implements Action {
  readonly type = TrackingActionType.TRACKING_ONCLICK_EVENT;
  constructor(public payload: ITrackingClickEvent) {}
}

export type TrackingActions = TrackingOnClickEvent;

export interface ITrackingClickEvent {
  buttonName: string;
  category: any;
}
