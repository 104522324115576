import { Action } from "@ngrx/store";

export enum HeatpumpActionType {
  GET_HEATPUMP_DATA = "[energy-publicSector] GET_HEATPUMP_DATA",
  GET_HEATPUMP_DATA_SUCCESS = "[energy-publicSector] GET_HEATPUMP_DATA_SUCCESS",
  GET_HEATPUMP_DATA_ERROR = "[energy-publicSector] GET_HEATPUMP_DATA_ERROR"
}

export class GetHeatpumpData implements Action {
  public readonly type = HeatpumpActionType.GET_HEATPUMP_DATA;

  constructor(public connectionGrouId: string, public year: number, public scenarioId: string) {}
}

export class GetHeatpumpDataSuccess implements Action {
  public readonly type = HeatpumpActionType.GET_HEATPUMP_DATA_SUCCESS;

  constructor(public data: IHeatpumpData) {}
}

export class GetHeatpumpDataError implements Action {
  public readonly type = HeatpumpActionType.GET_HEATPUMP_DATA_ERROR;

  constructor(public error: string) {}
}

export interface IHeatpumpData {
  count?: number;
  capacity?: number;
  co2Saving?: number;
  consumption?: number;
}

export type HeatpumpAction = GetHeatpumpData | GetHeatpumpDataSuccess | GetHeatpumpDataError;
