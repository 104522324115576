import { ExportActionType, ExportActions } from "./export-actions";

export function reducer(state = [], action: ExportActions) {
  switch (action.type) {
    case ExportActionType.EXPORT_TO_CSV:
    case ExportActionType.EXPORT_TO_CSV_SUCCESS:
      return action;
    case ExportActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
