import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MeasureEditorComponent } from "./measure-editor/measure-editor.component";
import { PanelMeasuresComponent } from "./measures.component";
import { DeprecatedEneRangeSliderModule, EneFormsModule } from "libs/ui/src/lib/forms";
import { TranslateModule } from "@ngx-translate/core";
import { EneModalModule } from "libs/ui/src/lib/modal";
import { EneIconModule } from "libs/ui/src/lib/icon";
import { EneAccordeonModule } from "libs/ui/src/lib/accordeon";
import { PipesModule } from "../../../core/pipe/pipes.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NguCarouselModule } from "@ngu/carousel";
import { MatStepperModule } from "@angular/material/stepper";
import { MatInputModule } from "@angular/material/input";
import { EffectsModule } from "@ngrx/effects";
import { MeasuresEffects, SetsEffects } from "../../../state";

@NgModule({
  declarations: [MeasureEditorComponent, PanelMeasuresComponent],
  imports: [
    CommonModule,
    DeprecatedEneRangeSliderModule,
    TranslateModule,
    EneModalModule,
    EneIconModule,
    EneFormsModule,
    EneAccordeonModule,
    PipesModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NguCarouselModule,
    MatStepperModule
  ],
  exports: [MeasureEditorComponent, PanelMeasuresComponent]
})
export class MeasuresModule {}
