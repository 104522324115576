import { Injectable } from "@angular/core";
import { AccountingMethodService } from "../../../../../services/accounting-method.service";
import { ChartsService } from "../../../../../services/charts.service";
import { UtilService, IRegionIdentifier } from "../../../../../services/util.service";
import { RegionPropertyEnergyService } from "../../../../../services/region-property/region-property-energy.service";
import {
  IEnergySectorLeaf,
  IEnergySectorNode
} from "../../../../../services/region-property/interfaces/region-property-energy.interface";
import { map } from "rxjs/operators";
import { DEFAULT_TRAFFIC_COLOR } from "../final-energy.model";
import { FinalEnergyService } from "../final-energy.service";
import { Observable } from "rxjs";
import { SunburstSeries } from "../../models/traffic-data.interface";
import { omit } from "lodash";

@Injectable()
export class EnergyTrafficService {
  private lastId = 0;
  private colorAlphaStep = 0.15;
  private subsectorOrder = ["onRoad", "roadTransportation", "railway", "waterTransport", "aviation"];
  private subsectorsCount = 0;

  constructor(private chartService: ChartsService, private regionPropertyEnergyService: RegionPropertyEnergyService) {}

  private flatTree({ data, parent = "0.0", level = 1, result }): Array<SunburstSeries> {
    for (const i in data) {
      if (data[i].hasOwnProperty("value") && data[i].hasOwnProperty("quality")) {
        result.push({
          id: `${level}.${this.lastId++}`,
          parent,
          key: i,
          translationKey: `RESOURCES.${i.toUpperCase()}`,
          value: data[i]["value"]
        });
        continue;
      } else {
        result.push({
          id: `${level}.${++this.lastId}`,
          parent,
          translationKey: `RESOURCES.${i.toUpperCase()}`,
          key: i
        });
        this.subsectorsCount++;

        this.flatTree({
          data: data[i],
          parent: `${level}.${this.lastId}`,
          level: level + 1,
          result
        });
      }
    }

    return result;
  }

  private setColors(data: Array<SunburstSeries>) {
    let alpha = 1;
    const each = Math.ceil(data.length / (alpha / this.colorAlphaStep));

    const [r, g, b] = this.chartService.hexToRgb(DEFAULT_TRAFFIC_COLOR);
    data.forEach((item, idx) => {
      if (idx > 1 && !(idx % each)) {
        alpha -= this.colorAlphaStep;
      }
      item.color = idx > 1 ? `rgba(${r}, ${g}, ${b}, ${alpha} )` : DEFAULT_TRAFFIC_COLOR;
    });
  }

  private treefy(data: Array<SunburstSeries>) {
    const tree = [];
    const dataMap = {};
    data.forEach((item) => {
      dataMap[item["id"]] = item;
      item.childrens = [];
    });

    data.forEach((item) => {
      if (!item["parent"]) {
        tree.push(item);
      } else {
        dataMap[item["parent"]].childrens.push(item);
      }
    });
    return tree;
  }

  public prepareChartData(data: any): Array<SunburstSeries> {
    let chartData = this.flatTree({
      data,
      result: [
        {
          id: `0.0`,
          parent: "",
          color: DEFAULT_TRAFFIC_COLOR,
          translationKey: "RESOURCES.TRANSPORTATION",
          key: "total"
        }
      ]
    });
    const tree = this.treefy(chartData);
    this.setSumChildrens(tree[0]);

    chartData = chartData.sort((a, b) => {
      if (a.parent && b.parent && b.parent !== a.parent) {
        return parseFloat(a.parent) - parseFloat(b.parent);
      }

      if (a.id.slice(0, 1) === b.id.slice(0, 1)) {
        return (b.sum || b.value) - (a.sum || a.value);
      }

      return parseInt(a.id.slice(0, 1), 10) - parseInt(b.id.slice(0, 1), 10);
    });

    this.setColors(chartData);
    this.sortSubsectors(chartData);
    this.subsectorsCount = 0;

    return chartData.filter((item) => item.sum > 0 || item.value > 0);
  }

  private setSumChildrens(tree: SunburstSeries): number {
    let sum = 0;
    tree.childrens.forEach((child) => {
      if (child.childrens && child.childrens.length) {
        sum += this.setSumChildrens(child);
      } else {
        sum += child.value;
      }
      tree["sum"] = sum;
    });
    return tree["sum"] || 0;
  }

  public getEnergyData(
    regionIdentifier: IRegionIdentifier,
    year: number
  ): Observable<{ [id: string]: IEnergySectorNode | IEnergySectorLeaf }> {
    return this.regionPropertyEnergyService
      .getEnergyTransportationData(regionIdentifier, year)
      .pipe(map(([rawData]) => omit(rawData, "year")));
  }

  private sortSubsectors(data: any): void {
    const subsectors = data.splice(1, this.subsectorsCount);
    const sortedSubsectors = [];

    this.subsectorOrder.forEach((item: string) => {
      const subsector = subsectors.find(({ key }) => key === item);

      if (subsector) {
        sortedSubsectors.push(subsector);
      }

      return;
    });

    data.splice(1, 0, ...sortedSubsectors);
  }
}
