import { EnergyTypes } from "./../../../../../configs/accounting-method";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { ISectorConfig, sectorConfig } from "../../../../../models/co2-sectors-config.model";
import { RegionPropertyEmissionService } from "../../../../../services/region-property/region-property-emission.service";
import { AccountingMethodService } from "../../../../../services/accounting-method.service";
import { UtilService, IRegionIdentifier, RegionType } from "../../../../../services/util.service";

@Injectable({
  providedIn: "root"
})
export class ChartCo2SectorsService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    public accountingMethodService: AccountingMethodService,
    private utilService: UtilService,
    private emissionService: RegionPropertyEmissionService
  ) {}

  public getChartData(regionIdentifier: IRegionIdentifier, year?: number): Observable<Array<ICo2EmissionSectorResult>> {
    if (
      !regionIdentifier?.regionId ||
      !regionIdentifier?.regionType ||
      !this.accountingMethodService.selectedAccountingMethod$.value
    ) {
      return of(undefined);
    }

    const selectedYear = year || this.utilService.getSelectedYear();
    const sectorQuery = sectorConfig.sectors
      .filter((sector) => sector.methods.includes(this.accountingMethodService.selectedAccountingMethod$.value))
      .map((sector) => ({
        ...sector,
        weatherCorrection: sector.weatherCorrection ? this.accountingMethodService.weatherCorrection$.value : null
      }));

    return this.emissionService.getCo2EmissionSectorData(regionIdentifier, sectorQuery, selectedYear);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}

export interface ICo2EmissionSectorsQuery {
  type: EnergyTypes;
  level: number;
  getEndpointRoute: (regionId: string, level: number, regionType?: RegionType) => string;
  service: string;
  queries?: string;
  weatherCorrection?: boolean;
}

export interface ICo2EmissionSectorResult {
  result: ICo2EmissionSectorRoot[];
  sector: ISectorConfig;
}

export interface ICo2EmissionSectorLeaf {
  value: number;
  quality?: number;
}

export interface ICo2EmissionSectorNode {
  [id: string]: ICo2EmissionSectorNode | ICo2EmissionSectorLeaf;
}

export interface ICo2EmissionSectorRoot {
  year: number;
  [id: string]: ICo2EmissionSectorNode | ICo2EmissionSectorLeaf | any;
}
