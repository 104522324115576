import { trackEvent } from "@redux-beacon/google-analytics";
import { PanelEvent } from "@energy-city/ui/sidenav";
import { SideNavigationEvent } from "../../state/sidenavigation/sidenavigation-actions";

export const panelChange = trackEvent((action: SideNavigationEvent) => {
  const event = action.payload;
  const response: any = {
    category: "panel",
    action: action.payload.name,
    label: false
  };

  if (event.state === true && event.event === PanelEvent.VISIBLE) {
    // open
    response.label = "opened";
  } else if (event.state === false && event.event === PanelEvent.VISIBLE) {
    // closed
    response.label = "closed";
  } else if (event.state === true && event.event === PanelEvent.COLLAPSED) {
    // collapsed
    response.label = "collapsed";
  } else if (event.state === true && event.event === PanelEvent.ENLARGED) {
    // enlarged
    response.label = "enlarged";
  }
  return response;
});

export const panelChangeValidator = (event) => {
  return event.eventLabel !== false;
};
