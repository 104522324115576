import { Action } from "@ngrx/store";

export enum SetsActionTypes {
  GET_SETS = "[SETS] GET_SETS",
  GET_SETS_SUCCESS = "[SETS] GET_SETS_SUCCESS",
  GET_SETS_ERROR = "[SETS] GET_SETS_ERROR",
  CREATE_SET = "[SETS] CREATE_SET",
  EDIT_SET = "[SETS] EDIT_SET",
  DELETE_SET = "[SETS] DELETE_SET",
  DELETE_SET_SUCCESS = "[SETS] DELETE_SET_SUCCESS",
  DELETE_SET_ERROR = "[SETS] DELETE_SET_ERROR",
  SAVE_SET_SUCCESS = "[SETS] SAVE_SET_SUCCESS",
  SAVE_SET_ERROR = "[SETS] SAVE_SET_ERROR",
  SET_CURRENT_SET = "[SETS] SET_CURRENT_SET"
}

export class GetSets implements Action {
  public readonly type = SetsActionTypes.GET_SETS;
  constructor(public regionId: string) {}
}

export class GetSetsSuccess implements Action {
  public readonly type = SetsActionTypes.GET_SETS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetSetsError implements Action {
  public readonly type = SetsActionTypes.GET_SETS_ERROR;
  constructor(public payload: any) {}
}

export class CreateSet implements Action {
  public readonly type = SetsActionTypes.CREATE_SET;
  constructor(public form: any, public regionId: string) {}
}

export class SaveSetSuccess implements Action {
  public readonly type = SetsActionTypes.SAVE_SET_SUCCESS;
  constructor(public newSetId?: string) {}
}

export class SaveSetError implements Action {
  public readonly type = SetsActionTypes.SAVE_SET_ERROR;
  constructor(public payload: any) {}
}

export class EditSet implements Action {
  public readonly type = SetsActionTypes.EDIT_SET;
  constructor(public form: any, public regionId: string) {}
}

export class DeleteSet implements Action {
  public readonly type = SetsActionTypes.DELETE_SET;
  constructor(public set: any, public regionId: string) {}
}

export class DeleteSetSuccess implements Action {
  public readonly type = SetsActionTypes.DELETE_SET_SUCCESS;
}

export class DeleteSetError implements Action {
  public readonly type = SetsActionTypes.DELETE_SET_ERROR;
}

export class SetCurrentSet implements Action {
  public readonly type = SetsActionTypes.SET_CURRENT_SET;
  constructor(public payload: any) {}
}
export type SetsActions =
  | CreateSet
  | GetSets
  | GetSetsSuccess
  | GetSetsError
  | SaveSetSuccess
  | DeleteSetSuccess
  | DeleteSetError
  | SetCurrentSet
  | SaveSetError;
