import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IValues, IMapping, IPending, IDescribingIcon, INotAvailableText } from "../interfaces/kpis";

@Injectable({
  providedIn: "root"
})
export class EneKpisV2Service {
  public icons$: Observable<IMapping>;

  public describingIcons$: Observable<IDescribingIcon>;
  public values$: Observable<IMapping>;
  public pending$: Observable<IPending>;
  public notAvailableText$: Observable<INotAvailableText>;
  private valuesSubject = new Subject<IMapping>();
  private pendingSubject = new Subject<IPending>();
  private iconsSubject = new Subject<IMapping>();
  private describingIconsSubject = new Subject<IDescribingIcon>();
  private notAvailableTextSubject = new Subject<INotAvailableText>();

  constructor() {
    this.values$ = this.valuesSubject.asObservable();
    this.icons$ = this.iconsSubject.asObservable();
    this.pending$ = this.pendingSubject.asObservable();
    this.describingIcons$ = this.describingIconsSubject.asObservable();
    this.notAvailableText$ = this.notAvailableTextSubject.asObservable();
  }

  public runMapping(values: IValues, namespace: string): void {
    this.valuesSubject.next({ values, namespace });
  }

  public activateIcon(icon_name: String) {
    this.describingIconsSubject.next({ active: true, name: icon_name });
  }

  public deactivateIcon(icon_name: String) {
    this.describingIconsSubject.next({ active: false, name: icon_name });
  }

  public pending(namespaces: Array<string> | string): void {
    this.toArray(namespaces).forEach((namespace) => {
      this.pendingSubject.next({ namespace });
    });
  }

  public setNotAvailableText(notAvailableText: string, namespace: string) {
    this.notAvailableTextSubject.next({ text: notAvailableText, namespace });
  }

  private toArray(namespaces: Array<string> | string | undefined): Array<string> {
    return Array.isArray(namespaces) ? namespaces : [namespaces];
  }
}
