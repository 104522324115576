import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneTooltipModule } from "@energy-city/ui/tooltip";
import { EneSidenavComponent } from "./components/sidenav/ui-sidenav.component";
import { EneSidenavContentFixedComponent } from "./components/sidenav/ui-sidenav-content-fixed.component";
import { EneSidenavContentEnlargedComponent } from "./components/sidenav/ui-sidenav-content-enlarged.component";
import { EneSidenavContentFullComponent } from "./components/sidenav/ui-sidenav-content-full.component";
import { EneSidepanelComponent } from "./components/sidepanel/ui-sidepanel.component";
import {
  EneSidebarDirective,
  EneSidenavEndDirective,
  EneSidenavContentDirective,
  EneSidenavStartDirective,
  EneSidenavCenterDirective,
  EneSidenavContentContainerDirective,
  EneSidebarItemDirective,
  EneSidenavFooterDirective
} from "./directives/ui-sidenav.directives";
import { EneSidenavService } from "./services/ui-sidenav.service";

@NgModule({
  imports: [CommonModule, EneIconModule, EneTooltipModule, TranslateModule],
  declarations: [
    EneSidenavComponent,
    EneSidepanelComponent,
    EneSidebarDirective,
    EneSidenavContentDirective,
    EneSidenavContentFixedComponent,
    EneSidenavContentEnlargedComponent,
    EneSidenavContentFullComponent,
    EneSidenavFooterDirective,
    EneSidenavStartDirective,
    EneSidenavCenterDirective,
    EneSidenavEndDirective,
    EneSidenavContentContainerDirective,
    EneSidebarItemDirective
  ],
  exports: [
    EneSidenavComponent,
    EneSidepanelComponent,
    EneSidebarDirective,
    EneSidenavContentDirective,
    EneSidenavContentFixedComponent,
    EneSidenavContentEnlargedComponent,
    EneSidenavContentFullComponent,
    EneSidenavFooterDirective,
    EneSidenavStartDirective,
    EneSidenavCenterDirective,
    EneSidenavEndDirective,
    EneSidenavContentContainerDirective,
    EneSidebarItemDirective
  ],
  providers: [EneSidenavService]
})
export class EneSidenavModule {}
