<div class="head">
  <h4 class="subtitle">{{ "APP.COMMENT_FORM_SUBTITLE" | translate }}</h4>
</div>
<div class="body" [class.editing]="editing">
  <div class="body_head">
    <p *ngIf="!commentDate" class="text">{{ "APP.NO_COMMENT_YET" | translate }}</p>
    <p *ngIf="commentDate" class="text">
      {{ "APP.LAST_CHANGE_AT" | translate }} {{ commentDate }}
      <span *ngIf="commentPerson"> {{ "APP.WITH" | translate }} {{ commentPerson }}</span>
    </p>

    <div class="edit_button" (click)="edit()">
      <ene-icon icon="ec_edit"></ene-icon>
    </div>
  </div>
  <div class="textarea">
    <textarea
      [maxLength]="maxCount"
      [disabled]="!editing"
      class="ene-surface-input-main contact-form__input__contact-textarea"
      [(ngModel)]="message"
    ></textarea>
    <p class="count_left">{{ "APP.CHARACTERS_LEFT" | translate }}: {{ maxCount - message.length }}</p>
  </div>
</div>
<div class="footer" [class.editing]="editing">
  <gp-button xlarge secondary outlined (click)="closeModal()">
    {{ "APP.CANCEL" | translate }}
  </gp-button>
  <gp-button secondary xlarge filled (click)="send()">
    {{ "APP.SAVE" | translate }}
  </gp-button>
</div>
