import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as fromRoot from "@energy-city/components";
import { Store } from "@ngrx/store";
import { isEqual } from "lodash";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, take, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AccountingMethod, MixType } from "../configs/accounting-method";
import { IParameterModel } from "../models/accounting-method";
import { ModulesService } from "./modules.service";

function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}
interface IAccountingMethodConfig {
  type: AccountingMethod;
  disabled?: boolean;
}

@Injectable({
  providedIn: "root"
})
export class AccountingMethodService {
  public selectedAccountingMethod$: BehaviorSubject<AccountingMethod> = new BehaviorSubject(undefined);
  public selectedMixType$: BehaviorSubject<MixType> = new BehaviorSubject(undefined);
  public weatherCorrection$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  public accountingMethodsMap: Map<AccountingMethod, boolean> = new Map();
  public useUserFactors$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

  /** change the timestamp to break the `distinctUntilChanged` pattern and force a reload of data */
  public timestamp$: BehaviorSubject<number> = new BehaviorSubject(Date.now());

  public get currentParameters$(): Observable<IParameterModel> {
    return this._currentParameters$;
  }

  private _currentParameters$: Observable<IParameterModel>;

  constructor(
    private http: HttpClient,
    public store: Store<fromRoot.IApplicationState>,
    private modulesService: ModulesService
  ) {
    this.init();
  }

  private init() {
    this.modulesService.afterInit$.pipe(take(1)).subscribe(() => {
      const clientConfig = this.modulesService.getClientConfig();
      const accountingMethods: Array<IAccountingMethodConfig> = clientConfig.accountingMethods.methods;
      const defaultAccountingMethod = accountingMethods.find(({ disabled }) => !Boolean(disabled));

      accountingMethods.forEach(({ type, disabled }) => {
        this.accountingMethodsMap.set(type, Boolean(disabled));
      });

      this.updateParameters({
        accountingMethod: defaultAccountingMethod.type,
        mixType: MixType.FEDERAL,
        weatherCorrection: false,
        useUserFactors: false
      });
    });

    this._currentParameters$ = combineLatest([
      this.selectedAccountingMethod$,
      this.selectedMixType$,
      this.weatherCorrection$,
      this.useUserFactors$,
      this.timestamp$
    ]).pipe(
      filter((x) => x.every(inputIsNotNullOrUndefined)),
      debounceTime(5),
      distinctUntilChanged(isEqual),
      map(([accountingMethod, mixType, weatherCorrection, useUserFactors, timestamp]) => {
        const model: IParameterModel = {
          accountingMethod,
          mixType,
          weatherCorrection,
          useUserFactors,
          timestamp
        };
        return model;
      })
    );
  }

  public updateParameters(model: IParameterModel) {
    const { accountingMethod, mixType, weatherCorrection, useUserFactors } = model;
    this.selectedAccountingMethod$.next(accountingMethod);
    this.selectedMixType$.next(mixType);
    this.weatherCorrection$.next(weatherCorrection);
    this.useUserFactors$.next(useUserFactors);
  }

  /**
   * Calling this method will set the `timestamp$` observable to the current
   * timestamp. This can be useful when the AccountingMethod etc doesn't change,
   * but the emission input data or the emission-factors change.
   */
  public forceReload() {
    this.timestamp$.next(Date.now());
  }

  public getAccountingMethods(): Observable<IParameterModel> {
    const url = `${environment.configState}/settings/balancing`;
    return this.http
      .get<{
        method: AccountingMethod;
        mixType: "federal" | "local";
        weatherCorrection: boolean;
        useUserFactors?: boolean;
      }>(url)
      .pipe(
        map((data) => {
          const model: IParameterModel = {
            accountingMethod: data.method,
            mixType: data.mixType === "federal" ? MixType.FEDERAL : MixType.LOCAL,
            weatherCorrection: data.weatherCorrection,
            useUserFactors: data.useUserFactors ?? false
          };
          return model;
        }),
        tap((model) => this.updateParameters(model))
      );
  }

  public saveAccountingMethod(model: IParameterModel): Observable<IParameterModel> {
    const url = `${environment.configState}/settings/balancing`;
    const body = {
      method: model.accountingMethod,
      mixType: model.mixType === MixType.FEDERAL ? "federal" : "local",
      weatherCorrection: model.weatherCorrection,
      useUserFactors: model.useUserFactors
    };

    return this.http.put(url, body).pipe(map(() => model));
  }

  public isMethodDisabled(method: AccountingMethod): boolean {
    return !this.accountingMethodsMap.has(method) || this.accountingMethodsMap.get(method);
  }
}
