import { ActionReducerMap } from "@ngrx/store/src/models";

import { reducer } from "./app-reducer";
import { AppActionType } from "./app-actions";

export const appReducers: ActionReducerMap<any> = {
  app: reducer
};

export interface IAppState {
  type: AppActionType;
  payload: any;
}
