<div id="glossary">
  <header mat-dialog-title>
    {{ glossary_title | translate }}
    <ene-icon icon="ec_building_delete_address" class="closebutton" (click)="close()"> </ene-icon>
  </header>
  <hr *ngIf="glossary_tabs.length > 1" />
  <ene-tabs *ngIf="glossary_tabs.length > 1" (currentTabChange)="changeTab($event.tabName)" class="pill">
    <ene-tab
      *ngFor="let tab of glossary_tabs; let i = index"
      [tabTitle]="tab.title | translate"
      [tabName]="i"
      [active]="i === glossary_activeTab"
    >
    </ene-tab>
  </ene-tabs>
  <hr />
  <mat-dialog-content
    #glossaryContent
    *ngIf="glossary_activeTab >= 0 && glossary_tabs[glossary_activeTab]"
    (click)="goToAnchorByEvent($event)"
    class="html-content"
    [innerHTML]="glossary_tabs[glossary_activeTab].content | translate"
  ></mat-dialog-content>
</div>
