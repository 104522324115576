/* tslint:disable:member-access */

import { Action } from "@ngrx/store";
import { IPanelEvent, EneSidepanelComponent } from "@energy-city/ui/sidenav";

export enum SideNavigationActionType {
  SIDE_NAVIGATION_EVENT = "[SideNavigation] SIDE_NAVIGATION_EVENT",
  SIDE_NAVIGATION_INIT = "[SideNavigation] SIDE_NAVIGATION_INIT",
  CHANGE_PANEL_STATE = "[SideNavigation] CHANGE_PANEL_STATE",
  ERROR = "[Sidenavigation] ERROR"
}

export class SideNavigationEvent implements Action {
  readonly type = SideNavigationActionType.SIDE_NAVIGATION_EVENT;
  constructor(public payload: IPanelEvent) {}
}

export class SideNavigationInit implements Action {
  readonly type = SideNavigationActionType.SIDE_NAVIGATION_INIT;
  constructor(public payload: EneSidepanelComponent) {}
}

export class ChangePanelState implements Action {
  readonly type = SideNavigationActionType.CHANGE_PANEL_STATE;
  constructor(public payload: IPanelEvent) {}
}

export class SideNavigationError implements Action {
  readonly type = SideNavigationActionType.ERROR;
  constructor(public payload: any) {}
}

export type SidenavigationActions = SideNavigationEvent | SideNavigationInit | ChangePanelState | SideNavigationError;
