<div class="dropdown-element" [ngClass]="{ active: active }">
  <div class="box" (click)="changeTopic()">
    <div class="box-info">
      <ene-icon *ngIf="arrowPosition === 'start'" icon="ec_controls_closed_dropdown"></ene-icon>
      <span [innerHTML]="title"></span>
      <img [src]="icon" *ngIf="icon" />
    </div>
    <div class="label">
      <div class="box-label" *ngIf="measures >= 0" [ngStyle]="{ 'background-color': labelColor }">
        <span>{{ measures }} {{ "ACCORDEON.LABEL.ACTIVE_MEASURES" | translate }}</span>
      </div>
      <ene-icon *ngIf="arrowPosition === 'end'" icon="ec_controls_closed_dropdown"></ene-icon>
    </div>
  </div>
  <div class="detail_wrapper">
    <div class="detail-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
