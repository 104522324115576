import { Component, Input, OnInit, Injector, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { PrintService } from "../../services/print.service";
import {
  UtilService,
  AccountingMethodService,
  EntryComponents,
  DisplayChartConfig,
  EnergyType
} from "@energy-city/components";
import { NUMBER_FORMAT } from "../../reports.config";
import { DISPLAY_CONFIG, HideExport, YEAR } from "../../../../modules/co2-cockpit/charts/const";
import { EnergyCarrierService } from "../../../co2-cockpit/charts/final-energy/energy-carrier/energy-carrier.service";
import { TranslateService } from "@ngx-translate/core";

enum TableColumns {
  NAME = "name",
  PRIVATE_HOUSEHOLD = "privateHousehold",
  COMMERCE = "commerce",
  INDUSTRY = "industry",
  COMMUNITY_FACILITIES = "communityFacilities",
  TRANSPORTATION = "transportation"
}

@Component({
  selector: "app-energy-per-energy-carrier-page",
  templateUrl: "./energy-carrier.component.html",
  styleUrls: ["./energy-carrier.component.scss"]
})
export class EnergyPerEnergyCarrierPageComponent implements OnInit, OnDestroy {
  @Input() public config;

  public numberFormat = NUMBER_FORMAT;
  public locale: string;
  public component = EntryComponents.charts.ChartFinalEnergyPerEnergyCarrierComponent;
  public columns: Array<any>;
  public displayedColumns: Array<string>;
  public tableColumns = TableColumns;
  public data$: Observable<any>;
  public year: number;
  public chartInjector: Injector;
  public displayConfig: DisplayChartConfig = {
    switcher: false,
    showMethod: true
  };

  private destroy$ = new Subject<void>();

  constructor(
    private energyCarrierService: EnergyCarrierService,
    private printService: PrintService,
    private utilService: UtilService,
    private injector: Injector,
    private translate: TranslateService,
    private accountingMethodService: AccountingMethodService
  ) {}

  public ngOnInit(): void {
    this.locale = this.translate.currentLang;
    this.year = this.printService.selectedReportYear;
    this.columns = this.getColumns();
    this.displayedColumns = this.columns.map((item) => item.id);
    this.data$ = this.getData();
    this.chartInjector = Injector.create({
      providers: [
        { provide: YEAR, useValue: this.year },
        { provide: DISPLAY_CONFIG, useValue: this.displayConfig },
        { provide: HideExport, useValue: true }
      ],
      parent: this.injector
    });

    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.locale = this.translate.currentLang));
  }

  private getColumns(): Array<any> {
    return [
      {
        id: TableColumns.NAME,
        name: this.translate.instant("SELECTION.RENEWABLE.ENERGY_CARRIER")
      },
      {
        id: TableColumns.PRIVATE_HOUSEHOLD,
        name: this.translate.instant("RESOURCES.PRIVATEHOUSEHOLD")
      },
      {
        id: TableColumns.COMMERCE,
        name: this.translate.instant("RESOURCES.COMMERCE")
      },
      {
        id: TableColumns.INDUSTRY,
        name: this.translate.instant("RESOURCES.INDUSTRY")
      },
      {
        id: TableColumns.COMMUNITY_FACILITIES,
        name: this.translate.instant("RESOURCES.COMMUNITYFACILITIES")
      },
      {
        id: TableColumns.TRANSPORTATION,
        name: this.translate.instant("RESOURCES.TRANSPORTATION")
      }
    ];
  }

  private getData(): Observable<any> {
    const year = this.year;

    return this.utilService.regionIdentifier$.pipe(
      switchMap((regionIdentifier) =>
        this.energyCarrierService.getEnergyData(
          regionIdentifier,
          EnergyType.FINAL,
          year,
          this.accountingMethodService.weatherCorrection$.value,
          this.accountingMethodService.selectedMixType$.value
        )
      ),
      map((data) => {
        const merged = this.utilService.mergeData(data);

        const keys = Object.keys(merged);
        const result = keys.reduce((acc, key) => {
          return [...acc, { ...merged[key], name: key }];
        }, []);

        return result;
      })
    );
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
