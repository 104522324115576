import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneChartModule } from "@energy-city/ui/chart";
import { SharedModule } from "../../../../shared/shared.module";
import { AccountingInfoWrapperModule, SunburstLegendModule } from "../../common";
import { MainPolluterChartComponent } from "./main-polluter.component";
import { PublicSectorMainPolluterChartComponent } from "./extensions/public-sector.component";
import { Co2EmissionMainPolluterChartComponent } from "./extensions/co2-emission.component";
import { MainPolluterService } from "./main-polluter.service";
import "@enersis/gp-components/gp-chart-export-menu";
import "@enersis/gp-components/gp-icon-button";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    EneChartModule,
    AccountingInfoWrapperModule,
    SunburstLegendModule
  ],
  declarations: [
    MainPolluterChartComponent,
    Co2EmissionMainPolluterChartComponent,
    PublicSectorMainPolluterChartComponent
  ],
  providers: [MainPolluterService],
  entryComponents: [
    MainPolluterChartComponent,
    Co2EmissionMainPolluterChartComponent,
    PublicSectorMainPolluterChartComponent
  ],
  exports: [Co2EmissionMainPolluterChartComponent, PublicSectorMainPolluterChartComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainPolluterChartModule {}
