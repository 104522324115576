import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ColumnKeyPipe } from "./column-key.pipe";
import { EmptyPipe } from "./empty.pipe";
import { GroupByPipe } from "./group-by.pipe";
import { AppNumberPipe } from "./number.pipe";
import { TrustHtmlPipe } from "./trustHtml.pipe";
import { NumberSeparatorPipe } from "./number-separator.pipe";
import { CountOfPipe } from "./count-of.pipe";
import { NameByIdPipe } from "./name-by-id.pipe";

@NgModule({
  declarations: [
    ColumnKeyPipe,
    EmptyPipe,
    GroupByPipe,
    AppNumberPipe,
    TrustHtmlPipe,
    NumberSeparatorPipe,
    CountOfPipe,
    NameByIdPipe
  ],
  imports: [CommonModule],
  exports: [
    EmptyPipe,
    ColumnKeyPipe,
    GroupByPipe,
    AppNumberPipe,
    TrustHtmlPipe,
    NumberSeparatorPipe,
    CountOfPipe,
    NameByIdPipe
  ]
})
export class PipesModule {}
