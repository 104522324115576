import { State } from "./index";
import Big from "big.js";
import { DataInputActionsUnion, DataInputActionTypes, IUpdateProps } from "./data-input.actions";
import { set, get } from "lodash";
import { isNumber } from "util";

const UNIT_PATH = "payload.metadata.unitOfMeasure";

const initialState = (): State => ({
  metadata: "",
  unitOptions: null,
  data: [],
  locked: true,
  loading: false,
  errorMessage: ""
});

export function reducer(state: State = initialState(), action: DataInputActionsUnion): State {
  switch (action.type) {
    case DataInputActionTypes.GET_TABLE_DATA: {
      return { ...state, loading: true };
    }
    case DataInputActionTypes.GET_TABLE_DATA_SUCCESS: {
      const metadata = get(action, UNIT_PATH, "");

      return {
        ...state,
        loading: false,
        errorMessage: "",
        metadata,
        data: action.payload.data
      };
    }
    case DataInputActionTypes.UPDATE_TABLE_DATA_BY_ID: {
      return updateById(state, action.props);
    }
    case DataInputActionTypes.UPDATE_UNIT: {
      return {
        ...state,
        unitOptions: action.props
      };
    }
    case DataInputActionTypes.LOCK_TABLE: {
      return {
        ...state,
        locked: action.prop
      };
    }
    case DataInputActionTypes.GET_TABLE_DATA_ERROR: {
      return {
        ...initialState(),
        loading: false,
        locked: state.locked,
        errorMessage: action.errorMessage
      };
    }
    default: {
      return state;
    }
  }
}

function updateById(state: State, props: IUpdateProps) {
  const { id, year, value, quality } = props;
  const { scale } = state.unitOptions;
  const foundItem = state.data.find((item) => item.year === year);
  let _value = value;
  if (!isNumber(_value) && !isNumber(quality)) {
    if (foundItem) {
      set(foundItem, id, undefined);
    }
    return state;
  }

  if (scale && isNumber(_value)) {
    _value = +String(new Big(_value).div(scale));
  }

  if (!isNumber(_value)) {
    _value = undefined;
  }

  if (foundItem) {
    set(foundItem, id, { value: _value, quality });
  } else {
    const newCell = set({ year }, id, { value: _value, quality });

    state.data.push(newCell);
  }

  return state;
}
