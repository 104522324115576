import { ICategory } from "../model/category.interface";
import { MainTableCategories } from "../model/main-table-categories.enum";

export const TABLE_CONFIG: Array<ICategory> = [
  {
    id: MainTableCategories.TRANSPORTATION,
    name: "RESOURCES.TRANSPORTATION",
    icon: "ec_verkehr"
  },
  {
    id: MainTableCategories.STATIONARY_ENERGY,
    name: "RESOURCES.STATIONARYENERGY",
    icon: "ec_station"
  },
  {
    id: MainTableCategories.WASTE,
    name: "RESOURCES.WASTEANDWASTEWATER",
    icon: "ec_abfall"
  },
  {
    id: MainTableCategories.AFOLU,
    name: "RESOURCES.AFOLU",
    icon: "ec_landwirtschaft"
  },
  {
    id: MainTableCategories.IPPU,
    name: "RESOURCES.IPPU",
    icon: "ec_industrie"
  },
  {
    id: MainTableCategories.FEED_IN,
    name: "RESOURCES.FEED_IN",
    icon: "ec_feed-in"
  }
];
