import { Injectable, OnDestroy } from "@angular/core";
import { sum, get, omit } from "lodash";
import { EneKpisV2Service } from "@energy-city/ui/kpis-v2";
import { BehaviorSubject, Subject } from "rxjs";
import { Store, select } from "@ngrx/store";
import { filter, takeUntil } from "rxjs/operators";
import {
  GetDatabaseChartData,
  ClearDatabaseChartData,
  GetSpecificEmissionFactor,
  IApplicationState,
  getDatabaseChartData,
  getSpecificEmissionFactor
} from "../../../../state/index";
import { renewable, chartTranslateKeys, ChartTypes } from "../../../../models/charts.model";
import { minYear } from "../../../../models/co2-kpi.model";
import { RegionService } from "../../../../services/region.service";
import { ChartsService } from "../../../../services/charts.service";
import { UtilService, IRegionIdentifier } from "../../../../services/util.service";
import { TranslateService } from "@ngx-translate/core";
import { DecimalPipe } from "@angular/common";

@Injectable()
export class DatabaseChartService implements OnDestroy {
  public chartTranslateKeys = chartTranslateKeys;
  public renewable = renewable;
  public chartType;
  public chartData$: BehaviorSubject<any> = new BehaviorSubject(null);
  public chartConfig$: BehaviorSubject<any> = new BehaviorSubject(null);
  public dqi$: Subject<any> = new Subject();
  private categories;
  private series;
  private kpi = {
    share: null,
    emission_factor: null,
    change_since_1990: null
  };
  private destroy$: Subject<void> = new Subject();
  private data: { consumption: any; chartData: any };
  private emissionFactor1990: number;
  private getChartData$: Subject<string> = new Subject();
  private regionIdentifier: IRegionIdentifier;

  constructor(
    private eneKpisV2Service: EneKpisV2Service,
    private chartsService: ChartsService,
    private utilService: UtilService,
    private regionService: RegionService,
    private store: Store<IApplicationState>,
    private translate: TranslateService,
    private decimalPipe: DecimalPipe
  ) {
    this.store
      .pipe(
        select(getDatabaseChartData),
        filter((data) => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe(({ consumption, chartData }) => {
        this.data = { consumption, chartData };
        this.initChart();
      });

    this.store
      .pipe(
        select(getSpecificEmissionFactor),
        filter((data) => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe((kpis) => {
        if (!this.regionId) {
          return;
        }

        const kpi = get(kpis, `${this.regionId}`, []);
        const minYearKpi = kpi?.find((item) => item.year === minYear);
        const selectedYearKpi = kpi?.find((item) => item.year === this.utilService.getSelectedYear());
        this.emissionFactor1990 = minYearKpi && minYearKpi.factor * Math.pow(10, 6);
        this.kpi.emission_factor = selectedYearKpi ? selectedYearKpi.factor * Math.pow(10, 6) : undefined;

        if (!isNaN(this.emissionFactor1990) && !isNaN(this.kpi.emission_factor)) {
          this.kpi.change_since_1990 =
            this.kpi.emission_factor === 0
              ? 0
              : ((this.kpi.emission_factor * 100) / this.emissionFactor1990 - 100) / 100;
        } else {
          this.kpi.change_since_1990 = undefined;
        }

        this.fillKpi();
      });

    this.getChartData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.regionId) {
        return;
      }

      this.store.dispatch(new GetSpecificEmissionFactor(this.regionIdentifier, this.chartType));
      this.store.dispatch(new GetDatabaseChartData(this.regionIdentifier));
    });
  }

  private get regionId(): string {
    return this.regionIdentifier?.regionId;
  }

  private initChart() {
    this.series = this.prepareData();
    this.series["consumption"] = this.calculateConsumption();
    this.setChartConfig();
    const data = this.convertData();
    this.fillKpiShare();
    this.chartData$.next(data);
  }

  private calculateConsumption() {
    const chartData = this.data.consumption;
    const consumption = Array(this.categories.length).fill(0);
    chartData.forEach((items) => {
      const year = items.year;
      Object.values(omit(items, "year")).forEach((item: any) => {
        const idx = this.categories.indexOf(year);
        if (idx !== -1) {
          consumption[idx] += item.value;
        }
      });
    });
    return {
      name: "consumption",
      data: consumption
    };
  }

  private prepareData() {
    const dqi = { sum: 0, quality: 0 };
    const chartData = this.data.chartData;
    const maxYear = this.utilService.getSelectedYear();
    const allYears = chartData.map((item) => item.year).sort();
    let lastYearIdx = allYears.indexOf(maxYear);
    if (lastYearIdx === -1) {
      lastYearIdx = allYears.length;
    }
    let firstYearIdx = lastYearIdx - 6;
    if (firstYearIdx < 0) {
      firstYearIdx = -1;
    }
    this.categories = allYears.slice(firstYearIdx + 1, lastYearIdx + 1);
    const series = {};
    chartData.forEach((items) => {
      const year = items.year;
      Object.values(omit(items, "year")).forEach((item: any) => {
        for (const i in item) {
          if (!item.hasOwnProperty(i)) {
            continue;
          }
          if (!series[i]) {
            series[i] = {
              name: i,
              data: Array(this.categories.length).fill(0)
            };
          }
          const idx = this.categories.indexOf(year);
          if (idx === -1) {
            continue;
          }
          series[i].data[idx] += item[i].value;
          if (item[i].quality && item[i].value) {
            dqi.quality += item[i].quality * item[i].value;
            dqi.sum += item[i].value;
          }
        }
      });
    });
    this.dqi$.next(dqi.quality / dqi.sum);
    return series;
  }

  private convertData() {
    const _data = Object.values(this.chartTranslateKeys).map((item: any) => ({
      ...get(this.series, `${item.id}`, {}),
      ...item
    }));

    _data.sort((a, b) => {
      const idxa = this.renewable.indexOf(a.id);
      const idxb = this.renewable.indexOf(b.id);
      if (idxa >= 0 && idxb === -1) {
        return -1;
      }
      if (idxa === -1 && idxb >= 0) {
        return 1;
      }
      if (idxa >= 0 && idxb >= 0) {
        return idxa - idxb;
      }
      return a.id > b.id ? 1 : -1;
    });
    this.chartsService.setSerieNames(_data);
    return _data
      .map((item) => ({
        ...item,
        visible: !!(item.id !== "consumption" && item.data && sum(item.data))
      }))
      .filter((item) => item.id === "consumption" || item.visible);
  }

  public getChartData() {
    this.chartData$.next(null);

    this.utilService.selectedYear$
      .pipe(
        filter((year) => !!year),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.regionId) {
          return;
        }

        this.store.dispatch(new GetSpecificEmissionFactor(this.regionIdentifier, this.chartType));
        this.store.dispatch(new GetDatabaseChartData(this.regionIdentifier));
      });

    if (this.chartType === ChartTypes.FEDERAL) {
      this.regionService.selectedRegionUpLevels$.pipe(takeUntil(this.destroy$)).subscribe((regions) => {
        regions.sort((a, b) => a.level - b.level);
        this.regionIdentifier = {
          regionId: regions[0].region_id,
          regionType: regions[0].region_type
        };
        this.getChartData$.next(null);
      });
    } else {
      this.utilService.regionIdentifier$.pipe(takeUntil(this.destroy$)).subscribe((regionIdentifier) => {
        this.regionIdentifier = regionIdentifier;
        this.getChartData$.next(null);
      });
    }
  }

  private setChartConfig() {
    this.chartConfig$.next({
      chart: {
        spacingRight: 20,
        spacingTop: 20,
        unit: " [kWh]",
        type: "area",
        height: 310
      },
      xAxis: {
        categories: this.categories,
        tickmarkPlacement: "on"
      },
      yAxis: {
        title: {
          useHTML: true,
          text: "GWh"
        },
        labels: {
          overflow: false,
          formatter: this.yAxisLabelFormatter()
        }
      },
      tooltip: {
        shared: true,
        enabled: true,
        useHTML: true,
        borderWidth: 0,
        padding: 0,
        shadow: false,
        crosshairs: {
          width: 2
        },
        formatter: this.getTooltip()
      },
      legend: {
        useHTML: true,
        symbolRadius: 2,
        itemStyle: {
          fontWeight: "normal",
          fontSize: "0.9em",
          lineHeight: "normal"
        }
      },
      plotOptions: {
        area: {
          stacking: "normal",
          lineColor: "var(--ene-main-white)",
          lineWidth: 1,
          marker: {
            enabled: false
          }
        },
        line: {
          marker: {
            enabled: false
          }
        },
        series: {
          pointPlacement: "on",
          events: {
            legendItemClick: function () {
              if (!sum(this.yData)) {
                return false;
              }
            }
          }
        }
      }
    });
  }

  private getTooltip() {
    const that = this;

    return function () {
      const locale = that.translate.currentLang;

      return this.points.reduce((tooltip, point) => {
        let value, unit;
        if (point.y >= Math.pow(10, 6)) {
          value = point.y / 1000000;
          unit = "GWh";
        } else {
          value = point.y;
          unit = "kWh";
        }

        return (tooltip += `<p><b>${point.series.name}:</b> ${value.toLocaleString(locale)} ${unit}</p>`);
      }, "");
    };
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.store.dispatch(new ClearDatabaseChartData());
  }

  private fillKpiShare() {
    const idx = this.categories.indexOf(this.utilService.getSelectedYear());
    const productionRenewable = this.renewable
      .map((item) => this.series[item])
      .filter((item) => !!item && item.data)
      .reduce((acc, item) => (acc += item.data[idx]), 0);
    const consumption = this.series.consumption.data[idx];
    this.kpi.share = Math.min(1, productionRenewable / consumption);
    this.fillKpi();
  }

  private fillKpi() {
    this.eneKpisV2Service.runMapping(this.kpi, "co2_cockpit_extended");
  }

  private yAxisLabelFormatter() {
    const format = this.decimalPipe.transform;
    const locale = this.translate.currentLang;
    return function () {
      return format(this.value / 1000000, "1.0-2", locale);
    };
  }
}
