import { Component, Input } from "@angular/core";
import { assetUrl } from "../../../../../share/asset-url";

@Component({
  selector: "app-data-foundation",
  templateUrl: "./data-foundation.component.html",
  styleUrls: ["./data-foundation.component.scss", "../info-sectors.subsectors.scss"]
})
export class DataFoundationComponent {
  @Input() public sectors: any; // as soon as we haved a shared interface config-service <-> energy-city, change type from any;
  @Input() public sources: any; // as soon as we haved a shared interface config-service <-> energy-city, change type from any;
  @Input() public accountingMethods: any; // as soon as we haved a shared interface config-service <-> energy-city, change type from any;

  public getAssetUrl(url: string): string {
    return assetUrl(url);
  }
}
