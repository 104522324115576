import {
  Component,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Output,
  EventEmitter
} from "@angular/core";
import { EneModalService } from "../services/modal.service";

@Component({
  selector: "ene-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-modal"
  }
})
export class EneModalComponent implements OnInit, OnDestroy {
  @Input() public id: string;
  // if true there will be automatically a closebutton shown
  @Input() public closebutton: boolean = true;
  @Input() public sizing: "large" | "medium" | "small";
  @Input() public title: string;
  @Input() public watermark: string;
  @Input() public content: string;
  @Input() public disableScroll: boolean = false;
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  private element: any;

  constructor(private modalService: EneModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  public ngOnInit(): void {
    const modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error("modal must have an id");
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", function (e: any) {
      if (e.target.className === "ene-modal-container") {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it"s accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when directive is destroyed
  public ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  public open(): void {
    this.element.style.display = "block";
    setTimeout(() => {
      this.element.classList.add("ene-modal-in");
    }, 1);
    document.body.classList.add("ene-modal-open");
  }

  // close modal
  public close(): void {
    this.element.style.display = "none";
    this.element.classList.remove("ene-modal-in");
    document.body.classList.remove("ene-modal-open");
    this.onClose.emit(true);
  }
}
