import { EmissionDataService } from "../../../accounting-emission/data/emission-data.service";
import { AccountingMethod } from "./accounting-method.interface";
import { MainGhgSector } from "../sectors/main-ghg-sector.interface";
import { StationaryEnergySector } from "../sectors/stationary-energy-sector";
import { TransportationSector } from "../sectors/transportation-sector";

export class AccountingMethodSTET implements AccountingMethod {
  constructor(private emissionDataService: EmissionDataService) {}
  getSectors(): MainGhgSector[] {
    return [new StationaryEnergySector(this.emissionDataService), new TransportationSector(this.emissionDataService)];
  }
}
