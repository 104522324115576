import { Injectable } from "@angular/core";
import { Co2BalanceToken, ITokenService } from "./token.interface";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root"
})
export class TokenStandaloneService implements ITokenService {
  getCo2BalanceToken(): string {
    const authenticationObject = localStorage.getItem("authenticationObject");
    if (!authenticationObject) {
      throw Error("Authentication object is not available");
    }
    return JSON.parse(authenticationObject).accessToken;
  }
  getParsedCo2BalanceToken(): Co2BalanceToken {
    return jwt_decode(this.getCo2BalanceToken());
  }
}
