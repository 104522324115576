import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromRoot from "@energy-city/components";
import { TranslateService } from "@ngx-translate/core";
import { ICo2EmissionSectorNodeDto } from "../../../data/dto/co2-emission-dto.interface";
import { IChartTree } from "../../../../../../../../../libs/components/src/lib/app/modules/co2-cockpit/charts/greenhouse-gases/sectors/shared/chart-co2-sectors.helper.class";

import "@enersis/gp-components/gp-chart-emission-sunburst";
import { Highcharts } from "@enersis/gp-components/gp-chart";
import { onlyTonsCo2Unit } from "libs/components/src/lib/app/modules/co2-cockpit/charts/tooltip-formatter";

@Component({
  selector: "co2-chart-emissions-sunburst-base",
  templateUrl: "./chart-emissions-sunburst-base.component.html",
  styleUrls: ["./chart-emissions-sunburst-base.component.scss"]
})
export class ChartEmissionsComponentBase implements OnInit {
  @Input() public legendPosition: "bottom" | "bottom-centered" | "right" | undefined;

  /** A function that will be called to get the co2 emission observable */
  @Input() chartDataFunction!: () => Observable<ICo2EmissionSectorNodeDto>;
  /** An optional function that will be called to get the nh3 emissions observable */
  @Input() nh3DataFunction?: () => Observable<IChartTree | undefined>;
  public chartData$!: Observable<ICo2EmissionSectorNodeDto>;
  public nh3Data$!: Observable<IChartTree | undefined>;
  public tooltipUnitConfig = onlyTonsCo2Unit;

  @Output() public gpChartRef = new EventEmitter<Highcharts.Chart>();

  constructor(public store: Store<fromRoot.IApplicationState>, public translate: TranslateService) {}

  public ngOnInit(): void {
    this.chartData$ = this.chartDataFunction();
    this.nh3Data$ = this.nh3DataFunction?.() ?? of(undefined);
  }

  public setChartRef(loadEvent: CustomEvent<Highcharts.Chart>) {
    this.gpChartRef.emit(loadEvent.detail);
  }
}
