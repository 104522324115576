import { Injectable } from "@angular/core";
import { environment } from "../../../../../../environments/environment";
import { AccountingMethodService } from "../../../../../services/accounting-method.service";
import { ChartsService } from "../../../../../services/charts.service";
import { UtilService, IRegionIdentifier } from "../../../../../services/util.service";
import { HttpParams, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FINAL_ENERGY_SECTORS_YEAR_LIMIT, DEFAULT_FINAL_ENERGY_SECTOR_COLOR } from "../final-energy.model";
import { IDataItems, IChartData, ISeriesData } from "../../models/final-energy-data.interface";
import { omit } from "lodash";

@Injectable()
export class EnergySectorsService {
  private legendOrder = { commerce: 1, communityFacilities: 3, industry: 2, privateHousehold: 0 };

  constructor(
    private utilService: UtilService,
    private accountingMethodService: AccountingMethodService,
    private http: HttpClient,
    private chartService: ChartsService
  ) {}

  public getEnergyValues({ regionId, regionType }: IRegionIdentifier): Observable<{ [key: string]: IDataItems }> {
    const url = `${environment.regionPropertyApi}/energy/stationary-energy`;
    const params = new HttpParams({
      fromObject: {
        regionId,
        regionType,
        depth: "1",
        weatherCorrection: String(this.accountingMethodService.weatherCorrection$.value),
        energyType: "final"
      }
    });

    return this.http.get<any>(url, { params });
  }

  public getChartData(data: IChartData): [{ categories: Array<number>; series: Array<ISeriesData> }, number] {
    const dqiSeries = this.getDqiSeries(data);
    return [
      {
        categories: dqiSeries["categories"],
        series: dqiSeries["series"]
      },
      dqiSeries["dqi"]
    ];
  }

  private fillColors(series) {
    const seriesKeys = Object.keys(series);
    const step = 1 / seriesKeys.length;
    const [red, green, blue] = this.chartService.hexToRgb(DEFAULT_FINAL_ENERGY_SECTOR_COLOR);
    seriesKeys
      .map((seria) => {
        const sumData = series[seria].data.reduce((agg, item) => agg + item, 0);
        series[seria].sumData = sumData;
        return { seria, sumData };
      })
      .sort((a, b) => {
        return b.sumData - a.sumData;
      })
      .forEach((item, idx) => {
        const alpha = 1 - idx * step;
        series[item.seria].color = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
      });
  }

  public getDqiSeries(data): { series: Array<ISeriesData>; dqi: number; categories: Array<number> } {
    let toYear = this.utilService.getSelectedYear();
    if (!toYear) {
      toYear = data
        .map((item) => item.year)
        .sort()
        .reverse()[0];
    }
    const fromYear = toYear - FINAL_ENERGY_SECTORS_YEAR_LIMIT + 1;
    const filteredData = data.filter((item) => item.year >= fromYear && item.year <= toYear);
    const categories = [];
    for (let i = fromYear; i <= toYear; i++) {
      categories.push(i);
    }
    const itemsKeys = [];
    filteredData.forEach((item) => {
      Object.keys(omit(item, "year")).forEach((key) => {
        if (itemsKeys.indexOf(key) === -1) {
          itemsKeys.push(key);
        }
      });
    });
    const series: Array<ISeriesData> = itemsKeys.reduce((agg, itemKey) => {
      const legendIndex = this.legendOrder[itemKey];
      return {
        ...agg,
        [itemKey]: {
          translationKey: `RESOURCES.${itemKey}`.toUpperCase(),
          data: Array(categories.length).fill(0),
          ...(legendIndex !== undefined && { legendIndex })
        }
      };
    }, {});
    let sumValues = 0;
    let weighnedQuality = 0;
    filteredData.forEach((item) => {
      const index = categories.indexOf(item.year);
      itemsKeys.forEach((itemKey) => {
        if (item[itemKey]) {
          series[itemKey].data[index] = item[itemKey].value;
          sumValues += item[itemKey].value;
          weighnedQuality += item[itemKey].value * item[itemKey].quality;
        }
      });
    });

    this.fillColors(series);
    const dqi = weighnedQuality / sumValues;
    return { series: Object.values(series).sort((a, b) => a.sumData - b.sumData), dqi, categories };
  }
}
