/* tslint:disable:member-access */

/**
 * all "old" endpoints are commented out.
 * right now we only fetch data from the co2-dummy-data service
 */

import { Action } from "@ngrx/store";
import { ICo2 } from ".";
export enum Co2CockpitActionType {
  GET_KPI_EMISSION = "[CO2] GET_KPI_EMISSION",
  GET_KPI_EMISSION_SUCCESS = "[CO2] GET_KPI_EMISSION_SUCCESS",
  // GET_REDUCTION = "[CO2] GET_REDUCTION",
  // GET_REDUCTION_SUCCESS = "[CO2] GET_REDUCTION_SUCCESS",
  // GET_REDUCTION_GOAL = "[CO2] GET_REDUCTION_GOAL",
  // GET_REDUCTION_GOAL_SUCCESS = "[CO2] GET_REDUCTION_GOAL_SUCCESS",
  // GET_RANK = "[CO2] GET_RANK",
  // GET_RANK_SUCCESS = "[CO2] GET_RANK_SUCCESS",
  // GET_PER_AREA = "[CO2] GET_PER_AREA",
  // GET_PER_AREA_SUCCESS = "[CO2] GET_PER_AREA_SUCCESS",
  ERROR = "[CO2] ERROR"
}
export class GetCo2CockpitKpiEmission implements Action {
  public readonly type = Co2CockpitActionType.GET_KPI_EMISSION;
  constructor() {}
}
export class GetCo2CockpitKpiEmissionSuccess implements Action {
  public readonly type = Co2CockpitActionType.GET_KPI_EMISSION_SUCCESS;
  constructor(public payload: any) {}
}
// export class GetCo2CockpitReduction implements Action {
//     type = Co2CockpitActionType.GET_REDUCTION;
//     constructor(public payload: any) { }
// }
// export class GetCo2CockpitReductionSuccess implements Action {
//     type = Co2CockpitActionType.GET_REDUCTION_SUCCESS;
//     constructor(public payload: any) { }
// }
// export class GetCo2CockpitReductionGoal implements Action {
//     type = Co2CockpitActionType.GET_REDUCTION_GOAL;
//     constructor(public payload: any) { }
// }
// export class GetCo2CockpitReductionGoalSuccess implements Action {
//     type = Co2CockpitActionType.GET_REDUCTION_GOAL_SUCCESS;
//     constructor(public payload: any) { }
// }
// export class GetCo2CockpitRank implements Action {
//     type = Co2CockpitActionType.GET_RANK;
//     constructor(public payload: any) { }
// }
// export class GetCo2CockpitRankSuccess implements Action {
//     type = Co2CockpitActionType.GET_RANK_SUCCESS;
//     constructor(public payload: any) { }
// }
// export class GetCo2CockpitPerArea implements Action {
//     type = Co2CockpitActionType.GET_PER_AREA;
//     constructor(public payload: any) { }
// }
// export class GetCo2CockpitPerAreaSuccess implements Action {
//     type = Co2CockpitActionType.GET_PER_AREA_SUCCESS;
//     constructor(public payload: any) { }
// }
export class Co2CockpitError implements Action {
  type = Co2CockpitActionType.ERROR;
  constructor(public payload: any) {}
}

export type GetCo2CockpitReductionActions =
  | GetCo2CockpitKpiEmission
  | GetCo2CockpitKpiEmissionSuccess
  // GetCo2CockpitReduction |
  // GetCo2CockpitReductionSuccess |
  // GetCo2CockpitReductionGoal |
  // GetCo2CockpitReductionGoalSuccess |
  // GetCo2CockpitRank |
  // GetCo2CockpitRankSuccess |
  // GetCo2CockpitPerArea |
  // GetCo2CockpitPerAreaSuccess |
  | Co2CockpitError;
