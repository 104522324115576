import { Component, OnDestroy, OnInit } from "@angular/core";
import { EneKpisV2Service } from "@energy-city/ui/kpis-v2";
import { isEqual } from "lodash";
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest } from "rxjs";
import { debounceTime, filter, first, map, shareReplay, takeUntil } from "rxjs/operators";
import { AccountingMethod, MixType } from "../../../../configs/accounting-method";
import { RegionTypes } from "../../../../core/enum/region-types.enum";
import { IParameterModel } from "../../../../models/accounting-method";
import { AccountingMethodService } from "../../../../services/accounting-method.service";
import { ModulesService } from "../../../../services/modules.service";
import { UtilService } from "../../../../services/util.service";
import { FactorManagementService } from "../factor-management/factor-management.service";
import { notification } from "@energy-city/ui/notification";
import { TranslateService } from "@ngx-translate/core";

interface IAccountingMethod {
  type: AccountingMethod;
  disabled?: boolean;
}

interface IAccountingConfig {
  availableMethods: Array<IAccountingMethod>;
  isWeatherCorrectionDisabled: boolean;
  isLocalMixDisabled: boolean;
}

@Component({
  selector: "app-accounting-methods",
  templateUrl: "./accounting-methods.component.html",
  styleUrls: ["./accounting-methods.component.scss"]
})
export class AccountingMethodsComponent implements OnInit, OnDestroy {
  public accountingConfig$: BehaviorSubject<IAccountingConfig> = new BehaviorSubject<IAccountingConfig>({
    availableMethods: [],
    isWeatherCorrectionDisabled: false,
    isLocalMixDisabled: false
  });
  public currentSelection$: Observable<IParameterModel>;
  public selectedMethod$: Subject<AccountingMethod> = new Subject<AccountingMethod>();
  public weatherCorrection$ = new BehaviorSubject<boolean>(false);
  public useUserFactors$ = new BehaviorSubject<boolean>(false);
  public mixType$ = new BehaviorSubject<MixType>(MixType.FEDERAL);
  public isWeatherCorrectionDisabled$: Observable<boolean>;
  public isUserFactorsDisabled$: Observable<boolean>;
  public isLocalMixDisabled$: Observable<boolean>;
  public mixType = MixType; // enum used in template
  public isRegionTypeUnion$: Observable<boolean>;

  public factors = [{ type: "base" }, { type: "custom" }];
  public selectedFactor$ = this.accountingMethodService.useUserFactors$.pipe(map((e) => (e ? "custom" : "base")));

  private savedModel$ = new ReplaySubject<IParameterModel>(1);

  constructor(
    private accountingMethodService: AccountingMethodService,
    private translate: TranslateService,
    private modulesService: ModulesService,
    private ene: EneKpisV2Service,
    private utilService: UtilService,
    public readonly factorManagementService: FactorManagementService
  ) {}

  private destroy$: Subject<undefined> = new Subject();

  public ngOnInit(): void {
    const isUserFactorsAllowed = (accountingMethod: AccountingMethod): boolean =>
      accountingMethod !== AccountingMethod.BISKO;

    const accounting = this.modulesService.getClientConfig().accountingMethods;

    this.currentSelection$ = combineLatest([
      this.selectedMethod$,
      this.weatherCorrection$,
      this.mixType$,
      this.accountingConfig$,
      this.useUserFactors$
    ]).pipe(
      debounceTime(100),
      map(([selectedMethod, weatherCorrection, mixType, config, useUserFactors]) => {
        const selection: IParameterModel = {
          accountingMethod: selectedMethod,
          mixType: selectedMethod === AccountingMethod.BISKO || config.isLocalMixDisabled ? MixType.FEDERAL : mixType,
          useUserFactors: isUserFactorsAllowed(selectedMethod) ? useUserFactors : false, // cannot use user factors with BISKO
          weatherCorrection:
            selectedMethod === AccountingMethod.BISKO || config.isWeatherCorrectionDisabled ? false : weatherCorrection
        };
        return selection;
      }),
      shareReplay(1)
    );

    this.isWeatherCorrectionDisabled$ = combineLatest([this.accountingConfig$, this.currentSelection$]).pipe(
      map(
        ([config, currentSelection]) =>
          config.isWeatherCorrectionDisabled || currentSelection.accountingMethod === AccountingMethod.BISKO
      )
    );
    this.isLocalMixDisabled$ = combineLatest([this.accountingConfig$, this.currentSelection$]).pipe(
      map(
        ([config, currentSelection]) =>
          config.isLocalMixDisabled || currentSelection.accountingMethod === AccountingMethod.BISKO
      )
    );
    this.isUserFactorsDisabled$ = this.currentSelection$.pipe(
      map((currentSelection) => !isUserFactorsAllowed(currentSelection.accountingMethod))
    );

    this.accountingMethodService.getAccountingMethods().subscribe((savedModel) => {
      this.savedModel$.next(savedModel);
    });

    combineLatest([this.savedModel$, this.accountingConfig$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([currentSelection, config]) => {
        const usableMethods = config.availableMethods.filter((method) => !method.disabled).map((method) => method.type);
        const selectedMethod = usableMethods.includes(currentSelection.accountingMethod)
          ? currentSelection.accountingMethod
          : usableMethods[0];
        this.selectedMethod$.next(selectedMethod);
        this.weatherCorrection$.next(currentSelection.weatherCorrection);
        this.useUserFactors$.next(currentSelection.useUserFactors);
        this.mixType$.next(currentSelection.mixType);

        if (selectedMethod !== currentSelection.accountingMethod) {
          // the saved method (in balancingApi) had a value that wasn't
          // supported according to the clientConfig. We now automatically fall
          // back to the first supported accounting method and save it back to
          // the balancingApi:
          this.currentSelection$.pipe(first()).subscribe((model) => this.saveModel(model));
        }
        currentSelection.weatherCorrection === true
          ? this.ene.activateIcon("ec_witterungskorrektur")
          : this.ene.deactivateIcon("ec_witterungskorrektur");
      });

    this.accountingConfig$.next({
      availableMethods: accounting.methods,
      isWeatherCorrectionDisabled: accounting.isWeatherCorrectionDisabled,
      isLocalMixDisabled: accounting.isLocalMixDisabled
    });

    this.isRegionTypeUnion$ = this.utilService.regionIdentifier$.pipe(
      map((regionIdentifier) => regionIdentifier.regionType === RegionTypes.UNION)
    );

    this.handleModelChanges();
  }

  public updateAccountingMethod(accountingMethod: IAccountingMethod) {
    if (accountingMethod.disabled) {
      return;
    }
    this.selectedMethod$.next(accountingMethod.type);
  }

  public toggleWeatherCorrection() {
    this.weatherCorrection$.next(!this.weatherCorrection$.value);
  }

  public updateMixType(mixType: MixType) {
    this.mixType$.next(mixType);
  }

  public updateFactor(factorSetting: "base" | "custom") {
    const useUserFactors = Boolean(factorSetting === "custom");
    // why the button is clicked twice?
    if (this.useUserFactors$.getValue() === useUserFactors) {
      return;
    }
    this.useUserFactors$.next(useUserFactors);
  }

  public saveModel(model: IParameterModel): void {
    this.accountingMethodService.saveAccountingMethod(model).subscribe(
      (currentSelection) => {
        notification.success(this.translate.instant("DATA_PANEL.NOTIFICATIONS.PARAMS_CHANGE_SUCCESS"));
        this.savedModel$.next(currentSelection);
        this.accountingMethodService.updateParameters(currentSelection);
      },
      () => {
        // saving failed, the balancing endpoint returned an error
        notification.warning(this.translate.instant("DATA_PANEL.NOTIFICATIONS.PARAMS_CHANGE_FAILED"));
      }
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private handleModelChanges(): void {
    combineLatest([this.currentSelection$, this.savedModel$])
      .pipe(
        filter(([currentSelection, savedModel]) => !isEqual(currentSelection, savedModel)),
        takeUntil(this.destroy$)
      )
      .subscribe(([currentSelection]) => this.saveModel(currentSelection));
  }
}
