import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneIconModule } from "@energy-city/ui/icon";
import { SunburstLegendComponent } from "./legend.component";

@NgModule({
  declarations: [SunburstLegendComponent],
  imports: [CommonModule, TranslateModule, EneIconModule],
  exports: [SunburstLegendComponent]
})
export class SunburstLegendModule {}
