import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as fromRoot from "../state/index";
import { AppActionType, UpdateTranslations } from "../state/app/app-actions";
import { AuthenticationService } from "../modules/authentication/services/exported/authentication.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { IAppState } from "../state/app";
import { filter, map, pluck, switchMap } from "rxjs/operators";
import { getTimelineState, getTimelineYear, ITimelineState } from "../state/timeline";
import { mergeWith } from "lodash";

import { SafeResourceUrl } from "@angular/platform-browser";
import { CoaDataService } from "../../../../../shared/src/lib/common/coa/coa-data.service";
import { RegionType as RegionTypeNew } from "../../../../../shared/src/lib/common/model/region-type.enum";

export const hasValue = (value) => value !== null && value !== undefined;
export const hasChanged = (currentValue, newValue) => currentValue !== newValue;

export type RegionType = "basic" | "union";
export interface IRegionIdentifier {
  regionId: string;
  regionType: RegionType;
}

@Injectable({
  providedIn: "root"
})
export class UtilService {
  private locale: string;

  private selected_scenario: number;
  private selected_ags: number;
  private _mapConfig: any = [];

  private _selectedYearSubject: BehaviorSubject<number> = new BehaviorSubject(undefined);
  private _scenarioIdBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject(undefined);
  private _regionIdentifierSubject: BehaviorSubject<IRegionIdentifier> = new BehaviorSubject(undefined);
  private _clientConfigSubject$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  private userLocation = new BehaviorSubject([]);

  public readonly selectedYear$: Observable<number> = this._selectedYearSubject.asObservable().pipe(filter(hasValue));
  public readonly scenarioId$: Observable<string> = this._scenarioIdBehaviorSubject
    .asObservable()
    .pipe(filter(hasValue));
  public readonly regionIdentifier$: Observable<IRegionIdentifier> = this._regionIdentifierSubject
    .asObservable()
    .pipe(filter(hasValue));
  public readonly clientConfig$: Observable<any> = this._clientConfigSubject$.asObservable().pipe(filter(hasValue));
  public readonly regionId$: Observable<string> = this.regionIdentifier$.pipe(pluck("regionId"));

  public mapConfig$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public userLocation$: Observable<Array<number>>;

  public displayDataPrivacyMessage: boolean = false;
  public compactionPotential: boolean;
  public anchors: any = {};

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private coaDataService: CoaDataService
  ) {
    this.store.select(fromRoot.GetAppState).subscribe((res: IAppState) => {
      switch (res && res.type) {
        case AppActionType.UPDATE_TRANSLATIONS_SUCCESS:
          this.locale = res.payload.language;
          break;
        case AppActionType.GET_CLIENT_CONFIG_SUCCESS:
          const scenarioId = res && res.payload && res.payload.environments && res.payload.environments.scenarioId;
          this._scenarioIdBehaviorSubject.next(scenarioId);
          break;
        default:
          break;
      }
    });

    this.store.select(getTimelineState).subscribe((state: ITimelineState) => {
      this.setSelectedYear(getTimelineYear(state));
    });

    this.userLocation$ = this.userLocation.asObservable();
  }
  public registerAnchor(first: any, second: any) {
    this.anchors[first] = second;
  }

  public get timelineInitialYear(): number {
    try {
      return this.authService.tokenParsed.timelineYear
        ? this.authService.tokenParsed.timelineYear
        : environment.selected_year;
    } catch (e) {
      throw e;
    }
  }

  public get timelineLastYear(): number {
    return Number(this.timelineRange[1]);
  }

  public get timelineFirstYear(): number {
    return Number(this.timelineRange[0]);
  }

  public get timelineRange(): number[] {
    try {
      return this.authService.tokenParsed.timelineRange ? this.authService.tokenParsed.timelineRange : [1990, 2050];
    } catch (e) {
      throw e;
    }
  }

  public get tourTemplate() {
    try {
      return this.authService.tokenParsed.guidedTour
        ? this.authService.tokenParsed.guidedTour
        : "default_tour_2019_2039";
    } catch (e) {
      throw e;
    }
  }

  public get idToken(): string {
    return this.authService.authenticationObject.idToken;
  }

  public get tokenParsed(): string {
    return this.authService.tokenParsed;
  }

  public getSelectedYear(): number {
    return this._selectedYearSubject.getValue();
  }

  public setSelectedYear(value: number) {
    if (hasValue(value) && hasChanged(this.getSelectedYear(), value)) {
      this._selectedYearSubject.next(value);
    }
  }

  public set selectedAgs(value: number) {
    this.selected_ags = value;
  }

  public get selectedAgs(): number {
    try {
      return this.selected_ags;
    } catch (e) {
      return;
    }
  }

  public setRegionId(regionId: string, regionType: RegionType) {
    this._regionIdentifierSubject.next({
      regionId,
      regionType
    });
  }

  public getRegionId(): string {
    return this._regionIdentifierSubject.getValue()?.regionId;
  }

  public getRegionIdentifier(): IRegionIdentifier {
    return this._regionIdentifierSubject.getValue();
  }

  public set scenarioId(value: string) {
    this._scenarioIdBehaviorSubject.next(value);
  }

  /**
   * Returns the current selected scenarioId.
   * It will return undefined until the scenarioId has been retrieved from the config service.
   * That's why it's recommended to use the observable scenarioId$ instead.
   * @deprecated use observable scenarioId$ instead
   */
  public get scenarioId(): string {
    return this._scenarioIdBehaviorSubject.getValue();
  }

  /**
   * @deprecated use setter scenarioId (UUID string) instead
   */
  public set selectedScenario(value: number) {
    this.selected_scenario = value;
  }

  /**
   * @deprecated use observable scenarioId$ (UUID string) instead
   */
  public get selectedScenario(): number {
    return this.selected_scenario;
  }

  public set dataPrivacy(value: boolean) {
    this.displayDataPrivacyMessage = value;
  }

  public get dataPrivacy(): boolean {
    try {
      return this.displayDataPrivacyMessage;
    } catch (e) {
      return;
    }
  }

  public get mapConfig() {
    return this._mapConfig;
  }

  public set mapConfig(config: any) {
    this._mapConfig = config;
    this.mapConfig$.next(config);
  }

  public get clientConfig() {
    return this._clientConfigSubject$.value;
  }

  public set clientConfig(config: any) {
    this._clientConfigSubject$.next(config);
  }

  /**
   * sorts an array of objects with the property specified
   * to use in a array.sort() function.
   * https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value-in-javascript
   * @param property string of a property within array of objects
   */
  public dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  /**
   * scans all datalayers for a certain object in a category
   * @param name name of the datalayerclass
   * @param value as stated in datalayerClass-item
   */
  public getDataLayerClass(name: string, value: number) {
    if (!environment.dataLayerClasses[name]) {
      return null;
    }
    const kpis = environment.dataLayerClasses[name].filter((obj) => {
      return obj.value === value;
    });
    return kpis.length === 1 ? kpis[0] : environment.dataLayerClasses[name][0];
  }
  /**
   *
   * @param state
   * @param name
   * @param key
   */
  public getIndicatorObject(state: IndicatorStates, addition?: any) {
    const response: any = { label: "", style: "", state: state };
    switch (state) {
      // tslint:disable-next-line:no-use-before-declare
      case IndicatorStates.ERROR:
        if (addition === 401) {
          response.label = "TDI.ERRORS.UNAUTHORIZED_LONG";
          response.style = "error";
        } else {
          response.label = "TDI.ERRORS.SAVE_LONG";
          response.style = "error";
        }
        break;
      // tslint:disable-next-line:no-use-before-declare
      case IndicatorStates.LOADED:
        response.label = "TDI.LOADED.LONG";
        response.style = "info";
        break;
      // tslint:disable-next-line:no-use-before-declare
      case IndicatorStates.PENDING:
        response.label = "";
        response.style = "";
        break;
      // tslint:disable-next-line:no-use-before-declare
      case IndicatorStates.SAVED:
        response.label = "TDI.SAVED.LONG";
        response.style = "success";
        response.itemname = addition;
        break;
      // tslint:disable-next-line:no-use-before-declare
      case IndicatorStates.PROGRESS:
        response.label = "TDI.PROGRESS.LONG";
        switch (true) {
          case addition < 50:
            response.style = "error";
            break;
          case addition > 50 && addition < 100:
            // response.style = "info";
            response.style = "error";
            break;
          case addition === 100:
            response.style = "success";
            break;
        }
        response.itemname = addition;
        break;
    }
    return response;
  }

  public getLocale(): string {
    return this.locale;
  }
  public setLocale(locale: string) {
    this.store.dispatch(
      new UpdateTranslations({
        language: locale
      })
    );
  }

  public flatParser(data: any) {
    const result = {};
    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (let i = 0, l = cur.length; i < l; i++) {
          recurse(cur[i], prop + "[" + i + "]");
          if (l === 0) {
            result[prop] = [];
          }
        }
      } else {
        let isEmpty = true;
        for (const p of Object.keys(cur)) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + "." + p : p);
        }
        if (isEmpty && prop) {
          result[prop] = {};
        }
      }
    }
    recurse(data, "");

    return result;
  }
  /**
   * takes an array of translations-strings, translate it and returns a formatter-compatible object with to/from for nouislider.
   * Outputs the string in the array with the index matching to the selected value in the slider
   * @param strings array of translate-strings -> ["TDI.DQI.0", "ABC.DEF"]
   * @returns object like { to: fn(), from:() }
   */
  public stringFormatter(strings: string[]) {
    const translatedStrings: string[] = [];
    // forEach instead of normal translateService.get to also keep empty array strings
    strings.forEach((element) => {
      if (element !== "") {
        this.translateService.get(element).subscribe((res) => {
          translatedStrings.push(res);
        });
      } else {
        translatedStrings.push("");
      }
    });

    return {
      to(value: number): string {
        value = Math.round(value);
        if (value < 0) {
          value = 0;
        }
        if (value >= translatedStrings.length) {
          value = translatedStrings.length - 1;
        }
        return translatedStrings[value];
      },
      from(value: string): number {
        const result = translatedStrings.indexOf(value);
        return result === -1 ? 0 : result;
      }
    };
  }

  public setUserLocation(value: Array<number>): void {
    this.userLocation.next(value);
  }

  public getCoatOfArmsUrl(coaLoadError$?: Subject<string>): Observable<SafeResourceUrl | string | null> {
    return this.regionIdentifier$.pipe(
      switchMap((region) => {
        return this.coaDataService.getCoaImage(
          {
            regionId: region.regionId,
            regionType: region.regionType === "union" ? RegionTypeNew.UNION : RegionTypeNew.BASIC
          },
          coaLoadError$
        );
      })
    );
  }

  public getCoatOfArmsBackgroundUrl(): Observable<string> {
    // the background url of the coa is used in the header of the standalone application
    // background url with base64 encoded images was not working right away
    // all coa for the standalone applications are available in the config service
    // so we take them from there
    return this.regionIdentifier$.pipe(
      map((region) => {
        return `url(${this.coaDataService.getCoaUrlConfigService({
          regionId: region.regionId,
          regionType: region.regionType === "union" ? RegionTypeNew.UNION : RegionTypeNew.BASIC
        })}`;
      })
    );
  }

  public mergeData(data: object): object {
    const keys = Object.keys(data).filter((key) => key !== "year");

    return keys.reduce((acc, key) => {
      const customizer = (curr = {}, next) => {
        return { ...curr, [key]: next.value };
      };
      const merged = mergeWith(acc, data[key], customizer);

      return { ...acc, ...merged };
    }, {});
  }
}

export enum IndicatorStates {
  ERROR = "error",
  LOADED = "loaded",
  PENDING = "pending",
  SAVED = "saved",
  PROGRESS = "progress",
  NONE = "none"
}
