import { ColorRange } from "./color-range";
import { GpAllRules, IGpAnyLayerInput } from "@enersis/gp-components/gp-map2-viewer";
import { RegionType } from "../../common/model/region-type.enum";
import { IRegionLayerConfig } from "./region-layer-config.interface";

const SELECTED_STYLE: GpAllRules = {
  type: "selected",
  output: "#FFD500"
};

export class RegionLayerConfig implements IRegionLayerConfig {
  constructor(
    readonly level: string,
    readonly minZoom: number,
    readonly maxZoom: number,
    private readonly minCo2EmissionsTons: number,
    private readonly maxCo2EmissionsTons: number,
    private readonly minCo2EmissionsTonsPerCapita: number,
    private readonly maxCo2EmissionsTonsPerCapita: number,
    private readonly colorRange: ColorRange
  ) {}

  getLayerId(): string {
    return `emission_region_level_${this.level}`;
  }

  private getBorderLayerId(): string {
    return `border_region_level_${this.level}`;
  }

  getRegionType(): RegionType {
    return RegionType.BASIC;
  }

  getLayerSortKey(): number {
    //union returns 55 since it is displayed betweeen 50 and 60
    return -(this.level === "union" ? 55 : Number(this.level));
  }

  getLayerConfig(): IGpAnyLayerInput {
    return {
      id: this.getLayerId(),
      type: "fill",
      source: `region/${this.level}`,
      label: `REGIONS.LEVELS.${this.level}`,
      activeThematicId: "emission_benchmark",
      minzoom: this.minZoom,
      maxzoom: this.maxZoom,
      selectable: "single",
      defaultStyle: {
        "fill-sort-key": this.getLayerSortKey(),
        "fill-opacity": {
          stops: [
            [this.minZoom, 0.5],
            [this.minZoom + 0.5, 0.9],
            [this.maxZoom - 0.5, 0.9],
            [this.maxZoom, 0.5]
          ]
        },
        "fill-color": "#E1EEDC",
        "fill-outline-color": "hsla(0, 0%, 13%, 0.3)",
        "fill-antialias": true
      },
      thematics: [
        {
          id: "emission_benchmark",
          label: "MAP2.LAYERS.REGIONS.THEMATICS.CO2_EMISSION.LABEL",
          styleRules: {
            color: [
              SELECTED_STYLE,
              {
                type: "min",
                input: ["var", "_aggregated_co2_emissions_sector"],
                legend2: {
                  type: "range",
                  rangeType: "gradient"
                },
                steps: this.colorRange.getColorMap(
                  this.minCo2EmissionsTons,
                  this.maxCo2EmissionsTons,
                  10,
                  {
                    startLabel: "MAP2.LAYERS.REGIONS.THEMATICS.CO2_EMISSION.LOW",
                    endLabel: "MAP2.LAYERS.REGIONS.THEMATICS.CO2_EMISSION.HIGH",
                  }
                )
              }
            ]
          }
        },
        {
          id: "emissions_per_capita",
          label: "MAP2.LAYERS.REGIONS.THEMATICS.CO2_EMISSION_PER_HEAD",
          styleRules: {
            color: [
              SELECTED_STYLE,
              {
                type: "min",
                input: [
                  "case",
                  [">", ["number", ["get", "population"], -1], 0],
                  ["/", ["var", "_aggregated_co2_emissions_sector"], ["number", ["get", "population"], -1]],
                  0
                ],
                steps: this.colorRange.getColorMap(
                  this.minCo2EmissionsTonsPerCapita,
                  this.maxCo2EmissionsTonsPerCapita,
                  6,
                  {
                    showEachLabel: true,
                    suffix: "t"
                  }
                )
              }
            ]
          }
        }
      ]
    };
  }

  getBorderLayerConfig(): IGpAnyLayerInput {
    return {
      id: this.getBorderLayerId(),
      type: "fill",
      source: `region/${this.level}`,
      minzoom: this.minZoom,
      maxzoom: this.maxZoom,
      defaultStyle: {
        "fill-opacity": {
          stops: [
            [this.minZoom, 0.5],
            [this.minZoom + 0.5, 0.8],
            [this.maxZoom - 0.5, 0.8],
            [this.maxZoom, 0.5]
          ]
        },
        "fill-color": "transparent",
        "fill-outline-color": "hsla(0, 0%, 13%, 0.3)",
        "fill-antialias": true
      }
    };
  }
}
