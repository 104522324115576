import { InjectionToken } from "@angular/core";
import { RegionType } from "../model/region-type.enum";

export interface Co2BalanceToken {
  tenant: string;
  mainRegion?: {
    id?: string;
    regionType?: RegionType;
    lat: number;
    lon: number;
    level?: number;
    zoomLevel?: number;
  };
}

export interface ITokenService {
  getCo2BalanceToken(): string;
  getParsedCo2BalanceToken(): Co2BalanceToken;
}

export const TOKEN_SERVICE_TOKEN = new InjectionToken<ITokenService>("TOKEN_SERVICE_TOKEN");
