import { DecimalPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Options, SeriesLineOptions } from "highcharts";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { Accounting } from "../../../accounting-common/model/accounting";
import { EmissionTimeSeries } from "../../../accounting-emission/model/emission-time-series";
import { SimulationDataService } from "../../data/simulation-data.service";
import { Savings } from "../../model/savings/savings";
import { Scenario } from "../../model/scenario/scenario";
import { Simulation } from "../../model/simulation";
import { SimulationHighCharts } from "../../model/simulation-highcharts";

@Component({
  selector: "co2-simulation-chart",
  templateUrl: "./simulation-chart.component.html",
  styleUrls: ["./simulation-chart.component.scss"]
})
export class SimulationChartComponent implements OnInit {
  @Input() public set isEnabled(isEnabled: boolean) {
    this._isEnabled = isEnabled;
    if (isEnabled) {
      // every time the simulation is enabled, the savings
      // from measures need to be queried from the BE
      // otherwise we might miss newly added measures
      this.measureSetId$.next(this.measureSetId$.getValue());
    } else {
      this.resetData();
    }
  }
  @Input() public set accounting(accounting: Accounting) {
    this.resetData();
    this.accounting$.next(accounting);
  }

  @Input() public set measureSetId(measureSetId: string) {
    this.resetData();
    this.measureSetId$.next(measureSetId);
  }

  @Input() public set scenario(scenario: Scenario) {
    this.resetData();
    this.scenario$.next(scenario);
  }

  public chartSettings: Options | undefined;
  public chartData: SeriesLineOptions[] | undefined;

  private accounting$: BehaviorSubject<Accounting | null> = new BehaviorSubject<Accounting | null>(null);
  private scenario$: BehaviorSubject<Scenario | null> = new BehaviorSubject<Scenario | null>(null);
  private measureSetId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private destroy$ = new Subject();
  private _isEnabled: boolean = false;
  private highChartsSimulation: SimulationHighCharts | null = null;

  constructor(
    private simulationService: SimulationDataService,
    private decimalPipe: DecimalPipe,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.listenDataUpdates();
    this.listenLanguageChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private listenDataUpdates(): void {
    const history$ = this.accounting$.pipe(
      filter((accounting): accounting is Accounting => !!accounting),
      switchMap((accounting) => accounting.getEmissionTimeSeries())
    );
    const savings$ = this.measureSetId$.pipe(
      filter((measureSetId): measureSetId is string => !!measureSetId),
      switchMap((measureSetId) => this.simulationService.requestSavingsData(measureSetId))
    );

    combineLatest([history$, this.scenario$, savings$])
      .pipe(
        map(([history, scenario, savings]) => {
          return { history, scenario, savings };
        }),
        filter(
          (data): data is { history: EmissionTimeSeries; scenario: Scenario; savings: Savings } =>
            data.history != null && data.scenario != null && data.savings != null && this._isEnabled
        )
      )
      .subscribe((data) => {
        const simulation = new Simulation(data.history, data.savings, data.scenario);
        this.highChartsSimulation = new SimulationHighCharts(simulation, this.decimalPipe, this.translate);
        this.setData();
      });
  }

  private listenLanguageChanges(): void {
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setData();
    });
  }

  private resetData(): void {
    this.resetChartData();
    this.highChartsSimulation = null;
  }

  private resetChartData(): void {
    this.chartSettings = undefined;
    this.chartData = undefined;
  }

  private setData(): void {
    if (this._isEnabled) {
      this.chartSettings = this.highChartsSimulation?.getSettings();
      this.chartData = this.highChartsSimulation?.getChartSeries();
    }
  }
}
