<div class="contact-form">
  <ul class="contact-form__contact-radio">
    <li
      *ngFor="let component of components"
      class="contact-form__contact-radio_item"
      [class.active]="(currentComponent$ | async) === component.type"
      (click)="checkBox(component.type)"
    >
      <label class="ene-radio-button">
        <input type="radio" [checked]="(currentComponent$ | async) === component.type" />
        {{ component.name | translate }}
      </label>
    </li>
  </ul>
</div>
<ng-container [ngSwitch]="currentComponent$ | async">
  <ng-container *ngSwitchCase="ContactType.CONTACTS">
    <app-contacts *ngIf="contactInfo" [contactInfo]="contactInfo"></app-contacts>
  </ng-container>
  <ng-container *ngSwitchCase="ContactType.FEEDBACK">
    <app-contact-form-new [type]="ContactType.FEEDBACK"></app-contact-form-new>
  </ng-container>
  <ng-container *ngSwitchCase="ContactType.ERROR">
    <app-contact-form-new [type]="ContactType.ERROR"></app-contact-form-new>
  </ng-container>
</ng-container>
