<svg class="patterns" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <pattern
      *ngFor="let id of renewableItems"
      id="pattern-{{ id }}"
      patternUnits="userSpaceOnUse"
      width="20"
      height="20"
    >
      <rect width="20" height="20" attr.fill="{{ colors[id]?.color }}"></rect>
      <path d="M 0 0 L 20 20 M 19 -1 L 21 1 M -1 19 L 1 21" stroke="rgba(0,0,0,.2)" stroke-width="3"></path>
    </pattern>
  </defs>
</svg>

<app-accounting-info-wrapper [dqi]="dqi" [config]="displayConfig">
  <div style="margin-right: 1em; position: relative;">
    <gp-chart-export-menu [chartRef]="chart?.chart"></gp-chart-export-menu>
  </div>
  <ene-chart #chart type="area" [data]="chartData$ | async" [settings]="chartConfig$ | async"></ene-chart>
  <div class="buttons">
    <div class="consumption" [class.active]="consumptionActive" (click)="toggleConsumption()">
      <span>
        {{ "COCKPIT.CO2.TABS.DATABASE.TABS.LOCAL_ENERGY_MIX.DESCRIPTION.CONSUMPTION" | translate }}
      </span>
    </div>
    <div class="renewable" [class.active]="renewableActive" (click)="toggleRenewable()">
      <span>{{ "COCKPIT.CO2.TABS.DATABASE.TABS.FEDERAL_MIX.DESCRIPTION.RENEWABLES" | translate }}</span>
    </div>
  </div>
</app-accounting-info-wrapper>
