import { Component, Input } from "@angular/core";

@Component({
  selector: "app-page-wrapper",
  templateUrl: "./page-wrapper.component.html",
  styleUrls: ["./page-wrapper.component.scss"]
})
export class PageWrapperComponent {
  @Input() public title: string = "";
  @Input() public chartTitle: string = "";
  @Input() public dqi: number = 0;
  @Input() public dqiSubtitle: string = "";
  @Input() public activeDqi: boolean = true;
  @Input() public showTitle: boolean = true;
}
