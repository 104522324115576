import * as network from "./network-actions";
import { NetworkActionType } from "./network-actions";
import { IEnergyMixItem } from "../../core/interfaces/energy-mix.interfaces";
import { NetworkPanelKey } from "../../core/enum/network.enum";

export function reducer(state = [], action: network.NetworkActions) {
  let data;
  switch (action.type) {
    case NetworkActionType.UPDATE_UTILITY:
      (<any>action.payload) = {
        utility_id: action.payload.utility_id,
        key: action.payload.key === NetworkPanelKey.OPERATOR ? "name" : action.payload.key,
        value: action.payload.data
      };
      return action;
    case NetworkActionType.CREATE_MIX:
      data = (<any>action.payload).data;
      (<any>action.payload) = {
        network_id: data.network_id,
        utility_id: data.utility_id,
        tracking_id: data.tracking_id, // our self created mix_id will be sent as tracking_id. later on, it will be used to replace our mix id with the official mix_id
        mix: {
          valid_from: data.valid_from,
          valid_to: data.valid_to,
          mix_id_dqi: data.mix_id_dqi,
          items: data.items,
          user_notes: data.user_notes,
          name: data.name
        }
      };
      return action;
    case NetworkActionType.UPDATE_MIX:
      (<any>action.payload) = {
        mix_id: action.payload.mix_id,
        key: action.payload.key,
        value: action.payload.data
      };
      return action;
    case NetworkActionType.DELETE_MIX:
      (<any>action.payload) = {
        mix_id: action.payload.mix_id
      };
      return action;
    case NetworkActionType.CREATE_COMPONENT:
      data = <IEnergyMixItem>action.payload.data;
      (<any>action.payload) = {
        mix_id: action.payload.mix_id,
        mix_component_id: Number(data.id),
        percent: Number(data.mixPercentage)
      };
      return action;
    case NetworkActionType.UPDATE_COMPONENT:
      data = <any>action.payload.data;
      (<any>action.payload) = {
        mix_id: action.payload.mix_id,
        mix_component_id: data.mix_component_id,
        key: action.payload.key,
        value: data.currDataValue
      };
      /*if (action.payload.key === NetworkPanelKey.PERCENTAGE) {
                (<any>action.payload).value = (<any>action.payload).value;
            }*/
      return action;
    case NetworkActionType.DELETE_COMPONENT:
      data = <IEnergyMixItem>action.payload.data;
      (<any>action.payload) = {
        mix_id: action.payload.mix_id,
        mix_component_id: Number(data.id)
      };
      return action;
    case NetworkActionType.GET_NETWORK:
      return action;
    case NetworkActionType.UPDATE_NETWORK:
      (<any>action.payload) = {
        network_id: action.payload.network_id,
        key: action.payload.key,
        value: action.payload.data,
        utility_id: action.payload.utility_id
      };
      if (action.payload.key === NetworkPanelKey.LOSS) {
        (<any>action.payload).value = (<any>action.payload).value / 100;
      }
      return action;
    case NetworkActionType.GET_EMISSION_FACTOR:
      return { payload: undefined };
    case NetworkActionType.GET_GAS_COCKPIT_VALUES_FOR_ALL_YEARS_SUCCESS:
      const map = new Map<
        number,
        {
          connectionObjectCount: number;
          connectionRate: number;
          buildingCompactionCount: number;
          networkExpansion: number;
        }
      >();
      action.payload.result.attributes.forEach((attribute) => {
        let obj = {};
        if (map.has(attribute.year)) {
          obj = map.get(attribute.year);
        }
        obj[attribute.name] = Number(attribute.value);
        map.set(attribute.year, <any>obj);
      });
      const _data: {
        connectionObjectCount: number;
        connectionRate: number;
        buildingCompactionCount: number;
        networkExpansion: number;
        year: number;
      }[] = [];
      map.forEach((element, year) => {
        _data.push({
          ...element,
          year
        });
      });
      _data.sort((a, b) => a.year - b.year);
      action.payload = _data;
      return action;
    case NetworkActionType.GET_INVEST_CHART_DATA:
    case NetworkActionType.GET_INVEST_CHART_DATA_SUCCESS:
    case NetworkActionType.UPDATE_UTILITY_SUCCESS:
    case NetworkActionType.CREATE_MIX_SUCCESS:
    case NetworkActionType.UPDATE_MIX_SUCCESS:
    case NetworkActionType.DELETE_MIX_SUCCESS:
    case NetworkActionType.CREATE_COMPONENT_SUCCESS:
    case NetworkActionType.UPDATE_COMPONENT_SUCCESS:
    case NetworkActionType.DELETE_COMPONENT_SUCCESS:
    case NetworkActionType.GET_NETWORK_SUCCESS:
    case NetworkActionType.UPDATE_NETWORK_SUCCESS:
    case NetworkActionType.GET_EMISSION_FACTOR_SUCCESS:
    case NetworkActionType.GET_NETWORK_DATA_BY_TILE:
    case NetworkActionType.GET_NETWORK_DATA_BY_TILE_SUCCESS:
    case NetworkActionType.GET_STATION_INFORMATION:
    case NetworkActionType.GET_STATION_INFORMATION_SUCCESS:
    case NetworkActionType.GET_ALL_STATIONS:
    case NetworkActionType.GET_ALL_STATIONS_SUCCESS:
    case NetworkActionType.GET_CUMULATIVE_INVESTS:
    case NetworkActionType.GET_CUMULATIVE_INVESTS_SUCCESS:
    case NetworkActionType.GET_NETWORK_ID:
    case NetworkActionType.GET_NETWORK_ID_SUCCESS:
    case NetworkActionType.GET_GAS_NETWORK_LENGTH:
    case NetworkActionType.GET_GAS_NETWORK_LENGTH_SUCCESS:
    case NetworkActionType.GET_GAS_COCKPIT_KPI:
    case NetworkActionType.GET_GAS_COCKPIT_KPI_SUCCESS:
    case NetworkActionType.GET_GAS_COCKPIT_VALUES_FOR_ALL_YEARS:
    case NetworkActionType.ERROR:
      return action;
    default:
      return state;
  }
}
