export enum TooltipEvents {
  "SHOW",
  "SHOWN",
  "HIDE",
  "HIDDEN"
}

export enum TooltipPlacement {
  "TOP",
  "BOTTOM",
  "LEFT",
  "RIGHT"
}

export enum TooltipTrigger {
  "HOVER",
  "CLICK"
}

export enum TooltipTheme {
  "DARK",
  "LIGHT"
}
