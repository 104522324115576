import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { store } from "@enersis/gp-components/store";
import {
  IRegionIdentifier as LegacyRegionIdentifier,
  RegionPropertyKpiService,
  RegionTypes,
  UtilService
} from "@energy-city/components";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Region } from "@enersis/gp-components/interfaces";
import { IRegion, IRegionIdentifier } from "../model/region-identifier";
import { RegionType } from "../model/region-type.enum";
import { ISelectedRegionService } from "./selected-region.interface";

@Injectable({
  providedIn: "root"
})
export class SelectedRegionService implements OnDestroy, ISelectedRegionService {
  private selectedRegionSlice = store.slice<Region>({
    scope: "regions",
    name: "selected-region"
  });

  private selectableRegionsSlice = store.slice<Region[]>({
    scope: "regions",
    name: "selectable-regions"
  });
  private regionChangedInHeader$: Subject<Region> = new Subject();
  private destroy$: Subject<null> = new Subject();

  private readonly fakeCoordinates: [number, number] = [0, 0];

  constructor(private utilService: UtilService, private kpiService: RegionPropertyKpiService, private zone: NgZone) {
    this.listenSelectedRegionChangeHeader();
    this.listenSelectedRegionOldStore();
  }

  getSelectedRegion(): Observable<IRegion> {
    return new Observable<Region>((subscriber) =>
      this.selectedRegionSlice.subscribe((region) => {
        this.zone.run(() => subscriber.next(region));
      })
    ).pipe(
      map((region: Region) => {
        return {
          regionId: region.id,
          coordinates: region.coordinates === this.fakeCoordinates ? undefined : region.coordinates,
          regionType: RegionType.BASIC
        };
      })
    );
  }

  getSelectedRegionIdentifier(): Observable<IRegionIdentifier> {
    return new Observable<Region>((subscriber) =>
      this.selectedRegionSlice.subscribe((region) => {
        this.zone.run(() => subscriber.next(region));
      })
    ).pipe(
      map((region: Region) => {
        return { regionId: region.id, regionType: RegionType.BASIC };
      })
    );
  }

  setSelectedRegion(regionIdentifier: IRegionIdentifier): void {
    // This should trigger a header change -> no need to update the old store directly
    this.setSelectedRegionHeader(regionIdentifier);
  }

  getRegionChangedInHeader(): Observable<Region> {
    return this.regionChangedInHeader$.asObservable();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private async setSelectedRegionHeader(regionIdentifier: LegacyRegionIdentifier): Promise<void> {
    // some selectable regions on the map are not selectable in the header
    // thus we need to check first if the region is available in the header
    const selectableRegion = this.selectableRegionsSlice
      .getInstantValue()
      .filter((region: Region) => region.id === regionIdentifier.regionId);
    let region: Region;
    if (selectableRegion.length === 1) {
      region = selectableRegion[0];
    } else {
      // region is not available in the header select, however, we set the name anyway
      // we only want to know region name and level that does not change over time, thus we
      // constantly query the year 2020.
      region = await this.kpiService
        .getRegionProperties(regionIdentifier, 2020)
        .pipe(
          map((regionProperties) => {
            return {
              name: regionProperties.name,
              id: regionIdentifier.regionId,
              coordinates: this.fakeCoordinates
            };
          })
        )
        .toPromise();
    }

    // to avoid unwanted update circles, only updated if the new region is different
    // from the currently selected one.
    if (this.selectedRegionSlice.getInstantValue() !== region) {
      this.selectedRegionSlice.update(region);
    }
  }

  private listenSelectedRegionOldStore(): void {
    this.utilService.regionIdentifier$.subscribe((regionIdentifier: LegacyRegionIdentifier) => {
      this.setSelectedRegionHeader(regionIdentifier);
    });
  }

  private listenSelectedRegionChangeHeader(): void {
    new Observable<Region>((subscriber) =>
      this.selectedRegionSlice.subscribe((region) => {
        this.zone.run(() => subscriber.next(region));
      })
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((region: Region) => {
        this.setSelectedRegionOldStore(region);
      });
  }

  // this should get obsolete after the refactoring
  private setSelectedRegionOldStore(region: Region): void {
    const regionIdStore = this.utilService.getRegionId();
    // to avoid unwanted update circles, only updated if the new region is different
    // from the currently selected one.
    if (region.id !== regionIdStore) {
      this.utilService.setRegionId(region.id, RegionTypes.BASIC);
      if (region.coordinates !== this.fakeCoordinates) {
        this.regionChangedInHeader$.next(region);
      }
    }
  }
}
