import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { TenantInfoService, assetUrl } from "@energy-city/components";
import { TranslateService } from "@ngx-translate/core";
import { PrintService } from "../../services/print.service";

@Component({
  selector: "app-general-info-page",
  templateUrl: "./general-info-page.component.html",
  styleUrls: ["./general-info-page.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class GeneralInfoPageComponent implements OnInit {
  @Input() public text;
  @Input() public tenant;
  public title = "PRINT_VIEW.TITLE.PAGE_6";
  public logo = "";
  public applicationName = "";
  public assetUrl = "";
  public useUserFactorTranslationKey: string;

  constructor(
    private tenantInfoService: TenantInfoService,
    private translationService: TranslateService,
    private readonly printService: PrintService
  ) {}

  public ngOnInit(): void {
    this.logo = this.tenantInfoService.getTenantLogo(this.tenant);
    this.applicationName = this.translationService.instant(`CLIENTS.${this.tenant}.APP_NAME`);
    this.applicationName =
      this.applicationName !== `CLIENTS.${this.tenant}.APP_NAME`
        ? this.applicationName
        : this.translationService.instant(`CLIENTS.DEFAULT_APP_NAME`);
    this.assetUrl = assetUrl("");

    this.useUserFactorTranslationKey = `DATA_PANEL.ACCOUNTING_PARAMETERS.FACTORS.${
      this.printService.selectedUseUserFactors ? "custom" : "base"
    }`;
  }
}
