/* tslint:disable:member-ordering */
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import { switchMap, map, catchError } from "rxjs/operators";

import { TourActionType, GetTour, GetTourSuccess, TourError } from "./tour-actions";

import { ErrorAction } from "./../common";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class TourEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  @Effect() public getTours$ = this.actions$.pipe(
    ofType(TourActionType.GET_TOURS),
    switchMap((action: GetTour) => {
      const url =
        environment.configServiceApi +
        `/login/getTour/${action.tour}/${action.ags}/${action.tourTemplate}/${action.clientId}`;
      return this.http.get(url, {}).pipe(
        map((payload) => new GetTourSuccess(payload)),
        catchError((error) => ErrorAction(action, error, TourError))
      );
    })
  );
}
