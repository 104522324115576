import { ExtendedCockpitCharts } from "./extended-charts.type";
import { AccountingMethod } from "../../configs/accounting-method";
import { ChartCo2SectorsFullComponent } from "../../modules/co2-cockpit/charts/greenhouse-gases/sectors/full/chart-co2-sectors-full.component";
import { ChartEnergyRelatedGreenhouseGasesComponent } from "../../modules/co2-cockpit/charts/greenhouse-gases/energy-related/chart-energy-related-greenhouse-gases.component";
import { ChartGreenhouseGasesTrendComponent } from "../../modules/co2-cockpit/charts/greenhouse-gases/trend/chart-greenhouse-gases-trend.component";
import { Co2EmissionMainPolluterChartComponent } from "../../modules/charts/components/main-polluter/extensions/co2-emission.component";
import { PublicSectorMainPolluterChartComponent } from "../../modules/charts/components/main-polluter/extensions/public-sector.component";
import { ChartFinalEnergySectorsComponent } from "../../modules/co2-cockpit/charts/final-energy/sectors/chart-final-energy-sectors.component";
import { ChartFinalEnergyPerEnergyCarrierComponent } from "../../modules/co2-cockpit/charts/final-energy/energy-carrier/chart-final-energy-per-energy-carrier.component";
import { ChartFinalEnergyTrafficComponent } from "../../modules/co2-cockpit/charts/final-energy/traffic/chart-final-energy-traffic.component";
import { QualitiveBenchmarkingComponent } from "../../modules/benchmark/qualitative/qualitive-benchmarking.component";
import { QuantitiveBenchmarkingComponent } from "../../modules/benchmark/quantitive/quantitive-benchmarking.component";
import { LocalElectricityComponent } from "../../modules/co2-cockpit/charts/database/local-electricity/local-electricity.component";
import { FederalMixComponent } from "../../modules/co2-cockpit/charts/database/federal-mix/federal-mix.component";

export class Co2EntryComponents {
  public readonly components = {
    [AccountingMethod.GPC]: {
      ChartCo2SectorsComponent: ChartCo2SectorsFullComponent,
      ChartEnergyRelatedGreenhouseGasesComponent: ChartEnergyRelatedGreenhouseGasesComponent,
      ChartGreenhouseGasesTrendComponent: ChartGreenhouseGasesTrendComponent,
      Co2EmissionMainPolluterChartComponent: Co2EmissionMainPolluterChartComponent,
      PublicSectorMainPolluterChartComponent: PublicSectorMainPolluterChartComponent,
      ChartFinalEnergySectorsComponent: ChartFinalEnergySectorsComponent,
      ChartFinalEnergyPerEnergyCarrierComponent: ChartFinalEnergyPerEnergyCarrierComponent,
      ChartFinalEnergyTrafficComponent: ChartFinalEnergyTrafficComponent,
      QualitiveBenchmarkingComponent: QualitiveBenchmarkingComponent,
      QuantitiveBenchmarkingComponent: QuantitiveBenchmarkingComponent,
      LocalElectricityComponent: LocalElectricityComponent,
      FederalMixComponent: FederalMixComponent
    },
    [AccountingMethod.BISKO]: {
      ChartCo2SectorsComponent: ChartCo2SectorsFullComponent,
      ChartEnergyRelatedGreenhouseGasesComponent: ChartEnergyRelatedGreenhouseGasesComponent,
      QualitiveBenchmarkingComponent: QualitiveBenchmarkingComponent,
      QuantitiveBenchmarkingComponent: QuantitiveBenchmarkingComponent,
      ChartFinalEnergySectorsComponent: ChartFinalEnergySectorsComponent,
      ChartFinalEnergyPerEnergyCarrierComponent: ChartFinalEnergyPerEnergyCarrierComponent,
      ChartFinalEnergyTrafficComponent: ChartFinalEnergyTrafficComponent,
      FederalMixComponent: FederalMixComponent,
      Co2EmissionMainPolluterChartComponent: Co2EmissionMainPolluterChartComponent,
      PublicSectorMainPolluterChartComponent: PublicSectorMainPolluterChartComponent,
      ChartGreenhouseGasesTrendComponent: ChartGreenhouseGasesTrendComponent
    },
    [AccountingMethod.BICO2SH]: {
      ChartCo2SectorsComponent: ChartCo2SectorsFullComponent,
      ChartEnergyRelatedGreenhouseGasesComponent: ChartEnergyRelatedGreenhouseGasesComponent,
      ChartGreenhouseGasesTrendComponent: ChartGreenhouseGasesTrendComponent,
      Co2EmissionMainPolluterChartComponent: Co2EmissionMainPolluterChartComponent,
      PublicSectorMainPolluterChartComponent: PublicSectorMainPolluterChartComponent,
      ChartFinalEnergySectorsComponent: ChartFinalEnergySectorsComponent,
      ChartFinalEnergyPerEnergyCarrierComponent: ChartFinalEnergyPerEnergyCarrierComponent,
      ChartFinalEnergyTrafficComponent: ChartFinalEnergyTrafficComponent,
      FederalMixComponent: FederalMixComponent,
      LocalElectricityComponent: LocalElectricityComponent,
      QuantitiveBenchmarkingComponent: QuantitiveBenchmarkingComponent,
      QualitiveBenchmarkingComponent: QualitiveBenchmarkingComponent
    },
    [AccountingMethod.STET]: {
      ChartCo2SectorsComponent: ChartCo2SectorsFullComponent,
      ChartEnergyRelatedGreenhouseGasesComponent: ChartEnergyRelatedGreenhouseGasesComponent,
      ChartGreenhouseGasesTrendComponent: ChartGreenhouseGasesTrendComponent,
      Co2EmissionMainPolluterChartComponent: Co2EmissionMainPolluterChartComponent,
      PublicSectorMainPolluterChartComponent: PublicSectorMainPolluterChartComponent,
      ChartFinalEnergySectorsComponent: ChartFinalEnergySectorsComponent,
      ChartFinalEnergyPerEnergyCarrierComponent: ChartFinalEnergyPerEnergyCarrierComponent,
      ChartFinalEnergyTrafficComponent: ChartFinalEnergyTrafficComponent,
      FederalMixComponent: FederalMixComponent,
      LocalElectricityComponent: LocalElectricityComponent,
      QuantitiveBenchmarkingComponent: QuantitiveBenchmarkingComponent,
      QualitiveBenchmarkingComponent: QualitiveBenchmarkingComponent
    },
    [AccountingMethod.BICO2BW]: {
      ChartCo2SectorsComponent: ChartCo2SectorsFullComponent,
      ChartEnergyRelatedGreenhouseGasesComponent: ChartEnergyRelatedGreenhouseGasesComponent,
      ChartGreenhouseGasesTrendComponent: ChartGreenhouseGasesTrendComponent,
      Co2EmissionMainPolluterChartComponent: Co2EmissionMainPolluterChartComponent,
      PublicSectorMainPolluterChartComponent: PublicSectorMainPolluterChartComponent,
      ChartFinalEnergySectorsComponent: ChartFinalEnergySectorsComponent,
      ChartFinalEnergyPerEnergyCarrierComponent: ChartFinalEnergyPerEnergyCarrierComponent,
      ChartFinalEnergyTrafficComponent: ChartFinalEnergyTrafficComponent,
      FederalMixComponent: FederalMixComponent,
      LocalElectricityComponent: LocalElectricityComponent,
      QuantitiveBenchmarkingComponent: QuantitiveBenchmarkingComponent,
      QualitiveBenchmarkingComponent: QualitiveBenchmarkingComponent
    }
  };

  public getComponent(accountingMethod: AccountingMethod, name: string): ExtendedCockpitCharts {
    return this.components[accountingMethod][name];
  }
}
