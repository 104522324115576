import { Injectable } from "@angular/core";
import { IDataItem } from "../../charts/models/chart-data.interface";

import { Observable, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromRoot from "@energy-city/components";
import { map } from "rxjs/operators";
import { RegionService, DistrictKpi } from "@energy-city/components";

@Injectable()
export class Co2CockpitFixedService {
  constructor(private store: Store<fromRoot.IApplicationState>, private regionService: RegionService) {}

  public convertData(data: Array<Array<{ [key: string]: IDataItem }>>) {
    return data.reduce((summ, items) => {
      return items["result"].reduce((acc, item) => {
        return (acc += item.value);
      }, summ);
    }, 0);
  }

  public selectCockpitKpi(): Observable<DistrictKpi> {
    return combineLatest([this.regionService.selectedRegionCountBuildings$, this.regionService.selectedRegionDetails$])
      .pipe(map(([countBuildings, region]) => new DistrictKpi({ countBuildings, region })));
  }
}
