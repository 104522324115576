import { Directive } from "@angular/core";

@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: "[ene-header-right]",
  exportAs: "eneHeaderRightDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-header-right"
  }
})
export class EneHeaderRightDirective {}
