import { Action } from "@ngrx/store";
import { IAttribute } from "./index";

export enum GasCockpitKpiActionTypes {
  GET_GAS_KPI = "[Gas Cockpit]: Get gas kpi data",
  GET_GAS_KPI_SUCCESS = "[Gas Cockpit]: Get gas kpi data successfully",
  GET_GAS_KPI_ERROR = "[Gas Cockpit]: Get gas kpi data error"
}

interface IProps {
  gasnetworkId: string;
  year: number;
}

export class GasCockpitKpi implements Action {
  public readonly type = GasCockpitKpiActionTypes.GET_GAS_KPI;

  constructor(public props: IProps) {}
}

export class GasCockpitKpiSuccess implements Action {
  public readonly type = GasCockpitKpiActionTypes.GET_GAS_KPI_SUCCESS;

  constructor(public data: Array<IAttribute>) {}
}

export class GasCockpitKpiError implements Action {
  public readonly type = GasCockpitKpiActionTypes.GET_GAS_KPI_ERROR;

  constructor(public error: string) {}
}

export type GasCockpitKpiActionsUnion = GasCockpitKpi | GasCockpitKpiSuccess | GasCockpitKpiError;
