/* tslint:disable:member-ordering */
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import { switchMap, map, mergeMap, catchError } from "rxjs/operators";

import { EneUiService } from "@energy-city/ui/helper";
import { TranslateService } from "@ngx-translate/core";
import * as fromRoot from "@energy-city/components";

import {
  AppActionType,
  UpdateTranslationsLoaded,
  GetClientConfigSuccess,
  AppError,
  GetClientMapConfigSuccess
} from "./app-actions";

import { ErrorAction, FatalError } from "./../common";

import * as JSONFn from "json-fn";
import { Store } from "@ngrx/store";
import { DataService } from "../../services/data.service";
import { Http } from "../../../types/httpMethod";

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.IApplicationState>,
    private translate: TranslateService,
    private uiService: EneUiService,
    private dataService: DataService
  ) {}

  @Effect() public UpdateTranslations$ = this.actions$.pipe(
    ofType(AppActionType.UPDATE_TRANSLATIONS),
    switchMap((action: any) => {
      this.uiService.setLanguage(action.payload.language);
      return this.translate.use(action.payload.language).pipe(
        map(() => new UpdateTranslationsLoaded(action.payload)),
        catchError((error) => ErrorAction(action, error, AppError))
      );
    })
  );

  @Effect() public getClientConfig$ = this.actions$.pipe(
    ofType(AppActionType.GET_CLIENT_CONFIG),
    mergeMap((action: any) =>
      this.dataService.sendConfigServiceRequest("/login/clientConfig ", action.payload, Http.GET).pipe(
        map((data) => new GetClientConfigSuccess(data)),
        catchError((error) => ErrorAction(action, error, FatalError))
      )
    )
  );

  @Effect() public getClientMapConfig$ = this.actions$.pipe(
    ofType(AppActionType.GET_CLIENT_MAPCONFIG),
    mergeMap((action: any) =>
      this.dataService
        .sendConfigServiceRequest("/login/clientMapConfig ", action.payload, Http.GET, { responseType: "text" })
        .pipe(
          map((data) => new GetClientMapConfigSuccess(JSONFn.parse(data))),
          catchError((error) => ErrorAction(action, error, FatalError))
        )
    )
  );
}
