import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../../state/index";
import { EneChartComponent } from "@energy-city/ui/chart";
import { Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";
import { ChartQualitiveBenchmarking } from "./chart-qualitive-benchmarking.model";
import { EneKpisV2Service } from "@energy-city/ui/kpis-v2";
import { FinalEnergyService } from "../../co2-cockpit/charts/final-energy/final-energy.service";
import { ofType } from "@ngrx/effects";
import { ChartFinalEnergyActionType, GetFinalEnergyPerEnergyKpi, getMeasures } from "../../../state/index";
import { UtilService } from "../../../services/util.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-qualitive-benchmarking",
  templateUrl: "./qualitive-benchmarking.component.html",
  styleUrls: ["./qualitive-benchmarking.component.scss"],
  providers: [{ provide: "chartModel", useFactory: ChartQualitiveBenchmarking }]
})
export class QualitiveBenchmarkingComponent implements OnInit, OnDestroy {
  public chartData: any;
  public chartConfig: any;
  private destroy$: Subject<void> = new Subject();
  private chartModel: ChartQualitiveBenchmarking;
  private kpiAverage: number;
  private kpiTotal: number;
  @ViewChild("chart", { static: true }) public chart: EneChartComponent;

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private eneKpisV2Service: EneKpisV2Service,
    private utilService: UtilService,
    private finalEnergyService: FinalEnergyService,
    private translate: TranslateService
  ) {}

  public ngOnInit() {
    this.chartModel = new ChartQualitiveBenchmarking(this.translate);
    this.requestChartData();
    this.chartModel.data$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.chartData = data;
    });

    this.chartModel.config$.pipe(takeUntil(this.destroy$)).subscribe((config) => {
      this.chartConfig = config;
    });

    this.store
      .select(fromRoot.GetFinalEnergyState)
      .pipe(ofType(ChartFinalEnergyActionType.GET_KPI_SUCCESS), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.kpiAverage = res.payload.average;
        this.renewKPI();
      });

    this.chartModel.onSeriesChange$.pipe(takeUntil(this.destroy$)).subscribe((series) => {
      this.recalculateKPI(series);
    });

    this.store
      .select(getMeasures)
      .pipe(
        filter((data) => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe((currentMeasures) => {
        this.chartModel.initChart(currentMeasures);
      });

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.chartData) {
        this.chartModel.updateChart();
      }
    });
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private recalculateKPI(series) {
    this.kpiTotal = this.finalEnergyService.getKpiTotal(series, this.utilService.getSelectedYear());
    this.renewKPI();
  }

  private renewKPI() {
    if (this.kpiAverage === undefined || this.kpiTotal === undefined) {
      return;
    }
    const difference_to_average = this.kpiAverage - this.kpiTotal;
    this.eneKpisV2Service.runMapping(
      { total: this.kpiTotal, average: this.kpiAverage, difference_to_average },
      "benchmark_kpi"
    );
  }

  private requestChartData() {
    this.chart.chart && this.chart.showLoading();
    this.store.dispatch(
      new GetFinalEnergyPerEnergyKpi(this.utilService.selectedAgs, this.utilService.getSelectedYear())
    );
  }
}
