import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KpiRankEmissionsComponent } from "./components/kpi/kpi-rank-emissions/kpi-rank-emissions.component";
import { TranslateModule } from "@ngx-translate/core";
import { ApiEndpointService } from "../../common/utils/api-endpoint.service";
import { API_ENDPOINT_SERVICE_TOKEN } from "../../common/utils/api-endpoint.interface";
import { KpiEmissionsAbsoluteChangeComponent } from "./components/kpi/kpi-emissions-absolute-change/kpi-emissions-absolute-change.component";
import { KpiEmissionsRelativeChangeComponent } from "./components/kpi/kpi-emissions-relative-change/kpi-emissions-relative-change.component";
import { UtilsModule } from "../../common/utils/utils.module";
import { KpiEmissionsPerCapitaComponent } from "./components/kpi/kpi-emissions-per-capita/kpi-emissions-per-capita.component";
import { KpiRegionIndicatorsComponent } from "./components/kpi-region-indicators/kpi-region-indicators.component";
import { ChartEmissionsComponent } from "./components/charts/chart-emissions-sunburst/chart-emissions-sunburst.component";
import { ChartEmissionsComponentBase } from "./components/charts/chart-emissions-sunburst-base/chart-emissions-sunburst-base.component";
import { WeatherCorrectionIndicatorModule } from "@energy-city/ui/weather-correction-indicator";

@NgModule({
  declarations: [
    KpiRankEmissionsComponent,
    KpiEmissionsAbsoluteChangeComponent,
    KpiEmissionsRelativeChangeComponent,
    KpiEmissionsPerCapitaComponent,
    KpiRegionIndicatorsComponent,
    ChartEmissionsComponent,
    ChartEmissionsComponentBase
  ],
  providers: [{ provide: API_ENDPOINT_SERVICE_TOKEN, useClass: ApiEndpointService }],
  imports: [CommonModule, TranslateModule, UtilsModule, WeatherCorrectionIndicatorModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    KpiRankEmissionsComponent,
    KpiEmissionsAbsoluteChangeComponent,
    KpiEmissionsRelativeChangeComponent,
    KpiEmissionsPerCapitaComponent,
    KpiRegionIndicatorsComponent,
    ChartEmissionsComponent,
    ChartEmissionsComponentBase
  ]
})
export class AccountingEmissionModule {}
