<section ene-sidenav-start>
  <div class="ene-surface-box-flat">
    <mat-form-field>
      <mat-label class="select-panel-label">
        {{ "SIMULATION.SCENARIOS.TITLE" | translate }}
      </mat-label>
      <mat-select disableOptionCentering [formControl]="scenario" [disabled]="selectionDisabled$ | async">
        <mat-option *ngFor="let scenario of scenarios$ | async" [value]="scenario.scenarioId">
          {{ scenario.title | translate }}
        </mat-option>
        <mat-option class="new-scenario" [value]="'new'">
          {{ "SIMULATION.SCENARIOS.CREATE_SCENARIO" | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label class="select-panel-label">{{ "SIMULATION.MEASURMENT.TITLE" | translate }}</mat-label>
      <mat-select [formControl]="set" [disabled]="selectionDisabled$ | async">
        <mat-option *ngFor="let set of sets$ | async" [value]="set.setId">
          {{ set.title | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="simulation-scenario-section">
    <app-simulation-scenario [scenario]="selectedScenario" [isSimulationActive]="selectionDisabled$ | async">
    </app-simulation-scenario>
  </div>
  <div class="ene-surface-box-flat ene-surface-box-flat-controls" [class.disabled]="disableSimulation$ | async">
    <button
      (click)="toggleSimulation()"
      [disabled]="simulationDisabled$ | async"
      mat-flat-button
      class="ene-button-primary"
    >
      {{ (chartIsEnabled ? "SIMULATION.STOP_SIMULATION" : "SIMULATION.START_SIMULATION") | translate }}
    </button>
  </div>

  <div class="ene-surface-box-flat ene-surface-box-flat-chart">
    <co2-simulation-chart
      [accounting]="chartAccounting"
      [measureSetId]="chartMeasureSetId"
      [scenario]="chartScenario"
      [isEnabled]="chartIsEnabled"
    ></co2-simulation-chart>
  </div>
</section>
