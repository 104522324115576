import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneEditDirective } from "./directive/EneEdit.directive";
import { EneEditChangeListenerDirective } from "./directive/EneEditChangeListener.directive";
import { EneEditModeTriggerDirective } from "./directive/EneEditModeTrigger.directive";
import { EneEditableInputDirective } from "./directive/EneEditableInput.directive";
import { AddRemoveButtonComponent } from "./add-remove-button/addRemoveButton.component";
import { EneIconModule } from "@energy-city/ui/icon";

@NgModule({
  declarations: [
    EneEditDirective,
    EneEditChangeListenerDirective,
    EneEditModeTriggerDirective,
    EneEditableInputDirective,
    AddRemoveButtonComponent
  ],
  imports: [CommonModule, EneIconModule],
  exports: [
    EneEditDirective,
    EneEditChangeListenerDirective,
    EneEditModeTriggerDirective,
    EneEditableInputDirective,
    AddRemoveButtonComponent
  ]
})
export class EneEditModule {}
