import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FactorTableComponent } from "./factor-table/factor-table.component";
import { CrossTableComponent } from "./factor-table/cross-table/cross-table.component";
import { HierarchyTableComponent } from "./factor-table/hierarchy-table/hierarchy-table.component";
import { ValueListTableComponent } from "./factor-table/value-list-table/value-list-table.component";
import { TranslateModule } from "@ngx-translate/core";
import { CdkTableModule } from "@angular/cdk/table";

@NgModule({
  declarations: [FactorTableComponent, CrossTableComponent, HierarchyTableComponent, ValueListTableComponent],
  imports: [CommonModule, TranslateModule, CdkTableModule],
  exports: [FactorTableComponent]
})
export class AccountingReportModule {}
