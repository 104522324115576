import { Component, ChangeDetectionStrategy } from "@angular/core";
import { EneSnackbarService } from "@energy-city/ui/snackbar";
import { DataInputService } from "../data-input.service";
import { BASIC_ERROR } from "../../../../../configs/snackbar";

@Component({
  selector: "app-import-notice",
  template: `
    <ene-modal
      id="import-notice"
      sizing="medium"
      closebutton="false"
      [title]="'DATA_PANEL.INPUT_DATA.MODAL.TITLE' | translate | uppercase"
    >
      <ul>
        <li>{{ "DATA_PANEL.INPUT_DATA.MODAL.LI.LI_1" | translate }}</li>
        <li>{{ "DATA_PANEL.INPUT_DATA.MODAL.LI.LI_2" | translate }}</li>
        <li>{{ "DATA_PANEL.INPUT_DATA.MODAL.LI.LI_3" | translate }}</li>
      </ul>
      <section class="button-container">
        <label class="ene-button-pill-primary">
          {{ "DATA_PANEL.INPUT_DATA.MODAL.BUTTON" | translate | uppercase }}
          <input type="file" (change)="onChange($event)" />
        </label>
      </section>
    </ene-modal>
  `,
  styles: [
    `
      li {
        white-space: pre-wrap;
        padding-bottom: 0.5em;
      }

      .button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0.5em 0;
      }

      input {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
      }

      label {
        position: relative;
        border-radius: 4px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportNoticeModalComponent {
  // TODO: needs translation?
  private errorTooManyFiles = "Bitte laden Sie nur eine Datei hoch";
  private errorFaultyFile = "Bitte laden Sie eine Datei mit Dateiendung '.csv' hoch";

  constructor(private snackbar: EneSnackbarService, private dataInputService: DataInputService) {}

  public onChange(event: any): void {
    event.stopPropagation();

    const files = event.target.files;

    try {
      if (!files || !files.length) {
        throw new Error(BASIC_ERROR);
      }

      if (files.length === 1) {
        const file = files[0];
        const fileExtension = file.name.split(".").pop();

        if (fileExtension === "csv") {
          // TODO: This needs await, or exceptions will not be caught
          this.dataInputService.import(file);
        } else {
          throw new Error(this.errorFaultyFile);
        }
      } else {
        throw new Error(this.errorTooManyFiles);
      }
    } catch (error) {
      this.snackbar.error(error.message);
    } finally {
      event.target.value = ""; // Remove file name, so that file can be uploaded again
    }
  }
}
