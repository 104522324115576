import { MainTableCategories } from "../../data-input/model/main-table-categories.enum";
import { IEnvDto, ITenantMapConfigDto } from "./env.model";

export interface IConfigDto {
  dataPanel?: IDataPanelConfigDto;
  cockpitPanel?: ICockpitPanelConfigDto;
}

interface IDataPanelConfigDto {
  dataInput?: IDataInputConfigDto;
}

interface IDataInputConfigDto {
  feedIn?: IDataInputTableConfigDto;
}

interface ICockpitPanelConfigDto {
  ranking: {
    enabled: boolean;
  };
}

interface IDataInputTableConfigDto {
  enabled: boolean;
}

export class Config {
  public readonly dataPanel: DataPanelConfig;
  public readonly cockpitPanel: CockpitPanelConfig;
  public readonly tenantMapConfig: ITenantMapConfigDto | undefined;

  constructor(config?: IConfigDto, env?: IEnvDto) {
    this.dataPanel = new DataPanelConfig(config?.dataPanel);
    this.cockpitPanel = new CockpitPanelConfig(config?.cockpitPanel);
    this.tenantMapConfig = env?.co2balance?.tenantMapConfig;
  }
}

export class DataPanelConfig {
  public readonly dataInput: DataInputConfig;
  constructor(dataPanelConfig?: IDataPanelConfigDto) {
    this.dataInput = new Map();
    this.dataInput.set(MainTableCategories.FEED_IN, new DataInputTableConfig(dataPanelConfig?.dataInput?.feedIn));
  }
}

export type DataInputConfig = Map<MainTableCategories, DataInputTableConfig>;

export class CockpitPanelConfig {
  public readonly isRankingEnabled: boolean;
  constructor(cockpitPanelConfig?: ICockpitPanelConfigDto) {
    this.isRankingEnabled = !!cockpitPanelConfig?.ranking?.enabled;
  }
}

export class DataInputTableConfig {
  public readonly enabled: boolean;
  constructor(dataInputTableConfig?: IDataInputTableConfigDto) {
    this.enabled = !!dataInputTableConfig?.enabled;
  }
}
