import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IInitialConfig, ILogin } from "../../core/global.interface";
import { Login } from "../../core/classes/login.class";
import { AutoLogin } from "../../core/classes/auto-login.class";
import { Subject } from "rxjs";
import { INITIAL_CONFIG } from "../../core/tokens";

@Injectable({
  providedIn: "root"
})
export class InternalAuthenticationService {
  private _loggedIn: boolean = false;

  constructor(@Inject(INITIAL_CONFIG) private initialConfig: IInitialConfig, private http: HttpClient) {}

  public get loggedIn(): boolean {
    try {
      return this._loggedIn;
    } catch (e) {
      return false;
    }
  }

  public _login(options: { noIdpHint: boolean; idp_hint?: string }): Subject<any> {
    const response: Subject<any> = new Subject<any>();

    const config: ILogin = {
      realm: this.initialConfig.realm,
      serviceUrl: this.initialConfig.serviceUrl,
      clientName: this.initialConfig.clientId,
      refreshUrl: this.initialConfig.refreshUrl
    };
    if (options.idp_hint) {
      config.idp_hint = options.idp_hint;
    } else if (options.noIdpHint) {
      config.noIdpHint = options.noIdpHint;
    }

    let login = Login;

    if (sessionStorage.getItem("publicReferer") === null) {
      if (this.initialConfig.loginType === "auto") {
        login = AutoLogin;
      }
    } else {
      config.serviceUrl = this.initialConfig.refreshUrl;
    }

    new login(this.http, config).login.subscribe((res) => {
      this._loggedIn = true;
      response.next(res);
    });

    return response;
  }

  public _logout(): boolean {
    localStorage.removeItem("authenticationObject");
    this._loggedIn = false;
    return false;
  }
}
