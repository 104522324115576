<ng-container *ngIf="table">
  <div class="table-subtitle">{{ table.title | translate: { year: year, item: tableNr } }}</div>

  <co2-cross-table *ngIf="table.tableType === FactorTableType.CROSS_TABLE" [table]="table.crossTable"></co2-cross-table>
  <co2-hierarchy-table
    *ngIf="table.tableType === FactorTableType.HIERARCHY_TABLE"
    [table]="table.hierarchyTable"
  ></co2-hierarchy-table>
  <co2-value-list-table
    *ngIf="table.tableType === FactorTableType.VALUE_LIST"
    [table]="table.valueList"
  ></co2-value-list-table>
</ng-container>
