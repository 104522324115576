import { ConsumptionAction, ConsumptionActionType } from "./consumption-actions";
import { ILoadingBehavior } from "../../../common";

export const reducer = (state: IConsumptionState = {}, action: ConsumptionAction) => {
  switch (action.type) {
    case ConsumptionActionType.GET_CONSUMPTION_DATA:
      return {
        loading: true,
        regionId: action.regionId,
        scenarioId: action.scenarioId,
        year: action.year
      };
    case ConsumptionActionType.GET_CONSUMPTION_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false
      };
    case ConsumptionActionType.GET_CONSUMPTION_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};

export interface IConsumptionState extends ILoadingBehavior {
  electricity?: number;
  gas?: number;
  regionId?: string;
  scenarioId?: string;
  year?: number;
}
