import { Component, Inject } from "@angular/core";
import { ISimulationScenarioValue } from "@energy-city/components";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-simulation-goal-editor",
  templateUrl: "./simulation-goal-editor.component.html",
  styleUrls: ["./simulation-goal-editor.component.scss"]
})
export class SimulationGoalEditorComponent {
  public value: ISimulationScenarioValue;
  constructor(
    public dialogRef: MatDialogRef<SimulationGoalEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { value: ISimulationScenarioValue }
  ) {
    this.value = data.value;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public saveChanges(): void {
    this.dialogRef.close(this.value);
  }

  public yearChanged(year: number): void {
    this.value.year = year;
  }

  public goalChanged(goal: number): void {
    this.value.goal = goal;
  }

  public formatRangeValuePercentage(value: number): string {
    return `-${value * 100}%`;
  }
}
