import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CONFIG_DATA_SERVICE_TOKEN, IConfigDataService } from "../../common/config/config-data.interface";
import { ICategory } from "../model/category.interface";
import { TABLE_CONFIG } from "./data-input.config";

@Injectable({
  providedIn: "root"
})
export class DataInputConfigService {
  constructor(@Inject(CONFIG_DATA_SERVICE_TOKEN) private configDataService: IConfigDataService) {}

  getCategories(): Observable<Array<ICategory>> {
    return this.configDataService.getConfig().pipe(
      map((config) => {
        return TABLE_CONFIG.filter((cat) => {
          const tableConfig = config.dataPanel.dataInput.get(cat.id);
          if (tableConfig) {
            return tableConfig.enabled;
          } else {
            return true;
          }
        });
      })
    );
  }
}
