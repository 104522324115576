<ng-content></ng-content>
<div
  class="dqi-view"
  [ene-tooltip]="'TDI.DQI.TOOLTIP' | translate: { assetUrl: assetUrl }"
  [placement]="'BOTTOM'"
  [max-width]="'27em'"
  [delay]="250"
>
  <div class="dqi-view__item" *ngFor="let item of items" [class.active]="(value | number: locale) > item"></div>
</div>
