import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChartFinalEnergyPerEnergyCarrierComponent } from "./final-energy/energy-carrier/chart-final-energy-per-energy-carrier.component";
import { ChartFinalEnergySectorsComponent } from "./final-energy/sectors/chart-final-energy-sectors.component";
import { ChartFinalEnergyTrafficComponent } from "./final-energy/traffic/chart-final-energy-traffic.component";
import { ChartEnergyRelatedGreenhouseGasesComponent } from "./greenhouse-gases/energy-related/chart-energy-related-greenhouse-gases.component";
import { ChartGreenhouseGasesTrendComponent } from "./greenhouse-gases/trend/chart-greenhouse-gases-trend.component";
import { NoDisplayComponent } from "./no-display/no-display.component";
import { EneChartModule } from "@energy-city/ui/chart";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneFormsModule } from "@energy-city/ui/forms";
import { TranslateModule } from "@ngx-translate/core";
import { FinalEnergyService } from "./final-energy/final-energy.service";
import { SharedModule } from "../../../shared/shared.module";
import { EnergyTrafficService } from "./final-energy/traffic/energy-traffic.service";
import { EnergyCarrierService } from "./final-energy/energy-carrier/energy-carrier.service";
import { ChartCo2SectorsFullComponent } from "./greenhouse-gases/sectors/full/chart-co2-sectors-full.component";
import { ChartCo2SectorsCompactComponent } from "./greenhouse-gases/sectors/compact/chart-co2-sectors-compact.component";
import { StatusBarComponent } from "./status-bar/status-bar.component";
import { GreenhouseGasesService } from "./greenhouse-gases/energy-related/greenhouse-gases.service";
import { QualitiveBenchmarkingComponent } from "../../benchmark/qualitative/qualitive-benchmarking.component";
import { EnergySectorsService } from "./final-energy/sectors/energy-sectors.service";
import { FederalMixComponent } from "./database/federal-mix/federal-mix.component";
import { LocalElectricityComponent } from "./database/local-electricity/local-electricity.component";
import { MainPolluterChartModule } from "../../charts/components";
import { DatabaseChartComponent } from "./database/database-chart.component";
import { AccountingInfoWrapperModule } from "../../charts/common";
import { QuantitiveBenchmarkingComponent } from "../../benchmark/quantitive/quantitive-benchmarking.component";
import { Co2CockpitKPIService } from "./services/co2-cockpit-kpi.service";
import "@enersis/gp-components/gp-chart-export-menu";
import "@enersis/gp-components/gp-icon-button";
import { AccountingEmissionModule } from "@co2-shared/accounting-emission";

@NgModule({
  declarations: [
    ChartFinalEnergyPerEnergyCarrierComponent,
    ChartFinalEnergySectorsComponent,
    ChartFinalEnergyTrafficComponent,
    ChartCo2SectorsFullComponent,
    ChartEnergyRelatedGreenhouseGasesComponent,
    ChartGreenhouseGasesTrendComponent,
    NoDisplayComponent,
    ChartCo2SectorsCompactComponent,
    StatusBarComponent,
    QualitiveBenchmarkingComponent,
    QuantitiveBenchmarkingComponent,
    DatabaseChartComponent,
    FederalMixComponent,
    LocalElectricityComponent
  ],
  imports: [
    CommonModule,
    EneChartModule,
    EneIconModule,
    EneFormsModule,
    SharedModule,
    TranslateModule,
    AccountingInfoWrapperModule,
    MainPolluterChartModule,
    AccountingEmissionModule
  ],
  exports: [
    ChartFinalEnergyPerEnergyCarrierComponent,
    ChartFinalEnergySectorsComponent,
    ChartFinalEnergyTrafficComponent,
    ChartCo2SectorsFullComponent,
    ChartCo2SectorsCompactComponent,
    ChartEnergyRelatedGreenhouseGasesComponent,
    ChartGreenhouseGasesTrendComponent,
    NoDisplayComponent,
    QualitiveBenchmarkingComponent,
    QuantitiveBenchmarkingComponent,
    FederalMixComponent,
    LocalElectricityComponent,
    MainPolluterChartModule,
    DatabaseChartComponent
  ],
  entryComponents: [
    ChartFinalEnergyPerEnergyCarrierComponent,
    ChartFinalEnergySectorsComponent,
    ChartFinalEnergyTrafficComponent,
    ChartCo2SectorsFullComponent,
    ChartCo2SectorsCompactComponent,
    ChartEnergyRelatedGreenhouseGasesComponent,
    ChartGreenhouseGasesTrendComponent,
    NoDisplayComponent,
    QualitiveBenchmarkingComponent,
    QuantitiveBenchmarkingComponent,
    FederalMixComponent,
    LocalElectricityComponent
  ],
  providers: [
    FinalEnergyService,
    EnergyCarrierService,
    EnergyTrafficService,
    GreenhouseGasesService,
    EnergySectorsService,
    Co2CockpitKPIService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class Co2CockpitChartsModule {}
