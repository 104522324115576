import { Injectable } from "@angular/core";
import { EneSnackbarService } from "@energy-city/ui/snackbar";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, forkJoin, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { FactorManagementService } from "../../components/factor-management/factor-management.service";
import {
  FactorEmissionActionTypes,
  GetFactorEmissionData,
  GetFactorEmissionDataError,
  GetFactorEmissionDataSuccess,
  PatchFactorEmissionData
} from "./factor-emission.actions";
import { AccountingMethodService } from "@energy-city/components";

@Injectable()
export class FactorEmissionEffects {
  @Effect()
  public getFactorEmissionData$: Observable<Action> = this.actions$.pipe(
    ofType(FactorEmissionActionTypes.GET_FACTOR_EMISSION_DATA),
    switchMap((action: GetFactorEmissionData) =>
      forkJoin(action.endpoints.map((endpoint) => this.factorManagementService.getFactors(endpoint, action.regionId)))
    ),
    map((dataSets) => dataSets.filter(({ result }) => Boolean(result)).map(({ result }) => result)),

    map((response) => new GetFactorEmissionDataSuccess(response)),
    catchError(() => of(new GetFactorEmissionDataError("DATA_PANEL.LOAD_ERROR")))
  );

  @Effect()
  public patchFactorEmissionData$: Observable<Action> = this.actions$.pipe(
    ofType(FactorEmissionActionTypes.PATCH_FACTOR_EMISSION_DATA),
    switchMap(({ regionId, data }: PatchFactorEmissionData) =>
      forkJoin(
        data.map(({ endpointId, data }) => this.factorManagementService.patchFactors(endpointId, regionId, data))
      ).pipe(
        map((responses) => {
          const errors = responses
            .filter(
              (response: { isError: boolean; error: any }) => response && "isError" in response && response.isError
            )
            .map((response: any) => response.error);
          const success = errors.length === 0;
          return {
            success,
            errors,
            regionId,
            endpointIds: data.map(({ endpointId }) => endpointId)
          };
        })
      )
    ),
    map(({ regionId, endpointIds, success, errors }) => {
      if (success) {
        this.snackbar.success(this.translate.instant("DATA_PANEL.SNACKBAR.UPDATE_SUCCESS"));
        this.accountingMethodService.forceReload();
      } else {
        console.error("error while patching emission factors:", errors);
        this.snackbar.error(this.translate.instant("DATA_PANEL.SNACKBAR.UPDATE_ERROR"));
      }
      return new GetFactorEmissionData(regionId, endpointIds);
    })
  );

  constructor(
    private actions$: Actions,
    private factorManagementService: FactorManagementService,
    private accountingMethodService: AccountingMethodService,
    private snackbar: EneSnackbarService,
    private translate: TranslateService
  ) {}
}
