/* tslint:disable:member-ordering */
/* tslint:disable:member-access */

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  ChartBenchmarkingActionType,
  GetChartBenchmarkingDataSuccess,
  GetChartBenchmarkingKpiSuccess,
  ChartBenchmarkingError
} from "./benchmarking-actions";
import { ErrorAction } from "../../../common";

import { environment } from "../../../../../environments/environment";

@Injectable()
export class ChartBenchmarkingEffects {
  @Effect() ChartBenchmarkingData$ = this.actions$.pipe(
    ofType(ChartBenchmarkingActionType.GET_DATA),
    switchMap((action: any) => {
      const url = environment.co2DummyDataService + `/benchmark/${action.ags}/${action.year}`;
      const params = new HttpParams();
      return this.http.get(url, { params }).pipe(
        map((payload) => new GetChartBenchmarkingDataSuccess(this.removeEnvelope(payload))),
        catchError((error) => ErrorAction(action, error, ChartBenchmarkingError))
      );
    })
  );

  @Effect() ChartBenchmarkingKpi$ = this.actions$.pipe(
    ofType(ChartBenchmarkingActionType.GET_KPI),
    switchMap((action: any) => {
      const url = environment.co2DummyDataService + `/benchmark-kpi/${action.ags}/${action.year}`;
      const params = new HttpParams();
      return this.http.get(url, { params }).pipe(
        map((payload) => new GetChartBenchmarkingKpiSuccess(this.removeEnvelope(payload))),
        catchError((error) => ErrorAction(action, error, ChartBenchmarkingError))
      );
    })
  );

  private removeEnvelope(payload: Object) {
    return payload ? payload["result"] : null;
  }

  constructor(private http: HttpClient, private actions$: Actions) {}
}
