import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, share } from "rxjs/operators";
import { IRegionIdentifier } from "../../../../common/model/region-identifier";
import { ICo2EmissionSectorResultDto } from "../../../accounting-emission/data/dto/co2-emission-dto.interface";
import { EmissionDataService } from "../../../accounting-emission/data/emission-data.service";
import { ElectricityMix } from "../electricity-mix.enum";
import { WeatherCorrection } from "../weather-correction.enum";
import { ICo2EmissionSectorResult, MainGhgSector } from "./main-ghg-sector.interface";
import { Sector } from "./sector.enum";

export class StationaryEnergySector implements MainGhgSector {
  constructor(private emissionDataService: EmissionDataService) {}

  sector: Sector = Sector.STATIONARY_ENERGY;

  private $co2EmissionsForYear?: Observable<ICo2EmissionSectorResult>;
  private $totalCo2EmissionsForYear?: Observable<ICo2EmissionSectorResult>;
  private $totalCo2EmissionTimeSeries?: Observable<ICo2EmissionSectorResult>;

  getCo2EmissionsForYear(
    region: IRegionIdentifier,
    year: number,
    useUserFactors: boolean,
    weatherCorrection: WeatherCorrection,
    electricityMix: ElectricityMix
  ): Observable<ICo2EmissionSectorResult> {
    if (!this.$co2EmissionsForYear) {
      const params = this.getParams(region, useUserFactors, weatherCorrection, electricityMix, 3, year);
      this.$co2EmissionsForYear = this.emissionDataService
        .getEmissions("/emissions/co2/stationary-energy", params)
        .pipe(map(this.mapResult), share());
    }
    return this.$co2EmissionsForYear;
  }

  getTotalCo2EmissionsForYear(
    region: IRegionIdentifier,
    year: number,
    useUserFactors: boolean,
    weatherCorrection: WeatherCorrection,
    electricityMix: ElectricityMix
  ): Observable<ICo2EmissionSectorResult> {
    if (!this.$totalCo2EmissionsForYear) {
      const params = this.getParams(region, useUserFactors, weatherCorrection, electricityMix, 0, year);
      this.$totalCo2EmissionsForYear = this.emissionDataService
        .getEmissions("/emissions/co2/stationary-energy", params)
        .pipe(map(this.mapResult), share());
    }
    return this.$totalCo2EmissionsForYear;
  }

  getTotalCo2EmissionTimeSeries(
    region: IRegionIdentifier,
    useUserFactors: boolean,
    weatherCorrection: WeatherCorrection,
    electricityMix: ElectricityMix
  ): Observable<ICo2EmissionSectorResult> {
    if (!this.$totalCo2EmissionTimeSeries) {
      const params = this.getParams(region, useUserFactors, weatherCorrection, electricityMix, 0);
      this.$totalCo2EmissionTimeSeries = this.emissionDataService
        .getEmissions("/emissions/co2/stationary-energy", params)
        .pipe(map(this.mapResult), share());
    }
    return this.$totalCo2EmissionTimeSeries;
  }

  private isWeatherCorrectionOn(weatherCorrection: WeatherCorrection): boolean {
    return weatherCorrection === WeatherCorrection.ON;
  }

  private isLocalMix(electricityMix: ElectricityMix): boolean {
    return electricityMix === ElectricityMix.REGION_SPECIFIC;
  }

  private getParams(
    region: IRegionIdentifier,
    useUserFactors: boolean,
    weatherCorrection: WeatherCorrection,
    electricityMix: ElectricityMix,
    depth: number,
    year?: number
  ): HttpParams {
    return new HttpParams({
      fromObject: {
        regionId: region.regionId,
        regionType: region.regionType,
        depth,
        energyType: "final",
        useUserFactors,
        weatherCorrection: this.isWeatherCorrectionOn(weatherCorrection),
        localMix: this.isLocalMix(electricityMix),
        ...(year ? { year } : {})
      }
    });
  }

  private mapResult(result: ICo2EmissionSectorResultDto): ICo2EmissionSectorResult {
    return {
      sector: Sector.STATIONARY_ENERGY,
      ...result
    };
  }
}
