import { CategoryFilter, CategoryFilterItem } from "@enersis/gp-components/gp-map2-filter-control";
import { LayerControlSection } from "@enersis/gp-components/gp-map2-layer-control";
import { IGpAnyLayerInput, IGpViewerSource } from "@enersis/gp-components/gp-map2-viewer";
import { AccountingMethod } from "../../accounting/accounting-common/model/accounting-methods/accounting-method.enum";
import { ElectricityMix } from "../../accounting/accounting-common/model/electricity-mix.enum";
import { Sector } from "../../accounting/accounting-common/model/sectors/sector.enum";
import { WeatherCorrection } from "../../accounting/accounting-common/model/weather-correction.enum";
import {
  IEmissionLayerColorsDto,
  IRegionLayerConfigDto,
  ITenantMapConfigDto,
  IUnionLayerConfigDto
} from "../../common/config/env.model";
import { ColorRange } from "./color-range";
import { RegionLayerConfig } from "./region-layer-config";
import { UnionLayerConfig } from "./union-layer-config";

const DEFAULT_REGION_LAYER_CONFIG: Array<IRegionLayerConfigDto | IUnionLayerConfigDto> = [
  {
    level: "20",
    minZoom: 3,
    maxZoom: 5.5,
    minCo2EmissionsTons: 0,
    maxCo2EmissionsTons: 90000000
  },
  {
    level: "40",
    minZoom: 5.5,
    maxZoom: 7.5,
    minCo2EmissionsTons: 0,
    maxCo2EmissionsTons: 9000000
  },
  {
    level: "50",
    minZoom: 7.5,
    maxZoom: 9.5,
    minCo2EmissionsTons: 0,
    maxCo2EmissionsTons: 900000
  },

  {
    level: "60",
    minZoom: 9.5,
    maxZoom: 12,
    minCo2EmissionsTons: 0,
    maxCo2EmissionsTons: 90000
  },
  {
    level: "union",
    minZoom: 9.5,
    maxZoom: 11
  }
];
const DEFAULT_EMISSON_COLORS: IEmissionLayerColorsDto = {
  colorSteps: ["#D1E1EC", "#5D95BF", "#264965"],
  domain: [0, 0.4375, 0.9219]
};

export class TenantMapConfig {
  public readonly regionLayers: Array<RegionLayerConfig | UnionLayerConfig>;
  public readonly buildingYear?: number;

  constructor(tenantMapConfig?: ITenantMapConfigDto) {
    this.buildingYear = tenantMapConfig?.buildingYear;
    const layers = tenantMapConfig?.regionLayers || DEFAULT_REGION_LAYER_CONFIG;
    this.regionLayers = layers.map((layer) => {
      if (layer.level === "union") {
        return new UnionLayerConfig(layer.level, layer.minZoom, layer.maxZoom);
      }
      const regionLayers = layer as IRegionLayerConfigDto;
      const emissionLayerColors = tenantMapConfig?.emissionLayerColors || DEFAULT_EMISSON_COLORS;
      return new RegionLayerConfig(
        regionLayers.level,
        regionLayers.minZoom,
        regionLayers.maxZoom,
        regionLayers.minCo2EmissionsTons,
        regionLayers.maxCo2EmissionsTons,
        tenantMapConfig?.minCo2EmissionsTonsPerCapita || 0,
        tenantMapConfig?.maxCo2EmissionsTonsPerCapita || 25,
        new ColorRange(emissionLayerColors.colorSteps, emissionLayerColors.domain)
      );
    });
  }

  getRegionSource(
    baseUrl: string,
    year: number,
    accountingMethod: AccountingMethod,
    weatherCorrection: WeatherCorrection,
    electricityMix: ElectricityMix,
    useUserFactors: boolean,
    timestamp: number
  ): IGpViewerSource {
    return {
      type: "vector",
      minzoom: this.getMinRegionZoom(),
      maxzoom: this.getMaxRegionZoom(),
      promoteId: "region_id",
      tiles: [`${baseUrl}/emissions/tile/{z}/{x}/{y}`],
      urlParams: {
        year: year,
        levels: this.getRegionLevels(),
        accountingMethod: accountingMethod,
        weatherCorrection: weatherCorrection === WeatherCorrection.ON ? true : false,
        localMix: electricityMix === ElectricityMix.REGION_SPECIFIC ? true : false,
        useUserFactors,
        timestamp
      }
    };
  }

  getLayerConfigs(): Array<IGpAnyLayerInput> {
    return [
      ...this.regionLayers.map((config) => config.getLayerConfig()),
      ...this.regionLayers
        .filter((config) => config instanceof RegionLayerConfig)
        .map((config) => (config as RegionLayerConfig).getBorderLayerConfig())
    ];
  }

  getLayerControlConfig(): LayerControlSection {
    return {
      type: "section",
      id: "co2-section",
      label: "MAP2.LAYERS.REGIONS.THEMATICS.SECTION.CO2_BUDGET",
      activityControlType: "switch",
      expanded: true,
      childrenType: "radio",
      children: [
        this.getRegionLayerIds(),
        {
          type: "filter",
          filterIds: ["co2_emissions_sector"]
        }
      ]
    };
  }

  getUnselectableRegionFilter(): CategoryFilter {
    return {
      id: "has_value_filter",
      type: "category",
      affectedLayerIds: this.getRegionLayerIds(),
      input: ["case", ["has", "co2_stationary-energy"], 1, 0],
      values: [
        {
          value: 1,
          active: true
        }
      ]
    };
  }

  getSectorFilter(sectors: Array<Sector>): CategoryFilter {
    return {
      id: "co2_emissions_sector",
      affectedLayerIds: this.getRegionLayerIds(),
      type: "category",
      compareBy: "hasProperty",
      styleAggregation: "sum",
      disableFeatureFiltering: true,
      values: sectors.map((sector) => this.mapSectors2filterValues(sector)),
      label: "MAP2.LAYERS.REGIONS.THEMATICS.SECTION.FILTER",
      listItemDirection: "column",
      // @ts-ignore
      expanded: true
    };
  }

  getRegionLevels(): Array<string> {
    return this.regionLayers.map((regionLayer) => regionLayer.level);
  }

  isBuildingYearFix(): boolean {
    return Boolean(this.buildingYear);
  }

  private getMinRegionZoom(): number {
    return Math.min(...this.regionLayers.map((config) => config.minZoom));
  }

  private getMaxRegionZoom(): number {
    return Math.max(...this.regionLayers.map((config) => config.maxZoom));
  }

  private getRegionLayerIds(): Array<string> {
    return this.regionLayers
      .filter((config) => config instanceof RegionLayerConfig)
      .map((region) => region.getLayerId());
  }

  private mapSectors2filterValues(sector: Sector): CategoryFilterItem {
    switch (sector) {
      case Sector.STATIONARY_ENERGY:
        return {
          value: "co2_stationary-energy",
          label: "RESOURCES.STATIONARYENERGY",
          active: true
        };
      case Sector.TRANSPORTATION:
        return {
          value: "co2_transportation",
          label: "RESOURCES.TRANSPORTATION",
          active: true
        };
      case Sector.AFOLU:
        return {
          value: "co2_afolu",
          label: "RESOURCES.AFOLU",
          active: true
        };
      case Sector.WASTE_AND_WASTE_WATER:
        return {
          value: "co2_waste",
          label: "RESOURCES.WASTEANDWASTEWATER",
          active: true
        };
      case Sector.IPPU:
        return {
          value: "co2_ippu",
          label: "RESOURCES.IPPU",
          active: true
        };
      default:
        throw Error("Unsupported sector `${sector}");
    }
  }
}
