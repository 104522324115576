// tslint:disable:directive-selector
import { Directive, Output, EventEmitter } from "@angular/core";

@Directive({
  selector: "[ene-footer-center]",
  exportAs: "eneFooterCenterDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-footer-center"
  }
})
export class EneFooterCenterDirective {
  @Output()
  public onclick = new EventEmitter<boolean>();

  public onClick(event: any) {
    this.onclick.emit(event);
  }
}
