import { InjectionToken } from "@angular/core";
import { ActionReducerMap, combineReducers, USER_PROVIDED_META_REDUCERS } from "@ngrx/store";

import * as fromApp from "./app/index";
import * as fromReset from "./reset/index";
import * as fromTour from "./tour/index";
import * as fromAuth from "./auth/index";
import * as fromDistrict from "./district/index";
import * as fromNetwork from "./network/index";
import * as formGasCockpit from "./gas-cockpit";
import * as fromExport from "./export/index";
import * as fromTimeline from "./timeline/index";
import * as fromChartCo2EmissionSectors from "./charts/co2-cockpit/emission-sectors/index";
import * as fromContact from "./contact/index";
import * as fromNewContact from "./contact-new/contact.reducer";
import * as fromFinalEnergy from "./charts/co2-cockpit/final-energy/index";
import * as fromLocalEnergyMix from "./charts/co2-cockpit/local-energy-mix/index";
import * as fromCo2 from "./co2-cockpit/index";
import * as fromSideNavigation from "./sidenavigation/index";
import * as fromSimulation from "./simulation/simulation-reducer";
import * as fromMeasures from "./measures";
import * as fromSets from "./sets";
import * as fromDatabaseChart from "./charts/co2-cockpit/database-chart";
import * as fromCommon from "./common";
import * as fromBenchmarking from "./charts/co2-cockpit/benchmarking/index";
import * as fromBenchmark from "./charts/benchmark";
import * as fromMainPolluter from "./charts/main-polluter";
import * as fromGlossar from "./glossar";
import { IEnergyState } from "./energy";
import { reducer as energyReducer } from "./energy";
import { reducer as configReducer } from "./config";
import { IConfigState } from "./config";
import { IAppState } from "./app/index";

import storeAnalytics from "../analytics";

export * from "./app/app-effects";
export * from "./tour/tour-effects";
export * from "./auth/auth-effects";
export * from "./district/district-effects";
export * from "./network/network-effects";
export * from "./gas-cockpit";
export * from "./charts/co2-cockpit/emission-sectors/emission-sectors-effects";
export * from "./contact/contact-effects";
export * from "./contact-new/contact.effects";
export * from "./charts/co2-cockpit/local-energy-mix/local-energy-mix-effects";
export * from "./co2-cockpit/co2-cockpit-effects";
export * from "./sidenavigation/sidenavigation-effects";
export * from "./simulation/simulation-effects";
export * from "./measures/measures.effects";
export * from "./sets/sets.effects";
export * from "./charts/co2-cockpit/database-chart/database-chart.effects";
export * from "./charts/co2-cockpit/benchmarking/benchmarking-effects";
export * from "./charts/benchmark/benchmark-effects";
export * from "./charts/main-polluter/main-polluter.effects";
export * from "./router/router-effects";
export * from "./energy/publicSector/consumption/consumption-effects";
export * from "./energy/publicSector/co2-saving/fixed-year/index";
export * from "./energy/publicSector/co2-saving/all-years/index";
export * from "./energy/publicSector/connection-group/e-mobility/e-mobility-effects";
export * from "./energy/publicSector/connection-group/heatpump/heatpump-effects";
export * from "./config/accounting-methods/get/get-accounting-methods-config-effects";
export * from "./config/accounting-methods/put/put-accounting-methods-config-effects";

export * from "./app/app-actions";
export * from "./tour/tour-actions";
export * from "./auth/auth-actions";
export * from "./district/district-actions";
export * from "./network/network-actions";
export * from "./gas-cockpit/network/network-actions";
export * from "./gas-cockpit/kpi/kpi-actions";
export * from "./charts/co2-cockpit/emission-sectors/emission-sectors-actions";
export * from "./contact/contact-actions";
export * from "./contact-new/contact.actions";
export * from "./charts/co2-cockpit/local-energy-mix/local-energy-mix-actions";
export * from "./co2-cockpit/co2-cockpit-actions";
export * from "./sidenavigation/sidenavigation-actions";
export * from "./simulation/simulation-actions";
export * from "./measures/measures.actions";
export * from "./sets/sets.actions";
export * from "./charts/co2-cockpit/database-chart/database-chart.actions";
export * from "./charts/co2-cockpit/benchmarking/benchmarking-actions";
export * from "./charts/benchmark/benchmark-actions";
export * from "./charts/main-polluter/main-polluter.actions";
export * from "./router/router-actions";
export * from "./charts/co2-cockpit/final-energy/final-energy-actions";
export * from "./energy/publicSector/consumption/consumption-actions";
export * from "./energy/publicSector/co2-saving/fixed-year/index";
export * from "./energy/publicSector/co2-saving/all-years/index";
export * from "./energy/publicSector/connection-group/e-mobility/e-mobility-actions";
export * from "./energy/publicSector/connection-group/heatpump/heatpump-actions";
export * from "./config/accounting-methods/get/get-accounting-methods-config-actions";
export * from "./config/accounting-methods/put/put-accounting-methods-config-actions";
export * from "./router/router-actions";
export * from "./timeline/timeline-actions";
export * from "./reset/reset-actions";
export * from "./tracking/tracking-actions";
export * from "./export/export-actions";

export * from "./charts/benchmark/benchmark-selectors";
export * from "./charts/co2-cockpit/database-chart/database-chart.selectors";
export * from "./contact-new/contact.selectors";
export * from "./measures/measures.selectors";
export * from "./sets/sets.selectors";
export * from "./simulation/simulation-selectors";

export * from "./app/index";
export * from "./co2-cockpit/index";
export * from "./charts/main-polluter/index";
export * from "./common";

export interface IApplicationState {
  app: {
    app: fromApp.IAppState;
    reset: fromReset.IResetState;
    tour: fromTour.ITourState;
    auth: fromAuth.IAuthState;
    district: fromDistrict.IDistrictState;
    energy: IEnergyState;
    config: IConfigState;
    network: fromNetwork.INetworkState;
    gasCockpit: formGasCockpit.State;
    export: fromExport.IExportState;
    timeline: fromTimeline.ITimelineState;
    finalEnergySectors: fromFinalEnergy.IFinalEnergy;
    localEnergyMix: fromLocalEnergyMix.ILocalEnergyMix;
    // co2Sectors: fromCo2Sectors.ICo2Sectors,
    sidenavigation: fromSideNavigation.ISideNavigationState;
    simulation: fromSimulation.ISimulationState;
    co2: fromCo2.ICo2;
    ChartCo2EmissionSectors: fromChartCo2EmissionSectors.IChartCo2EmissionSectors;
    contact: fromContact.IContactState;
    newContact: fromNewContact.ContactState;
    fromBenchmarking: fromBenchmarking.IChartBenchmarking;
    benchmark: fromBenchmark.IBenchmarkState;
    mainPolluter: fromMainPolluter.MainPolluterState;
    measures: fromMeasures.MeasuresState;
    sets: fromSets.SetsState;
    databaseChart: fromDatabaseChart.DatabaseChartState;
    glossar: fromGlossar.State;
  };
}

export const reducers = combineReducers({
  app: fromApp.appReducers.app,
  reset: fromReset.resetReducers.reset,
  tour: fromTour.tourReducers.tour,
  auth: fromAuth.authReducers.auth,
  district: fromDistrict.districtReducers.district,
  energy: energyReducer,
  config: configReducer,
  network: fromNetwork.networkReducers.network,
  gasCockpit: formGasCockpit.gasCockpitRedusers,
  export: fromExport.exportReducers.export,
  timeline: fromTimeline.timelineReducers.timeline,
  finalEnergy: fromFinalEnergy.FinalEnergyReducers.finalEnergy,
  localEnergyMix: fromLocalEnergyMix.LocalEnergyMixReducers.localEnergyMix,
  // co2Sectors: fromCo2Sectors.co2SectorsReducers.co2Sectors,
  sidenavigation: fromSideNavigation.sidenavigationReducers.sidenavigation,
  simulation: fromSimulation.reducer,
  co2: fromCo2.co2Reducers.co2,
  ChartCo2EmissionSectors: fromChartCo2EmissionSectors.ChartCo2EmissionSectorsReducers.fromChartCo2EmissionSectors,
  common: fromCommon.commonReducer,
  contact: fromContact.contactReducers.contact,
  newContact: fromNewContact.reducer,
  benchmarking: fromBenchmarking.ChartBenchmarkingReducers.fromChartBenchmarking,
  benchmark: fromBenchmark.reducer,
  mainPolluter: fromMainPolluter.reducer,
  measures: fromMeasures.reducer,
  sets: fromSets.reducer,
  databaseChart: fromDatabaseChart.reducer,
  glossar: fromGlossar.glossarReducers.glossar
});

export function getReducers() {
  return {
    app: reducers
  };
}

export function getMetaReducers() {
  return [storeAnalytics];
}

export const reducerToken = new InjectionToken<ActionReducerMap<IApplicationState>>("Reducers");

export const reducerProvider = [
  { provide: reducerToken, useFactory: getReducers },
  { provide: USER_PROVIDED_META_REDUCERS, useFactory: getMetaReducers }
];

export const GetAppState = (state: IApplicationState): IAppState => state && state.app && state.app.app;
export const GetResetState = (state: any) => state.app.reset;
export const GetTourState = (state: any) => state.app.tour;
export const GetAuthState = (state: IApplicationState) => state.app.auth;
export const GetDistrictState = (state: IApplicationState) => state.app.district;
export const GetBuildingState = (state: any) => state.app.building;
export const GetNetworkState = (state: IApplicationState) => state.app.network;
export const GetExportState = (state: IApplicationState) => state.app.export;
export const GetTimelineState = (state: any) => state.app.timeline;
export const GetScenarioState = (state: any) => state.app.scenario;
export const GetSelfSufficiencyState = (state: any) => state.app.selfSufficiency;
export const GetFinalEnergyState = (state: any) => state.app.finalEnergy;
export const GetLocalEnergyMixState = (state: any) => state.app.localEnergyMix;
export const GetCo2SectorsState = (state: any) => state.app.co2Sectors;
export const GetStationaryEnergyState = (state: any) => state.app.stationaryEnergy;
export const GetSideNavigationState = (state: any) => state.app.sidenavigation;
export const GetCo2State = (state: any) => state.app.co2;
export const GetCo2CockpitChartsState = (state: any) => state.app.co2CockpitCharts;
export const GetChartCo2EmissionSectorsState = (state: any) => state.app.ChartCo2EmissionSectors;
export const GetChartCo2EmissionTrendState = (state: any) => state.app.ChartCo2EmissionTrend;
export const GetAdminAreaDataState = (state: any) => state.app.adminAreaData;
export const GetAdminAreaClassificationState = (state: any) => state.app.adminAreaClassification;
export const GetContactState = (state: any) => state.app.contact;
export const GetBenchmarkingState = (state: any) => state.app.benchmarking;
export const GetMeasuresState = (state: IApplicationState) => state.app.measures;
export const GetGlossarState = (state: any) => state.app.glossar;

export type TRoot =
  | "GetAppState"
  | "GetResetState"
  | "GetTourState"
  | "GetMapState"
  | "GetAuthState"
  | "GetDistrictState"
  | "GetBuildingState"
  | "GetRegionState"
  | "GetNetworkState"
  | "GetExportState"
  | "GetTimelineState"
  | "GetScenarioState"
  | "GetSelfSufficiencyState"
  | "ChartCo2GreenhouseGasesState"
  | "GetFinalEnergyState"
  | "GetLocalEnergyMixState"
  | "GetCo2SectorsState"
  | "GetStationaryEnergyState"
  | "GetSideNavigationState"
  | "GetCo2"
  | "GetCo2CockpitChartsState"
  | "GetChartCo2EmissionSectorsState"
  | "GetChartCo2EmissionTrendState"
  | "GetAdminAreaDataState"
  | "GetAdminAreaClassificationState"
  | "GetContactState"
  | "GetBenchmarkingState";
