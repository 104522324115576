import { trackEvent } from "@redux-beacon/google-analytics";

export const mapSelection = trackEvent((action: any) => ({
  category: "map",
  action: "selection",
  label: action.payload.level
}));

// IMPORTANT: this is currently only used for stations, bc of that we can just extend
export const layerSelection = trackEvent((action: any) => ({
  category: "map",
  action: "selection",
  label: action.payload.layer + " STATION"
}));
