import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { EnergyType } from "../../models/charts.models";
import { AccountingMethodService } from "../accounting-method.service";
import { IRegionIdentifier } from "../util.service";
import { IEnergySector, IEnergySectorResult, IEnergySectorRoot } from "./interfaces/region-property-energy.interface";

@Injectable({
  providedIn: "root"
})
export class RegionPropertyEnergyService {
  constructor(private http: HttpClient, private accountingMethodService: AccountingMethodService) {}

  public getEnergySectorData(
    { regionId, regionType }: IRegionIdentifier,
    sectors: Array<IEnergySector>,
    year: number
  ): Observable<Array<IEnergySectorResult>> {
    const requests: Array<Observable<any>> = [];

    const useUserFactors = this.accountingMethodService.useUserFactors$.getValue() ? { useUserFactors: "true" } : {};

    for (const sector of sectors) {
      const params = new HttpParams({
        fromObject: {
          regionId,
          regionType,
          year: String(year),
          weatherCorrection: String(sector.weatherCorrection),
          depth: String(sector.level),
          energyType: sector.energyType,
          localMix: String(sector.localMix),
          ...useUserFactors
        }
      });

      requests.push(
        this.http
          .get<any>(sector.url(), { params })
          .pipe(
            map(({ result }: any) => ({ sector, result } as IEnergySectorResult)),
            catchError(({ status }) => (status === 404 ? of({ sector, result: [] }) : EMPTY))
          )
      );
    }
    return forkJoin(requests);
  }

  public getEnergyTransportationData(
    { regionId, regionType }: IRegionIdentifier,
    year: number
  ): Observable<Array<IEnergySectorRoot>> {
    const url = `${environment.regionPropertyApi}/energy/transportation`;
    const params = new HttpParams({
      fromObject: {
        regionId,
        regionType,
        depth: "2",
        year: String(year),
        energyType: EnergyType.FINAL
      }
    });

    return this.http
      .get<any>(url, { params })
      .pipe(
        map(({ result }: any) => result),
        catchError(({ status }) => (status === 404 ? of([]) : EMPTY))
      );
  }
}
