import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { Subject } from "rxjs";
import { EneChartComponent } from "@energy-city/ui/chart";
import { TranslateService } from "@ngx-translate/core";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { AccountingMethodService } from "../../../../../services/accounting-method.service";
import { SimulationService } from "../../../../../services/simulation/simulation.service";
import { RegionPropertyEmissionService } from "../../../../../services/region-property/region-property-emission.service";
import { UtilService } from "../../../../../services/util.service";
import { ICo2EmissionSectorResult } from "../sectors/chart-co2-sectors.service";
import { sectorConfig } from "../../../../../models/co2-sectors-config.model";
import { ISimulationScenarioValue } from "@energy-city/components";
export interface IChartDataValue {
  data: number[][];
  forecast: boolean;
  goalText: string;
  year: number;
}
@Component({
  selector: "app-chart-greenhouse-gases-trend",
  templateUrl: "./chart-greenhouse-gases-trend.component.html",
  styleUrls: ["./chart-greenhouse-gases-trend.component.scss"]
})
export class ChartGreenhouseGasesTrendComponent implements OnInit, OnDestroy {
  public chartData: any;
  public chartConfig = {
    chart: {
      unit: " [t]",
      type: "area",
      events: {
        load: this.drawThreshold(),
        redraw: this.drawThreshold()
      },
      marginTop: 20
    },
    xAxis: {
      crosshair: true
    },
    yAxis: {
      title: {
        useHTML: true,
        text: "t CO<sub>2</sub>e"
      },
      labels: {
        formatter: this.labelFormatter()
      }
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: this.tooltipFormatter()
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        },
        dashStyle: "LongDash"
      },
      area: {
        stacking: "normal",
        lineWidth: 2,
        fillOpacity: 0.7,
        marker: {
          lineWidth: 1,
          lineColor: "#666666"
        }
      }
    }
  };

  @ViewChild("chart", { static: true }) public chart: EneChartComponent;

  private destroy$ = new Subject();
  private scenarioGoals: ISimulationScenarioValue[] = [];
  constructor(
    public utilService: UtilService,
    public translate: TranslateService,
    private simulationService: SimulationService,
    private decimalPipe: DecimalPipe,
    private emissionService: RegionPropertyEmissionService,
    private accService: AccountingMethodService
  ) {}

  public async ngOnInit() {
    try {
      const result = await this.simulationService.getReportScenario().toPromise();
      this.scenarioGoals = result.result.values;
    } catch (e) {}
    const sectorQuery = sectorConfig.sectors
      .filter((sector) => sector.methods.includes(this.accService.selectedAccountingMethod$.value))
      .map((sector) => ({
        ...sector,
        level: 0
      }));

    this.utilService.regionIdentifier$
      .pipe(
        switchMap((regionIdentifier) =>
          this.emissionService
            .getCo2EmissionSectorData(regionIdentifier, sectorQuery)
            .pipe(
              map((emissionsSectors) => emissionsSectors.filter((sectorResult) => Boolean(sectorResult.result.length)))
            )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((emissionsSectors) => this.updateChart(emissionsSectors));

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.chart.redrawChart());
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
  private getRatio(co2EmissionFrom1990: number, goal: number): number {
    return co2EmissionFrom1990 - goal * co2EmissionFrom1990;
  }
  private updateChart(emissionsSectors: Array<ICo2EmissionSectorResult>): void {
    const co2EmissionFrom1990 = emissionsSectors.reduce((acc, emission) => acc + emission.result[0].value, 0);
    const interpolated = emissionsSectors.reduce((chartData, emissions) => {
      const data = emissions.result.map((emission) => [emission.year, emission.value]);
      const translationKey = `COCKPIT.CO2.TABS.TREND.LEGEND.${emissions.sector.type}`;

      const sector = {
        name: emissions.sector.type,
        title: this.translate.instant(translationKey),
        translationKey,
        color: emissions.sector.color,
        showInLegend: true,
        data
      };

      return [...chartData, sector];
    }, []);
    const threshold: IChartDataValue[] = [];
    this.scenarioGoals.forEach((value) => {
      if (value.year < new Date().getFullYear()) {
        return;
      }
      threshold.push(<IChartDataValue>{
        forecast: true,
        goalText: (value.goal * 100).toString() + "%",
        year: value.year,
        data: [[value.year, this.getRatio(co2EmissionFrom1990, value.goal)]]
      });
    });
    threshold.map((item) => ({
      ...item,
      showInLegend: false,
      color: "#7EC4F7",
      marker: {
        enabled: true,
        symbol: "circle",
        lineWidth: 2,
        lineColor: "#7EC4F7",
        fillColor: "var(--ene-main-white)",
        radius: 4
      }
    }));
    this.chartData = [...threshold, ...interpolated];
  }

  private tooltipFormatter() {
    const format = this.decimalPipe.transform;
    const that = this;

    return function () {
      const reductionTranslatedString = that.translate.instant("COCKPIT.REDUCTION");
      const totalTranslatedString = that.translate.instant("COCKPIT.TOTAL");
      const locale = that.translate.currentLang;
      const year = Math.round(this.point.x);
      if (this.series.userOptions.forecast) {
        return `<b>${this.series.userOptions.year}</b><br><hr/>${reductionTranslatedString}: ${this.series.userOptions.goalText}`;
      }
      return `<b>${year}</b><br><hr/>
          ${that.translate.instant(this.series.userOptions.translationKey)}: ${format(
        this.point.y,
        "1.0-0",
        locale
      )} t CO<sub>2</sub>e (${format((100 / this.point.stackTotal) * this.point.y, "1.1-1", locale)} %)<br>
          ${totalTranslatedString}: ${format(this.point.stackTotal, "1.0-0", locale)} t CO<sub>2</sub>e`;
    };
  }

  private drawThreshold() {
    return function () {
      const xAxis = this.xAxis[0],
        yAxis = this.yAxis[0],
        yValue = yAxis.toPixels(0),
        yValueMax = yAxis.height;

      if (this.__tresholdElementCollection !== undefined && this.__tresholdElementCollection.length > 0) {
        for (let i = this.__tresholdElementCollection.length - 1; i >= 0; --i) {
          this.__tresholdElementCollection[i].destroy();
          this.__tresholdElementCollection.splice(i, 1);
        }
      } else {
        this.__tresholdElementCollection = [];
      }

      this.series.forEach((thresholdSeries) => {
        if (thresholdSeries.userOptions.forecast) {
          const xValue = xAxis.toPixels(thresholdSeries.xData[0]),
            yData = yAxis.toPixels(thresholdSeries.yData[0]);

          this.__tresholdElementCollection.push(
            this.renderer
              .path(["M", xValue, yValue, "L", xValue, yData])
              .attr({
                "stroke-width": 2,
                stroke: "#7EC4F7",
                dashstyle: "dash"
              })
              .add()
          );
          // render circle at the bottom
          this.__tresholdElementCollection.push(
            this.renderer
              .circle(xValue, yValueMax + 10, 5)
              .attr({
                useHTML: true,
                "stroke-width": 3,
                fill: "var(--ene-main-white)",
                stroke: "#7EC4F7",
                zIndex: 999
              })
              .add()
          );
          this.__tresholdElementCollection.push(
            this.renderer
              .circle(xValue, yData, 5)
              .attr({
                useHTML: true,
                "stroke-width": 3,
                fill: "var(--ene-main-white)",
                stroke: "#7EC4F7",
                zIndex: 999
              })
              .add()
          );
        }
      });
    };
  }

  private labelFormatter() {
    const format = this.decimalPipe.transform;
    const that = this;

    return function () {
      return format(this.value, "1.0-0", that.translate.currentLang);
    };
  }
}
