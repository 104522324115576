<h3 [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.CLIMATEGOALS.TITLE') | translate"></h3>
<p [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.CLIMATEGOALS.PARAGRAPH_1') | translate"></p>
<p class="info-padded-box"><img [src]="'../../../../../../assets/images/info/climate/default.png'" alt="" /></p>
<p
  [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.CLIMATEGOALS.PARAGRAPH_2') | translate"
  class="info-padded-box"
></p>
<p
  [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.CLIMATEGOALS.PARAGRAPH_3') | translate"
  class="info-padded-box"
></p>
<p
  [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.CLIMATEGOALS.PARAGRAPH_4') | translate"
  class="info-padded-box"
></p>
<p class="button">
  <button class="ene-button-pill-primary" (click)="goToCockpit()">
    <ene-icon icon="ec_side_cockpit"></ene-icon>
    {{ getTenantSpecificTranslation("INFO.DETAILS.BACKGROUND.CLIMATEGOALS.GOTOCOCKPIT") | translate }}
  </button>
</p>
