import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { Http } from "../../types/httpMethod";

import { UtilService } from "./util.service";

import { environment } from "../../environments/environment";
import { IEmailRequest } from "../core/interfaces/email-request.interface";
import { IMapRequest } from "../core/interfaces/map.interface";
import { ContactType } from "../state/contact-new/contact.actions";
import { acceptJsonWithoutCache } from "../state/common";
import { AuthenticationService } from "../modules/authentication/services/exported/authentication.service";

export type ResponseType = "arraybuffer" | "blob" | "json" | "text";

@Injectable({
  providedIn: "root"
})
export class DataService {
  constructor(private http: HttpClient, private utilService: UtilService, private authService: AuthenticationService) {}

  /*  public sendBuildingServiceRequest(methodName: string, requestPayload: any, httpMethod: Http): Observable<Object> {
     const url = environment.buildingServiceApi + methodName;
     return this.serviceRequest(url, requestPayload, httpMethod);
   } */

  public sendMapRequest(endpointUrl: string, requestPayload: IMapRequest): Observable<Object> {
    let url = `${endpointUrl}/${requestPayload.tile.z}/${requestPayload.tile.x}/${requestPayload.tile.y}`;
    const possibleParameters = {
      ags: this.authService.tokenParsed.ags,
      year: this.utilService.getSelectedYear(),
      scenarioId: this.utilService.scenarioId,
      regionId: this.utilService.getRegionId()
    };

    // add standard Parameters to query
    const filters = [...(requestPayload.filters || [])];
    if (requestPayload.standardParams) {
      for (const standardParam of requestPayload.standardParams) {
        filters.push({ name: standardParam, value: possibleParameters[standardParam] });
      }
    }
    if (filters.length) {
      url += "?" + filters.map((e) => `${e.name}=${e.value}`).join("&");
    }
    return this.http.get(url, requestPayload as any);
  }

  public sendNetworkServiceRequest(methodName: string, requestPayload: any, httpMethod: Http): Observable<Object> {
    const url = environment.networkServiceApi + methodName;
    return this.serviceRequest(url, requestPayload, httpMethod);
  }

  public sendEmailToEdgeService(requestPayload: IEmailRequest, contactType: ContactType): Observable<Object> {
    let url = environment.emailEdgeService + "/public/support";

    if (contactType === ContactType.FEEDBACK) {
      url = environment.emailEdgeService + "/public/feedback";
    }
    return this.http.post(url, requestPayload);
  }

  /**
   *
   * @param url baseurl to send stuff to
   * @param requestPayload the payload which shall be submitted
   * @param parameters an array of parameteres to append to the url. wil be processed in the provided order. can be "ags", "year", "standard", "scenario"
   * @param suffix will be attached to the end of the url
   */
  public sendWithGetParameters(
    url: string,
    requestPayload: any = {},
    parameters: any[],
    suffix?: string
  ): Observable<Object> {
    const possibleParameters = {
      ags: requestPayload.ags || this.authService.tokenParsed.ags,
      year: requestPayload.year || this.utilService.getSelectedYear(),
      scenario: this.utilService.selectedScenario
    };

    let addendum: string = "";
    parameters.forEach((param) => {
      if (possibleParameters.hasOwnProperty(param)) {
        addendum += "/" + possibleParameters[param];
      }
    });

    // add suffix if provided
    addendum = typeof suffix === "string" && suffix !== "" ? addendum + suffix : addendum;
    return this.http.get(url + addendum, requestPayload);
  }

  /**
   *
   * @param url baseurl to send stuff to
   * @param queryParams Array of key, value, where boath will be check if the are null
   */
  public sendWithGetQueries(url: string, queryParams: [string, string | number | boolean][] = []): Observable<any> {
    let params = new HttpParams();
    for (const queryParam of queryParams) {
      if (!queryParam[0] || queryParam[1] == null) {
        continue;
      }
      params = params.set(queryParam[0], String(queryParam[1]));
    }
    return this.http.get<any>(url, { headers: new HttpHeaders(acceptJsonWithoutCache), params });
  }

  public sendConfigServiceRequest(
    methodName: string,
    requestPayload: any,
    httpMethod: Http,
    { responseType = "json" }: { responseType?: ResponseType } = {}
  ): Observable<Object> {
    let params = new HttpParams();
    const url = environment.configServiceApi + methodName;
    const flatPayload = this.utilService.flatParser(requestPayload);
    for (const key in flatPayload) {
      // skip loop if the property is from prototype
      if (!flatPayload.hasOwnProperty(key)) {
        continue;
      }
      params = params.set(key, flatPayload[key]);
    }
    return this.http.get(url, { responseType, params } as any);
  }

  public serviceRequest(url: string, requestPayload: any, httpMethod: Http): Observable<Object> {
    const preparedPayload = this.preparePayload(requestPayload);
    const noCacheHeaders = new HttpHeaders({
      Pragma: "no-cache",
      "Cache-Control": "no-cache"
    });
    let params = new HttpParams();
    if (httpMethod === Http.GET || httpMethod === Http.DELETE) {
      const flatPayload = this.utilService.flatParser(preparedPayload);

      for (const key in flatPayload) {
        // skip loop if the property is from prototype
        if (!flatPayload.hasOwnProperty(key)) {
          continue;
        }
        params = params.set(key, flatPayload[key]);
      }
    }
    // greenited data view service does not accept get parameters "ags, level, year, scenario"
    // instead it expects "ags, year, agsLevel" - for now the parameters are HARD CODED in the ngrx effects
    // TODO KER: change it
    // tslint:disable:max-line-length
    if (url.indexOf("https://greenited-dev.enersis.services/data-view/") > -1) {
      switch (httpMethod) {
        case Http.GET:
          return this.http.get(url);
      }
    } else if (
      url.indexOf(
        environment.greenitedDeepThought + "/GetDataWithKeycloakAuthentication?query=CVS_CO2_STATUS.xsodata"
      ) > -1
    ) {
      url +=
        "/input(P_GPC_ID=0,P_YEAR=" +
        this.utilService.getSelectedYear() +
        ",P_AGS=" +
        this.authService.tokenParsed.ags +
        ",P_LEVEL=4,P_GPC_2=100,P_GPC_3=100,P_GPC_4=100,P_GPC_5=100,P_GPC_6=100)/Results"; // todo: change
      return this.http.get(url, { headers: noCacheHeaders });
    } else if (
      url.indexOf(
        environment.greenitedDeepThought + "/GetDataWithKeycloakAuthentication?query=CVS_CO2_RANKING.xsodata"
      ) > -1
    ) {
      url +=
        "/input(P_OUTPUT_RECORDS=3,P_GPC_ID=0,P_YEAR=" +
        this.utilService.getSelectedYear() +
        ",P_AGS=" +
        this.authService.tokenParsed.ags +
        ",P_LEVEL=4,P_GPC_2=100,P_GPC_3=100,P_GPC_4=100,P_GPC_5=100,P_GPC_6=100)/Results";
      return this.http.get(url, { headers: noCacheHeaders });
    } else if (
      url.indexOf(
        environment.greenitedDeepThought + "/GetDataWithKeycloakAuthentication?query=CVS_CONS_SECTOR.xsodata"
      ) > -1
    ) {
      url +=
        "/input(START_YEAR=1990,END_YEAR=2017,P_AGS=" +
        this.authService.tokenParsed.ags +
        ",P_LEVEL=4,P_GPC=2)/Results";
      return this.http.get(url, { headers: noCacheHeaders });
    } else if (
      url.indexOf(
        environment.greenitedDeepThought + "/GetDataWithKeycloakAuthentication?query=CVS_CO2_SEKTOR.xsodata"
      ) > -1
    ) {
      url +=
        "/input(P_GPC_ID=0,P_YEAR=2017,P_AGS=1054020,P_LEVEL=4,P_GPC_2=100,P_GPC_3=100,P_GPC_4=100,P_GPC_5=100,P_GPC_6=100)/Results";
      return this.http.get(url, { headers: noCacheHeaders });
    } else {
      switch (httpMethod) {
        case Http.GET:
          return this.http.get(url, { params });
        case Http.POST:
          return this.http.post(url, preparedPayload);
        case Http.PATCH:
          return this.http.patch(url, preparedPayload);
        case Http.PUT:
          return this.http.put(url, preparedPayload);
        case Http.DELETE:
          return this.http.delete(url, { params });
      }
    }
  }

  private preparePayload(requestPayload: any): any {
    requestPayload = requestPayload || {};
    requestPayload.ags = requestPayload.ags || this.authService.tokenParsed.ags;
    requestPayload.level = this.authService.tokenParsed.level;
    requestPayload.year = this.utilService.getSelectedYear();
    requestPayload.scenario = this.utilService.selectedScenario;
    return requestPayload;
  }
}
