import { UnitConfig, formatUnit } from "@enersis/gp-components/number-formatting";
import { TranslateService } from "@ngx-translate/core";

export const onlyKwhUnit: UnitConfig = {
  category: "decimal",
  base: "k",
  onlyUnits: ["k"],
  unitSuffix: "Wh",
  numberFormat: {
    maximumFractionDigits: 0
  }
};

export const onlyTonsCo2Unit: UnitConfig = {
  category: "weight",
  base: "t",
  onlyUnits: ["t"],
  unitSuffix: "\xA0CO₂e",
  numberFormat: {
    maximumFractionDigits: 0
  }
};

export const percentageUnit: UnitConfig = {
  category: "percentage",
  base: "decimal",
  numberFormat: {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0
  }
};

export function valueAndTotalFormatter(options: {
  translate: TranslateService;
  unitConfig: UnitConfig;
  title: string;
  value: number;
  total?: number;
}) {
  const titleAndValue = `<b>${options.title}</b><br>
  <hr/>
  ${formatUnit(options.value, options.unitConfig)}`;

  if (options.total) {
    const percentageAndTotal = ` (${formatUnit(options.value / options.total, percentageUnit)})<br>
    ${options.translate.instant("COCKPIT.TOTAL")}: ${formatUnit(options.total, options.unitConfig)}`;

    return titleAndValue + percentageAndTotal;
  }
  return titleAndValue;
}
