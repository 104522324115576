<div class="dqi_wrapper">
  <div class="weather_icon" *ngIf="accountingMethodService.weatherCorrection$ | async">
    <ene-icon icon="ec_witterungskorrektur"> </ene-icon>
  </div>
  <div *ngIf="bisko" class="bisko_icon">
    <img src="../../../../../../assets/images/info/methods/bisko.svg" alt="BISKO" />
  </div>
  <app-dqi-view [value]="dqi">
    <div class="dqi_title">
      <span>{{ "TDI.DQI.ABBR" | translate }}</span>
      <span>{{ dqi | number: "1.0-2" }}</span>
    </div>
  </app-dqi-view>
</div>
