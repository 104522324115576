import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocaleService } from "./locale.service";
import { NumberInputDirective } from "./number-input/number-input.directive";
import { LocalNumberPipe } from "./local-number.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [NumberInputDirective, LocalNumberPipe],
  providers: [LocaleService],
  exports: [NumberInputDirective, LocalNumberPipe]
})
export class LocaleConversionsModule {}
