import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { acceptJsonWithoutCache } from "../../common";
import { GasCockpitKpiActionTypes, GasCockpitKpi, GasCockpitKpiSuccess, GasCockpitKpiError } from "./kpi-actions";

@Injectable()
export class GasCockpitKpiEffects {
  @Effect()
  public gasCockpitKpi$ = this.actions$.pipe(
    ofType(GasCockpitKpiActionTypes.GET_GAS_KPI),
    switchMap((action: GasCockpitKpi) => {
      const url: string = `${environment.infrastructure.gridApi}/networks/${action.props.gasnetworkId}`;
      const headers = new HttpHeaders(acceptJsonWithoutCache);
      const params = new HttpParams().set("year", String(action.props.year));

      return this.http.get(url, { headers, params }).pipe(
        map((data: any) => new GasCockpitKpiSuccess(data.result)),
        catchError((error) => of(new GasCockpitKpiError(error)))
      );
    })
  );

  constructor(private http: HttpClient, private actions$: Actions) {}
}
