import { Component, OnInit, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Observable } from "rxjs";
import { takeUntil, map } from "rxjs/operators";
import { AccountingMethodService, EntryComponents } from "@energy-city/components";
import { AccountingMethod } from "../../../../configs/accounting-method";
import { EneKpisV2Service } from "@energy-city/ui/kpis-v2";

@Component({
  selector: "app-no-display",
  templateUrl: "./no-display.component.html",
  styleUrls: ["./no-display.component.scss"]
})
export class NoDisplayComponent implements OnInit, OnDestroy {
  constructor(
    private translate: TranslateService,
    private accountingMethodService: AccountingMethodService,
    private ene: EneKpisV2Service
  ) {}

  public translateKey: string = "COCKPIT.CO2.NO_DISPLAY.NO_DISPLAY";
  public content$: Observable<string>;
  public note: string = "COCKPIT.CO2.NO_DISPLAY.NO_DISPLAY_SELECT_GPC_BICO2SH";

  private currentAccountingMethod: AccountingMethod;
  private destroy$: Subject<void> = new Subject();

  private chartComponents = new EntryComponents();
  private methodMap = {
    [AccountingMethod.GPC]: "GPC",
    [AccountingMethod.BISKO]: "BISKO",
    [AccountingMethod.BICO2SH]: "BiCO<sub>2</sub>sh",
    [AccountingMethod.STET]: "STET",
    [AccountingMethod.BICO2BW]: "BICO2 BW"
  };

  public ngOnInit(): void {
    this.handleAccountingMethod();
    this.getTranslation();
    this.updateKpis();
  }

  private getTranslation(): void {
    this.content$ = this.translate.get(this.translateKey).pipe(
      map((translation: string) =>
        translation.replace("{{ method }}", `<b>${this.methodMap[this.currentAccountingMethod]}</b>`)
      ),
      takeUntil(this.destroy$)
    );
  }

  private handleAccountingMethod(): void {
    this.accountingMethodService.selectedAccountingMethod$
      .pipe(takeUntil(this.destroy$))
      .subscribe((method: AccountingMethod) => {
        this.currentAccountingMethod = method;
        this.getTranslation();
      });
  }

  private updateKpis(): void {
    this.ene.runMapping(
      {
        share: null,
        emission_factor: null,
        change_since_1990: null
      },
      "co2_cockpit_extended"
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
