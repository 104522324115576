<gp-tooltip dark hoist placement="right" [disabled]="!(useUserFactors || isUnionRegion)">
  <span *ngIf="isUnionRegion" slot="content" [innerHTML]="'KPI.CO2_RANK_DISABLED_IS_UNION_TOOLTIP' | translate"></span>
  <span
    *ngIf="!isUnionRegion && useUserFactors"
    slot="content"
    [innerHTML]="'KPI.CO2_RANK_DISABLED_USERFACTORS_TOOLTIP' | translate"
  ></span>
  <gp-kpi [kpiTitle]="'KPI.CO2_RANK' | translate" [disabled]="isKpiDisabled" icon="gpi-filled-badge-star">
    <ng-container *ngIf="rank; else noRank">
      {{ rank.rank }} {{ "APP.OF" | translate }} {{ rank.numberOfRegions }}
    </ng-container>
    <ng-template #noRank>-</ng-template>

    <app-weather-correction-indicator
      *ngIf="isEnabled"
      slot="indicator"
      [weatherCorrection]="accounting?.weatherCorrection"
    ></app-weather-correction-indicator>
  </gp-kpi>
</gp-tooltip>
