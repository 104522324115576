import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Co2CockpitFixedComponent } from "./co2-cockpit/fixed/co2-cockpit-fixed.component";
import { Co2CockpitEnlargedComponent } from "./co2-cockpit/enlarged/co2-cockpit-enlarged.component";
import { EneKpisModule } from "@energy-city/ui/kpis";
import { EneTabsModule } from "@energy-city/ui/tabs";
import { TranslateModule } from "@ngx-translate/core";
import { EneFormsModule } from "@energy-city/ui/forms";
import { FormsModule } from "@angular/forms";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneKpisV2Module } from "@energy-city/ui/kpis-v2";
import { Co2CockpitComponent } from "./co2-cockpit/co2-cockpit.component";
import { Co2CockpitChartsModule } from "./charts/co2-cockpit-charts.module";
import { ChartsKpiService } from "../../services/charts-kpi.service";
import { RegionService } from "../../services/region.service";
import { AccountingEmissionModule } from "@co2-shared/accounting-emission";

@NgModule({
  declarations: [Co2CockpitFixedComponent, Co2CockpitEnlargedComponent, Co2CockpitComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AccountingEmissionModule,
    EneKpisModule,
    EneTabsModule,
    EneFormsModule,
    FormsModule,
    EneIconModule,
    EneKpisV2Module,
    Co2CockpitChartsModule
  ],
  exports: [Co2CockpitComponent, Co2CockpitEnlargedComponent, Co2CockpitFixedComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ChartsKpiService, RegionService]
})
export class Co2CockpitModule {}
