<gp-kpis-box>
  <gp-kpis-box-entry
    data-test="kpi-population"
    [kpiTitle]="'KPI.POPULATION' | translate"
    [value]="(regionProperties$ | async)?.population"
    [unitConfig]="{
      category: 'decimal',
      base: '',
      omitUnits: ['k', 'M', 'G', 'T'],
      numberFormat: {
        maximumSignificantDigits: 10,
        minimumSignificantDigits: 1
      }
    }"
  >
  </gp-kpis-box-entry>
  <gp-kpis-box-entry
    data-test="kpi-countBuildings"
    [kpiTitle]="'KPI.BUILDINGS_COUNT' | translate"
    [value]="regionBuildingsCount$ | async"
    [unitConfig]="{
      category: 'decimal',
      base: '',
      omitUnits: ['k', 'M', 'G', 'T'],
      numberFormat: {
        maximumSignificantDigits: 10,
        minimumSignificantDigits: 1
      }
    }"
  >
  </gp-kpis-box-entry>
  <gp-kpis-box-entry
    data-test="kpi-area"
    [kpiTitle]="'KPI.AREA' | translate"
    [value]="(regionProperties$ | async)?.area_m2"
    [unitConfig]="{
      category: 'area',
      base: 'm2',
      omitUnits: ['ha'],
      numberFormat: {
        maximumSignificantDigits: 4,
        minimumSignificantDigits: 1
      }
    }"
  >
  </gp-kpis-box-entry>
</gp-kpis-box>
