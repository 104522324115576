<ul [class]="'tabs__tab-bar'" class="custom" [ngClass]="{ disabled: disabled === true }">
  <li
    class="tabs__tab"
    [class.hidden]="tab.hidden"
    *ngFor="let tab of tabs"
    [ngClass]="{
      active: tab.active === true && disabled !== true,
      single: tabs.length === 1,
      disabled: tab.disabled === true
    }"
    (click)="tab.disabled !== true && selectTab(tab)"
  >
    <ene-icon [icon]="tab.icon" [project]="iconsProject" *ngIf="tab.icon"></ene-icon>
    <span [innerHTML]="tab.tabTitle"></span>
  </li>
</ul>
<ng-content *ngIf="disabled !== true"></ng-content>
<div class="overlay" [style.display]="isDisabled()"></div>
