import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule, GlobalConfig, TOAST_CONFIG } from "ngx-toastr";
import { defaultConfig } from "./constants/config";
import { EneSnackbarService } from "./services/snackbar.service";
import { EneSnackbarComponent } from "./components/snackbar.component";

@NgModule({
  declarations: [EneSnackbarComponent],
  imports: [CommonModule, TranslateModule, ToastrModule],
  exports: [EneSnackbarComponent],
  providers: [EneSnackbarService]
})
export class EneSnackbarModule {
  public static forRoot(config: Partial<GlobalConfig> = {}): ModuleWithProviders<EneSnackbarModule> {
    return {
      ngModule: EneSnackbarModule,
      providers: [
        {
          provide: TOAST_CONFIG,
          useValue: {
            default: defaultConfig,
            config
          }
        },
        EneSnackbarService
      ]
    };
  }
}
