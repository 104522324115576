import {
  Component,
  AfterContentInit,
  Input,
  HostBinding,
  HostListener,
  OnChanges,
  SimpleChanges,
  Host,
  ChangeDetectorRef,
  Inject,
  forwardRef
} from "@angular/core";
import { IDependencies, IResponsiveColumns } from "../../models/ui-sidenav.interfaces";
import { PanelEvent } from "../../models/ui-sidepanel.enum";
import { EneSidenavComponent } from "../sidenav/ui-sidenav.component";
import { EneSidenavService } from "../../services/ui-sidenav.service";

@Component({
  selector: "ene-sidepanel",
  templateUrl: "./ui-sidepanel.component.html",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-sidepanel"
  }
})
export class EneSidepanelComponent implements AfterContentInit, OnChanges {
  // class binding
  @HostBinding("class") @Input() public align: string = "vertical";
  @HostBinding("class.ene-sidepanel--collapse") @Input() public collapse: boolean = false;
  @HostBinding("class.ene-sidepanel--visible") @Input() public visible: boolean = true;
  @HostBinding("class.ene-sidepanel--overlay") @Input() public overlay: boolean = false;
  @HostBinding("class.ene-sidepanel--disabled") @Input() public disabled: boolean = false;
  @HostBinding("class.ene-sidepanel--enlarge") @Input() public enlarge: boolean = false;
  @HostBinding("class.ene-sidepanel--stickyheader") @Input() public stickyheader: boolean = false;
  @HostBinding("class.ene-sidepanel--master") @Input() public master: boolean = false;

  // style binding
  @HostBinding("style.width") public calculatedWidth: any;
  @HostBinding("style.max-width") @Input() public enlargeSize: string = "100vw";
  @HostBinding("style.min-width") public calculatedMinWidth: any;
  @HostBinding("style.height") public calculatedHeight: any;
  public calculatedContainerWidth: any;
  public iconPaddingTop: any;

  // inputs
  @Input() private width: any;
  @Input() private collision: boolean = true;
  @Input() private columns: number | IResponsiveColumns = 4;
  @Input() public name: string;
  @Input() public icon: string = "iconmonstr-arrow-25";
  @Input() public buttonSize: number;
  @Input() public navbuttons: boolean = true;
  @Input() public slave: string = undefined; // used for master: if string passed, it will be a slave of the masterpanel specified.
  @Input() public extendable: boolean = false;
  @Input() public sidebarItemClass: string;

  // used in parent component ene-sidenav (has to be here for typing)
  @Input() private buttonPosition: string = "start";
  @Input() private label: string;
  @Input() private dependencies: IDependencies;
  @Input() private progressIndicator: boolean = false;

  constructor(
    @Host()
    @Inject(forwardRef(() => EneSidenavComponent))
    private parent: any,
    private sidenavService: EneSidenavService,
    private cd: ChangeDetectorRef
  ) {}

  /*************************************
   * ANGULAR LIFECYCLE
   *************************************/

  /**
   * angular lifecycle hook - does calculate the size for itself.
   */
  public ngAfterContentInit() {
    this.calculatePanelWidth();
  }

  /**
   * ngOnChanges ensure that the width will change if anything is altered
   * @param changes delivered by angular
   */
  public ngOnChanges(changes: SimpleChanges) {
    this.calculatePanelWidth();
    this.parent.performChildrenChecks(this);
  }

  /*************************************
   * PUBLIC FUNCTIONS
   *************************************/
  /**
   * does alter the state for collapse
   * @param state boolean of the state to set (true = active, false=inactive). if undefined, the current state will be inverted
   * @param performDepsCheck boolean, if yes it performs the dependencies checks (eg. does open/close other panels if defined in this.dependencies )
   */
  public setCollapse(state?: boolean, performDepsCheck: boolean = true): void {
    this.collapse = typeof state === "boolean" ? state : !this.collapse;
    if (this.collapse && this.enlarge !== null) {
      this.setEnlarge(false, false);
    }
    if (performDepsCheck) {
      const EventToCheck = this.collapse ? "onCollapse" : "offCollapse";
      this.parent.checkDependencies(this.name, EventToCheck);
      this.parent.performChildrenChecks(this);
    }
    this.onPanelChange(this.name, this.collapse, PanelEvent.COLLAPSED);
  }

  /**
   * does alter the state for visible
   * @param state boolean of the state to set (true = active, false=inactive). if undefined, the current state will be inverted
   * @param performDepsCheck boolean, if yes it performs the dependencies checks (eg. does open/close other panels if defined in this.dependencies )
   */
  public setVisible(state?: boolean, performDepsCheck: boolean = true): void {
    this.visible = typeof state === "boolean" ? state : !this.visible;
    this.parent._checkMasterpanel(this);
    if (!this.visible) {
      this.setCollapse(false);
    }
    if (performDepsCheck) {
      const EventToCheck = this.visible ? "onVisible" : "offVisible";
      this.parent.checkDependencies(this.name, EventToCheck);
      this.parent.performChildrenChecks(this);
    }
    this.onPanelChange(this.name, this.visible, PanelEvent.VISIBLE);
  }

  /**
   * does alter the state for disabled
   * @param state boolean of the state to set (true = active, false=inactive). if undefined, the current state will be inverted
   * @param performDepsCheck boolean, if yes it performs the dependencies checks (eg. does open/close other panels if defined in this.dependencies )
   */
  public setDisabled(state?: boolean, performDepsCheck: boolean = true): void {
    this.disabled = typeof state === "boolean" ? state : !this.disabled;
    if (performDepsCheck) {
      const EventToCheck = this.disabled ? "onDisabled" : "offDisabled";
      this.parent.checkDependencies(this.name, EventToCheck);
      this.parent.performChildrenChecks(this);
    }
    this.onPanelChange(this.name, this.disabled, PanelEvent.DISABLED);
  }

  /**
   * does alter the state for enlarge
   * @param state boolean of the state to set (true = active, false=inactive). if undefined, the current state will be inverted
   * @param performDepsCheck boolean, if yes it performs the dependencies checks (eg. does open/close other panels if defined in this.dependencies )
   */
  public setEnlarge(state?: boolean, performDepsCheck: boolean = true): void {
    this.enlarge = typeof state === "boolean" ? state : !this.enlarge;
    if (performDepsCheck) {
      const EventToCheck = this.enlarge ? "onEnlarge" : "offEnlarge";
      this.parent.performChildrenChecks(this);
      this.parent.checkDependencies(this.name, EventToCheck);
    }

    this.onPanelChange(this.name, this.enlarge, PanelEvent.ENLARGED);
  }

  /**
   * this function is called in the template by the collapse button of the panel.
   * It differs from setCollapse because it may just removes enlarge state or may it really collapse it
   */
  public clickCollapse(): void {
    if (this.overlay) {
      return;
    }
    if (this.enlarge === true) {
      this.setEnlarge(false, false);
    } else {
      this.setCollapse(true, false);
    }
  }

  /*************************************
   * PRIVATE FUNCTIONS
   *************************************/

  /**
   * this function will be called by EneSidebarItemDirective in the window.resize() hostlistener event
   * @param paddingTop pixels as number
   */
  public _setIconPosition(paddingTop: number) {
    if (this.align === "vertical") {
      this.iconPaddingTop = "calc(" + paddingTop + "px - 0.5em)"; // remove 0.5em so the icon is centered
    } else {
      this.iconPaddingTop = "0px";
    }
    this.cd.detectChanges(); // execute detectChanges to prevent “expression has changed after it was checked” exception
  }

  /**
   * internal helper function to execute a next on EnesidenavService.onPanelChange$
   * @param name name of panel as string
   * @param state new state as boolean
   * @param event like ENUMS PanelEvent
   */
  private onPanelChange(name: string, state: boolean, event: PanelEvent): void {
    this.sidenavService.onPanelChange$.next({
      name: name,
      state: state,
      event: event
    });
    this.calculatePanelWidth();
  }

  /**
   * does all the calculate on this panel, especially keeping it responsible
   */
  private calculatePanelWidth(): void {
    const paddings: any = { sm: 18, md: 24, lg: 32, xl: 40 };
    const padding: any = paddings[this.sidenavService.responsiveCalculator(false, true)]; // add 17px here for the scrollbar

    // FOR NOW THIS IS DEACTIVATED BECAUSE IT CAUSES TROUBLES WITH LATER INSTANCIATED COMPONENTS LIKE CHARTHOST
    // THE SOLUTION FOR NOW IS TO PUT max-width: 100%; on the content-fixed element
    // maybe the content exceed the height of the container? then we add additonal 17px padding for the scrollbar
    // we add the 17px per default and remove then 17px if not scrollable.
    // the reason is that dynamically inserted content like chart-host will not have a height in this moment and we wanna ensure that it fits this case too.
    // https://www.textfixer.com/tutorials/browser-scrollbar-width.php
    // const contentFull = this.contentFull.nativeElement.offsetHeight;
    // const contentFixedAndEnlarged = this.contentFixedAndEnlarged.nativeElement.offsetHeight;
    // if (this.elementRef.nativeElement.offsetHeight > contentFull + contentFixedAndEnlarged) {
    //   padding = padding + 17;
    // }

    let noPaddingWidth: string;
    // If there is no width and columns set, we will calculate them
    if (typeof this.width === "undefined") {
      // Change it to a object if just a number is submitted
      if (typeof this.columns === "number") {
        this.columns = { sm: this.columns, md: this.columns, lg: this.columns, xl: this.columns };
      }

      let columnsToCalc: number;
      const myResponsiveShortname = this.sidenavService.responsiveCalculator(false, true);
      if (this.columns.hasOwnProperty(myResponsiveShortname)) {
        columnsToCalc = this.columns[myResponsiveShortname];
      } else {
        console.error("missing the property " + myResponsiveShortname + " in [colums] attribute for sidepanel");
      }

      // set height or width
      if (this.align === "vertical") {
        this.calculatedWidth = columnsToCalc * <number>this.sidenavService.responsiveCalculator() + "px";
        noPaddingWidth = columnsToCalc * <number>this.sidenavService.responsiveCalculator() - padding + "px";
        if (!this.collision) {
          this.calculatedMinWidth = columnsToCalc * <number>this.sidenavService.responsiveCalculator() + "px";
        }
      } else {
        this.calculatedHeight = columnsToCalc * <number>this.sidenavService.responsiveCalculator() + "px";
      }
    } else {
      // for vw i have to remove the sidebar from the total value
      if (this.width === "full") {
        this.calculatedWidth = "calc(100vw - " + this.sidenavService.responsiveCalculator() + "px)";
        noPaddingWidth =
          "calc(100vw - " +
          (this.sidenavService.responsiveCalculator() +
            paddings[this.sidenavService.responsiveCalculator(false, true)]) +
          "px)";
      } else {
        this.calculatedWidth = this.width;
        noPaddingWidth = "calc(" + this.calculatedWidth + " - " + padding + "px)";
      }
    }
    // calculate the inner content container width (remove paddings)
    this.calculatedContainerWidth = noPaddingWidth;

    // are we in enlarged mode? then we set width to 100% for the sidepanel
    // we still have to run all the other code to get calculatedContainerWidth for the content-fixed
    if (this.enlarge) {
      this.calculatedWidth = "calc(100vw - " + this.sidenavService.responsiveCalculator() + "px)"; // vw to use for sure all space, with 100% the content will may not extend fully
    }
  }

  /*************************************
   * LISTENER FUNCTIONS
   *************************************/
  /**
   * Ensures that the whole sidenav is recalculated
   * @param event comes from angular
   */
  @HostListener("window:resize", ["$event"])
  public onResize(event?: Event) {
    // switch of true so it will execute always. I've used switch here becaus it probably looks cleaner with it.
    this.calculatePanelWidth();
  }
}
