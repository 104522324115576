import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RadioButtonComponent, RadioGroupComponent } from "./radio.component";

@NgModule({
  declarations: [RadioGroupComponent, RadioButtonComponent],
  imports: [CommonModule],
  exports: [RadioGroupComponent, RadioButtonComponent]
})
export class RadioModule {}
