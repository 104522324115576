import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneChartModule } from "@energy-city/ui/chart";
import { SimulationChartComponent } from "./components/simulation/simulation-chart.component";
import { SimulationDataService } from "./data/simulation-data.service";
import { AccountingCommonModule } from "../accounting-common";
import { AccountingEmissionModule } from "../accounting-emission";
import "@enersis/gp-components/gp-chart-export-menu";
import "@enersis/gp-components/gp-icon-button";

@NgModule({
  declarations: [SimulationChartComponent],
  providers: [SimulationDataService],
  imports: [CommonModule, EneChartModule, AccountingCommonModule, AccountingEmissionModule],
  exports: [SimulationChartComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AccountingSimulationModule {}
