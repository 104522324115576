import { Injectable } from "@angular/core";
import { EneKpisComponent } from "./ui-kpis.component";
import { IKpiBlock, IKpiItem, KpiStates, IKpiSettings } from "./ui-kpis.interface";

@Injectable()
export class EneKpisService {
  private namespaces: any = {};
  private namespacesUninitalized: any = {};
  private _settings: IKpiSettings = {
    i18n: {
      NOTAVAILABLE: "TDI.NOT_AVAILABLE.LONG",
      CLEARED: "",
      UNKNOWN: "TDI.UNKNOWN.LONG",
      PENDING: ""
    },
    allowIdDuplicates: false, // if false, id check will be executed
    iconsProject: "energycity"
  };
  public get settings(): any {
    return this._settings;
  }
  public set settings(value: any) {
    this._settings = value;
  }
  public functions: any = {
    // read and display dataset entries
    // the arguments are defined in IKpiItem.function.data and should contain a paring of property-names:
    // the property-name equals the property of IKpiItem, the value equal the property name of the dataset-entries
    datasetByValue: function (
      item: IKpiItem,
      ref: EneKpisComponent,
      args: { [key in keyof IKpiItem]: IKpiItem[key] }
    ): void {
      if (!item.hasOwnProperty("dataset")) {
        console.warn("this item has no dataset, can not execute datasetByValue()", item);
      } else {
        const filteredDataset = item.dataset.filter((i) => i.value === item.value);
        const obj = filteredDataset.length === 1 ? filteredDataset[0] : item.dataset[0];
        if (obj) {
          if (typeof args === "undefined") {
            console.warn("datasetByValue: could not read args, therefore don't know what to alter!");
          } else {
            item.state = KpiStates.LOADED;
            let key: keyof IKpiItem | any;
            for (key in args) {
              if (item.hasOwnProperty(key) && obj.hasOwnProperty(args[key])) {
                item[key] = obj[args[key]];
              }
            }
          }
        }
      }
    }
  };

  constructor() {}

  /**
   * will be called by kpi component, only use internally in ene-ui!
   * @param namespace name delivered by kpi block
   * @returns true if successfuly registered, false if a namespace with same name already exist
   */
  public _initNamespace(namespace: string, ref: EneKpisComponent) {
    try {
      if (this.namespaces.hasOwnProperty(namespace)) {
        throw ["tried to register already used same namespace. aborting here", namespace];
      } else {
        // will be called after a) sucessful duplicates check or b) the check is deactivated, if so it will be called right away
        const addNamespace = () => {
          // safe reference of component
          this.namespaces[namespace] = ref;
          // check if there is already data which can be grabbed
          if (this.namespacesUninitalized.hasOwnProperty(namespace)) {
            if (
              this.namespacesUninitalized[namespace].hasOwnProperty("data") &&
              Array.isArray(this.namespacesUninitalized[namespace].data)
            ) {
              this.namespaces[namespace]._data = this.namespacesUninitalized[namespace].data;
            }
            delete this.namespacesUninitalized[namespace];
          }
        };

        // check for duplicates
        if (!this._settings.allowIdDuplicates) {
          const listOfExistingItemIds: any[] = [];
          const listOfExistingBlockIds: any[] = [];
          for (const ns in this.namespaces) {
            if (this.namespaces.hasOwnProperty(ns) && this.namespaces[ns].hasOwnProperty("_data")) {
              this.namespaces[ns]._data.forEach((block: IKpiBlock) => {
                if (block.hasOwnProperty("id")) {
                  if (listOfExistingBlockIds.indexOf(block.id) > -1) {
                    throw {
                      error: "block id duplicate detected!",
                      blockId: block.id,
                      blockObj: block,
                      namespaceId: ns,
                      namespaceRef: this.namespaces[ns]
                    };
                  }
                  listOfExistingBlockIds.push(block.id);
                }
                // IKpiItem[]
                if (block.hasOwnProperty("items") && block.items.length > 0) {
                  block.items.forEach((item: IKpiItem) => {
                    if (item.hasOwnProperty("id")) {
                      if (listOfExistingItemIds.indexOf(item.id) > -1) {
                        throw {
                          error: "Item id duplicate detected! " + item.id,
                          itemArray: block.items,
                          failedIndex: block.items.indexOf(item),
                          itemObj: item,
                          blockId: block.id,
                          blockObj: block,
                          namespaceId: ns,
                          namespaceRef: this.namespaces[ns]
                        };
                      }
                      listOfExistingItemIds.push(item.id);
                    }
                  });
                }
              });
            }
          }

          // no throws? t hen we sucessed and can safe our new component
          addNamespace();
          return true;
        }
      }
    } catch (error) {
      console.error(error, this.namespaces);
    }
  }

  /**
   * Will be called from the kpis.component when the ngOnDestroy() lifecycle hits (eg. the component get removed)
   * this function will store the data, so we are able to reinitialize it anytime.
   * @param namespace string of namespace
   */
  public removeNamespace(namespace: string, forceRemove?: boolean) {
    if (this.namespaces.hasOwnProperty(namespace)) {
      // shift data to namespacesUninitalized to be able to reinitialize at any point without the need to use "addBlocks" again
      if (!this.namespacesUninitalized.hasOwnProperty(namespace)) {
        this.namespacesUninitalized[namespace] = { data: this.namespaces[namespace]._data };
      }
      delete this.namespaces[namespace];
    }
    if (forceRemove && this.namespacesUninitalized.hasOwnProperty(namespace)) {
      delete this.namespacesUninitalized[namespace];
    }
  }

  /**
   * adds an array of Blocks into the specified namespace
   * Usage of params namespace and blocks recommended for better performance
   * @param namespace namespace of to affecting component
   * @param blocks array of blocks like IKpiBlock
   * @param position optional: determines the position where to add. "append" | "prepend" | number | undefined
   */
  public addBlocks(namespace: string, blocks: IKpiBlock[], position?: "append" | "prepend" | number | undefined) {
    if (this.namespaces.hasOwnProperty(namespace)) {
      this.runComponentFunction("addBlocks", [blocks, position], namespace);
    } else {
      this.namespacesUninitalized[namespace] = { data: blocks };
    }
  }

  /**
   * remove blockids
   * @param blockIds array of ids from blocks. if undefined, all will be deleted.
   */
  public removeBlocks(blockIds: any[]) {
    this.runComponentFunction("removeBlocks", [blockIds]);
  }

  /**
   * executing mapping as defined in the property mapping in IKpiItem
   * Usage of params namespace and blocks recommended for better performance
   * @param results any object with new values
   * @param namespace optional: only for a specific component (usage recommended, much better performance)
   * @param blocks optional: if provided it will be only applied to the ids submitted as array. if empty, it will be executed in all blocks.
   */
  public runMapping(results: any, namespace?: string, blocks?: any[]) {
    this.runComponentFunction("runMapping", [results, blocks], namespace);
  }

  /**
   * set a function of a item with specified id as defined in IKpiItem
   * Usage of param namespace recommended for better performance
   * @param id of item
   * @param fn optional: passed function
   * @param namespace optional: only for a specific component (usage recommended, much better performance)
   */
  public setFunction(id: string | number, fn: any, namespace?: string) {
    if (this.namespaces.hasOwnProperty(namespace)) {
      this.runComponentFunction("setFunction", [id, fn], namespace);
    } else {
      if (this.namespacesUninitalized.hasOwnProperty(namespace)) {
        let itemToAlter: IKpiItem;
        if (this.namespacesUninitalized[namespace].hasOwnProperty("data")) {
          this.namespacesUninitalized[namespace].data.some(function (block: IKpiBlock) {
            if (block.hasOwnProperty("items")) {
              itemToAlter = block.items.find(function (obj) {
                return obj.id === id;
              });
              return typeof itemToAlter !== "undefined" ? true : false;
            }
          });
        }
        if (itemToAlter) {
          if (itemToAlter.hasOwnProperty("function")) {
            itemToAlter.function.reference = fn;
          } else {
            itemToAlter.function = { reference: fn };
          }
        }
      } else {
        console.warn("setFunction called but namespace is unknown (in both unitialized and initialized)!");
      }
    }
  }

  /**
   * executing function of a item with specified id as defined in the property mapping in IKpiItem
   * Usage of params namespace and blocks recommended for better performance
   * @param id of item
   * @param fn optional: passed function
   * @param namespace optional: only for a specific component (usage recommended, much better performance)
   * @param blocks optional: if provided it will be only applied to the ids submitted as array. if empty, it will be executed in all blocks.
   */
  public runFunction(id: string | number, fn?: any, namespace?: string, blocks?: any[]) {
    this.runComponentFunction("runFunction", [id, fn], namespace);
  }

  /**
   * executing ALL functions
   * Usage of params namespace and blocks recommended for better performance
   * @param results any object with new values
   * @param namespace optional: only for a specific component (usage recommended, much better performance)
   * @param blocks optional: if provided it will be only applied to the ids submitted as array. if empty, it will be executed in all blocks.
   */
  public runFunctions(namespace?: string, blocks?: any[]) {
    this.runComponentFunction("runFunctions", [], namespace);
  }

  /**
   * update specific kpi
   * Usage of param namespace recommended for better performance
   * @param id of a IKpiItem
   * @param value new value to be inserted
   * @param namespace optional: only for a specific component (usage recommended, much better performance)
   */
  public update(id: any, value: any, namespace?: string) {
    this.runComponentFunction("update", [id, value], namespace);
  }

  /**
   * Usage of param namespace recommended for better performance
   * @param ids array of ids of IKpiItem[]
   * @param namespace optional: only for a specific component (usage recommended, much better performance)
   */
  public clear(ids?: (string | number)[], namespace?: string) {
    this.runComponentFunction("clear", [ids], namespace);
  }

  /**
   * Usage of param namespace recommended for better performance
   * @param ids array of ids of IKpiItem[]
   * @param namespace optional: only for a specific component (usage recommended, much better performance)
   */
  public pending(ids?: (string | number)[], namespace?: string) {
    this.runComponentFunction("pending", [ids], namespace);
  }

  /**
   * Usage of param namespace recommended for better performance
   * @param ids array of ids of IKpiItem[]
   * @param namespace optional: only for a specific component (usage recommended, much better performance)
   */
  public notavailable(ids?: (string | number)[], namespace?: string) {
    this.runComponentFunction("notavailable", [ids], namespace);
  }

  /**
   * Usage of param namespace recommended for better performance
   * @param ids optional: array of ids of IKpiItem[] - if not defined it will alter the state of ALL
   * @param namespace optional: only for a specific component (usage recommended, much better performance)
   */
  public unknown(ids?: (string | number)[], namespace?: string) {
    this.runComponentFunction("unknown", [ids], namespace);
  }

  /**
   * @param id id of IKpiItem[]
   * @param dataset any dataset to add
   * @param namespace name of a specific component
   */
  public setDataset(id: string | number, dataset: any, namespace: string) {
    if (this.namespaces.hasOwnProperty(namespace)) {
      this.runComponentFunction("setDataset", [id, dataset], namespace);
    } else {
      if (this.namespacesUninitalized.hasOwnProperty(namespace)) {
        let itemToAlter: IKpiItem;
        this.namespacesUninitalized[namespace].data.some(function (block: IKpiBlock) {
          if (block.hasOwnProperty("items")) {
            itemToAlter = block.items.find(function (obj) {
              return obj.id === id;
            });
            return typeof itemToAlter !== "undefined" ? true : false;
          }
        });
        if (itemToAlter) {
          itemToAlter.dataset = dataset;
        }
      } else {
        console.warn("setDataset called but namespace is unknown (in both unitialized and initialized)!");
      }
    }
  }

  /**
   * internal helper function which executes a FN only in a certain namespace OR if undefined, it will execute the fn in ALL .
   * Main benefit is that it uses a specified namespace when provided to safe lots of performance.
   * if no namespace provided, is looks up all initialized components.
   * @param fnToRun name of function equivalent in a kpi.component
   * @param paramsOfFn params as array for the passed fn
   * @param namespace name of the namespace if known
   */
  private runComponentFunction(fnToRun: any, paramsOfFn?: any[], namespace?: string): void {
    if (typeof namespace === "string") {
      if (this.namespaces.hasOwnProperty(namespace)) {
        this.namespaces[namespace][fnToRun](...paramsOfFn);
      } else {
        // console.warn("runScopedFn called on a not existing namespace", namespace, fnToRun);
      }
    } else {
      for (const k in this.namespaces) {
        if (this.namespaces.hasOwnProperty(k)) {
          this.namespaces[k][fnToRun](...paramsOfFn);
        }
      }
    }
  }
}
