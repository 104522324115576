<div class="print-container" *ngIf="active$ | async" @print>
  <div class="print-body" [ngClass]="sizing">
    <ene-icon icon="ec_building_delete_address" class="closebutton" [size]="'21px'" (click)="close()"></ene-icon>
    <p class="button">
      <button class="ene-button-pill-secondary" (click)="print()">{{ "PRINT_VIEW.HEADER.BUTTON" | translate }}</button>
    </p>
    <section class="content">
      <header>
        <h2>{{ "PRINT_VIEW.HEADER.TITLE" | translate }}</h2>
        <p>
          {{ "PRINT_VIEW.HEADER.CREATE_DATA_TITLE" | translate }}: {{ createDate | date: "dd.MM.yyyy" }}, {{ link }}
        </p>
      </header>
      <app-print-header></app-print-header>
      <section class="content__pages">
        <!-- similar pages -->
        <div
          *ngFor="let config of pagesConfig; trackBy: trackByFn; let i = index"
          [class.break-before]="i !== 0"
          class="break-after"
        >
          <ng-container *ngIf="i === 0">
            <app-trend-page [config]="config"></app-trend-page>
          </ng-container>
          <ng-container *ngIf="i === 1">
            <app-sectors-emission-page [config]="config"></app-sectors-emission-page>
          </ng-container>
          <ng-container *ngIf="i === 2">
            <app-energy-related-gases-page [config]="config"></app-energy-related-gases-page>
          </ng-container>
          <ng-container *ngIf="i === 3">
            <app-energy-per-energy-carrier-page [config]="config"></app-energy-per-energy-carrier-page>
          </ng-container>
          <ng-container *ngIf="i === 4">
            <app-transportation-page [config]="config"></app-transportation-page>
          </ng-container>
        </div>
        <!-- page with tables -->

        <app-factor-page class="break-before"></app-factor-page>

        <!-- last page -->
        <app-general-info-page class="break-before" [tenant]="tenant" [text]="generalPageText + accountingMethod">
        </app-general-info-page>
      </section>
    </section>
  </div>
</div>
<div class="print-background" *ngIf="active$ | async" @print></div>
