import { UnitConfig } from "@enersis/gp-components/number-formatting";

export type FactorProperty = Omit<UnitConfig, "onlyUnits" | "omitUnits"> & {
  /** if true will take same translation from FACTOR_MANAGEMENT.FACTORS with same key otherwise as given key */
  customTitle?: string | true;
  displayedUnit: UnitConfig["onlyUnits"][number];
};

export enum FactorEndpointId {
  Waste_Biowaste,
  Waste_BiowasteCh,
  Waste_WastewaterProteinIntake,
  Waste_WastewaterSewageEmission,
  Waste_WastewaterCh,
  Waste_WastewaterEmissionCh,
  Waste_SewageSludgeNitrogen,
  Waste_SewageSludgeNitrus,
  Afolu_Land,
  Afolu_LivestockMethaneManure,
  Afolu_LivestockMethaneDigestion,
  Afolu_LivestockNitrusOxideManure,
  Stationary_Final,
  Stationary_Primary,
  Transportation_FactorKmPerEnergySource,
  Transportation_FactorKwhPerKmPerEnergySource,
  Transportation_W2w,
  Transportation_DieselFraction
}
export type IFactorTransportation_VehicleDto = Array<{
  vehicleType: "automobile" | "coach" | "lightCommercialVehicles" | "poweredTwoWheeler" | "serviceBus" | "truck";
  roadType: "highway" | "rural" | "urban";
  fuelType:
    | "biogas"
    | "dieselBio"
    | "dieselFossil"
    | "electricity"
    | "gasolineBio"
    | "gasolineFossil"
    | "liquefiedPetroleumGas"
    | "naturalGas"
    | "kerosene";
  factorsByYear: Record<string, number>;
}>;

export const FactorTransportationVehicleUnit = "kWh/Fz-km";

const PLACEHOLDER: FactorProperty = {
  base: "",
  category: "decimal",
  displayedUnit: "",
  unitSuffix: "",
  customTitle: true
};

const PERCENTAGE_UNIT: FactorProperty = {
  category: "percentage",
  base: "decimal",
  displayedUnit: "%"
};
// This factors are also used for the interfaces
export const FactorEndpointAttributes: Record<FactorEndpointId, Record<string, FactorProperty>> = {
  [FactorEndpointId.Waste_Biowaste]: {
    compostCh4: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0CH₄/t", category: "weight", customTitle: true },
    fermentationCh4: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0N₂O/t", category: "weight", customTitle: true },
    compostN2o: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0N₂O/t", category: "weight", customTitle: true },
    fermentationN2o: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0CH₄/t", category: "weight", customTitle: true }
  },
  // TODO: choose correct settings
  [FactorEndpointId.Waste_BiowasteCh]: {
    ch4Compost: {
      base: "g",
      displayedUnit: "g",
      unitSuffix: "\xA0/t composted waste",
      category: "weight",
      customTitle: true
    },
    n2oCompost: {
      base: "g",
      displayedUnit: "g",
      unitSuffix: "\xA0/t composted waste",
      category: "weight",
      customTitle: true
    },
    indBiogasFacility: {
      base: "t",
      displayedUnit: "t",
      unitSuffix: "\xA0CH₄/facility",
      category: "weight",
      customTitle: true
    },
    biogasUpgrade: { base: "g", displayedUnit: "g", unitSuffix: "\xA0CH₄/GJ", category: "weight", customTitle: true }
  },
  // TODO: choose correct settings
  [FactorEndpointId.Waste_WastewaterProteinIntake]: {
    kgPerCapitaPerYear: {
      base: "kg",
      displayedUnit: "kg",
      unitSuffix: "/Person/a",
      category: "weight",
      customTitle: "PROTEININTAKE"
    }
  },
  [FactorEndpointId.Waste_WastewaterSewageEmission]: {
    ch4: { base: "kg", displayedUnit: "kg", unitSuffix: "/Person/a", category: "weight", customTitle: true },
    n2o: { base: "kg", displayedUnit: "kg", unitSuffix: "/Person/a", category: "weight", customTitle: true },
    releasedIntoWasteWater: {
      base: "kg",
      displayedUnit: "kg",
      unitSuffix: "\xA0N₂O-N/kg N",
      category: "weight",
      customTitle: true
    }
  },
  [FactorEndpointId.Waste_WastewaterCh]: {
    n2o: {
      base: "kg",
      displayedUnit: "kg",
      unitSuffix: "\xA0N₂O/Einwohner/a",
      category: "weight",
      customTitle: true
    },
    fractionOfPopulationConnectedToDomesticWWTPlants: {
      ...PERCENTAGE_UNIT,
      customTitle: true
    },
    factorForIndustrialCoDischargedProtein: {
      category: "weight",
      base: "g",
      displayedUnit: "g",
      customTitle: true
    },
    nitrogenFractionInProtein: {
      base: "kg",
      displayedUnit: "kg",
      unitSuffix: "\xA0N/kg protein",
      category: "weight",
      customTitle: true
    },
    fractionOfNonConsumedProteinInWasteWater: {
      category: "weight",
      base: "g",
      displayedUnit: "g",
      customTitle: true
    },
    effluentFactor: {
      base: "kg",
      displayedUnit: "kg",
      unitSuffix: "\xA0N₂O-N/kg N",
      category: "weight",
      customTitle: true
    }
  },
  [FactorEndpointId.Waste_WastewaterEmissionCh]: {
    ch4Wastewater: { base: "kg", displayedUnit: "kg", unitSuffix: "\xA0CH₄/Einwohner/ a", category: "weight" },
    ch4SewageGas: { base: "kg", displayedUnit: "kg", unitSuffix: "\xA0CH₄/Einwohner/ a", category: "weight" }
  },
  [FactorEndpointId.Waste_SewageSludgeNitrogen]: {
    nitrogenContent: {
      customTitle: true,
      base: "t",
      displayedUnit: "kg",
      unitSuffix: "\xA0/t Trockenmasse",
      category: "weight"
    }
  },
  [FactorEndpointId.Waste_SewageSludgeNitrus]: {
    n2o: {
      base: "kg",
      displayedUnit: "kg",
      unitSuffix: "\xA0N₂O-N/kg N",
      category: "weight",
      customTitle: "n2o_SewageSludgeNitrus"
    }
  },
  [FactorEndpointId.Afolu_Land]: {
    cropland: {
      base: "t",
      displayedUnit: "g",
      unitSuffix: "\xA0CO₂e/m²",
      category: "weight"
    },
    grassland: {
      base: "t",
      displayedUnit: "g",
      unitSuffix: "\xA0CO₂e/m²",
      category: "weight"
    },
    settlements: {
      base: "t",
      displayedUnit: "g",
      unitSuffix: "\xA0CO₂e/m²",
      category: "weight"
    },
    wetlands: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/m²", category: "weight" }
    // not used, currently breaks the POST endpoint when included:
    // forest: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/m²", category: "weight" }
  },
  [FactorEndpointId.Afolu_LivestockMethaneManure]: {
    broilers: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    dairyCattle: {
      base: "t",
      displayedUnit: "kg",
      unitSuffix: "\xA0pl-1 a-1 CH₄",
      category: "weight",
      customTitle: "dairyCattle_methane_manure"
    },
    ducks: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    geese: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    layingHens: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    maleBeefCattle: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    pigs: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    pullets: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    sheep: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    sows: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    turkeys: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" }
  },
  [FactorEndpointId.Afolu_LivestockMethaneDigestion]: {
    dairyCattle: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    maleBeefCattle: {
      base: "t",
      displayedUnit: "kg",
      unitSuffix: "\xA0pl-1 a-1 CH₄",
      category: "weight",
      customTitle: "dairyCattle_methane_digestion"
    },
    pigs: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    sheep: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" },
    sows: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 CH₄", category: "weight" }
  },
  [FactorEndpointId.Afolu_LivestockNitrusOxideManure]: {
    broilers: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" },
    dairyCattle: {
      base: "t",
      displayedUnit: "kg",
      unitSuffix: "\xA0pl-1 a-1 N₂O",
      category: "weight",
      customTitle: "dairyCattle_nitrus_oxide_manure"
    },
    ducks: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" },
    geese: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" },
    layingHens: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" },
    maleBeefCattle: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" },
    pigs: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" },
    pullets: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" },
    sheep: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" },
    sows: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" },
    turkeys: { base: "t", displayedUnit: "kg", unitSuffix: "\xA0pl-1 a-1 N₂O", category: "weight" }
  },
  [FactorEndpointId.Stationary_Final]: {
    biomass: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    blackCoal: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    brownCoal: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    diesel: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    districtHeating: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    electricity: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    environmentalHeat: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    fuelOil: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    gasoline: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    liquidGas: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    naturalGas: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    solarHeat: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" }
  },
  [FactorEndpointId.Stationary_Primary]: {
    biomass: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    blackCoal: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    brownCoal: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    environmentalHeat: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    gas: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    nuclear: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    solarHeat: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    oil: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    other: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    photovoltaics: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    water: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" },
    wind: { base: "t", displayedUnit: "g", unitSuffix: "\xA0CO₂e/kWh", category: "weight" }
  },
  [FactorEndpointId.Transportation_W2w]: {
    biogas: { base: "t", category: "weight", unitSuffix: "\xA0CO₂e/kWh", displayedUnit: "g" },
    dieselBio: { base: "t", category: "weight", unitSuffix: "\xA0CO₂e/kWh", displayedUnit: "g" },
    dieselFossil: { base: "t", category: "weight", unitSuffix: "\xA0CO₂e/kWh", displayedUnit: "g" },
    gasolineBio: { base: "t", category: "weight", unitSuffix: "\xA0CO₂e/kWh", displayedUnit: "g" },
    gasolineFossil: { base: "t", category: "weight", unitSuffix: "\xA0CO₂e/kWh", displayedUnit: "g" },
    kerosene: { base: "t", category: "weight", unitSuffix: "\xA0CO₂e/kWh", displayedUnit: "g" },
    liquefiedPetroleumGas: { base: "t", category: "weight", unitSuffix: "\xA0CO₂e/kWh", displayedUnit: "g" },
    naturalGas: { base: "t", category: "weight", unitSuffix: "\xA0CO₂e/kWh", displayedUnit: "g" }
  },
  [FactorEndpointId.Transportation_FactorKmPerEnergySource]: {
    vehicleType: PLACEHOLDER,
    roadType: PLACEHOLDER,
    fuelType: PLACEHOLDER,
    factorsByYear: { ...PERCENTAGE_UNIT, customTitle: true }
  },
  [FactorEndpointId.Transportation_FactorKwhPerKmPerEnergySource]: {
    vehicleType: PLACEHOLDER,
    roadType: PLACEHOLDER,
    fuelType: PLACEHOLDER,
    factorsByYear: { category: "decimal", base: "k", displayedUnit: "k", unitSuffix: "Wh/Fz-km" }
  },
  [FactorEndpointId.Transportation_DieselFraction]: {
    dieselBio: { ...PERCENTAGE_UNIT },
    dieselFossil: { ...PERCENTAGE_UNIT }
  }
};

export type FactorGenericKeyValueDto<T> = {
  [K in keyof T]: Record<string, number> | undefined;
};

export interface IFactorResponseDtoMap {
  [FactorEndpointId.Waste_Biowaste]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Waste_Biowaste]
  >;
  [FactorEndpointId.Waste_BiowasteCh]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Waste_BiowasteCh]
  >;
  [FactorEndpointId.Waste_WastewaterProteinIntake]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Waste_WastewaterProteinIntake]
  >;
  [FactorEndpointId.Waste_WastewaterSewageEmission]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Waste_WastewaterSewageEmission]
  >;
  [FactorEndpointId.Waste_WastewaterCh]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Waste_WastewaterCh]
  >;
  [FactorEndpointId.Waste_WastewaterEmissionCh]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Waste_WastewaterEmissionCh]
  >;

  [FactorEndpointId.Waste_SewageSludgeNitrogen]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Waste_SewageSludgeNitrogen]
  >;
  [FactorEndpointId.Waste_SewageSludgeNitrus]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Waste_SewageSludgeNitrus]
  >;
  [FactorEndpointId.Afolu_Land]: FactorGenericKeyValueDto<typeof FactorEndpointAttributes[FactorEndpointId.Afolu_Land]>;
  [FactorEndpointId.Afolu_LivestockMethaneManure]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Afolu_LivestockMethaneManure]
  >;
  [FactorEndpointId.Afolu_LivestockMethaneDigestion]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Afolu_LivestockMethaneDigestion]
  >;
  [FactorEndpointId.Afolu_LivestockNitrusOxideManure]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Afolu_LivestockNitrusOxideManure]
  >;
  [FactorEndpointId.Stationary_Final]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Stationary_Final]
  >;
  [FactorEndpointId.Stationary_Primary]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Stationary_Primary]
  >;
  [FactorEndpointId.Transportation_FactorKmPerEnergySource]: IFactorTransportation_VehicleDto;
  [FactorEndpointId.Transportation_FactorKwhPerKmPerEnergySource]: IFactorTransportation_VehicleDto;
  [FactorEndpointId.Transportation_W2w]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Transportation_W2w]
  >;
  [FactorEndpointId.Transportation_DieselFraction]: FactorGenericKeyValueDto<
    typeof FactorEndpointAttributes[FactorEndpointId.Transportation_DieselFraction]
  >;
}

export type AnyFactorEmissionResponseDto = IFactorResponseDtoMap[keyof IFactorResponseDtoMap];

export const FactorEndpointConf: Record<FactorEndpointId, { url: string }> = {
  // Waste
  [FactorEndpointId.Waste_Biowaste]: { url: "factors/emission/waste/biowaste" },
  [FactorEndpointId.Waste_BiowasteCh]: { url: "factors/emission/ch/waste/biowaste" },
  [FactorEndpointId.Waste_WastewaterProteinIntake]: {
    url: "factors/emission/waste/wastewater/protein-intake"
  },
  [FactorEndpointId.Waste_WastewaterSewageEmission]: {
    url: "factors/emission/waste/wastewater/sewage-emission"
  },
  [FactorEndpointId.Waste_WastewaterCh]: {
    url: "factors/emission/ch/waste/wastewater"
  },
  [FactorEndpointId.Waste_WastewaterEmissionCh]: {
    url: "factors/emission/ch/waste/wastewater-emission"
  },
  [FactorEndpointId.Waste_SewageSludgeNitrogen]: {
    url: "factors/emission/waste/sewage-sludge/nitrogen"
  },
  [FactorEndpointId.Waste_SewageSludgeNitrus]: {
    url: "factors/emission/waste/sewage-sludge/nitrus"
  },
  // Afolu
  [FactorEndpointId.Afolu_Land]: {
    url: "factors/emission/afolu/land"
  },
  [FactorEndpointId.Afolu_LivestockMethaneManure]: {
    url: "factors/emission/afolu/livestock/methane_manure"
  },
  [FactorEndpointId.Afolu_LivestockMethaneDigestion]: {
    url: "factors/emission/afolu/livestock/methane_digestion"
  },
  [FactorEndpointId.Afolu_LivestockNitrusOxideManure]: {
    url: "factors/emission/afolu/livestock/nitrus_oxide_manure"
  },
  // Stationary
  [FactorEndpointId.Stationary_Final]: {
    url: "factors/emission/stationary/final"
  },
  [FactorEndpointId.Stationary_Primary]: {
    url: "factors/emission/stationary/primary"
  },
  // Transportation
  [FactorEndpointId.Transportation_FactorKmPerEnergySource]: {
    url: "factors/publicsector/transportation/ratio/factor_km_per_energy_source"
  },
  [FactorEndpointId.Transportation_FactorKwhPerKmPerEnergySource]: {
    url: "factors/publicsector/transportation/ratio/factor_kwh_per_km_per_energy_source"
  },
  [FactorEndpointId.Transportation_W2w]: {
    url: "factors/emission/transportation/w2w"
  },
  [FactorEndpointId.Transportation_DieselFraction]: {
    url: "factors/publicsector/transportation/diesel-fraction"
  }
};
