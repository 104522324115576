import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { Accounting } from "../model/accounting";

export const ACTIVE_ACCOUNTING_SERVICE_TOKEN = new InjectionToken<IActiveAccountingService>(
  "ACTIVE_ACCOUNTING_SERVICE_TOKEN"
);

export interface IActiveAccountingService {
  getActiveAccounting(): Observable<Accounting>;
  getActiveAccounting1990(): Observable<Accounting>;
}
