import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { Accounting } from "../../../../accounting-common/model/accounting";
import { inputIsNotNullOrUndefined } from "../../../../../common/utils/util-rxjs";

@Component({
  selector: "co2-kpi-emissions-per-capita",
  templateUrl: "./kpi-emissions-per-capita.component.html",
  styleUrls: ["../kpi.component.scss"]
})
export class KpiEmissionsPerCapitaComponent implements OnInit {
  public emissionsPerCapita$?: Observable<number | null>;
  public accounting$ = new BehaviorSubject<Accounting | null>(null);

  @Input() public set accounting(accounting: Accounting | null) {
    this.accounting$.next(accounting);
  }

  ngOnInit() {
    this.emissionsPerCapita$ = this.accounting$.pipe(
      filter(inputIsNotNullOrUndefined),
      switchMap((accounting) => {
        return combineLatest([accounting.getTotalEmissionsPerSector(), accounting.getRegionProperties()]);
      }),
      map(([accounting, region]) => (region?.population ? accounting.getTotal() / region?.population : null))
    );
  }
}
