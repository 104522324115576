import { Component } from "@angular/core";
import { EneTopicComponent } from "../topic/topic.component";

@Component({
  selector: "ene-accordeon",
  templateUrl: "./accordeon.component.html"
})
export class EneAccordeonComponent {
  public topics: EneTopicComponent[] = [];

  public addTopic(topic: EneTopicComponent) {
    this.topics.push(topic);
  }

  /**
   * Changes active topic
   * @param  topic
   */
  public changeTopic(topic: EneTopicComponent) {
    if (topic.active) {
      topic.active = false;
    } else {
      this.topics.forEach((element) => {
        element.active = false;
      });
      topic.active = true;
    }
  }
}
