// tslint:disable:component-selector

import {
  Component,
  OnChanges,
  ViewEncapsulation,
  ViewChild,
  Input,
  HostListener,
  Output,
  EventEmitter
} from "@angular/core";
import { NouiFormatter, NouisliderComponent } from "ng2-nouislider";

@Component({
  selector: "ene-range-slider",
  templateUrl: "./range-slider.component.html",
  styleUrls: [
    "../../../../../../../node_modules/nouislider/distribute/nouislider.min.css",
    "./range-slider.component.scss"
  ],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-range-slider"
  }
})
export class EneRangeSliderComponent implements OnChanges {
  @ViewChild("nouislider", { static: true })
  public nouislider: NouisliderComponent;

  @Output()
  public change: EventEmitter<boolean> = new EventEmitter();

  // copied from ng2-nouislider
  @Input() public disabled: boolean;
  @Input() public behaviour: string;
  @Input() public connect: boolean[];
  @Input() public limit: number;
  @Input() public min: number;
  @Input() public max: number;
  @Input() public step: number;
  @Input() public format: NouiFormatter;
  @Input() public pageSteps: number;
  @Input() public config: any = {};
  @Input() public ngModel: number | number[];
  @Input() public keyboard: boolean;
  @Input() public onKeydown: any;
  @Input() public tooltips: Array<any>;

  constructor() {}

  // onAllClick only does something if labelclick = true
  @HostListener("click") public onAllClick() {}

  public onChange(event: any) {
    this.change.emit(event);
  }

  public ngOnChanges(changes: any) {
    if (changes.min || changes.max || changes.step || changes.ngModel) {
      setTimeout(() => {
        const newOptions: any = {};

        if (
          (changes.hasOwnProperty("min") && !changes.min.firstChange) ||
          (changes.hasOwnProperty("max") && !changes.max.firstChange)
        ) {
          newOptions.range = {
            min: this.min,
            max: this.max
          };
        }

        if (changes.hasOwnProperty("step") && !changes.step.firstChange) {
          newOptions.step = this.step;
        }

        if (changes.hasOwnProperty("ngModel") && !changes.ngModel.firstChange) {
          this.nouislider.slider.set(this.ngModel);
        }

        if (newOptions !== {}) {
          this.nouislider.slider.updateOptions(newOptions);
        }
      });
    }
  }
}
