// Find sources at https://enersis.atlassian.net/wiki/spaces/CCB/pages/1447428107/Ma+nahmen+bersicht

export const averageDataSorted = [
  // Sektorübergreifende Maßnahmen
  { title: "Informations-/Bildungsarbeit", sector: "global", active: true, status: 3, key: "MEASURE.ALL_MEASURES.EDUCATIONAL_WORK" },
  { title: "Mitarbeitermotivation", sector: "global", active: true, status: 1, key: "MEASURE.ALL_MEASURES.EMPLOYEE_MOTIVATION" },
  { title: "Regionalisierung", sector: "global", active: true, status: 2, key: "MEASURE.ALL_MEASURES.REGIOANLIZATION" },
  // Stationäre Energie
  { title: "Ausbau Erneuerbare Energien", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.EXPANSION_RENEWABLES" },
  { title: "Energetische Stadtsanierung", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.ENERGETIC_URBAN_REDEVELOPMENT" },
  { title: "Energieeffiziente Straßenbeleuchtung", sector: "stationaryEnergy", active: true, status: 1, key: "MEASURE.ALL_MEASURES.EFFICIENT_LIGHTNING" },
  { title: "Energiemix Kühlung", sector: "stationaryEnergy", active: true, status: 1, key: "MEASURE.ALL_MEASURES.ENERGYMIX_COOLING" },
  { title: "Energiemix Strom", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.ENERGYMIX_ELECTRICITY" },
  { title: "Energiemix Warmwasser", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.ENERGYMIX_HOT_WATER" },
  { title: "Energiemix Wärme", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.ENERGYMIX_HEAT" },
  { title: "Förderprogramme", sector: "stationaryEnergy", active: true, status: 3, key: "MEASURE.ALL_MEASURES.FUNDING_PROGRAMS" },
  { title: "Gebäudeneubau", sector: "stationaryEnergy", active: true, status: 1, key: "MEASURE.ALL_MEASURES.NEW_BUILDING_CONSTRUCTION" },
  { title: "Gebäudesanierung", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.BUILDING_RENOVATION" },
  { title: "KWK & Fernwärmeausbau", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.DISTRICT_HEATING_EXPANSION" },
  { title: "Kläranlagen", sector: "stationaryEnergy", active: true, status: 1, key: "MEASURE.ALL_MEASURES.PURIFICATION_PLANT" },
  { title: "Kommunale Wärmeplanung", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.MUNICIPAL_HEATING_PLANNING" },
  { title: "Müllverbrennung und Biogasanlagen", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.WASTE_INCINERATION" },
  { title: "Vorbildfunktion Bundesgebäude", sector: "stationaryEnergy", active: true, status: 2, key: "MEASURE.ALL_MEASURES.EXEMPLARY_FUNCTION" },
  // Verkehr
  { title: "Ausbau und Optimierung des Radverkehrs", sector: "transportation", active: true, status: 2, key: "MEASURE.ALL_MEASURES.CYCLING_EXPANSION" },
  { title: "Elektromobilität", sector: "transportation", active: true, status: 2, key: "MEASURE.ALL_MEASURES.ELECTROMOBILITY" },
  { title: "Emissionsanforderungen", sector: "transportation", active: true, status: 1, key: "MEASURE.ALL_MEASURES.EMISSION_REQUIREMENTS" },
  { title: "Nachhaltige PKW-Nutzung", sector: "transportation", active: true, status: 1, key: "MEASURE.ALL_MEASURES.SUSTAINABLE_CAR_USE" },
  { title: "Verkehrslenkung", sector: "transportation", active: true, status: 1, key: "MEASURE.ALL_MEASURES.TRAFFIC_CONTROL" },
  { title: "Öffentlicher Nahverkehr", sector: "transportation", active: true, status: 2, key: "MEASURE.ALL_MEASURES.LOCAL_PUBLIC_TRANSPORT" },
  // Landwirtschaft
  { title: "Grünlanderhalt", sector: "afolu", active: true, status: 2, key: "MEASURE.ALL_MEASURES.GRASSLAND_CONSERVATION" },
  { title: "Humuserhalt und Humusaufbau", sector: "afolu", active: true, status: 2, key: "MEASURE.ALL_MEASURES.HUMUS_PRESERVATION" },
  { title: "Nachhaltige Waldbewirtschaftung", sector: "afolu", active: true, status: 2, key: "MEASURE.ALL_MEASURES.SUSTAINABLE_FOREST_MANAGEMENT" },
  { title: "Naturschutz", sector: "afolu", active: true, status: 2, key: "MEASURE.ALL_MEASURES.NATURE_CONSERVANCY" },
  { title: "Reduktion von Düngemitteln", sector: "afolu", active: true, status: 2, key: "MEASURE.ALL_MEASURES.REDUCTION_OF_FERTILIZERS" },
  { title: "Renaturierung von Mooren", sector: "afolu", active: true, status: 2, key: "MEASURE.ALL_MEASURES.RENATURATION_OF_BOGS" },
  { title: "Ökolandbau", sector: "afolu", active: true, status: 2, key: "MEASURE.ALL_MEASURES.ORGANIC_FARMING" },
  // Industrie
  { title: "Optimierung von Industrieprozessen", sector: "ippu", active: true, status: 3, key: "MEASURE.ALL_MEASURES.OPTIMISATION_OF_INDUSTRIAL_PROCESSES" },
  // Abfall und Abwasser
  { title: "Müllvermeidung", sector: "waste", active: true, status: 2, key: "MEASURE.ALL_MEASURES.WASTE_AVOIDANCE" },
  { title: "Recycling", sector: "waste", active: true, status: 2, key: "MEASURE.ALL_MEASURES.RECYCLING" },
];
