import { trackEvent } from "@redux-beacon/google-analytics";
import { GetTour } from "../../state/tour/tour-actions";

export const guidedTour = trackEvent((action: GetTour) => {
  const response: any = {
    category: "guidedTour",
    action: action.tour,
    label: false
  };

  return response;
});
