import { Action, ActionReducer, combineReducers } from "@ngrx/store";
import { IApplicationState } from "../index";
import { IConsumptionState, reducer as consumptionReducer } from "./publicSector/consumption/consumption-reducer";
import { ICo2SavingState } from "./publicSector/co2-saving/fixed-year/co2-saving-reducer";
import { ICo2SavingForAllYearsState } from "./publicSector/co2-saving/all-years/co2-saving-reducer";
import { reducer as co2SavingReducer } from "./publicSector/co2-saving/fixed-year/co2-saving-reducer";
import { reducer as co2SavingFroAllYearsReducer } from "./publicSector/co2-saving/all-years/co2-saving-reducer";

import {
  IEMobilityState,
  reducer as eMobilityReducer
} from "./publicSector/connection-group/e-mobility/e-mobility-reducer";
import { IHeatpumpState, reducer as heatpumpReducer } from "./publicSector/connection-group/heatpump/heatpump-reducer";

export interface IEnergyState {
  publicSector: {
    "connection-group": {
      "e-mobility": IEMobilityState;
      heatpump: IHeatpumpState;
    };
    consumption: IConsumptionState;
    "co2-saving": ICo2SavingState;
    "co2-saving-for-all-years": ICo2SavingForAllYearsState;
  };
}

export const reducer: ActionReducer<any, Action> = combineReducers({
  publicSector: combineReducers({
    "connection-group": combineReducers({
      "e-mobility": eMobilityReducer,
      heatpump: heatpumpReducer
    }),
    consumption: consumptionReducer,
    "co2-saving": co2SavingReducer,
    "co2-saving-for-all-years": co2SavingFroAllYearsReducer,
    "data-input": combineReducers({
      "stationary-energy": undefined
    })
  })
});

export const getEnergyState = (state: IApplicationState) => state && state.app && state.app.energy;

export const getPublicSectorState = (state: IApplicationState) =>
  getEnergyState(state) && getEnergyState(state).publicSector;
