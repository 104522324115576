import { IUnitOption } from "../../../../configs/data-panel";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromDataPanel from "../index";

export * from "./data-input.actions";
export * from "./data-input.effects";
export * from "./data-input.reducer";

export interface State {
  metadata: string;
  unitOptions: IUnitOption;
  data: any;
  locked: boolean;
  loading: boolean;
  errorMessage?: string;
}

const selectDataPanelState = createFeatureSelector<fromDataPanel.DataPanelState>("dataPanel");

export const getData = createSelector(selectDataPanelState, (state) => state.dataInput.data);

export const isDataLoading = createSelector(selectDataPanelState, (state) => state.dataInput.loading);

export const getErrorMessage = createSelector(selectDataPanelState, (state) => state.dataInput.errorMessage);

export const getMetadata = createSelector(selectDataPanelState, (state) => state.dataInput.metadata);

export const getDataWithMetadata = createSelector(getData, getMetadata, (data, metadata) => ({ data, metadata }));

export const getUnit = createSelector(selectDataPanelState, (state) => state && state.dataInput.unitOptions);

export const getLockedState = createSelector(selectDataPanelState, (state) => state && state.dataInput.locked);
