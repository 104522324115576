import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { BuildingsCount } from "../../../accounting-common/data/region-properties-data.service";
import { Accounting } from "../../../accounting-common/model/accounting";
import { RegionProperties } from "../../../accounting-common/model/population/region-properties";
import { inputIsNotNullOrUndefined } from "../../../../common/utils/util-rxjs";

@Component({
  selector: "co2-kpi-region-indicators",
  templateUrl: "./kpi-region-indicators.component.html",
  styleUrls: ["./kpi-region-indicators.component.scss"]
})
export class KpiRegionIndicatorsComponent implements OnInit {
  public regionProperties$!: Observable<RegionProperties | null>;
  public regionBuildingsCount$!: Observable<BuildingsCount | null>;

  private accounting$ = new BehaviorSubject<Accounting | null>(null);

  @Input() public set accounting(accounting: Accounting | null) {
    this.accounting$.next(accounting);
  }

  ngOnInit() {
    this.accounting$.pipe(filter(inputIsNotNullOrUndefined)).subscribe((accounting) => {
      this.regionProperties$ = accounting.getRegionProperties();
      this.regionBuildingsCount$ = accounting.getRegionBuildingsCount();
    });
  }

  ngOnDestroy() {
    this.accounting$.complete();
  }
}
