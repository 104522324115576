<app-accounting-info-wrapper
  [disabledPrimary]="hidePrimaryEnergy"
  [dqi]="dqi"
  (method)="onChange($event)"
  [config]="displayConfig || defaultDisplayConfig"
>
  <div style="margin-right: 1em; position: relative;" *ngIf="!hideExport">
    <gp-chart-export-menu [chartRef]="chart?.chart"></gp-chart-export-menu>
  </div>
  <ene-chart #chart type="column" [data]="chartData" [settings]="chartConfig"> </ene-chart>
</app-accounting-info-wrapper>
