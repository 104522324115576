<gp-kpi
  data-test="kpi-co2Emission"
  [kpiTitle]="'KPI.EMISSIONCO2' | translate"
  icon="gpi-filled-co2-emissions-pro-capita"
  [value]="emissionsPerCapita$ | async"
  [unitConfig]="{
    category: 'weight',
    base: 't',
    omitUnits: ['Mt', 'Gt'],
    unitSuffix: 'APP.PER_CAPITA' | translate,
    numberFormat: {
      maximumFractionDigits: 1
    }
  }"
>
  <app-weather-correction-indicator
    slot="indicator"
    [weatherCorrection]="(accounting$ | async)?.weatherCorrection"
  ></app-weather-correction-indicator>
</gp-kpi>
