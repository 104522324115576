import { createSelector } from "@ngrx/store";
import { IApplicationState } from "../../index";

export * from "./main-polluter.actions";
export * from "./main-polluter.effects";
export * from "./main-polluter.reducer";

export const getStationaryEnergyData =
  (state: IApplicationState) => state && state.app && state.app.mainPolluter.stationaryEnergy;

export const getTransportationData =
  (state: IApplicationState) => state && state.app && state.app.mainPolluter.transportation;

export const getAllData = createSelector(
  getStationaryEnergyData,
  getTransportationData,
  (stationaryEnergy, transportation) => ({ stationaryEnergy, transportation })
);

export const getStationaryEnergyDataBySector = createSelector(
  getStationaryEnergyData,
  (stationaryEnergy, { sector }) => stationaryEnergy[sector] || {}
);
