export const chartStylesPresets: any = {
  gauge: {
    medium: {
      legend: {
        enabled: true
      }
    },
    small: {
      legend: {
        enabled: false
      }
    }
  }
};
