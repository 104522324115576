import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DqiTableComponent } from "./dqi-table.component";
import { EneFormsModule } from "@energy-city/ui/forms";

export * from "./dqi-table.component";

@NgModule({
  imports: [CommonModule, EneFormsModule],
  exports: [DqiTableComponent],
  declarations: [DqiTableComponent]
})
export class DqiTableModule {}
