import { LayerControlSection } from "@enersis/gp-components/gp-map2-layer-control";
import { IGpAnyLayerInput, IGpViewerSource } from "@enersis/gp-components/gp-map2-viewer";

export class BuildingLayerConfig {
  getSource(baseUrl: string, regionId: string, year: number, isYearFix = false): IGpViewerSource {
    const urlParams: { regionId: string; year?: number } = { regionId };
    if (!isYearFix) {
      urlParams.year = year;
    }
    return {
      type: "vector",
      // changed to settings of buildings, was minzoom: 14, maxzoom: 14
      minzoom: 11,
      maxzoom: 16,
      tiles: [`${baseUrl}/map/buildings/{z}/{x}/{y}${isYearFix ? "?year=" + year : ""}`],
      urlParams
    };
  }

  getLayerConfig(): IGpAnyLayerInput {
    return {
      id: "building",
      type: "fill-extrusion",
      source: "building/building",
      defaultStyle: {
        "fill-extrusion-height": ["get", "height"],
        "fill-extrusion-opacity": ["interpolate", ["linear"], ["zoom"], 11, 0, 12, 1],
        "fill-extrusion-color": "#686868"
      },
      thematics: [{ label: "MAP2.LAYERS.BUILDINGS.THEMATICS.BUILDINGS_3D" }]
    };
  }

  getLayerControlConfig(): LayerControlSection {
    return {
      type: "section",
      id: "building-section",
      label: "MAP2.LAYERS.BUILDINGS.LABEL",
      activityControlType: "icon",
      childrenType: "switch",
      children: ["building"]
    };
  }
}
