import { FactorEndpointId } from "./factor-management.endpoint-config";
import { ColumnConfig } from "./table/table.interface";
export type FactorSector = "stationary-energy" | "transportation" | "afolu" | "ippu" | "waste";

export interface FactorSubCategory {
  id: FactorSubCategories;
  name: string;
  columConfig: Array<ColumnConfig>;
  endpoints: Array<FactorEndpointId>;
  endpointsCh?: Array<FactorEndpointId>;
}

export interface FactorCategory {
  id: FactorSector;
  name: string;
  icon: string;
  subCategories?: Array<FactorSubCategory>;
}

export enum FactorSubCategories {
  Waste_Biowaste,
  Waste_Wastewater,
  Waste_Sewage,
  Afolu_Land,
  Afolu_LivestockMethaneManure,
  Afolu_LivestockMethaneDigestion,
  Afolu_LivestockNitrusOxideManure,
  Stationary_Final,
  Stationary_Primary,
  Transportation_FactorKmPerEnergySource,
  Transportation_FactorKwhPerKmPerEnergySource,
  Transportation_W2w,
  Transportation_DieselFraction
}

const KeyValueTableColumns: Array<ColumnConfig> = [
  {
    id: "name",
    title: "Bezeichnung",
    sticky: true
  },
  {
    id: "unit",
    title: "Einheit",
    sticky: true
  }
];

const TransportationFactorComums: Array<ColumnConfig> = [
  {
    id: "vehicleType",
    title: "Fz-Kat",
    sticky: true
  },
  {
    id: "roadType",
    title: "SK",
    sticky: true
  },
  {
    id: "fuelType",
    title: "Treibstoff",
    sticky: true
  },
  {
    id: "unit",
    title: "Einheit",
    sticky: true
  }
];

export const CategoriesConfig: Array<FactorCategory> = [
  {
    id: "transportation",
    name: "RESOURCES.TRANSPORTATION",
    icon: "ec_verkehr",
    subCategories: [
      {
        id: FactorSubCategories.Transportation_FactorKmPerEnergySource,
        name: "Fahrleistungsaufteilung Straße",
        endpoints: [FactorEndpointId.Transportation_FactorKmPerEnergySource],
        columConfig: TransportationFactorComums
      },
      {
        id: FactorSubCategories.Transportation_FactorKwhPerKmPerEnergySource,
        name: "Verbrauchsfaktoren Straße",
        endpoints: [FactorEndpointId.Transportation_FactorKwhPerKmPerEnergySource],
        columConfig: TransportationFactorComums
      },
      {
        id: FactorSubCategories.Transportation_W2w,
        name: "THG-Faktoren der Kraftstoffe",
        endpoints: [FactorEndpointId.Transportation_W2w],
        columConfig: KeyValueTableColumns
      },
      {
        id: FactorSubCategories.Transportation_DieselFraction,
        name: "Anteile von Biodiesel",
        endpoints: [FactorEndpointId.Transportation_DieselFraction],
        columConfig: KeyValueTableColumns
      }
    ]
  },
  {
    id: "stationary-energy",
    name: "RESOURCES.STATIONARYENERGY",
    icon: "ec_station",
    subCategories: [
      {
        id: FactorSubCategories.Stationary_Final,
        name: "Emissionsfaktoren für Endenergieverbrauch",
        endpoints: [FactorEndpointId.Stationary_Final],
        columConfig: KeyValueTableColumns
      },
      {
        id: FactorSubCategories.Stationary_Primary,
        name: "Emissionsfaktoren für Erzeugung",
        endpoints: [FactorEndpointId.Stationary_Primary],
        columConfig: KeyValueTableColumns
      }
    ]
  },
  {
    id: "afolu",
    name: "RESOURCES.AFOLU",
    icon: "ec_landwirtschaft",
    subCategories: [
      {
        id: FactorSubCategories.Afolu_Land,
        name: "Emissionsfaktoren zu Landnutzungskategorien",
        endpoints: [FactorEndpointId.Afolu_Land],
        columConfig: KeyValueTableColumns
      },
      {
        id: FactorSubCategories.Afolu_LivestockMethaneManure,
        name: "Wirtschaftsdünger Management Methan",
        endpoints: [FactorEndpointId.Afolu_LivestockMethaneManure],
        columConfig: KeyValueTableColumns
      },
      {
        id: FactorSubCategories.Afolu_LivestockMethaneDigestion,
        name: "Methan für Verdauung",
        endpoints: [FactorEndpointId.Afolu_LivestockMethaneDigestion],
        columConfig: KeyValueTableColumns
      },
      {
        id: FactorSubCategories.Afolu_LivestockNitrusOxideManure,
        name: "N2O für Wirtschaftsdünger Management",
        endpoints: [FactorEndpointId.Afolu_LivestockNitrusOxideManure],
        columConfig: KeyValueTableColumns
      }
    ]
  },
  /* {
    id: "ippu",
    name: "RESOURCES.IPPU",
    icon: "ec_industrie"
  }, */
  {
    id: "waste",
    name: "RESOURCES.WASTEANDWASTEWATER",
    icon: "ec_abfall",
    subCategories: [
      {
        id: FactorSubCategories.Waste_Biowaste,
        name: "Bioabfall",
        endpoints: [FactorEndpointId.Waste_Biowaste],
        endpointsCh: [FactorEndpointId.Waste_BiowasteCh],
        columConfig: KeyValueTableColumns
      },
      {
        id: FactorSubCategories.Waste_Wastewater,
        name: "Abwasser",
        endpoints: [FactorEndpointId.Waste_WastewaterProteinIntake, FactorEndpointId.Waste_WastewaterSewageEmission],
        endpointsCh: [FactorEndpointId.Waste_WastewaterCh, FactorEndpointId.Waste_WastewaterEmissionCh],
        columConfig: KeyValueTableColumns
      },
      {
        id: FactorSubCategories.Waste_Sewage,
        name: "Klärschlamm",
        endpoints: [FactorEndpointId.Waste_SewageSludgeNitrogen, FactorEndpointId.Waste_SewageSludgeNitrus],
        columConfig: KeyValueTableColumns
      }
    ]
  }
];
