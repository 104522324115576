export const colorMap = {
  afolu: "#ED7D31",
  ippu: "#4A4A4A",
  transportation: "#008797",
  stationary_energy: "#5D2F58",
  waste: "#8AAE1B"
};

export const chartConfig = {
  chart: {
    type: "column",
    marginTop: 30,
    marginRight: 30
  },
  xAxis: {
    labels: {
      style: {
        fontSize: "0.9em"
      }
    }
  },
  yAxis: {
    min: 0,
    title: {
      useHTML: true,
      style: {
        padding: "5px 15px",
        "border-radius": "4px",
        "background-color": "var(--ene-color-WSW)"
      }
    },
    stackLabels: {
      enabled: false
    },
    labels: {
      overflow: false
    }
  },
  tooltip: {
    enabled: true,
    valueDecimals: 2
  },
  legend: {
    useHTML: true,
    itemMarginBottom: 5,
    itemDistance: 16,
    symbolRadius: 2,
    symbolPadding: 10,
    itemStyle: {
      fontWeight: "normal",
      fontSize: "0.9em",
      lineHeight: 2
    }
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: false
      }
    },
    series: {
      pointWidth: 45,
      // Fix for HAN-578: Tooltip not available for Firefox
      clip: false
    }
  },
  exporting: {
    chartOptions: {
      chart: {
        height: 620,
        width: 1170
      }
    }
  }
};
