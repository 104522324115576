import { Injectable, OnDestroy } from "@angular/core";
import { AuthenticationService, environment, TimelineUpdate, UtilService } from "@energy-city/components";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";

import { inputIsNotNullOrUndefined } from "../utils/util-rxjs";

// ngrx to dispatch legacy events
import { Store } from "@ngrx/store";
import * as fromRoot from "@energy-city/components";

@Injectable({
  providedIn: "root"
})
export class TimelineService implements OnDestroy {
  private selectedYear: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  private destroy$: Subject<null> = new Subject();

  constructor(
    private authService: AuthenticationService,
    private ngrxStore: Store<fromRoot.IApplicationState>,
    private utilService: UtilService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private get timelineRange(): number[] {
    return [1990, 2050];
  }

  public getStartYear(): number {
    return this.timelineRange[0];
  }

  public getEndYear(): number {
    return this.timelineRange[1];
  }

  public getInitialYear(): number {
    return this.authService.tokenParsed.timelineYear
      ? this.authService.tokenParsed.timelineYear
      : environment.selected_year;
  }

  public getYearsWithData(): Array<number> {
    const firstYearWithData = this.authService.tokenParsed.timelineRange[0];
    const lastYearWithData = this.authService.tokenParsed.timelineRange[1];
    return Array.from({ length: lastYearWithData - firstYearWithData + 1 }, (_, i) => i + firstYearWithData);
  }

  public getSelectedYear(): Observable<number> {
    return this.selectedYear.pipe(filter(inputIsNotNullOrUndefined));
  }

  public setSelectedYear(year: number): void {
    // to avoid unwanted update circles, only updated if the new year is different
    // from the currently selected one.
    if (this.selectedYear.getValue() !== year) {
      this.selectedYear.next(year);
      this.ngrxStore.dispatch(new TimelineUpdate(year));
      this.utilService.setSelectedYear(year);
    }
  }
}
