/* tslint:disable:member-access */

import { Action } from "@ngrx/store";

export enum ChartBenchmarkingActionType {
  GET_DATA = "[ChartBenchmarking] GET_DATA",
  GET_DATA_SUCCESS = "[ChartBenchmarking] GET_DATA_SUCCESS",
  GET_KPI = "[ChartBenchmarking] GET_KPI",
  GET_KPI_SUCCESS = "[ChartBenchmarking] GET_KPI_SUCCESS",
  ERROR = "[ChartBenchmarking] ERROR"
}

export class GetChartBenchmarkingData implements Action {
  type = ChartBenchmarkingActionType.GET_DATA;
  payload = null;
  constructor(public ags: string, public year: number) {}
}
export class GetChartBenchmarkingDataSuccess implements Action {
  type = ChartBenchmarkingActionType.GET_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class GetChartBenchmarkingKpi implements Action {
  type = ChartBenchmarkingActionType.GET_KPI;
  payload = null;
  constructor(public ags: string, public year: number) {}
}
export class GetChartBenchmarkingKpiSuccess implements Action {
  type = ChartBenchmarkingActionType.GET_KPI_SUCCESS;
  constructor(public payload: any) {}
}
export class ChartBenchmarkingError implements Action {
  type = ChartBenchmarkingActionType.ERROR;
  constructor(public payload: any) {}
}

export type ChartBenchmarkingActions =
  | GetChartBenchmarkingData
  | GetChartBenchmarkingDataSuccess
  | GetChartBenchmarkingKpi
  | GetChartBenchmarkingKpiSuccess
  | ChartBenchmarkingError;
