import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  GetAccountingMethodsConfigActionType,
  GetAccountingMethodsConfig,
  GetAccountingMethodsConfigSuccess,
  GetAccountingMethodsConfigError
} from "./get-accounting-methods-config-actions";
import { switchMap, map, catchError } from "rxjs/operators";
import { acceptJsonWithoutCache } from "../../../common";
import { Observable, of } from "rxjs";
import { environment } from "../../../../../environments/environment";

@Injectable()
export class GetAccountingMethodsConfigEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  @Effect() public getAccountingMethodsConfigEffects$: Observable<any> = this.actions$.pipe(
    ofType(GetAccountingMethodsConfigActionType.GET_ACCOUNTING_METHODS_CONFIG),
    switchMap((action: GetAccountingMethodsConfig) => {
      const url: string = environment.accountingMethodConfig; // Please replace with correct url
      return this.http
        .get(url, {
          headers: new HttpHeaders(acceptJsonWithoutCache)
        })
        .pipe(
          map((config: any) => new GetAccountingMethodsConfigSuccess(config)),
          catchError(
            (error): Observable<GetAccountingMethodsConfigError> => of(new GetAccountingMethodsConfigError(error))
          )
        );
    })
  );
}
