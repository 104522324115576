<div id="dataPrivacy">
  <header mat-dialog-title>
    <div class="dialog-title">
      <ene-icon *ngIf="dataPrivacy_data.icon" [icon]="dataPrivacy_data.icon"></ene-icon>
      <span>{{ dataPrivacy_title | translate }}</span>
    </div>
    <ene-icon *ngIf="canAbort" icon="ec_building_delete_address" class="closebutton" (click)="close()"> </ene-icon>
  </header>
  <hr *ngIf="dataPrivacy_tabs.length > 1" />
  <ene-tabs *ngIf="dataPrivacy_tabs.length > 1" (currentTabChange)="changeTab($event.tabName)" class="pill">
    <ene-tab
      *ngFor="let tab of dataPrivacy_tabs; let i = index"
      [tabTitle]="tab.title | translate"
      [tabName]="i"
      [active]="i === dataPrivacy_activeTab"
    >
    </ene-tab>
  </ene-tabs>
  <hr />
  <mat-dialog-content
    #dataPrivacyContent
    *ngIf="dataPrivacy_activeTab >= 0 && dataPrivacy_tabs[dataPrivacy_activeTab]"
    class="html-content"
  >
    <div [innerHTML]="getTenantTranslation()"></div>
    <ng-container *ngIf="dataPrivacy_activeTab === 2" class="html-content">
      <ng-container *ngTemplateOutlet="cookies"></ng-container>
    </ng-container>
  </mat-dialog-content>
  <hr />
  <ng-container *ngIf="!isRejecting; else rejection">
    <ng-container *ngTemplateOutlet="checkboxesAndActions"></ng-container>
  </ng-container>

  <ng-template #checkboxesAndActions>
    <mat-dialog-actions>
      <form class="checkboxes-section" [formGroup]="policyForm">
        <mat-checkbox formControlName="terms">
          {{ "COOKIES_POLICY.CHECKBOXES.TERMS" | translate }}
        </mat-checkbox>
        <mat-checkbox formControlName="dsgvo">
          {{ "COOKIES_POLICY.CHECKBOXES.DSGVO" | translate }}
        </mat-checkbox>
        <div *ngIf="{ all: allCookiesSelected$ | async } as cookies">
          <mat-checkbox formControlName="acceptCookies">
            {{
              (cookies.all ? "COOKIES_POLICY.CHECKBOXES.COOKIES.ALL" : "COOKIES_POLICY.CHECKBOXES.COOKIES.SOME")
                | translate
            }}
            <span class="checkboxes-section__cookie-link" (click)="changeToCookieTab()">
              {{
                (cookies.all
                  ? "COOKIES_POLICY.CHECKBOXES.COOKIES.ALL_LINK"
                  : "COOKIES_POLICY.CHECKBOXES.COOKIES.SOME_LINK"
                ) | translate
              }}
            </span>
          </mat-checkbox>
        </div>
      </form>
      <div class="actions">
        <button *ngIf="isPolicyAccepted$ | async" class="ene-button-modal-secondary" (click)="onRejectClicked()">
          {{ "COOKIES_POLICY.BUTTONS.REJECT" | translate }}
        </button>
        <button class="ene-button-cta" mat-dialog-close [disabled]="policyForm.invalid" (click)="submit()">
          {{ "COOKIES_POLICY.BUTTONS.AGREE" | translate }}
        </button>
      </div>
    </mat-dialog-actions>
  </ng-template>
</div>

<ng-template #rejection>
  <div
    class="warning"
    [innerHTML]="
      ((isPolicyAccepted$ | async) ? 'COOKIES_POLICY.INFOS.REJECTION_WARNING' : 'COOKIES_POLICY.INFOS.REJECTED_INFO')
        | translate
    "
  ></div>
  <div class="actions">
    <button class="ene-button-modal-secondary" [disabled]="!(isPolicyAccepted$ | async)" (click)="onDeleteCookies()">
      {{ "COOKIES_POLICY.BUTTONS.DELETE_COOKIES" | translate }}
    </button>

    <button class="ene-button-cta" (click)="onRejectClicked()">
      {{ "COOKIES_POLICY.BUTTONS.BACK_FROM_REJECT" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #cookies>
  <div class="cookies-type" [formGroup]="policyForm">
    <mat-checkbox class="cookies-type__functional" [checked]="true" formControlName="functionalCookies"></mat-checkbox>
    <div class="cookies-type__info" [innerHTML]="'COOKIES_POLICY.TABS.COOKIES.TYPES.FUNCTIONAL' | translate"></div>
  </div>
  <div class="cookies-type" [formGroup]="policyForm">
    <mat-checkbox [checked]="true" formControlName="statisticalCookies"></mat-checkbox>
    <div class="cookies-type__info" [innerHTML]="'COOKIES_POLICY.TABS.COOKIES.TYPES.STATISTIC' | translate"></div>
  </div>

  <div class="cookies-type" [formGroup]="policyForm">
    <mat-checkbox [checked]="true" formControlName="comfortCookies"></mat-checkbox>
    <div class="cookies-type__info" [innerHTML]="'COOKIES_POLICY.TABS.COOKIES.TYPES.COMFORT' | translate"></div>
  </div>
</ng-template>
