import { cloneDeep } from "lodash";

export const Helper = {
  /**
   * Will return a array of all child objects and object itself
   * @param element Could be a deep mix of array and object
   * @param remRef delete deep object reference, also return as new object
   */

  objToArrayDeep(element: any, { remRef = false }: { remRef?: boolean } = {}): { key: string; element: any }[] {
    const list: any[] = [];

    const ObjToArrayRecursive = (element1: any, objKey = "") => {
      if (typeof element1 !== "object" || element1 == null) {
        return;
      }
      if (Array.isArray(element1)) {
        for (let key = 0; key < element1.length; key++) {
          const item = element1[key];
          ObjToArrayRecursive(item, objKey ? `${objKey}.[${key}]` : `[${key}]`);
        }
      } else {
        list.push({ key: objKey, element: element1 });
        for (const [key, item] of Object.entries(element1)) {
          if (remRef && typeof item === "object") {
            delete element1[key];
          }
          ObjToArrayRecursive(item, objKey ? `${objKey}.${key}` : `${key}`);
        }
      }
    };
    ObjToArrayRecursive(remRef ? cloneDeep(element) : element);

    return list;
  }
};
