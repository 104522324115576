import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Footer } from "../../models";

@Component({
  selector: "app-default-footer",
  templateUrl: "./default-footer.component.html",
  styleUrls: ["./default-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultFooterComponent extends Footer {
  public copyrightLink = "http://www.enersis.ch";
  public companyNameFooter = "";

  protected config(): void {
    this.companyNameFooter = this.data.companyNameFooter || "enersis suisse AG";
  }
}
