import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AccountingMethodService } from "../../../../../services/accounting-method.service";
import { AccountingMethod } from "../../../../../configs/accounting-method";
import { assetUrl } from "../../../../../share/asset-url";
import { TenantInfoService } from "../../../../../services/tenantInfo.service";

@Component({
  selector: "app-info-standards",
  templateUrl: "./info-standards.component.html",
  styleUrls: ["./info-standards.component.scss", "../info-sectors.subsectors.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoStandardsComponent implements OnInit {
  @Output() public goToTab: EventEmitter<boolean> = new EventEmitter();
  public methods: Array<AccountingMethod> = [];

  constructor(
    private accountingMethodService: AccountingMethodService,
    private tenantInfoService: TenantInfoService
  ) {}

  public ngOnInit(): void {
    this.accountingMethodService.accountingMethodsMap.forEach(
      (_, key: AccountingMethod) => this.methods.push(key)
    );
  }

  public goToSectors(): void {
    this.goToTab.next(true);
  }

  public getTenantSpecificTranslation(path: string): string {
    return this.tenantInfoService.getTranslation(path);
  }

  public getAssetUrl(url: string): string {
    return assetUrl(url);
  }
}
