import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneHeaderModule } from "@energy-city/ui/header";
import { EneIconModule } from "@energy-city/ui/icon";
import { EnePopperModule } from "@energy-city/ui/popper";
import { EneTooltipModule } from "@energy-city/ui/tooltip";
import { PipesModule } from "../../core/pipe/pipes.module";
import {
  DefaultHeaderComponent,
  Co2BalanceHeaderComponent,
  LaunchpadPopoverComponent,
  HansewerkHeaderComponent
} from "./components";
import { HeaderComponent } from "./header.component";
import { EneModalService, EneModalModule } from "@energy-city/ui/modal";
import {
  NetzeBWUserPopoverComponent
} from "../../modules/header/components/user-popover/components/netzebw-user-popover/netzebw-user-popover.component";
import {
  DefaultUserPopoverComponent
} from "../../modules/header/components/user-popover/components/default-user-popover/default-user-popover.component";
import { HelpPopoverComponent } from "./components/help-popper/help-popover.component";
import { EneTabsModule } from "../../../../../../ui/src/lib/tabs";
import { ModuleSwitchModule } from "../module-switch/module-switch.module";
import { SearchboxModule } from "../searchbox/searchbox.module";
import { DashboardHansewerkComponent } from "../../components/dashboard/hansewerk/dashboard-hansewerk.component";

@NgModule({
  declarations: [
    HeaderComponent,
    DefaultHeaderComponent,
    Co2BalanceHeaderComponent,
    HansewerkHeaderComponent,
    LaunchpadPopoverComponent,
    DashboardHansewerkComponent,
    NetzeBWUserPopoverComponent,
    DefaultUserPopoverComponent,
    HelpPopoverComponent
  ],
  entryComponents: [DefaultHeaderComponent, Co2BalanceHeaderComponent, HansewerkHeaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PipesModule,
    EneHeaderModule,
    EneIconModule,
    EnePopperModule,
    EneTooltipModule,
    EneModalModule,
    EneTabsModule,
    SearchboxModule,
    ModuleSwitchModule
  ],
  providers: [EneModalService],
  exports: [HeaderComponent]
})
export class HeaderModule {}
