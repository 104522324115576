import { TimeSeries } from "../../../accounting-common/model/time-series/time-series";

export class Savings {
  private readonly timeSeries: TimeSeries;

  constructor(newTimeSeries: TimeSeries) {
    this.timeSeries = newTimeSeries;
  }

  /**
   * getCumulativeSavings starts adding the value (saving Value) of the timeSeries together
   * @param startYear from which year on the data should be cumulative added if year is below the specified one the dataEntry will be missing in the result TimeSeries
   * @param endYear up to which data should bue cumulative added
   * @returns TimeSeries with the cumulative savings data
   */
  public getCumulativeSavings(startYear: number, endYear: number): TimeSeries {
    const cumulativeTimeSeries = new TimeSeries([], `cumulative${this.timeSeries.name}`);
    let cumulativeValue = 0;
    for (let year = startYear; year <= endYear; year++) {
      cumulativeValue = cumulativeValue + (this.timeSeries.getValueOfYear(year) || 0);
      cumulativeTimeSeries.data.push({
        year: year,
        value: cumulativeValue
      });
    }
    return cumulativeTimeSeries;
  }
}
