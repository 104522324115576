import { Component, Input } from "@angular/core";
import { Person } from "../contacts.model";

@Component({
  selector: "app-contact-person",
  templateUrl: "./contact-person.component.html",
  styleUrls: ["./contact-person.component.scss"]
})
export class ContactPersonComponent {
  @Input() public person: Person;
}
