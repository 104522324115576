import { trackEvent } from "@redux-beacon/google-analytics";
import { TrackingOnClickEvent } from "../../state/tracking/tracking-actions";

export const onClick = trackEvent((action: TrackingOnClickEvent) => {
  const response: any = {
    category: action.payload.category,
    action: action.payload.buttonName,
    label: false
  };
  return response;
});
