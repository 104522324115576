import { Injectable } from "@angular/core";
import { NguCarouselConfig } from "@ngu/carousel";
import { IMeasure } from "@energy-city/components";
import { colors } from "../../../constants/measures";

export interface IMeasureSector {
  sector: string;
  color: string;
  items: Array<IMeasure>;
}

@Injectable()
export class MeasuresService {
  public carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 600,
    point: {
      visible: true,
      hideOnSingleSlide: true
    },
    touch: false, // drag-gesture worked but somehow it does not change slide, instead it switches back.
    loop: true,
    easing: "ease"
  };
  public bySectors(data: Array<IMeasure> = []) {
    const sectorGroups = data.reduce(
      (acc, item) => ({
        ...acc,
        [item.sector]: {
          sector: item.sector,
          color: colors[item.sector],
          items: [...(acc[item.sector]?.items ?? []), item]
        }
      }),
      {} as { [key: string]: IMeasureSector }
    );

    // measures should be ordered by the following layout (https://enersis.atlassian.net/browse/HAN-573):
    const basicMeasureOrder = [
      "global",           // Sektorenübergreifend
      "stationaryEnergy", // Stationäre Energie
      "transportation",   // Verkehr
      "afolu",            // Landwirtschaft
      "ippu",             // Industrie
      "waste"             // Abfall und Abwasser
      // all other categories in alphabetical order
    ];

    const sortByString = (a: string, b: string) => (a > b ? 1 : -1);

    const sortByCategory = (a: string, b: string) => {
      const basicAIdx = basicMeasureOrder.indexOf(a);
      const basicBIdx = basicMeasureOrder.indexOf(b);

      // Case 1: none of them are in the predefined measure order
      if (basicAIdx < 0 && basicBIdx < 0) {
        return sortByString(a, b);
      }

      // Case 2: both of them are in the predefined measure order
      if (basicAIdx >= 0 && basicBIdx >= 0) {
        return basicAIdx > basicBIdx ? 1 : -1;
      }

      // Case 3: only a is in the predefined measure order
      if (basicAIdx > 0) {
        return -1;
      }

      // Case 4: only b is in the predefined measure order
      return 1;
    };

    return Object.values(sectorGroups)
      .sort((a, b) => sortByCategory(a.sector, b.sector))
      .map((measuresSector) => ({
        ...measuresSector,
        items: measuresSector.items.sort((a, b) => sortByString(a.title, b.title))
      }));
  }
}
