import { AccountingMethod, EnergyTypes } from "../configs/accounting-method";
import { environment } from "../../environments/environment";
import { RegionType } from "../services/util.service";
import { EnergyType } from "./charts.models";

export class Co2SectorsConfigModel {
  private smallChartConfig = [
    {
      type: "sunburst",
      data: undefined,
      allowDrillToNode: false,
      cursor: "pointer",
      dataLabels: {
        enabled: false
      },
      levels: [
        {
          level: 1,
          levelIsConstant: true,
          color: "transparent",
          levelSize: {
            unit: "percentage",
            value: 70
          }
        },
        {
          level: 2,
          colorByPoint: true,
          levelIsConstant: false,
          levelSize: {
            unit: "percentage",
            value: 30
          }
        }
      ]
    }
  ];

  private largeChartConfig = [
    {
      type: "sunburst",
      data: undefined,
      allowDrillToNode: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        rotation: 0,
        formatter: function () {
          return this.point.id === "0.0" ? "" : this.point.id === this.series.rootNode ? this.key : "";
        }
      },
      levels: [
        {
          level: 1,
          levelIsConstant: false,
          color: "transparent"
        },
        {
          level: 2,
          colorByPoint: true
        },
        {
          level: 3,
          colorByPoint: true
        },
        {
          level: 4,
          colorByPoint: true
        },
        {
          level: 5,
          colorByPoint: true
        },
        {
          level: 6,
          colorByPoint: true
        }
      ]
    }
  ];

  public getChartConfig(type: "small" | "large"): [] {
    return this[`${type}ChartConfig`];
  }
}

export interface ISectorConfig {
  type: EnergyTypes;
  level: number;
  color: string;
  methods: Array<AccountingMethod>;
  nh3: boolean;
  getEndpointRoute: (
    regionId: string,
    level: number,
    regionType?: RegionType,
    energyType?: string,
    weatherCorrection?: boolean,
    localMix?: boolean
  ) => string;
  service: any;
  weatherCorrection: boolean;
  energyType?: EnergyType;
}

export const sectorConfig: { sectors: Array<ISectorConfig> } = {
  sectors: [
    {
      type: EnergyTypes.STATIONARY_ENERGY,
      level: 3,
      color: "#5d2f58",
      methods: [
        AccountingMethod.GPC,
        AccountingMethod.BISKO,
        AccountingMethod.BICO2SH,
        AccountingMethod.STET,
        AccountingMethod.BICO2BW
      ],
      nh3: false,
      getEndpointRoute: (
        regionId: string,
        level: number,
        regionType: RegionType,
        energyType: EnergyType,
        weatherCorrection: boolean,
        localMix: boolean
      ) => {
        weatherCorrection = weatherCorrection ? true : false;
        return `/emissions/co2/stationary-energy?regionId=${regionId}&regionType=${regionType}&depth=${level}&energyType=${energyType}&weatherCorrection=${weatherCorrection}&localMix=${
          localMix ?? false
        }`;
      },
      service: () => environment.regionPropertyApi,
      weatherCorrection: true,
      energyType: EnergyType.FINAL
    },
    {
      type: EnergyTypes.TRANSPORTATION,
      level: 4,
      color: "#008797",
      methods: [
        AccountingMethod.GPC,
        AccountingMethod.BISKO,
        AccountingMethod.BICO2SH,
        AccountingMethod.STET,
        AccountingMethod.BICO2BW
      ],
      nh3: false,
      getEndpointRoute: (
        regionId: string,
        level: number,
        regionType: RegionType,
        energyType: EnergyType,
        weatherCorrection: boolean
      ) => {
        weatherCorrection = weatherCorrection ? true : false;
        return `/emissions/co2/transportation?regionId=${regionId}&regionType=${regionType}&depth=${level}&energyType=${energyType}&weatherCorrection=${weatherCorrection}`;
      },
      service: () => environment.regionPropertyApi,
      weatherCorrection: true,
      energyType: EnergyType.FINAL
    },
    {
      type: EnergyTypes.AFOLU,
      level: 3,
      color: "#8AAE1B",
      methods: [AccountingMethod.GPC, AccountingMethod.BICO2SH],
      nh3: true,
      getEndpointRoute: (
        regionId: string,
        level: number,
        regionType,
        energyType: EnergyType,
        weatherCorrection: boolean
      ) => {
        weatherCorrection = weatherCorrection ? true : false;
        return `/emissions/co2/afolu?regionId=${regionId}&regionType=${regionType}&depth=${level}&weatherCorrection=${weatherCorrection}`;
      },
      service: () => environment.regionPropertyApi,
      weatherCorrection: false
    },
    {
      type: EnergyTypes.IPPU,
      level: 2,
      color: "#4a4a4a",
      methods: [AccountingMethod.GPC],
      nh3: false,
      getEndpointRoute: (
        regionId: string,
        level: number,
        regionType,
        energyType: EnergyType,
        weatherCorrection: boolean
      ) => {
        weatherCorrection = weatherCorrection ? true : false;
        return `/emissions/co2/ippu?regionId=${regionId}&regionType=${regionType}&depth=${level}&weatherCorrection=${weatherCorrection}`;
      },
      service: () => environment.regionPropertyApi,
      weatherCorrection: true
    },
    {
      type: EnergyTypes.WASTE,
      level: 4,
      color: "#ED7D31",
      methods: [AccountingMethod.GPC],
      nh3: false,
      getEndpointRoute: (
        regionId: string,
        level: number,
        regionType,
        energyType: EnergyType,
        weatherCorrection: boolean
      ) => {
        weatherCorrection = weatherCorrection ? true : false;
        return `/emissions/co2/waste?regionId=${regionId}&regionType=${regionType}&depth=${level}&weatherCorrection=${weatherCorrection}`;
      },
      service: () => environment.regionPropertyApi,
      weatherCorrection: true
    }
  ]
};
