export enum NetworkPanelAction {
  ADD,
  DELETE,
  UPDATE
}

export enum NetworkPanelGroup {
  UTILITY,
  NETWORK,
  MIX,
  COMPONENT
}

export enum NetworkPanelKey {
  TITLE = "name",
  LOSS = "network_loss",
  TIMEPERIOD = "timeperiod",
  DQI = "mix_id_dqi",
  NOTE = "user_notes",
  SELECTION = "mix_component_id",
  PERCENTAGE = "percent",
  OPERATOR = "utility_company_name",
  CONSNETWORK = "network_consumption",
  VALIDFROM = "valid_from",
  VALIDTO = "valid_to"
}
