import { Injectable } from "@angular/core";
import { environment } from "../../../../../components/src";
import { IApiEndpointService } from "./api-endpoint.interface";

@Injectable({
  providedIn: "root"
})
export class ApiEndpointService implements IApiEndpointService {
  assetUrl(url: string): string {
    const publicPath = environment.baseUrl;
    const publicPathSuffix = publicPath.endsWith("/") ? "" : "/";
    const urlPrefix = url.startsWith("/") ? "" : "/";

    return `${publicPath}${publicPathSuffix}assets${urlPrefix}${url}`;
  }
  getSimulationPropertyApi(): string {
    if (!environment.simulation) {
      throw Error("simulation calculation url missing");
    }
    return environment.simulation;
  }
  getRegionPropertyApi(): string {
    return environment.regionPropertyApi;
  }

  getConfigApi(): string {
    return environment.configServiceApi;
  }
}
