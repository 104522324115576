/* tslint:disable:member-access */

import { Action } from "@ngrx/store";

export enum GlossarActionType {
  GLOSSAR_TAB_CHANGE = "[GLOSSAR] GLOSSAR_TAB_CHANGE"
}

export class GlossarTabChange implements Action {
  type = GlossarActionType.GLOSSAR_TAB_CHANGE;
  constructor(public tabName: string) {}
}

export type GlossarActions = GlossarTabChange;
