import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ISectorConfig } from "../../models/co2-sectors-config.model";
import { ICo2EmissionSectorResult } from "../../modules/co2-cockpit/charts/greenhouse-gases/sectors/chart-co2-sectors.service";
import { AccountingMethodService } from "../accounting-method.service";
import { IRegionIdentifier } from "../util.service";
import { MixType } from "../../configs/accounting-method";

@Injectable({
  providedIn: "root"
})
export class RegionPropertyEmissionService {
  constructor(private http: HttpClient, private accountingService: AccountingMethodService) {}

  public getCo2EmissionSectorData(
    { regionId, regionType }: IRegionIdentifier,
    sectors: Array<ISectorConfig>,
    year?: number
  ): Observable<Array<ICo2EmissionSectorResult>> {
    const requests: Array<Observable<ICo2EmissionSectorResult>> = [];

    const weatherCorrection = this.accountingService.weatherCorrection$.getValue();
    const useUserFactors = this.accountingService.useUserFactors$.getValue();
    const localMix = this.accountingService.selectedMixType$.getValue() === MixType.LOCAL;

    for (const sector of sectors) {
      let params = new HttpParams();

      if (year) {
        params = params.set("year", `${year}`);
      }
      if (useUserFactors) {
        params = params.set("useUserFactors", "true");
      }

      const url = sector
        .service()
        .concat(
          sector.getEndpointRoute(
            regionId,
            sector.level,
            regionType,
            sector.energyType,
            weatherCorrection,
            localMix
          )
        );
      requests.push(
        this.http
          .get<any>(url, { params })
          .pipe(
            map(({ result }: any) => ({ sector, result } as ICo2EmissionSectorResult)),
            catchError(({ status }) => (status === 404 ? of({ sector, result: [] }) : EMPTY))
          )
      );
    }
    return forkJoin(requests);
  }
}
