import { ActionReducerMap } from "@ngrx/store/src/models";
import { reducer } from "./reset-reducer";

export const resetReducers: ActionReducerMap<any> = {
  reset: reducer
};

// tslint:disable-next-line:no-empty-interface
export interface IResetState {
  reset: boolean;
}
