import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IRegionIdentifier } from "../../../common/model/region-identifier";
import { API_ENDPOINT_SERVICE_TOKEN, IApiEndpointService } from "../../../common/utils/api-endpoint.interface";
import { RegionProperties } from "../model/population/region-properties";
import { RegionPropertiesDto } from "./dto/region-properties-dto.interface";

export type BuildingsCount = number;
@Injectable({
  providedIn: "root"
})
export class RegionPropertiesDataService {
  constructor(
    private http: HttpClient,
    @Inject(API_ENDPOINT_SERVICE_TOKEN) private apiEndpointService: IApiEndpointService
  ) {}

  public getRegionProperties(region: IRegionIdentifier, year: number): Observable<RegionProperties | null> {
    const params = new HttpParams({
      fromObject: {
        regionId: region.regionId,
        regionType: region.regionType,
        year: String(year)
      }
    });

    return this.http
      .get<any>(`${this.apiEndpointService.getRegionPropertyApi()}/kpis`, { params })
      .pipe(
        map(
          (region: RegionPropertiesDto) =>
            new RegionProperties(region.area_m2, region.level, region.name, region.population)
        ),
        catchError(() => of(null))
      );
  }

  public getRegionBuildingsCount(region: IRegionIdentifier, year: number): Observable<BuildingsCount | null> {
    const params = new HttpParams({
      fromObject: {
        regionId: region.regionId,
        regionType: region.regionType,
        year: String(year)
      }
    });
    return this.http
      .get<{ countBuildings: BuildingsCount }>(
        `${this.apiEndpointService.getRegionPropertyApi()}/kpis/buildings-count`,
        { params }
      )
      .pipe(
        map(({ countBuildings }) => {
          return countBuildings;
        }),
        catchError(() => of(null))
      );
  }
}
