import {
  GetChartCo2EmissionSectorsSuccess,
  GetChartCo2EmissionSectorsKpiSuccess,
  ChartCo2EmissionSectorsActionType,
  ChartCo2EmissionSectorsError
} from "./emission-sectors-actions";
/* tslint:disable:member-ordering */
/* tslint:disable:member-access */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "../../../../services/data.service";

import { Actions, Effect, ofType } from "@ngrx/effects";
import { switchMap, map, catchError } from "rxjs/operators";
import { ErrorAction } from "../../../common";
import { environment } from "../../../../../environments/environment";
import { UtilService } from "../../../../services/util.service";
import { Observable, forkJoin } from "rxjs";

@Injectable()
export class ChartCo2EmissionSectorsEffects {
  /*    @Effect() GetChartCo2EmissionSectors$ = this.actions$.pipe(
           ofType(ChartCo2EmissionSectorsActionType.GET_DATA),
           switchMap((action: any) => {
               const suffix = (action.payload.accountingMethod) ? "/" + action.payload.accountingMethod : "";
               return this.dataService.sendWithGetParameters(environment.co2DummyDataService + "/emissions/sector/chart", action.payload, ["ags", "year"], suffix).pipe(
                   map(data => {
                       ///////////////////////////////////////////////////////////////////////
                       // HACK: USE-DQI-MOCKDATA.
                       //
                       // This is a workaround to have dqi data from exactly three cities (Gettdorf, Lübeck, Breklum),
                       // because "it has to be done in half a day, because of BISKO current certification process with IFEU" (SPH).
                       //
                       // Mock data is taken from Q:\Kundenprojekte\greenited\Klima_Navi_HAW\TP Daten\07 Smart Load Data\DGI_für_BISKO-Diagramme_Breklum_Gettorf_Lübeck.xlsx
                       // and stored as JSON in mockdata file
                       ///////////////////////////////////////////////////////////////////////

                       let dataPerYear: any;

                       // This is a workaround inside the workaround, because dqiMockdata[String(action.payload.ags)] did not work, also String(action.payload.ags) === "1054020" (in case of Breklum and so on)
                       // switch (action.payload.ags) {
                       //     case 1054020:
                       //         dataPerYear = dqiMockdata["1054020"];
                       //         break;
                       //     case 1058058:
                       //         dataPerYear = dqiMockdata["1058058"];
                       //         break;
                       //     case 1003000:
                       //         dataPerYear = dqiMockdata["1003000"];
                       //         break;
                       //     case 1061113:
                       //         dataPerYear = dqiMockdata["1061113"];
                       //         break;
                       //     default:
                       //         break;
                       // }
                       dataPerYear = dqiMockdata.default[action.payload.ags];

                       if (dataPerYear && dataPerYear[action.payload.year] && dataPerYear[action.payload.year]["greenhouse-gases-sectors"]) {
                           data["dqi"] = dataPerYear[action.payload.year]["greenhouse-gases-sectors"];
                       } else {
                           data["dqi"] = 0;
                       }

                       return new GetChartCo2EmissionSectorsSuccess(data);
                   }),
                   catchError((error) => ErrorAction(action, error, Error))
               );
           }
           )
       ); */

  /**
   * action.payload.sectors
   * action.payload.weatherCorrection
   */
  @Effect() GetChartCo2EmissionSectors$ = this.actions$.pipe(
    ofType(ChartCo2EmissionSectorsActionType.GET_DATA),
    switchMap((action: any) => {
      const observers: Observable<any>[] = [];
      const queries = `weatherCorrection=${action.payload.weatherCorrection.toString()}&year=${this.utilService.getSelectedYear()}`;
      for (const sector of action.payload.sectors) {
        const url = `${
          environment.emission.calculationApi
        }/regions/${this.utilService.getRegionId()}/${sector}?${queries}`;
        observers.push(
          this.http.get(url).pipe(
            map((response: any) => {
              return {
                sector: sector,
                result: response.result
              };
            })
          )
        );
      }
      return forkJoin(observers).pipe(map((result: any[]) => new GetChartCo2EmissionSectorsSuccess(result)));
    })
  );

  @Effect() GetChartCo2EmissionSectorsKpi$ = this.actions$.pipe(
    ofType(ChartCo2EmissionSectorsActionType.GET_KPI),
    switchMap((action: any) =>
      this.dataService
        .sendWithGetParameters(environment.co2DummyDataService + "/emissions/sector/kpi", action.payload, [
          "ags",
          "year"
        ])
        .pipe(
          map((data) => new GetChartCo2EmissionSectorsKpiSuccess(data)),
          catchError((error) => ErrorAction(action, error, ChartCo2EmissionSectorsError))
        )
    )
  );

  constructor(
    private dataService: DataService,
    private actions$: Actions,
    private utilService: UtilService,
    private http: HttpClient
  ) {}
}
