import { ITrendModel } from "./holts-linear-trend";
import { YearlyValue } from "./time-series";

export class LinearTrend implements ITrendModel {
  private readonly slope: number;
  private readonly intercept: number;
  constructor(private readonly measuredData: Array<YearlyValue>) {
    this.slope = this.getSlope();
    this.intercept = this.getIntercept();
  }
  getForecast(maxYear: number): YearlyValue[] {
    const forecast = [];
    for (let year = Math.max(...this.measuredData.map((entry) => entry.year)); year <= maxYear; year++) {
      forecast.push({ year: year, value: this.intercept + this.slope * year });
    }
    return forecast;
  }

  private sum(values: Array<number>): number {
    return values.reduce((a, b) => a + b, 0);
  }

  public getSlope() {
    return this.getCovariance() / this.getVarianceX();
  }

  public getIntercept() {
    const meanX = this.meanX();
    const meanY = this.meanY();
    return meanY - this.getSlope() * meanX;
  }

  private getVarianceX(): number {
    const xValues = this.getXValues();
    const meanX = this.meanX();
    return this.sum(xValues.map((x) => (x - meanX) * (x - meanX)));
  }

  private getCovariance(): number {
    const xValues = this.getXValues();
    const yValues = this.getYValues();
    if (xValues.length !== yValues.length) {
      throw Error("xValues and yValues do not have the same length.");
    }
    const meanX = this.meanX();
    const meanY = this.meanY();
    return this.sum(xValues.map((x, index) => (x - meanX) * (yValues[index] - meanY)));
  }

  private meanX(): number {
    const values = this.getXValues();
    return this.sum(values) / values.length;
  }

  private meanY(): number {
    const values = this.getYValues();
    return this.sum(values) / values.length;
  }

  private getXValues(): Array<number> {
    return this.measuredData.map((point) => point.year);
  }

  private getYValues(): Array<number> {
    return this.measuredData.map((point) => point.value);
  }
}
