import { Action } from "@ngrx/store";
import { IGetAccountingMethodsConfigState } from "./get-accounting-methods-config-reducer";

export enum GetAccountingMethodsConfigActionType {
  GET_ACCOUNTING_METHODS_CONFIG = "[config] GET_ACCOUNTING_METHODS_CONFIG",
  GET_ACCOUNTING_METHODS_CONFIG_SUCCESS = "[config] GET_ACCOUNTING_METHODS_CONFIG_SUCCESS",
  GET_ACCOUNTING_METHODS_CONFIG_ERROR = "[config] GET_ACCOUNTING_METHODS_CONFIG_ERROR"
}

export class GetAccountingMethodsConfig implements Action {
  public readonly type: GetAccountingMethodsConfigActionType =
    GetAccountingMethodsConfigActionType.GET_ACCOUNTING_METHODS_CONFIG;

  constructor(public regionId: string) {}
}

export class GetAccountingMethodsConfigSuccess implements Action {
  public readonly type: GetAccountingMethodsConfigActionType =
    GetAccountingMethodsConfigActionType.GET_ACCOUNTING_METHODS_CONFIG_SUCCESS;

  constructor(public payload: IGetAccountingMethodsConfigState) {
    payload.model = payload["result"];
  }
}

export class GetAccountingMethodsConfigError implements Action {
  public readonly type: GetAccountingMethodsConfigActionType =
    GetAccountingMethodsConfigActionType.GET_ACCOUNTING_METHODS_CONFIG_ERROR;

  constructor(public error: string) {}
}

export type GetAccountingMethodsConfigAction =
  | GetAccountingMethodsConfig
  | GetAccountingMethodsConfigSuccess
  | GetAccountingMethodsConfigError;
