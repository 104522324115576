import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PanelInfoComponent } from "./info/info.component";
import { InfoBuergerportalComponent } from "./info/info-buergerportal/info-buergerportal.component";
import { InfoBuergerportalGrantsComponent } from "./info/info-buergerportal/info-buergerportal-grants/info-buergerportal-grants.component";
import { InfoSectorsComponent } from "./info/info-sectors/info-sectors.component";
import { DataFoundationComponent } from "./info/info-sectors/data-foundation/data-foundation.component";
import { InfoClimateGoalsComponent } from "./info/info-sectors/info-climate-goals/info-climate-goals.component";
import { InfoDatasourceComponent } from "./info/info-sectors/info-datasource/info-datasource.component";
import { InfoStandardsComponent } from "./info/info-sectors/info-standards/info-standards.component";
import { TranslateModule } from "@ngx-translate/core";
import { EneTabsModule } from "@energy-city/ui/tabs";
import { PipesModule } from "../../core/pipe/pipes.module";
import { EneModalModule } from "@energy-city/ui/modal";
import { AppDqiModule } from "../dqi/dqi.module";
import { NewsComponent } from "./info/news/news.component";
import { NgxTwitterTimelineModule } from "ngx-twitter-timeline";
import { EneKpisV2Module } from "@energy-city/ui/kpis-v2";
import { EneIconModule } from "@energy-city/ui/icon";

@NgModule({
  declarations: [
    PanelInfoComponent,
    InfoBuergerportalComponent,
    InfoBuergerportalGrantsComponent,
    InfoSectorsComponent,
    DataFoundationComponent,
    InfoClimateGoalsComponent,
    InfoDatasourceComponent,
    InfoStandardsComponent,
    NewsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    EneTabsModule,
    PipesModule,
    EneModalModule,
    AppDqiModule,
    NgxTwitterTimelineModule,
    EneKpisV2Module,
    EneIconModule
  ],
  exports: [PanelInfoComponent],
  entryComponents: [InfoSectorsComponent, InfoBuergerportalComponent, InfoBuergerportalGrantsComponent, NewsComponent]
})
export class InfoPanelModule {}
