import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import "@enersis/gp-components/gp-map2-control";
import "@enersis/gp-components/gp-map2-viewer";
import "@enersis/gp-components/gp-map2-legend2";
import "@enersis/gp-components/gp-map2-layer-control";
import "@enersis/gp-components/gp-attribution-button";
import "@enersis/gp-components/gp-button";
import { ConfigModule } from "../common";

import { API_ENDPOINT_SERVICE_TOKEN } from "../common/utils/api-endpoint.interface";
import { ApiEndpointService } from "../common/utils/api-endpoint.service";
import { MapComponent } from "./components/map/map.component";
import { LayerPanelComponent } from "./components/layer-panel/layer-panel.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [MapComponent, LayerPanelComponent],
  providers: [{ provide: API_ENDPOINT_SERVICE_TOKEN, useClass: ApiEndpointService }],
  imports: [CommonModule, ConfigModule, TranslateModule],
  exports: [MapComponent, LayerPanelComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MapModule {}
