import { GasCockpitKpiActionsUnion, GasCockpitKpiActionTypes } from "./kpi-actions";
import { State } from "./index";

export const initialState: any = {
  attributes: []
};

export function reducer(state: State = initialState, action: GasCockpitKpiActionsUnion): any {
  switch (action.type) {
    case GasCockpitKpiActionTypes.GET_GAS_KPI: {
      return {
        loading: true
      };
    }
    case GasCockpitKpiActionTypes.GET_GAS_KPI_SUCCESS: {
      return {
        loading: false,
        ...state,
        ...action.data
      };
    }
    case GasCockpitKpiActionTypes.GET_GAS_KPI_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    default: {
      return state;
    }
  }
}
