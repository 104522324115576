import { Component, ChangeDetectionStrategy, Inject, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { DYNAMIC_HEADER_DATA, Header, IHeaderConfigs } from "../../models";
import { UtilService } from "../../../../services/util.service";
import { AuthenticationService } from "../../../../modules/authentication/services/exported/authentication.service";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EneModalService } from "@energy-city/ui/modal";

@Component({
  selector: "app-co2balance-header",
  templateUrl: "./co2balance-header.component.html",
  styleUrls: ["./co2balance-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Co2BalanceHeaderComponent extends Header implements OnDestroy {
  public title = "co2balance";
  public hasCoatOfArms = false;
  public staticDashboardImage?: string = null;

  private coaLoadError$: Subject<string> = new Subject();
  private destroy$: Subject<void> = new Subject();

  constructor(
    @Inject(DYNAMIC_HEADER_DATA) private headerData: IHeaderConfigs,
    private utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private modal: EneModalService,
    private authService: AuthenticationService
  ) {
    super(headerData);
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  protected config(): void {
    this.utilService
      .getCoatOfArmsUrl(this.coaLoadError$)
      .pipe(takeUntil(this.destroy$))
      .subscribe((url: string) => {
        this.coatOfArms = url;
        this.hasCoatOfArms = true;

        this.staticDashboardImage = this.authService.tokenParsed.static_dashboard || null;

        this.cdr.detectChanges();
      });
  }

  public openStaticDashboard(): void {
    if (this.staticDashboardImage) {
      this.modal.open("static-dashboard");
    }
  }

  public onCoaLoadError(e: ErrorEvent) {
    this.hasCoatOfArms = false;
    const imgTag = e.target as HTMLImageElement;
    this.coaLoadError$.next(imgTag.src);
  }
}
