import { Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CO2SavingType, ImplementationStatusType } from "../../../../constants/measures";
import { EneModalService } from "@energy-city/ui/modal";
import { MatStepper } from "@angular/material/stepper";
import { IApplicationState, getEditingMeasure, SetEditingMeasure } from "@energy-city/components";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
  /* tslint:disable:component-selector */
  selector: "measure-editor",
  templateUrl: "./measure-editor.component.html",
  styleUrls: ["./measure-editor.component.scss"]
})
export class MeasureEditorComponent implements OnInit, OnDestroy {
  @ViewChild("stepper", { static: true }) public stepper: MatStepper;
  @Output() public onSave: EventEmitter<any> = new EventEmitter();
  public readonly IMPLEMENTATION_PERIOD_START = 2000;
  public readonly IMPLEMENTATION_PERIOD_END = 2050;
  public model: any;
  public sliderConfig = {
    pips: {
      mode: "count",
      values: 25,
      density: 100
    }
  };
  public co2SavingType = CO2SavingType;
  public implementationStatusType = ImplementationStatusType;
  public generalFormGroup: FormGroup;
  public co2SavingFormGroup: FormGroup;
  public implementationStatusFormGroup: FormGroup;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private _formBuilder: FormBuilder,
    private eneModalService: EneModalService,
    private store: Store<IApplicationState>,
    private translate: TranslateService
  ) {}

  public ngOnInit() {
    this.generalFormGroup = this._formBuilder.group({
      title: ["", Validators.required],
      description: ["", Validators.required]
    });
    this.co2SavingFormGroup = this._formBuilder.group({
      sector: ["" + this.co2SavingType.SECTOR, Validators.required],
      co2Saving: ["", Validators.required]
    });
    this.implementationStatusFormGroup = this._formBuilder.group({
      status: [this.implementationStatusType.STEP_0, Validators.required]
    });

    this.store
      .select(getEditingMeasure)
      .pipe(takeUntil(this.destroy$))
      .subscribe((measure) => {
        if (measure) {
          this.model = measure;
          this.fillForms();
        } else {
          this.resetToDefault();
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public onChangeRangeSlider(event) {
    this.model.timerange[0] = event[0];
    this.model.timerange[1] = event[1];
  }

  public save() {
    const data = {
      // keep translation key if possible
      title: this.getFormValueToSave(this.model.title, "title"),
      description: this.getFormValueToSave(this.model.description, "description"),
      sector: this.co2SavingFormGroup.get("sector").value,
      co2Saving: parseFloat(this.co2SavingFormGroup.get("co2Saving").value),
      timerange: this.model.timerange,
      status: parseInt(this.implementationStatusFormGroup.get("status").value, 10)
    };
    this.onSave.emit(data);
  }

  public isRadioButtonDisabled(): boolean {
    return this.model.default ? true : null;
  }

  private getFormValueToSave(original: string, formKey: string): string {
    const potentialTranslationKey = original ? original : "inexistentTranslationKey";
    const wasTranslationKey = this.translate.instant(potentialTranslationKey) != original;
    const isUnchanged = this.translate.instant(potentialTranslationKey) == this.generalFormGroup.get(formKey).value;
    if (wasTranslationKey && isUnchanged) {
      return original;
    } else {
      return this.generalFormGroup.get(formKey).value;
    }
  }

  private fillForms() {
    this.generalFormGroup.patchValue({
      title: this.model.title ? this.translate.instant(this.model.title) : "",
      description: this.model.description ? this.translate.instant(this.model.description) : ""
    });
    this.co2SavingFormGroup.patchValue({
      sector: this.model.sector,
      co2Saving: this.model.co2Saving
    });
    this.implementationStatusFormGroup.patchValue({
      status: `${this.model.status}`
    });
  }

  private cleanModel() {
    this.model = {
      title: "",
      description: "",
      sector: "",
      co2Saving: null,
      timerange: [this.IMPLEMENTATION_PERIOD_START, this.IMPLEMENTATION_PERIOD_END],
      status: this.implementationStatusType.STEP_0
    };
  }

  public resetToDefault() {
    this.cleanModel();
    this.fillForms();
  }

  public close() {
    this.stepper.reset();
    this.cleanModel();
    this.store.dispatch(new SetEditingMeasure(null));
    this.eneModalService.close("measure_editor");
  }
}
