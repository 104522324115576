import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UtilService, environment } from "@energy-city/components";
import { Subject } from "rxjs";
import { EneSnackbarService } from "@energy-city/ui/snackbar";

@Injectable()
export class CommentService {
  public comment$: Subject<Comment> = new Subject();
  constructor(private utilService: UtilService, private snackbar: EneSnackbarService, private http: HttpClient) {
    this.utilService.regionId$.subscribe((regionId) => {
      this.getMessage();
    });
  }

  public send(message) {
    return this.http
      .put(`${environment.configState}/regions/${this.utilService.getRegionId()}/data-input/comments`, {
        userNotes: message
      })
      .subscribe(
        (result) => {
          this.snackbar.success("Kommentar gespeichert");
          this.getMessage();
        },
        () => {
          this.snackbar.error("Es ist ein Fehler aufgetreten");
        }
      );
  }

  private getMessage() {
    return this.http
      .get<{
        result: Comment;
      }>(`${environment.configState}/regions/${this.utilService.getRegionId()}/data-input/comments`)
      .subscribe(({ result }) => {
        this.comment$.next(result);
      });
  }
}

export interface Comment {
  lastChange: number;
  givenName: string;
  familyName: string;
  userNotes: string;
}
