import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  QueryList,
  ViewChildren,
  HostBinding,
  OnChanges,
  SimpleChanges
} from "@angular/core";

import { DqiComponent } from "../dqi/dqi/dqi.component";
import { EneSlideToggleComponent } from "@energy-city/ui/forms";

export interface ITableInput {
  label: string;
  value: string | number;
  type: "number" | "string" | "select" | "boolean";
  options?: [
    {
      value: number;
      label: string | number;
    }
  ];
  dqi?: number;
  data_precision?: number;
  key: string;
  category: string;
}
export interface ITableOutput {
  label: string;
  key: string;
  category: string;
  value?: string;
  dqi?: number;
}

interface IPreviousActions {
  change: any;
  category: any;
  key: any;
  label: any;
  target: any;
  oldValue: any;
  newValue: any;
}

@Component({
  selector: "app-dqi-table",
  templateUrl: "./dqi-table.component.html",
  styleUrls: ["./dqi-table.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DqiTableComponent implements OnChanges {
  @HostBinding("class.app-dqi-table") private hostClass: boolean = true;
  @Input() @HostBinding("class.disabled") public disabled: boolean = false;
  @Input() public data: Array<ITableInput>;
  @Output() public change: EventEmitter<ITableOutput> = new EventEmitter();
  @Output() public onDisabledClick: EventEmitter<boolean> = new EventEmitter();
  @Output() public canUndo: boolean = false;

  @ViewChildren(DqiComponent) private dqiComponents: QueryList<DqiComponent>;
  @ViewChildren(EneSlideToggleComponent) private slideToggles: QueryList<EneSlideToggleComponent>;
  private output: ITableOutput;
  private previousActions: Array<IPreviousActions> = [];

  private firstItem: boolean = true;
  constructor(private elementRef: ElementRef) {
    this.resetOutput();
  }

  public ngOnChanges(changes: SimpleChanges) {
    // data is changed -> probably new building selected just reset all undo values
    if (changes.data !== undefined) {
      this.canUndo = false;
      this.previousActions = [];
    }
  }

  public undo() {
    if (this.canUndo === false) {
      return;
    } else {
      try {
        this.resetOutput();
        const lastAction = this.getLastAction();
        const dataItem = this.data.find((e: any) => e.category === lastAction.category && e.key === lastAction.key);

        if (lastAction.change === "value") {
          this.output.value = lastAction.oldValue;
          lastAction.target.value = lastAction.oldValue;
          dataItem.value = lastAction.oldValue;
        } else if (lastAction.change === "select") {
          this.output.value = lastAction.oldValue;
          lastAction.target.target.value = lastAction.oldValue;
          dataItem.value = lastAction.oldValue;
        } else if (lastAction.change === "dqi") {
          this.output.dqi = lastAction.oldValue;
          const dqiComponent = this.dqiComponents.find(
            (e) => e.item.category === lastAction.category && e.item.key === lastAction.key
          );
          dqiComponent.changeValue(lastAction.oldValue);
          dataItem.dqi = lastAction.oldValue;
        } else if (lastAction.change === "boolean") {
          this.output.value = lastAction.oldValue;
          const slideToggle = this.slideToggles.find(
            (e) => e.item.category === lastAction.category && e.item.key === lastAction.key
          );
          slideToggle.changeValue(lastAction.oldValue);
          dataItem.value = lastAction.oldValue;
        }

        this.emitChange({
          label: lastAction.label,
          category: lastAction.category,
          key: lastAction.key
        });
        this.previousActions.pop();
        if (this.previousActions.length === 0) {
          this.canUndo = false;
        }
      } catch (error) {
        throw error;
      }
    }
  }

  public onChangeValue(item: any, target: any) {
    this.resetOutput();
    if (item.type === "select") {
      this.output.value = target.target.value;
      this.setLastAction("select", item, target.target.value, target);
    } else if (item.type === "boolean") {
      this.output.value = target;
      this.setLastAction("boolean", item, target);
    } else {
      this.output.value = target.value;
      this.setLastAction("value", item, target.value, target);
    }
    this.canUndo = true;
    this.emitChange(item);
  }

  public onChangeDqi(item: any, value: any) {
    this.setLastAction("dqi", item, value);
    this.resetOutput();
    this.output.dqi = value;
    this.canUndo = true;
    this.emitChange(item);
  }

  /**
   * checks if it is disabled while user clicked on an input.
   * @param item clicked item
   */
  public onClick() {
    if (this.disabled) {
      this.onDisabledClick.emit(true);
    }
  }

  private emitChange(item: any) {
    this.output.label = item.label;
    this.output.category = item.category;
    this.output.key = item.key;
    this.change.emit(this.output);
  }

  private resetOutput() {
    this.output = {
      label: "",
      category: "",
      key: ""
    };
  }

  private setLastAction(change: string, item: any, value: number, target?: any) {
    const localItem = Object.assign({}, item);
    const dataItem = this.data.find((e: any) => e.category === item.category && e.key === item.key);
    const newAction: any = {};
    newAction.change = change;
    newAction.category = localItem.category;
    newAction.key = localItem.key;
    newAction.label = localItem.label;
    newAction.target = target;
    if (change === "select") {
      newAction.oldValue = String(dataItem.value);
      newAction.newValue = String(value);
      dataItem.value = newAction.newValue;
    } else if (change === "boolean") {
      newAction.oldValue = (<any>dataItem).value;
      newAction.newValue = value;
      (<any>dataItem).value = newAction.newValue;
    } else {
      newAction.oldValue = (<any>dataItem)[change];
      newAction.newValue = value;
      (<any>dataItem)[change] = newAction.newValue;
    }
    this.previousActions.push(newAction);
  }

  private getLastAction() {
    return this.previousActions[this.previousActions.length - 1];
  }
}
