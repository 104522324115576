import { Co2SavingAction, Co2SavingActionType } from "./co2-saving-actions";
import { Co2Saving, GasCo2Saving } from "../common";
import { ILoadingBehavior } from "../../../../common";

export const reducer = (state: ICo2SavingState = {}, action: Co2SavingAction) => {
  switch (action.type) {
    case Co2SavingActionType.GET_CO2_SAVING_DATA:
      return {
        loading: true,
        regionId: action.regionId,
        scenarioId: action.scenarioId,
        year: action.year
      };
    case Co2SavingActionType.GET_CO2_SAVING_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false
      };
    case Co2SavingActionType.GET_CO2_SAVING_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};

export interface ICo2SavingState extends ILoadingBehavior {
  electricity?: Co2Saving;
  gas?: GasCo2Saving;
  regionId?: string;
  scenarioId?: string;
  year?: number;
}
