import { ActionReducerMap } from "@ngrx/store";
import { reducer } from "./network-reducer";

export interface State {
  supplySegmentLength?: number;
  totalLength?: number;
  error?: string;
  loading?: boolean;
}

export const gasNetworkLengthReducers: ActionReducerMap<any> = {
  gasNetworkLength: reducer
};
