import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { EneChartComponent } from "@energy-city/ui/chart";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { DatabaseChartService } from "./database-chart.service";
import { ChartTypes } from "../../../../models/charts.model";
import { DisplayChartConfig } from "../../../../models/charts.models";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-database-chart",
  templateUrl: "./database-chart.component.html",
  styleUrls: ["./database-chart.component.scss"],
  providers: [DatabaseChartService]
})
export class DatabaseChartComponent implements OnInit, OnDestroy {
  public chartData$: BehaviorSubject<any>;
  public chartConfig$: Observable<any>;
  @ViewChild("chart", { static: true }) public chart: EneChartComponent;
  public renewableActive: boolean = false;
  public consumptionActive: boolean = false;
  public renewableItems;
  public colors;
  public dqi: number;
  private destroy$: Subject<void> = new Subject();
  protected chartType: ChartTypes;
  public displayConfig: DisplayChartConfig = {
    biskoDisabled: true
  };
  constructor(private databaseChartService: DatabaseChartService, private translate: TranslateService) {}

  public ngOnInit(): void {
    this.databaseChartService.chartType = this.chartType;
    this.renewableItems = this.databaseChartService.renewable;
    this.colors = this.databaseChartService.chartTranslateKeys;
    this.databaseChartService.getChartData();
    this.chartData$ = this.databaseChartService.chartData$;
    this.chartConfig$ = this.databaseChartService.chartConfig$;
    this.databaseChartService.dqi$.pipe(takeUntil(this.destroy$)).subscribe((dqi) => {
      this.dqi = dqi;
    });

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.chartData$.next(this.chartData$.value.map(item => ({...item, name: this.translate.instant(item.translationKey)})));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public toggleConsumption() {
    this.consumptionActive = !this.consumptionActive;
    this.chart.chart.get("consumption").update({ visible: this.consumptionActive });
  }

  public toggleRenewable() {
    this.renewableActive = !this.renewableActive;
    this.databaseChartService.renewable.forEach((item) => {
      const renewable = this.chart.chart.get(item);

      if (renewable) {
        renewable.update(
            this.renewableActive ? { color: `url(#pattern-${item})` } : { color: this.colors[item].color, opacity: 1 }
          );
      }
    });
  }
}
