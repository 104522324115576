import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { PrintService } from "../../services/print.service";
import { NUMBER_FORMAT } from "../../reports.config";
import { ReportTableColumn } from "../../interfaces/report-table.interface";
import { TranslateService } from "@ngx-translate/core";
import { ANIMAL_HUSBANDRY_COLUMNS, GLOBAL_FACTORS_COLUMNS, LAND_USE_COLUMNS } from "../../../../constants/factor.const";
import { TableCellTypes } from "../../../../core/enum/table-cell-types.enum";
import { EmissionsFactors, FactorEmissionsTables } from "../../../../core/enum/factor.enum";
import { AgricultureFactors, GlobalFactors } from "./factor.interface";
import { FactorTableModel } from "./factor-table.model";
import { AccountingMethodService } from "../../../../services/accounting-method.service";
import { EmissionsFactorsService } from "../../../../services/emissions-factors.service";
import { EnergyTypes } from "../../../../configs/accounting-method";
import { sectorConfig } from "../../../../models/co2-sectors-config.model";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FactorTableRawData } from "../../../../../../../../shared/src/lib/accounting/accounting-report/factor-table/factor-table.interface";

@Component({
  selector: "app-factor-page",
  templateUrl: "./factor.component.html",
  styleUrls: ["./factor.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FactorPageComponent implements OnInit, OnDestroy {
  public numberFormat = NUMBER_FORMAT;
  public locale: string;
  public year: number;
  public useUserFactors: boolean;
  public tableCellTypes = TableCellTypes;
  public energyTypes = EnergyTypes;

  public landUseTable: FactorTableModel;
  public animalHusbandryTable: FactorTableModel;
  public globalFactorsTable: FactorTableModel;
  public waste: FactorTableRawData[];
  public stationaryEnergy: FactorTableRawData[];
  public transportation: FactorTableRawData[];
  private destroy$ = new Subject<void>();

  public landUseTableIndex: number;
  public animalHusbandryTableIndex: number;
  public globalFactorsTableIndex: number;
  public wasteTableIndex: number[];
  public stationaryEnergyTableIndex: number[];
  public transportationTableIndex: number[];

  constructor(
    private printService: PrintService,
    private translate: TranslateService,
    private emissionsFactorsService: EmissionsFactorsService,
    private accountingMethodService: AccountingMethodService
  ) {}

  public ngOnInit(): void {
    this.locale = this.translate.currentLang;
    this.year = this.printService.selectedReportYear;
    this.useUserFactors = this.printService.selectedUseUserFactors;

    combineLatest([
      this.emissionsFactorsService.getEmissionsFactors<Array<FactorTableRawData>>(
        EmissionsFactors.STATIONARY_ENERGY,
        this.useUserFactors,
        this.year
      ),
      this.emissionsFactorsService.getEmissionsFactors<Array<FactorTableRawData>>(
        EmissionsFactors.TRANSPORTATION,
        this.useUserFactors,
        this.year
      ),
      this.emissionsFactorsService.getEmissionsFactors<AgricultureFactors>(
        EmissionsFactors.AGRICULTURE,
        this.useUserFactors,
        this.year
      ),
      this.emissionsFactorsService.getEmissionsFactors<Array<FactorTableRawData>>(
        EmissionsFactors.WASTE,
        this.useUserFactors,
        this.year
      ),
      this.emissionsFactorsService.getEmissionsFactors<GlobalFactors>(EmissionsFactors.GLOBAL, this.useUserFactors)
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([stationaryEnergy, transportation, agriculture, waste, globalFactors]) => {
        this.stationaryEnergy = stationaryEnergy;
        this.transportation = transportation;

        this.landUseTable = new FactorTableModel(
          this.translate,
          FactorEmissionsTables.LAND_USE,
          agriculture,
          LAND_USE_COLUMNS
        );
        this.animalHusbandryTable = new FactorTableModel(
          this.translate,
          FactorEmissionsTables.ANIMAL_HUSBANDRY,
          agriculture,
          ANIMAL_HUSBANDRY_COLUMNS
        );

        this.waste = waste;

        this.globalFactorsTable = new FactorTableModel(
          this.translate,
          FactorEmissionsTables.GLOBAL_FACTORS,
          globalFactors,
          GLOBAL_FACTORS_COLUMNS
        );

        this.updateTableNumbers();
      });

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.locale = this.translate.currentLang;
    });
  }

  private updateTableNumbers(): void {
    let nextTableNr = 6;

    this.stationaryEnergyTableIndex = [];
    if (this.isSectorEnabled(EnergyTypes.STATIONARY_ENERGY)) {
      for (let i = 0; i < this.stationaryEnergy.length; i++) {
        this.stationaryEnergyTableIndex.push(nextTableNr);
        nextTableNr++;
      }
    }

    this.transportationTableIndex = [];
    if (this.isSectorEnabled(EnergyTypes.TRANSPORTATION)) {
      for (let i = 0; i < this.transportation.length; i++) {
        this.transportationTableIndex.push(nextTableNr);
        nextTableNr++;
      }
    }

    if (this.isSectorEnabled(EnergyTypes.AFOLU)) {
      this.landUseTableIndex = nextTableNr;
      nextTableNr++;
      this.animalHusbandryTableIndex = nextTableNr;
      nextTableNr++;
    }

    this.wasteTableIndex = [];
    if (this.isSectorEnabled(EnergyTypes.WASTE)) {
      for (let i = 0; i < this.waste.length; i++) {
        this.wasteTableIndex.push(nextTableNr);
        nextTableNr++;
      }
    }
    this.globalFactorsTableIndex = nextTableNr;
  }

  public isSectorEnabled(energyType: EnergyTypes): boolean {
    const sector = sectorConfig.sectors.find((sector) => sector.type === energyType);
    if (!sector) {
      throw Error(`unsupported sector ${energyType}`);
    }
    return sector.methods.includes(this.accountingMethodService.selectedAccountingMethod$.value);
  }

  public getDisplayedColumns(columns: Array<ReportTableColumn>): Array<string> {
    return columns.map((item) => item.id);
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
