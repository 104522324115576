import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IRegionIdentifier } from "../../../common/model/region-identifier";
import { acceptJsonWithoutCache } from "../../../common/utils/http-headers";
import { AccountingMethod } from "../../accounting-common/model/accounting-methods/accounting-method.enum";
import { ElectricityMix } from "../../accounting-common/model/electricity-mix.enum";
import { Rank } from "../model/kpi/rank";
import { WeatherCorrection } from "../../accounting-common/model/weather-correction.enum";
import { IRankDto } from "./dto/rank-dto.interface";
import { API_ENDPOINT_SERVICE_TOKEN, IApiEndpointService } from "../../../common/utils/api-endpoint.interface";

@Injectable({
  providedIn: "root"
})
export class KpiEmissionRankDataService {
  constructor(
    private http: HttpClient,
    @Inject(API_ENDPOINT_SERVICE_TOKEN) private apiEndpointService: IApiEndpointService
  ) {}

  public getRank(
    region: IRegionIdentifier,
    accountingMethod: AccountingMethod,
    year: number,
    weatherCorrection: WeatherCorrection,
    electricityMix: ElectricityMix
  ): Observable<Rank | null> {
    const params = new HttpParams({
      fromObject: {
        regionId: region.regionId,
        regionType: region.regionType,
        accountingMethod,
        year: String(year),
        weatherCorrection: weatherCorrection === WeatherCorrection.ON ? true : false,
        localMix: electricityMix === ElectricityMix.REGION_SPECIFIC ? true : false
      }
    });
    const url = `${this.apiEndpointService.getRegionPropertyApi()}/kpis/rank`;

    return this.http
      .get<IRankDto>(url, { headers: new HttpHeaders(acceptJsonWithoutCache), params })
      .pipe(
        map(
          (rank: IRankDto): Rank => {
            return new Rank(rank.rank, rank.numberOfRegions);
          }
        ),
        catchError(() => of(null))
      );
  }
}
