import { GpAllRules, IGpAnyLayerInput } from "@enersis/gp-components/gp-map2-viewer";
import { RegionType } from "../../common/model/region-type.enum";
import { IRegionLayerConfig } from "./region-layer-config.interface";

const SELECTED_STYLE: GpAllRules = {
  type: "selected",
  output: "#FFD500"
};

const DEFAULT_STYLE: GpAllRules = {
  type: "default",
  output: "#E1EEDC"
};

export class UnionLayerConfig implements IRegionLayerConfig {
  constructor(readonly level: string, readonly minZoom: number, readonly maxZoom: number) {}

  getLayerId(): string {
    return this.level;
  }

  getRegionType(): RegionType {
    return RegionType.UNION;
  }

  getLayerConfig(): IGpAnyLayerInput {
    return {
      id: this.getLayerId(),
      type: "fill",
      source: `region/${this.level}`,
      label: `REGIONS.LEVELS.${this.level}`,
      minzoom: this.minZoom,
      maxzoom: this.maxZoom,
      selectable: "single",
      activeThematicId: "unionThematic",
      active: true,
      defaultStyle: {
        "fill-opacity": {
          stops: [
            [this.minZoom, 0.5],
            [this.minZoom + 0.5, 0.9],
            [this.maxZoom - 0.5, 0.9],
            [this.maxZoom, 0.5]
          ]
        },
        "fill-color": "#E1EEDC",
        "fill-outline-color": "hsla(0, 0%, 13%, 0.3)",
        "fill-antialias": true
      },
      thematics: [
        {
          id: "unionThematic",
          styleRules: {
            color: [SELECTED_STYLE, DEFAULT_STYLE]
          }
        }
      ]
    };
  }
}
