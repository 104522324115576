/* tslint:disable:member-access */

import { of } from "rxjs";
import { Action } from "@ngrx/store";
import { tap } from "rxjs/operators";

// tslint:disable-next-line:no-shadowed-variable
export const ErrorAction = (action, error, ErrorAction) =>
  of(
    new ErrorAction({
      place: "Error on Effect of type" + action.type,
      status: error.status,
      message: error.message,
      action
    })
  );

export enum CommonActionType {
  FATAL_ERROR = "[Shared] FATAL_ERROR"
}

export class FatalError implements Action {
  type = CommonActionType.FATAL_ERROR;
  constructor(public payload: any) {}
}

export function commonReducer(state = [], action: FatalError) {
  switch (action.type) {
    case CommonActionType.FATAL_ERROR:
      console.error(
        `The application is in an unrecoverable error state. Error details: ${JSON.stringify(action.payload)}`
      );
      return state;
    default:
      return state;
  }
}

export const acceptJsonWithoutCache = {
  Accept: "application/json",
  "Cache-Control": "no-cache",
  Pragma: "no-cache"
};

export interface ILoadingBehavior {
  error?: string;
  loading?: boolean;
  data?: any;
}

export const createErrorMessage = (error): string =>
  error &&
  error.error &&
  error.error.errorMessage &&
  error.error.errorMessage.type &&
  error.error.errorMessage.type.message
    ? `${error.error.errorMessage.type.message} - ${error.message}`
    : error && error.message;

export const hasLoaded = (states: Array<[ILoadingBehavior, string]>): boolean =>
  states && states.filter((state) => state[0].loading != null && state[0].loading === false).length === states.length;

export const hasNoLoadingError = (states: Array<[ILoadingBehavior, string]>): boolean =>
  states &&
  states
    .filter((state) => state[0].error)
    .map((state) => console.error(`${state[1]} has the following unexpected error: ${state[0].error}`)).length === 0;

export const withBasicLoadingErrorHandling = <T, E>(
  states: Array<[ILoadingBehavior, string]>,
  callback: () => T,
  errorCallback?: () => E
): T | E =>
  hasLoaded(states) && (hasNoLoadingError(states) ? callback && callback() : errorCallback && errorCallback());

export const isLoading = (...states: ILoadingBehavior[]) =>
  states && states.findIndex((state) => state && state.loading === true) >= 0;

export const hasLoadedWithError = (...states: ILoadingBehavior[]) =>
  !isLoading(...states) && states.some((state) => state && !!state.error);

export const hasLoadedWithoutError = (...states: ILoadingBehavior[]): boolean =>
  !isLoading(...states) && !hasLoadedWithError(...states);

export const onLoading = (callback: () => void) =>
  tap((...states: ILoadingBehavior[]): void => isLoading(...states) && callback());

export const onLoadingError = (callback: () => void) =>
  tap((...states: ILoadingBehavior[]): void => hasLoadedWithError(...states) && callback());
