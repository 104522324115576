import { map, catchError } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../../modules/authentication/services/exported/authentication.service";

@Component({
  selector: "app-download-report",
  templateUrl: "./download-report.component.html",
  styleUrls: ["./download-report.component.scss"]
})
export class DownloadReportComponent implements OnInit {
  public reporturl: string;
  public reportname: string;

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient
  ) {}

  public ngOnInit() {
    const ags = this.authService.tokenParsed.ags;
    this.reportname = "Ergebnis_Bericht_" + ags + ".pdf";
    this.reporturl = "./../assets/downloads/" + this.reportname;
  }

  public downloadReport() {
    return this.http
      .get(this.reporturl, { responseType: "blob" as "json" })
      .pipe(
        map((res) => ({ filename: this.reportname, data: res })),
        catchError(() => {
          window.alert("Ergebis Bericht konnte nicht erstellt werden");

          return null;
        })
      )
      .subscribe((res: any) => {
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveBlob) {
          const blob = new Blob([res.data], { type: "application/pdf" });

          window.navigator.msSaveBlob(blob, res.filename);
          return;
        }

        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement("a");

        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }
}
