import { Component, ChangeDetectionStrategy } from "@angular/core";
import { MainPolluterChartComponent } from "../main-polluter.component";
import { MAIN_POLLUTER_APIS } from "../../../../../models/charts.models";

@Component({
  selector: "app-co2-emission-main-polluter-chart",
  templateUrl: "../main-polluter.component.html",
  styleUrls: ["../main-polluter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Co2EmissionMainPolluterChartComponent extends MainPolluterChartComponent {
  protected chartApi = MAIN_POLLUTER_APIS.CO2;
  public hidePrimaryEnergy = this.platformEnvFile?.co2balance?.featureFlags?.hidePrimaryEnergy ?? false;
}
