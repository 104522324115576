import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
  AccountingMethodService as LegacyAccountingMethodService,
  MixType as LegacyMixType
} from "@energy-city/components";
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from "rxjs";
import { distinctUntilChanged, filter, map, takeUntil } from "rxjs/operators";
import { ISelectedRegionService, SELECTED_REGION_SERVICE_TOKEN, TimelineService } from "../../../common/state";
import { inputIsNotNullOrUndefined } from "../../../common/utils/util-rxjs";
import { EmissionDataService } from "../../accounting-emission/data/emission-data.service";
import { KpiEmissionRankDataService } from "../../accounting-emission/data/kpi-emission-rank-data.service";
import { RegionPropertiesDataService } from "../data/region-properties-data.service";
import { Accounting } from "../model/accounting";
import { ElectricityMix } from "../model/electricity-mix.enum";
import { WeatherCorrection } from "../model/weather-correction.enum";
import { IActiveAccountingService } from "./active-accounting.interface";

@Injectable({
  providedIn: "root"
})
export class ActiveAccountingService implements OnDestroy, IActiveAccountingService {
  private accounting: BehaviorSubject<Accounting | null> = new BehaviorSubject<Accounting | null>(null);

  private destroy$: Subject<undefined> = new Subject();

  constructor(
    @Inject(SELECTED_REGION_SERVICE_TOKEN) private selectedRegionService: ISelectedRegionService,
    private accountingMethodService: LegacyAccountingMethodService,
    private rankService: KpiEmissionRankDataService,
    private timelineService: TimelineService,
    private emissionDataService: EmissionDataService,
    private regionPropertiesDataService: RegionPropertiesDataService
  ) {
    this.subscribeAccountingUpdates();
  }

  getActiveAccounting(): Observable<Accounting> {
    return this.accounting.asObservable().pipe(filter(inputIsNotNullOrUndefined));
  }

  public ngOnDestroy(): void {
    this.accounting.complete();
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  getActiveAccounting1990(): Observable<Accounting> {
    return this.getActiveAccounting().pipe(
      distinctUntilChanged((prev, curr) => {
        return (
          prev.region === curr.region &&
          prev.accountingMethod === curr.accountingMethod &&
          prev.electricityMix === curr.electricityMix &&
          prev.weatherCorrection === curr.weatherCorrection &&
          prev.useUserFactors === curr.useUserFactors
        );
      }),
      map(
        (accounting) =>
          new Accounting(
            {
              region: accounting.region,
              year: 1990,
              accountingMethod: accounting.accountingMethod,
              electricityMix: accounting.electricityMix,
              weatherCorrection: accounting.weatherCorrection,
              useUserFactors: accounting.useUserFactors,
              timestamp: accounting.timestamp
            },
            this.rankService,
            this.emissionDataService,
            this.regionPropertiesDataService
          )
      )
    );
  }

  private subscribeAccountingUpdates(): Subscription {
    return combineLatest([
      this.selectedRegionService.getSelectedRegionIdentifier(),
      this.getSelectedYear(),
      this.accountingMethodService.currentParameters$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([region, year, params]) => {
        this.accounting.next(
          new Accounting(
            {
              region,
              year,
              accountingMethod: params.accountingMethod,
              electricityMix: this.convertMixType(params.mixType),
              weatherCorrection: this.convertWeatherCorrection(params.weatherCorrection),
              useUserFactors: params.useUserFactors,
              timestamp: params.timestamp
            },
            this.rankService,
            this.emissionDataService,
            this.regionPropertiesDataService
          )
        );
      });
  }

  private getSelectedYear(): Observable<number> {
    return this.timelineService.getSelectedYear();
  }

  private convertMixType(mixType: LegacyMixType): ElectricityMix {
    switch (mixType) {
      case LegacyMixType.LOCAL:
        return ElectricityMix.REGION_SPECIFIC;
      case LegacyMixType.FEDERAL:
        return ElectricityMix.NATIONAL;
      default:
        throw Error(`Unknown electricity mix ${mixType}`);
    }
  }

  private convertWeatherCorrection(weatherCorrection: boolean): WeatherCorrection {
    return weatherCorrection ? WeatherCorrection.ON : WeatherCorrection.OFF;
  }
}
