import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from "@angular/core";
import { EneChartComponent } from "@energy-city/ui/chart";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { isEqual } from "lodash";
import { Subject, combineLatest } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { DisplayChartConfig, EnergyType } from "../../../../../models/charts.models";
import { AccountingMethodService } from "../../../../../services/accounting-method.service";
import { ChartsService } from "../../../../../services/charts.service";
import { RegionService } from "../../../../../services/region.service";
import { UtilService } from "../../../../../services/util.service";
import * as fromRoot from "../../../../../state/index";
import { DISPLAY_CONFIG, HideExport, YEAR } from "../../const";
import { energyTypeColorMap } from "../../greenhouse-gases/greenhouse-gases.model";
import { IChartData, ISeriesData } from "../../models/final-energy-data.interface";
import { Co2CockpitKPIService } from "../../services/co2-cockpit-kpi.service";
import { FinalEnergyService } from "../final-energy.service";
import { ChartFinalEnergyPerEnergyCarrierService } from "./chart-final-energy-per-energy-carrier.service";
import { EnergyCarrierService } from "./energy-carrier.service";

@Component({
  selector: "app-chart-final-energy-per-energy-carrier",
  templateUrl: "./chart-final-energy-per-energy-carrier.component.html",
  styleUrls: ["./chart-final-energy-per-energy-carrier.component.scss"],
  providers: [ChartFinalEnergyPerEnergyCarrierService]
})
export class ChartFinalEnergyPerEnergyCarrierComponent implements OnInit, OnDestroy {
  @ViewChild("chart", { static: true }) private chart: EneChartComponent;

  public chartData: any = [];
  public chartConfig: any;

  public dqi: number;
  public energyType: EnergyType = EnergyType.FINAL;
  public defaultDisplayConfig: DisplayChartConfig = {
    switcher: true
  };

  private categoryKeys: string[] = [];
  private destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private accountingMethodService: AccountingMethodService,
    private utilService: UtilService,
    private energyCarrierService: EnergyCarrierService,
    private translate: TranslateService,
    private chartFinalEnergyCarrierService: ChartFinalEnergyPerEnergyCarrierService,
    private chartsService: ChartsService,
    private co2CockpitKPIService: Co2CockpitKPIService,
    private regionService: RegionService,
    private finalEnergyService: FinalEnergyService,
    @Optional() @Inject(YEAR) private reportYear: number,
    @Optional() @Inject(DISPLAY_CONFIG) public displayConfig: DisplayChartConfig,
    @Optional() @Inject(HideExport) public hideExport: boolean
  ) {}

  public ngOnInit() {
    this.translate.get("COCKPIT.TOTAL").subscribe(() => {
      this.chartFinalEnergyCarrierService.initConfig();
      this.chartConfig = this.chartFinalEnergyCarrierService.config;
    });

    combineLatest([
      this.accountingMethodService.currentParameters$,
      this.regionService.selectedRegionUpLevels$,
      this.store.pipe(select(fromRoot.GetTimelineState))
    ])
      .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe(() => {
        this.requestChartData();
      });

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.chartData = [
        ...this.chartData.map((item) => ({ ...item, name: this.translate.instant(item.translationKey) }))
      ];
      this.chartConfig.xAxis.categories = this.getCategories();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public onChange(type: EnergyType) {
    this.energyType = type;
    this.requestChartData();
  }

  private calculateTotal(total, _) {
    const calculate = (object) => {
      if (object.value) {
        return object.value;
      } else {
        let sum = 0;
        Object.values(object).forEach((val) => {
          sum += calculate(val);
        });
        return sum;
      }
    };
    return total ? calculate(total) : 0;
  }

  private requestChartData() {
    this.chart.chart && this.chart.showLoading();
    const year = this.reportYear || this.utilService.getSelectedYear();
    const selectedRegionDetails = this.regionService.selectedRegionDetails;

    const chartData$ = this.energyCarrierService.getEnergyData(
      selectedRegionDetails,
      this.energyType,
      year,
      this.accountingMethodService.weatherCorrection$.value,
      this.accountingMethodService.selectedMixType$.value
    );

    chartData$.pipe(takeUntil(this.destroy$)).subscribe((energy) => this.updateData(energy));

    const regionIdentifier20 = this.regionService.getSelectedRegionLevelId(20);

    if (!regionIdentifier20) {
      return;
    }

    const chartData20$ = this.energyCarrierService.getEnergyData(
      regionIdentifier20,
      this.energyType,
      year,
      this.accountingMethodService.weatherCorrection$.value,
      this.accountingMethodService.selectedMixType$.value
    );
    const childLevels$ = this.regionService.getRegionChildLevels(regionIdentifier20.regionId);

    this.co2CockpitKPIService.requestChartData({
      selectedRegionLevel: selectedRegionDetails.level,
      regionType: selectedRegionDetails.regionType,
      chartData$,
      chartData20$,
      childLevels$,
      calculateTotal: this.calculateTotal
    });
  }

  private updateData(energy) {
    this.chart.chart && this.chart.hideLoading();
    if (energy === null) {
      return;
    }

    const data = this.getChartData(energy);

    this.energyCarrierService
      .getDqi(
        this.reportYear,
        this.accountingMethodService.weatherCorrection$.value,
        this.accountingMethodService.selectedMixType$.value,
        this.energyType,
        energy
      )
      .subscribe((dqi) => (this.dqi = dqi));

    this.chartData = this.chartsService.setSerieNames(data.series);
    this.chartFinalEnergyCarrierService.setCategories(data.categories);
  }

  private getChartData(data: IChartData): { categories: Array<string>; series: Array<ISeriesData> } {
    const { series, categoryKeys } = this.finalEnergyService.getSeries(data, energyTypeColorMap);
    this.categoryKeys = categoryKeys;

    return {
      categories: this.getCategories(),
      series
    };
  }

  private getCategories(): Array<string> {
    return this.categoryKeys.map((key) => this.translate.instant(key));
  }
}
