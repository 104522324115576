import { forkJoin, Observable } from "rxjs";
import { map, share } from "rxjs/operators";
import { IRegionIdentifier } from "../../../common/model/region-identifier";
import { EmissionDataService } from "../../accounting-emission/data/emission-data.service";
import { KpiEmissionRankDataService } from "../../accounting-emission/data/kpi-emission-rank-data.service";
import { EmissionTimeSeries } from "../../accounting-emission/model/emission-time-series";
import { EmissionsPerSector } from "../../accounting-emission/model/emissions-per-sector";
import { Rank } from "../../accounting-emission/model/kpi/rank";
import { Nh3PerSector } from "../../accounting-emission/model/nh3-per-sector";
import { TotalEmissionsPerSector } from "../../accounting-emission/model/total-emissions-per-sector";
import {
  BuildingsCount,
  RegionPropertiesDataService,
} from "../data/region-properties-data.service";
import { AccountingMethod } from "./accounting-methods/accounting-method.enum";
import { AccountingMethodFactory } from "./accounting-methods/accounting-method.factory";
import { ElectricityMix } from "./electricity-mix.enum";
import { RegionProperties } from "./population/region-properties";
import { MainGhgSector } from "./sectors/main-ghg-sector.interface";
import { Sector } from "./sectors/sector.enum";
import { WeatherCorrection } from "./weather-correction.enum";

export class Accounting {
  readonly region: IRegionIdentifier;
  readonly year: number;
  readonly accountingMethod: AccountingMethod;
  readonly weatherCorrection: WeatherCorrection;
  readonly useUserFactors: boolean;
  readonly electricityMix: ElectricityMix;
  readonly timestamp: number;
  private readonly sectors: Array<MainGhgSector>;
  private $regionProperties?: Observable<RegionProperties | null>;
  private $regionBuildingsCount?: Observable<BuildingsCount | null>;

  constructor(
    private params: {
      region: IRegionIdentifier;
      year: number;
      accountingMethod: AccountingMethod;
      electricityMix: ElectricityMix;
      weatherCorrection: WeatherCorrection;
      useUserFactors: boolean;
      timestamp?: number;
    },
    private rankService: KpiEmissionRankDataService,
    private emissionDataService: EmissionDataService,
    private regionPropertiesDataService: RegionPropertiesDataService
  ) {
    this.region = this.params.region;
    this.year = this.params.year;
    this.accountingMethod = this.params.accountingMethod;
    this.electricityMix = this.params.electricityMix;
    this.weatherCorrection = this.params.weatherCorrection;
    this.useUserFactors = this.params.useUserFactors;
    this.timestamp = this.params.timestamp ?? 0;
    this.sectors = AccountingMethodFactory.create(this.accountingMethod, this.emissionDataService).getSectors();
  }

  getEmissionRank(): Observable<Rank | null> {
    return this.rankService.getRank(
      this.region,
      this.accountingMethod,
      this.year,
      this.weatherCorrection,
      this.electricityMix
    );
  }

  /**
   * Gets a EmissionsPerSector object for the current year which includes the co2 emissions for all sectors
   * @returns EmissionsPerSector -- emissions data for all sectors for the current year
   */
  getEmissionsPerSector(): Observable<EmissionsPerSector> {
    return forkJoin(
      this.sectors.map((sec) =>
        sec.getCo2EmissionsForYear(
          this.region,
          this.year,
          this.useUserFactors,
          this.weatherCorrection,
          this.electricityMix
        )
      )
    ).pipe(map((sectors) => new EmissionsPerSector(sectors)));
  }

  getNh3PerSector(): Observable<Nh3PerSector> {
    return forkJoin([
      ...this.sectors.map(
        (sec) =>
          // call the nh3 endpoint where available (only afolu)...
          sec.getNh3ForYear?.(this.region, this.year) ??
          // ...or return null if not available
          null
      ).filter((nh3) => nh3 != null)]
    ).pipe(map((sectors) => new Nh3PerSector(sectors)));
  }

  /**
   * Gets a EmissionTimeSeries object which includes the co2 emissions for the main sectors for each year
   * @returns EmissionTimeSeries -- emissions data for the main sectors for each year
   */
  getEmissionTimeSeries(): Observable<EmissionTimeSeries> {
    return forkJoin(
      this.sectors.map((sec) =>
        sec.getTotalCo2EmissionTimeSeries(
          this.region,
          this.useUserFactors,
          this.weatherCorrection,
          this.electricityMix
        )
      )
    ).pipe(map((sectors) => new EmissionTimeSeries(sectors)));
  }

  getTotalEmissionsPerSector(): Observable<TotalEmissionsPerSector> {
    return forkJoin(
      this.sectors.map((sec) =>
        sec.getTotalCo2EmissionsForYear(
          this.region,
          this.year,
          this.useUserFactors,
          this.weatherCorrection,
          this.electricityMix
        )
      )
    ).pipe(map((sectors) => new TotalEmissionsPerSector(sectors)));
  }

  getRegionProperties(): Observable<RegionProperties | null> {
    if (!this.$regionProperties) {
      this.$regionProperties = this.regionPropertiesDataService
        .getRegionProperties(this.region, this.year)
        .pipe(share());
    }

    return this.$regionProperties;
  }

  getSectors(): Array<Sector> {
    return this.sectors.map((sector) => sector.sector);
  }

  getRegionBuildingsCount(): Observable<BuildingsCount | null> {
    if (!this.$regionBuildingsCount) {
      this.$regionBuildingsCount = this.regionPropertiesDataService
        .getRegionBuildingsCount(this.region, this.year)
        .pipe(share());
    }
    return this.$regionBuildingsCount;
  }
}
