import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimelineComponent } from "./timeline/timeline.component";
import { CockpitComponent } from "./cockpit/cockpit.component";
import { TranslateModule } from "@ngx-translate/core";
import { AccountingEmissionModule } from "../../accounting/accounting-emission/accounting-emission.module";

@NgModule({
  declarations: [TimelineComponent, CockpitComponent],
  imports: [CommonModule, TranslateModule, AccountingEmissionModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TimelineComponent, CockpitComponent]
})
export class ComponentsModule {}
