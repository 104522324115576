import { TimelineUpdate } from "../../state/timeline/timeline-actions";
import { trackEvent } from "@redux-beacon/google-analytics";

export const timelineChange = trackEvent((action: TimelineUpdate) => {
  const response: any = {
    category: "timeline",
    action: action.type,
    label: action.year
  };
  return response;
});
