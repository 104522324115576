import { Action } from "@ngrx/store";

export enum ConsumptionActionType {
  GET_CONSUMPTION_DATA = "[energy-publicSector] GET_CONSUMPTION_DATA",
  GET_CONSUMPTION_DATA_SUCCESS = "[energy-publicSector] GET_CONSUMPTION_DATA_SUCCESS",
  GET_CONSUMPTION_DATA_ERROR = "[energy-publicSector] GET_CONSUMPTION_DATA_ERROR"
}

export class GetConsumptionData implements Action {
  public readonly type = ConsumptionActionType.GET_CONSUMPTION_DATA;

  constructor(public regionId: string, public year: number, public scenarioId: string) {}
}

export class GetConsumptionDataSuccess implements Action {
  public readonly type = ConsumptionActionType.GET_CONSUMPTION_DATA_SUCCESS;

  constructor(public data: IConsumptionData) {}
}

export class GetConsumptionDataError implements Action {
  public readonly type = ConsumptionActionType.GET_CONSUMPTION_DATA_ERROR;

  constructor(public error: string) {}
}

export interface IConsumptionData {
  electricity: number;
  gas: number;
}

export type ConsumptionAction = GetConsumptionData | GetConsumptionDataSuccess | GetConsumptionDataError;
