import { Injectable, OnDestroy } from "@angular/core";
import { Subject, Observable, combineLatest } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { GetQuantitiveBenchmark, IApplicationState } from "../../../state/index";
import { UtilService } from "../../../services/util.service";
import { AccountingMethodService } from "../../../services/accounting-method.service";
import { ChartsService } from "../../../services/charts.service";
import { EneKpisV2Service } from "@energy-city/ui/kpis-v2";
import { FinalEnergyService } from "../../co2-cockpit/charts/final-energy/final-energy.service";
import { chartConfig } from "./quantitive-benchmarking.model";
import { filter, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";

@Injectable()
export class QuantitiveBenchmarkingService implements OnDestroy {
  private categoriesKeys: any;
  private config: any = chartConfig;
  private onDataChange: Subject<any> = new Subject();
  public onSeriesChange$: Observable<any>;
  private destroy$: Subject<void> = new Subject();

  protected COLORS: { [key: string]: string } = {
    "RESOURCES.WASTE": "#ED7D31",
    "RESOURCES.IPPU": "#4A4A4A",
    "RESOURCES.TRANSPORTATION": "#008797",
    "RESOURCES.STATIONARY_ENERGY": "#5D2F58",
    "RESOURCES.AFOLU": "#8AAE1B"
  };

  constructor(
    private eneKpisV2Service: EneKpisV2Service,
    private translate: TranslateService,
    private utilService: UtilService,
    private store: Store<IApplicationState>,
    private accountingMethodService: AccountingMethodService,
    private finalEnergyService: FinalEnergyService,
    private chartsService: ChartsService
  ) {
    this.onSeriesChange$ = this.onDataChange.asObservable();

    combineLatest([
      this.utilService.regionId$.pipe(filter((regionId) => !!regionId)),
      this.utilService.selectedYear$,
      this.accountingMethodService.weatherCorrection$.pipe(
        filter((isWeatherCorrection) => isWeatherCorrection !== undefined)
      ),
      this.accountingMethodService.selectedAccountingMethod$,
      this.accountingMethodService.timestamp$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(new GetQuantitiveBenchmark());
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public getChartConfig(): any {
    const self = this;

    this.config.yAxis.title.text = this.translate.instant("COCKPIT.CO2.TABS.BENCHMARKING.CO2E_PER_CAPITA");
    this.config.chart["events"] = {
      load: function () {
        if (this.series.length) {
          self.onDataChange.next(this.series);
        }
      }
    };
    this.config.plotOptions.series["events"] = {
      hide: function () {
        self.onDataChange.next(this.chart.series);
      },
      show: function () {
        self.onDataChange.next(this.chart.series);
      }
    };

    return this.config;
  }

  public getKpiTotal(series: any): number {
    return this.finalEnergyService.getKpiTotal(series, this.utilService.getSelectedYear());
  }

  public renewKPI(kpi) {
    const { kpiAverage, kpiTotal } = kpi;
    if (kpiAverage === undefined || kpiTotal === undefined) {
      return;
    }
    const difference_to_average = kpiAverage - kpiTotal;
    this.eneKpisV2Service.runMapping({ total: kpiTotal, average: kpiAverage, difference_to_average }, "benchmark_kpi");
  }

  public convertDataToChartFormat(data: Array<{ data: { [key: string]: { value: number } }; level: number }>) {
    const categories = data
      // REMOVE THIS FILTER DO DISPLAY GERMANY AGAIN
      .filter((item) => item.level !== 10)
      // -------------------------------------------
      .map((item) => +item.level)
      .filter((item, idx, arr) => arr.indexOf(item) === idx)
      .sort((a, b) => b - a);
    let series = {};
    data.forEach((item) => {
      for (const key of Object.keys(item.data)) {
        const itemValue = item.data[key].value;
        if (!series[key]) {
          const translationKey = `RESOURCES.${key.toUpperCase().replace("-", "_")}`;
          series[key] = {
            translationKey,
            data: Array(categories.length).fill(0),
            color: this.COLORS[translationKey]
          };
        }
        const dataIdx = categories.findIndex((category) => category === +item.level);

        if (dataIdx >= 0) {
          series[key].data[dataIdx] += itemValue;
        }
      }
    });

    if (!Object.values(series).length) {
      return this.convertDataToChartFormat(null);
    }
    this.setCategories(categories);
    series = Object.values(series);

    this.chartsService.setSerieNames(series);
    return series;
  }

  public setCategories(categories: Array<any>) {
    this.categoriesKeys = categories.map((item) => `LEVELS.${item}`);
  }

  public getCategories(): Array<string> {
    return this.categoriesKeys.map((key) => this.translate.instant(key));
  }
}
