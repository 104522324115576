/* tslint:disable:member-ordering */

import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { map, catchError, switchMap } from "rxjs/operators";

import { Co2CockpitActionType, GetCo2CockpitKpiEmissionSuccess, GetCo2CockpitKpiEmission } from "./co2-cockpit-actions";
import { AccountingMethodService } from "../../services/accounting-method.service";
import { UtilService } from "../../services/util.service";
import { SECTORS_NAMES } from "../../configs/accounting-method";
import { HttpClient, HttpParams } from "@angular/common/http";
import { forkJoin, of } from "rxjs";
import { minYear } from "../../models/co2-kpi.model";
import { sectorConfig } from "../../models/co2-sectors-config.model";

@Injectable()
export class Co2CockpitEffects {
  @Effect() public GetCo2CockpitKpiEmission$ = this.actions$.pipe(
    ofType<GetCo2CockpitKpiEmission>(Co2CockpitActionType.GET_KPI_EMISSION),
    switchMap(() => {
      const sectors = SECTORS_NAMES[this.accountingMethodService.selectedAccountingMethod$.value];
      const weatherCorrection = String(this.accountingMethodService.weatherCorrection$.value);
      const { regionId, regionType } = this.utilService.getRegionIdentifier();

      const urls: string[] = sectorConfig.sectors
        .filter((sector) => sectors.includes(sector.type))
        .map((sector) => {
          const url = sector.service().concat(
            sector.getEndpointRoute(regionId, 0, regionType, sector.energyType)
          );
          return url;
        });

      const emissionParams = new HttpParams()
        .set("weatherCorrection", weatherCorrection)
        .set("year", "" + this.utilService.getSelectedYear())
        .set("useUserFactors", `${this.accountingMethodService.useUserFactors$.getValue()}`);
      const reducedParams = new HttpParams().set("weatherCorrection", weatherCorrection).set("year", "" + minYear);
      return forkJoin(
        urls.reduce((acc, url) => {
          acc.push(this.http.get(`${url}`, { params: emissionParams }).pipe(catchError(() => of(null))));
          acc.push(this.http.get(`${url}`, { params: reducedParams }).pipe(catchError(() => of(null))));
          return acc;
        }, [])
      ).pipe(
        map((data) => {
          const [emission_absolute, reduction_absolute] = data.reduce<Array<any>>(
            (acc, item, idx) => {
              if (item && Object.values(item).length) {
                acc[idx % 2].push(item);
              }
              return acc;
            },
            [[], []]
          );
          return new GetCo2CockpitKpiEmissionSuccess({
            emission_absolute,
            reduction_absolute
          });
        })
      );
    })
  );

  constructor(
    private http: HttpClient,
    private accountingMethodService: AccountingMethodService,
    private utilService: UtilService,
    private actions$: Actions
  ) {}
}
