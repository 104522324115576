import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  ConsumptionActionType,
  GetConsumptionData,
  GetConsumptionDataError,
  GetConsumptionDataSuccess
} from "./consumption-actions";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { acceptJsonWithoutCache, createErrorMessage } from "../../../common";

export interface IConsumptionDataResponse {
  result?: {
    electricity: number;
    gas: number;
  };
}

@Injectable()
export class ConsumptionEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  /**
   * returns details about the consumption of a given region.
   */
  @Effect() public getConsumptionData$ = this.actions$.pipe(
    ofType(ConsumptionActionType.GET_CONSUMPTION_DATA),
    switchMap((action: GetConsumptionData) => {
      const uri = `${environment.emission.calculationApi}/publicsector/regions/${action.regionId}/consumption`;
      return this.http
        .get<IConsumptionDataResponse>(uri, {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams().set("year", String(action.year)).set("scenarioId", action.scenarioId)
        })
        .pipe(
          map((data: IConsumptionDataResponse) => new GetConsumptionDataSuccess(data.result)),
          catchError(
            (error): Observable<GetConsumptionDataError> => of(new GetConsumptionDataError(createErrorMessage(error)))
          )
        );
    })
  );
}
