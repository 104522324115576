/* This module is inspired by this article: http://jasonwatmore.com/post/2018/05/25/angular-6-custom-modal-window-dialog-box */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneModalComponent } from "./components/modal.component";
import { EneModalService } from "./services/modal.service";

@NgModule({
  declarations: [EneModalComponent],
  providers: [EneModalService],
  imports: [CommonModule, EneIconModule],
  exports: [EneModalComponent]
})
export class EneModalModule {}
