import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { APP_BASE_HREF, CommonModule } from "@angular/common";

/** components */
import { DashboardComponent } from "@energy-city/components";

/** guards */
import { EneModalService } from "@energy-city/ui/modal";
import { EmptyRouteComponent } from "./empty-route/empty-route.component";
import { Co2balanceSidenavigationComponent } from "@energy-city/co2balance-components";

const appRoutes: Routes = [
  { path: "dashboard", component: DashboardComponent },
  {
    path: "co2/:panel",
    component: Co2balanceSidenavigationComponent,
    data: { module: "co2" }
  },
  { path: "", redirectTo: "co2/cockpit", pathMatch: "full" },
  { path: "**", component: EmptyRouteComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      relativeLinkResolution: "legacy"
    })
  ],
  exports: [RouterModule],
  providers: [EneModalService, [{ provide: APP_BASE_HREF, useValue: "/co2balance" }]]
})
export class AppRoutingModule {}
