import { Action } from "@ngrx/store";
import {
  AnyFactorEmissionResponseDto,
  FactorEndpointId,
  IFactorResponseDtoMap
} from "../../components/factor-management/factor-management.endpoint-config";

export enum FactorEmissionActionTypes {
  GET_FACTOR_EMISSION_DATA = "[Factor Emission Data]: Get factor-emission data",
  GET_FACTOR_EMISSION_DATA_SUCCESS = "[Factor Emission Data]: Get factor-emission data successfully",
  GET_FACTOR_EMISSION_DATA_ERROR = "[Factor Emission Data]: Get factor-emissiondata error",
  PATCH_FACTOR_EMISSION_DATA = "[Factor Emission Data]: Patch factor-emission data",
  PATCH_FACTOR_EMISSION_DATA_ERROR = "[Factor Emission Data]: Patch factor-emissiondata error"
}

export class GetFactorEmissionData implements Action {
  public readonly type = FactorEmissionActionTypes.GET_FACTOR_EMISSION_DATA;

  constructor(public regionId: string, public endpoints: Array<FactorEndpointId>) {}
}

export class GetFactorEmissionDataSuccess implements Action {
  public readonly type = FactorEmissionActionTypes.GET_FACTOR_EMISSION_DATA_SUCCESS;

  constructor(public payload: Array<AnyFactorEmissionResponseDto>) {}
}

export class GetFactorEmissionDataError implements Action {
  public readonly type = FactorEmissionActionTypes.GET_FACTOR_EMISSION_DATA_ERROR;

  constructor(public errorMessage: string) {}
}

export class PatchFactorEmissionData implements Action {
  public readonly type = FactorEmissionActionTypes.PATCH_FACTOR_EMISSION_DATA;

  constructor(
    public regionId: string,
    public data: Array<{ endpointId: FactorEndpointId; data: IFactorResponseDtoMap[FactorEndpointId] }>
  ) {}
}

export type FactorEmissionActionsUnion =
  | GetFactorEmissionData
  | GetFactorEmissionDataSuccess
  | GetFactorEmissionDataError
  | PatchFactorEmissionData;
