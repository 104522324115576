import { ActionReducerMap } from "@ngrx/store";
import { reducer } from "./kpi-reducer";

export interface IAttribute {
  value: string;
  name: string;
  year: number;
}

export interface State {
  loading?: boolean;
  attributes: Array<IAttribute>;
}

export const gasCockpitKpiReducers: ActionReducerMap<any> = {
  gasCockpitKpi: reducer
};
