import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
  IApplicationState,
  AccountingMethodService as LegacyAccountingMethodService,
  MixType as LegacyMixType
} from "@energy-city/components";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import {
  ITimelineState,
  getTimelineState,
  getTimelineYear
} from "../../../../../../components/src/lib/app/state/timeline";
import { ISelectedRegionService, SELECTED_REGION_SERVICE_TOKEN } from "../../../common/state";
import { inputIsNotNullOrUndefined } from "../../../common/utils/util-rxjs";
import { EmissionDataService } from "../../accounting-emission/data/emission-data.service";
import { KpiEmissionRankDataService } from "../../accounting-emission/data/kpi-emission-rank-data.service";
import { RegionPropertiesDataService } from "../data/region-properties-data.service";
import { Accounting } from "../model/accounting";
import { ElectricityMix } from "../model/electricity-mix.enum";
import { WeatherCorrection } from "../model/weather-correction.enum";
import { IActiveAccountingService } from "./active-accounting.interface";

@Injectable({
  providedIn: "root"
})
export class ActiveAccountingStandaloneService implements OnDestroy, IActiveAccountingService {
  private accounting: BehaviorSubject<Accounting | null> = new BehaviorSubject<Accounting | null>(null);

  private accountingSubscriptions: Subscription;

  constructor(
    @Inject(SELECTED_REGION_SERVICE_TOKEN) private selectedRegionService: ISelectedRegionService,
    private accountingMethodService: LegacyAccountingMethodService,
    private store: Store<IApplicationState>,
    private rankService: KpiEmissionRankDataService,
    private emissionDataService: EmissionDataService,
    private regionPropertiesDataService: RegionPropertiesDataService
  ) {
    this.accountingSubscriptions = this.subscribeAccountingUpdates();
  }

  getActiveAccounting(): Observable<Accounting> {
    return this.accounting.pipe(filter(inputIsNotNullOrUndefined));
  }

  ngOnDestroy() {
    this.accountingSubscriptions.unsubscribe();
  }

  getActiveAccounting1990(): Observable<Accounting> {
    return combineLatest([
      this.selectedRegionService.getSelectedRegionIdentifier(),
      this.accountingMethodService.currentParameters$
    ]).pipe(
      map(
        ([region, params]) =>
          new Accounting(
            {
              region,
              year: 1990,
              accountingMethod: params.accountingMethod,
              electricityMix: this.convertMixType(params.mixType),
              weatherCorrection: this.convertWeatherCorrection(params.weatherCorrection),
              useUserFactors: params.useUserFactors,
              timestamp: params.timestamp
            },
            this.rankService,
            this.emissionDataService,
            this.regionPropertiesDataService
          )
      )
    );
  }

  private subscribeAccountingUpdates(): Subscription {
    return combineLatest([
      this.selectedRegionService.getSelectedRegionIdentifier(),
      this.getSelectedYear(),
      this.accountingMethodService.currentParameters$
    ]).subscribe(([region, year, params]) => {
      this.accounting.next(
        new Accounting(
          {
            region,
            year,
            accountingMethod: params.accountingMethod,
            electricityMix: this.convertMixType(params.mixType),
            weatherCorrection: this.convertWeatherCorrection(params.weatherCorrection),
            useUserFactors: params.useUserFactors,
            timestamp: params.timestamp
          },
          this.rankService,
          this.emissionDataService,
          this.regionPropertiesDataService
        )
      );
    });
  }

  private getSelectedYear(): Observable<number> {
    return this.store.select(getTimelineState).pipe(
      map((state: ITimelineState) => {
        return getTimelineYear(state);
      })
    );
  }

  private convertMixType(mixType: LegacyMixType): ElectricityMix {
    switch (mixType) {
      case LegacyMixType.LOCAL:
        return ElectricityMix.REGION_SPECIFIC;
      case LegacyMixType.FEDERAL:
        return ElectricityMix.NATIONAL;
      default:
        throw Error(`Unknown electricity mix ${mixType}`);
    }
  }

  private convertWeatherCorrection(weatherCorrection: boolean): WeatherCorrection {
    return weatherCorrection ? WeatherCorrection.ON : WeatherCorrection.OFF;
  }
}
