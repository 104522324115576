import { Observable } from "rxjs";

export abstract class EneEditComponentChange<T> {
  // called once: returns a stream of value whenever its changed
  public abstract connect(): Observable<T | undefined>;

  // returns the currentValue
  // normally called once at OnInit
  public abstract get value(): T;

  // used to set the value from outside
  public abstract set value(value: T);

  // called once: to close all open connections
  public abstract disconnect(): void;
}
