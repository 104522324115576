<div class="ene-surface-box-raised header">
  <div class="header__content">
    <img [src]="getAssetUrl('/images/logos/') + infoSectorsTab.header_img + '.jpg'" />
    <div class="ene-surface-box-feature header__content__text">
      <p [innerHTML]="infoSectorsTab.header | translate"></p>
    </div>
    <table class="header__content__table">
      <tr>
        <ng-container *ngIf="population !== undefined; else empty">
          <td [innerHTML]="population | eneUnit: pipeDecimal"></td>
        </ng-container>
        <td>{{ "CLIENTS.DEFAULT.INFO.DETAILS.INHABITANTS" | translate }}</td>
      </tr>
      <tr>
        <ng-container *ngIf="buildings_count !== undefined; else empty">
          <td [innerHTML]="buildings_count | eneUnit: pipeDecimal"></td>
        </ng-container>
        <td>{{ "CLIENTS.DEFAULT.INFO.DETAILS.BUILDINGS_COUNT" | translate }}</td>
      </tr>
      <tr>
        <ng-container *ngIf="area_m2 !== undefined; else empty">
          <td [innerHTML]="area_m2 | eneUnit: pipeArea"></td>
        </ng-container>
        <td>{{ "CLIENTS.DEFAULT.INFO.DETAILS.AREA_M2" | translate }}</td>
      </tr>
    </table>
  </div>
</div>
<ene-tabs mode="horizontal block" #tabs>
  <ene-tab
    *ngFor="let tab of infoSectorsTab.tabs"
    [tabTitle]="tab.name | translate"
    [icon]="tab.icon"
    [active]="tab.active"
  >
    <div class="tab-content" *ngIf="tab.data_foundation">
      <section>
        <app-data-foundation
          [sectors]="tab.data_foundation.sectors[0]"
          [sources]="tab.data_foundation.sources"
          [accountingMethods]="tab.data_foundation.accountingMethods"
        >
        </app-data-foundation>
      </section>
      <section>
        <h3>{{ getTenantSpecificTranslation('INFO.DETAILS.DESCRIPTION_TITLE') | translate }}</h3>
        <div class="tab-content__description" [innerHTML]="tab.description | translate"></div>
      </section>
      <section>
        <img [src]="getAssetUrl('/images/info/sectors/') + tab.image + '.jpg'" />
      </section>
    </div>
    <div class="tab-content" *ngIf="!tab.data_foundation">
      <section>
        <app-info-climate-goals class="verticalFlex"></app-info-climate-goals>
      </section>
      <section>
        <app-info-standards class="verticalFlex" (goToTab)="goToTab()"></app-info-standards>
      </section>
      <section>
        <app-info-datasource class="verticalFlex"></app-info-datasource>
      </section>
    </div>
  </ene-tab>
</ene-tabs>
<ng-template #empty>
  <td>{{ "TDI.NOT_AVAILABLE.LONG" | translate }}</td>
</ng-template>
