import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneHeaderComponent } from "./components/ui-header.component";
import { EneHeaderRowDirective } from "./directives/header-row.directive";
import { EneHeaderLeftDirective } from "./directives/header-left.directive";
import { EneHeaderRightDirective } from "./directives/header-right.directive";
import { EneHeaderCenterDirective } from "./directives/header-center.directive";

@NgModule({
  declarations: [
    EneHeaderComponent,
    EneHeaderRowDirective,
    EneHeaderLeftDirective,
    EneHeaderRightDirective,
    EneHeaderCenterDirective
  ],
  imports: [CommonModule],
  exports: [
    EneHeaderComponent,
    EneHeaderRowDirective,
    EneHeaderLeftDirective,
    EneHeaderRightDirective,
    EneHeaderCenterDirective
  ]
})
export class EneHeaderModule {}
