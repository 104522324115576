export const newSimulationScenario: ISimulationScenario = {
  scenarioId: null,
  title: "SIMULATION.SCENARIOS.CREATE_SCENARIO",
  description: "SIMULATION.SCENARIO.DESCRIPTION",
  regionId: null,
  default: false,
  values: []
};

export interface ISimulationScenarioValue {
  goal: number;
  year: number;
}

export interface ISimulationScenarioValue {
  goal: number;
  year: number;
}

export interface ISimulationScenario {
  title: string;
  default: boolean;
  scenarioId: string;
  regionId: string;
  description: string;
  values?: ISimulationScenarioValue[];
}

export interface IGetScenariosSuccess {
  result: ISimulationScenario[];
}
export interface IGetReportScenarioSuccess {
  result: ISimulationScenario;
}
