import { Subject } from "rxjs";
import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { onlyKwhUnit, valueAndTotalFormatter } from "../../tooltip-formatter";

@Injectable()
export class ChartFinalEnergyPerEnergyCarrierService {
  public config;
  public onDataChange$: Subject<Array<any>> = new Subject();
  private categories: Array<string> = [];

  constructor(private decimalPipe: DecimalPipe, private translate: TranslateService) {}

  public setCategories(categories: Array<string>) {
    this.categories = categories;
    if (this.config) {
      this.config.xAxis.categories = this.categories;
    }
  }

  public initConfig(): void {
    const that = this;

    this.config = {
      chart: {
        unit: " [kWh]",
        type: "column",
        marginTop: 30,
        marginRight: 20,
        events: {
          load: function () {
            if (this.series.length) {
              that.onDataChange$.next(this.series);
            }
          }
        }
      },
      xAxis: {
        categories: this.categories,
        labels: {
          style: {
            fontSize: "0.8em"
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          useHTML: true,
          text: "GWh"
        },
        stackLabels: {
          enabled: false
        },
        labels: {
          overflow: false,
          formatter: this.yAxisLabelFormatter()
        }
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        formatter: this.tooltipFormatter()
      },
      legend: {
        useHTML: true,
        itemMarginBottom: 5,
        itemDistance: 16,
        symbolRadius: 2,
        symbolPadding: 10,
        itemStyle: {
          fontWeight: "normal",
          fontSize: "0.8em",
          lineHeight: "normal"
        }
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false
          }
        },
        series: {
          pointWidth: 45,
          events: {
            hide: function () {
              that.onDataChange$.next(this.chart.series);
            },
            show: function () {
              that.onDataChange$.next(this.chart.series);
            }
          }
        }
      }
    };
  }

  private tooltipFormatter() {
    const translate = this.translate;

    return function () {
      return valueAndTotalFormatter({
        unitConfig: onlyKwhUnit,
        title: this.series.name,
        value: this.point.y,
        total: this.point.stackTotal,
        translate
      });
    };
  }

  private yAxisLabelFormatter() {
    const format = this.decimalPipe.transform;
    const locale = this.translate.currentLang;
    return function () {
      return format(this.value / 1000000, "1.0-2", locale);
    };
  }
}
