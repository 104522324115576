import { ActionReducerMap } from "@ngrx/store/src/models";
import { reducer } from "./sidenavigation-reducer";

export const sidenavigationReducers: ActionReducerMap<any> = {
  sidenavigation: reducer
};

export interface ISideNavigationState {
  [key: string]: any;
}
