import { Component, Input, OnInit, Injector, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as fromRoot from "@energy-city/components";
import { PrintService } from "../../services/print.service";
import { UtilService, EntryComponents, AccountingMethod } from "@energy-city/components";
import { NUMBER_FORMAT } from "../../reports.config";
import { HideExport, YEAR } from "../../../../modules/co2-cockpit/charts/const";
import { EnergyTrafficService } from "../../../co2-cockpit/charts/final-energy/traffic/energy-traffic.service";
import { TranslateService } from "@ngx-translate/core";

enum TableColumns {
  SECTOR = "sector",
  ENERGY = "energy"
}

@Component({
  selector: "app-transportation-page",
  templateUrl: "./transportation.component.html",
  styleUrls: ["./transportation.component.scss"]
})
export class TransportationPageComponent implements OnInit, OnDestroy {
  @Input() public config;

  public numberFormat = NUMBER_FORMAT;
  public locale: string;
  public component: any;
  public columns: Array<any>;
  public displayedColumns: Array<string>;
  public data$: Observable<any>;
  public year: number;
  public isBisko: boolean;
  public propsInjector: Injector;
  public tableColumns = TableColumns;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private printService: PrintService,
    private utilService: UtilService,
    private injector: Injector,
    private energyTrafficService: EnergyTrafficService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.locale = this.translate.currentLang;
    this.component = EntryComponents.charts.ChartFinalEnergyTrafficComponent;
    this.year = this.printService.selectedReportYear;
    this.isBisko = this.printService.selectedAccountingMethod === AccountingMethod.BISKO;
    this.columns = this.getColumns();
    this.displayedColumns = this.columns.map((item) => item.id);
    this.data$ = this.getData();
    this.propsInjector = Injector.create(
      [
        { provide: YEAR, useValue: this.year },
        { provide: HideExport, useValue: true }
      ],
      this.injector
    );

    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.locale = this.translate.currentLang));
  }

  private getColumns(): Array<any> {
    return [
      {
        id: TableColumns.SECTOR,
        name: this.translate.instant("APP.SECTOR")
      },
      {
        id: TableColumns.ENERGY,
        name: `${this.translate.instant("APP.FINAL_ENERGY_CONSUMPTION")} [kWh]`
      }
    ];
  }

  private getData(): Observable<any> {
    return this.utilService.regionIdentifier$.pipe(
      switchMap((regionIdentifier) => this.energyTrafficService.getEnergyData(regionIdentifier, this.year)),
      map((data: any) => {
        const lvl1Keys = Object.keys(data);
        const transportation = lvl1Keys.reduce((acc, key) => ({ ...acc, ...data[key] }), {});
        const transportationKeys = Object.keys(transportation);
        const transportationMax = transportationKeys.reduce((acc, key) => acc + transportation[key].value, 0);
        const transportationItem = {
          name: "transportation",
          parent: true,
          value: transportationMax,
          percent: this.getPercent(transportationMax, transportationMax)
        };

        const transportationList = lvl1Keys
          .map((lvl1key) => {
            const lvl2Data = data[lvl1key];
            const lvl2Keys = Object.keys(lvl2Data);
            const maxValue = lvl2Keys.reduce(
              (acc, lvl2Key) => (lvl2Data[lvl2Key] ? acc + lvl2Data[lvl2Key].value : acc),
              0
            );
            const item = {
              name: lvl1key,
              parent: true,
              child: true,
              value: maxValue,
              percent: this.getPercent(maxValue, transportationMax)
            };
            const list = lvl2Keys
              .map((lvl2Key) => ({
                name: lvl2Key,
                value: lvl2Data[lvl2Key].value,
                percent: this.getPercent(lvl2Data[lvl2Key].value, transportationMax),
                child: true
              }))
              .sort((a, b) => b.value - a.value);

            return [item, ...list];
          })
          .sort((a, b) => b[0].value - a[0].value)
          .reduce((acc, list) => [...acc, ...list], []);

        return [transportationItem, ...transportationList];
      })
    );
  }

  private getPercent(current: number, max: number): number {
    return (current / max) * 100;
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
