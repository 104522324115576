import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Observable, Subject } from "rxjs";
import { map, distinctUntilChanged, startWith, takeUntil } from "rxjs/operators";

@Component({
  selector: "co2-layer-panel",
  templateUrl: "./layer-panel.component.html",
  styleUrls: ["./layer-panel.component.scss"]
})
export class LayerPanelComponent implements OnInit {
  public translationMap$!: Observable<Record<string, string>>;
  private destroy$ = new Subject();

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.subscribeForTranslationMap();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeForTranslationMap() {
    this.translationMap$ = combineLatest([
      this.translate.onLangChange.pipe(
        map((event) => event.translations),
        startWith(this.translate.translations[this.translate.currentLang]),
        distinctUntilChanged()
      ),
      this.translate.onDefaultLangChange.pipe(
        map((event) => event.translations),
        startWith(this.translate.translations[this.translate.getDefaultLang()]),
        distinctUntilChanged()
      )
    ]).pipe(
      map(([currentTranslations, fallBackTranslations]) => ({
        ...fallBackTranslations,
        ...currentTranslations
      })),
      takeUntil(this.destroy$)
    );
  }
}
