import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { filter, first, switchMap } from "rxjs/operators";
import { Accounting } from "../../../../accounting-common/model/accounting";
import { Rank } from "../../../model/kpi/rank";
@Component({
  selector: "co2-kpi-rank-emissions",
  templateUrl: "./kpi-rank-emissions.component.html",
  styleUrls: ["../kpi.component.scss"]
})
export class KpiRankEmissionsComponent implements OnInit {
  @Input() public set accounting(accounting: Accounting | null) {
    this._accounting = accounting;
    this.rank = null;
    this.updateSettings();
  }
  @Input() set enabled(enabled: boolean | null) {
    this.isEnabled = Boolean(enabled);
    this.updateSettings();
  }

  public get accounting(): Accounting | null {
    return this._accounting;
  }
  public get useUserFactors(): boolean {
    return this._accounting?.useUserFactors ?? false;
  }
  public get isUnionRegion(): boolean {
    return this._accounting?.region?.regionType === "union";
  }
  public get isKpiDisabled(): boolean {
    return !this.isEnabled || this.useUserFactors || this.isUnionRegion;
  }

  public rank: Rank | null = null;
  public isEnabled: boolean = false;

  private settings$ = new BehaviorSubject<{ accounting: Accounting | null; isEnabled: boolean }>({
    accounting: null,
    isEnabled: false
  });
  private _accounting: Accounting | null = null;

  private updateSettings() {
    const isEnabled = this.useUserFactors || this.isUnionRegion ? false : this.isEnabled;
    this.settings$.next({ accounting: this._accounting, isEnabled });
  }

  ngOnInit() {
    this.settings$
      .pipe(
        filter(
          (settings): settings is { accounting: Accounting; isEnabled: true } =>
            settings.accounting !== null && settings.isEnabled
        ),
        switchMap((settings) => {
          return settings.accounting.getEmissionRank().pipe(first());
        })
      )
      .subscribe((rank) => (this.rank = rank));
  }
}
