import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EneIconModule } from "@energy-city/ui/icon";

import { ModuleSwitchComponent } from "./components/module-switch.component";
import { ModuleSwitchService } from "./services/module-switch.service";

@NgModule({
  declarations: [ModuleSwitchComponent],
  imports: [CommonModule, EneIconModule],
  exports: [ModuleSwitchComponent]
})
export class ModuleSwitchModule {}
