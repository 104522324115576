<div>
  <div
    class="launchpad"
    [class.active]="!isDisabled && isActive"
    [class.disabled]="isDisabled"
    (click)="!isDisabled && changeState()"
  >
    <ene-icon icon="ec_header_launchpad"></ene-icon>
  </div>
</div>
