import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { EneIconModule } from "@energy-city/ui/icon";
import { ToggleButtonComponent } from "./toggle-button.component";

@NgModule({
  declarations: [ToggleButtonComponent],
  imports: [CommonModule, FormsModule, EneIconModule],
  exports: [ToggleButtonComponent]
})
export class ToggleButtonModule {}
