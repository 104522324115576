import { ISimulationState } from "./simulation-reducer";
import { IApplicationState } from "..";
import { createSelector } from "@ngrx/store";

const selectSimulation = (state: IApplicationState) => state.app.simulation;

export const getSimulationScenario = createSelector(
  selectSimulation,
  (state: ISimulationState) => state.currentScenarioId
);

export const getSimulationScenarios = createSelector(selectSimulation, (state: ISimulationState) => state.scenarios);

export const getSimulationScenarioError = createSelector(selectSimulation, (state: ISimulationState) => state.error);
