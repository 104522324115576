import { ICo2EmissionSectorResult } from "../../accounting-common/model/sectors/main-ghg-sector.interface";
import { TimeSeries } from "../../accounting-common/model/time-series/time-series";

export class EmissionTimeSeries {
  public readonly series: Array<TimeSeries>;

  constructor(sectors: ICo2EmissionSectorResult[]) {
    this.series = sectors.map((sec) => {
      const data = sec.result.map((yearlyValue) => {
        if (!yearlyValue.hasOwnProperty("value")) {
          throw Error("Expected value property not available in object.");
        }
        return { year: yearlyValue.year, value: yearlyValue["value"] };
      });
      return new TimeSeries(data, sec.sector);
    });
  }

  public getMaxYear(): number {
    const maxYearForSeries = this.series.map((series) => Math.max(...series.data.map((entry) => entry.year)));
    const maxYear = new Set(maxYearForSeries);
    if (maxYear.size === 1) {
      return maxYear.values().next().value;
    }
    throw Error("Time series have different end year");
  }

  /**
   * getTotalTimeSeries() returns a TimeSeries with all data for each year aggregated
   * @returns TimeSeries -- Object which has all values aggregated for each year
   */
  getTotalTimeSeries(): TimeSeries {
    const allYears = this.getAllYears();
    let totalTimeSeries = new TimeSeries([], "total");
    this.series.forEach((series) => {
      if (!series.hasDataForEveryYear(allYears)) {
        console.error(`TimeSerie ${series.name} has no data for some years.`);
      }
      totalTimeSeries = totalTimeSeries.add(series);
    });
    return totalTimeSeries;
  }

  /**
   * get total value for a given year
   * @param year number
   * @returns number -- total emissions for a given year
   */
  getTotal(year: number): number {
    const values: Array<number> = this.series
      .map((series) => series.data.find((yearlyValue) => yearlyValue.year === year)?.value)
      .filter((value) => {
        const hasValue = value !== undefined;
        if (!hasValue) {
          console.error(`Missing data for year ${year}.`);
        }
        return hasValue;
      }) as Array<number>;
    if (values.length === 0) {
      throw Error(`No data for year ${year}.`);
    }

    return values.reduce((sum, current) => sum + current, 0);
  }

  private getAllYears(): Array<number> {
    const years: Set<number> = new Set<number>(
      this.series
        .reduce<number[]>((yearsArray, series) => {
          return yearsArray.concat(...series.data.map((dataEntry) => dataEntry.year));
        }, [])
        .sort()
    );
    return Array.from(years);
  }
}
