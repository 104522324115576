import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  ACTIVE_ACCOUNTING_SERVICE_TOKEN,
  Accounting,
  AccountingMethod,
  ElectricityMix,
  IActiveAccountingService,
  WeatherCorrection
} from "@co2-shared/accounting-common";
import { Scenario } from "@co2-shared/accounting-simulation";
import { RegionType, inputIsNotNullOrUndefined } from "@co2-shared/common";
import * as fromRoot from "@energy-city/components";
import {
  AccountingMethodService,
  GetScenarios,
  GetSets,
  ISimulationScenario,
  MixType as LegacyMixType,
  RegionType as LegacyRegionType,
  UtilService,
  getSets,
  getSimulationScenarios,
  newSimulationScenario
} from "@energy-city/components";
import { EneSnackbarService } from "@energy-city/ui/snackbar";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { cloneDeep } from "lodash";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-simulation",
  templateUrl: "./simulation.component.html",
  styleUrls: ["./simulation.component.scss"]
})
export class SimulationComponent implements OnInit, OnDestroy {
  public simulationDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public selectionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public scenario: FormControl = new FormControl();
  public set: FormControl = new FormControl();

  public scenarios$: BehaviorSubject<any> = new BehaviorSubject(null);
  public sets$: BehaviorSubject<any> = new BehaviorSubject(null);

  public simulationVisible$: Observable<boolean>;
  // public currentScenarioText: string;
  public disableSimulation$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectedScenario: ISimulationScenario;

  public chartScenario: Scenario;
  public chartMeasureSetId: string;
  public chartAccounting: Accounting;
  public chartIsEnabled: boolean = false;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private snackbar: EneSnackbarService,
    private utilService: UtilService,
    private translate: TranslateService,
    private accountingMethodService: AccountingMethodService,
    @Inject(ACTIVE_ACCOUNTING_SERVICE_TOKEN) private activeAccountingService: IActiveAccountingService
  ) {}

  public ngOnInit(): void {
    this.subscribeScenarios();
    this.subscribeMeasures();
    this.updateUponRegionChange();
    this.listenAccounting();
  }

  public toggleSimulation() {
    this.chartIsEnabled = !this.chartIsEnabled;
    if (this.chartIsEnabled) {
      this.snackbar.info(this.translate.instant("SIMULATION.SNACKBAR.SIMULATION_ACTIVE"));
      this.selectionDisabled$.next(true);
    } else {
      this.selectionDisabled$.next(false);
    }
  }

  // activates simulation button if both dropdown have some value selected
  private activateSimulationButton() {
    if (!!this.scenario.value && this.scenario.value !== "new" && !!this.set.value) {
      this.simulationDisabled$.next(false);
    } else {
      this.simulationDisabled$.next(true);
    }
  }

  private subscribeMeasures() {
    this.store
      .pipe(
        select(getSets),
        filter((data) => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.sets$.next(data);
        this.set.setValue(undefined);
        this.set.reset();
        this.set.valueChanges.pipe(filter((setId) => !!setId)).subscribe((setId) => {
          this.chartMeasureSetId = setId;
          this.activateSimulationButton();
        });
      });
  }

  private updateUponRegionChange() {
    this.utilService.regionId$.pipe(takeUntil(this.destroy$)).subscribe((regionId) => {
      if (this.chartIsEnabled) {
        this.toggleSimulation();
      }
      this.store.dispatch(new GetSets(regionId));
      this.store.dispatch(new GetScenarios());
    });
  }

  private subscribeScenarios() {
    this.store
      .pipe(
        select(getSimulationScenarios),
        filter((data) => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.scenarios$.next(data);
        this.scenario.valueChanges.pipe(filter((scenarioId) => !!scenarioId)).subscribe((scenarioId) => {
          if (scenarioId === "new") {
            this.selectedScenario = cloneDeep(newSimulationScenario);
          } else {
            this.selectedScenario = cloneDeep(this.scenarios$.value.find((item) => item.scenarioId === scenarioId));
          }
          this.chartScenario = new Scenario(
            this.selectedScenario.default,
            this.selectedScenario.title,
            this.selectedScenario.description,
            this.selectedScenario.regionId,
            this.selectedScenario.scenarioId,
            this.selectedScenario.values?.map((value) => {
              return { year: value.year, value: value.goal };
            })
          );
          this.activateSimulationButton();
        });

        if (data.length) {
          this.scenario.setValue(data[0].scenarioId);
        } else {
          this.scenario.setValue("");
          this.selectedScenario = null;
        }
      });
  }

  private listenAccounting(): void {
    this.activeAccountingService
      .getActiveAccounting()
      .pipe(takeUntil(this.destroy$))
      .subscribe((accounting) => (this.chartAccounting = accounting));
  }

  private mapRegionType(regionType: LegacyRegionType): RegionType {
    switch (regionType) {
      case "basic":
        return RegionType.BASIC;
      case "union":
        return RegionType.UNION;
      default:
        throw Error(`Unsupported region type ${regionType}`);
    }
  }

  private getSelectedYear(): Observable<number> {
    return this.utilService.selectedYear$;
  }

  private getSelectedAccountingMethod(): Observable<AccountingMethod> {
    return this.accountingMethodService.selectedAccountingMethod$.pipe(
      filter(inputIsNotNullOrUndefined),
      distinctUntilChanged(),
      map((accountingMethod: AccountingMethod): AccountingMethod => accountingMethod)
    );
  }

  private getSelectedElectricityMix(): Observable<ElectricityMix> {
    return this.accountingMethodService.selectedMixType$.pipe(
      filter(inputIsNotNullOrUndefined),
      distinctUntilChanged(),
      map((mix) => {
        switch (mix) {
          case LegacyMixType.LOCAL:
            return ElectricityMix.REGION_SPECIFIC;
          case LegacyMixType.FEDERAL:
            return ElectricityMix.NATIONAL;
          default:
            throw Error(`Unknown electricity mix ${mix}`);
        }
      })
    );
  }

  private getSelectedWeatherCorrection(): Observable<WeatherCorrection> {
    return this.accountingMethodService.weatherCorrection$.pipe(
      filter(inputIsNotNullOrUndefined),
      distinctUntilChanged(),
      map((weatherCorrection) => (weatherCorrection ? WeatherCorrection.ON : WeatherCorrection.OFF))
    );
  }

  private getSelectedUseUserFactors(): Observable<boolean> {
    return this.accountingMethodService.getAccountingMethods().pipe(
      filter(inputIsNotNullOrUndefined),
      distinctUntilChanged(),
      map(({ useUserFactors }) => useUserFactors),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
