import {
  Component,
  OnInit,
  OnChanges,
  HostBinding,
  Input,
  SimpleChanges,
  ElementRef,
  ViewChild,
  OnDestroy,
  EventEmitter,
  Output
} from "@angular/core";
import { ResizeObserver } from "resize-observer";
import { ResizeObserverCallback } from "resize-observer/lib/ResizeObserverCallback";
import * as Highcharts from "highcharts";
import { debounce } from "lodash";
import { EneChartService } from "../services/chart.service";
import { chartSettingsPresets } from "../configs/chart.presets";
import { chartStylesPresets } from "../configs/chart.styles";
import ExportData from "highcharts/modules/export-data";
import ExportingLocal from "highcharts/modules/offline-exporting";
ExportData(Highcharts);
ExportingLocal(Highcharts);

@Component({
  selector: "ene-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-chart"
  }
})
export class EneChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("eneChart", { static: true })
  public eneChartRef: ElementRef;

  @HostBinding("class.defaultcolors")
  @Input()
  public defaultColors: boolean = true;

  @Input() public data: any;
  @Input() public settings: any;
  // set default/fallback type here
  @Input() public type: string = "";
  // set default/fallback style here
  @Input() public styling: string = "small";

  @Output() public load = new EventEmitter<Highcharts.Chart>();

  // obj for chart itself
  public chart: any;
  // merged settings, used to create chart
  public finalSetting: any = {};

  private fallback: any = {
    chart: {
      //this backgroundColor needs to be set to transparent else the background of the chart in print review in firefox is black since the color is no loaded correctly
      backgroundColor: "transparent",
      reflow: false,
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      style: {
        fontFamily: "inherit",
        //this backgroundColor needs to be set else the background of the chart in print review in firefox is black
        backgroundColor: "white"
      }
    },
    loading: {
      style: {
        opacity: 1
      },
      showDuration: 200,
      hideDuration: 150
    },
    // do not show title
    title: {
      text: ""
    },
    //  no styling
    subtitle: {
      style: false
    },
    // hides the highcharts copyright credits
    credits: {
      enabled: false
    },
    // hides the toolip
    tooltip: {
      enabled: false
    },
    // disable the exporting button
    exporting: {
      chartOptions: {
        chart: {
          backgroundColor: "white",
          events: {
            //So the normal load event is not triggered
            load: () => {
              return;
            },
          },
        },
      },
      enabled: false,
      csv: {
        columnHeaderFormatter: (e) => {
          if (e.name) {
            return e.name + e.chart.options?.chart?.unit;
          }
          return "";
        },
        decimalPoint: ".",
        lineDelimiter: "\r\n"
      }
    }
  };
  private _elementObserver: ResizeObserver;
  private initTimeoutId: number;
  private showNoDataTimeoutId: number;

  constructor(private chartService: EneChartService) {}

  public ngOnInit(): void {
    this._elementObserver = new ResizeObserver(this.resizeFn());
    this._elementObserver.observe(this.eneChartRef.nativeElement);
    this.initTimeoutId = window.setTimeout(() => {
      this.chart.reflow();
    }, 1);
    /**
     * Chaning setOptions (used mainly for language translations) does not mutate the chart in runtime.
     * Therefore we have to reinitialize it here
     */
    this.chartService.optionsChange$.subscribe(() => this.initializeChart());
  }

  public ngOnDestroy(): void {
    if (this._elementObserver) {
      this._elementObserver.disconnect();
    }
    clearTimeout(this.initTimeoutId);
    clearTimeout(this.showNoDataTimeoutId);
  }

  public showLoading(): void {
    this.chart.showLoading();
  }

  public hideLoading(): void {
    this.chart.hideLoading();
  }

  public showNoData(): void {
    // async, cuz chart init in async func
    this.showNoDataTimeoutId = window.setTimeout(() => {
      this.chart.showNoData();
    }, 0);
  }

  public hideNoData(): void {
    this.chart.hideNoData();
  }

  // collection of functions to execute when we init the chart
  private initializeChart(): void {
    this.mergeSettings();
    this.chart = Highcharts.chart(this.eneChartRef.nativeElement, this.finalSetting);
    this.onChartLoaded();
    this.chart.hideLoading();

    if (this.data?.length && this.data[0].data.length) {
      this.chart.hideNoData();
    }
    this.dispatchChartLoadedEvent();
  }

  public redrawChart(): void {
    this.chart = Highcharts.chart(this.eneChartRef.nativeElement, this.finalSetting);
    this.chart.reflow();
    this.dispatchChartLoadedEvent();
  }

  private dispatchChartLoadedEvent() {
    this.load.emit(this.chart);
  }

  private getSettingsByType(type: string): any {
    if (chartSettingsPresets.hasOwnProperty(type)) {
      return chartSettingsPresets[type];
    } else {
      return {};
    }
  }

  private getSettingsByStyle(type: string, style: string): any {
    if (chartStylesPresets.hasOwnProperty(type)) {
      if (chartStylesPresets[type].hasOwnProperty(style)) {
        return chartStylesPresets[type][style];
      } else {
        return {};
      }
    } else {
      return {};
    }
  }

  /**
   * from the frontend only comes the actual data, but sometimes we need to add some informations for the series like size, showInLegend etc.
   * This function extends the series with additional properties depending on their type.
   */
  private extendSeries(): void {
    switch (this.type) {
      case "synthesis":
        if (this.data.length === 2) {
          let innerCircle = {
            size: "60%",
            showInLegend: true,
            dataLabels: false,
            linkedTo: ":previous"
          };
          innerCircle = Object.assign(this.data[0], innerCircle);
          let outerCircle = {
            size: "100%",
            innerSize: "60%",
            showInLegend: true,
            dataLabels: false,
            linkedTo: ":previous"
          };
          outerCircle = Object.assign(this.data[1], outerCircle);
          this.data = [innerCircle, outerCircle];
        }
        break;
      case "sunburst":
        // quick fix for empty sector shown in sunburst diagram
        // TODO: fix data at the source!
        if (this.data.length && this.data[0].data.length === 1 && !this.data[0].data[0].value) {
          this.data[0].data = [];
        }
    }
  }

  // combines the initial fallback-config, the type-config, the style-config and the user-config
  private mergeSettings(): void {
    // merge initialConfig  with type config
    this.finalSetting = this.mergeDeep(this.fallback, this.getSettingsByType(this.type));
    // merging stylings
    this.finalSetting = this.mergeDeep(this.finalSetting, this.getSettingsByStyle(this.type, this.styling));
    // merge userconfig if available
    if (typeof this.settings === "object") {
      const tmp = this.finalSetting;
      this.finalSetting = this.mergeDeep(tmp, this.settings);
    }

    // add data series
    this.extendSeries();
    this.finalSetting.series = this.data;
  }

  /**
   * is defined in the mergeSettings function as a property of the settings object.
   * Handles special actions after initialization, for example to activate a certain series
   */
  private onChartLoaded(): void {
    switch (this.type) {
      case "gauge":
        this.OnlyShowThisSeries(1);
        break;
    }
  }

  /**
   * Used by gauge to intially active the meaningful series
   * @param index index of series in array
   */
  private OnlyShowThisSeries(index: number): void {
    if (this.chart && this.chart.series.length > 1) {
      for (let i = 0; this.chart.series.length > i; i++) {
        if (i !== index) {
          this.chart.series[i].hide();
        } else {
          this.chart.series[i].show();
        }
      }
    } else {
      console.error("chart has no data!");
    }
    this.redrawChart();
  }

  /**
   * ngOnChanges ensure that the notifications will be animated if the value change
   * @param changes delivered by angular
   */
  public ngOnChanges(changes: SimpleChanges): void {
    // first init
    if (changes.data !== undefined && changes.data.firstChange) {
      this.initializeChart();
    }

    if (changes.data !== undefined && !changes.data.firstChange) {
      this.chart.destroy();
      this.initializeChart();
    }

    if (changes.settings !== undefined && !changes.settings.firstChange) {
      this.chart.destroy();
      this.initializeChart();
    }
  }

  // Simple is object check.
  private isObject(item: any): boolean {
    return item && typeof item === "object" && !Array.isArray(item) && item !== null;
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param source
   */
  private mergeDeep(target: any, source: any): any {
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return target;
  }

  /**
   * Trick for decrease reflow method calling.
   *
   * Bug for chart.type "synthesis" with multiple calling: chart is disappears sometimes.
   * 50ms optimal time for debounce after testing
   *
   * also needed for fading in side panels and thus debounced for all kind of diagrams
   */
  private resizeFn(): ResizeObserverCallback {
    return debounce(() => this.chart.reflow(), 50);
  }
}
