import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "countOf"
})
export class CountOfPipe implements PipeTransform {
  public transform(arr: Array<any>, key: string): number {
    return arr.filter((item) => item[key]).length;
  }
}
