import { Person } from "./contacts.model";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromRoot from "@energy-city/components";
import { Observable } from "rxjs";
import {
  getCurrrentContactComponent,
  SetCurrentContactComponent,
  ContactType,
  AuthenticationService
} from "@energy-city/components";

@Injectable()
export class ContactsService {
  public selectedContactComponent$: Observable<ContactType>;
  public contactInfo: Person;

  constructor(private store: Store<fromRoot.IApplicationState>, private authenticationService: AuthenticationService) {
    this.selectedContactComponent$ = store.select(getCurrrentContactComponent);
    const { guided_tour_summary_contact_person: contactPerson = [] } = this.authenticationService.tokenParsed;

    if (contactPerson.length === 5) {
      const [
        name,
        title,
        phone,
        email,
        address
      ] = this.authenticationService.tokenParsed.guided_tour_summary_contact_person;
      this.contactInfo = { name, title, phone, email, address };
    }
  }

  public setCurrentComponent(component) {
    this.store.dispatch(new SetCurrentContactComponent(component));
  }
}
