import { IGpClassificationStep } from "@enersis/gp-components/gp-map2-viewer";
import { scale } from "chroma-js";
export class ColorRange {
  constructor(private colorSteps: Array<string>, private domain: Array<number> | [0, 1]) {}

  getColorMap(
    startValue: number,
    endValue: number,
    numberOfSteps: number,
    legendConfig?: {
      showEachLabel?: boolean,
      suffix?: string,
      startLabel?: string,
      endLabel?: string
    }
  ) {
    const chromaScale = scale(this.colorSteps).domain(this.domain).mode("hsl");
    const steps: Array<IGpClassificationStep> = [];
    for (let step = 0; step < numberOfSteps; step++) {
      const fraction = step / (numberOfSteps - 1);
      const value = startValue + (endValue - startValue) * fraction;

      const colorStep: IGpClassificationStep = {
        value,
        output: chromaScale(fraction).hex(),
      };

      if (legendConfig?.showEachLabel) {
        colorStep.legend2 = {
          showLabel: true,
          label: legendConfig.suffix ? `${value} ${legendConfig.suffix}`: `${value}`
        }
      }

      steps.push(colorStep)
    }

    if (legendConfig?.startLabel) {
      steps[0].legend2 = { showLabel: true, label: legendConfig.startLabel };
    }

    if (legendConfig?.endLabel) {
      const lastIndex = steps.length - 1;
      steps[lastIndex].legend2 = { showLabel: true, label: legendConfig.endLabel };
    }

    return steps;
  }
}
