import { Subject, Observable, BehaviorSubject } from "rxjs";
import { ISpiderSeries, IBenchmarkData, IMeasure } from "@energy-city/components";
import { averageDataSorted } from "./averageData";
import { TranslateService } from "@ngx-translate/core";

export class ChartQualitiveBenchmarking {
  public onSeriesChange$: Observable<any>;
  public config$: Observable<any>;
  public data$: Observable<Array<ISpiderSeries>>;
  private configSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private dataSubject: BehaviorSubject<Array<ISpiderSeries>> = new BehaviorSubject(null);
  private seriesSubject: Subject<any> = new Subject();
  public constructor(private translate: TranslateService) {
    this.config$ = this.configSubject.asObservable();
    this.data$ = this.dataSubject.asObservable();
    this.onSeriesChange$ = this.seriesSubject.asObservable();
  }
  private initConfig(categories: Array<string>, max: number) {
    const self = this;
    const padding = (max / 100) * 5;
    this.configSubject.next({
      chart: {
        height: 620,
        spacingBottom: 20,
        events: {
          load: function () {
            if (this.series.length) {
              self.seriesSubject.next(this.series);
            }
          }
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          events: {
            hide: function () {
              self.seriesSubject.next(this.chart.series);
            },
            show: function () {
              self.seriesSubject.next(this.chart.series);
            }
          }
        }
      },
      xAxis: {
        categories,
        tickmarkPlacement: "on",
        labels: {
          useHTML: true,
          distance: 10,
          align: "left"
        }
      },
      yAxis: {
        tickInterval: Math.floor(max / 3),
        max: max + padding
      },
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0
        },
        enabled: true,
        shared: true,
        pointFormatter: function () {
          return `<span>${this.series.name}: <b>${this.options.y - 1}</b><br/></span>`;
        }
      },
      legend: {
        verticalAlign: "bottom",
        useHTML: true,
        symbolRadius: 0,
        symbolPadding: 35,
        margin: 25,
        itemStyle: {
          color: "#5E5E5E",
          fontWeight: "100"
        }
      },
      exporting: {
        chartOptions: {
          chart: {
            height: 620,
            width: 1170
          }
        }
      }
    });
  }

  public initChart(customSet: IBenchmarkData) {
    // Only the 34 categories from averageDataSorted shall be shown in the chart
    // Prepare the data by filtering measures, that exist in these categories
    // Matching takes place by sector and title.
    // This still could lead to non-deterministic behavior, because there are no restrictions regarding the title
    const categories = averageDataSorted.map((item) => this.translate.instant(item.key));

    const sortedMeasures = [];
    averageDataSorted.forEach((entry) => {
      const measure = customSet.measures.find((m) => entry.sector === m.sector && entry.title === m.title);
      sortedMeasures.push(measure);
    });
    customSet.measures = sortedMeasures;

    const customSetChartData = this.convertToChartFormat(customSet.measures);
    const averageChartData = this.convertToChartFormat(averageDataSorted);
    const max = Math.max(...[...customSetChartData, ...averageChartData]);
    this.initConfig(categories, max);
    this.dataSubject.next([
      {
        name: this.translate.instant("APP.AVARAGE"),
        type: "area",
        color: "rgba(141, 231, 255, 0.75)",
        data: averageChartData,
        pointPlacement: "on",
        key: "APP.AVARAGE"
      },
      {
        name: this.translate.instant("APP.YOUR_SELECTION"),
        type: "area",
        color: "rgba(255, 224, 141, 0.75)",
        data: customSetChartData,
        pointPlacement: "on",
        key: "APP.YOUR_SELECTION"
      }
    ]);
  }

  public updateChart(): void {
    this.configSubject.next({
      ...this.configSubject.value,
      xAxis: {
        ...this.configSubject.value.xAxis,
        categories: averageDataSorted.map((item) => this.translate.instant(item.key))
      }
    });
    this.dataSubject.next(
      this.dataSubject.value.map((item) => ({
        ...item,
        name: this.translate.instant(item.key)
      }))
    );
  }

  private convertToChartFormat(measures: Array<Pick<IMeasure, "title" | "active" | "status">>): Array<number> {
    return measures.map((measure) => {
      if (measure) {
        return measure.active ? measure.status + 2 : 1;
      }
      // SPH asks to use 1 instead of null
      return 1;
    });
  }
}
