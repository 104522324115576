import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Observable } from "rxjs";
import {
  INestedCategory,
  RegionPropertyDataInputService,
  IDataInputSector,
  AuthorizationService,
  UtilService,
  NavigationCategory
} from "@energy-city/components";
import { NavigationService } from "./navigation.service";
import { map, switchMap, take } from "rxjs/operators";
import { MainTableCategories } from "libs/shared/src/lib/data-input/model/main-table-categories.enum";
import { DataInputService } from "../data-input.service";

const LOCK_ICONS = ["ec_building_edit_locked", "ec_building_edit_unlocked"];

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  providers: [NavigationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit {
  public lockIcons = LOCK_ICONS;
  public categories: Array<NavigationCategory>;
  public nestedCategories: Array<INestedCategory>;
  public initialCategory: MainTableCategories;
  public initialNestedCategory: string;
  public locked$: Observable<boolean>;
  public editting$: Observable<boolean>;
  private activeCategory: MainTableCategories;
  private activeNestedCategory: string;

  constructor(
    private navService: NavigationService,
    private dataInputService: DataInputService,
    private regionPropDataInputService: RegionPropertyDataInputService,
    private cdr: ChangeDetectorRef,
    private authService: AuthorizationService,
    private utilService: UtilService
  ) {}

  public ngOnInit(): void {
    this.initiateProps();
    this.dataInputService.loadData(this.initialCategory, this.initialNestedCategory);
  }

  public trackByFn(_: any, value: any): string {
    return value.id;
  }

  private initiateProps(): void {
    this.navService.getCategories().subscribe((categories) => {
      this.categories = categories;
      this.initialCategory = this.navService.getInitialCategory(this.categories);
      this.onChangeCategory(this.initialCategory);
    });

    this.locked$ = this.navService.selectLockedState();
    this.editting$ = this.utilService.regionIdentifier$.pipe(
      switchMap(({ regionId }) => this.authService.isAllowedToEdit$(regionId)),
      map((value: boolean) => {
        if (!value) {
          this.navService.lock(true);
        }

        return !value;
      })
    );
  }

  public onChangeCategory(id: MainTableCategories): void {
    this.activeCategory = id;

    this.regionPropDataInputService
      .getSectorConfig(id)
      .pipe(take(1))
      .subscribe((config: IDataInputSector) => {
        this.dataInputService.setCategoryConfig(config);
        this.nestedCategories = config.subsectors.map(
          (subsector): INestedCategory => ({
            id: subsector.name,
            name: `RESOURCES.${subsector.name.toUpperCase()}`
          })
        );
        this.initialNestedCategory = this.nestedCategories[0].id;
        this.activeNestedCategory = this.initialNestedCategory;
        this.dataInputService.loadData(id, this.initialNestedCategory);
        this.cdr.detectChanges();
      });
  }

  public onChangeNestedCategory(id: string): void {
    this.activeNestedCategory = id;
    this.dataInputService.loadData(this.activeCategory, id);
  }

  public onLock(value: boolean): void {
    this.navService.lock(value);
  }

  public onSave(): void {
    this.navService.save();
  }

  public onImport(): void {
    this.navService.import();
  }

  public onExport(): void {
    this.navService.export();
  }
}
