import { Component, ChangeDetectionStrategy, Inject, OnInit } from "@angular/core";
import { Header, IHeaderConfigs, DYNAMIC_HEADER_DATA } from "../../models";
import { EneModalService } from "@energy-city/ui/modal";
import { UtilService } from "../../../../services/util.service";
import { AuthenticationService } from "../../../../modules/authentication/services/exported/authentication.service";

@Component({
  selector: "app-default-header",
  templateUrl: "./default-header.component.html",
  styleUrls: ["./default-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultHeaderComponent extends Header implements OnInit {
  public hasCoatOfArms = false;
  public staticDashboardImage: string;
  public sectors = [
    {
      id: "transportation",
      name: "Verkehr",
      icon: "ec_verkehr"
    },
    {
      id: "stationary-energy",
      name: "Stationäre Energie",
      icon: "ec_station"
    },
    {
      id: "waste",
      name: "Abfall und Abwasser",
      icon: "ec_abfall"
    },
    {
      id: "afolu",
      name: "Industrie",
      icon: "ec_industrie"
    },
    {
      id: "ippu",
      name: "Landwirtschaft",
      icon: "ec_landwirtschaft"
    }
  ];

  constructor(
    private modal: EneModalService,
    @Inject(DYNAMIC_HEADER_DATA) private headerData: IHeaderConfigs,
    private authService: AuthenticationService,
    private utilService: UtilService
  ) {
    super(headerData);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.staticDashboardImage = this.authService.tokenParsed.static_dashboard || null;
    this.coatOfArmsBackgroundUrl$ = this.utilService.getCoatOfArmsBackgroundUrl();
  }

  public openStaticDashboard(): void {
    if (this.staticDashboardImage) {
      this.modal.open("dashboard-hansewerk");
    }
  }
}
