<header ene-header role="header">
  <ene-header-row>
    <a ene-header-left>
      <img
        id="coatOfArms"
        alt="Coat of Arms"
        *ngIf="hasCoatOfArms"
        [src]="coatOfArms"
        (error)="hasCoatOfArms = false"
      />
      <img [src]="logo" alt="Logo" (click)="openStaticDashboard()" />
    </a>
    <a ene-header-center>
      <div id="headerlogo" [style.background-image]="coatOfArmsBackgroundUrl$ | async" (click)="reloadPage()"></div>
    </a>
    <ul ene-header-right>
      <searchbox mode="max" *ngIf="!showMinSearchbox; else minSearchbox" id="maxBox"></searchbox>
      <ng-template #minSearchbox>
        <li>
          <searchbox mode="min"></searchbox>
        </li>
      </ng-template>
      <li
        *ngIf="launchpadLinks.length"
        [ene-tooltip]="'FRAMEWORK.TOOLTIP.LAUNCHPAD' | translate"
        [placement]="tooltip.placement"
        [show-delay]="tooltip.showDelay"
      >
        <app-launchpad-popover [links]="launchpadLinks"></app-launchpad-popover>
      </li>
      <li
        *ngIf="showHelp"
        [ene-tooltip]="helpTooltipText | translate"
        [placement]="tooltip.placement"
        [show-delay]="tooltip.showDelay"
      >
        <app-help-popover></app-help-popover>
      </li>
      <li
        [ene-tooltip]="'FRAMEWORK.TOOLTIP.PROFILE' | translate"
        [placement]="tooltip.placement"
        [show-delay]="tooltip.showDelay"
      >
        <app-default-user-popover [user]="user"></app-default-user-popover>
      </li>
    </ul>
  </ene-header-row>
  <ene-modal *ngIf="!!staticDashboardImage" class="dashboard-hansewerk" id="dashboard-hansewerk" sizing="large">
    <app-dashboard-hansewerk [fileName]="staticDashboardImage"></app-dashboard-hansewerk>
  </ene-modal>
</header>
