import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UserPopover } from "../../../../../../modules/header/components/user-popover/user-popover.class";
import { TranslateService } from "@ngx-translate/core";
import { EnvironmentType } from "../../../../../../core/enum/environment.enum";
import { ILanguage } from "../../user-popover.models";
import { UtilService } from "../../../../../../services/util.service";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../../../../../state/index";
import { environment } from "../../../../../../../environments/environment";
import { PopupService } from "../../../../../pop-up/services/popup/popup.service";

@Component({
  selector: "app-default-user-popover",
  templateUrl: "./default-user-popover.component.html",
  styleUrls: ["./default-user-popover.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultUserPopoverComponent extends UserPopover {
  constructor(
    translate: TranslateService,
    utilService: UtilService,
    store: Store<fromRoot.IApplicationState>,
    private popupService: PopupService
  ) {
    super(translate, utilService, store);
  }

  /**
   * Overwriting base method for allowing english language only on DEV & RC
   */
  protected getAllowedLanguages(list: Array<string>): Array<ILanguage> {
    return super.getAllowedLanguages(
      list
        ? list.filter(
            (languageCode) => !(languageCode === "en-us" && environment.environment === EnvironmentType.PRODUCTIVE)
          )
        : null
    );
  }

  /**
   * Opens the modal to change cookies
   */
  public openDataPrivacy() {
    this.popupService.openDataPrivacy();
  }
}
