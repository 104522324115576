import { AgricultureFactors, GlobalFactors, IContext } from "./factor.interface";
import { FactorEmissionsTables } from "../../../../core/enum/factor.enum";
import { ReportTableColumn } from "../../interfaces/report-table.interface";
import { FACTORS_SCALE } from "../../../../constants/factor.const";
import { TableCellTypes } from "../../../../core/enum/table-cell-types.enum";
import { isNumber, isObject, merge } from "lodash";
import { TranslateService } from "@ngx-translate/core";

type RawTableData = AgricultureFactors | GlobalFactors;

export class FactorTableModel {
  private readonly type: FactorEmissionsTables;
  private readonly columns: Array<ReportTableColumn>;
  private data: RawTableData;
  public context: IContext;

  constructor(
    private translate: TranslateService,
    type: FactorEmissionsTables,
    data: RawTableData,
    columns: Array<ReportTableColumn>
  ) {
    this.type = type;
    this.data = data;
    this.columns = columns;
    this.context = this.getContext();
  }

  private getContext(): IContext {
    return {
      data: this.getTableData(),
      columns: this.getColumns()
    };
  }

  private getTableData(): any {
    let result;
    const scales = FACTORS_SCALE[this.type];
    const columns = this.columns;
    const columnIds = columns.map((item) => item.id);

    const textColumnId = columns.find((column) => column.type === TableCellTypes.TEXT)?.id;
    const valueColumnIds = columns.filter((column) => column.type === TableCellTypes.VALUE).map((column) => column.id);

    const tableRow = columnIds.reduce(
      (acc, columnId) => ({
        ...acc,
        [columnId]: null
      }),
      {}
    );

    if (textColumnId) {
      const rawKeys = Object.keys(
        valueColumnIds.reduce((acc, columnId) => {
          return merge(acc, this.data[columnId] || this.data);
        }, {})
      );

      result = rawKeys.map((rawKey) => {
        const row = { ...tableRow, [textColumnId]: rawKey };
        valueColumnIds.forEach((columnId) => {
          const scale = scales[columnId];
          row[columnId] = isNumber(this.data[rawKey]) ? this.data[rawKey] * scale : this.data[columnId][rawKey] * scale;
        });
        return row;
      });
    } else {
      const row = { ...tableRow };
      valueColumnIds.forEach((columnId) => {
        const scale = scales[columnId];
        row[columnId] = isObject(this.data[this.type])
          ? this.data[this.type][columnId] * scale
          : this.data[columnId] * scale;
      });
      result = [row];
    }

    return result;
  }

  private getColumns(): Array<ReportTableColumn> {
    return this.columns.map((item) => ({
      ...item,
      name: this.translate.instant(item.name)
    }));
  }
}
