import { OnInit, AfterViewInit, Inject, HostListener, Directive } from "@angular/core";
import { IUserConfigs } from "../components/user-popover/user-popover.models";
import { DYNAMIC_HEADER_DATA, TOOLTIP_CONFIGS, ITooltip, IHeaderConfigs } from "./header";
import { Observable } from "rxjs";
import { assetUrl } from "../../../share/asset-url";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class Header implements OnInit, AfterViewInit {
  public tooltip: ITooltip;
  public coatOfArms: string;
  public logo: string;
  public user: IUserConfigs;
  public showHelp: boolean;
  public launchpadLinks: Array<object> = [];
  public showMinSearchbox: boolean = false;
  public helpTooltipText: string;
  public municipalityName: string;
  public coatOfArmsBackgroundUrl$: Observable<string>;

  constructor(@Inject(DYNAMIC_HEADER_DATA) protected data: IHeaderConfigs) {}

  @HostListener("window:resize", ["$event"])
  private onResize(event) {
    if (event.target.innerWidth <= 1150) {
      this.showMinSearchbox = true;
    } else {
      this.showMinSearchbox = false;
    }
  }

  protected config(): void {
    // implemented by some subclasses
  }

  public ngOnInit(): void {
    window.innerWidth <= 1090 ? (this.showMinSearchbox = true) : (this.showMinSearchbox = false);

    this.tooltip = { ...TOOLTIP_CONFIGS };
    this.logo = assetUrl(`/images/logos/${this.data.logo}`);
    this.municipalityName = this.data.municipality_name;
    this.showHelp = Boolean(this.data.help);
    this.helpTooltip = this.data.help;
    this.user = this.data.user;

    this.config();
  }

  public ngAfterViewInit(): void {
    this.launchpadLinks = this.data.launchpadLinks;
  }

  public reloadPage(): void {
    window.location.href = "/";
  }

  private set helpTooltip(value: any) {
    const helpKey = value && value.tour ? "HELP" : "HELP_WITHOUT_GUIDED_TOUR";
    this.helpTooltipText = `FRAMEWORK.TOOLTIP.${helpKey}`;
  }
}
