<ng-container [ngTemplateOutlet]="currentMode"></ng-container>

<ng-template #min>
  <div [popper]="test" [popperTrigger]="'click'" [popperPlacement]="'top'" [popperTimeout]="200">
    <ene-icon icon="ec_magnifier"></ene-icon>
  </div>
  <popper-content #test class="popover compact">
    <ul class="ene-list nav-list">
      <li>
        <ng-container *ngTemplateOutlet="max"></ng-container>
      </li>
    </ul>
  </popper-content>
</ng-template>

<ng-template #max>
  <ene-search [options]="searchOptions" [userLocation]="userLocation$ | async">
    <div class="searchbox-wrapper">
      <ene-icon icon="ec_magnifier" class="search-icon"></ene-icon>
      <input
        class="ene-surface-input-main"
        [id]="searchId"
        [placeholder]="'COCKPIT.SEARCH.PLACEHOLDER' | translate"
        [formControl]="search"
      />
      <ene-icon
        *ngIf="selectedLngLat.length"
        icon="ec_building_delete_address"
        class="delete-icon"
        (click)="clearSearchbox()"
      ></ene-icon>
    </div>
    <div [id]="searchSuggestId"></div>
  </ene-search>
</ng-template>
