import { Co2SavingForAllYearsAction, Co2SavingForAllYearsActionType } from "./co2-saving-actions";
import { ICo2SavingData } from "../common";
import { ILoadingBehavior } from "../../../../common";

export const reducer = (state: ICo2SavingForAllYearsState = {}, action: Co2SavingForAllYearsAction) => {
  switch (action.type) {
    case Co2SavingForAllYearsActionType.GET_CO2_SAVING_FOR_ALL_YEARS_DATA:
      return {
        loading: true,
        regionId: action.regionId,
        scenarioId: action.scenarioId
      };
    case Co2SavingForAllYearsActionType.GET_CO2_SAVING_FOR_ALL_YEARS_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false
      };
    case Co2SavingForAllYearsActionType.GET_CO2_SAVING_FOR_ALL_YEARS_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};

export interface ICo2SavingForAllYearsState extends ILoadingBehavior {
  data?: ICo2SavingForAllYearsData[];
  regionId?: string;
  scenarioId?: string;
}
export interface ICo2SavingForAllYearsData extends ICo2SavingData {
  year?: number;
}
