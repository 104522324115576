<div
  *ngFor="let block of _data"
  class="ene-kpi__block"
  [ngClass]="[block.type, block.surface, 'col-' + block.columns]"
  [class.disabled]="block.disabled"
  [attr.data-id]="block.id"
>
  <!-- header -->
  <header *ngIf="block.header">
    <ene-icon *ngIf="block.header.icon" [icon]="block.header.icon" [project]="_iconsProject"></ene-icon>
    <span
      *ngIf="block.header.title"
      [innerHTML]="block.header.translate ? (block.header.title | translate) : block.header.title"
    ></span>
  </header>
  <!-- items -->
  <section
    *ngFor="let item of block.items"
    [attr.data-id]="item.id"
    [class.disabled]="item.disabled"
    [ngClass]="{
      oneLine: item.oneLine,
      hasTitle: item.title,
      hasIcon: item.icon,
      hasLabel: item.label || (item.pipe && item.pipe.type === 'eneFormatUnit' && item.pipe.unit)
    }"
  >
    <ene-icon *ngIf="item.icon" [icon]="item.icon" [project]="_iconsProject"></ene-icon>
    <div>
      <span class="title" [innerHTML]="item.translate ? (item.title | translate) : item.title"></span>

      <span
        *ngIf="item.state === _kpiStates.PENDING && _eneKpisService.settings.i18n.PENDING === ''"
        class="loading ene-spinner value"
        [ngClass]="{ containsLabel: item.pipe && item.pipe.type === 'eneFormatUnit' && item.pipe.unit }"
      >
        <div></div>
        <div></div>
        <div></div>
      </span>

      <span class="value" *ngIf="item.state === _kpiStates.PENDING && _eneKpisService.settings.i18n.PENDING !== ''">
        [innerHTML]="_eneKpisService.settings.i18n.PENDING | translate">
        <label *ngIf="item.label" [innerHTML]="item.label"></label>
      </span>

      <span class="value" *ngIf="item.state === _kpiStates.NOTAVAILABLE">
        <span>{{
          (item.notAvailableText ? item.notAvailableText : _eneKpisService.settings.i18n.NOTAVAILABLE) | translate
        }}</span>
      </span>

      <span class="value" *ngIf="item.state === _kpiStates.CLEARED">
        <span>{{ _eneKpisService.settings.i18n.CLEARED | translate }}</span>
      </span>

      <span class="value" *ngIf="item.state === _kpiStates.UNKNOWN">
        <span>{{ _eneKpisService.settings.i18n.UNKNOWN | translate }}</span>
      </span>

      <ng-template [ngIf]="item.state === _kpiStates.LOADED">
        <!-- raw values, not formatted at all OR number but no pipe-->
        <span class="value" *ngIf="item.raw || (_isTypeof('number', item.value) && !item.pipe)">
          <span [innerHTML]="item.value"></span>
          <label *ngIf="item.label" [innerHTML]="item.label"></label>
        </span>

        <!-- strings -->
        <span class="value" *ngIf="_isTypeof('string', item.value)">
          <span [innerHTML]="item.translate ? (item.value | translate) : item.value"></span>
          <label *ngIf="item.label" [innerHTML]="item.label"></label>
        </span>

        <!-- pipe -->
        <span
          class="value"
          *ngIf="item.pipe && !item.raw"
          [ngClass]="{ containsLabel: item.pipe && item.pipe.type === 'eneFormatUnit' && item.pipe.unit }"
        >
          <span [innerHTML]="_pipedOutput(item)"></span>
          <label *ngIf="item.label" [innerHTML]="item.translate ? (item.label | translate) : item.label"></label>
        </span>
      </ng-template>
    </div>
  </section>
</div>
