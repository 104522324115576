import { Component, Input } from "@angular/core";
import { FactorTableType as FactorTableTypeEnum } from "./factor-table-type.enum";
import { FactorTableRawData } from "./factor-table.interface";

@Component({
  selector: "co2-factor-table",
  templateUrl: "./factor-table.component.html",
  styleUrls: ["./factor-table.component.scss"]
})
export class FactorTableComponent {
  @Input() public table?: FactorTableRawData;
  @Input() public year?: number;
  @Input() public tableNr?: number;

  public readonly FactorTableType = FactorTableTypeEnum;
}
