import { ActionReducerMap } from "@ngrx/store/src/models";
import { reducer } from "./timeline-reducer";
import { IApplicationState } from "../index";
import { createSelector } from "@ngrx/store";

export const timelineReducers: ActionReducerMap<any> = {
  timeline: reducer
};

export interface ITimelineState {
  /**
   * payload of the last dispatched action
   * @deprecated use the year property instead
   */
  payload?: ITimelinePayload;

  /**
   * type of the last dispatched action
   * @deprecated the action types shouldn't matter when subscribing to a state
   */
  type?: string;

  /**
   * year currently selected in the timeline
   */
  year?: number;
}

/**
 * @deprecated use state.year instead of state.payload.year
 */
export interface ITimelinePayload {
  year?: number;
}

export const getTimelineState = (state: IApplicationState): ITimelineState => state && state.app && state.app.timeline;

export const getTimelineYear = (state: ITimelineState): number => state && state.year;

export const timelineYear = createSelector(getTimelineState, (state: ITimelineState) => state && state.year);
