import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from "@angular/core";
import { BehaviorSubject, Observable, Subject, combineLatest } from "rxjs";
import { skip, switchMap, takeUntil } from "rxjs/operators";
import {
  DisplayChartConfig,
  EnergyType,
  IApiConfig,
  IFormatterResult,
  SectorType
} from "../../../../models/charts.models";
import { AccountingMethodService } from "../../../../services/accounting-method.service";
import { UtilService } from "../../../../services/util.service";

import { IEnvDto, PLATFORM_ENV_FILE } from "@co2-shared/common";
import { EneChartComponent } from "@energy-city/ui/chart";
import { TranslateService } from "@ngx-translate/core";
import { MainPolluterService } from "./main-polluter.service";

@Component({
  selector: "app-main-polluter-chart",
  templateUrl: "./main-polluter.component.html",
  styleUrls: ["./main-polluter.component.scss"]
})
export class MainPolluterChartComponent implements OnInit, OnDestroy {
  @ViewChild("chartRef", { static: true }) private chartRefElement: EneChartComponent;
  public configs = {};
  public radioOptions = { checkbox: true };
  public selectedButton = SectorType.TOTAL_BALANCE;
  public dqi$: Subject<number>;
  public data$: Observable<any>;
  public radioButtons: Array<string>;
  public legendFormatter: (event: any, chart: any) => IFormatterResult;
  public displayConfig: DisplayChartConfig = {
    biskoDisabled: true,
    switcher: true,
    showMethod: true
  };
  protected chartApi: IApiConfig;
  private weatherCorrection = false;
  private stationaryEnergy = EnergyType.FINAL;
  private destroy$ = new Subject();
  public selectedSectorType$ = new BehaviorSubject<SectorType>(SectorType.TOTAL_BALANCE);
  public hidePrimaryEnergy = false;

  constructor(
    private mainPolluterService: MainPolluterService,
    private accMethService: AccountingMethodService,
    private utilService: UtilService,
    private translate: TranslateService,
    @Optional() @Inject(PLATFORM_ENV_FILE) protected platformEnvFile: IEnvDto
  ) {}

  public ngOnInit(): void {
    this.legendFormatter = this.mainPolluterService.getLegendFormatter();

    this.initProperties();
    this.handleTimeLineChanges();
    this.handleWeatherCorrectionAndRegionChanges();

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.data$ = this.selectedSectorType$.pipe(switchMap((sector) => this.mainPolluterService.getSeries(sector)));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public onChangeMethod(stationaryEnergy: EnergyType): void {
    this.stationaryEnergy = stationaryEnergy;
    this.dispatchData();
  }

  public onChangeRadio(sector: SectorType): void {
    this.selectedButton = sector;
    this.selectedSectorType$.next(sector);
  }

  private dispatchData(): void {
    const year = this.utilService.getSelectedYear();
    const { regionId, regionType } = this.utilService.getRegionIdentifier();
    const props = {
      api: this.chartApi,
      stationaryEnergy: this.stationaryEnergy,
      weatherCorrection: this.weatherCorrection,
      regionId,
      regionType,
      year
    };

    this.mainPolluterService.resetDqi();
    this.mainPolluterService.getDQI(year, this.chartApi);
    this.mainPolluterService.dispatchData(props);
  }

  private initProperties(): void {
    this.radioButtons = this.mainPolluterService.getRadioButtons();
    this.configs = this.mainPolluterService.getOptions(this.chartApi.dataType);
    this.data$ = combineLatest([this.selectedSectorType$, this.accMethService.useUserFactors$]).pipe(
      switchMap(([sector]) => this.mainPolluterService.getSeries(sector))
    );
    this.dqi$ = this.mainPolluterService.getDqi();
  }

  private handleTimeLineChanges(): void {
    this.utilService.selectedYear$.pipe(skip(1), takeUntil(this.destroy$)).subscribe(() => {
      this.dispatchData();
    });
  }

  private handleWeatherCorrectionAndRegionChanges(): void {
    combineLatest([this.accMethService.weatherCorrection$, this.utilService.regionIdentifier$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([wheaterCorrectionValue]) => {
        this.weatherCorrection = wheaterCorrectionValue;
        this.dispatchData();
      });
  }
}
