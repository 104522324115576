import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { WeatherCorrectionIndicatorComponent } from "./weather-correction-indicator.component";

import "@enersis/gp-components/gp-icon";
import "@enersis/gp-components/gp-tooltip";

@NgModule({
  declarations: [WeatherCorrectionIndicatorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, TranslateModule],
  exports: [WeatherCorrectionIndicatorComponent]
})
export class WeatherCorrectionIndicatorModule {}
