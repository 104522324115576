import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, filter } from "rxjs/operators";
import { inputIsNotNullOrUndefined } from "../utils/util-rxjs";
import { Config, IConfigDto } from "./config.model";
import { IEnvDto, PLATFORM_ENV_FILE } from "./env.model";
import { API_ENDPOINT_SERVICE_TOKEN, IApiEndpointService } from "../utils/api-endpoint.interface";
import { IConfigDataService } from "./config-data.interface";

@Injectable({
  providedIn: "root"
})
export class ConfigDataService implements IConfigDataService {
  private config: BehaviorSubject<Config | undefined> = new BehaviorSubject<Config | undefined>(undefined);
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ENV_FILE) private platformEnvFile: IEnvDto,
    @Inject(API_ENDPOINT_SERVICE_TOKEN) private apiEndpointService: IApiEndpointService
  ) {
    this.setConfig();
  }

  getConfig(): Observable<Config> {
    return this.config.asObservable().pipe(filter(inputIsNotNullOrUndefined));
  }

  private setConfig(): void {
    this.queryConfig().subscribe((config) => {
      this.config.next(new Config(config, this.platformEnvFile));
    });
  }

  private queryConfig(): Observable<IConfigDto | undefined> {
    return this.http
      .get<IConfigDto>(`${this.apiEndpointService.getConfigApi()}/login/config`)
      .pipe(catchError(() => of({})));
  }
}
