<section ene-sidenav-start>
  <gp-card>
    <gp-tabs (activeTabChange)="activeTabChange($event)">
      <gp-tab
        *ngFor="let tab of tabs; let first = first"
        [active]="first"
        [label]="tab.name + '.TITLE' | translate"
      ></gp-tab>
    </gp-tabs>

    <app-quantitive-benchmarking
      *ngIf="activeTab.name === 'BENCHMARKING.QUANTITATIVE_BENCHMARKING'"
    ></app-quantitive-benchmarking>
    <app-qualitive-benchmarking
      *ngIf="activeTab.name === 'BENCHMARKING.QUALITATIVE_ACTIVITY_PROFILE'"
    ></app-qualitive-benchmarking>
  </gp-card>

  <gp-card data-cy="benchmarking-kpis" *ngIf="activeTab?.kpi">
    <ene-kpis-v2
      namespace="benchmark_kpi"
      [items]="activeTab.kpi"
      [weatherCorrection]="weatherCorrection$ | async"
    ></ene-kpis-v2>
  </gp-card>

  <gp-card data-cy="benchmarking-description" class="description-container" *ngIf="activeTab?.description">
    <article class="description-container__article">
      <p class="description-container__article__introduction" [innerHTML]="activeTab.description | translate"></p>
      <ng-container *ngIf="selectedAccountingMethod === AccountingMethod.BISKO">
        <p class="description-container__article__notice">{{ "COCKPIT.CO2.NOTICE" | translate | uppercase }}</p>
        <p class="description-container__article__notice-body">{{ "COCKPIT.CO2.NOTICE_BISKO" | translate }}</p>
      </ng-container>
    </article>
  </gp-card>
</section>
