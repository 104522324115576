import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";

import * as fromRoot from "../../../../state/index";
import { AppActionType, IAppState } from "../../../../state/index";

import { DataPrivacyComponent, IDataPrivacyData } from "../../components/data-privacy/data-privacy.component";
import { GlossaryComponent, IGlossaryData } from "../../components/glossary/glossary.component";

@Injectable({
  providedIn: "root"
})
export class PopupService {
  private glossaryData: IGlossaryData;

  private dataPrivacyData: IDataPrivacyData;

  constructor(public dialog: MatDialog, private store: Store<fromRoot.IApplicationState>) {
    // We have to store the client config here, because components in material/dialog are lazy loaded
    // -> Subscriptions to store don't receive the config as they would subscribe to late
    this.store.select(fromRoot.GetAppState).subscribe((res: IAppState) => {
      switch (res && res.type) {
        case AppActionType.GET_CLIENT_CONFIG_SUCCESS:
          if (res.payload.help) {
            this.glossaryData = res.payload.help.glossary;
          }
          break;
        default:
          break;
      }
    });
  }

  public openGlossary(): void {
    this.openGlossaryAtTab(0);
  }

  public openGlossaryAtTab(activeTab: number = 0): void {
    const glossaryData = { ...this.glossaryData, ...{ initialTab: activeTab } };

    this._openGlossary(glossaryData);
  }

  public openGlossaryAtAnchor(anchor: string = ""): void {
    const glossaryData = { ...this.glossaryData, ...{ initialAnchor: anchor } };

    this._openGlossary(glossaryData);
  }

  public openDataPrivacy() {
    this.dataPrivacyData = {
      title: "COOKIES_POLICY.TITLE",
      icon: "ec_info",
      sections: [
        {
          id: "terms",
          content: "COOKIES_POLICY.TABS.TERMS.TEXT",
          contentIsTenantSpecific: true,
          title: "COOKIES_POLICY.TABS.TERMS.TITLE"
        },
        {
          id: "dsgvo",
          content: "COOKIES_POLICY.TABS.DSGVO.TEXT",
          contentIsTenantSpecific: false,
          title: "COOKIES_POLICY.TABS.DSGVO.TITLE"
        },
        {
          id: "dataProtection",
          content: "COOKIES_POLICY.TABS.DATA_PROTECTION.TEXT",
          contentIsTenantSpecific: true,
          title: "COOKIES_POLICY.TABS.DATA_PROTECTION.TITLE"
        },
        {
          id: "cookies",
          content: "COOKIES_POLICY.TABS.COOKIES.TEXT",
          contentIsTenantSpecific: true,
          title: "POLICY_MODAL.TABS.COOKIES.TITLE"
        }
      ],
      initialTab: 0
    };
    this.dialog.open(DataPrivacyComponent, {
      data: this.dataPrivacyData,
      panelClass: "data-privacy-dialog",
      disableClose: true
    });
  }

  private _openGlossary(data: IGlossaryData): void {
    this.dialog.open(GlossaryComponent, {
      data: data,
      autoFocus: false,
      panelClass: "glossary-dialog"
    });
  }
}
