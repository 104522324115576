import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { IApplicationState, ChartFinalEnergyActionType, getQuantitiveChartData } from "../../../state/index";
import { Store } from "@ngrx/store";
import { filter, takeUntil, map } from "rxjs/operators";
import { Subject } from "rxjs";
import { QuantitiveBenchmarkingService } from "./quantitive-benchmarking.service";
import * as fromRoot from "../../../state/index";
import { ofType } from "@ngrx/effects";
import { EneChartComponent } from "@energy-city/ui/chart";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-quantitive-benchmarking",
  templateUrl: "quantitive-benchmarking.component.html",
  styleUrls: ["quantitive-benchmarking.component.scss"],
  providers: [QuantitiveBenchmarkingService]
})
export class QuantitiveBenchmarkingComponent implements OnInit, OnDestroy {
  public chartData: any;
  public chartConfig: any;
  @ViewChild("chart", { static: true }) public chart: EneChartComponent;
  private destroy$: Subject<void> = new Subject();
  private kpiAverage: number;
  private kpiTotal: number;
  constructor(
    private store: Store<IApplicationState>,
    private quantitiveBenchmarkingService: QuantitiveBenchmarkingService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.quantitiveBenchmarkingService.onSeriesChange$.pipe(takeUntil(this.destroy$)).subscribe((series) => {
      this.recalculateKPI(series);
    });

    this.store
      .select(fromRoot.GetFinalEnergyState)
      .pipe(ofType(ChartFinalEnergyActionType.GET_KPI_SUCCESS), takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.kpiAverage = res.payload.average;
        this.quantitiveBenchmarkingService.renewKPI({ kpiAverage: this.kpiAverage, kpiTotal: this.kpiTotal });
      });

    this.store.select(getQuantitiveChartData).pipe(
      filter((data) => !!data),
      map((data) => this.quantitiveBenchmarkingService.convertDataToChartFormat(data))
    ).subscribe(data => {
      this.chartData = data;
      this.chartConfig = this.quantitiveBenchmarkingService.getChartConfig();
      this.chartConfig.xAxis.categories = this.quantitiveBenchmarkingService.getCategories();
    });

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.chartData) {
        this.chartData = this.chartData.map(item => ({...item, name: this.translate.instant(item.translationKey)}));
        this.chartConfig.xAxis.categories = this.quantitiveBenchmarkingService.getCategories();
        this.chartConfig.yAxis.title.text = this.translate.instant("COCKPIT.CO2.TABS.BENCHMARKING.CO2E_PER_CAPITA");
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private recalculateKPI(series) {
    this.kpiTotal = this.quantitiveBenchmarkingService.getKpiTotal(series);
    this.quantitiveBenchmarkingService.renewKPI({ kpiAverage: this.kpiAverage, kpiTotal: this.kpiTotal });
  }
}
