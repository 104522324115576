<app-page-wrapper [activeDqi]="false">
  <div class="avoid-break-inside" *ngIf="isSectorEnabled(energyTypes.STATIONARY_ENERGY)">
    <ng-container *ngIf="stationaryEnergy">
      <h2 class="title">{{ "PRINT_VIEW.TITLE.PAGE_5.STATIONARY_ENERGY" | translate }}</h2>
      <div *ngFor="let table of stationaryEnergy; index as i" class="avoid-break-inside">
        <co2-factor-table [table]="table" [year]="year" [tableNr]="stationaryEnergyTableIndex[i]"></co2-factor-table>
      </div>
    </ng-container>
  </div>

  <div class="avoid-break-inside" *ngIf="isSectorEnabled(energyTypes.TRANSPORTATION)">
    <ng-container *ngIf="transportation">
      <h2 class="title">{{ "PRINT_VIEW.TITLE.PAGE_5.TRANSPORTATION" | translate }}</h2>
      <div *ngFor="let table of transportation; index as i" class="avoid-break-inside">
        <co2-factor-table [table]="table" [year]="year" [tableNr]="transportationTableIndex[i]"></co2-factor-table>
      </div>
    </ng-container>
  </div>

  <div class="avoid-break-inside" *ngIf="isSectorEnabled(energyTypes.AFOLU)">
    <ng-container *ngIf="landUseTable && animalHusbandryTable">
      <h2 class="title">{{ "PRINT_VIEW.TITLE.PAGE_5.AGRICULTURE" | translate }}</h2>
      <div class="table-subtitle">
        {{ "PRINT_VIEW.TABLE.FOOTER.TEXT_7" | translate: { year: year, item: landUseTableIndex } }}
      </div>
      <ng-container *ngTemplateOutlet="table; context: landUseTable.context"></ng-container>

      <div class="table-subtitle">
        {{ "PRINT_VIEW.TABLE.FOOTER.TEXT_8" | translate: { year: year, item: animalHusbandryTableIndex } }}
      </div>
      <ng-container *ngTemplateOutlet="table; context: animalHusbandryTable.context"></ng-container>
    </ng-container>
  </div>

  <div class="waste avoid-break-inside" *ngIf="isSectorEnabled(energyTypes.WASTE)">
    <h2 class="title">{{ "PRINT_VIEW.TITLE.PAGE_5.WASTE" | translate }}</h2>

    <ng-container *ngIf="waste">
      <div *ngFor="let table of waste; index as i" class="avoid-break-inside">
        <co2-factor-table [table]="table" [year]="year" [tableNr]="wasteTableIndex[i]"></co2-factor-table>
      </div>
    </ng-container>
  </div>

  <div class="avoid-break-inside global">
    <ng-container *ngIf="globalFactorsTable">
      <h2 class="title">{{ "PRINT_VIEW.TITLE.PAGE_5.GLOBAL" | translate }}</h2>
      <div class="table-subtitle">
        {{ "PRINT_VIEW.TABLE.FOOTER.TEXT_13" | translate: { year: year, item: globalFactorsTableIndex } }}
      </div>
      <ng-container *ngTemplateOutlet="table; context: globalFactorsTable.context"></ng-container>
    </ng-container>
  </div>
</app-page-wrapper>

<ng-template #table let-data="data" let-columns="columns">
  <table class="factors" cdk-table [dataSource]="data">
    <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.id">
      <th cdk-header-cell *cdkHeaderCellDef="let headerCell" [innerHTML]="column.name"></th>
      <td cdk-cell *cdkCellDef="let element" [ngClass]="column.id">
        <span *ngIf="column.type === tableCellTypes.TEXT">{{
          "RESOURCES." + element[column.id] | uppercase | translate
        }}</span>
        <span *ngIf="column.type === tableCellTypes.VALUE">
          <ng-container *ngIf="element[column.id]; else dash">
            {{ element[column.id] | number: numberFormat:locale }}
          </ng-container>
          <ng-container *ngIf="element.asterix">&thinsp;*</ng-container>
        </span>
      </td>
    </ng-container>
    <tr cdk-header-row *cdkHeaderRowDef="getDisplayedColumns(columns)"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: getDisplayedColumns(columns)"></tr>
  </table>
</ng-template>

<ng-template #dash> - </ng-template>
