import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EneSnackbarService } from "@energy-city/ui/snackbar";
import { ITableCell } from "@energy-city/components";
import { DataInputService } from "../../data-input.service";
import { TranslateService } from "@ngx-translate/core";

const MIN_VALUE_DIFF = 0.1;
const MAX_VALUE_DIFF = 10;

@Injectable()
export class TableCellService {
  constructor(
    private dataInputService: DataInputService,
    private snackbar: EneSnackbarService,
    private translate: TranslateService
  ) {}

  public getLockedState(): Observable<boolean> {
    return this.dataInputService.selectLockedState();
  }

  public validateValuePlausibility(initial: number, current: number): boolean {
    if (!initial || current === null) {
      return true;
    }

    const diff = current / initial;
    return diff > MIN_VALUE_DIFF && diff < MAX_VALUE_DIFF;
  }

  public validateQualityPlausibility(initial: number, current: number): void {
    if (!initial || current === null) {
      return;
    }

    const diff = current - initial;

    if (diff < 0) {
      this.snackbar.error(this.translate.instant("DATA_PANEL.SNACKBAR.QUALITY_PLAUSIBILITY_MESSAGE"));
    }
  }

  public setData(data: Partial<ITableCell>): void {
    this.dataInputService.updateTableById(data);
  }
}
