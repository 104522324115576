import { createSelector } from "@ngrx/store";
import { IApplicationState } from "../../..";
import { DatabaseChartState } from "./database-chart.reducer";
import { get } from "lodash";

const GetMeasuresState = (state: IApplicationState) => state.app.databaseChart;
export const getDatabaseChartData = createSelector(GetMeasuresState, (state: DatabaseChartState) => state.data);
export const getSpecificEmissionFactor = createSelector(
  GetMeasuresState,
  (state: DatabaseChartState) => state.specificEmissionFactor
);
