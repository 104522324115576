import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CdkTableModule } from "@angular/cdk/table";
import { TranslateModule } from "@ngx-translate/core";
import { EneAccordeonModule } from "@energy-city/ui/accordeon";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneKpisModule } from "@energy-city/ui/kpis";
import { PrintPagesModule } from "./pages/pages.module";
import { PipesModule } from "../../core/pipe/pipes.module";
import { SharedModule } from "../../shared/shared.module";
import { PrintService } from "./services/print.service";

import { PrintWrapperComponent, PrintHeaderComponent, ReportsPanelComponent } from "./components";
import { EneKpisV2Module } from "@energy-city/ui/kpis-v2";
import { NguCarouselModule } from "@ngu/carousel";
import { ReportPreviewComponent } from "./components/report-preview/report-preview.component";

@NgModule({
  declarations: [PrintWrapperComponent, PrintHeaderComponent, ReportsPanelComponent, ReportPreviewComponent],
  providers: [PrintService],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    TranslateModule,
    CdkTableModule,
    EneIconModule,
    EneKpisModule,
    EneAccordeonModule,
    SharedModule,
    PipesModule,
    PrintPagesModule,
    EneKpisV2Module,
    NguCarouselModule,
    TranslateModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [PrintPagesModule, PrintWrapperComponent, PrintHeaderComponent, ReportsPanelComponent]
})
export class ReportsPanelModule {}
