import { Injectable } from "@angular/core";
import { environment } from "@energy-city/environments";

@Injectable({
  providedIn: "root"
})
export class EneIconService {
  public loadedFonts: string[] = [];
  constructor() {}

  /**
   *
   * @param project takes a string of a project and return a boolean if adding successed (true) or false if already existing
   */
  public checkFont(project: string) {
    /*if ( this.loadedFonts.indexOf(project) === -1) {
        this.loadedFonts.push(project);
        return true;
    } else {
        return false;
    }*/
    if (this.loadedFonts.indexOf(project) === -1) {
      this.loadedFonts.push(project);

      const headID = document.getElementsByTagName("head")[0];
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";

      headID.appendChild(link);

      link.href = `${environment.relativeBaseUrl}assets/icons/${project}-icons/css/${project}-icons.css`;
    }
  }
}
