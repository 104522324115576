<div class="simulation-scenario ene-surface-box-flat" *ngIf="scenario" [formGroup]="scenarioInfoFormGroup">
  <div class="simulation-scenario__header">
    <div>
      <span *ngIf="!isEditing" class="simulation-scenario__title">
        {{ scenario.title | translate }}
      </span>
      <div *ngIf="isEditing">
        <mat-form-field>
          <input matInput formControlName="title" autocomplete="off" required />
        </mat-form-field>
      </div>
    </div>

    <div class="simulation-scenario__header-options">
      <div class="simulation-scenario__option" [class.disabled]="isEditDisabled" (click)="editInfo()">
        <ene-icon icon="ec_edit"></ene-icon>
      </div>
      <div class="simulation-scenario__option" [class.disabled]="isSaveDisabled" (click)="saveScenario()">
        <ene-icon icon="ec_save"></ene-icon>
      </div>
      <div class="simulation-scenario__option" [class.disabled]="isDeleteDisabled" (click)="deleteScenario()">
        <ene-icon icon="ec_trash"></ene-icon>
      </div>
    </div>
  </div>

  <div>
    <span *ngIf="!isEditing" class="simulation-scenario__description">
      {{ scenario.description | translate }}
    </span>
    <div *ngIf="isEditing">
      <mat-form-field>
        <input matInput formControlName="description" autocomplete="off" required />
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!isScenarioDefault" class="simulation-scenario__content" [class.disabled]="isSimulationActive">
    <table>
      <tr *ngFor="let item of scenario.values; let i = index; trackBy: trackByFn">
        <td class="name">
          {{ "SIMULATION.SCENARIO.GOAL" | translate: { goal: item.goal * 100, year: item.year } }}
        </td>
        <td class="simulation-scenario__edit">
          <div class="simulation-scenario__edit-icon-wrapper">
            <ene-icon icon="ec_edit" (click)="updateGoal(i, item)"> </ene-icon>
          </div>
          <div class="simulation-scenario__edit-icon-wrapper">
            <ene-icon icon="ec_building_delete_address" (click)="removeGoal(i)"> </ene-icon>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="simulation-scenario__add-goal">
    <button
      (click)="addGoal()"
      [disabled]="isAddGoalDisabled"
      mat-flat-button
      class="simulation-scenario__add-goal--button"
    >
      <ene-icon icon="ec_maptools_zoomin"></ene-icon>
      {{ "SIMULATION.SCENARIO.ADD_GOAL" | translate }}
    </button>
  </div>
</div>
