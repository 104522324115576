import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { CdkTableModule } from "@angular/cdk/table";
import { TranslateModule } from "@ngx-translate/core";
import { UnitsFilterModule } from "./units-filter/units-filter.module";
import { TableCellModule } from "./cell/cell.module";
import { DataInputTableComponent } from "./data-input-table.component";

@NgModule({
  declarations: [DataInputTableComponent],
  imports: [CommonModule, CdkTableModule, ReactiveFormsModule, TranslateModule, UnitsFilterModule, TableCellModule],
  exports: [DataInputTableComponent]
})
export class DataInputTableModule {}
