/* tslint:disable:member-access */

import { Action } from "@ngrx/store";

export enum AppActionType {
  UPDATE_TRANSLATIONS = "[App] UPDATE_TRANSLATIONS",
  UPDATE_TRANSLATIONS_LOADED = "[App] UPDATE_TRANSLATIONS_LOADED",
  UPDATE_TRANSLATIONS_SUCCESS = "[App] UPDATE_TRANSLATIONS_SUCCESS",
  GET_CLIENT_CONFIG = "[App] GET_CLIENT_CONFIG",
  GET_CLIENT_CONFIG_SUCCESS = "[App] GET_CLIENT_CONFIG_SUCCESS",
  GET_CLIENT_MAPCONFIG = "[App] GET_CLIENT_MAPCONFIG",
  GET_CLIENT_MAPCONFIG_SUCCESS = "[App] GET_CLIENT_MAPCONFIG_SUCCESS",
  ERROR = "[App] ERROR"
}

export class UpdateTranslations implements Action {
  type = AppActionType.UPDATE_TRANSLATIONS;
  constructor(public payload: any) {}
}
export class UpdateTranslationsLoaded implements Action {
  type = AppActionType.UPDATE_TRANSLATIONS_LOADED;
  constructor(public payload: any) {}
}
export class UpdateTranslationsSuccess implements Action {
  type = AppActionType.UPDATE_TRANSLATIONS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetClientConfig implements Action {
  type = AppActionType.GET_CLIENT_CONFIG;
  constructor(public payload: any) {}
}
export class GetClientConfigSuccess implements Action {
  type = AppActionType.GET_CLIENT_CONFIG_SUCCESS;
  constructor(public payload: any) {}
}
export class GetClientMapConfig implements Action {
  type = AppActionType.GET_CLIENT_MAPCONFIG;
  constructor(public payload: any) {}
}
export class GetClientMapConfigSuccess implements Action {
  type = AppActionType.GET_CLIENT_MAPCONFIG_SUCCESS;
  constructor(public payload: any) {}
}
export class AppError implements Action {
  type = AppActionType.ERROR;
  constructor(public payload: any) {}
}

export type AppActions =
  | UpdateTranslations
  | UpdateTranslationsLoaded
  | UpdateTranslationsSuccess
  | GetClientMapConfig
  | GetClientMapConfigSuccess
  | AppError;
