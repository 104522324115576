<div class="data-input">
  <div class="data-input__navigation">
    <app-navigation></app-navigation>
  </div>
  <div class="data-input__table-container">
    <ng-scrollbar #scrollBar class="data-input-scrollbar" position="invertX" track="all">
      <app-data-input-table></app-data-input-table>
    </ng-scrollbar>
  </div>
</div>
<div class="footer">
  <app-data-input-footer></app-data-input-footer>
</div>
<!-- modals -->
<app-import-notice></app-import-notice>
<ene-modal id="data-input_comment" title="{{ 'APP.LEAVE_COMMENT' | translate }}">
  <app-comment></app-comment>
</ene-modal>
