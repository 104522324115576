/* tslint:disable:max-line-length */
export const chartSettingsPresets: any = {
  gauge: {
    chart: {
      type: "solidgauge"
    },
    subtitle: {
      verticalAlign: "bottom",
      y: -22
    },
    legend: {
      symbolWidth: 0,
      symbolHeight: 0,
      symbolPadding: 0,
      symbolRadius: 0,
      itemHiddenStyle: {
        color: "inherit"
      },
      itemHoverStyle: {
        color: "inherit"
      },
      itemStyle: {
        fontSize: "inherit",
        color: "inherit",
        fontWeight: "inherit"
      },
      align: "center",
      /*
       * If the plot area sized is calculated automatically and the legend is not floating,
       * the legend margin is the space between the legend and the axis labels or plot area.
       */
      margin: 12
    },
    pane: {
      size: "100%",
      startAngle: 0,
      endAngle: 360,
      // Track for Move
      background: [
        {
          outerRadius: "100%",
          innerRadius: "80%",
          backgroundColor: "#EBEFF1",
          borderWidth: 0
        }
      ]
    },
    yAxis: {
      min: 0,
      lineWidth: 0,
      tickPositions: []
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false
        },
        label: {
          enabled: true
        },
        linecap: "round",
        stickyTracking: false,
        rounded: true,
        // does not go round a round if it exceeds max value
        wrap: false
      },
      series: {
        colorByPoint: false,
        dataLabels: {
          enabled: true,
          borderWidth: 0,
          color: "inherit",
          format: "<span class='value'>{y}</span><span class='unit'>GW/a</span>",
          verticalAlign: "middle",
          align: "center",
          y: 0,
          x: 0,
          className: "ene-centered-label ",
          shape: "square",
          useHTML: true,
          style: {
            fontSize: "inherit",
            color: "inherit",
            fontWeight: "inherit",
            textOutline: false
          }
        },
        events: {
          legendItemClick: function (): boolean {
            const ctx = this as any;
            const seriesIndex = ctx.index;
            const series = ctx.chart.series;

            ctx.chart.subtitle.text = "na?";

            for (let i = 0; i < series.length; i++) {
              if (series[i].index !== seriesIndex) {
                series[i].hide();
              } else {
                series[i].show();
              }
            }

            return false;
          }
        }
      }
    }
  },

  line: {
    chart: {
      type: "line"
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle"
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        pointStart: 2010
      }
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom"
            }
          }
        }
      ]
    }
  },

  pie: {
    chart: {
      type: "pie"
    }
  },

  area: {
    chart: {
      type: "area"
    },
    credits: {
      enabled: false
    }
  },

  mix: {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      height: "80%",
      spacingBottom: 10,
      spacingTop: 10,
      spacingLeft: 10,
      spacingRight: 10,
      type: "pie"
    },
    legend: {
      enabled: true,
      align: "left",
      verticalAlign: "middle",
      layout: "vertical",
      symbolRadius: 4,
      itemMarginBottom: 4,
      itemStyle: {
        fontWeight: "normal"
      },
      itemHoverStyle: {
        fontWeight: "bold"
      },
      navigation: {
        enabled: true
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        innerSize: "75%",
        dataLabels: {
          enabled: false
        }
      }
    },
    tooltip: {
      enabled: true,
      borderWidth: 0,
      borderRadius: 4,
      padding: 8,
      headerFormat: "",
      pointFormat: "<span class='tooltip-text'>{point.name}: <b>{point.y}</b></span>",
      useHTML: true,
      style: {
        fontSize: "inherit"
      }
    }
  },

  synthesis: {
    chart: {
      type: "pie"
    },
    subtitle: {
      useHTML: true,
      x: 0,
      y: -95,
      align: "left",
      verticalAlign: "bottom"
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ["50%", "50%"],
        allowPointSelect: false,
        showInLegend: true,
        point: {
          events: {
            legendItemClick: function (e: any) {
              e.preventDefault();
              return false;
            }
          }
        }
      }
    },
    tooltip: {
      backgroundColor: "rgba(255,255,255,0.94)",
      enabled: true,
      borderWidth: 0,
      borderRadius: 4,
      padding: 8,
      headerFormat: "",
      useHTML: true,
      style: {
        fontSize: "inherit"
      },
      pointFormat:
        "<span class='tooltip-text twovalues'><span class='title'>{point.name}</span><span class='kWh'>{point.kWh}</span><span class='percent'>{point.percentage:.1f}<label>%</label></span></span>"
    },
    legend: {
      enabled: true,
      align: "left",
      verticalAlign: "top",
      layout: "vertical",
      symbolRadius: 0,
      symbolWidth: 0,
      symbolHeight: 0,
      itemStyle: {
        fontWeight: "normal"
      },
      itemHoverStyle: {
        fontWeight: "bold"
      },
      navigation: {
        enabled: true
      },
      useHTML: true,
      x: -12,
      labelFormatter: function (): string | void {
        const ctx = this as any;
        const nameString: string = `<span class="customLegendItem"><span class="symbol" style="background-color: ${ctx.color}"></span>${ctx.name}</span>`;

        if (ctx.showTitle) {
          return `<div class="title">${ctx.showTitle}</div>${nameString}`;
        }
        if (ctx.showInLegend) {
          return nameString;
        }
      }
    },
    noData: {
      position: {
        y: -100
      }
    },
    loading: {
      labelStyle: {
        top: "40%"
      }
    }
  },

  column: {
    chart: {
      type: "column"
    },
    title: {
      align: "right",
      floating: true
    },
    legend: {
      enabled: true,
      symbolRadius: 0,
      itemStyle: {
        fontSize: "inherit"
      }
    },
    xAxis: {
      className: "axis-label-style",
      labels: {
        style: {
          fontSize: "inherit"
        }
      }
    },
    yAxis: {
      className: "axis-label-style"
    },
    tooltip: {
      enabled: true
    }
  },

  stackedColumn: {
    chart: {
      type: "column"
    },
    title: {
      align: "right",
      floating: true
    },
    legend: {
      enabled: true,
      symbolRadius: 0,
      itemStyle: {
        fontSize: "inherit"
      }
    },
    xAxis: {
      className: "axis-label-style",
      labels: {
        style: {
          fontSize: "inherit"
        }
      }
    },
    yAxis: {
      className: "axis-label-style"
    },
    plotOptions: {
      series: {
        stacking: "normal"
      }
    },
    tooltip: {
      enabled: true
    }
  },

  sunburst: {
    chart: {
      type: "pie"
    }
  },

  spider: {
    chart: {
      polar: true,
      type: "line"
    },

    xAxis: {
      lineWidth: 0
    },

    yAxis: {
      gridLineInterpolation: "polygon",
      labels: {
        enabled: false
      },
      endOnTick: false,
      lineWidth: 0
    }
  }
};
