import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { TranslateModule } from "@ngx-translate/core";
import { FormatUnitPipe, TableComponent } from "./table.component";

@NgModule({
  declarations: [TableComponent, FormatUnitPipe ],
  imports: [CommonModule, TranslateModule, FormsModule, MatTableModule],
  exports: [TableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TableModule {}
