import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AuthorizationService, UtilService } from "@energy-city/components";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FactorManagementService } from "../factor-management.service";
import { CategoriesConfig, FactorCategory, FactorSector, FactorSubCategories } from "../factor-management.table-config";
import { NotSavedDialogComponent } from "../not-saved-dialog/not-saved-dialog.component";

export type NotSavedAction = "undo" | "save" | "back";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit, AfterViewInit {
  @Output() public onCategoryChange = new EventEmitter<[FactorSector, FactorSubCategories]>();
  @Output() public onSaveChange = new EventEmitter<boolean>();
  @Output() public onResetChanges = new EventEmitter<boolean>();

  public initialCategory: FactorSector = "transportation";
  public initialSubCategory: FactorSubCategories = FactorSubCategories.Transportation_DieselFraction; // temporary id

  public categories = CategoriesConfig;
  public selectedCategory: FactorSector;
  public subCategories: FactorCategory["subCategories"];
  public lockIcons = ["ec_building_edit_locked", "ec_building_edit_unlocked"];
  public editable$: Observable<boolean>;
  public isLockButtonChecked$: Observable<{
    value: boolean;
  }>;

  constructor(
    private authService: AuthorizationService,
    private utilService: UtilService,
    private dialog: MatDialog,
    public readonly factorManagementService: FactorManagementService
  ) {}

  public ngOnInit(): void {
    this.editable$ = this.utilService.regionIdentifier$.pipe(
      switchMap(({ regionId }) => this.authService.isAllowedToEdit$(regionId)),
      map((value: boolean) => !value)
    );
    this.isLockButtonChecked$ = this.factorManagementService.isEditing$.pipe(
      map((e) => ({
        value: !e
      }))
    );
  }

  public ngAfterViewInit() {
    this.onChangeCategory(this.initialCategory);
  }

  public trackByFn(_: any, value: any): string {
    return value.id;
  }

  public onChangeCategory(id: FactorSector): void {
    if (this.hasChanges()) {
      this.openDialog();
      return;
    }
    this.selectedCategory = id;
    this.subCategories = this.categories.find(({ id }) => id === this.selectedCategory).subCategories;
    this.initialSubCategory = this.subCategories[0].id;
    this.onCategoryChange.emit([id, this.initialSubCategory]);
  }

  public onChangeNestedCategory(id: FactorSubCategories): void {
    if (this.hasChanges()) {
      this.openDialog();
      return;
    }
    this.onCategoryChange.emit([this.selectedCategory, id]);
  }

  public onLock(value: boolean): void {
    if (this.hasChanges()) {
      this.factorManagementService.isEditing$.next(true);
      this.openDialog();
      return;
    }
    this.factorManagementService.isEditing$.next(!value);
  }

  public onSave(): void {
    this.onSaveChange.emit(true);
  }

  private hasChanges() {
    return Boolean(this.factorManagementService.tableDataChanges$.getValue());
  }

  private openDialog() {
    const dialogRef: MatDialogRef<NotSavedDialogComponent> = this.dialog.open(NotSavedDialogComponent, {
      width: "500px"
    });
    dialogRef.afterClosed().subscribe((action: NotSavedAction) => {
      switch (action) {
        case "save":
          this.factorManagementService.isEditing$.next(true);
          this.onSaveChange.emit(true);
          break;
        case "undo":
          this.onResetChanges.emit(true);
          break;
        case "back":
          this.factorManagementService.isEditing$.next(true);
          break;
      }
    });
  }
}
