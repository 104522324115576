import { IRegionDetails } from "../services/region.service";

export interface DistrictState {
  countBuildings: number;
  region: IRegionDetails;
}

export interface IDistrictKpi {
  area_m2: number;
  population: number;
  buildings_count: number;
}

export class DistrictKpi implements IDistrictKpi {
  public area_m2: number;
  public population: number;
  public buildings_count: number;

  constructor(data: DistrictState) {
    this.area_m2 = data.region.area_m2;
    this.population = data.region.population;
    this.buildings_count = data.countBuildings;
  }
}
