import { IPanelEvent, PanelEvent } from "@energy-city/ui/sidenav";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

// ngrx
import * as fromRoot from "../../../../../state/index";
import { TenantInfoService } from "../../../../../services/tenantInfo.service";
import { Store } from "@ngrx/store";
import { ChangePanelState } from "../../../../../state/sidenavigation/sidenavigation-actions";

@Component({
  selector: "app-info-climate-goals",
  templateUrl: "./info-climate-goals.component.html",
  styleUrls: ["./info-climate-goals.component.scss", "../info-sectors.subsectors.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoClimateGoalsComponent implements OnInit {
  public tenant: string;
  constructor(public store: Store<fromRoot.IApplicationState>, private tenantInfoService: TenantInfoService) {}

  public ngOnInit(): void {
    this.tenant = this.tenantInfoService.getTenant() === "hansewerk" ? "hansewerk" : "default";
  }

  public goToCockpit() {
    const interactionsToTake: IPanelEvent[] = [
      {
        name: "info",
        state: false,
        event: PanelEvent.VISIBLE
      },
      {
        name: "maptopics",
        state: false,
        event: PanelEvent.VISIBLE
      },
      {
        name: "co2_cockpit",
        state: true,
        event: PanelEvent.VISIBLE
      },
      {
        name: "co2_cockpit",
        state: true,
        event: PanelEvent.ENLARGED
      }
    ];

    interactionsToTake.forEach((element) => {
      this.store.dispatch(new ChangePanelState(element));
    });
  }

  public getTenantSpecificTranslation(path: string): string {
    return this.tenantInfoService.getTranslation(path);
  }
}
