import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  Injector,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from "@angular/core";
import { ITooltipOptions, IPlacement, ITrigger, ITheme, IAdComponent } from "./tooltip.interface";
import { TooltipEvents, TooltipTrigger, TooltipTheme, TooltipPlacement } from "./tooltip.enum";
import { EneTooltipComponent } from "./tooltip.component";
import { defaultOptions } from "./options";

@Directive({
  selector: "[ene-tooltip]"
})
export class EneTooltipDirective implements OnInit, OnDestroy {
  private componentRef: IAdComponent;
  private _destroyDelay: number;
  private _showDelay: any = 0;
  private _hideDelay: number = 300;
  private _id: any;
  private _options: ITooltipOptions = {};
  private componentSubscribe: any;
  private createTimeoutId: number;
  private showTimeoutId: number;
  private hideTimeoutId: number;
  private destroyTimeoutId: number;
  private hideAfterClickTimeoutId: number;

  /* tslint:disable:no-input-rename */
  @Input("ene-tooltip") public tooltipValue: string;
  /* tslint:enable */

  @Input() public set options(value: ITooltipOptions) {
    this._options = value;

    // change string inputs to Enums
    if (this._options.placement) {
      this.placement = this._options.placement;
    }
    if (this._options.trigger) {
      this.trigger = this._options.trigger;
    }
    if (this._options.theme) {
      this.theme = this._options.theme;
    }
  }

  @Input() public set placement(value: IPlacement) {
    if (typeof value === "string") {
      this._options.placement = TooltipPlacement[value.toUpperCase()];
    } else {
      this._options.placement = value;
    }
  }

  @Input() public set delay(value: number) {
    this._options.delay = value;
  }

  @Input("hide-delay-mobile") public set hideDelayMobile(value: number) {
    this._options["hide-delay-mobile"] = value;
  }

  @Input("z-index") public set zIndex(value: number) {
    this._options["z-index"] = value;
  }

  @Input("animation-duration") public set animationDuration(value: number) {
    this._options["animation-duration"] = value;
  }

  @Input() public set trigger(value: ITrigger) {
    if (typeof value === "string") {
      this._options.trigger = TooltipTrigger[value.toUpperCase()];
    } else {
      this._options.trigger = value;
    }
  }

  @Input("tooltip-class") public set tooltipClass(value: string) {
    this._options["tooltip-class"] = value;
  }

  @Input() public set display(value: boolean) {
    this._options.display = value;
  }

  @Input("display-mobile") public set displayMobile(value: boolean) {
    this._options["display-mobile"] = value;
  }

  @Input() public set shadow(value: boolean) {
    this._options.shadow = value;
  }

  @Input() public set theme(value: ITheme) {
    if (typeof value === "string") {
      this._options.theme = TooltipTheme[value.toUpperCase()];
    } else {
      this._options.theme = value;
    }
  }

  @Input() public set offset(value: number) {
    this._options.offset = value;
  }

  @Input("max-width") public set maxWidth(value: string) {
    this._options["max-width"] = value;
  }

  @Input() public set id(value: any) {
    this._id = value;
  }

  public get id() {
    return this._id;
  }

  @Input("show-delay") public set showDelay(value: number) {
    if (value) {
      this._showDelay = this._options["show-delay"] = value;
    }
  }

  public get showDelay() {
    if (this.isMobile) {
      return 0;
    } else {
      return this._options.delay || this._showDelay;
    }
  }

  @Input("hide-delay") public set hideDelay(value: number) {
    if (value) {
      this._hideDelay = this._options["hide-delay"] = value;
    }
  }

  public get hideDelay() {
    if (this.isMobile) {
      const mobileDelay = this._options["hide-delay-mobile"] || 0;

      return this._hideDelay >= mobileDelay ? this._hideDelay : mobileDelay;
    }

    return this._hideDelay;
  }

  public get destroyDelay() {
    if (this._destroyDelay) {
      return this._destroyDelay;
    } else {
      return Number(this._hideDelay) + Number(this._options["animation-duration"]);
    }
  }

  public set destroyDelay(value: number) {
    this._destroyDelay = value;
  }

  private get isTooltipDestroyed() {
    return this.componentRef && this.componentRef.hostView.destroyed;
  }

  @Output() public events = new EventEmitter<any>();

  constructor(
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  @HostListener("focusin")
  @HostListener("mouseenter")
  public onMouseEnter() {
    if (!this.isDisplayOnHover) {
      return;
    }

    this.show();
  }

  @HostListener("focusout")
  @HostListener("mouseleave")
  public onMouseLeave() {
    if (this._options.trigger === TooltipTrigger.HOVER) {
      this.destroyTooltip();
    }
  }

  @HostListener("click")
  public onClick() {
    if (!this.isDisplayOnClick) {
      return;
    }

    clearTimeout(this.hideAfterClickTimeoutId);
    this.show();

    this.hideAfterClickTimeoutId = window.setTimeout(() => {
      this.destroyTooltip();
    }, 0);
  }

  public ngOnInit(): void {
    this.applyOptionsDefault(this._options);
  }

  public ngOnDestroy(): void {
    this.destroyTooltip({ fast: true });

    if (this.componentSubscribe) {
      this.componentSubscribe.unsubscribe();
    }
  }

  private createTooltip(): void {
    this.clearTimeouts();

    this.createTimeoutId = window.setTimeout(() => {
      this.createComponentInBody();
    }, this.showDelay);

    this.showTimeoutId = window.setTimeout(() => {
      this.showTooltipElement();
    }, this.showDelay);
  }

  private destroyTooltip(options = { fast: false }): void {
    this.clearTimeouts();

    if (this.isTooltipDestroyed) {
      return;
    }

    // hide first
    this.hideTimeoutId = window.setTimeout(
      () => {
        this.hideTooltip();
      },
      options.fast ? 0 : this.hideDelay
    );

    // then destroy
    this.destroyTimeoutId = window.setTimeout(
      () => {
        if (!this.componentRef || this.isTooltipDestroyed) {
          return;
        }

        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.events.emit(TooltipEvents[3]);
      },
      options.fast ? 0 : this.destroyDelay
    );
  }

  private showTooltipElement(): void {
    this.clearTimeouts();
    this.events.emit(TooltipEvents[0]);

    this.componentRef.instance.show = true;
  }

  private hideTooltip(): void {
    if (!this.componentRef || this.isTooltipDestroyed) {
      return;
    }
    this.events.emit(TooltipEvents[2]);

    this.componentRef.instance.show = false;
  }

  private createComponentInBody(): void {
    const componentRef = <IAdComponent>(
      this.componentFactoryResolver.resolveComponentFactory(EneTooltipComponent).create(this.injector)
    );

    componentRef.instance.data = {
      value: this.tooltipValue,
      element: this.elementRef.nativeElement,
      elementPosition: this.elementRef.nativeElement.getBoundingClientRect(),
      options: this._options
    };

    this.appRef.attachView(componentRef.hostView);
    document.body.appendChild((<EmbeddedViewRef<any>>componentRef.hostView).rootNodes[0]);
    // component subcription for event and logic handling
    this.componentSubscribe = componentRef.instance.events.subscribe((event: TooltipEvents) => {
      if (event === TooltipEvents.SHOWN) {
        this.events.emit(TooltipEvents[event]);
      }
    });

    this.componentRef = componentRef;
  }

  private get isDisplayOnHover(): boolean {
    if (
      !this._options.display ||
      (!this._options["display-mobile"] && this.isMobile) ||
      this._options.trigger !== TooltipTrigger.HOVER
    ) {
      return false;
    }
    return true;
  }

  private get isDisplayOnClick(): boolean {
    if (
      !this._options.display ||
      (!this._options["display-mobile"] && this.isMobile) ||
      this._options.trigger !== TooltipTrigger.CLICK
    ) {
      return false;
    }
    return true;
  }

  private get isMobile(): boolean {
    // https://patrickhlauke.github.io/touch/touchscreen-detection/
    return navigator.maxTouchPoints > 0;
  }

  private applyOptionsDefault(options: ITooltipOptions): void {
    this._options = Object.assign({}, defaultOptions, options);
  }

  public show(): void {
    if (!this.componentRef || this.isTooltipDestroyed) {
      this.createTooltip();
    } else if (!this.isTooltipDestroyed) {
      this.showTooltipElement();
    }
  }

  private clearTimeouts(): void {
    if (this.createTimeoutId) {
      clearTimeout(this.createTimeoutId);
    }

    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
    }

    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
    }

    if (this.destroyTimeoutId) {
      clearTimeout(this.destroyTimeoutId);
    }
  }
}
