import { style, animate, animation } from "@angular/animations";
import { AccountingMethod } from "../../configs/accounting-method";

export enum LOGOS {
  Breklum = "../../../../../../../../assets/images/logos/breklum.jpg",
  Gettorf = "../../../../../../../../assets/images/logos/gettorf.png"
}

export enum PAGE_ORDER {
  ChartGreenhouseGasesTrendComponent = 0,
  ChartCo2SectorsComponent = 1,
  ChartEnergyRelatedGreenhouseGasesComponent = 2,
  ChartFinalEnergyPerEnergyCarrierComponent = 3,
  ChartFinalEnergyTrafficComponent = 4
}

export function getChartDescription(componentName: string): string {
  switch (componentName) {
    case "ChartGreenhouseGasesTrendComponent":
      return "PRINT_VIEW.CHART_DESCRIPTION.TREND";
    case "ChartCo2SectorsComponent":
      return "PRINT_VIEW.CHART_DESCRIPTION.CO2_SECTORS";
    case "ChartEnergyRelatedGreenhouseGasesComponent":
      return "PRINT_VIEW.CHART_DESCRIPTION.ENERGY_RELATED";
    case "ChartFinalEnergyPerEnergyCarrierComponent":
      return "PRINT_VIEW.CHART_DESCRIPTION.FINAL_ENERGY_PER_ENERGY_CARRIER";
    case "ChartFinalEnergyTrafficComponent":
      return "PRINT_VIEW.CHART_DESCRIPTION.FINAL_ENERGY_TRAFFIC";
  }
}

export const ENTER_ANIMATION = animation([style({ opacity: 0 }), animate("0.5s ease-in", style({ opacity: 1 }))]);

export const LEAVE_ANIMATION = animation([animate("0.5s ease-out", style({ opacity: 0 }))]);

export interface IButton {
  value: number;
  disabled: boolean;
}

export const REPORT_WARNING_MODAL_OPTIONS = {
  title: "Hinweis",
  content: "INFO.HINT",
  sizing: "small"
};
