/* tslint:disable:member-access */

import { Action } from "@ngrx/store";

export enum ChartCo2EmissionSectorsActionType {
  GET_DATA = "[ChartCo2EmissionSectors] GET_DATA",
  GET_DATA_SUCCESS = "[ChartCo2EmissionSectors] GET_DATA_SUCCESS",
  GET_KPI = "[ChartCo2EmissionSectors] GET_KPI",
  GET_KPI_SUCCESS = "[ChartCo2EmissionSectors] GET_KPI_SUCCESS",
  ERROR = "[ChartCo2EmissionSectors] ERROR"
}

export class GetChartCo2EmissionSectors implements Action {
  readonly type = ChartCo2EmissionSectorsActionType.GET_DATA;
  constructor(public payload: any) {}
}
export class GetChartCo2EmissionSectorsSuccess implements Action {
  readonly type = ChartCo2EmissionSectorsActionType.GET_DATA_SUCCESS;
  constructor(public payload: any[]) {}
}
export class GetChartCo2EmissionSectorsKpi implements Action {
  readonly type = ChartCo2EmissionSectorsActionType.GET_KPI;
  constructor(public payload: any) {}
}
export class GetChartCo2EmissionSectorsKpiSuccess implements Action {
  readonly type = ChartCo2EmissionSectorsActionType.GET_KPI_SUCCESS;
  constructor(public payload: any) {}
}
export class ChartCo2EmissionSectorsError implements Action {
  readonly type = ChartCo2EmissionSectorsActionType.ERROR;
  constructor(public payload: any) {}
}

export type ChartCo2EmissionSectorsActions =
  | GetChartCo2EmissionSectors
  | GetChartCo2EmissionSectorsSuccess
  | GetChartCo2EmissionSectorsKpi
  | GetChartCo2EmissionSectorsKpiSuccess
  | ChartCo2EmissionSectorsError;
