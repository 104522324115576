import { OnInit, AfterViewInit, OnDestroy, Input, ViewChild, Directive } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PopperContentComponent } from "@energy-city/ui/popper";
import { Store, select } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { ofType } from "@ngrx/effects";
import { Subject, Observable } from "rxjs";
import * as fromRoot from "../../../../state/index";
import { AppActionType, UpdateTranslationsSuccess } from "../../../../state/index";
import { UtilService } from "../../../../services/util.service";
import { environment } from "../../../../../environments/environment";
import { IUserConfigs, ILanguage, IUser, PUBLIC_USER, DEFAULT_LANGUAGES } from "./user-popover.models";
import { IPopper, POPPER_CONFIGS } from "../../models";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class UserPopover implements OnInit, AfterViewInit, OnDestroy {
  public showUserInfo: boolean;
  public isPublic: boolean;
  public userInfo: IUser | IUserConfigs;
  public languages: Array<ILanguage>;
  public activeLanguage: ILanguage;
  public popper: IPopper;
  private app$: Observable<any>;
  private destroy$ = new Subject();

  @ViewChild("languageSwitcher", { static: true }) private langSwitcherRef: PopperContentComponent;

  @Input() public showUserInfoOverride = true;

  @Input() public set user(value: IUserConfigs) {
    if (!value) {
      return;
    }

    this.userInfo = value;
    this.showUserInfo = Boolean(value.family_name || value.given_name || value.email);
    this.languages = this.getAllowedLanguages(value.allowedLanguages);

    this.switchLanguage(value.langCode);
  }

  constructor(
    private translate: TranslateService,
    private utilService: UtilService,
    private store: Store<fromRoot.IApplicationState>
  ) {}

  public ngOnInit(): void {
    this.popper = { ...POPPER_CONFIGS };
    this.isPublic = environment.loginConfig.publicLogin && sessionStorage.getItem("publicReferer") === null;
    this.app$ = this.appSelection;

    this.initLanguage();
  }

  public ngAfterViewInit(): void {
    this.app$.subscribe(({ language }: any) => {
      this.activeLanguage = DEFAULT_LANGUAGES.find((item: ILanguage) => item.code === language);
      this.store.dispatch(new UpdateTranslationsSuccess({ language }));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public logout(): void {
    const issuer = (this.utilService.tokenParsed as any)?.iss;

    sessionStorage.removeItem("publicReferer");
    location.assign(
      `${issuer}/protocol/openid-connect/logout` +
        // If new Keycloak is used (clientId "frontend"), use a different url for logout
        (environment.loginConfig.clientId === "frontend"
          ? `?client_id=frontend&post_logout_redirect_uri=${location.origin}&id_token_hint=${this.utilService.idToken}`
          : `?redirect_uri=${location.origin}`)
    );
  }

  public login(): void {
    sessionStorage.setItem("publicReferer", location.origin);
    location.href = "/login";
  }

  public trackByFn(index: number): number {
    return index;
  }

  /**
   * Sets the language, will be called once in ngInit and throught the dropdown in the frontend
   *
   * @param code: string
   */
  public switchLanguage(code: string): void {
    if (code) {
      this.utilService.setLocale(code);
    }
    // force closing of Tooltip
    this.langSwitcherRef.hide();
  }

  private initLanguage(): void {
    const defaultLangInd = 0;
    /**
     * This language will be used as a fallback when a translation isn"t found in the current language / keycloack
     *
     * Thats the default language here
     */
    this.activeLanguage = DEFAULT_LANGUAGES[defaultLangInd];

    this.translate.setDefaultLang(this.activeLanguage.code);
  }

  /**
   * Takes an array of allowed language-codes and replaces this.languages with only allowed ones
   *
   * @param allowedLanguages delivered from mapTopics per client
   */
  protected getAllowedLanguages(list: Array<string>): Array<ILanguage> {
    if (!list || !list.length) {
      return [];
    }

    return list.reduce((acc, item) => {
      const lang = DEFAULT_LANGUAGES.find(({ code }) => code === item);

      return [...acc, lang];
    }, []);
  }

  private get appSelection(): Observable<any> {
    return this.store.pipe(
      select(fromRoot.GetAppState),
      ofType(AppActionType.UPDATE_TRANSLATIONS_LOADED),
      map((response: any) => response.payload),
      takeUntil(this.destroy$)
    );
  }
}
