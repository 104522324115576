<div
  #dqiDirective="popper"
  class="wrapper"
  [popper]="dqiPopper"
  [popperTrigger]="'click'"
  [popperPlacement]="'bottom-start'"
  [popperCloseOnClickOutside]="true"
  [popperDisabled]="disabled"
>
  <div class="dqi__element" *ngFor="let item of [0, 1, 2, 3, 4]" [class.active]="dqi > item"></div>
</div>
<popper-content #dqiPopper class="popover dqi">
  <ng-content></ng-content>
  <ene-range-slider
    class="circles numbers"
    [config]="sliderConfig"
    [step]="1"
    [(ngModel)]="dqi"
    [tooltips]="true"
    [connect]="[true, false]"
    [min]="0"
    [max]="5"
    (change)="onChange($event)"
  >
  </ene-range-slider>
</popper-content>
