export enum AccountingMethod {
  /** GPC: "Global Protocol for Community-Scale Greenhouse Gas Emission Inventories" */
  GPC = "GPC",
  /** BISKO: "Bilanzierungs-Systematik Kommunal" */
  BISKO = "BISKO",
  /** BiCO₂SH: "Bilanzierung von CO₂e-Emissionen in Schleswig-Holstein" */
  BICO2SH = "BICO2SH",
  /** STET: "Accounting method exclusively considers the energy-related sectors "stationary energy" and "transport"" */
  STET = "STET",
  /** BICO2BW: "Accounting method used in Baden-Württemberg and considers the sectors stationary energy and transport" */
  BICO2BW = "BICO2BW"
}

export enum MixType {
  FEDERAL,
  LOCAL
}

export enum EnergyTypes {
  /** also used for selecting map layers and thus need to match the name of those */
  STATIONARY_ENERGY = "stationary-energy",
  /** AFOLU: "Agriculture, Forestry and Other Land Use" */
  AFOLU = "afolu",
  /** IPPU: "Industrial Processes and Product Use" */
  IPPU = "ippu",
  TRANSPORTATION = "transportation",
  WASTE = "waste"
}

export const SECTORS_NAMES = {
  [AccountingMethod.GPC]: [
    EnergyTypes.STATIONARY_ENERGY,
    EnergyTypes.AFOLU,
    EnergyTypes.IPPU,
    EnergyTypes.TRANSPORTATION,
    EnergyTypes.WASTE
  ],
  [AccountingMethod.BISKO]: [EnergyTypes.STATIONARY_ENERGY, EnergyTypes.TRANSPORTATION],
  [AccountingMethod.BICO2SH]: [EnergyTypes.STATIONARY_ENERGY, EnergyTypes.TRANSPORTATION, EnergyTypes.AFOLU],
  [AccountingMethod.STET]: [EnergyTypes.STATIONARY_ENERGY, EnergyTypes.TRANSPORTATION],
  [AccountingMethod.BICO2BW]: [EnergyTypes.STATIONARY_ENERGY, EnergyTypes.TRANSPORTATION]
};

// Added own object for the benchmarking panel, so the order of items is correct.
// I didn't want to provoke any breaking things in other components using this object
export const SECTORS_NAMES_BENCHMARKING = {
  [AccountingMethod.GPC]: [
    EnergyTypes.WASTE,
    EnergyTypes.IPPU,
    EnergyTypes.TRANSPORTATION,
    EnergyTypes.STATIONARY_ENERGY,
    EnergyTypes.AFOLU
  ],
  [AccountingMethod.BISKO]: [EnergyTypes.TRANSPORTATION, EnergyTypes.STATIONARY_ENERGY],
  [AccountingMethod.BICO2SH]: [EnergyTypes.TRANSPORTATION, EnergyTypes.STATIONARY_ENERGY, EnergyTypes.AFOLU],
  [AccountingMethod.STET]: [EnergyTypes.TRANSPORTATION, EnergyTypes.STATIONARY_ENERGY],
  [AccountingMethod.BICO2BW]: [EnergyTypes.STATIONARY_ENERGY, EnergyTypes.TRANSPORTATION]
};
