<div
  [popper]="userNavigation"
  [popperTrigger]="popper.trigger"
  [popperPlacement]="popper.placement"
  [popperTimeout]="popper.timeout"
>
  <ene-icon icon="ec_header_profile"></ene-icon>
</div>
<popper-content #userNavigation class="userNavigation">
  <section class="ene-list header" *ngIf="showUserInfo">
    <span class="title__main">{{ userInfo.given_name }} {{ userInfo.family_name }}</span>
    <span class="title__sub">{{ userInfo.email }}</span>
  </section>
  <ul class="ene-list nav-list">
    <li class="clickable">
      <ene-icon icon="ec_header_language"></ene-icon>
      <div
        class="language-switcher"
        [popper]="languageSwitcher"
        [popperTrigger]="popper.trigger"
        [popperPlacement]="'bottom'"
        [popperTimeout]="popper.timeout"
      >
        {{ "HEADER.USER.LANGUAGE" | translate }}
        <br />
        <span class="dropdown">
          {{ activeLanguage.name }}
          <ene-icon icon="ec_controls_closed_dropdown"></ene-icon>
        </span>
      </div>
    </li>
    <li class="clickable">
      <a (click)="openDataPrivacy()">
        <ene-icon icon="ec_cookie"></ene-icon>
        {{ "HEADER.USER.POLICY" | translate }}
      </a>
    </li>
    <li class="clickable">
      <ng-container *ngIf="isPublic; else notPublic">
        <a (click)="login()">
          <ene-icon icon="ec_header_logout"></ene-icon>
          {{ "HEADER.USER.LOGIN" | translate }}
        </a>
      </ng-container>
      <ng-template #notPublic>
        <a (click)="logout()">
          <ene-icon icon="ec_header_logout"></ene-icon>
          {{ "HEADER.USER.LOGOUT" | translate }}
        </a>
      </ng-template>
    </li>
  </ul>
</popper-content>
<popper-content #languageSwitcher class="popover compact">
  <ul class="ene-list nav-list">
    <li
      *ngFor="let item of languages; trackBy: trackByFn"
      class="ene-checkbox"
      [class.checked]="item.code === activeLanguage.code"
      (click)="switchLanguage(item.code)"
    >
      {{ item.name }}
    </li>
  </ul>
</popper-content>
