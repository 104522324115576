/* tslint:disable:member-ordering */

import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { switchMap, map, catchError } from "rxjs/operators";
import { DataService } from "./../../services/data.service";
import { DistrictActionType, GetDistrictDataSuccess, GetDistrictBasicDataSuccess, DistrictError } from "./district-actions";
import { ErrorAction } from "./../common";

@Injectable()
export class DistrictEffects {
  /* @Effect() private getDistrictData$ = this.actions$.pipe(
        ofType(DistrictActionType.GET_DISTRICT_DATA),
        switchMap((action: any) => {
            return this.dataService.sendBuildingServiceRequest("/getDistrictData", action.payload, Http.POST).pipe(
                map(data => new GetDistrictDataSuccess(data)),
                catchError((error) => ErrorAction(action, error, Error))
            ); }
        )
    );

    @Effect() private getDistrictBasicData$ = this.actions$.pipe(
        ofType(DistrictActionType.GET_DISTRICT_BASIC_DATA),
        switchMap((action: any) => {
            return this.dataService.sendBuildingServiceRequest("/district/basicData", action.payload, Http.GET).pipe(
                map(data => new GetDistrictBasicDataSuccess(data)),
                catchError((error) => ErrorAction(action, error, Error))
            ); }
        )
    ); */

  constructor(private dataService: DataService, private actions$: Actions) {}
}
