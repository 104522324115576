import {
  AnimalHusbandryTableColumns,
  FactorEmissionsTables,
  GlobalFactorsTableColumns,
  LandUseTableColumns
} from "../core/enum/factor.enum";
import { TableCellTypes } from "../core/enum/table-cell-types.enum";

export const LAND_USE_COLUMNS = [
  {
    id: LandUseTableColumns.EMISSION_FACTOR,
    name: "PRINT_VIEW.EMISSIONS_FACTORS.LAND_USE.TABLE_COLUMNS.EMISSION_FACTOR",
    type: TableCellTypes.TEXT
  },
  {
    id: LandUseTableColumns.TYPE_OF_USE,
    name: "PRINT_VIEW.EMISSIONS_FACTORS.LAND_USE.TABLE_COLUMNS.TYPE_OF_USE",
    type: TableCellTypes.VALUE
  }
];

export const ANIMAL_HUSBANDRY_COLUMNS = [
  {
    id: AnimalHusbandryTableColumns.ANIMAL_SPECIES,
    name: "PRINT_VIEW.EMISSIONS_FACTORS.ANIMAL_HUSBANDRY.TABLE_COLUMNS.ANIMAL_SPECIES",
    type: TableCellTypes.TEXT
  },
  {
    id: AnimalHusbandryTableColumns.DIGESTION,
    name: "PRINT_VIEW.EMISSIONS_FACTORS.ANIMAL_HUSBANDRY.TABLE_COLUMNS.DIGESTION",
    type: TableCellTypes.VALUE
  },
  {
    id: AnimalHusbandryTableColumns.FARM_MANURE_CH4,
    name: "PRINT_VIEW.EMISSIONS_FACTORS.ANIMAL_HUSBANDRY.TABLE_COLUMNS.FARM_MANURE_CH4",
    type: TableCellTypes.VALUE
  },
  {
    id: AnimalHusbandryTableColumns.FARM_MANURE_N2O,
    name: "PRINT_VIEW.EMISSIONS_FACTORS.ANIMAL_HUSBANDRY.TABLE_COLUMNS.FARM_MANURE_N2O",
    type: TableCellTypes.VALUE
  }
];

export const GLOBAL_FACTORS_COLUMNS = [
  {
    id: GlobalFactorsTableColumns.CH4,
    name: "PRINT_VIEW.EMISSIONS_FACTORS.GLOBAL_FACTORS.TABLE_COLUMNS.CH4",
    type: TableCellTypes.VALUE
  },
  {
    id: GlobalFactorsTableColumns.N2O,
    name: "PRINT_VIEW.EMISSIONS_FACTORS.GLOBAL_FACTORS.TABLE_COLUMNS.N2O",
    type: TableCellTypes.VALUE
  }
];

export const FACTORS_SCALE = {
  [FactorEmissionsTables.ANIMAL_HUSBANDRY]: {
    [AnimalHusbandryTableColumns.DIGESTION]: 1000,
    [AnimalHusbandryTableColumns.FARM_MANURE_N2O]: 1000,
    [AnimalHusbandryTableColumns.FARM_MANURE_CH4]: 1000
  },
  [FactorEmissionsTables.LAND_USE]: {
    [LandUseTableColumns.TYPE_OF_USE]: 1000000
  },
  [FactorEmissionsTables.GLOBAL_FACTORS]: {
    [GlobalFactorsTableColumns.CH4]: 1,
    [GlobalFactorsTableColumns.N2O]: 1
  }
};
