import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { acceptJsonWithoutCache, createErrorMessage } from "../../../../common";
import {
  Co2SavingForAllYearsActionType,
  GetCo2SavingForAllYearsData,
  GetCo2SavingForAllYearsDataError,
  GetCo2SavingForAllYearsDataSuccess
} from "./co2-saving-actions";
import { ICo2SavingForAllYearsData } from "./co2-saving-reducer";

export interface ICo2SavingDataAllYearsResponse {
  result?: ICo2SavingForAllYearsData[];
}

@Injectable()
export class Co2SavingForAllYearsEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  /**
   * returns details about the CO2 saving of a given region.
   */
  @Effect() public getCo2SavingData$ = this.actions$.pipe(
    ofType(Co2SavingForAllYearsActionType.GET_CO2_SAVING_FOR_ALL_YEARS_DATA),
    switchMap((action: GetCo2SavingForAllYearsData) => {
      const uri = `${environment.emission.calculationApi}/publicsector/regions/${action.regionId}/co2-saving`;
      return this.http
        .get<ICo2SavingDataAllYearsResponse>(uri, {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: this.setHttpParams(action.scenarioId)
        })
        .pipe(
          map((data: ICo2SavingDataAllYearsResponse) => new GetCo2SavingForAllYearsDataSuccess(data.result)),
          catchError(
            (error): Observable<GetCo2SavingForAllYearsDataError> =>
              of(new GetCo2SavingForAllYearsDataError(createErrorMessage(error)))
          )
        );
    })
  );

  private setHttpParams = (scenarioId: string): HttpParams => {
    return new HttpParams().set("scenarioId", scenarioId);
  }
}
