import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectedRegionService } from "./selected-region.service";
import { TimelineService } from "./timeline.service";
import { SelectedRegionStandaloneService } from "./selected-region-standalone.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [SelectedRegionService, SelectedRegionStandaloneService, TimelineService]
})
export class StateModule {}
