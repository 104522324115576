import { createMetaReducer, ConnectivitySelector } from "redux-beacon";
import GoogleAnalytics from "@redux-beacon/google-analytics";
import logger from "@redux-beacon/logger";
import { MetaReducer } from "@ngrx/store";

// import { State } from "../reducers";
import eventsMap from "./events-map";

// This creates a meta reducer for Redux Beacon that is configured to
// send events to Google Analytics. It is also configured with a
// couple of optional extensions, one to log events to the console,
// and the other to preserve analytics that occur when the user loses
// their connection.
const analyticsMetaReducer: MetaReducer<any> = createMetaReducer(eventsMap, GoogleAnalytics(), { logger });

export default analyticsMetaReducer;
