import { Component, Inject, Optional } from "@angular/core";
import { IEnvDto, PLATFORM_ENV_FILE } from "@co2-shared/common";

@Component({
  selector: "app-co2balance-sidenavigation",
  templateUrl: "./co2balance-sidenavigation.component.html",
  styleUrls: ["./co2balance-sidenavigation.component.scss"]
})
export class Co2balanceSidenavigationComponent {
  public isBenchmarkingDisabled = false;
  constructor(@Optional() @Inject(PLATFORM_ENV_FILE) protected platformEnvFile: IEnvDto) {
    this.isBenchmarkingDisabled = Boolean(this.platformEnvFile?.isBenchmarkingDisabled);
  }
}
