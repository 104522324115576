import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
/**
 * allow whole html code
 */
@Pipe({ name: "trustHtml" })
export class TrustHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(htmlCode) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlCode);
  }
}
