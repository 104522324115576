import { ActionReducerMap } from "@ngrx/store";
import * as fromDataInput from "./data-input";
import * as fromFactorEmission from "./factor-emission";

export * from "./data-input";
export * from "./factor-emission";

export const featureKey = "dataPanel";

export interface DataPanelState {
  dataInput: fromDataInput.State;
  factorEmission: fromFactorEmission.FactorEmissionState;
}

export const reducers: ActionReducerMap<DataPanelState> = {
  dataInput: fromDataInput.reducer,
  factorEmission: fromFactorEmission.factorEmissionReducer
};
