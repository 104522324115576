<ng-container>
  <gp-sidebar
    [canOpenInParallel]="[
      ['simulation', 'measures'],
      ['cockpit', 'filters', 'maptopics']
    ]"
    class="gp-sidebar"
  >
    <gp-sidepanel
      name="cockpit"
      icon="gpi-cockpit"
      openedIcon="gpi-filled-cockpit"
      small
      isOpen
      [heading]="'COCKPIT.TITLE' | translate"
    >
      <co2-cockpit></co2-cockpit>
    </gp-sidepanel>

    <gp-sidepanel
      name="maptopics"
      icon="gpi-layerpanel"
      openedIcon="gpi-filled-layerpanel"
      [heading]="'PANELS.LAYER_PANEL.TITLE' | translate"
      [description]="'PANELS.LAYER_PANEL.DESCRIPTION' | translate"
    >
      <co2-layer-panel></co2-layer-panel>
    </gp-sidepanel>
    <gp-sidepanel
      name="diagrams"
      icon="gpi-diagrams-panel"
      openedIcon="gpi-filled-diagrams-panel"
      xlarge="true"
      [heading]="'DIAGRAM_PANEL.TITLE' | translate"
    >
      <diagrams-panel></diagrams-panel>
    </gp-sidepanel>
    <hr />
    <gp-sidepanel
      name="data"
      icon="gpi-data-panel"
      openedIcon="gpi-filled-data-panel"
      full="true"
      [heading]="'DATA_PANEL.TITLE' | translate"
      [fixed]="true"
    >
      <app-data-panel></app-data-panel>
    </gp-sidepanel>
    <hr />
    <gp-sidepanel
      name="measures"
      icon="gpi-measures"
      openedIcon="gpi-filled-measures"
      large="true"
      [heading]="'MEASURE.TITLE' | translate"
      [description]="'MEASURE.PANEL_DESCRIPTION' | translate"
    >
      <panel-measures></panel-measures>
    </gp-sidepanel>
    <gp-sidepanel
      name="simulation"
      icon="gpi-simulation-panel"
      openedIcon="gpi-filled-simulation-panel"
      large="true"
      [heading]="'SIMULATION.TITLE' | translate"
      [description]="'SIMULATION.PANEL_DESCRIPTION' | translate"
    >
      <app-simulation></app-simulation>
    </gp-sidepanel>
    <hr />
    <ng-container *ngIf="!isBenchmarkingDisabled">
      <gp-sidepanel
        name="benchmarking"
        icon="gpi-benchmarking"
        openedIcon="gpi-filled-benchmarking"
        xlarge="true"
        [heading]="'BENCHMARKING.TITLE' | translate"
        [description]="benchmarking.activeTab?.text | translate"
      >
        <panel-benchmarking #benchmarking></panel-benchmarking>
      </gp-sidepanel>
      <hr />
    </ng-container>
    <gp-sidepanel
      name="reports"
      icon="gpi-reports-panel"
      openedIcon="gpi-filled-reports-panel"
      full="true"
      [heading]="'REPORTS.PANEL_TITLE' | translate"
      #reportsPanel
    >
      <app-reports-panel *ngIf="reportsPanel.isOpen"></app-reports-panel>
    </gp-sidepanel>
  </gp-sidebar>
</ng-container>

<co2-timeline class="co2balance"></co2-timeline>
