import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneTabsModule } from "@energy-city/ui/tabs";
import { EneIconModule } from "@energy-city/ui/icon";
import { TranslateModule } from "@ngx-translate/core";
import { environment } from "@energy-city/environments";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import * as fromDataPanel from "./state";
import { DataInputModule } from "./components/data-input/data-input.module";
import { AccountingMethodsModule } from "./components/accounting-methods/accounting-methods.module";
import { DataPanelComponent } from "./data-panel.component";
import { FactorManagementModule } from "./components/factor-management/factor-management.module";

@NgModule({
  declarations: [DataPanelComponent],
  providers: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDataPanel.featureKey, fromDataPanel.reducers),
    EffectsModule.forFeature([fromDataPanel.DataInputEffects, fromDataPanel.FactorEmissionEffects]),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 5 }) : [],
    EneTabsModule,
    EneIconModule,
    TranslateModule,
    DataInputModule,
    AccountingMethodsModule,
    FactorManagementModule
  ],
  exports: [DataPanelComponent]
})
export class DataPanelModule {}
