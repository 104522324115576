<ng-container *ngIf="locked$ | async; else unlockedTemplate">
  <div class="locked-cell">
    <div class="value-wrapper">
      {{ value == null ? "-" : (value | localNumber: numberFormatOptions) }}
    </div>
    <app-dqi-view [value]="quality"></app-dqi-view>
  </div>
</ng-container>

<ng-template #unlockedTemplate>
  <div
    class="unlocked-cell"
    [class.plausibilityQ]="!isPlausible.value || !isPlausible.quality"
    #unlockedCell
    [popper]="plausibilityPopper"
    [popperTrigger]="'none'"
    [popperPlacement]="'auto-end'"
    [popperCloseOnClickOutside]="false"
  >
    <input
      eneNumberInput
      [readonly]="!isPlausible.quality"
      type="text"
      class="unlocked-cell__input"
      [ngModel]="value"
      (ngModelChange)="changeValue($event)"
    />
    <ene-dqi
      [disabled]="!isPlausible.value || !isPlausible.quality"
      [dqi]="quality"
      (change)="changeQuality($event)"
    ></ene-dqi>
  </div>
</ng-template>

<popper-content #plausibilityPopper class="popover">
  <ng-content></ng-content>
  <div class="plausibilityPopperContent">
    {{ popper.infoText$ | async }}
    <button class="ene-button-control" (click)="popperConfim()">{{ "TDI.YES" | translate }}</button>
    <button class="ene-button-control" (click)="popperRevert()">{{ "TDI.NO" | translate }}</button>
  </div>
</popper-content>
