<div class="sticky-header" *ngIf="stickyheader">
  <div class="navbuttons" *ngIf="navbuttons">
    <ene-icon
      icon="ec_controls_close_panel"
      class="close"
      (click)="setVisible(false)"
      [ene-tooltip]="'FRAMEWORK.TOOLTIP.CLOSE_PANEL' | translate"
      placement="bottom"
      show-delay="300"
    ></ene-icon>
    <ene-icon
      icon="ec_controls_collapse_panel"
      class="collapse"
      *ngIf="false"
      (click)="clickCollapse()"
      [ene-tooltip]="'FRAMEWORK.TOOLTIP.COLLAPSE_PANEL' | translate"
      placement="bottom"
      show-delay="300"
    ></ene-icon>
    {{ master }}
    <ene-icon
      icon="ec_controls_open_panel"
      class="enlarge"
      *ngIf="enlarge && !master && extendable"
      (click)="setEnlarge(true, false)"
      [ene-tooltip]="'FRAMEWORK.TOOLTIP.OPEN_PANEL' | translate"
      placement="bottom"
      show-delay="300"
    ></ene-icon>
  </div>
</div>
<div class="navbuttons" *ngIf="!stickyheader && navbuttons">
  <ene-icon
    icon="ec_controls_close_panel"
    class="close"
    (click)="setVisible(false)"
    [ene-tooltip]="'FRAMEWORK.TOOLTIP.CLOSE_PANEL' | translate"
    placement="bottom"
    show-delay="300"
  ></ene-icon>
  <ene-icon
    icon="ec_controls_collapse_panel"
    class="collapse"
    *ngIf="collapse !== null && !master && overlay === false"
    (click)="clickCollapse()"
    [ene-tooltip]="'FRAMEWORK.TOOLTIP.COLLAPSE_PANEL' | translate"
    placement="bottom"
    show-delay="300"
  ></ene-icon>
  <ene-icon
    icon="ec_controls_open_panel"
    class="enlarge"
    *ngIf="enlarge !== null && !master && extendable"
    (click)="setEnlarge(true, false)"
    [ene-tooltip]="'FRAMEWORK.TOOLTIP.OPEN_PANEL' | translate"
    placement="bottom"
    show-delay="300"
  ></ene-icon>
</div>
<div class="content-full">
  <ng-content select="ene-sidenav-content-full"></ng-content>
  <ng-content></ng-content>
  <!-- ng-content here to ensure backwards compatibility without content-fixed declaration -->
</div>
<div class="ene-sidepanel-container">
  <div class="content-fixed" [style.width]="calculatedContainerWidth">
    <ng-content select="ene-sidenav-content-fixed"></ng-content>
  </div>
  <div class="content-enlarged" *ngIf="enlarge !== null" [ngClass]="{ active: enlarge }">
    <ng-content select="ene-sidenav-content-enlarged"></ng-content>
  </div>
</div>
<div class="toggle-collapsed" (click)="setCollapse(false)" [style.paddingTop]="iconPaddingTop">
  <ene-icon [icon]="icon" class="collapse"></ene-icon>
</div>
<ng-content select="[align=horizontal]"></ng-content>
