/* tslint:disable:directive-selector */
import {
  Directive,
  HostBinding,
  Input,
  Host,
  OnInit,
  HostListener,
  ElementRef,
  AfterViewInit,
  Renderer2,
  OnDestroy
} from "@angular/core";
import { EneSidenavComponent } from "../components/sidenav/ui-sidenav.component";
import { EneSidepanelComponent } from "../components/sidepanel/ui-sidepanel.component";
import { EneSidenavService } from "../services/ui-sidenav.service";
import { PanelEvent } from "../models/ui-sidepanel.enum";
import { Subscription } from "rxjs";

@Directive({
  selector: "[ene-sidebar]",
  exportAs: "EneSidebarDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-sidebar"
  }
})
export class EneSidebarDirective {}

@Directive({
  selector: "[ene-sidenav-start]",
  exportAs: "EneSidenavStartDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-sidenav-start"
  }
})
export class EneSidenavStartDirective {}

@Directive({
  selector: "[ene-sidenav-center]",
  exportAs: "EneSidenavCenterDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-sidenav-center"
  }
})
export class EneSidenavCenterDirective {}

@Directive({
  selector: "[ene-sidenav-end]",
  exportAs: "EneSidenavEndDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-sidenav-end"
  }
})
export class EneSidenavEndDirective {}

@Directive({
  selector: "[ene-sidenav-content]",
  exportAs: "EneSidenavContentDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-sidenav-content"
  }
})
export class EneSidenavContentDirective {}

@Directive({
  selector: "[ene-sidenav-content-container]",
  exportAs: "EneSidenavContentContainerDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-sidenav-content-container"
  }
})
export class EneSidenavContentContainerDirective {}

/**
 * Transform a directive vertically depending on opend or closed horizontal tabs
 */
@Directive({
  selector: "[ene-sidenav-footer]",
  exportAs: "EneSidenavFooterDirective"
})
export class EneSidenavFooterDirective {
  @HostBinding("class.ene-sidenav-animation-up") public classUp: boolean;
  @HostBinding("class.ene-sidenav-animation-down") public classDown: boolean;
  public _bottomDistance: number = 0;
  constructor(public uiService: EneSidenavService, public element: ElementRef, public renderer: Renderer2) {
    this.uiService.onPanelVerticalHeightChange$.subscribe((bottomDistance) => {
      if (bottomDistance === this._bottomDistance) {
        return;
      }
      const isUp = bottomDistance > this._bottomDistance;
      this.classUp = isUp;
      this.classDown = !isUp;
      this._bottomDistance = bottomDistance;
      this.renderer.setStyle(element.nativeElement, "transform", `translateY(${-bottomDistance}px)`);
    });
  }
}

@Directive({
  selector: "[ene-sidebar-item]",
  exportAs: "EneSidebarItemDirective",
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-sidebar-item"
  }
})
export class EneSidebarItemDirective implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding("class.visible") public visible: boolean;
  @HostBinding("class.collapse") public collapse: boolean;
  @HostBinding("class.disabled") public disabled: boolean = false;
  @HostBinding("class.progressIndicator") public progressIndicator: boolean;
  @HostBinding("class.master") public master: boolean;
  @HostBinding("class.overlay") public overlay: boolean;
  @Input() public link: string;
  @Input() public interaction: boolean = true;

  public panel: any;
  public timeoutCalculateOffset: any;
  public subscriptions: Array<Subscription> = [];

  constructor(@Host() public parent: EneSidenavComponent, public el: ElementRef, public uiService: EneSidenavService) {}

  public ngOnInit() {
    this.panel = this.parent.getPanel(this.link);
    if (this.panel) {
      this.updateClasses(this.panel);
    }
    this.parent.getPanelUpdate$(this.link).subscribe((panel: EneSidepanelComponent) => {
      this.updateClasses(panel);
    });

    this.subscriptions.push(
      this.uiService.onPanelChange$.subscribe((panelChange) => {
        if (panelChange.event === PanelEvent.COLLAPSED) {
          this.updateCollapsed(panelChange.name, panelChange.state);
        }
      })
    );
  }

  /**
   * I am using AfterViewInit here to be able to retrieve offsetHeight (in ngOnInit only offsetTop is available but not offsetHeight)
   */
  public ngAfterViewInit() {
    this.calculateOffset();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Calculates the offset of the sidebar item relative to the beginning. it sends it to the panel so the panel can set the padding of the icon accordingly
   */
  public calculateOffset() {
    const offset = this.el.nativeElement.offsetTop;
    const height = this.el.nativeElement.offsetHeight;
    this.panel._setIconPosition(offset + height / 2);
  }

  /**
   * When calling calculateOffset() directly inside this functions, sometimes the icons are elements are not positioned correctly (calculateOffset() get outdated values).
   * By using a timeout with callback, debouncing is reduced and the calculation gets the correct values.
   */
  @HostListener("window:resize")
  public triggerCalculateOffset() {
    clearTimeout(this.timeoutCalculateOffset);
    this.timeoutCalculateOffset = setTimeout(() => {
      this.calculateOffset();
    }, 1);
  }

  /**
   * simple takes the properties from the newobj and does set the internal booleans accordingly
   * @param newobj usually EneSidepanelComponent
   */
  public updateClasses(newobj: any) {
    this.visible = newobj.visible;
    this.collapse = newobj.collapse;
    this.disabled = newobj.disabled;
    this.progressIndicator = newobj.progressIndicator;
    this.master = newobj.master;
    this.overlay = newobj.overlay;
  }

  public updateCollapsed(panelName: string, newState: boolean) {
    if (this.panel.name === panelName) {
      this.collapse = newState;
    }
  }

  @HostListener("click") public onItemClick() {
    if (this.interaction) {
      this.parent.sidebarClick(this.link);
    }
  }
}
