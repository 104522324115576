import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, zip } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { inputIsNotNullOrUndefined } from "../utils/util-rxjs";
import { Config, IConfigDto } from "./config.model";
import { API_ENDPOINT_SERVICE_TOKEN, IApiEndpointService } from "../utils/api-endpoint.interface";
import { IConfigDataService } from "./config-data.interface";
import { ITokenService, TOKEN_SERVICE_TOKEN } from "../utils/token.interface";
import { IEnvDto } from "./env.model";

@Injectable({
  providedIn: "root"
})
export class ConfigDataStandaloneService implements IConfigDataService {
  private config: BehaviorSubject<Config | undefined> = new BehaviorSubject<Config | undefined>(undefined);
  constructor(
    private http: HttpClient,
    @Inject(API_ENDPOINT_SERVICE_TOKEN) private apiEndpointService: IApiEndpointService,
    @Inject(TOKEN_SERVICE_TOKEN) private tokenService: ITokenService
  ) {
    this.setConfig();
  }

  getConfig(): Observable<Config> {
    return this.config.asObservable().pipe(filter(inputIsNotNullOrUndefined));
  }

  private setConfig(): void {
    zip(this.queryConfig(), this.getMapConfig()).subscribe(([config, env]) => {
      this.config.next(new Config(config, env));
    });
  }

  private queryConfig(): Observable<IConfigDto | undefined> {
    return this.http
      .get<IConfigDto>(`${this.apiEndpointService.getConfigApi()}/login/config`)
      .pipe(catchError(() => of({})));
  }

  private getMapConfig(): Observable<IEnvDto | undefined> {
    const tenant = this.tokenService.getParsedCo2BalanceToken().tenant;
    return this.http.get<any>(this.apiEndpointService.assetUrl(`map-config/${tenant}.json`)).pipe(
      map(
        (mapConfig): IEnvDto => {
          return { co2balance: { tenantMapConfig: mapConfig } };
        }
      ),
      catchError(() => of(undefined))
    );
  }
}
