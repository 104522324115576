import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CONFIG_DATA_SERVICE_TOKEN, IConfigDataService } from "../../../common/config/config-data.interface";

@Injectable({
  providedIn: "root"
})
export class CockpitConfigService {
  constructor(@Inject(CONFIG_DATA_SERVICE_TOKEN) private configDataService: IConfigDataService) {}

  isRankingEnabled(): Observable<boolean> {
    return this.configDataService.getConfig().pipe(
      map((config) => {
        return config.cockpitPanel.isRankingEnabled;
      })
    );
  }
}
