import { Component, OnInit } from "@angular/core";
import { Toast } from "ngx-toastr";
import { snackbarAnimation } from "../constants/animation";

@Component({
  selector: "[ene-snackbar]",
  templateUrl: "./snackbar.component.html",
  animations: [snackbarAnimation],
  preserveWhitespaces: false
})
export class EneSnackbarComponent extends Toast implements OnInit {
  public pendingAnimation: boolean;
  public message: string;

  public ngOnInit(): void {
    this.pendingAnimation = this.toastClasses.includes("snackbar-pending");
  }
}
