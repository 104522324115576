import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { PopperContentOptions } from "./models/popper.model";
import { PopperContentComponent } from "./components/popper-content.component";
import { PopperDirective } from "./directives/popper.directive";

@NgModule({
  declarations: [PopperContentComponent, PopperDirective],
  imports: [CommonModule],
  exports: [PopperContentComponent, PopperDirective],
  providers: [
    {
      provide: "popperDefaults",
      useValue: {}
    }
  ]
})
export class EnePopperModule {
  public static forRoot(popperBaseOptions: PopperContentOptions = {}): ModuleWithProviders<EnePopperModule> {
    return {
      ngModule: EnePopperModule,
      providers: [
        {
          provide: "popperDefaults",
          useValue: popperBaseOptions
        }
      ]
    };
  }
}
