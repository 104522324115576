import { Observable, Subject } from "rxjs";
import { ILogin, IAuthenticationObject } from "../global.interface";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { TokenFormatter } from "./tokenFormatter";
import { environment } from "../../../../../environments/environment";

export class Login {
  public login: Subject<any> = new Subject<any>();
  private keycloak: any;

  constructor(protected http: HttpClient, protected config: ILogin) {
    this._login().subscribe((obj) => {
      this._saveAuthenticationObject(obj);
      this._refreshToken(obj.refreshToken); // refreshing first time direct
      this.login.next(obj.accessTokenDecoded);
    });
  }

  protected _login(): Observable<IAuthenticationObject> {
    const response: Subject<IAuthenticationObject> = new Subject<IAuthenticationObject>();

    this.keycloak = Keycloak({
      url: this.config.serviceUrl,
      realm: this.config.realm,
      clientId: this.config.clientName
    });

    this.keycloak.init({ checkLoginIframe: false }).success(() => {
      const loginSuccess = () => {
        response.next({
          accessToken: this.keycloak.token,
          refreshToken: this.keycloak.refreshToken,
          idToken: this.keycloak.idToken,
          accessTokenDecoded: TokenFormatter.format(this.keycloak.tokenParsed)
        });
      };

      if (this.keycloak.authenticated) {
        loginSuccess();
      } else {
        if (this.config.idp_hint) {
          this.keycloak.login({ idpHint: this.config.idp_hint }).success(loginSuccess);
        } else if (this.config.noIdpHint) {
          this.keycloak.login().success(loginSuccess);
        } else {
          this.keycloak.login({ idpHint: "tenant" }).success(loginSuccess);
        }
      }
    });

    return response;
  }

  private _refreshToken(initRefreshToken: string): void {
    let refreshToken = initRefreshToken;

    const refresh = (): Observable<any> => {
      const path =
        (this.config.refreshUrl ? this.config.refreshUrl : this.config.serviceUrl) +
        "/realms/" +
        environment.loginConfig.realm +
        "/protocol/openid-connect/token";
      const body = "grant_type=refresh_token&client_id=" + this.config.clientName + "&refresh_token=" + refreshToken;
      let headers = new HttpHeaders();
      headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
      return this.http.post(path, body, { headers: headers });
    };

    const updateStorage = (data: any): void => {
      const authenticationObject: IAuthenticationObject = JSON.parse(localStorage.getItem("authenticationObject"));

      authenticationObject.accessToken = data.access_token;
      authenticationObject.refreshToken = data.refresh_token;
      authenticationObject.idToken = data.id_token;
      localStorage.setItem("authenticationObject", JSON.stringify(authenticationObject));
    };

    const higherOrder = () => {
      refresh().subscribe(
        (data) => {
          updateStorage(data);
          refreshToken = data.refresh_token;
          // all the other calls are depending on response from keycloak
          // Update Access Token after its lifetime is over
          if (data.expires_in > data.refresh_expires_in) {
            // to ensure that refresh token is valid when refreshing it, send request 5sec before it gets invalid
            setTimeout(higherOrder, data.refresh_expires_in * 1000 - 5000);
          } else {
            setTimeout(higherOrder, data.expires_in * 1000);
          }
        },
        (error) => {
          console.error(error);
          this.keycloak.logout();
        }
      );
    };

    // first call is made immediatly
    setTimeout(higherOrder, 0);
  }

  private _saveAuthenticationObject(obj: IAuthenticationObject): void {
    try {
      localStorage.setItem("authenticationObject", JSON.stringify(obj));
    } catch (error) {
      throw error;
    }
  }
}
