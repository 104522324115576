import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CdkTableModule } from "@angular/cdk/table";
import { TranslateModule } from "@ngx-translate/core";
import { EneFormsModule } from "@energy-city/ui/forms";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneKpisModule } from "@energy-city/ui/kpis";
import { PipesModule } from "../../../core/pipe/pipes.module";
import { PageWrapperComponent } from "./page-wrapper/page-wrapper.component";
import { WrappedDqiComponent } from "./page-wrapper/wrapped-dqi/wrapped-dqi.component";
import { GeneralInfoPageComponent } from "./general-info-page/general-info-page.component";
import { TableComponent } from "../components";
import { TrendPageComponent } from "./trend/trend.component";
import { SectorsEmissionPageComponent } from "./sectors-emission/sectors-emission.component";
import { EnergyRelatedGasesPageComponent } from "./energy-related-gases/energy-related-gases.component";
import { EnergyPerEnergyCarrierPageComponent } from "./energy-carrier/energy-carrier.component";
import { TransportationPageComponent } from "./transportation/transportation.component";
import { FactorPageComponent } from "./factor/factor.component";
import { GreenhouseGasesService } from "../../co2-cockpit/charts/greenhouse-gases/energy-related/greenhouse-gases.service";
import { EnergyCarrierService } from "../../co2-cockpit/charts/final-energy/energy-carrier/energy-carrier.service";
import { EmissionsFactorsService } from "../../../services/emissions-factors.service";
import { AccountingReportModule } from "@co2-shared/accounting-report";

@NgModule({
  declarations: [
    PageWrapperComponent,
    WrappedDqiComponent,
    TableComponent,
    GeneralInfoPageComponent,

    // refactored pages
    TrendPageComponent,
    SectorsEmissionPageComponent,
    EnergyRelatedGasesPageComponent,
    EnergyPerEnergyCarrierPageComponent,
    TransportationPageComponent,
    FactorPageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    EneFormsModule,
    EneIconModule,
    EneKpisModule,
    CdkTableModule,
    PipesModule,
    AccountingReportModule
  ],
  providers: [GreenhouseGasesService, EnergyCarrierService, EmissionsFactorsService],
  exports: [
    TableComponent,
    GeneralInfoPageComponent,

    // refactored pages
    TrendPageComponent,
    SectorsEmissionPageComponent,
    EnergyRelatedGasesPageComponent,
    EnergyPerEnergyCarrierPageComponent,
    TransportationPageComponent,
    FactorPageComponent
  ]
})
export class PrintPagesModule {}
