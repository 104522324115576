import { TimeSeries, YearlyValueRelative } from "../../accounting-common/model/time-series/time-series";
import { EmissionTimeSeries } from "../../accounting-emission/model/emission-time-series";
import { Savings } from "./savings/savings";
import { Scenario } from "./scenario/scenario";

export class Simulation {
  public readonly referenceValue1990: number;
  private readonly MAX_YEAR = 2050;
  constructor(
    public readonly history: EmissionTimeSeries,
    public readonly savings: Savings,
    public readonly scenario: Scenario
  ) {
    this.referenceValue1990 = this.history.getTotal(1990);
  }

  getHistory(): Array<YearlyValueRelative> {
    return this.history.getTotalTimeSeries().decorateRelativeDifference(this.referenceValue1990);
  }

  getModelForecast(): Array<YearlyValueRelative> {
    return this.history
      .getTotalTimeSeries()
      .getForecast(this.MAX_YEAR)
      .decorateRelativeDifference(this.referenceValue1990);
  }

  getMeasureForecast(): Array<YearlyValueRelative> {
    const emissionsLastMeasuredYear = this.history.getTotalTimeSeries().getValueOfYear(this.history.getMaxYear());
    const propagatedEmissions = new TimeSeries([], "emission reduction by measures");
    for (let year = this.history.getMaxYear() + 1; year <= this.MAX_YEAR; year++) {
      propagatedEmissions.data.push({ year: year, value: emissionsLastMeasuredYear! });
    }
    return propagatedEmissions
      .subtract(this.savings.getCumulativeSavings(this.history.getMaxYear() + 1, this.MAX_YEAR))
      .decorateRelativeDifference(this.referenceValue1990);
  }

  getReductionTargets(): Array<YearlyValueRelative> {
    return this.scenario
      .getAbsoluteTargets(this.referenceValue1990)
      .decorateRelativeDifference(this.referenceValue1990);
  }
}
