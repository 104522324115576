import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
  AddScenario,
  DeleteScenario,
  UpdateScenario,
  getSimulationScenarioError,
  getSimulationScenarios,
  IApplicationState
} from "../../../state/index";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SimulationGoalEditorComponent } from "../../../modules/simulation/simulation-goal-editor/simulation-goal-editor.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ISimulationScenario, ISimulationScenarioValue } from "../../../models/simulation-model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-simulation-scenario",
  templateUrl: "./simulation-scenario.component.html",
  styleUrls: ["./simulation-scenario.component.scss"]
})
export class SimulationScenarioComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public scenario: ISimulationScenario;
  @Input() public isSimulationActive: boolean;

  public isSaveDisabled: boolean;
  public isDeleteDisabled: boolean;
  public isEditDisabled: boolean;
  public isAddGoalDisabled: boolean;
  public isScenarioDefault: boolean = true;
  public isEditing: boolean = false;

  public scenarioInfoFormGroup: FormGroup;

  private destroy$: Subject<undefined> = new Subject();
  private dialogWidth: string = "450px";
  private isProcessing: boolean = false;

  constructor(
    private store: Store<IApplicationState>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.scenarioInfoFormGroup = this.fb.group({
      title: ["", Validators.required],
      description: ["", Validators.required]
    });

    this.store
      .pipe(select(getSimulationScenarios), takeUntil(this.destroy$))
      .subscribe(() => (this.isProcessing = false));

    this.scenarioInfoFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateFlags());

    this.store.pipe(select(getSimulationScenarioError), filter(Boolean)).subscribe(() => {
      this.isProcessing = false;
      this.updateFlags();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.scenario && changes.scenario.currentValue) {
      this.isScenarioDefault = this.scenario.default;
      this.scenarioInfoFormGroup.setValue({
        title: this.translate.instant(this.scenario.title),
        description: this.translate.instant(this.scenario.description)
      });
      this.isEditing = false;
      this.isProcessing = false;
    }
    this.updateFlags();
  }

  public editInfo(): void {
    if (this.isEditDisabled) {
      return;
    }

    this.isEditing = true;
    this.updateFlags();
  }

  public updateGoal(index: number, value: ISimulationScenarioValue): void {
    if (this.isSimulationActive || this.isScenarioDefault) {
      return;
    }
    const dialogRef: MatDialogRef<SimulationGoalEditorComponent> = this.dialog.open(SimulationGoalEditorComponent, {
      width: this.dialogWidth,
      data: {
        value
      }
    });

    dialogRef.afterClosed().subscribe((updatedGoal: ISimulationScenarioValue) => {
      if (updatedGoal) {
        this.scenario.values.splice(index, 1, updatedGoal);
      }
    });
  }

  public addGoal(): void {
    if (this.isAddGoalDisabled) {
      return;
    }
    const dialogRef: MatDialogRef<SimulationGoalEditorComponent> = this.dialog.open(SimulationGoalEditorComponent, {
      width: this.dialogWidth,
      data: {
        value: {
          goal: 0,
          year: 2010
        }
      }
    });

    dialogRef.afterClosed().subscribe((goal: ISimulationScenarioValue) => {
      if (goal) {
        this.scenario.values.push(goal);
        this.updateFlags();
      }
    });
  }

  public removeGoal(index: number): void {
    if (this.isSimulationActive) {
      return;
    }
    this.scenario.values.splice(index, 1);
    this.updateFlags();
  }

  public saveScenario(): void {
    if (this.isSaveDisabled) {
      return;
    }

    this.isProcessing = true;
    const newScenario: ISimulationScenario = {
      ...this.scenario,
      ...this.scenarioInfoFormGroup.value
    };

    if (this.scenario.scenarioId) {
      this.store.dispatch(new UpdateScenario(newScenario));
    } else {
      this.store.dispatch(new AddScenario(newScenario));
    }
    this.updateFlags();
  }

  public deleteScenario(): void {
    if (this.isDeleteDisabled) {
      return;
    }

    this.isProcessing = true;
    this.store.dispatch(new DeleteScenario({ scenarioId: this.scenario.scenarioId }));
    this.updateFlags();
  }

  public trackByFn(index): number {
    return index;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private updateFlags(): void {
    this.isEditDisabled = this.isScenarioDefault || this.isSimulationActive || this.isProcessing || this.isEditing;

    this.isSaveDisabled =
      this.isScenarioDefault ||
      this.isSimulationActive ||
      this.isProcessing ||
      this.scenarioInfoFormGroup.invalid ||
      !this.scenario.values.length;

    this.isDeleteDisabled =
      this.isScenarioDefault || !this.scenario.scenarioId || this.isSimulationActive || this.isProcessing;

    this.isAddGoalDisabled = this.isScenarioDefault || this.isSimulationActive || this.scenario.values.length > 9;
  }
}
