import { CommentComponent } from "./comment.component";
import { CommentService } from "./comment.service";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { EneIconModule } from "@energy-city/ui/icon";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [CommentComponent],
  providers: [CommentService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, EneIconModule, TranslateModule, FormsModule],
  exports: [CommentComponent],
  entryComponents: [CommentComponent]
})
export class CommentModule {}
