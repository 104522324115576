import { ActionReducerMap } from "@ngrx/store/src/models";

import * as fromDistrict from "./district-reducer";

export const districtReducers: ActionReducerMap<any> = {
  district: fromDistrict.reducer
};

// tslint:disable-next-line:no-empty-interface
export interface IDistrictState {
  type: any;
  payload: any;
}
