export enum AccountingMethod {
  /** GPC: "Global Protocol for Community-Scale Greenhouse Gas Emission Inventories" */
  GPC = "GPC",
  /** BISKO: "Bilanzierungs-Systematik Kommunal" */
  BISKO = "BISKO",
  /** BiCO₂SH: "Bilanzierung von CO₂e-Emissionen in Schleswig-Holstein" */
  BICO2SH = "BICO2SH",
  /** STET: "Accounting method exclusively considers the energy-related sectors "stationary energy" and "transport"" */
  STET = "STET",
  /** BICO2BW: "Accounting of Baden-Württemberg method exclusively considers the energy-related sectors "stationary energy" and "transport"" */
  BICO2BW = "BICO2BW"
}
