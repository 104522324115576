export const renewable = ["photovoltaics", "wind", "biomass", "water", "biogas"];

export interface FactorResponse {
  result: Array<{ factor: number; year: number }>;
}

export enum ChartTypes {
  FEDERAL = "federal",
  LOCAL = "local"
}

export const chartTranslateKeys = {
  blackCoal: {
    id: "blackCoal",
    translationKey: "RESOURCES.HARDCOAL",
    color: "#594637"
  },
  brownCoal: {
    id: "brownCoal",
    translationKey: "RESOURCES.BROWNCOAL",
    color: "#8F582B"
  },
  gas: {
    id: "gas",
    translationKey: "RESOURCES.GAS",
    color: "#FFBD35"
  },
  photovoltaics: {
    id: "photovoltaics",
    translationKey: "RESOURCES.PHOTOVOLTAICS",
    color: "#9FA7E8"
  },
  nuclear: {
    id: "nuclear",
    translationKey: "RESOURCES.NUCLEAR",
    color: "#85FF66"
  },
  wind: {
    id: "wind",
    translationKey: "RESOURCES.WIND",
    color: "#CDEEF3"
  },
  water: {
    id: "water",
    translationKey: "RESOURCES.WATER",
    color: "#39A9E3"
  },
  oil: {
    id: "oil",
    translationKey: "RESOURCES.OIL",
    color: "#2338B2"
  },
  environmentalHeat: {
    id: "environmentalHeat",
    translationKey: "RESOURCES.ENVIRONMENTALHEAT",
    color: "#922424"
  },
  biomass: {
    id: "biomass",
    translationKey: "RESOURCES.BIOMASS",
    color: "#B7A99F"
  },
  consumption: {
    id: "consumption",
    translationKey: "RESOURCES.CONSUMPTION",
    color: "var(--ene-main-grey-700)",
    type: "line",
    showInLegend: false,
    visible: false
  },
  other: {
    id: "other",
    translationKey: "RESOURCES.OTHER",
    color: "#F05918"
  },
  biogas: {
    id: "biogas",
    translationKey: "RESOURCES.BIOGAS",
    color: "#3AC33A"
  },
  wasteIncineration: {
    id: "wasteIncineration",
    translationKey: "RESOURCES.WASTEINCINERATION",
    color: "#D3FFA6"
  }
};
