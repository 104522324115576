import { ChartFinalEnergyActionType, FinalEnergyActions } from "./final-energy-actions";

export function reducer(state = [], action: FinalEnergyActions) {
  switch (action.type) {
    case ChartFinalEnergyActionType.GET_KPI_SUCCESS:
      return action;
    case ChartFinalEnergyActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
