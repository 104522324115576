import { Injectable } from "@angular/core";
import { LocaleService } from "@energy-city/locale-conversions";
import { TranslateService } from "@ngx-translate/core";
import { IInputTable, IKey } from "../../../../models/data-panel/table.model";
import { first, initial, isEmpty, last, tail } from "lodash";
import { IValueAndQuality, IDataByYear, IDataInputDto } from "../../interfaces/data-input-dto";
import { EnergyTypes } from "../../../../configs/accounting-method";

const CSV = {
  EMPTY_FIELD: "", // was "null" before
  ALLOWED_NULL_VALUES: ["-", "--", "null", ""],
  PARENT_ROW_FILLER: {
    /** box-drawing character U+2550 */
    CHARACTER: "-",
    /** amount of filler-character per field */
    REPETITIONS: 10
  },
  INDENTATION: 4,
  DELIMITER: ";",
  EXPORT_NUMBER_FORMAT: { maximumFractionDigits: 20, useGrouping: false } as Intl.NumberFormatOptions
};

interface IKeys {
  [key: string]: string;
}

interface IRow {
  rowNameCandidates: string[];
  fields: string[];
}

interface IGroup {
  groupNameCandidates: string[];
  rows: Array<IRow>;
}

interface ICsvCellValues {
  value: string;
  quality: string;
}

interface ICsvRow {
  isParentRow: boolean;
  translatedName: string;
  cells?: Array<ICsvCellValues>;
}

interface IReconstructed {
  [key: string]: IValueAndQuality | IReconstructed;
}

class LocalizedError extends Error {
  constructor(translateService: TranslateService, translationKey: string, interpolateParams?: Object) {
    const errorMessage = translateService.instant(translationKey, interpolateParams);
    super(errorMessage);
  }
}

@Injectable()
export class CsvTransformerService {
  constructor(private translateService: TranslateService, private localeService: LocaleService) {}

  public prepareForExport(data: IInputTable, unit: string) {
    if (!data?.columns || !data?.aside || !data?.dataSource) {
      throw new Error("no Data");
    }

    /** The locale is the user's locale in which number are displayed. Not to be
     * confused with "language", which is the *Language* in which text is
     * translated.
     */
    const locale = this.localeService.getUserLocale();
    const availableYears = data.columns;

    const dataRows: Array<ICsvRow> = data.dataSource.map((item) => {
      const inputCells = availableYears.map((column) => item[column]);
      const firstCell = first(inputCells);
      const translatedName = this.translateService.instant(`RESOURCES.${firstCell.name}`.toUpperCase());
      const isParentRow = !first(inputCells).dependency && inputCells.every((cell) => cell.value === undefined);

      if (isParentRow) {
        return {
          isParentRow,
          translatedName
        };
      }

      const valueFormattedCells: Array<ICsvCellValues> = inputCells.map((cell) => {
        const value: string = cell.value?.toLocaleString(locale, CSV.EXPORT_NUMBER_FORMAT) ?? CSV.EMPTY_FIELD;
        const quality: string = value === CSV.EMPTY_FIELD ? CSV.EMPTY_FIELD : cell.quality?.toString();
        return {
          value,
          quality
        };
      });

      return {
        isParentRow,
        translatedName,
        cells: valueFormattedCells
      };
    });

    return this.formatToCsv(availableYears, dataRows, unit);
  }

  public downloadAsFile(fileContent: string, filename: string): void {
    const blob: Blob = new Blob([`\ufeff${fileContent}`], {
      type: "text/csv;charset=utf-8"
    });

    // apparently, this is indeed the easiest and most reliable way to download
    // a file: create an <a> tag, append to body, click on it to download and
    // clean up afterwards. It is now 2020.
    const a: HTMLElement = document.createElement("a");
    const url: string = URL.createObjectURL(blob);
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.style.visibility = "hidden";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public async importFromCsv(file: any, keys: Array<IKey>): Promise<IDataInputDto> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        try {
          const csv: string = (reader.result as string).replace(/(\r\n)/gm, "\n");
          const dataImportObject = this.csvToImportObject(csv, keys);
          resolve(dataImportObject);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (e) => {
        reject(reader.error.message);
      };
    });
  }

  public csvToImportObject(csv: string, keys: Array<IKey>, delimiter: string = CSV.DELIMITER): IDataInputDto {
    const lines = csv.split("\n");
    const headers = lines.splice(0, 2)[0].split(delimiter);
    const unit = first(headers).trim();

    // to ensure backwards-compatibility, we need to check whether `unit` is a translation or a translation key
    const unitOfMeasure = this.getTranslationKeyCandidates(unit, this.getUnitTranslationKeys())[0] || unit;

    const years = tail(headers)
      .map((field) => field.trim())
      .filter((field) => !isEmpty(field));

    const groups: Array<IGroup> = this.textRowsToGroups(lines);
    const data: Array<any> = this.restructureByYears(groups, years, keys);

    return {
      metadata: {
        unitOfMeasure
      },
      data
    };
  }

  /** This method reads the lines as they appear in the csv file and forms
   * groups from "parent" lines and their following child-lines.
   * Also, the translated row headers will be un-translated by a reverse lookup */
  private textRowsToGroups(lines: Array<string>): Array<IGroup> {
    const translationKeys = this.getTranslationKeys();

    // build up the data as it appears in the csv:
    const groups = lines.reduce((acc, currentLine) => {
      const untrimmedFields = currentLine.split(CSV.DELIMITER);
      const fields = untrimmedFields.map((field) => field.trim());
      const name: string[] = this.getTranslationKeyCandidates(first(fields), translationKeys);
      if (name === undefined || name.length === 0) {
        // an empty row, ignore
        return acc;
      }
      if (this.isParentRow(untrimmedFields)) {
        // create a new group with the group name taken from currentLine
        const newGroup: IGroup = { groupNameCandidates: name, rows: [] };
        return [...acc, newGroup];
      } else {
        // add a row to the most recent group or create a default group if there is none.
        const recentGroup: IGroup = last(acc) || { groupNameCandidates: undefined, rows: [] };
        const newRow: IRow = { rowNameCandidates: name, fields: fields.slice(2) };
        return [...initial(acc), { ...recentGroup, rows: [...recentGroup.rows, newRow] }];
      }
    }, [] as Array<IGroup>);

    return groups;
  }

  /** This method accepts an arrow of groups (where each entry of each group has
   * values for multiple years) and groups the data by year instead. */
  private restructureByYears(groups: Array<IGroup>, years: Array<string>, keys: Array<IKey>) {
    const data: Array<IDataByYear> = years
      .map((currentYear, yearIndex) => {
        const groupsWithThisYear = groups
          .map((group) => ({
            groupNameCandidates: group.groupNameCandidates,
            valuesAndQualities: group.rows
              .filter((row) => {
                const valueForThisYear = row.fields[yearIndex * 2];
                const isNumeric = this.isNumeric(this.localeService.parseLocalNumber(valueForThisYear));
                const isValidValue = isNumeric || CSV.ALLOWED_NULL_VALUES.includes(valueForThisYear);
                if (!isValidValue) {
                  throw new LocalizedError(this.translateService, "DATA_PANEL.INPUT_DATA.MODAL.ERROR.INVALID_VALUE", {
                    rowName: this.translateService.instant(`RESOURCES.${row.rowNameCandidates[0]}`.toUpperCase()),
                    currentYear,
                    valueForThisYear
                  });
                }
                return isValidValue;
              })
              .map((row) => {
                const inputValue = row.fields[yearIndex * 2];
                const inputQuality = row.fields[yearIndex * 2 + 1];
                const valueAndQuality: IValueAndQuality = {
                  value: CSV.ALLOWED_NULL_VALUES.includes(inputValue)
                    ? null
                    : this.localeService.parseLocalNumber(inputValue),
                  quality: this.localeService.parseLocalNumber(inputQuality) ?? 0
                };
                return {
                  rowNameCandidates: row.rowNameCandidates,
                  valueAndQuality
                };
              })
          }))
          .filter((group) => group.valuesAndQualities.length > 0);

        const yearData = groupsWithThisYear.reduce((acc, group) => {
          const rowsWithValueAndQuality = group.valuesAndQualities.reduce((nested, row) => {
            const { name, id } = keys.find(
              (key) => row.rowNameCandidates.find((candidate) => key.name.toUpperCase() === candidate) !== undefined
            );
            const path = id.split(".").slice(2).reverse();
            const reconstructedValueAndQuality =
              path.length > 0
                ? path.reduce(
                    (res: IReconstructed | IValueAndQuality, item: string) => ({ [item]: { ...res } }),
                    row.valueAndQuality
                  )
                : row.valueAndQuality;

            return { ...nested, [name]: reconstructedValueAndQuality };
          }, {} as { [key: string]: IValueAndQuality });

          if (group.groupNameCandidates !== undefined && group.groupNameCandidates.length > 0) {
            const { name } =
              keys.find(
                (key) =>
                  group.groupNameCandidates.find((candidate) => key.name.toUpperCase() === candidate) !== undefined
              ) || {};
            return {
              ...acc,
              [name]: {
                ...rowsWithValueAndQuality
              }
            };
          }

          // if a groupName is "undefined", promote the inner nested values to this level
          return {
            ...acc,
            ...rowsWithValueAndQuality
          };
        }, {} as { [key: string]: { [key: string]: IValueAndQuality } });

        if (isEmpty(yearData)) {
          return undefined;
        } else {
          return {
            year: +currentYear,
            ...yearData
          };
        }
      })
      .filter((value) => value !== undefined);

    return data;
  }

  private formatToCsv(availableYears: Array<string>, dataRows: Array<ICsvRow>, unit: string): string {
    const headerOne: Array<string> = [unit, "", ...availableYears.reduce((acc, year) => [...acc, year, ""], [])];
    const headerTwo: Array<string> = ["", "", ...availableYears.reduce((acc, _) => [...acc, "Wert", "DGI"], [])];
    const body: Array<Array<string>> = dataRows.map((row) => this.buildRow(row, availableYears.length));

    const allCells = [headerOne, headerTwo, ...body];

    const maxColumnWidth: Array<number> = headerOne.map((_, i) => {
      return allCells.reduce((max, currentRow) => Math.max(currentRow[i].length, max), 0);
    });

    const formattedRows = allCells.map((row) =>
      row.reduce((acc, cellText, i) => {
        const columnWidth = maxColumnWidth[i];
        if (i === 0) {
          // title column: left-align
          return cellText.padEnd(columnWidth, " ");
        } else {
          // left-align columns
          const padChar = cellText.startsWith(CSV.PARENT_ROW_FILLER.CHARACTER) ? CSV.PARENT_ROW_FILLER.CHARACTER : " ";
          return acc + CSV.DELIMITER + cellText.padEnd(columnWidth, padChar);
        }
      }, "")
    );

    return formattedRows.join("\n");
  }

  private buildRow(row: ICsvRow, numberOfYears: number): Array<string> {
    if (row.isParentRow) {
      const filler = CSV.PARENT_ROW_FILLER;
      const fillerField = filler.CHARACTER.repeat(filler.REPETITIONS);
      return [row.translatedName, "", ...Array<string>(numberOfYears * 2).fill(fillerField)];
    }

    const dataFields: Array<string> = row.cells?.reduce((acc, cell) => [...acc, cell.value, cell.quality], []);
    return [" ".repeat(CSV.INDENTATION) + row.translatedName, "", ...dataFields];
  }

  private isParentRow(fields: Array<string>): boolean {
    if (first(fields).startsWith(" ")) {
      // if the row title is indented, it cannot be a parent row, even if all values
      // are missing.
      return false;
    }
    return fields
      .slice(1) // ignore first field (title text)
      .every((item) => item === "" || item.replace(new RegExp(`${CSV.PARENT_ROW_FILLER.CHARACTER}+`, "g"), "") === "");
  }

  private isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /** gets all translation keys from the "RESOURCES" parent key, for reverse
   * lookup during import. */
  private getTranslationKeys(): IKeys {
    return this.translateService.instant("RESOURCES") as IKeys;
  }

  /** gets all translation keys from the "DATA_PANEL" parent key, for reverse
   * lookup during import. */
  private getUnitTranslationKeys(): IKeys {
    return this.translateService.instant("DATA_PANEL") as IKeys;
  }

  /** Gets the translation key for a given translation (reverse lookup), but
   * also ignores keys that contain a dash ("-"), because there are duplicate
   * entries in the translation service, for example for "productUse" and
   * "product-use" */
  private getTranslationKeyCandidates(translationValue: string, keys: IKeys): string[] {
    if (!translationValue) {
      return;
    }
    try {
      const translationKeys = Object.entries(keys)
        .filter(([key, translation]) => translation === translationValue)
        .map(([key, translation]) => key);
      return translationKeys;
    } catch {
      throw new LocalizedError(this.translateService, "DATA_PANEL.INPUT_DATA.MODAL.ERROR.TRANSLATION_NOT_FOUND", {
        rowName: translationValue
      });
    }
  }
}
