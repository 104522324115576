import { ActionReducerMap } from "@ngrx/store/src/models";

import { reducer } from "./auth-reducer";
import { IAuth } from "./auth-model";
import { AuthActionType } from "./auth-actions";

export const authReducers: ActionReducerMap<any> = {
  auth: reducer
};

export interface IAuthState {
  auth: IAuth;
  type: AuthActionType;
}
