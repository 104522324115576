import { Component } from "@angular/core";
import { ChartTypes } from "../../../../../models/charts.model";
import { DatabaseChartService } from "../database-chart.service";
import { DatabaseChartComponent } from "../database-chart.component";

@Component({
  selector: "app-local-electricity",
  templateUrl: "../database-chart.component.html",
  styleUrls: ["../database-chart.component.scss"],
  providers: [DatabaseChartService]
})
export class LocalElectricityComponent extends DatabaseChartComponent {
  protected chartType = ChartTypes.LOCAL;
}
