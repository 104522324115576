import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { omit } from "lodash";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { EnergyTypes, MixType } from "../../../../../configs/accounting-method";
import { EnergyType } from "../../../../../models/charts.models";
import { sectorConfig } from "../../../../../models/co2-sectors-config.model";
import {
  RegionPropertyEmissionService
} from "../../../../../services/region-property/region-property-emission.service";
import { IRegionIdentifier, UtilService } from "../../../../../services/util.service";
import { FinalEnergyService } from "../../final-energy/final-energy.service";
import { IDataItems } from "../../models/final-energy-data.interface";
import { energyTypeMap } from "../greenhouse-gases.model";

@Injectable()
export class GreenhouseGasesService {
  private requestedSectors: Array<EnergyTypes> = [EnergyTypes.TRANSPORTATION, EnergyTypes.STATIONARY_ENERGY];

  constructor(
    private utilService: UtilService,
    private http: HttpClient,
    private finalEnergyService: FinalEnergyService,
    private emissionService: RegionPropertyEmissionService,
  ) {}
  public getCo2Emissions(
    regionIdentifier: IRegionIdentifier,
    energyType: EnergyType,
    year: number,
    weatherCorrection: boolean,
    localMix: MixType,
  ): Observable<{ [key: string]: IDataItems }> {
    if (!regionIdentifier?.regionId || !regionIdentifier?.regionType) {
      return;
    }

    const sectorQuery = sectorConfig.sectors
      .filter((sector) => this.requestedSectors.includes(sector.type))
      .map((sector) => ({
        ...sector,
        level: sector.type === EnergyTypes.STATIONARY_ENERGY ? 2 : sector.level,
        energyType,
        weatherCorrection: weatherCorrection,
        localMix: Boolean(localMix)
      }));

    return this.emissionService.getCo2EmissionSectorData(
      regionIdentifier,
      sectorQuery,
      year
    ).pipe(
        map((emissions) => {
          return emissions.reduce((acc, emissionSector) => {
            if (emissionSector.sector.type === EnergyTypes.STATIONARY_ENERGY) {
              return {...acc, ...omit(emissionSector.result[0], "year")};
            } else {
              return {
                ...acc,
                transportation: this.finalEnergyService.joinGrouped(
                  this.finalEnergyService.groupEnergyTypes(
                    this.finalEnergyService.pluckToLevelWithKey(emissionSector.result[0], "value"),
                    energyTypeMap
                  )
                )
              };
            }
          }, {});
        })
      );
  }

  public getDqi(
    year: number,
    weatherCorrection: boolean,
    localMix: MixType,
    energyType: EnergyType,
    emissions?: { [key: string]: IDataItems }
  ): Observable<number> {
    if (energyType === EnergyType.FINAL && emissions) {
      return of(this.finalEnergyService.calculateDQI(emissions as any));
    }

    return this.getCo2Emissions(
      this.utilService.getRegionIdentifier(),
      EnergyType.FINAL,
      year,
      weatherCorrection,
      localMix
    ).pipe(map(emissionsFinal => this.finalEnergyService.calculateDQI(emissionsFinal as any)));
  }
}
