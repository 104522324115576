import { ChangeDetectionStrategy, Component } from "@angular/core";

// ngrx
import * as fromRoot from "../../../../../state/index";
import { Store } from "@ngrx/store";
import { TenantInfoService } from "../../../../../services/tenantInfo.service";
import { PopupService } from "../../../../pop-up/services/popup/popup.service";
import { HelpTabChange } from "../../../../../state/index";

@Component({
  selector: "app-info-datasource",
  templateUrl: "./info-datasource.component.html",
  styleUrls: ["./info-datasource.component.scss", "../info-sectors.subsectors.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDatasourceComponent {
  constructor(
    public store: Store<fromRoot.IApplicationState>,
    private popup: PopupService,
    private tenantInfoService: TenantInfoService
  ) {}

  public goToGlossary() {
    const action = new HelpTabChange("definitions.#basisbilanz");
    this.store.dispatch(action);
    this.popup.openGlossaryAtAnchor("definitions.#basisbilanz");
  }

  public getTenantSpecificTranslation(path: string): string {
    return this.tenantInfoService.getTranslation(path);
  }
}
