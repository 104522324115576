import { Action } from "@ngrx/store";
import { State } from "./index";

export enum GasNetworkActionTypes {
  GET_GAS_NETWORK_LENGTH = "[Gas Network]: Get gas network length",
  GET_GAS_NETWORK_LENGTH_SUCCESS = "[Gas Network]: Get gas network length successfully",
  GET_GAS_NETWORK_LENGTH_ERROR = "[Gas Network]: Get gas network length error"
}

export class GasNetworkLength implements Action {
  public readonly type = GasNetworkActionTypes.GET_GAS_NETWORK_LENGTH;

  constructor(public payload: string) {}
}

export class GasNetworkLengthSuccess implements Action {
  public readonly type = GasNetworkActionTypes.GET_GAS_NETWORK_LENGTH_SUCCESS;

  constructor(public data: State) {}
}

export class GasNetworkLengthError implements Action {
  public readonly type = GasNetworkActionTypes.GET_GAS_NETWORK_LENGTH_ERROR;

  constructor(public error: string) {}
}

export type GasNetworkActionsUnion = GasNetworkLength | GasNetworkLengthSuccess | GasNetworkLengthError;
