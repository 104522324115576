import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneIconModule } from "@energy-city/ui/icon";
import { TranslateModule } from "@ngx-translate/core";

// components
import { AccountingMethodsComponent } from "./accounting-methods.component";

@NgModule({
  declarations: [AccountingMethodsComponent],
  imports: [CommonModule, EneIconModule, TranslateModule],
  exports: [AccountingMethodsComponent],
  entryComponents: [AccountingMethodsComponent]
})
export class AccountingMethodsModule {}
