/* tslint:disable:member-ordering */
import { Injectable } from "@angular/core";
import { switchMap, concatMap, map, mergeMap, catchError } from "rxjs/operators";
import { Actions, Effect, ofType } from "@ngrx/effects";

import { DataService } from "./../../services/data.service";
import * as network from "./network-actions";
import { NetworkActionType, GetNetworkDataByTileSuccess, NetworkError } from "./network-actions";
import { ErrorAction, acceptJsonWithoutCache, FatalError } from "./../common";
import { EMPTY } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { Http } from "../../../types/httpMethod";

@Injectable()
export class NetworkEffects {
  @Effect() public GetNetworkEmissionFactors$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_EMISSION_FACTOR),
    switchMap((action: network.GetNetworkEmissionFactor) =>
      this.requestGenerator(action, network.GetNetworkEmissionFactorSuccess, "/emissionFactors", Http.GET)
    )
  );

  @Effect() public updateUtility$ = this.actions$.pipe(
    ofType(NetworkActionType.UPDATE_UTILITY),
    switchMap((action: network.GetNetwork) =>
      this.requestGenerator(action, network.UpdateUtilitySuccess, "/utility", Http.PATCH)
    )
  );

  @Effect() public getNetwork$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_NETWORK),
    switchMap((action: network.GetNetwork) =>
      this.requestGenerator(action, network.GetNetworkSuccess, "/getNetworks", Http.POST)
    )
  );

  @Effect() public updateNetwork$ = this.actions$.pipe(
    ofType(NetworkActionType.UPDATE_NETWORK),
    concatMap((action: any) => this.requestGenerator(action, network.UpdateNetworkSuccess, "/network", Http.PATCH))
  );

  @Effect() public createMix$ = this.actions$.pipe(
    ofType(NetworkActionType.CREATE_MIX),
    concatMap((action: network.CreateMix) => this.requestGenerator(action, network.CreateMixSuccess, "/mix", Http.POST))
  );

  @Effect() public updateMix$ = this.actions$.pipe(
    ofType(NetworkActionType.UPDATE_MIX),
    concatMap((action: network.UpdateMix) =>
      this.requestGenerator(action, network.UpdateMixSuccess, "/mix", Http.PATCH)
    )
  );

  @Effect() public deleteMix$ = this.actions$.pipe(
    ofType(NetworkActionType.DELETE_MIX),
    concatMap((action: network.DeleteMix) =>
      this.requestGenerator(action, network.DeleteMixSuccess, "/mix", Http.DELETE)
    )
  );

  @Effect() public createComponent$ = this.actions$.pipe(
    ofType(NetworkActionType.CREATE_COMPONENT),
    concatMap((action: network.CreateComponent) =>
      this.requestGenerator(action, network.CreateComponentSuccess, "/component", Http.POST)
    )
  );

  @Effect() public updateComponent$ = this.actions$.pipe(
    ofType(NetworkActionType.UPDATE_COMPONENT),
    concatMap((action: network.UpdateComponent) =>
      this.requestGenerator(action, network.UpdateComponentSuccess, "/component", Http.PATCH)
    )
  );

  @Effect() public deleteComponent$ = this.actions$.pipe(
    ofType(NetworkActionType.DELETE_COMPONENT),
    concatMap((action: network.DeleteComponent) =>
      this.requestGenerator(action, network.DeleteComponentSuccess, "/component", Http.DELETE)
    )
  );

  @Effect() public GetNetworkInvestChartData$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_INVEST_CHART_DATA),
    switchMap((action: network.GetNetworkInvestChartData) =>
      this.requestGenerator(action, network.GetNetworkInvestChartDataSuccess, `/replacementInvest`, Http.GET)
    )
  );

  @Effect() public GetNetworkDataByTile$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_NETWORK_DATA_BY_TILE),
    mergeMap((action: network.GetNetworkDataByTile) => {
      const gridApi = environment.infrastructure.gridApi;
      const { endpoint, objectType, type } = action.payload;

      return this.dataService.sendMapRequest(`${gridApi}/map/${endpoint}/${objectType}/${type}`, action.payload).pipe(
        map((data) => new GetNetworkDataByTileSuccess({ ...data, request: action.payload })),
        catchError((error) => (error.status === 404 ? EMPTY : ErrorAction(action, error, FatalError)))
      );
    })
  );

  @Effect() public GetNetworkStationInformation$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_STATION_INFORMATION),
    switchMap((action: network.GetNetworkStationInformation) =>
      this.requestGenerator(
        action,
        network.GetNetworkStationInformationSuccess,
        `/station/${action.ags}/${action.level}/${action.year}/${action.scenario}/${action.segment_type}/${action.station_id}`,
        Http.GET
      )
    )
  );

  @Effect() public getAllStation$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_ALL_STATIONS),
    switchMap((action: network.GetNetworkAllStations) =>
      this.requestGenerator(
        action,
        network.GetNetworkAllStationsSuccess,
        `/station/${action.ags}/${action.level}/${action.year}/${action.scenario}/${action.segment_type}`,
        Http.GET
      )
    )
  );

  @Effect() public getCumulativeInvests$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_CUMULATIVE_INVESTS),
    mergeMap((action: network.GetCumulativeInvests) => {
      const url = "";

      return this.http
        .get<any>(url, {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams()
        })
        .pipe(map((data: any) => new network.GetCumulativeInvestsSuccess(data)));
    })
  );

  @Effect() public getNetworkId = this.actions$.pipe(
    ofType(NetworkActionType.GET_NETWORK_ID),
    switchMap((action: network.GetNetworkId) => {
      // const url: string = `${environment.infrastructure.gridApi}/networks`;
      const url = `${environment.infrastructure.gridApi}/networks`;

      return this.http
        .get<any>(url, {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams().set("regionId", action.payload)
        })
        .pipe(map((data: any) => new network.GetNetworkIdSuccess(data)));
    })
  );

  @Effect() public getGasNetworkLength$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_GAS_NETWORK_LENGTH),
    switchMap((action: network.GetGasNetworkLength) => {
      const url: string = `${environment.infrastructure.gridApi}/networks/${action.payload}/segments/length`;
      return this.http
        .get<any>(url, {
          headers: new HttpHeaders(acceptJsonWithoutCache)
        })
        .pipe(map((data: any) => new network.GetGasNetworkLengthSuccess(data)));
    })
  );

  @Effect() public getGasKpi$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_GAS_COCKPIT_KPI),
    switchMap((action: network.GetGasCockpitKpi) => {
      const url: string = `${environment.infrastructure.gridApi}/networks/${action.payload}`;
      return this.http
        .get<any>(url, {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams().set("year", "" + action.year)
        })
        .pipe(map((data: any) => new network.GetGasCockpitKpiSuccess(data)));
    })
  );

  @Effect() public getGasValuesForAllYe$ = this.actions$.pipe(
    ofType(NetworkActionType.GET_GAS_COCKPIT_VALUES_FOR_ALL_YEARS),
    switchMap((action: network.GetGasCockpitValuesForAllYears) => {
      const url: string = `${environment.infrastructure.gridApi}/networks/${action.payload}?year=${
        action.timerange[0] - 1
      }&year=${action.timerange[1]}`;
      const params = {
        headers: new HttpHeaders(acceptJsonWithoutCache)
      };
      return this.http
        .get<any>(url, params)
        .pipe(map((data: any) => new network.GetGasCockpitValuesForAllYearsSuccess(data)));
    })
  );

  constructor(private dataService: DataService, private actions$: Actions, private http: HttpClient) {}

  private requestGenerator(action: any, successClass: any, path: string, method: Http) {
    return this.dataService.sendNetworkServiceRequest(path, action.payload, method).pipe(
      map((payload) => new successClass(payload)),
      catchError((error) => ErrorAction(action, error, network.NetworkError))
    );
  }
}
