import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { SelectedRegionStandaloneService } from "@co2-shared/common";
import * as fromRoot from "@energy-city/components";
import { AccountingMethodService, Co2SectorsConfigModel } from "@energy-city/components";
import { LocaleService } from "@energy-city/locale-conversions";
import { EneSidenavService, IPanelEvent, PanelEvent } from "@energy-city/ui/sidenav";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { IRegionIdentifier } from "libs/components/src/lib/app/services/util.service";
import { isEqual } from "lodash";
import { Subject, combineLatest } from "rxjs";
import { distinctUntilChanged, filter, map, takeUntil } from "rxjs/operators";
import { AccountingMethod } from "../../../../../../configs/accounting-method";
import { ChartCo2SectorsService } from "../chart-co2-sectors.service";
import { ChartCo2SectorsHelper } from "../shared/chart-co2-sectors.helper.class";

@Component({
  selector: "app-chart-co2-sectors-compact",
  templateUrl: "./chart-co2-sectors-compact.component.html",
  styleUrls: [
    "./chart-co2-sectors-compact.component.scss",
    "../../../../../../../styles/chart.customlegend.include.scss"
  ]
})
export class ChartCo2SectorsCompactComponent implements AfterViewInit, OnDestroy {
  public Co2SectorConfig: any = {
    chart: {},
    plotOptions: { series: {} }
  };
  public panelEvent: typeof PanelEvent = PanelEvent;
  public AccountingMethod = AccountingMethod;
  public customLegendAbsolute: number;
  public customLegendRelative: number;
  public customLegendName: string;
  public customLegendItemList: any[] = [];
  public Co2SectorSeries: any = [];
  public locale: string;
  public isEnlarged: boolean;
  private calculatedTotal: number;
  private co2SectorsConfig: Co2SectorsConfigModel = new Co2SectorsConfigModel();
  private destroy$: Subject<void> = new Subject();

  constructor(
    private sidenavService: EneSidenavService,
    public store: Store<fromRoot.IApplicationState>,
    public translate: TranslateService,
    public accountingMethodService: AccountingMethodService,
    private chartCo2SectorsService: ChartCo2SectorsService,
    private regionService: SelectedRegionStandaloneService,
    private localeService: LocaleService
  ) {
    this.locale = this.localeService.getUserLocale();
  }

  public ngAfterViewInit(): void {
    this.isEnlarged = Boolean(this.sidenavService.sidenav.getPanel("co2_cockpit")?.enlarge);

    this.sidenavService.onPanelChange$
      .pipe(
        filter((panel: IPanelEvent) => panel.event !== 0 && panel.name === "co2_cockpit" && panel.event !== 3),
        map((event: IPanelEvent) => event.state),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => (this.isEnlarged = value));

    combineLatest([
      this.accountingMethodService.currentParameters$,
      this.regionService.getSelectedRegionIdentifier(),
      this.store.pipe(select(fromRoot.GetTimelineState))
    ])
      .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe(([accountingParameters, regionIdentifier]) => {
        this.requestCo2Emission(regionIdentifier);
      });

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.Co2SectorSeries[0]?.data) {
        this.Co2SectorSeries = [...this.Co2SectorSeries];
        this.Co2SectorSeries[0].data = this.Co2SectorSeries[0].data.map((item) => ({
          ...item,
          name: this.translate.instant(item.translationKey)
        }));
      }
    });
  }

  private async updateData(res) {
    const chartCo2SectorsHelper = new ChartCo2SectorsHelper();

    const { highchartsData, valueSum } = chartCo2SectorsHelper.getChartSectorData(res, 1, this.translate);
    this.calculatedTotal = valueSum;
    const translateRes = "RESOURCES.CO2TOTAL";
    highchartsData.forEach((element) => {
      // add events for custom legend
      element.events = {
        mouseOver: (event) => {
          const a = event.target.translationKey;
          const b = event.target.value.toFixed(2);
          this.changeLegend(b, a);
        },
        mouseOut: () => {
          this.changeLegend(this.calculatedTotal, translateRes);
        }
      };
    });
    this.changeLegend(this.calculatedTotal, translateRes);

    this.Co2SectorSeries = [...this.co2SectorsConfig.getChartConfig("small")];
    this.Co2SectorSeries[0].data = highchartsData;
  }

  private requestCo2Emission(regionIdentifier: IRegionIdentifier): void {
    if (this.chartCo2SectorsService) {
      this.chartCo2SectorsService
        .getChartData(regionIdentifier)
        .pipe(takeUntil(this.destroy$))
        .subscribe((emission) => this.updateData(emission));
    }
  }

  public changeLegend(value: number, key: string): void {
    this.customLegendName = key;
    this.customLegendAbsolute = value;
    this.customLegendRelative = (100 / this.calculatedTotal) * value;
  }

  public togglePanelState(newState: boolean): void {
    this.isEnlarged = newState;
    this.sidenavService.sidenav.getPanel("co2_cockpit").setEnlarge(newState);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
