import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  EMobilityActionType,
  GetEMobilityData,
  GetEMobilityDataError,
  GetEMobilityDataSuccess
} from "./e-mobility-actions";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { acceptJsonWithoutCache, createErrorMessage } from "../../../../common";

export interface IEMobilityDataResponse {
  result?: {
    count: number;
    capacity: number;
    consumption: number;
    co2Saving: number;
  };
}

@Injectable()
export class EMobilityEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  /**
   * returns details about the given gas station.
   */
  @Effect() public getEMobilityData$ = this.actions$.pipe(
    ofType(EMobilityActionType.GET_E_MOBILITY_DATA),
    switchMap((action: GetEMobilityData) => {
      const uri = `${environment.emission.calculationApi}/publicsector/connection-groups/${action.connectionGrouId}/e-mobility`;
      return this.http
        .get<IEMobilityDataResponse>(uri, {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams().set("year", String(action.year)).set("scenarioId", action.scenarioId)
        })
        .pipe(
          map((data: IEMobilityDataResponse) => {
            return new GetEMobilityDataSuccess(data.result);
          }),
          catchError(
            (error): Observable<GetEMobilityDataError> => {
              return of(new GetEMobilityDataError(createErrorMessage(error)));
            }
          )
        );
    })
  );
}
