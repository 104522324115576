import { Injectable } from "@angular/core";
import { ReplaySubject, Subject, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class EneUiService {
  constructor() {}
  private staticTextContent: any = {
    "de-ch": {
      true: "Ja",
      false: "Nein"
    },
    "de-de": {
      true: "Ja",
      false: "Nein"
    },
    "fr-fr": {
      true: "Oui",
      false: "Non"
    },
    "en-us": {
      true: "True",
      false: "False"
    }
  };
  public languageChange$: BehaviorSubject<string> = new BehaviorSubject("de-ch");
  public textChange$: BehaviorSubject<string> = new BehaviorSubject(this.staticTextContent["de-ch"]);
  public onPanelUpdate$: ReplaySubject<any> = new ReplaySubject<any>(undefined);
  public onPanelVerticalHeightChange$: Subject<number> = new Subject<number>();

  /**
   * Takes the width of the window and returns a number or string depending on the width
   * It is used to calculate grids values
   *
   * @param innerWidth width of the window. if not set it will read it automatically
   * @param returnNameInsteadOfNumber set true if you want to get "sm" instead of "123"
   */
  public responsiveCalculator(
    innerWidth?: number | boolean,
    returnNameInsteadOfNumber: boolean = false
  ): string | number | undefined {
    if (!innerWidth) {
      innerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    // switch of true so it will execute always. I've used switch here becaus it probably looks cleaner with it.
    switch (true) {
      case innerWidth < 1024:
        return returnNameInsteadOfNumber ? "sm" : 42;
      case innerWidth >= 1024 && innerWidth < 1440:
        return returnNameInsteadOfNumber ? "md" : 48;
      case innerWidth >= 1440 && innerWidth < 1930:
        return returnNameInsteadOfNumber ? "lg" : 64;
      case innerWidth >= 1930:
        return returnNameInsteadOfNumber ? "xl" : 80;
    }
  }

  public setLanguage(language: string): void {
    this.languageChange$.next(language);
    this.textChange$.next(this.staticTextContent[language]);
  }
}
