import { IChartTree, ICo2EmissionSectorLeaf, ICo2EmissionSectorNode} from "../../../../../../../libs/components/src/lib/app/modules/co2-cockpit/charts/greenhouse-gases/sectors/shared/chart-co2-sectors.helper.class"
import { INh3EmissionSectorResultDto } from "../data/dto/nh3-emission-dto.interface";
export class Nh3PerSector {
  public nh3PerSector: INh3EmissionSectorResultDto[];

  constructor(sectors: INh3EmissionSectorResultDto[]) {
    this.nh3PerSector = sectors;
  }

  /**
   * getSectorEmissionsForChart transforms data so it is usable by gp-chart-emission-sunburst
   * @returns SectorEmissionTree
   */
  public getNh3ForChart(): IChartTree | undefined {
    const newTree = this.updateNh3Data(this.nh3PerSector)
    return newTree;
  }

  private updateNh3Data(tree: Array<INh3EmissionSectorResultDto>) : IChartTree {
    const chartTree = this.getChartTree(tree);
    const iterateRecursice = (e: any) => {
      if (e.children) {
        e.value = 0;
        e.children.forEach((element: any) => (e.value += iterateRecursice(element)));
      }
      return e.value;
    };
    iterateRecursice(chartTree);
    return chartTree;
  }

    /**
   *  return the D3 hierarchy structure with children instead of any key as child
   * @param data
   */
     public getChartTree(data: INh3EmissionSectorResultDto[]): IChartTree {
      const newData = [] as any[];
    
      for (const sectorData of data) {
        const sectorResult = sectorData.result[0];
    
        newData.push({
          field: "afolu",
          ...this.getChartTreeRecursive(sectorResult),
        });
      }
  
      return { field: "co2Total", children: newData.filter((item) => item.children) };
    }
    
    public getChartTreeRecursive(data: ICo2EmissionSectorNode | ICo2EmissionSectorLeaf): IChartTree {
      if (!data) {
        return {};
      }
      // it is leaf
      if (data["value"] !== undefined) {
        return { value: (data as ICo2EmissionSectorLeaf).value, quality: (data as ICo2EmissionSectorLeaf).quality };
      }
  
      const childrenNames = Object.keys(data).filter((e) => e !== "value" && e !== "quality" && e !== "year");
      // its a node
      if (childrenNames.length) {
        const newEntry = { children: [] };
        for (const childName of childrenNames) {
          // @ts-ignore
          newEntry.children.push({ field: `${childName}`, ...this.getChartTreeRecursive(data[childName]) });
        }
        return newEntry;
      }
      // fallback
      return {};
    }
  
}
