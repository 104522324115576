import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IRegionIdentifier } from "../util.service";
import { EMPTY, Observable, of } from "rxjs";
import { acceptJsonWithoutCache } from "../../state/common";
import { catchError, map } from "rxjs/operators";
import {
  IDataInputRow,
  IDataInputSector,
  IDataInputSubSector
} from "./interfaces/region-property-data-input.interface";
import { ITableData, ITableDataCell } from "../../models/data-panel/table-data.model";
import { environment } from "../../../environments/environment";
import { RegionTypes } from "../../core/enum/region-types.enum";
import { Unit } from "../../models/data-panel";
import { MainTableCategories } from "../../../../../../shared/src/lib/data-input/model/main-table-categories.enum";

@Injectable({
  providedIn: "root"
})

/**
 * Gateway to the region property service for input tables. Can query the general
 * structure for the tables of a sector. Can read and write the table content.
 */
export class RegionPropertyDataInputService {
  constructor(private http: HttpClient) {}

  public getSectorConfig(sector: MainTableCategories): Observable<IDataInputSector> {
    const params = new HttpParams({
      fromObject: {}
    });
    const url = `${environment.regionPropertyApi}/datainput/config/${sector}`;

    return this.http
      .get<IConfigDto>(url, { headers: new HttpHeaders(acceptJsonWithoutCache), params })
      .pipe(
        map((res) => this.toSectorConfig(sector, res)),
        catchError(() => EMPTY)
      );
  }

  private toSectorConfig(name: string, config: IConfigDto): IDataInputSector {
    const subsectors: IDataInputSubSector[] = [];
    for (const sub of config.tables) {
      const subsector = this.toSubsector(sub);
      subsectors.push(subsector);
    }
    return { name: name, subsectors: subsectors, yearsFrom: config.firstYear, yearsTo: config.lastYear };
  }

  private toSubsector(sub: ITableConfigDto): IDataInputSubSector {
    const rows: IDataInputRow[] = [];
    const parent: string | undefined = undefined;
    const inRows: IRowConfigDto[] = sub.rows;
    this.copyRows(inRows, rows, parent);
    const units: Unit[] = [];
    for (const unit of sub.units) {
      units.push(new Unit("DATA_PANEL." + unit.name.toUpperCase(), unit.name, unit.factorFromDefault));
    }
    return { name: sub.name, rows: rows, units: units };
  }

  private copyRows(inRows: IRowConfigDto[], rows: IDataInputRow[], parent: string | undefined): void {
    for (const row of inRows) {
      rows.push({ isParent: row.isGroup, name: row.name, parentName: parent, rowId: row.path });
      if (row.isGroup) {
        this.copyRows(row.children, rows, row.name);
      }
    }
  }

  public getTableData(
    { regionId, regionType }: IRegionIdentifier,
    sector: MainTableCategories,
    subsector: string
  ): Observable<ITableData> {
    if (!regionId || !regionType || regionType === RegionTypes.UNION) {
      return EMPTY;
    }
    const params = new HttpParams({
      fromObject: {
        regionId,
        regionType,
        sector,
        subsector
      }
    });
    const url = `${environment.regionPropertyApi}/datainput/data`;

    return this.http
      .get<ITableDataDto>(url, { headers: new HttpHeaders(acceptJsonWithoutCache), params })
      .pipe(
        map((res) => this.toTableData(res)),
        catchError(() => EMPTY)
      );
  }

  public setTableData(
    { regionId, regionType }: IRegionIdentifier,
    sector: string,
    subsector: string,
    data: ITableData
  ): Observable<Result> {
    if (!regionId || !regionType || regionType === RegionTypes.UNION) {
      return of<Result>(Result.failure);
    }

    const params = new HttpParams({
      fromObject: {
        regionId,
        regionType,
        sector,
        subsector
      }
    });
    const url = `${environment.regionPropertyApi}/datainput/data`;

    return this.http
      .patch(url, this.toTableDataDto(data), { headers: new HttpHeaders(acceptJsonWithoutCache), params })
      .pipe(
        map(() => Result.success),
        catchError(() => of<Result>(Result.failure))
      );
  }

  private toTableData(dto: ITableDataDto): ITableData {
    return { cells: dto.cells.map((l) => this.toCell(l)) };
  }

  private toTableDataDto(data: ITableData): ITableDataDto {
    return { cells: data.cells.map((l) => this.toCellDto(l)) };
  }

  private toCell(cell: ITableCellDto): ITableDataCell {
    return { hasValue: cell.hasValue, quality: cell.quality, rowId: cell.path, value: cell.value, year: cell.year };
  }

  private toCellDto(cell: ITableDataCell): ITableCellDto {
    return { hasValue: cell.hasValue, path: cell.rowId, quality: cell.quality, value: cell.value, year: cell.year };
  }
}

export enum Result {
  success,
  failure,
  failureRowMissing
}

interface IConfigDto {
  firstYear: number;
  lastYear: number;
  tables: ITableConfigDto[];
}

interface ITableConfigDto {
  name: string;
  rows: IRowConfigDto[];
  units: IUnitDto[];
}

interface IUnitDto {
  name: string;
  factorFromDefault: number;
}

interface IRowConfigDto {
  name: string;
  path: string;
  isGroup: boolean;
  children: IRowConfigDto[];
}

interface ITableDataDto {
  cells: ITableCellDto[];
}

interface ITableCellDto {
  path: string;
  hasValue: boolean;
  value: number;
  quality: number;
  year: number;
}
