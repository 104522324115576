import { SideNavigationActionType, SidenavigationActions } from "./sidenavigation-actions";

export function reducer(state = [], action: SidenavigationActions) {
  switch (action.type) {
    case SideNavigationActionType.SIDE_NAVIGATION_EVENT:
      return action;
    case SideNavigationActionType.SIDE_NAVIGATION_INIT:
      return action;
    case SideNavigationActionType.CHANGE_PANEL_STATE:
      return action;
    case SideNavigationActionType.ERROR:
      return action;
    default:
      return state;
  }
}
