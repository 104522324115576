import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneTooltipDirective } from "./tooltip.directive";
import { EneTooltipComponent } from "./tooltip.component";

@NgModule({
  declarations: [EneTooltipDirective, EneTooltipComponent],
  imports: [CommonModule],
  exports: [EneTooltipDirective]
})
export class EneTooltipModule {}
