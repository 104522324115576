import { Login } from "./login.class";
import { ILogin, IAuthenticationObject } from "../global.interface";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

export class AutoLogin extends Login {
  constructor(http: HttpClient, config: ILogin) {
    super(http, config);
  }

  protected _login(): Observable<IAuthenticationObject> {
    return <Observable<IAuthenticationObject>>this.http.get(this.config.serviceUrl);
  }
}
