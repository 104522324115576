import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HierarchyTable, HierarchyTableRow } from "../factor-table.interface";

@Component({
  selector: "co2-hierarchy-table",
  templateUrl: "./hierarchy-table.component.html",
  styleUrls: ["./hierarchy-table.component.scss"]
})
export class HierarchyTableComponent {
  @Input() public table?: HierarchyTable;

  public currentLang: string;
  public displayedColumns = ["description", "value"];

  private destroy$ = new Subject();

  constructor(private translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
  }
  ngOnInit(): void {
    this.subscribeLanguageChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public hasUnit(table: HierarchyTable): boolean {
    return table.unit != null && table.unit !== "";
  }

  public isHeader(row: HierarchyTableRow): boolean {
    // the row is a header row if it has neither value nor percentage defined
    return row.value == null && row.percentage == null;
  }

  public hasValue(row: HierarchyTableRow): boolean {
    return row.value != null;
  }

  public hasPercentage(row: HierarchyTableRow): boolean {
    return row.percentage != null;
  }

  public getLevelClass(row: HierarchyTableRow): string {
    return `level${row.indentationLevel}`;
  }

  private subscribeLanguageChanges(): void {
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.currentLang = event.lang;
    });
  }
}
