import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { filter, take } from "rxjs/operators";
import { inputIsNotNullOrUndefined } from "../../common/utils/util-rxjs";
import { Observable } from "rxjs";
import { TenantMapConfig } from "../model/tenant-map-config";
import { CONFIG_DATA_SERVICE_TOKEN, IConfigDataService } from "../../common/config/config-data.interface";

@Injectable({
  providedIn: "root"
})
export class TenantMapConfigService {
  private config: BehaviorSubject<TenantMapConfig | undefined> = new BehaviorSubject<TenantMapConfig | undefined>(
    undefined
  );

  constructor(@Inject(CONFIG_DATA_SERVICE_TOKEN) private configDataService: IConfigDataService) {
    this.configDataService
      .getConfig()
      .pipe(take(1))
      .subscribe((config) => this.config.next(new TenantMapConfig(config.tenantMapConfig)));
  }

  getTenantConfig(): Observable<TenantMapConfig> {
    return this.config.asObservable().pipe(filter(inputIsNotNullOrUndefined));
  }
}
