import { Injectable } from "@angular/core";
import { EneKpisV2Component } from "@energy-city/ui/kpis-v2";

@Injectable()
export class ChartsKpiService {
  private componentRef: EneKpisV2Component;

  public getKpiItems(): any {
    return this.componentRef?._items[0].items;
  }

  public setComponentRef(componentRef: EneKpisV2Component): void {
    this.componentRef = componentRef;
  }

  public getComponentRef(): EneKpisV2Component {
    return this.componentRef;
  }
}
