import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  forwardRef
} from "@angular/core";
import { PopperContentComponent } from "@energy-city/ui/popper";
import { BehaviorSubject, Observable } from "rxjs";
import { EneEditComponentChange } from "../../abstracts/EneEditComponentChange";
import { EneEditComponentMode } from "../../abstracts/EneEditComponentMode";

@Component({
  selector: "ene-dqi",
  templateUrl: "./dqi.component.html",
  styleUrls: ["./dqi.component.scss"],
  providers: [
    {
      provide: EneEditComponentChange,
      useExisting: forwardRef(() => EneDqiComponent)
    },
    {
      provide: EneEditComponentMode,
      useExisting: forwardRef(() => EneDqiComponent)
    }
  ],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-dqi"
  }
})
/**
 * TODO: remove deprecated stuff -> BREAKING CHANGE
 */
export class EneDqiComponent
  implements EneEditComponentMode<number>, EneEditComponentChange<number>, OnInit, OnChanges, OnDestroy {
  @ViewChild("dqiPopper", { static: true })
  private dqiPopper: PopperContentComponent;

  @Input() public dqi: number;
  @Input() public item: any;
  /**
   * still usable but input decorator should be removed, since editModeActive is implemented
   * @deprecated
   */
  @Input() public disabled: boolean = false;
  /**
   * @deprecated
   */
  @Output() public change: EventEmitter<number> = new EventEmitter();
  @Output() public dqiChange: EventEmitter<number> = new EventEmitter();

  public cachedDQI: number;

  public sliderConfig: any = {
    pips: {
      mode: "count",
      values: 6,
      density: 100
    }
  };

  private _dataChange = new BehaviorSubject<number | undefined>(undefined);
  public get editModeActive(): boolean {
    return this.disabled;
  }
  public set editModeActive(editModeActive: boolean) {
    this.disabled = !editModeActive;
  }
  public set value(value: number) {
    this.dqi = value;
  }
  public get value(): number {
    if (this._dataChange.value === undefined) {
      return this.dqi;
    } else {
      return this._dataChange.value;
    }
  }

  public ngOnInit() {
    this.dqi = this.checkDQIType(this.dqi);
    this.cachedDQI = this.dqi;
  }
  /**
   * triggered by range slider
   * @param event event from rangeslider (as number)
   */
  public onChange(event: any) {
    setTimeout(() => {
      this.dqiPopper.hide();
    }, 300);
    this.dqi = event;
    if (this.dqi !== this.cachedDQI) {
      this.change.emit(event);
      this.dqiChange.emit(event);
      this._dataChange.next(event);
      this.cachedDQI = this.dqi;
    }
  }
  /**
   * triggered by changes from angular and does check the type and update the cached value
   * @param changes delivered by angular
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.dqi !== undefined) {
      this.dqi = this.checkDQIType(changes.dqi.currentValue);
      this.cachedDQI = this.dqi;
    }
  }
  /**
   * check if it is a number and if not returns 0
   * @param dqi take any type
   */
  private checkDQIType(dqi: any) {
    if (!Number.isInteger(dqi)) {
      return 0;
    } else {
      return dqi;
    }
  }

  public changeValue(newValue: number) {
    this.dqi = this.checkDQIType(newValue);
    this.cachedDQI = this.dqi;
  }

  public connect(): Observable<number | undefined> {
    return this._dataChange;
  }

  public disconnect(): void {
    this._dataChange.complete();
  }

  public ngOnDestroy() {
    this.disconnect();
  }
}
