<div *ngFor="let item of _items; trackBy: trackByFn" class="row ene-kpis-group ene-kpis-group-large">
  <gp-kpi
    *ngFor="let subItem of item.items; trackBy: trackByFn"
    [kpiTitle]="subItem.title | translate"
    [unitConfig]="statesMap[item.id] == 0 ? undefined : getTranslatedUnitConfig(subItem.unitConfig)"
    [value]="subItem.value"
    [icon]="subItem.icon"
    [disabled]="subItem.disabled"
  >
    <ng-container *ngTemplateOutlet="spinner"></ng-container>

    <app-weather-correction-indicator
      slot="indicator"
      class="indicator"
      *ngIf="hasWeatherCorrection(subItem) && statesMap[item.id]"
      [weatherCorrection]="true"
    ></app-weather-correction-indicator>
  </gp-kpi>
</div>

<ng-template #spinner>
  <div class="ene-spinner">
    <span></span>
    <span></span>
    <span></span>
  </div>
</ng-template>

<ng-template #pendingTitle>
  <span class="loading">Loading...</span>
</ng-template>
