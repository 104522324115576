import { Injectable } from "@angular/core";
import { IRegionIdentifier as LegacyRegionIdentifier, UtilService } from "@energy-city/components";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Region } from "@enersis/gp-components/interfaces";
import { IRegion, IRegionIdentifier } from "../model/region-identifier";
import { RegionType } from "../model/region-type.enum";
import { ISelectedRegionService } from "./selected-region.interface";
import { IMainRegion, MainRegionService } from "../../map/services/main-region.service";

@Injectable({
  providedIn: "root"
})
export class SelectedRegionStandaloneService implements ISelectedRegionService {
  private mainRegion: IMainRegion;
  constructor(private utilService: UtilService, private mainRegionService: MainRegionService) {
    this.mainRegion = this.mainRegionService.getMainRegion();
    this.utilService.setRegionId(this.mainRegion.regionId, this.mainRegion.regionType);
  }

  getSelectedRegion(): Observable<IRegion> {
    return this.utilService.regionIdentifier$.pipe(
      map((regionIdentifier: LegacyRegionIdentifier) => {
        return {
          regionId: regionIdentifier.regionId,
          regionType: this.mapRegionType(regionIdentifier.regionType),
          coordinates: regionIdentifier.regionId === this.mainRegion.regionId ? this.mainRegion.coordinates : undefined
        };
      })
    );
  }

  getSelectedRegionIdentifier(): Observable<IRegionIdentifier> {
    return this.utilService.regionIdentifier$.pipe(
      map((regionIdentifier: LegacyRegionIdentifier) => {
        return { regionId: regionIdentifier.regionId, regionType: this.mapRegionType(regionIdentifier.regionType) };
      })
    );
  }

  setSelectedRegion(regionIdentifier: IRegionIdentifier): void {
    const regionIdStore = this.utilService.getRegionId();
    // to avoid unwanted update circles, only updated if the new region is different
    // from the currently selected one.
    if (regionIdentifier.regionId !== regionIdStore) {
      this.utilService.setRegionId(regionIdentifier.regionId, regionIdentifier.regionType);
    }
  }

  getRegionChangedInHeader(): Observable<Region> {
    // The standalone application has no header and thus, we only have to return
    // an observable that never completes...
    return new Observable();
  }

  private mapRegionType(regionType: string): RegionType {
    switch (regionType) {
      case "basic":
        return RegionType.BASIC;
      case "union":
        return RegionType.UNION;
      default:
        throw Error(`Unsupported region type ${regionType}`);
    }
  }
}
