import { InjectionToken } from "@angular/core";

export interface IFooterLink {
  name: string;
  content?: string;
  content_title?: string;
  link?: boolean;
  type?: "contact" | "contact-restricted" | "footer_information";
  sizing?: "oversize" | "medium" | "small";
  watermark?: string;
  website?: string;
  propType?: "modal" | "route" | "link" | "text" | "website";
  itemType?: string;
}

export interface IFooterConfigs {
  logo: string;
  links: Array<IFooterLink>;
  theme: string;
  companyNameFooter: string;
}

export class FooterConfigs {
  public static getFormattedData({ auth, app }: any): IFooterConfigs {
    return {
      logo: auth.logo_footer,
      links: app.footer || [],
      theme: app.theme.name,
      companyNameFooter: app.companyNameFooter
    };
  }
}

export const DYNAMIC_FOOTER_DATA = new InjectionToken<any>("DYNAMIC_FOOTER_DATA");
