import { IUnitOption } from "../../../../../../configs/data-panel";
import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { Store } from "@ngrx/store";
import * as fromDataPanel from "../../../../state";

@Component({
  selector: "app-units-filter",
  templateUrl: "./units-filter.component.html",
  styleUrls: ["./units-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitsFilterComponent {
  @Input()
  public options: Array<IUnitOption>;

  @Input()
  public set value(id) {
    if (id) {
      this.changeUnit(id);
    }
  }

  public disabled: boolean;
  public label: string;
  public _value: string;

  constructor(private store: Store<fromDataPanel.DataPanelState>) {}

  private changeUnit(unitId) {
    this._value = unitId;
    const foundItem = this.options.find((option) => option.id === unitId);
    this.store.dispatch(new fromDataPanel.UpdateUnit(foundItem));
  }

  public onSelectionChange({ value }: MatSelectChange): void {
    this.changeUnit(value);
  }

  public trackByFn(_: number, value: any): string {
    return value.id;
  }
}
