import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";

import { switchMap, map, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { of } from "rxjs";
import {
  SetsActionTypes,
  GetSets,
  GetSetsSuccess,
  GetSetsError,
  SaveSetError,
  EditSet,
  SaveSetSuccess,
  CreateSet,
  DeleteSet,
  DeleteSetSuccess,
  DeleteSetError
} from "./sets.actions";

@Injectable()
export class SetsEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  @Effect()
  public getMeasuresSets = this.actions$.pipe(
    ofType(SetsActionTypes.GET_SETS),
    switchMap((action: GetSets) => {
      return this.http.get<{ result: Array<any> }>(`${environment.simulation}/regions/${action.regionId}/sets`).pipe(
        map(({ result }) => new GetSetsSuccess(result)),
        catchError((err) => of(new GetSetsError(err)))
      );
    })
  );

  @Effect()
  public createMeasureSet = this.actions$.pipe(
    ofType(SetsActionTypes.CREATE_SET),
    switchMap((action: CreateSet) => {
      const setObj = { title: action.form.title, description: action.form.description };
      return this.http.post<{ result }>(`${environment.simulation}/regions/${action.regionId}/sets`, setObj).pipe(
        map(({ result: { url } }) => new SaveSetSuccess(url.split("/").slice(-1)[0])),
        catchError((err) => of(new SaveSetError(err)))
      );
    })
  );

  @Effect()
  public editMeasureSet = this.actions$.pipe(
    ofType(SetsActionTypes.EDIT_SET),
    switchMap((action: EditSet) => {
      const setObj = { title: action.form.title, description: action.form.description };
      return this.http
        .patch(`${environment.simulation}/regions/${action.regionId}/sets/${action.form.id}`, setObj)
        .pipe(
          map(() => new SaveSetSuccess()),
          catchError((err) => of(new SaveSetError(err)))
        );
    })
  );

  @Effect()
  public deleteMeasureSet = this.actions$.pipe(
    ofType(SetsActionTypes.DELETE_SET),
    switchMap((action: DeleteSet) => {
      return this.http.delete(`${environment.simulation}/regions/${action.regionId}/sets/${action.set.setId}`).pipe(
        map(() => new DeleteSetSuccess()),
        catchError(() => of(new DeleteSetError()))
      );
    })
  );
}
