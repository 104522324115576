import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActiveAccountingService } from "./services/active-accounting.service";
import { TranslateModule } from "@ngx-translate/core";
import { CockpitConfigService } from "./panel-config/cockpit-config.service";
import { ApiEndpointService } from "../../common/utils/api-endpoint.service";
import { API_ENDPOINT_SERVICE_TOKEN } from "../../common/utils/api-endpoint.interface";
import { UtilsModule } from "../../common/utils/utils.module";
import { ActiveAccountingStandaloneService } from "./services/active-accounting-standalone.service";

@NgModule({
  providers: [
    { provide: API_ENDPOINT_SERVICE_TOKEN, useClass: ApiEndpointService },
    ActiveAccountingService,
    ActiveAccountingStandaloneService,
    CockpitConfigService
  ],
  imports: [CommonModule, TranslateModule, UtilsModule]
})
export class AccountingCommonModule {}
