import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { acceptJsonWithoutCache } from "../../common";
import {
  GasNetworkActionTypes,
  GasNetworkLength,
  GasNetworkLengthSuccess,
  GasNetworkLengthError
} from "./network-actions";

@Injectable()
export class GasNetworkEffects {
  @Effect()
  public gasNetworkLength$ = this.actions$.pipe(
    ofType(GasNetworkActionTypes.GET_GAS_NETWORK_LENGTH),
    switchMap((action: GasNetworkLength) => {
      const url: string = `${environment.infrastructure.gridApi}/networks/${action.payload}/segments/length`;
      const headers = new HttpHeaders(acceptJsonWithoutCache);

      return this.http.get(url, { headers }).pipe(
        map((data: any) => new GasNetworkLengthSuccess(data.result)),
        catchError((error) => of(new GasNetworkLengthError(error)))
      );
    })
  );

  constructor(private http: HttpClient, private actions$: Actions) {}
}
