import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneIconModule } from "@energy-city/ui/icon";
import { DeprecatedEneRangeSliderModule, EneFormsModule } from "@energy-city/ui/forms";
import { EneFilterComponent } from "./components/filter.component";

@NgModule({
  declarations: [EneFilterComponent],
  imports: [CommonModule, EneIconModule, EneFormsModule, TranslateModule, DeprecatedEneRangeSliderModule],
  exports: [EneFilterComponent]
})
export class EneFilterModule {}
