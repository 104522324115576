<div class="ene-modal-container">
  <div [ngClass]="{ 'ene-modal-body': true }" [class]="sizing">
    <img *ngIf="watermark" [src]="watermark" />
    <header *ngIf="title">
      <ene-icon icon="ec_building_delete_address" class="closebutton" *ngIf="closebutton" (click)="close()"> </ene-icon>
      {{ title }}
    </header>
    <section [ngClass]="{ 'ene-font-small': true, 'no-scroll': disableScroll }" [class]="'content-' + sizing">
      <ng-content></ng-content>
    </section>
  </div>
</div>
<div class="ene-modal-background" (click)="close()"></div>
