<gp-kpi
  data-test="kpi-co2Reduction"
  [kpiTitle]="'KPI.CO2_REDUCTION' | translate"
  icon="gpi-filled-co2-emissions-period"
  [value]="absoluteChange$ | async"
  [unitConfig]="{
    category: 'weight',
    base: 't',
    omitUnits: ['Mt', 'Gt'],
    numberFormat: {
      maximumFractionDigits: 1,
      signDisplay: 'always'
    }
  }"
>
  <app-weather-correction-indicator
    slot="indicator"
    [weatherCorrection]="(accounting$ | async)?.weatherCorrection"
  ></app-weather-correction-indicator>
</gp-kpi>
