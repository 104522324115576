import { OnInit, Inject, Directive } from "@angular/core";
import { IFooterLink, IFooterConfigs, DYNAMIC_FOOTER_DATA } from "./footer";
import { FooterService } from "../footer.service";

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class Footer implements OnInit {
  public logo = "logo-placeholder.png";
  public links: Array<IFooterLink> = [];

  constructor(@Inject(DYNAMIC_FOOTER_DATA) protected data: IFooterConfigs, private footerService: FooterService) {}

  protected config(): void {
    // is implemented in some subclasses
  }
  public ngOnInit(): void {
    this.logo = `/assets/images/logos/${this.data.logo}`;
    this.links = this.data.links;
    this.config();
  }

  public onClick(value: any): void {
    this.footerService.setOutputValue(value);
  }

  public trackByFn(index: number): number {
    return index;
  }
}
