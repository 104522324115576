import { Inject, Injectable } from "@angular/core";
import { RegionType } from "../../common/model/region-type.enum";
import { ITokenService, TOKEN_SERVICE_TOKEN } from "../../common/utils/token.interface";

export interface IMainRegion {
  regionId: string;
  regionType: RegionType;
  coordinates: [number, number];
  level: number;
  zoomLevel?: number;
}

@Injectable({
  providedIn: "root"
})
export class MainRegionService {
  constructor(@Inject(TOKEN_SERVICE_TOKEN) private tokenService: ITokenService) {}

  getMainRegion(): IMainRegion {
    const token = this.tokenService.getParsedCo2BalanceToken();
    if (!token.mainRegion?.id) {
      throw Error("No regionId provided for the mainRegion");
    }
    if (!token.mainRegion?.lat || !token.mainRegion?.lon) {
      throw Error("No or incomplete coordinates");
    }
    return {
      regionId: token.mainRegion?.id,
      regionType: token.mainRegion?.regionType || RegionType.BASIC,
      coordinates: [Number(token.mainRegion?.lat), Number(token.mainRegion?.lon)],
      level: token.mainRegion?.level || 60,
      zoomLevel: token.mainRegion?.zoomLevel
    };
  }
}
