/* tslint:disable:member-access */

import { Action } from "@ngrx/store";
export enum ResetActionType {
  RESET_UPDATE = "[Reset] RESET_UPDATE",
  ERROR = "[Reset] ERROR"
}

export class ResetUpdate implements Action {
  type = ResetActionType.RESET_UPDATE;
  constructor(public payload: any) {}
}

export class ResetError implements Action {
  type = ResetActionType.ERROR;
  constructor(public payload: any) {}
}

export type ResetActions = ResetUpdate | ResetError;
