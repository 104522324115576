<div class="table-wrapper">
  <table mat-table [dataSource]="data" *ngIf="{ isEditing: factorManagementService.isEditing$ | async } as service">
    <ng-container *ngFor="let column of columnsConfig" [matColumnDef]="column.id" [sticky]="column.sticky">
      <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>

      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="column.sticky || column.translateAccessor; else numericCell">
          {{ column.translateAccessor ? column.translateAccessor(row[column.id]) : row[column.id] }}
        </ng-container>

        <ng-template #numericCell>
          <ng-container *ngIf="!service.isEditing">
            {{ row[column.id] * row.scalingFactor | formatUnit }}
          </ng-container>

          <gp-number-input
            *ngIf="service.isEditing"
            [numberValue]="row[column.id] * row.scalingFactor"
            [unitConfig]="unitConfig"
            (valueChange)="updateValue($event.detail / row.scalingFactor, row, column.id)"
          >
          </gp-number-input>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
