<label class="title">
  <ng-content></ng-content>
</label>
<nouislider
  #nouislider
  [disabled]="disabled"
  [behaviour]="behaviour"
  [connect]="connect"
  [limit]="limit"
  [min]="min"
  [max]="max"
  [step]="step"
  [format]="format"
  [pageSteps]="pageSteps"
  [config]="config"
  [(ngModel)]="ngModel"
  [keyboard]="keyboard"
  [onKeydown]="onKeydown"
  [tooltips]="tooltips"
  (change)="onChange($event)"
>
</nouislider>
