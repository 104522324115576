/**
 * # Template example:
 *
 *  <app-radio-group name="dsafsa" [options]="radioOptions" (change)="onChange($event)">
 *    <app-radio-button value="with_icon_and_text">
 *      <ene-icon icon="ec_witterungskorrektur"></ene-icon>
 *      <span>TEST 1</span>
 *    </app-radio-button>
 *    <app-radio-button value="value2">Test title 2</app-radio-button>
 *    <app-radio-button value="value3" checked="true">Test title 3</app-radio-button>
 *  </app-radio-group>
 *
 * # Module import:
 *
 * @NgModule({
 *  imports: [
 *    SharedModule
 *  ]
 * })
 * export class Module { }
 */

export interface IOptions {
  checkbox: boolean;
}

import {
  Component,
  AfterContentInit,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  QueryList,
  ContentChildren,
  forwardRef,
  ChangeDetectorRef,
  Optional,
  Inject,
  Host
} from "@angular/core";

@Component({
  selector: "app-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonComponent {
  @Input()
  public set name(value: string) {
    this._name = value;
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input()
  public get value() {
    return this._value;
  }
  public set value(val: any) {
    this._value = val;
  }

  @Input()
  public set checked(value: boolean) {
    this._checked = value;
  }

  @Input()
  public set options(value: IOptions) {
    this._options = value;
  }

  public _checked = false;
  public _disabled = false;
  public _name: string;
  public _options: IOptions;
  private _value: any;

  constructor(
    @Host()
    @Optional()
    // tslint:disable-next-line: no-use-before-declare
    @Inject(forwardRef(() => RadioGroupComponent))
    public radioGroup: RadioGroupComponent,
    private cdr: ChangeDetectorRef
  ) {}

  public onChange(event: Event): void {
    event.stopPropagation();

    if (this.radioGroup) {
      this.radioGroup.emitChange(this._value);
    }
  }

  public markForCheck(): void {
    this.cdr.markForCheck();
  }

  public detectChanges(): void {
    this.cdr.detectChanges();
  }
}

@Component({
  // tslint:disable-next-line: directive-selector
  selector: "app-radio-group",
  exportAs: "appRadioGroup",
  template: "<ng-content></ng-content>",
  // tslint:disable-next-line:use-host-property-decorator no-host-metadata-property
  host: {
    role: "radiogroup",
    class: "radio-group"
  }
})
export class RadioGroupComponent implements AfterContentInit {
  @Input()
  public set name(value: string) {
    this._name = value;
  }

  @Input()
  public set options(value: IOptions) {
    this._options = value;
  }

  @ContentChildren(forwardRef(() => RadioButtonComponent), { descendants: true })
  private buttons: QueryList<RadioButtonComponent>;

  @Output()
  public readonly change = new EventEmitter<any>();

  private _options: IOptions;
  private _name: string;
  private isInitialized = false;

  public ngAfterContentInit(): void {
    this.isInitialized = true;
    this.updateRadioButtonsNames();
  }

  public emitChange(value: any): void {
    if (!this.isInitialized) {
      return;
    }

    this.buttons.forEach((button) => {
      button.checked = button.value === value;
      button.markForCheck();
    });

    this.change.emit(value);
  }

  private updateRadioButtonsNames(): void {
    if (!this.buttons) {
      return;
    }

    this.buttons.forEach((button) => {
      button.name = this._name;
      button.options = this._options;
      button.detectChanges();
    });
  }
}
