import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { AuthenticationService } from "../../modules/authentication/services/exported/authentication.service";
import { AccountingMethodService } from "@energy-city/components";
import { IGNORE, ENERSIS_SERVICES } from "./interceptor.config";
import { MixType } from "../../configs/accounting-method";

function fillMissingParam(req: HttpRequest<unknown>, paramName: string, fallbackValue: string): HttpRequest<unknown> {
  const existingParam = req.params?.get(paramName);
  return req.clone({
    params: (req.params ? req.params : new HttpParams()).set(paramName, String(existingParam || fallbackValue))
  });
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private weatherCorrection: boolean;
  private localMix: boolean;

  constructor(public authService: AuthenticationService, private accountingMethodService: AccountingMethodService) {
    this.accountingMethodService.selectedMixType$.subscribe((mixType: MixType) => {
      this.localMix = mixType === MixType.LOCAL;
    });

    this.accountingMethodService.weatherCorrection$.subscribe((weatherCorrection: boolean) => {
      this.weatherCorrection = weatherCorrection;
    });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isUrlAllowed(request.url)) {
      console.log(`[${request.url}] was dispatched, but returned by the interceptor`);
      return of(new HttpResponse({ status: 200, body: {} }));
    } else {
      if (this.isEnersisServices(request.url)) {
        // sanitize request params: all params that are in the url should be in the params object
        request = request.clone({
          url: request.url.split("?")[0],
          params: new HttpParams({ fromString: request.urlWithParams.split("?")[1] })
        });

        // add weatherCorrection and localMix params if they are not already present
        request = fillMissingParam(request, "weatherCorrection", String(!!this.weatherCorrection));
        request = fillMissingParam(request, "localMix", String(!!this.localMix));

        // Requests that are calculated with user-defined factors should never
        // be cached, because the factors can change at any time, even if the
        // request itself doesn't change:
        const useUserFactors = request.params.get("useUserFactors") as string | boolean;
        if (useUserFactors === "true" || useUserFactors === true) {
          request = request.clone({
            setHeaders: {
              "Cache-Control": "no-cache",
              Pragma: "no-cache"
            }
          });
        }
      }
      // s3 rejects request with our token
      if (
        this.authService.loggedIn &&
        !request.url.includes("enersis-assets") &&
        !request.url.includes("assets.s3.eu-central")
      ) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.accessToken}`
          }
        });
      }
      return next.handle(request);
    }
  }

  private isUrlAllowed(url: string): boolean {
    let returnable: boolean = true;
    IGNORE.forEach((key: string) => {
      if (url.includes(key)) {
        returnable = false;
      }
    });
    return returnable;
  }

  private isEnersisServices(url: string): boolean {
    if (url.match(ENERSIS_SERVICES) && !url.includes("config-service")) {
      return true;
    }
    return false;
  }
}
