import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, share } from "rxjs/operators";
import { IRegionIdentifier } from "../../../../common/model/region-identifier";
import { ICo2EmissionSectorResultDto } from "../../../accounting-emission/data/dto/co2-emission-dto.interface";
import { EmissionDataService } from "../../../accounting-emission/data/emission-data.service";
import { ElectricityMix } from "../electricity-mix.enum";
import { WeatherCorrection } from "../weather-correction.enum";
import { ICo2EmissionSectorResult, MainGhgSector } from "./main-ghg-sector.interface";
import { Sector } from "./sector.enum";

export class IppuSector implements MainGhgSector {
  constructor(private emissionDataService: EmissionDataService) {}

  sector: Sector = Sector.IPPU;

  private $co2EmissionsForYear?: Observable<ICo2EmissionSectorResult>;
  private $totalCo2EmissionsForYear?: Observable<ICo2EmissionSectorResult>;
  private $totalCo2EmissionTimeSeries?: Observable<ICo2EmissionSectorResult>;

  getCo2EmissionsForYear(
    region: IRegionIdentifier,
    year: number,
    useUserFactors: boolean,
    _weatherCorrection: WeatherCorrection,
    _electricityMix: ElectricityMix
  ): Observable<ICo2EmissionSectorResult> {
    if (!this.$co2EmissionsForYear) {
      const params = this.getParams(region, 2, useUserFactors, year);
      this.$co2EmissionsForYear = this.emissionDataService
        .getEmissions("/emissions/co2/ippu", params)
        .pipe(map(this.mapResult), share());
    }
    return this.$co2EmissionsForYear;
  }

  getTotalCo2EmissionsForYear(
    region: IRegionIdentifier,
    year: number,
    useUserFactors: boolean,
    _weatherCorrection: WeatherCorrection,
    _electricityMix: ElectricityMix
  ): Observable<ICo2EmissionSectorResult> {
    if (!this.$totalCo2EmissionsForYear) {
      const params = this.getParams(region, 0, useUserFactors, year);
      this.$totalCo2EmissionsForYear = this.emissionDataService
        .getEmissions("/emissions/co2/ippu", params)
        .pipe(map(this.mapResult), share());
    }
    return this.$totalCo2EmissionsForYear;
  }

  getTotalCo2EmissionTimeSeries(
    region: IRegionIdentifier,
    useUserFactors: boolean,
    _weatherCorrection: WeatherCorrection,
    _electricityMix: ElectricityMix
  ): Observable<ICo2EmissionSectorResult> {
    if (!this.$totalCo2EmissionTimeSeries) {
      const params = this.getParams(region, 0, useUserFactors);
      this.$totalCo2EmissionTimeSeries = this.emissionDataService
        .getEmissions("/emissions/co2/ippu", params)
        .pipe(map(this.mapResult), share());
    }
    return this.$totalCo2EmissionTimeSeries;
  }

  private getParams(region: IRegionIdentifier, depth: number, useUserFactors: boolean, year?: number): HttpParams {
    let params = new HttpParams();
    params = params.append("regionId", region.regionId).append("regionType", region.regionType).append("depth", depth);

    if (useUserFactors) {
      params = params.append("useUserFactors", "true");
    }
    if (year !== undefined) {
      params = params.append("year", year);
    }
    return params;
  }

  private mapResult(result: ICo2EmissionSectorResultDto): ICo2EmissionSectorResult {
    return {
      sector: Sector.IPPU,
      ...result
    };
  }
}
