/* tslint:disable:no-unused-expression */
/* tslint:disable:no-input-rename */
/* tslint:disable:member-access */
/* tslint:disable:member-ordering */
/* tslint:disable:directive-selector */
/* tslint:disable:directive-class-suffix */

import {
  Directive,
  HostListener,
  ComponentRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  SimpleChange,
  OnDestroy,
  Output,
  EventEmitter,
  OnInit,
  Renderer2,
  ChangeDetectorRef,
  Inject
} from "@angular/core";
import { Placement, Placements, PopperContentOptions, Trigger, Triggers } from "../models/popper.model";
import { PopperContentComponent } from "../components/popper-content.component";

@Directive({
  selector: "[popper]",
  exportAs: "popper"
})
export class PopperDirective implements OnInit, OnChanges, OnDestroy {
  private popperContentClass = PopperContentComponent;
  private popperContentRef: ComponentRef<PopperContentComponent>;
  private shown: boolean = false;
  private scheduledShowTimeout: any;
  private scheduledHideTimeout: any;
  private subscriptions: any[] = [];
  private globalClick: any;
  private globalScroll: any;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    @Inject("popperDefaults") private popperDefaults: PopperContentOptions = {}
  ) {
    PopperDirective.baseOptions = { ...PopperDirective.baseOptions, ...this.popperDefaults };
  }

  public static baseOptions: PopperContentOptions = <PopperContentOptions>{
    placement: Placements.Auto,
    hideOnClickOutside: true,
    hideOnScroll: false,
    showTrigger: Triggers.HOVER
  };

  @Input("popper")
  content: string | PopperContentComponent;

  @Input("popperDisabled")
  disabled: boolean;

  @Input("popperPlacement")
  placement: Placement;

  @Input("popperTrigger")
  showTrigger: Trigger | undefined;

  @Input("popperTarget")
  targetElement: HTMLElement;

  @Input("popperDelay")
  showDelay: number = 0;

  @Input("popperTimeout")
  hideTimeout: number = 0;

  @Input("popperTimeoutAfterShow")
  timeoutAfterShow: number = 0;

  @Input("popperBoundaries")
  boundariesElement: string;

  @Input("popperShowOnStart")
  showOnStart: boolean;

  @Input("popperCloseOnClickOutside")
  closeOnClickOutside: boolean;

  @Input("popperHideOnClickOutside")
  hideOnClickOutside: boolean | undefined;

  @Input("popperHideOnScroll")
  hideOnScroll: boolean | undefined;

  @Input("popperPositionFixed")
  positionFixed: boolean;

  @Input("popperModifiers")
  popperModifiers: {};

  @Input("popperDisableStyle")
  disableStyle: boolean;

  @Input("popperDisableAnimation")
  disableAnimation: boolean;

  @Input("popperForceDetection")
  forceDetection: boolean;

  @Output()
  popperOnShown = new EventEmitter<PopperDirective>();

  @Output()
  popperOnHidden = new EventEmitter<PopperDirective>();

  @HostListener("touchstart")
  @HostListener("click")
  showOrHideOnClick(): void {
    if (this.disabled || this.showTrigger !== Triggers.CLICK) {
      return;
    }
    this.toggle();
  }

  @HostListener("touchstart")
  @HostListener("mousedown")
  showOrHideOnMouseOver(): void {
    if (this.disabled || this.showTrigger !== Triggers.MOUSEDOWN) {
      return;
    }
    this.toggle();
  }

  @HostListener("mouseenter")
  showOnHover(): void {
    if (this.disabled || this.showTrigger !== Triggers.HOVER) {
      return;
    }
    this.scheduledShow();
  }

  hideOnClickOutsideHandler($event: MouseEvent): void {
    if (this.disabled || !this.hideOnClickOutside) {
      return;
    }
    this.scheduledHide($event, this.hideTimeout);
  }

  hideOnScrollHandler($event: MouseEvent): void {
    if (this.disabled || !this.hideOnScroll) {
      return;
    }
    this.scheduledHide($event, this.hideTimeout);
  }

  @HostListener("touchend")
  @HostListener("touchcancel")
  @HostListener("mouseleave")
  hideOnLeave(): void {
    if (this.disabled || this.showTrigger !== Triggers.HOVER) {
      return;
    }
    this.scheduledHide(null, this.hideTimeout);
  }

  static assignDefined(target: any, ...sources: any[]) {
    for (const source of sources) {
      for (const key of Object.keys(source)) {
        const val = source[key];
        if (val !== undefined) {
          target[key] = val;
        }
      }
    }
    return target;
  }

  ngOnInit() {
    // Support legacy prop
    this.hideOnClickOutside =
      typeof this.hideOnClickOutside === "undefined" ? this.closeOnClickOutside : this.hideOnClickOutside;

    if (typeof this.content === "string") {
      const text = this.content;
      this.content = this.constructContent();
      this.content.text = text;
    }
    const popperRef = this.content as PopperContentComponent;
    popperRef.referenceObject = this.getRefElement();
    this.setContentProperties(popperRef);
    this.setDefaults();

    if (this.showOnStart) {
      this.scheduledShow();
    }
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if (changes["popperDisabled"]) {
      if (changes["popperDisabled"].currentValue) {
        this.hide();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe && sub.unsubscribe());
    this.subscriptions.length = 0;
    this.clearEventListeners();
  }

  toggle() {
    this.shown ? this.scheduledHide(null, this.hideTimeout) : this.scheduledShow();
  }

  show() {
    if (this.shown) {
      this.overrideHideTimeout();
      return;
    }

    this.shown = true;
    const popperRef = this.content as PopperContentComponent;
    const element = this.getRefElement();
    if (popperRef.referenceObject !== element) {
      popperRef.referenceObject = element;
    }
    this.setContentProperties(popperRef);
    popperRef.show();
    this.popperOnShown.emit(this);
    if (this.timeoutAfterShow > 0) {
      this.scheduledHide(null, this.timeoutAfterShow);
    }
    this.globalClick = this.renderer.listen("document", "click", this.hideOnClickOutsideHandler.bind(this));
    this.globalScroll = this.renderer.listen(
      this.getScrollParent(this.getRefElement()),
      "scroll",
      this.hideOnScrollHandler.bind(this)
    );
  }

  hide() {
    if (!this.shown) {
      this.overrideShowTimeout();
      return;
    }

    this.shown = false;
    if (this.popperContentRef) {
      this.popperContentRef.instance.hide();
    } else {
      (this.content as PopperContentComponent).hide();
    }
    this.popperOnHidden.emit(this);
    this.clearEventListeners();
  }

  scheduledShow(delay: number = this.showDelay) {
    this.overrideHideTimeout();
    this.scheduledShowTimeout = setTimeout(() => {
      this.show();
      this.applyChanges();
    }, delay);
  }

  scheduledHide($event: any = null, delay: number = 0) {
    this.overrideShowTimeout();
    this.scheduledHideTimeout = setTimeout(() => {
      const toElement = $event ? $event.toElement : null;
      const popperContentView = (this.content as PopperContentComponent).popperViewRef
        ? (this.content as PopperContentComponent).popperViewRef.nativeElement
        : false;
      if (
        !popperContentView ||
        popperContentView === toElement ||
        popperContentView.contains(toElement) ||
        (this.content as PopperContentComponent).isMouseOver
      ) {
        return;
      }
      this.hide();
      this.applyChanges();
    }, delay);
  }

  getRefElement() {
    return this.targetElement || this.viewContainerRef.element.nativeElement;
  }

  private applyChanges() {
    this.changeDetectorRef.markForCheck();
    if (this.forceDetection) {
      this.changeDetectorRef.detectChanges();
    }
  }

  private setDefaults() {
    this.showTrigger = typeof this.showTrigger === "undefined" ? PopperDirective.baseOptions.trigger : this.showTrigger;
    this.hideOnClickOutside =
      typeof this.hideOnClickOutside === "undefined"
        ? PopperDirective.baseOptions.hideOnClickOutside
        : this.hideOnClickOutside;
    this.hideOnScroll =
      typeof this.hideOnScroll === "undefined" ? PopperDirective.baseOptions.hideOnScroll : this.hideOnScroll;
  }

  private clearEventListeners() {
    this.globalClick && typeof this.globalClick === "function" && this.globalClick();
    this.globalScroll && typeof this.globalScroll === "function" && this.globalScroll();
  }

  private overrideShowTimeout() {
    if (this.scheduledShowTimeout) {
      clearTimeout(this.scheduledShowTimeout);
      this.scheduledHideTimeout = 0;
    }
  }

  private overrideHideTimeout() {
    if (this.scheduledHideTimeout) {
      clearTimeout(this.scheduledHideTimeout);
      this.scheduledHideTimeout = 0;
    }
  }

  private constructContent(): PopperContentComponent {
    const factory = this.resolver.resolveComponentFactory(this.popperContentClass);
    this.popperContentRef = this.viewContainerRef.createComponent(factory);
    return this.popperContentRef.instance as PopperContentComponent;
  }

  private setContentProperties(popperRef: PopperContentComponent) {
    popperRef.popperOptions = PopperDirective.assignDefined(popperRef.popperOptions, PopperDirective.baseOptions, {
      disableAnimation: this.disableAnimation,
      disableDefaultStyling: this.disableStyle,
      placement: this.placement,
      boundariesElement: this.boundariesElement,
      trigger: this.showTrigger,
      positionFixed: this.positionFixed,
      popperModifiers: this.popperModifiers
    });
    this.subscriptions.push(popperRef.onHidden.subscribe(this.hide.bind(this)));
  }

  private getScrollParent(node: any): any {
    const isElement = node instanceof HTMLElement;
    const overflowY = isElement && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

    if (!node) {
      return null;
    } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
      return node;
    }

    return this.getScrollParent(node.parentNode) || document;
  }
}
