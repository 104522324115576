<app-page-wrapper [title]="title | translate" [activeDqi]="false">
  <h3>{{ "PRINT_VIEW.GENERAL_PAGE.USED_PARAMETERS" | translate }}</h3>
  <ol>
    <li>
      {{ "DATA_PANEL.ACCOUNTING_PARAMETERS.FACTORS.TITLE" | translate }}: {{ useUserFactorTranslationKey | translate }}
    </li>
  </ol>
  <div
    class="general-info__container"
    [innerHTML]="text | translate: { applicationName: applicationName, logo: logo, assetUrl: assetUrl }"
  ></div>
</app-page-wrapper>
