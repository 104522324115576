import { Component, Optional } from "@angular/core";
import { HelpTabChange, PopupService } from "@energy-city/components";
import { EneModalService } from "@energy-city/ui/modal";
import { Store } from "@ngrx/store";
import * as fromRoot from "@energy-city/components";
import { GlossaryService } from "@energy-city/co2balance-components/services";

@Component({
  selector: "app-data-input-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class DataInputFooterComponent {
  constructor(
    private modalService: EneModalService,
    public store: Store<fromRoot.IApplicationState>,
    private popup: PopupService,
    @Optional() private glossary: GlossaryService
  ) {}

  public onClickDQI(): void {
    if (this.glossary) {
      this.glossary.openTab(1);
    } else {
      const action = new HelpTabChange("calculation_logic.#datenguete");
      this.store.dispatch(action);
      this.popup.openGlossaryAtAnchor("calculation_logic.#datenguete");
    }
  }

  public onClickSource(): void {
    if (this.glossary) {
      this.glossary.openTab(2);
    } else {
      const action = new HelpTabChange("DATA_BASIS.#efStationaereEnergie");
      this.store.dispatch(action);
      this.popup.openGlossaryAtAnchor("DATA_BASIS.#efStationaereEnergie");
    }
  }

  public onClickComment(): void {
    this.modalService.open("data-input_comment");
  }
}
