import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NguCarousel, NguCarouselConfig } from "@ngu/carousel";
import { EneModalService } from "@energy-city/ui/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { select, Store } from "@ngrx/store";
import {
  IApplicationState,
  IMeasure,
  CreateMeasure,
  EditMeasure,
  getMeasures,
  GetMeasures,
  SetEditingMeasure,
  DeleteMeasure,
  UtilService,
  CreateSet,
  DeleteSet,
  EditSet,
  GetSets,
  getMeasureError,
  saveMeasureSuccess,
  getSets,
  saveSetSuccess
} from "@energy-city/components";
import { filter, takeUntil } from "rxjs/operators";
import { MODES, translateMap } from "../../../constants/measures";
import { IMeasureSector, MeasuresService } from "./measures.service";
import { EneSnackbarService } from "@energy-city/ui/snackbar";
import { TranslateService } from "@ngx-translate/core";

@Component({
  /* tslint:disable:component-selector */
  selector: "panel-measures",
  templateUrl: "./measures.component.html",
  styleUrls: ["./measures.component.scss"],
  providers: [MeasuresService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelMeasuresComponent implements OnInit, OnDestroy {
  public mode: MODES = MODES.READ;
  public sets: Array<any> = [];
  public translateMap = translateMap;
  public measures: Array<IMeasureSector>;
  public carouselConfig: NguCarouselConfig = this.measuresService.carouselConfig;
  public measureSetFormGroup: FormGroup;
  public activeSet: any;
  public newSetId: string;
  @ViewChild("carousel", { static: true }) private carousel: NguCarousel<any>;
  private measureMode: MODES;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private eneModalService: EneModalService,
    private store: Store<IApplicationState>,
    private utilService: UtilService,
    private _formBuilder: FormBuilder,
    private snackbar: EneSnackbarService,
    private measuresService: MeasuresService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.measureSetFormGroup = this._formBuilder.group({
      id: [""],
      title: ["", Validators.required],
      description: ["", Validators.required]
    });

    this.store
      .pipe(
        select(getMeasureError),
        filter((err) => !!err),
        takeUntil(this.destroy$)
      )
      .subscribe((err) => {
        this.snackbar.error(err.message);
      });

    this.store
      .pipe(
        select(saveMeasureSuccess),
        filter((err) => !!err),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.store.dispatch(new GetMeasures(this.activeSet.setId, this.utilService.getRegionId()));
        this.snackbar.success(this.translate.instant("MEASURE.SAVE_MEASURE_SUCCESS"));
      });

    this.store
      .pipe(
        select(saveSetSuccess),
        filter((message) => !!message),
        takeUntil(this.destroy$)
      )
      .subscribe(({ message, newSetId }) => {
        this.newSetId = newSetId;
        this.store.dispatch(new GetSets(this.utilService.getRegionId()));
        this.snackbar.success(message);
      });

    this.store
      .pipe(
        select(getSets),
        filter((sets) => !!sets && !!sets.length),
        takeUntil(this.destroy$)
      )
      .subscribe((sets) => {
        this.sets = sets.sort((a, b) => (a.setId > b.setId ? 1 : -1));
        this.cdr.detectChanges();
        const currentSetIdx = this.sets.findIndex((item) =>
          this.newSetId ? item.setId === this.newSetId : item.setId === this.activeSet?.setId
        );

        if (!this.activeSet || currentSetIdx === -1) {
          this.activeSet = this.sets[0];
          this.carousel.moveTo(0, false);
        } else {
          this.activeSet = this.sets[currentSetIdx];
          this.carousel.moveTo(currentSetIdx, false);
        }

        this.measureSetFormGroup.patchValue({ id: this.activeSet.setId });
        this.activeSet.measuresLoading = true;
        this.store.dispatch(new GetMeasures(this.activeSet.setId, this.utilService.getRegionId()));
      });

    this.store
      .pipe(
        select(getMeasures),
        filter((data) => !!data),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.measures = this.measuresService.bySectors(data.measures);
        this.activeSet.measuresLoading = false;
        this.cdr.detectChanges();
      });

    this.utilService.regionId$
      .pipe(
        filter((regionId) => !!regionId),
        takeUntil(this.destroy$)
      )
      .subscribe((regionId) => {
        this.store.dispatch(new GetSets(regionId));
      });
  }

  public onMove(e): void {
    this.activeSet.measuresLoading = false;
    this.activeSet = this.sets[e.currentSlide];
    this.activeSet.measuresLoading = true;

    this.store.dispatch(new GetMeasures(this.activeSet.setId, this.utilService.getRegionId()));
    this.measureSetFormGroup.patchValue({ id: this.activeSet.setId });
    this.setReadMode();
  }

  public isReadMode(set): boolean {
    return !set || this.mode === MODES.READ || this.activeSet.setId !== set.setId;
  }

  public isCreateMode(): boolean {
    return this.mode === MODES.CREATE;
  }

  public onChangeSlidetoggle(state, measure): void {
    this.store.dispatch(new SetEditingMeasure(measure));
    this.store.dispatch(new EditMeasure({ active: state }, this.activeSet.setId, this.utilService.getRegionId()));
  }

  public onEditSet(set) {
    if (set.default) {
      return;
    }
    this.measureSetFormGroup.patchValue({
      id: set.setId,
      title: this.translate.instant(set.title),
      description: set.description
    });
    this.mode = MODES.EDIT;
  }

  public onCreateSet() {
    this.mode = MODES.CREATE;
  }

  public setReadMode() {
    this.mode = MODES.READ;
    this.measureSetFormGroup.reset();
  }

  public saveMeasureSet() {
    switch (this.mode) {
      case MODES.CREATE:
        this.store.dispatch(new CreateSet(this.measureSetFormGroup.value, this.utilService.getRegionId()));
        break;
      case MODES.EDIT:
        this.store.dispatch(new EditSet(this.measureSetFormGroup.value, this.utilService.getRegionId()));
        break;
    }
    this.setReadMode();
  }

  public openMeasureEditor(item?) {
    this.store.dispatch(new SetEditingMeasure(item));
    this.measureMode = item ? MODES.EDIT : MODES.CREATE;
    this.eneModalService.open("measure_editor");
  }

  public onMeasureSave(data) {
    [data.minYear, data.maxYear] = data.timerange;
    delete data.timerange;
    if (this.measureMode === MODES.EDIT) {
      this.store.dispatch(new EditMeasure(data, this.activeSet.setId, this.utilService.getRegionId()));
    } else {
      this.store.dispatch(new CreateMeasure(data, this.activeSet.setId, this.utilService.getRegionId()));
    }
    this.store.dispatch(new SetEditingMeasure(null));
    this.eneModalService.close("measure_editor");
  }

  public trackByMeasures(_, item: IMeasureSector) {
    return item.sector;
  }
  public trackByMeasureItem(_, item: IMeasure) {
    return item.measure_id;
  }

  public onDeleteSet(set, idx) {
    if (set.default) {
      return;
    }
    this.store.dispatch(new DeleteSet(set, this.utilService.getRegionId()));
  }

  public onDeleteMeasure(measure) {
    this.store.dispatch(new DeleteMeasure(this.activeSet.setId, measure.measure_id, this.utilService.getRegionId()));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
