import { Injectable } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { EneKpisV2Service } from "@energy-city/ui/kpis-v2";
import { get, mergeWith } from "lodash";
import { Store, select } from "@ngrx/store";
import * as fromRoot from "../../../../state/index";
import { MainPolluter, SECTORS_LIST, ROOT_KEY } from "./main-polluter.model";
import { EnergyCarrierService } from "../../../../modules/co2-cockpit/charts/final-energy/energy-carrier/energy-carrier.service";
import { GreenhouseGasesService } from "../../../../modules/co2-cockpit/charts/greenhouse-gases/energy-related/greenhouse-gases.service";
import { AccountingMethodService } from "../../../../services/accounting-method.service";
import { ChartsKpiService } from "../../../../services/charts-kpi.service";
import {
  IFormatterResult,
  SectorType,
  KPI_NAMESPACE,
  MAIN_POLLUTER_APIS,
  MainPolluterDataType,
  EnergyType
} from "../../../../models/charts.models";

export const DATA_PATH = "[0].data";
export const CHART_DATA_PATH = `series[0].data`;
export const DRILL_UP_BUTTON = "CHARTS.DRILL_UP";

@Injectable()
export class MainPolluterService {
  private mainPolluter: MainPolluter;
  private dqiSubject = new BehaviorSubject(0);

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private kpiService: EneKpisV2Service,
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
    private chartsKpiService: ChartsKpiService,
    private greenhouseGasesService: GreenhouseGasesService,
    private energyCarrierService: EnergyCarrierService,
    private accountingMethodService: AccountingMethodService
  ) {
    this.mainPolluter = new MainPolluter(this.translate, this.decimalPipe);
  }

  public getOptions(dataType: MainPolluterDataType): any {
    return this.mainPolluter.getOptions(dataType);
  }

  public getDQI(year, chartApi) {
    const service = chartApi === MAIN_POLLUTER_APIS.CO2 ? this.greenhouseGasesService : this.energyCarrierService;
    service.getDqi(
      year,
      this.accountingMethodService.weatherCorrection$.value,
      this.accountingMethodService.selectedMixType$.value,
      EnergyType.FINAL
    )
      .subscribe((val) => this.dqiSubject.next(val));
  }

  public getSeries(sector: SectorType): Observable<any> {
    this.updateKpiTitles(sector);
    this.kpiService.pending(KPI_NAMESPACE);
    switch (sector) {
      case SectorType.TOTAL_BALANCE: {
        return this.selectAllData(sector);
      }
      case SectorType.TRANSPORTATION: {
        return this.selectTransportationData(sector);
      }
      default: {
        return this.selectStationaryEnergyData(sector);
      }
    }
  }

  private updateKpiTitles(sector: SectorType): void {
    const items = this.chartsKpiService.getKpiItems();
    const isTotalBalance = sector === SectorType.TOTAL_BALANCE;

    switch (items[2].id) {
      case "greenhouse_gases_co2_main_reason_transportation_total":
        items[2].title = isTotalBalance ? "KPI.CO2_TRANSPORTATION_TOTAL" : "KPI.CO2_TRANSPORTATION";
        break;
      case "greenhouse_gases_ps_main_reason_transportation_total":
        items[2].title = isTotalBalance
          ? "KPI.ENERGY_CONSUMPTION.TRANSPORTATION_TOTAL"
          : "KPI.ENERGY_CONSUMPTION.TRANSPORTATION";
        break;
    }
  }

  public getDqi(): Subject<number> {
    return this.dqiSubject;
  }

  public resetDqi(): void {
    this.dqiSubject.next(null);
  }

  public dispatchData(props: any): void {
    this.store.dispatch(new fromRoot.GetMainPolluterAllData(props));
  }

  public getRadioButtons(): Array<string> {
    return SECTORS_LIST;
  }

  public getLegendFormatter(): (event: any, chart: any) => IFormatterResult {
    return function (event: any, chart: any) {
      const { level } = event.point.node;
      let drillUpButton = "";
      let drill;

      if (level === 1) {
        drill = ROOT_KEY;
      } else {
        const { drillId, parent = ROOT_KEY, id } = event.point;
        const isEqualId = drillId === parent;

        drill = isEqualId ? id : parent;
        drillUpButton = isEqualId ? DRILL_UP_BUTTON : "";
      }

      const data = get(chart, CHART_DATA_PATH, []);
      const items = data.reduce((acc, item) => {
        const { name, color } = item;
        const _item = { name, color };

        return item.parent === drill && item.value ? [...acc, _item] : [...acc];
      }, []);

      return { items, drillUpButton };
    };
  }

  private selectAllData(sector: SectorType): Observable<any> {
    return this.store.pipe(
      select(fromRoot.getAllData),
      map(({ stationaryEnergy, transportation }) => this.mergeData({ ...stationaryEnergy, ...transportation })),
      map((data) => this.mainPolluter.getSeries(data, sector)),
      tap((series) => this.runMappingKpi(series))
    );
  }

  private selectStationaryEnergyData(sector: SectorType): Observable<any> {
    return this.store.pipe(
      select(fromRoot.getStationaryEnergyDataBySector, { sector }),
      map((data) => this.mainPolluter.getSeries(data, sector)),
      tap((series) => this.runMappingKpi(series))
    );
  }

  private selectTransportationData(sector: SectorType): Observable<any> {
    return this.store.pipe(
      select(fromRoot.getTransportationData),
      map((data) => this.mergeData(data)),
      map((data) => this.mainPolluter.getSeries(data, sector)),
      tap((series) => this.runMappingKpi(series))
    );
  }

  private runMappingKpi(series): void {
    const initial = {
      electricity: 0,
      transportation: 0,
      heat: 0
    };
    const data = get(series, DATA_PATH, []);
    const values = data.reduce((acc, item: any) => {
      switch (item.parent) {
        case `${ROOT_KEY}.electricity`: {
          return {
            ...acc,
            electricity: acc.electricity + item.value
          };
        }
        case `${ROOT_KEY}.transportation`: {
          return {
            ...acc,
            transportation: acc.transportation + item.value
          };
        }
        case `${ROOT_KEY}.heat`: {
          return {
            ...acc,
            heat: acc.heat + item.value
          };
        }
        default: {
          return acc;
        }
      }
    }, initial);

    this.kpiService.runMapping(values, KPI_NAMESPACE);
  }

  private mergeData(data: object): any {
    const keys = Object.keys(data);

    return keys.reduce((acc, key) => {
      const customizer = (curr = {}, next) => {
        return mergeWith(curr, next, (_curr = 0, _next) => _curr + _next);
      };
      const merged = mergeWith(acc, data[key], customizer);

      return { ...acc, ...merged };
    }, {});
  }
}
