import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EneChartService, HIGHCHARTS_MODULES } from "./services/chart.service";
import { EneChartComponent } from "./components/chart.component";

import * as more from "highcharts/highcharts-more";
import * as exporting from "highcharts/modules/exporting";
import * as solid from "highcharts/modules/solid-gauge";
import * as sunburst from "highcharts/modules/sunburst";
import * as noDataToDisplay from "highcharts/modules/no-data-to-display";
import * as patternFill from "highcharts/modules/pattern-fill";

// external highcharts plugins has to be packed within a function so you can provide them
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [more, exporting, solid, sunburst, noDataToDisplay, patternFill];
}

@NgModule({
  declarations: [EneChartComponent],
  providers: [{ provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }, EneChartService],
  imports: [CommonModule],
  exports: [EneChartComponent]
})
export class EneChartModule {
  public static forRoot(): ModuleWithProviders<EneChartModule> {
    return {
      ngModule: EneChartModule,
      providers: [{ provide: HIGHCHARTS_MODULES, useFactory: highchartsModules }]
    };
  }
}
