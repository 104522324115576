<ene-chart #chart type="" [data]="chartData" [settings]="chartConfig"></ene-chart>
<div class="custom-legend">
  <div class="legend-item-group">
    <div class="legend-item-title interpolated">
      <div><span></span> {{ "COCKPIT.CO2.TABS.TREND.LEGEND.HISTORICAL_DATA" | translate }}</div>
    </div>
    <div class="legend-item-container">
      <ng-container *ngFor="let item of chartData">
        <div class="legend-item" *ngIf="item.showInLegend">
          <span class="colorbox" [ngStyle]="{ background: item.color }"></span>
          <label>{{ "COCKPIT.CO2.TABS.TREND.LEGEND." + item.name | translate }} </label>
        </div>
      </ng-container>
    </div>
  </div>
</div>
