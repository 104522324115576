import { AppActionType, AppActions } from "./app-actions";

export function reducer(state = [], action: AppActions) {
  switch (action.type) {
    case AppActionType.UPDATE_TRANSLATIONS:
    case AppActionType.UPDATE_TRANSLATIONS_LOADED:
    case AppActionType.UPDATE_TRANSLATIONS_SUCCESS:
    case AppActionType.GET_CLIENT_CONFIG:
    case AppActionType.GET_CLIENT_CONFIG_SUCCESS:
    case AppActionType.GET_CLIENT_MAPCONFIG:
    case AppActionType.GET_CLIENT_MAPCONFIG_SUCCESS:
      return action;
    case AppActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
