import { Component, OnInit, Input, ChangeDetectionStrategy } from "@angular/core";
import { IPopper, POPPER_CONFIGS } from "../../models";

@Component({
  selector: "app-launchpad-popover",
  templateUrl: "./launchpad-popover.component.html",
  styleUrls: ["./launchpad-popover.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaunchpadPopoverComponent implements OnInit {
  public popper: IPopper;
  public folderPath = "/assets/launchpad/";

  @Input() public links: Array<object> = [];

  public ngOnInit(): void {
    this.popper = { ...POPPER_CONFIGS };
  }

  public trackByFn(index: number): number {
    return index;
  }
}
