import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ModuleSwitchService {
  public isActive$: Observable<boolean>;
  private isActiveSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isActive$ = this.isActiveSubject.asObservable();
  }

  public setActive(value: boolean): void {
    this.isActiveSubject.next(value);
  }
}
