export enum EmissionsFactors {
  STATIONARY_ENERGY = "stationary-energy",
  TRANSPORTATION = "transportation",
  AGRICULTURE = "afolu",
  WASTE = "waste",
  GLOBAL = "global"
}

export enum FactorEmissionsTables {
  STATIONARY_ENERGY = "stationaryEnergy",
  TRANSPORTATION = "transportation",
  LAND_USE = "landUse",
  ANIMAL_HUSBANDRY = "animalHusbandry",
  BIOWASTE = "biowaste",
  PROTEIN_INTAKE = "proteinIntake",
  WASTEWATER = "wasteWater",
  SEWAGE_SLUDGE = "sewageSludge",
  GLOBAL_FACTORS = "globalWarmingPotential"
}

export enum LandUseTableColumns {
  TYPE_OF_USE = "typeOfUse",
  EMISSION_FACTOR = "emission_factor"
}

export enum AnimalHusbandryTableColumns {
  ANIMAL_SPECIES = "animal_species",
  DIGESTION = "digestion",
  FARM_MANURE_CH4 = "manureMgmtCh4",
  FARM_MANURE_N2O = "manureMgmtN2o"
}

export enum GlobalFactorsTableColumns {
  CH4 = "Ch4",
  N2O = "N2o"
}
