// core imports
import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneUiPipesModule } from "@energy-city/ui/pipes";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneKpisComponent } from "./ui-kpis.component";

/**
 * See README for more details and tutorials
 */
@NgModule({
  imports: [CommonModule, EneIconModule, EneUiPipesModule, TranslateModule],
  exports: [EneKpisComponent],
  declarations: [EneKpisComponent],
  providers: [DecimalPipe, CurrencyPipe]
})
export class EneKpisModule {}
