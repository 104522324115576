<ng-container *ngIf="!isEnlarged; else goBack">
  <div class="ene-surface-box-flat">
    <header>
      <h3 class="chartTitle">{{ "COCKPIT.CO2.CHARTS.SECTORS.TITLE" | translate }}</h3>
      <button class="ene-button-cta" (click)="togglePanelState(true)">
        {{ "COCKPIT.GAS.FIXED.OPEN_ENLARGE" | translate | uppercase }}
      </button>
    </header>

    <div class="mainflex">
      <div class="chart-container-override clickable" (click)="togglePanelState(true)">
        <ene-chart type="sunburst" [data]="Co2SectorSeries" [settings]="Co2SectorConfig" [defaultColors]="false" #chart>
        </ene-chart>
        <div class="sunburst-legend">
          <div class="sunburst-legend-text">
            {{ customLegendName | translate }}
            <br />
            <b>
              {{ customLegendAbsolute | number: "1.0-0" : locale }}
              <span [innerHTML]="'COCKPIT.CO2.CHARTS.SECTORS.UNIT' | translate"></span>
            </b>
            <br />
            <b>{{ customLegendRelative | number: "1.1-1" : locale }}%</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #goBack>
  <div class="ene-surface-box-flat" (click)="togglePanelState(false)">
    <div class="ene-button-raised" class="go-back">
      <ene-icon icon="ec_controls_collapse_panel" class="close_cockpit"></ene-icon>
      <span>{{ "COCKPIT.CO2.CHARTS.SECTORS.BACK_TO_COMPACT_VIEW" | translate }}</span>
    </div>
  </div>
</ng-template>
