/* tslint:disable:member-ordering */

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { switchMap, map, withLatestFrom, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { IRegionIdentifier, UtilService } from "../../../services/util.service";
import { BenchmarkActions, GetQualitiveBenchmarkSuccess } from ".";
import { forkJoin, of, EMPTY, Observable } from "rxjs";
import { SECTORS_NAMES_BENCHMARKING, EnergyTypes } from "../../../configs/accounting-method";
import { AccountingMethodService } from "../../../services/accounting-method.service";
import { GetQuantitiveBenchmarkSuccess } from "./benchmark-actions";
import { RegionPropertyKpiService } from "../../../services/region-property/region-property-kpi.service";
import { sectorConfig } from "../../../models/co2-sectors-config.model";

@Injectable()
export class BenchmarkEffects {
  constructor(
    private utilService: UtilService,
    private accountingMethodService: AccountingMethodService,
    private actions$: Actions,
    private http: HttpClient,
    private kpisService: RegionPropertyKpiService
  ) {}

  @Effect() public getQualitiveBenchmark$ = this.actions$.pipe(
    ofType(BenchmarkActions.GET_QUALITIVE_DATA),

    switchMap((measures) => {
      const custom = measures;
      return of(new GetQualitiveBenchmarkSuccess({ data: { custom } }));
    })
  );

  @Effect() public getQuantitiveBenchmark$ = this.actions$.pipe(
    ofType(BenchmarkActions.GET_QUANTITIVE_DATA),
    switchMap(() => {
      return this.http
        .get<Array<any>>(`${environment.infrastructure.regionApi}/regions/${this.utilService.getRegionId()}/parents/id`)
        .pipe(
          switchMap((regions: any) => {
            const flattenDeep = (data: any): Array<any> => {
              const result = [];
              while (data) {
                result.push({
                  regionId: data.region_id,
                  level: data.level
                });
                data = data.parent;
              }
              return result;
            };
            const flatRegions = flattenDeep(regions.result);

            return forkJoin(
              flatRegions.map((parentRegion: { regionId: string; level: number }) => {
                const regionIdentifier: IRegionIdentifier = {
                  regionId: parentRegion.regionId,
                  /* TODO: will need the correct region-type, but there is no endpoint yet
                  which I can ask what the region-type for the given regionId is. */
                  regionType: "basic"
                };
                return this.getDataForRegion(regionIdentifier, parentRegion.level);
              })
            ).pipe(map((data) => new GetQuantitiveBenchmarkSuccess({ data })));
          }),
          catchError(() => EMPTY)
        );
    })
  );

  private getDataForRegion(regionIdentifier: IRegionIdentifier, regionLevel: number) {
    const year = String(this.utilService.getSelectedYear());
    const params = new HttpParams()
      .set("year", `${year}`)
      .set("useUserFactors", `${this.accountingMethodService.useUserFactors$.getValue()}`);
    const sectors = SECTORS_NAMES_BENCHMARKING[this.accountingMethodService.selectedAccountingMethod$.value];

    const requests: {
      [key in EnergyTypes]?: Observable<any>;
    } = sectorConfig.sectors
      .filter((config) => sectors.includes(config.type))
      .reduce((acc, sector) => {
        const url = sector
          .service()
          .concat(
            sector.getEndpointRoute(regionIdentifier.regionId, 0, regionIdentifier.regionType, sector.energyType)
          );
        return {
          ...acc,
          [sector.type]: this.http.get(`${url}`, { params }).pipe(catchError(() => of(null)))
        };
      }, {});

    return forkJoin({ ...requests, kpis: this.kpisService.getRegionProperties(regionIdentifier, year) }).pipe(
      map(({ kpis, ...results }) => {
        const population = kpis.population;

        const data = Object.entries(results)
          .map(([sector, response]: [string, { result: Array<any> }]) => {
            const fallbackResult = { quality: 0, value: 0, year: this.utilService.getSelectedYear() };
            const result = response?.result?.[0] ?? fallbackResult;
            const valueProCapitaOrZero = population > 0 ? result.value / population : 0;
            return {
              sector,
              valueAndQuality: {
                value: valueProCapitaOrZero,
                quality: result.quality,
                year: result.year
              } as { value: number; quality: number; year: number }
            };
          })
          .reduce((acc, { sector, valueAndQuality }) => {
            return {
              ...acc,
              [sector]: valueAndQuality
            };
          }, {});

        return { level: regionLevel, data };
      })
    );
  }
}
