import { Component, OnInit } from "@angular/core";
import { ContactsService } from "../contacts.service";
import { Observable } from "rxjs";
import { Person } from "../contacts.model";
import { ContactType } from "@energy-city/components";

@Component({
  selector: "app-contacts-container",
  templateUrl: "./contacts-container.component.html",
  styleUrls: ["./contacts-container.component.scss"]
})
export class ContactsContainerComponent implements OnInit {
  public currentComponent$: Observable<ContactType>;
  public contactInfo: Person;
  public ContactType = ContactType;
  public components = [
    {
      name: "APP.CONTACTS",
      type: ContactType.CONTACTS
    },
    {
      name: "APP.FEEDBACK_FORM",
      type: ContactType.FEEDBACK
    },
    {
      name: "APP.REPORT_ERROR",
      type: ContactType.ERROR
    }
  ];

  constructor(private contactsService: ContactsService) {}

  public ngOnInit(): void {
    this.currentComponent$ = this.contactsService.selectedContactComponent$;
    this.contactInfo = this.contactsService.contactInfo;
  }

  public checkBox(type): void {
    this.contactsService.setCurrentComponent(type);
  }
}
