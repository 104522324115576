export interface IUser {
  given_name: string;
  family_name: string;
  email: string;
}

export interface ILanguage {
  name: string;
  code: string;
}

export interface IUserConfigs extends IUser {
  allowedLanguages: Array<string>;
  langCode: string;
}

export const PUBLIC_USER: IUser = {
  given_name: "Not",
  family_name: "logged in",
  email: "not@loggged.in"
};

export const DEFAULT_LANGUAGES: Array<ILanguage> = [
  { name: "Deutsch (DE)", code: "de-de" },
  { name: "Deutsch (CH)", code: "de-ch" },
  { name: "Français", code: "fr-fr" },
  { name: "English", code: "en-us" }
];
