import { Action } from "@ngrx/store";
import { IRegionIdentifier } from "../../../../services/util.service";

export enum DatabaseChartActionTypes {
  GET_KPI = "[DatabaseChart] GET_KPI",
  GET_KPI_SUCCESS = "[DatabaseChart] GET_KPI_SUCCESS",
  GET_KPI_ERROR = "[DatabaseChart] GET_KPI_ERROR",
  GET_DATA = "[DatabaseChart] GET_DATA",
  GET_DATA_SUCCESS = "[DatabaseChart] GET_DATA_SUCCESS",
  CLEAR_DATA = "[DatabaseChart] CLEAR_DATA"
}

export class GetDatabaseChartData implements Action {
  public readonly type = DatabaseChartActionTypes.GET_DATA;
  constructor(public regionIdentifier: IRegionIdentifier) {}
}

export class GetDatabaseChartDataSuccess implements Action {
  public readonly type = DatabaseChartActionTypes.GET_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class ClearDatabaseChartData implements Action {
  public readonly type = DatabaseChartActionTypes.CLEAR_DATA;
}

export class GetSpecificEmissionFactor implements Action {
  public readonly type = DatabaseChartActionTypes.GET_KPI;
  constructor(public regionIdentifier: IRegionIdentifier, public chartType: string) {}
}

export class GetSpecificEmissionFactorSuccess implements Action {
  public readonly type = DatabaseChartActionTypes.GET_KPI_SUCCESS;
  constructor(public regionId: string, public payload: any) {}
}

export class GetSpecificEmissionFactorError implements Action {
  public readonly type = DatabaseChartActionTypes.GET_KPI_ERROR;
  constructor(public regionId: string) {}
}

export type DatabaseChartActions =
  | GetDatabaseChartData
  | ClearDatabaseChartData
  | GetSpecificEmissionFactorSuccess
  | GetSpecificEmissionFactorError
  | GetDatabaseChartDataSuccess;
