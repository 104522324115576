<ng-container *ngIf="table">
  <table class="factors" cdk-table [dataSource]="table.entries">
    <!-- Description Column -->
    <ng-container [cdkColumnDef]="displayedColumns[0]">
      <th cdk-header-cell *cdkHeaderCellDef>{{ "PRINT_VIEW.EMISSIONS_FACTORS.FACTOR" | translate }}</th>
      <td cdk-cell *cdkCellDef="let element">{{ element.description | translate }}</td>
    </ng-container>
    <!-- Unit Column -->
    <ng-container [cdkColumnDef]="displayedColumns[1]">
      <th cdk-header-cell *cdkHeaderCellDef>{{ "PRINT_VIEW.EMISSIONS_FACTORS.UNIT" | translate }}</th>
      <td cdk-cell *cdkCellDef="let element">{{ element.unit | translate }}</td>
    </ng-container>
    <!-- Value Column -->
    <ng-container [cdkColumnDef]="displayedColumns[2]">
      <th cdk-header-cell *cdkHeaderCellDef>{{ "PRINT_VIEW.EMISSIONS_FACTORS.VALUE" | translate }}</th>
      <td cdk-cell *cdkCellDef="let element">
        <ng-container>{{ element.value | number: "1.2-2":currentLang }}</ng-container>
        <ng-container *ngIf="element.hasFootnote">{{ "*".repeat(element.footnoteIndex + 1) }}</ng-container>
      </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <ng-template ngFor let-note [ngForOf]="table.footnotes" let-i="index">
    <!-- first star is port of the translation -->
    <div class="note">{{ "*".repeat(i) }}{{ note | translate }}</div>
  </ng-template>
</ng-container>
