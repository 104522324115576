import { Component, OnInit, Input, ViewChild, TemplateRef, ChangeDetectorRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { UtilService } from "@energy-city/components";
import { IOptions } from "@enersis/search/lib/core/interfaces/options.interace";
import { SearchboxOptions } from "./searchbox-config";
import { postEvent } from "@enersis/gp-components/events";
import { SearchResult } from "@enersis/gp-components/interfaces";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "searchbox",
  templateUrl: "./searchbox.component.html",
  styleUrls: ["./searchbox.component.scss"]
})
export class SearchboxComponent implements OnInit {
  @ViewChild("min", { static: true }) public minMode: TemplateRef<any>;
  @ViewChild("max", { static: true }) public maxMode: TemplateRef<any>;

  @Input() public mode: "min" | "max" = "max";

  public currentMode: TemplateRef<any>;
  public searchId: string;
  public searchSuggestId: string;
  public search: FormControl;
  public userLocation$: Observable<number[]>;
  public searchOptions: IOptions;
  public selectedLngLat: Array<number> = [];

  constructor(private cdr: ChangeDetectorRef, private utilService: UtilService) {}

  public ngOnInit(): void {
    const postfix = Math.random().toString(36).substr(2, 9);

    this.currentMode = this[`${this.mode}Mode`];
    this.searchId = `searchBox__${postfix}`;
    this.searchSuggestId = `searchSuggest__${postfix}`;
    this.search = new FormControl("");
    this.searchOptions = this.getSearchboxOptions();
    this.userLocation$ = this.getUserLocation();
  }

  public clearSearchbox(): void {
    this.selectedLngLat = [];

    this.search.setValue("");
  }

  private getUserLocation(): Observable<number[]> {
    return this.utilService.userLocation$.pipe(filter((value: Array<number>) => Boolean(value.length)));
  }

  private getSearchboxOptions(): IOptions {
    const callback = (result) => {
      const lngLat = [result.location.longitude, result.location.latitude];

      this.cdr.markForCheck();

      this.selectedLngLat = lngLat;
      const searchResult: SearchResult = {
        type: "location",
        coordinates: [result.location.longitude, result.location.latitude],
        bounds: [...result.bestView?.bounds].reverse() as [number, number, number, number],
        rawResult: result
      };
      postEvent("searchbar:selectionChanged", searchResult);
    };

    return SearchboxOptions.create(this.searchId, this.searchSuggestId, callback);
  }
}
