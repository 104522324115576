<app-accounting-info-wrapper
  [dqi]="dqi$ | async"
  [config]="displayConfig"
  [disabledPrimary]="hidePrimaryEnergy"
  (method)="onChangeMethod($event)"
  *ngIf="data$ | async as data"
>
  <div style="margin-right: 1em; position: relative;">
    <gp-chart-export-menu #chartExportMenu></gp-chart-export-menu>
  </div>
  <div class="sunburst-wrapper">
    <ene-chart
      (load)="chartExportMenu.chartRef = $event"
      #chartRef
      type="sunburst"
      [settings]="configs"
      [data]="data"
    ></ene-chart>
    <div class="legend">
      <app-sunburst-legend [chart]="chartRef?.chart" [formatter]="legendFormatter"></app-sunburst-legend>
    </div>
  </div>
</app-accounting-info-wrapper>
<app-radio-group name="mainPolluterRadio" [options]="radioOptions" (change)="onChangeRadio($event)">
  <app-radio-button *ngFor="let button of radioButtons" [value]="button" [checked]="button === selectedButton">
    {{ "RESOURCES." + button | uppercase | translate }}
  </app-radio-button>
</app-radio-group>
