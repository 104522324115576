import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { cloneDeep } from "lodash";
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, of } from "rxjs";
import { map, take, takeUntil, withLatestFrom } from "rxjs/operators";
import { UtilService } from "../../../../services/util.service";
import { IApplicationState } from "../../../../state";
import { PatchFactorEmissionData } from "../../state";
import { GetFactorEmissionData, getFactorEmissionData } from "../../state/factor-emission";
import { FactorManagementTransformerService } from "./factor-management-transformer.service";
import { FactorManagementService } from "./factor-management.service";
import { FactorSector, FactorSubCategories } from "./factor-management.table-config";
import { ColumnConfig, TableData } from "./table/table.interface";

@Component({
  selector: "app-factor-management",
  templateUrl: "./factor-management.component.html",
  styleUrls: ["./factor-management.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FactorManagementComponent {
  public readonly showBaseDataControl = new FormControl(void 0);
  public readonly showCustom$ = of(false); // for now this function isnt used.
  public readonly sectionChanged$: Subject<{
    section: FactorSector;
    subCategory: FactorSubCategories;
  }> = new ReplaySubject(1);
  public vm$: Observable<{
    config: Array<ColumnConfig>;
    data: TableData;
  }>;

  private destroy$ = new Subject<void>();
  private readonly resetChanges$ = new BehaviorSubject<void>(void 0);

  constructor(
    private readonly store: Store<IApplicationState>,
    private readonly factorManagementService: FactorManagementService,
    private readonly utilService: UtilService,
    private readonly factorManagementTransformerService: FactorManagementTransformerService
  ) {}

  public ngOnInit(): void {
    // request FactorEmission data when region or factor emission section changed
    combineLatest([this.utilService.regionIdentifier$, this.sectionChanged$, this.resetChanges$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([{ regionId }, { subCategory }]) => {
        this.factorManagementService.tableDataChanges$.next(null);
        this.factorManagementService.isEditing$.next(false);
        const { endpoints } = this.factorManagementTransformerService.getSubcategoryConfig(subCategory);
        this.store.dispatch(new GetFactorEmissionData(regionId, endpoints));
      });

    // incomming FactorEmission Data converted for table
    this.vm$ = this.store.select(getFactorEmissionData).pipe(
      map((data) => cloneDeep(data)),
      withLatestFrom(this.sectionChanged$),
      map(([data, { subCategory }]) => this.factorManagementTransformerService.getTableDataAndConfig(subCategory, data))
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public changeCategory([section, subCategory]: [FactorSector, FactorSubCategories]): void {
    this.sectionChanged$.next({ section, subCategory });
  }

  public save(): void {
    combineLatest([
      this.utilService.regionIdentifier$,
      this.sectionChanged$,
      this.factorManagementService.tableDataChanges$
    ])
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(([{ regionId }, { subCategory }, data]) => {
        this.store.dispatch(
          new PatchFactorEmissionData(
            regionId,
            this.factorManagementTransformerService.getDataForPostEndpoints(subCategory, data)
          )
        );
        this.factorManagementService.tableDataChanges$.next(null);
        this.factorManagementService.isEditing$.next(false);
      });
  }

  public resetChanges() {
    this.resetChanges$.next(void 0);
  }
}
