/* tslint:disable:member-ordering */

import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import { switchMap, map, catchError } from "rxjs/operators";

import { environment } from "../../../../../environments/environment";

import { DataService } from "../../../../services/data.service";

import {
  ChartLocalEnergyMixActionType,
  LocalEnergyMixError,
  GetLocalEnergyMixEnergyConsumptionSuccess
} from "./local-energy-mix-actions";
import { GetLocalEnergyMixRenewableSuccess } from "./local-energy-mix-actions";
import { ErrorAction } from "../../../common";
import { HttpParams, HttpClient } from "@angular/common/http";

@Injectable()
export class LocalEnergyMixEffects {
  @Effect() public getLocalEnergyMixRenewable$ = this.actions$.pipe(
    ofType(ChartLocalEnergyMixActionType.GET_RENEWABLE),
    switchMap((action: any) => {
      const url = environment.co2DummyDataService + `/local-energy-mix/renewable/${action.ags}/${action.year}`;
      const params = new HttpParams();
      return this.http.get(url, { params }).pipe(
        map((payload) => new GetLocalEnergyMixRenewableSuccess(payload)),
        catchError((error) => ErrorAction(action, error, LocalEnergyMixError))
      );
    })
  );

  @Effect() public getLocalEnergyMixEnergyConsumption$ = this.actions$.pipe(
    ofType(ChartLocalEnergyMixActionType.GET_ENERGY_CONSUMPTION),
    switchMap((action: any) => {
      const url = environment.co2DummyDataService + `/local-energy-mix/consumption/${action.ags}/${action.year}`;
      const params = new HttpParams();
      return this.http.get(url, { params }).pipe(
        map((payload) => new GetLocalEnergyMixEnergyConsumptionSuccess(payload)),
        catchError((error) => ErrorAction(action, error, LocalEnergyMixError))
      );
    })
  );

  constructor(private dataService: DataService, private actions$: Actions, private http: HttpClient) {}
}
