import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneRangeSliderComponent } from "./range-slider.component";
import { NouisliderModule } from "ng2-nouislider";

@NgModule({
  declarations: [EneRangeSliderComponent],
  imports: [CommonModule, NouisliderModule],
  exports: [EneRangeSliderComponent]
})
export class DeprecatedEneRangeSliderModule {}
