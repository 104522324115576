import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CrossTable } from "../factor-table.interface";

@Component({
  selector: "co2-cross-table",
  templateUrl: "./cross-table.component.html",
  styleUrls: ["./cross-table.component.scss"]
})
export class CrossTableComponent {
  @Input() public table?: CrossTable;

  public currentLang: string;

  private destroy$ = new Subject();

  constructor(private translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
  }
  ngOnInit(): void {
    this.subscribeLanguageChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private subscribeLanguageChanges(): void {
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.currentLang = event.lang;
    });
  }
}
