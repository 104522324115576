import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneTabsModule } from "@energy-city/ui/tabs";
import { TranslateModule } from "@ngx-translate/core";
import { IntroductionModalComponent } from "./introduction-modal.component";

@NgModule({
  declarations: [IntroductionModalComponent],
  imports: [CommonModule, EneIconModule, TranslateModule, EneTabsModule],
  exports: [IntroductionModalComponent]
})
export class IntroductionModalModule {}
