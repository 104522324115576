import { Directive, ElementRef, forwardRef, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { fromEvent, Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { LocaleService } from "../locale.service";
import { SubSink } from "subsink";

@Directive({
  selector: "input[eneNumberInput]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputDirective),
      multi: true
    }
  ]
})
export class NumberInputDirective implements OnInit, OnDestroy, ControlValueAccessor {
  /** Overwrites the locale used by this input. Leave this empty to use the
   * browser's locale by default. */
  @Input()
  public locale: string = this.localeService.getUserLocale();

  /** The source observable for the live updated (valid) numeric value. */
  public value$: Observable<number | null>;

  private allowedSigns: Array<string> = [];
  private originalValue: string | undefined = undefined;
  private subs = new SubSink();
  private inputField: HTMLInputElement;

  constructor(el: ElementRef, private localeService: LocaleService) {
    this.inputField = el.nativeElement as HTMLInputElement;
  }

  public writeValue(value: number | null): void {
    const newValue = this.localeService.toLocalString(value, this.locale);
    if (this.originalValue === undefined && value !== null) {
      this.originalValue = newValue;
    }
    this.setInputValue(newValue);
  }

  public registerOnChange(fn: (value: number | null) => void): void {
    this.subs.sink = this.value$.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    // not implemented, not yet needed
  }

  public setDisabledState(isDisabled: boolean): void {
    // not implemented, not yet needed
  }

  public ngOnInit(): void {
    this.allowedSigns = [this.localeService.getDecimalSign(this.locale), "+", "-", "e", "E"];

    const inputValue$ = fromEvent<InputEvent>(this.inputField, "input").pipe(
      map((event) => (event.target as HTMLInputElement).value)
    );
    this.value$ = inputValue$.pipe(
      map((inputValue) => ({
        inputValue,
        isValid: inputValue === "" || this.localeService.isValidLocalNumber(inputValue, this.locale)
      })),
      tap(({ isValid }) => {
        this.inputField.setCustomValidity(isValid ? "" : "not a valid decimal value");
      }),
      filter(({ isValid }) => isValid),
      map(({ inputValue }) => this.localeService.parseLocalNumber(inputValue, this.locale))
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @HostListener("keydown", ["$event"])
  public onKeyDown(event: KeyboardEvent) {
    if (this.isTypingEscKey(event)) {
      // manually reset to the original value
      this.setInputValue(this.originalValue ?? "");
      return;
    }

    if (
      this.isTypingCommonKeys(event) ||
      this.isTypingFunctionKeys(event) ||
      this.isTypingNumbersOrAllowedSigns(event)
    ) {
      // let it happen, don't do anything
      return;
    }

    // prevent insert
    event.preventDefault();
  }

  private setInputValue(value: string) {
    this.inputField.value = value;
    this.inputField.dispatchEvent(new Event("input"));
  }

  private isTypingEscKey(e: KeyboardEvent) {
    return e.keyCode === 27;
  }

  private isTypingCommonKeys(e: KeyboardEvent): boolean {
    return (
      [46, 8, 9, 27, 13, 16].indexOf(e.keyCode) !== -1 || // delete, backspace, tab, esc, enter, shift
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) || // Ctrl+A
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) || // Ctrl+C
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) || // Ctrl+V
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) || // Ctrl+X
      (e.keyCode >= 35 && e.keyCode <= 39)
    ); // home, end, left, right
  }

  private isTypingFunctionKeys(e: KeyboardEvent): boolean {
    return e.keyCode >= 112 && e.keyCode <= 123; // F1-F12
  }

  private isTypingNumbersOrAllowedSigns(e: KeyboardEvent): boolean {
    return (
      (e.keyCode >= 48 && e.keyCode <= 57) || // number
      (e.keyCode >= 96 && e.keyCode <= 105) || // numpad
      this.allowedSigns.includes(e.key)
    );
  }
}
