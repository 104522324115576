import { Component, OnInit, ViewChild } from "@angular/core";
import { EneModalService } from "@energy-city/ui/modal";
import { Observable } from "rxjs";
import * as fromRoot from "@energy-city/components";
import { Store, select } from "@ngrx/store";
import { ofType } from "@ngrx/effects";
import { AppActionType, TrackingOnClickEvent } from "@energy-city/components";
import { assetUrl } from "../../share/asset-url";

@Component({
  selector: "app-introduction-modal",
  templateUrl: "./introduction-modal.component.html",
  styleUrls: ["./introduction-modal.component.scss"]
})
export class IntroductionModalComponent implements OnInit {
  @ViewChild("tabs", { static: true }) public tabs: any;
  public tours: any;
  public checkBoxState: boolean = false;

  public ngOnInit() {
    const tours$: any = this.tourSelection;
    tours$.subscribe((res) => {
      res.payload.help && res.payload.help.tour ? (this.tours = res.payload.help.tour.tours) : (this.tours = null);
    });
  }

  constructor(private eneModalService: EneModalService, private store: Store<fromRoot.IApplicationState>) {}

  public closeIntroductionModal() {
    this.eneModalService.close("introduction");
    this.store.dispatch(new TrackingOnClickEvent({ buttonName: "Gehe zur KommunalPlattform", category: "button" }));
  }

  public changeTab(tabId: number) {
    this.tabs.selectTab(tabId);
  }

  public startTour() {}

  private get tourSelection(): Observable<any> {
    return this.store.pipe(select(fromRoot.GetAppState), ofType(AppActionType.GET_CLIENT_CONFIG_SUCCESS));
  }

  // change checked-attribute for both checkboxes (tab 1 & 2)
  public changeChecked(event: any) {
    this.checkBoxState = event.target.checked;
    localStorage.setItem("showIntroductionModal", String(!this.checkBoxState));
  }

  public getAssetUrl(url: string): string {
    return assetUrl(url);
  }
}
