/* tslint:disable:max-line-length */
import { Pipe, PipeTransform, Inject } from "@angular/core";
import { EneUiService } from "@energy-city/ui/helper";

@Pipe({
  name: "eneFormatUnit"
})
export class EneFormatUnitPipe implements PipeTransform {
  private initialConfig: IFormatUnitConfig = {
    digitsLength: 3,
    SymAtZero: false,
    prefix: "",
    suffix: "",
    container: "label",
    localization: "",
    lowerUnits: true
  };
  constructor(@Inject("initialConfig") private injectedConfig: IFormatUnitConfig, private uiService: EneUiService) {
    // Merge the injectedConfiguration with the defaults
    if (injectedConfig) {
      this.initialConfig = { ...this.initialConfig, ...injectedConfig };
    }
    // listen to languagechanges
    uiService.languageChange$.subscribe((language: string) => {
      this.initialConfig.localization = language;
    });
  }

  /* tslint:disable-next-line:member-access */
  transform(value: number, inputCategory: string, valueUnit: any, options: IFormatUnitConfig = {}): string {
    // merga initialConfig (afzer merged with injectedConfig) also with one-time-config from pipe
    options = Object.assign({}, this.initialConfig, options);
    // make sure it does not display undefined
    options.prefix = options.prefix === undefined ? "" : options.prefix;

    const categories: any = {
      decimal: [
        { name: "one", sym: "", scale: 1 },
        { name: "kilo", sym: "k", scale: 0.001 },
        { name: "mega", sym: "M", scale: 0.000001 },
        { name: "giga", sym: "G", scale: 0.000000001 },
        { name: "tera", sym: "T", scale: 0.000000000001 }
      ],
      decimal_de: [
        { name: "one", sym: "", scale: 1 },
        { name: "thousand", sym: "Tsd.", scale: 0.001 },
        { name: "million", sym: "Mio.", scale: 0.000001 },
        { name: "billion", sym: "Mrd.", scale: 0.000000001 }
      ],
      time: [
        { name: "second", sym: "s", scale: 1 },
        { name: "minute", sym: "m", scale: 1 / 60 },
        { name: "hour", sym: "h", scale: 1 / 1200 },
        { name: "day", sym: "d", scale: 1 / 28800 }
      ],
      length: [
        { name: "meter", sym: "m", scale: 1 },
        { name: "kilometer", sym: "km", scale: 0.001 }
      ],
      area: [
        { name: "squaremeter", sym: "m²", scale: 1 },
        { name: "hactare", sym: "ha", scale: 0.0001 },
        { name: "squarekilometer", sym: "km²", scale: 0.000001 }
      ],
      volume: [
        { name: "liter", sym: "l", scale: 1 },
        { name: "cubicmeter", sym: "m³", scale: 0.001 }
      ],
      weight: [
        { name: "gramm", sym: "g", scale: 1000 },
        { name: "kilogramm", sym: "kg", scale: 1 },
        { name: "tonne", sym: "t", scale: 0.001 },
        { name: "megatonne", sym: "Mt", scale: 0.000000001 },
        { name: "gigatonne", sym: "Gt", scale: 0.000000000001 }
      ],
      weight_nr: [
        { name: "gramm", sym: "g", scale: 1 },
        { name: "kilogramm", sym: "kg", scale: 0.001 },
        { name: "tonne", sym: "t", scale: 0.000001 },
        { name: "megatonne", sym: "Mt", scale: 0.000000000001 },
        { name: "gigatonne", sym: "Gt", scale: 0.000000000000001 }
      ], // calcs for navitas & regensdorf
      weight_yv: [
        { name: "gramm", sym: "g", scale: 1 },
        { name: "kilogramm", sym: "kg", scale: 0.001 },
        { name: "tonne", sym: "t", scale: 0.000001 },
        { name: "kilotonne", sym: "kt", scale: 0.000000001 },
        { name: "megatonne", sym: "Mt", scale: 0.000000000001 },
        { name: "gigatonne", sym: "Gt", scale: 0.000000000000001 }
      ], // calcs for yverdon
      percentage: [
        { name: "percent", sym: "", scale: 100 },
        { name: "decimal", sym: "", scale: 1 }
      ] // use only when data is coming in decimal unit (like 0.55, will then be transformed to 55%)
    };

    /**
     * if the value is negative the whole caluclation fails.
     * Therefore I safe the negative/positive state as a boolean and make it positive.
     * Before returning the final value i check the flag and add "-"
     */
    let isNegativ: boolean;
    if (value >= 0) {
      isNegativ = false;
    } else {
      isNegativ = true;
      value = -value; // invert
    }
    if( value === 0) {
      return value + valueUnit;
    }

    // find selected unit
    const category: any = categories[inputCategory];
    if (!category) {
      return null;
    }

    let selectedUnit;
    let selectedUnitIndex;
    if (valueUnit) {
      for (let i = 0; i < category.length; i++) {
        const symLength = category[i].sym.length; // unit may be more for example kwh
        if (symLength > valueUnit.length) {
          continue;
        }
        let suffix = "";
        let unitSym = "";
        if (category[i].sym === "") {
          unitSym = valueUnit;
        } else {
          unitSym = valueUnit.substring(0, symLength);
          suffix = valueUnit.substring(symLength);
        }

        if (category[i].sym === unitSym) {
          selectedUnit = category[i];
          selectedUnitIndex = i;
          options.suffix = suffix;
          break;
        }
      }
    }
    if (selectedUnit == null) {
      options.suffix = valueUnit;
      for (let i = 0; i < category.length; i++) {
        if (category[i].scale === 1) {
          selectedUnit = category[i];
          selectedUnitIndex = i;
          break;
        }
      }
    }

    // check if it can go lower or should stuck at some point. if it can be lower, then check all value, if not find first and start there to iterate.
    let startFromindex: number;
    if (options.lowerUnits) {
      startFromindex = 0;
    } else {
      startFromindex = selectedUnitIndex;
    }

    // find the best unit
    for (let i = startFromindex; i < category.length; i++) {
      let newValue: any = (value / selectedUnit.scale) * category[i].scale;
      const length = Math.round(newValue).toString().length;
      if (length <= options.digitsLength || i === category.length - 1) {
        const newUnit: any = category[i];
        let places;
        if (options.decimalPlaces !== undefined) {
          places = options.decimalPlaces;
        } else {
          places = options.digitsLength - length;
        }
        newValue = this.round(newValue, places);

        if (length - options.digitsLength > 6) {
          newValue = this.exponentialFormat(newValue);
        }
        if (!options.SymAtZero && !newValue) {
          newUnit.sym = options.prefix = options.suffix = "";
        }

        // add separator if specified
        if (options.decimalPlaces === undefined) {
          newValue = newValue.toLocaleString(options.localization);
        } else {
          newValue = newValue.toLocaleString(options.localization, {
            minimumFractionDigits: options.decimalPlaces,
            maximumFractionDigits: options.decimalPlaces
          });
        }

        // add minus if negative
        newValue = isNegativ ? "-" + newValue : newValue;
        // wrap if specified
        if (options.container) {
          return (
            newValue +
            " <" +
            options.container +
            ">" +
            (options.prefix + newUnit.sym + options.suffix) +
            "</" +
            options.container +
            ">"
          );
        } else {
          return newValue + options.prefix + " " + newUnit.sym + options.suffix;
        }
      }
    }
  }

  private round(value: number, places: number) {
    if (places <= 0) {
      return Math.round(value);
    }
    const multiplier = Math.pow(10, places);
    return Math.round(value * multiplier) / multiplier;
  }

  private exponentialFormat(value: number, options?: any) {
    options = options || {};
    options.digitsLength = options.digitsLength || 10;
    let tmpVar = value;
    let i = 0;
    while (tmpVar >= options.digitsLength) {
      tmpVar /= 10;
      i++;
    }
    return i > 1 ? Math.round(tmpVar) + "e<sup>+" + i + "</sup>" : value;
  }
}

export interface IFormatUnitConfig {
  digitsLength?: number;
  decimalPlaces?: number;
  SymAtZero?: boolean;
  prefix?: string;
  suffix?: string;
  container?: string | boolean;
  localization?: string;
  [key: string]: any;
  lowerUnits?: boolean;
}
