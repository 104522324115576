import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ApiEndpointService } from "./api-endpoint.service";
import { TokenService } from "./token.service";
import { TokenStandaloneService } from "./token-standalone.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ApiEndpointService, TokenService, TokenStandaloneService]
})
export class UtilsModule {}
