import {
  RegionPropertyDataInputService,
  Result
} from "../../../../services/region-property/region-property-data-input.service";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import {
  IDataInputSector,
  IDataInputSubSector
} from "../../../../services/region-property/interfaces/region-property-data-input.interface";
import { IDataByYear, IDataInputDto } from "../../interfaces/data-input-dto";
import { filter, map, switchMap } from "rxjs/operators";
import { ITableData } from "../../../../models/data-panel/table-data.model";
import { IRegionIdentifier } from "../../../../services/util.service";
import {
  IAsideCell,
  IDataInputTable,
  IInputTable,
  IKey,
  ITableCell,
  ITableRow,
  Unit
} from "../../../../models/data-panel";
import { range } from "lodash";
import { IDataInputServiceCore } from "./data-input-service.core.interface";
import { IUnitOption } from "../../../../configs/data-panel";
import { MainTableCategories } from "../../../../../../../../shared/src/lib/data-input/model/main-table-categories.enum";

class CategoryConfig {
  constructor(public category: MainTableCategories, public subSector: string) {}
}

export class DataInputServiceGenericCore implements IDataInputServiceCore {
  private activeUnit: IUnitOption = { id: "", name: "", scale: 1 };

  constructor(private regionPropDataInputService: RegionPropertyDataInputService) {}

  private tableData: ITableData;
  private tableConfig: ITableConfig;
  private categoryConfig: IDataInputSector;
  private selectedSector: BehaviorSubject<CategoryConfig> = new BehaviorSubject<CategoryConfig>(null);
  private lastSectorConfig: CategoryConfig;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public async import(
    payload: IDataInputDto,
    regionId: IRegionIdentifier,
    category: MainTableCategories,
    nestedCategory: string
  ): Promise<Result> {
    if (!this.isValidPayload(payload)) {
      return Result.failureRowMissing;
    }

    this.writeToTableData(payload);

    const result = await this.regionPropDataInputService
      .setTableData(regionId, category, nestedCategory, this.tableData)
      .toPromise();

    this.triggerReload();

    return result;
  }

  private writeToTableData(payload: IDataInputDto): void {
    const unit: Unit = this.tableConfig.subSector.units.find(
      (l) => l.id.toLowerCase() === payload.metadata.unitOfMeasure.toLowerCase()
    );

    const yearMap: Map<number, IDataByYear> = new Map<number, IDataByYear>();
    for (const yearData of payload.data) {
      yearMap[yearData.year] = yearData;
    }

    const rowMap: Map<string, string[]> = new Map<string, string[]>();
    for (const row of this.tableConfig.subSector.rows) {
      if (row.parentName !== undefined && row.parentName !== "") {
        rowMap[row.rowId] = [row.parentName, row.name];
      } else {
        rowMap[row.rowId] = [row.name];
      }
    }

    for (const cell of this.tableData.cells) {
      const input: IDataByYear = yearMap[cell.year];
      const path: string[] = rowMap[cell.rowId];
      let valueObj: any = input[path[0]];
      for (const pathEl of path.slice(1)) {
        valueObj = valueObj == null ? null : valueObj[pathEl];
      }
      if (valueObj?.value == null || valueObj?.quality == null) {
        cell.hasValue = false;
      } else {
        cell.hasValue = true;
        cell.value = unit.toBackendFromUnit(valueObj.value);
        cell.quality = valueObj.quality;
      }
    }
  }

  // check if every row can be found in payload
  private isValidPayload(payload: IDataInputDto): boolean {
    for (const row of this.tableConfig.subSector.rows) {
      let rowFound = false;
      for (const data of payload.data) {
        let valueObj: any = data;
        if (row.parentName !== undefined && row.parentName !== "") {
          valueObj = valueObj[row.parentName];
        }
        valueObj = valueObj == null ? null : valueObj[row.name];
        if (valueObj != null) {
          rowFound = true;
          break;
        }
      }
      if (!rowFound) {
        return false;
      }
    }
    return true;
  }

  public get yearRangeFromConfig(): Array<number> {
    return range(this.categoryConfig.yearsFrom, this.categoryConfig.yearsTo + 1);
  }

  public async save(region: IRegionIdentifier, category: MainTableCategories, nestedCategory: string): Promise<Result> {
    const result = await this.regionPropDataInputService
      .setTableData(region, category, nestedCategory, this.tableData)
      .toPromise();
    this.triggerReload();
    return result;
  }

  public updateTableById(data: Partial<ITableCell>): void {
    const cell = this.tableData.cells.find((c) => c.rowId === data.id && c.year === data.year);
    cell.hasValue =
      data.value !== undefined && data.quality !== undefined && data.value !== null && data.quality !== null;
    cell.value = data.value / this.activeUnit.scale;
    cell.quality = data.quality;
  }

  public setCategoryConfig(config: IDataInputSector): void {
    this.categoryConfig = config;
  }

  public selectDataSource(regionIdentifier: IRegionIdentifier, yearRange: Array<number>): Observable<IDataInputTable> {
    this.loading.next(true);
    const tableData: Observable<ITableData> = this.selectedSector.pipe(
      filter((sector) => sector !== null),
      switchMap<CategoryConfig, Observable<ITableData>>((sector) =>
        this.regionPropDataInputService.getTableData(regionIdentifier, sector.category, sector.subSector)
      )
    );

    const tableConfig: Observable<ITableConfig> = this.selectedSector.pipe(
      filter((sector) => sector !== null),
      switchMap<CategoryConfig, Observable<ITableConfig>>((sector) => this.subSectorConfig(sector))
    );

    return combineLatest([tableData, tableConfig]).pipe(
      map((l) => {
        this.tableData = l[0];
        this.tableConfig = l[1];
        this.loading.next(false);
        return this.toCombinedTableData(l[0], l[1], new Unit("", "", 1));
      })
    );
  }

  private subSectorConfig(sector: CategoryConfig): Observable<ITableConfig> {
    return this.regionPropDataInputService.getSectorConfig(sector.category).pipe(
      map<IDataInputSector, ITableConfig>((l) => ({
        yearsFrom: l.yearsFrom,
        yearsTo: l.yearsTo,
        subSector: l.subsectors.find((sub) => sub.name === sector.subSector)
      }))
    );
  }

  public export(
    activeCategory: MainTableCategories,
    activeNestedCategory: string,
    yearRange: Array<number>
  ): [IInputTable, string] {
    const unit = this.tableConfig.subSector.units[0];
    return [this.toCombinedTableData(this.tableData, this.tableConfig, unit), unit.name];
  }

  public loadData(category: MainTableCategories, nestedCategory: string): void {
    this.loading.next(true);
    this.lastSectorConfig = new CategoryConfig(category, nestedCategory);
    this.selectedSector.next(this.lastSectorConfig);
  }

  private triggerReload(): void {
    this.loadData(this.lastSectorConfig.category, this.lastSectorConfig.subSector);
  }

  private toCombinedTableData(data: ITableData, config: ITableConfig, unit: Unit): IDataInputTable {
    const aside: IAsideCell[] = [];
    const rows: ITableRow[] = [];
    const rowMap: Map<string, ITableRow> = new Map<string, ITableRow>();
    const years: number[] = range(config.yearsFrom, config.yearsTo + 1);
    // fill table layout
    for (const row of config.subSector.rows) {
      aside.push({ dependency: !row.isParent, name: row.name });
      const dataRow: ITableRow = {};
      for (const year of years) {
        if (row.isParent) {
          dataRow[year.toString()] = { dependency: false, id: row.name, name: row.name, year: year };
        } else {
          dataRow[year.toString()] = {
            dependency: true,
            id: row.rowId,
            name: row.name,
            parentName: row.parentName,
            year: year
          };
        }
      }
      rows.push(dataRow);
      if (!row.isParent) {
        rowMap.set(row.rowId, dataRow);
      }
    }
    // fill table data
    for (const cell of data.cells) {
      if (cell.hasValue) {
        const dataRow = rowMap.get(cell.rowId);
        if (dataRow !== undefined) {
          dataRow[cell.year.toString()].value = unit.fromBackendToUnit(cell.value);
          dataRow[cell.year.toString()].quality = cell.quality;
        }
      }
    }
    return {
      aside: aside,
      units: config.subSector.units,
      columns: years.map((year: number) => year.toString()),
      dataSource: rows
    };
  }

  public getKeys(category: MainTableCategories, nestedCategory: string): IKey[] {
    const keys: IKey[] = [];
    for (const row of this.tableConfig.subSector.rows) {
      let id = row.name;
      if (row.parentName !== undefined) {
        id = row.parentName + "." + row.name;
      }
      keys.push({ id: id, name: row.name, parent: row.isParent });
    }
    return keys;
  }

  public selectLoadingState(): Observable<boolean> {
    return this.loading;
  }

  public setActiveUnit(selectedUnit: IUnitOption) {
    this.activeUnit = selectedUnit;
  }
}

interface ITableConfig {
  subSector: IDataInputSubSector;
  yearsFrom: number;
  yearsTo: number;
}
