import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from "@angular/core";
import { IEnvDto, PLATFORM_ENV_FILE } from "@co2-shared/common";
import { EneChartComponent } from "@energy-city/ui/chart";
import { EneKpisV2Service } from "@energy-city/ui/kpis-v2";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { isEqual } from "lodash";
import { Subject, combineLatest } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { DisplayChartConfig, EnergyType } from "../../../../../models/charts.models";
import { AuthenticationService } from "../../../../../modules/authentication/services/exported/authentication.service";
import { AccountingMethodService } from "../../../../../services/accounting-method.service";
import { RegionService } from "../../../../../services/region.service";
import { UtilService } from "../../../../../services/util.service";
import { Helper } from "../../../../../share/helper";
import * as fromRoot from "../../../../../state/index";
import { DISPLAY_CONFIG, HideExport, YEAR } from "../../const";
import { FinalEnergyService } from "../../final-energy/final-energy.service";
import { IChartData } from "../../models/final-energy-data.interface";
import { Co2CockpitKPIService } from "../../services/co2-cockpit-kpi.service";
import { energyTypeColorMap } from "../greenhouse-gases.model";
import { ChartGreenhouseGases } from "./chart-greenhouse-gases.service";
import { GreenhouseGasesService } from "./greenhouse-gases.service";

@Component({
  selector: "app-chart-energy-related-greenhouse-gases",
  templateUrl: "./chart-energy-related-greenhouse-gases.component.html",
  styleUrls: ["./chart-energy-related-greenhouse-gases.component.scss"],
  providers: [ChartGreenhouseGases]
})
export class ChartEnergyRelatedGreenhouseGasesComponent implements OnInit, OnDestroy {
  @ViewChild("chart", { static: true }) public chart: EneChartComponent;

  public hidePrimaryEnergy: boolean;
  public chartData: any = [];
  public chartConfig: any;

  public radioOptions: any = { checkbox: true };
  public dqi: number;
  public energyType: EnergyType = EnergyType.FINAL;
  public defaultDisplayConfig: DisplayChartConfig = {
    switcher: true,
    showMethod: true
  };

  private categoryKeys: string[] = [];

  private destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    public authService: AuthenticationService,
    public utilService: UtilService,
    public translate: TranslateService,
    private greenhouseGasesService: GreenhouseGasesService,
    private chartGreenhouseGases: ChartGreenhouseGases,
    private finalEnergyService: FinalEnergyService,
    private eneKpisV2Service: EneKpisV2Service,
    private co2CockpitKPIService: Co2CockpitKPIService,
    private accountingMethodService: AccountingMethodService,
    private regionService: RegionService,
    @Optional() @Inject(YEAR) private reportYear: number,
    @Optional() @Inject(DISPLAY_CONFIG) public displayConfig: DisplayChartConfig,
    @Optional() @Inject(HideExport) public hideExport: boolean,
    @Optional() @Inject(PLATFORM_ENV_FILE) private platformEnvFile: IEnvDto
  ) {
    this.hidePrimaryEnergy = this.platformEnvFile?.co2balance?.featureFlags?.hidePrimaryEnergy;
  }

  public onChange(type: EnergyType) {
    this.energyType = type;
    this.requestChartData();
  }

  public ngOnInit() {
    combineLatest([
      this.accountingMethodService.currentParameters$,
      this.regionService.selectedRegionUpLevels$,
      this.store.pipe(select(fromRoot.GetTimelineState))
    ])
      .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe(() => {
        this.requestChartData();
      });

    // Temporary disabling until business  requirements will be clarified
    // Average kpi calculated wrong should be clarified too
    // this.chartGreenhouseGases.onDataChange$.pipe(takeUntil(this.destroy$)).subscribe((series) => {
    //   if (!this.yearInjected) {
    //     this.recalculateKPI(series);
    //   }
    // });

    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.chartData = [
        ...this.chartData.map((item) => ({ ...item, name: this.translate.instant(item.translationKey) }))
      ];
      this.chartConfig.xAxis.categories = this.getCategories();
    });

    this.eneKpisV2Service.pending("co2_cockpit_extended");
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private requestChartData() {
    const calculateTotal = (data, _) => {
      return Helper.objToArrayDeep(data)
        .map((e) => e.element)
        .filter((e) => e.value != null)
        .reduce((acc, e) => acc + e.value, 0);
    };
    const year = this.reportYear || this.utilService.getSelectedYear();
    const selectedRegionDetails = this.regionService.selectedRegionDetails;
    const chartData$ = this.greenhouseGasesService.getCo2Emissions(
      selectedRegionDetails,
      this.energyType,
      year,
      this.accountingMethodService.weatherCorrection$.value,
      this.accountingMethodService.selectedMixType$.value
    );

    chartData$.pipe(takeUntil(this.destroy$)).subscribe((emissions) => this.updateData(emissions));

    const regionIdentifier20 = this.regionService.getSelectedRegionLevelId(20);

    if (!regionIdentifier20) {
      return;
    }

    const chartData20$ = this.greenhouseGasesService.getCo2Emissions(
      regionIdentifier20,
      this.energyType,
      year,
      this.accountingMethodService.weatherCorrection$.value,
      this.accountingMethodService.selectedMixType$.value
    );
    const childLevels$ = this.regionService.getRegionChildLevels(regionIdentifier20.regionId);

    this.co2CockpitKPIService.requestChartData({
      selectedRegionLevel: selectedRegionDetails.level,
      regionType: selectedRegionDetails.regionType,
      chartData$,
      chartData20$,
      childLevels$,
      calculateTotal
    });
  }

  private updateData(emissions) {
    this.chart.chart && this.chart.hideLoading();
    if (emissions === null) {
      return;
    }

    this.greenhouseGasesService
      .getDqi(
        this.reportYear,
        this.accountingMethodService.weatherCorrection$.value,
        this.accountingMethodService.selectedMixType$.value,
        this.energyType,
        emissions
      )
      .subscribe((dqi) => (this.dqi = dqi));

    const data = this.getChartData(emissions);

    this.translate.get("COCKPIT.TOTAL").subscribe(() => {
      this.chartGreenhouseGases.initConfig();
      this.chartConfig = this.chartGreenhouseGases.config;

      this.chartData = data.series.map((item) => ({ ...item, name: this.translate.instant(item.translationKey) }));
      this.chartConfig.xAxis.categories = [...data.categories];
    });
  }

  private getChartData(data: IChartData) {
    const { series, categoryKeys } = this.finalEnergyService.getSeries(data, energyTypeColorMap);
    this.categoryKeys = categoryKeys;

    return {
      categories: this.getCategories(),
      series
    };
  }

  private getCategories(): Array<string> {
    return this.categoryKeys.map((key) => this.translate.instant(key));
  }

  private recalculateKPI(series) {
    const total = this.chartGreenhouseGases.getKpiTotal(series);
    const average = this.co2CockpitKPIService.kpiAverage;

    if (average === undefined || total === undefined) {
      return;
    }

    const difference_to_average = total - average;
    this.eneKpisV2Service.runMapping({ total, average, difference_to_average }, "co2_cockpit_extended");
  }
}
