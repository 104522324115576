import { Injectable } from "@angular/core";
import { IEnvDto, ITenantInfoDto } from "@co2-shared/common";

@Injectable({
  providedIn: "root"
})
export class PlatformEnvironmentService {
  private static platformEnvFile: IEnvDto;
  private static tenantInfoFile: ITenantInfoDto;

  get envFile(): IEnvDto {
    return PlatformEnvironmentService.platformEnvFile;
  }

  get tenantInfo(): ITenantInfoDto {
    return PlatformEnvironmentService.tenantInfoFile;
  }

  public static loadFiles(): Promise<[IEnvDto, ITenantInfoDto]> {
    return Promise.all([this.getEnvFile(), this.getTenantInfo()]);
  }

  public static getEnvFile(): Promise<IEnvDto> {
    if (this.platformEnvFile) {
      return Promise.resolve(this.platformEnvFile);
    }

    return fetch("/config/tenant/env.json")
      .then((response) => response.json())
      .then((file: IEnvDto) => {
        PlatformEnvironmentService.platformEnvFile = file;
        return file;
      })
      .catch(() => ({} as IEnvDto));
  }

  public static getTenantInfo(): Promise<ITenantInfoDto> {
    if (this.tenantInfoFile) {
      return Promise.resolve(this.tenantInfoFile);
    }

    return fetch("/config/tenant/tenant-infos.json")
      .then((response) => response.json())
      .then((file: ITenantInfoDto) => {
        PlatformEnvironmentService.tenantInfoFile = file;
        return file;
      })
      .catch(() => ({} as ITenantInfoDto));
  }
}
