import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
import { get } from "lodash";
import { IFormatterResult } from "@energy-city/components";

export const EVENT_TYPE = "click";
export const ROOT_ELEMENT_PARH = "series[0].data[0]";

@Component({
  selector: "app-sunburst-legend",
  templateUrl: "./legend.component.html",
  styleUrls: ["./legend.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SunburstLegendComponent implements OnInit, OnDestroy {
  public items: Array<any> = [];
  public drillUpButton: string;
  private rootId: string;
  private clickEvent: () => any;
  private _chart: any;

  @Input()
  public formatter: (event: any, chart: any) => IFormatterResult;

  @Input()
  public get chart() {
    return this._chart;
  }
  public set chart(chart: any) {
    if (!chart || !chart.series || !chart.series.length) {
      this.clearProperties();

      return;
    }

    this._chart = chart;

    if (this.clickEvent) {
      this.clickEvent();
    }

    if (this.formatter) {
      this.initiateLegend(chart);

      this.clickEvent = this.getDrillDownEvent(chart);
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initiateLegend(this.chart);
    this.clickEvent = this.getDrillDownEvent(this.chart);
  }

  public ngOnDestroy(): void {
    this.clickEvent();
  }

  public trackByFn(_: number, item: any) {
    return item.name;
  }

  // TODO(bobkov): temporary solution
  public drillUp(): void {
    const nativeElement = this.chart.renderTo.querySelector(".highcharts-drillup-button");
    const event = new Event("click");

    nativeElement.dispatchEvent(event);
    this.initiateLegend(this.chart);
  }

  private getDrillDownEvent(chart: any): any {
    const self = this;

    return Highcharts.addEvent(chart.series[0], EVENT_TYPE, function (event: any) {
      const _chart: any = this.chart;
      const id = event.point.id;
      const isLeaf = event.point.node.isLeaf;

      if (self.rootId === id || isLeaf) {
        return;
      }

      const { items, drillUpButton } = self.formatter(event, _chart);

      self.drillUpButton = drillUpButton;
      self.items = items;

      self.cdr.markForCheck();
    });
  }

  private initiateLegend(chart: any): void {
    const point = get(chart, ROOT_ELEMENT_PARH, { node: {} });
    const fakeEvent = { point };
    const { items, drillUpButton } = this.formatter(fakeEvent, chart);

    this.rootId = point.node.id;
    this.drillUpButton = drillUpButton;
    this.items = items;
  }

  private clearProperties(): void {
    this.rootId = "";
    this.drillUpButton = "";
    this.items = [];
  }
}
