import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FactorTableRawData } from "../../../../../shared/src/lib/accounting/accounting-report/factor-table/factor-table.interface";
import { environment } from "../../environments/environment";
import { EmissionsFactors } from "../core/enum/factor.enum";
import { acceptJsonWithoutCache } from "../state/common";

interface IEmissionsFactorsResponse<T> {
  result?: {
    year?: number;
    factors: T;
  };
  tables?: Array<FactorTableRawData>;
}

@Injectable()
export class EmissionsFactorsService {
  constructor(private http: HttpClient) {}

  public getEmissionsFactors<T>(factor: EmissionsFactors, useUserFactors: boolean, year?: number): Observable<T> {
    if (!factor) {
      return EMPTY;
    }

    let params = new HttpParams();
    const url = `${environment.regionPropertyApi}/emissions/factors/${factor}${this.getEndpointVersion(factor)}`;

    if (year) {
      params = params.set("year", String(year));
    }
    params = params.set("useUserFactors", useUserFactors);

    return this.http
      .get<IEmissionsFactorsResponse<T>>(url, { headers: new HttpHeaders(acceptJsonWithoutCache), params })
      .pipe(
        map((res) => res.result?.factors || res.tables),
        catchError(() => of({} as any))
      );
  }

  private getEndpointVersion(factor: EmissionsFactors): string {
    // This method can be deleted when all factors enpoints will be finished
    const updatedSectors = [
      EmissionsFactors.WASTE,
      EmissionsFactors.STATIONARY_ENERGY,
      EmissionsFactors.TRANSPORTATION
    ];
    return updatedSectors.includes(factor) ? "/v2" : "";
  }
}
