/* tslint:disable:member-access */

import { Action } from "@ngrx/store";

export enum AuthActionType {
  LOGIN = "[Auth] LOGIN",
  LOGIN_SUCCESS = "[Auth] LOGIN_SUCCESS",
  ERROR = "[Auth] ERROR"
}

export interface ILoginData {
  user: string;
  language: string;
  logo_main: string;
  logo_footer: string;
  family_name: string;
  given_name: string;
  email: string;
  municipality_name: string;
  customer_support_id: string;
  tenant: string;
  level: number;
}

export class Login implements Action {
  type = AuthActionType.LOGIN;
  constructor(public payload: any) {}
}
export class LoginSuccess implements Action {
  type = AuthActionType.LOGIN_SUCCESS;
  constructor(public payload: ILoginData) {}
}
export class AuthError implements Action {
  type = AuthActionType.ERROR;
  constructor(public payload: any) {}
}

export type AuthActions = Login | LoginSuccess | AuthError;
