import { ActionReducerMap } from "@ngrx/store/src/models";

import * as fromExport from "./export-reducer";

export const exportReducers: ActionReducerMap<any> = {
  export: fromExport.reducer
};

export interface IExportState {
  [key: string]: any;
}
