import {
  Component,
  ViewEncapsulation,
  Input,
  HostBinding,
  HostListener,
  ContentChildren,
  QueryList,
  AfterContentInit
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { IDependenciesChanges, IDependencies, IResponsiveColumns } from "../../models/ui-sidenav.interfaces";
import { EneSidepanelComponent } from "../sidepanel/ui-sidepanel.component";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { EneSidenavService } from "../../services/ui-sidenav.service";

// Paddings in comparison with column
const paddingsInColumns: IResponsiveColumns = {
  sm: 0.25, // (1 : 48) * 12
  md: 0.234375,
  lg: 0.25,
  xl: 0.25
};

@Component({
  /* tslint:disable-next-line:component-selector */
  selector: "[ene-sidenav]",
  templateUrl: "./ui-sidenav.component.html",
  styleUrls: ["./ui-sidenav.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: "ene-sidenav"
  }
})
export class EneSidenavComponent implements AfterContentInit {
  @Input() public autofill: boolean = false;
  @Input() public useRouter: boolean | string = false;
  @Input() public iconsProject: string = "energycity";
  @ContentChildren(EneSidepanelComponent) public rawPanels: QueryList<EneSidepanelComponent>;
  @HostBinding("class.ene-sidenav--masterVisible")
  public masterVisible: boolean = false;
  @HostBinding("class.ene-sidenav--overlayVisible")
  public overlayVisible: boolean = false;
  public layout: any = { horizontalContainerWidth: "100%" }; // stores calculated values to use in the frontend and defaults
  public sidebarButtons: any = { start: [], center: [], end: [] };
  public horizontalWidthExceed: boolean = false;

  private panels: Array<any>;
  private firstInitialization: boolean = false; // will be set true in ngAfterContentInit

  constructor(private sidenavService: EneSidenavService, private router: Router, private route: ActivatedRoute) {}

  /**
   * read the panels and covert it into a array so we can use it within the code.
   * It also execute to calculations for the collisions and sizes
   */
  public ngAfterContentInit() {
    // will be executed any time a children (mostly sidepanels add/remove) changes
    this.rawPanels.changes.subscribe((changes) => {
      this.panels = this.rawPanels.toArray();
      // this.performChildrenChecks(undefined);
    });

    // Initial checking
    this.firstInitialization = true;
    this.panels = this.rawPanels.toArray();
    this.performChildrenChecks(undefined);

    // routing detection
    if (this.useRouter === true || typeof this.useRouter === "string") {
      this.route.params.subscribe((params) => {
        if (params.hasOwnProperty("panel") && params.panel !== "") {
          const index = this.panels.map((elem) => elem.name).indexOf(params.panel);
          if (index >= 0) {
            this.panels[index].setVisible(true);
          } else {
            // console.log("routing parameter 'panel' detected but with unknown panel [ " + params.panel + "]");
          }
        }
      });
    }
    // send a onPanelInit$ for each panel
    if (this.panels) {
      this.panels.forEach((element: EneSidepanelComponent) => {
        this.sidenavService.onPanelInit$.next(element);
      });
    }
  }

  /*************************************
   * PUBLIC FUNCTIONS
   *************************************/

  public getOpenPanelNames(): Array<string> {
    return this.panels.filter((panel) => panel.visible).map((panel) => panel.name);
  }

  public _getTranslationKey(itemName: string) {
    return "FRAMEWORK.TOOLTIP." + itemName;
  }

  /**
   * shorthand functions which contains all actions to perform if a children has changed
   */
  public performChildrenChecks(panel: EneSidepanelComponent): void {
    // send a next for the panels (it is a forEach so every panel will be sent individually)
    if (this.panels) {
      this.panels.forEach((element: EneSidepanelComponent) => {
        this.sidenavService.onPanelUpdate$.next(element);
      });
    }
    this.calculateCollisions();
    this.setSizes();
    this.generateButtons();
  }

  /**
   * a observable you can register on to get informed on all changes.
   * Note that you should register not directly here but instead on the EnesidenavService.onPanelUpdate$
   * If you only need the new states (if visible/collapse/enlarge etc. has changed), you can subscribe to EnesidenavService.onPanelChange$
   * @param name if provided the Obsverable is being filtered by the specific panel name
   * @returns Observable<EneSidepanelComponent>
   */
  public getPanelUpdate$(name?: string): Observable<EneSidepanelComponent> {
    if (typeof name === "undefined") {
      return this.sidenavService.onPanelUpdate$;
    } else {
      return this.sidenavService.onPanelUpdate$.pipe(
        filter((obj) => {
          if (!obj) {
            return false;
          }
          return obj.name === name;
        })
      );
    }
  }

  /**
   * Will be triggered on a click on the sidebar if the sidebar is autofilled/autogenerated
   * @param name takes a string which has to be equal to the sidepanel's name
   */
  public sidebarClick(name: string) {
    const index = this.panels.map((elem) => elem.name).indexOf(name);
    if (index >= 0 && (!this.masterVisible || this.panels[index].master)) {
      if (!this.panels[index].disabled) {
        if (!this.panels[index].visible) {
          this.panels[index].setCollapse(false);

          // routing
          if (this.useRouter === true || typeof this.useRouter === "string") {
            let URL: string;
            if (typeof this.useRouter === "string") {
              URL = this.useRouter + name;
            } else {
              URL = name;
            }
            this.router.navigate([URL]);
            this.panels[index].setVisible(true);
          }
        } else {
          this.panels[index].setVisible();
        }
      }
    }
  }

  /**
   * Resolves all dependencies and executes changes
   * called by setter-functions in sidepanel.component.ts (setVisible, setCollapse etc.)
   * @param name name of the sidepanel
   * @param checkForThisEvent string of a event
   */
  public checkDependencies(name: string, checkForThisEvent: string): void {
    if (this.panels) {
      const index = this.panels.map((elem) => elem.name).indexOf(name);
      if (index >= 0) {
        if (this.panels[index].dependencies) {
          this.panels[index].dependencies.forEach((dependency: IDependencies) => {
            // does it equal to the event to check for?
            if (checkForThisEvent === dependency.event) {
              // for each DependenciesChanges...
              dependency.changes.forEach((change: IDependenciesChanges) => {
                if (change.hasOwnProperty("visible")) {
                  this.setVisible(change.name, change.visible, false);
                }
                if (change.hasOwnProperty("collapse")) {
                  this.setCollapse(change.name, change.collapse, false);
                }
                if (change.hasOwnProperty("disabled")) {
                  this.setDisabled(change.name, change.disabled, false);
                }
                if (change.hasOwnProperty("enlarge")) {
                  this.setEnlarge(change.name, change.enlarge, false);
                }
              });
            }
          });
        }
      } else {
        // console.log(name + " has no dependencies!");
      }
    }
  }

  /*************************************
   * PUBLIC WRAPPER FUNCTIONS
   *************************************/

  public setCollapse(name: string, state: boolean | null = null, performDepsCheck: boolean = true): void {
    const index = this.getPanelIndex(name);
    if (index >= 0) {
      this.panels[index].setCollapse(state, performDepsCheck);
    }
  }

  public setVisible(name: string, state: boolean | null = null, performDepsCheck: boolean = true): void {
    const index = this.getPanelIndex(name);
    if (index >= 0) {
      this.panels[index].setVisible(state, performDepsCheck);
    }
  }

  public setDisabled(name: string, state: boolean | null = null, performDepsCheck: boolean = true): void {
    const index = this.getPanelIndex(name);
    if (index >= 0) {
      this.panels[index].setDisabled(state, performDepsCheck);
    }
  }
  public setEnlarge(name: string, state: boolean | null = null, performDepsCheck: boolean = true): void {
    const index = this.getPanelIndex(name);
    if (index >= 0) {
      this.panels[index].setEnlarge(state, performDepsCheck);
    }
  }
  public getPanel(name: string): EneSidepanelComponent {
    if (!this.panels) {
      return undefined;
    }
    const index = this.getPanelIndex(name);
    if (index >= 0) {
      return this.panels[index];
    }
  }

  /*************************************
   * PRIVATE FUNCTIONS
   *************************************/
  /**
   * this function detects if panel is defined as master. if yes, it does disable and hide all other panels
   * @param panel EneSidepanelComponent delivered from sidepanel.component.ts
   */
  public _checkMasterpanel(panel: EneSidepanelComponent) {
    if (typeof panel !== "undefined" && panel.master && this.panels) {
      this.masterVisible = panel.visible;
      this.panels.forEach((element: EneSidepanelComponent) => {
        if (element.slave === panel.name) {
          element.setVisible(false, false);
          element.setCollapse(false, false);
          element.setEnlarge(false, false);
          element.setDisabled(panel.visible, false);
        }
      });
    }
  }

  public _checkOverlayPanel(panel: EneSidepanelComponent) {
    if (panel && panel.overlay && this.panels) {
      this.overlayVisible = panel.visible;
    }
  }

  /**
   * called by performChildrenChecks()
   * helper functions to put sidebar items into different containers for different positions
   */
  private generateButtons(): void {
    if (this.autofill && this.panels) {
      this.sidebarButtons = { start: [], center: [], end: [] };
      let firstRun: boolean = true;
      for (let i = 0; this.panels.length > i; i++) {
        const panel = this.panels[i];
        if (panel.progressIndicator) {
          if (firstRun) {
            firstRun = false;
            panel.progressIndicatorFirst = true;
          }
          if (
            this.panels.length - 1 === i ||
            this.panels[i + 1].progressIndicator === false ||
            this.panels[i].buttonPosition !== this.panels[i + 1].buttonPosition
          ) {
            panel.progressIndicatorLast = true;
          }
        } else {
          firstRun = true;
        }
        panel.buttonStyle = this.calculateButtonHeight(panel);
        this.sidebarButtons[panel.buttonPosition].push(panel);
      }
    }
  }

  /**
   * helper function to determine index of a panel within this.panels
   * @param name string of panel name
   * @returns index of panel as number
   */
  private getPanelIndex(name: string): number {
    return this.panels.map((elem) => elem.name).indexOf(name);
  }

  /**
   * internal helper function to calculate the height of a button
   * @param panel EneSidepanelComponent
   */
  private calculateButtonHeight(panel: EneSidepanelComponent) {
    if (panel.hasOwnProperty("buttonSize")) {
      const myResponsivevalue: number = <number>this.sidenavService.responsiveCalculator(false, false);
      return {
        height: myResponsivevalue * panel.buttonSize + "px",
        "line-height": myResponsivevalue * panel.buttonSize + "px"
      };
    } else {
      return false;
    }
  }

  /**
   * Set the height and width of the main containers
   */
  private setSizes() {
    if (this.panels) {
      const myResponsiveShortname = this.sidenavService.responsiveCalculator(false, true); // sm, md or lg
      this.panels.forEach((element) => {
        if (element.align === "vertical") {
          const calc: number =
            this.layout.noCollisionHeight[myResponsiveShortname] *
            <number>this.sidenavService.responsiveCalculator(false, false);
          this.sidenavService.onPanelVerticalHeightChange$.next(calc);
          // promise so it will execute a whole lifecycle change until it applies the changed height
          Promise.resolve(null).then(() => {
            if (element.collision) {
              element.calculatedHeight = "calc(100% - " + calc + "px)";
            } else {
              element.calculatedHeight = "100%";
            }
          });
        }
        element.buttonStyle = this.calculateButtonHeight(element);
      });

      const hcw: number =
        this.layout.noCollisionWidth[myResponsiveShortname] *
        <number>this.sidenavService.responsiveCalculator(false, false);
      this.layout.horizontalContainerWidth = "calc(100vw - " + hcw + "px)";
      this.layout.positionLeft = hcw + "px";
    }
  }

  /**
   * Calculate collisions and generate widths and heights for the containers,
   * so they respect the other panels and do not overlay each other.
   * called internally by sidepanel.component.ts when a resize event happens.
   */
  private calculateCollisions(): void {
    if (this.firstInitialization) {
      // this.panels = this.rawPanels.toArray();

      // Reset
      this.layout.noCollisionWidth = { sm: 1, md: 1, lg: 1, xl: 1 }; // start with 1 because the sidebar always is 1
      this.layout.noCollisionHeight = { sm: 0, md: 0, lg: 0, xl: 0 };

      this.panels.forEach((element) => {
        // visible and NOT collapsed
        if (element.align === "horizontal" && element.visible && !element.collapse) {
          this.layout.noCollisionHeight.sm += element.columns.sm;
          this.layout.noCollisionHeight.md += element.columns.md;
          this.layout.noCollisionHeight.lg += element.columns.lg;
          this.layout.noCollisionHeight.xl += element.columns.xl;
        }

        // visible and IS collapsed
        if (element.align === "horizontal" && element.visible && element.collapse) {
          this.layout.noCollisionHeight.sm += paddingsInColumns.sm;
          this.layout.noCollisionHeight.md += paddingsInColumns.md;
          this.layout.noCollisionHeight.lg += paddingsInColumns.lg;
          this.layout.noCollisionHeight.xl += paddingsInColumns.xl;
        }

        // count vertical BUT ONLY if collision=false
        if (!element.collision && element.align === "vertical" && element.visible && !element.collapse) {
          this.layout.noCollisionWidth.sm += element.columns.sm;
          this.layout.noCollisionWidth.md += element.columns.md;
          this.layout.noCollisionWidth.lg += element.columns.lg;
        }

        // count vertical BUT ONLY if collision=false
        if (!element.collision && element.align === "vertical" && element.visible && element.collapse) {
          this.layout.noCollisionWidth.sm += paddingsInColumns.sm;
          this.layout.noCollisionWidth.md += paddingsInColumns.md;
          this.layout.noCollisionWidth.lg += paddingsInColumns.lg;
          this.layout.noCollisionWidth.xl += paddingsInColumns.xl;
        }
      });
    }
    this.checkIfWidthIsExceed();
  }

  /**
   * this function detects what's the width of all vertical panels.
   * It does check it against the window width and set a boolean "horizontalWidthExceed" which activates a class in the template.
   * this class does change the max-width property to have smoother animations if widthNotExceed
   */
  private checkIfWidthIsExceed() {
    if (this.panels) {
      let enlargedPanelActive: boolean = false;
      const totalOpenColumnsVertical: IResponsiveColumns = {
        sm: 0,
        md: 0,
        lg: 0,
        xl: 0
      };
      const collapsedWidth = 0.375; // the width of a collapsed panel in gridcolumns (0.375 = 30px in XL) - keep insync with definition @collapsedWidth in sidepanel.component.scss

      // every time: check if total width of open Panels exceeds window width - if yes add Class to it to add "max-width:100%" to panel so content does adapt size.
      this.panels.forEach((element) => {
        // count vertical BUT ONLY if collision=false
        if (element.align === "vertical" && element.visible && !element.collapse && element.enlarge !== true) {
          totalOpenColumnsVertical.sm += element.columns.sm;
          totalOpenColumnsVertical.md += element.columns.md;
          totalOpenColumnsVertical.lg += element.columns.lg;
          totalOpenColumnsVertical.xl += element.columns.xl;
        }
        if (element.align === "vertical" && element.visible && element.collapse && element.enlarge !== true) {
          totalOpenColumnsVertical.sm += collapsedWidth;
          totalOpenColumnsVertical.md += collapsedWidth;
          totalOpenColumnsVertical.lg += collapsedWidth;
          totalOpenColumnsVertical.xl += collapsedWidth;
        }
        if (element.enlarge && element.visible) {
          enlargedPanelActive = true;
        }
      });

      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      // removing 1 from results to compensate static sidebar with the sidebarItems
      const maxColumns: IResponsiveColumns = {
        sm: windowWidth / 42 - 1,
        md: windowWidth / 48 - 1,
        lg: windowWidth / 64 - 1,
        xl: windowWidth / 80 - 1
      };
      const currentBP: string = <string>this.sidenavService.responsiveCalculator(false, true);
      if (maxColumns[currentBP] <= totalOpenColumnsVertical[currentBP] || enlargedPanelActive) {
        this.horizontalWidthExceed = true;
      } else {
        this.horizontalWidthExceed = false;
      }
    }
  }

  /*************************************
   * LISTENER
   *************************************/

  /**
   * Ensures that the whole sidenav is recalculated
   * @param event comes from angular
   */
  @HostListener("window:resize", ["$event"])
  public onResize(event?: Event) {
    // switch of true so it will execute always. I've used switch here becaus it probably looks cleaner with it.
    this.calculateCollisions();
    this.setSizes();
  }
}
