import { ILoadingBehavior } from "../../../../common";
import { EMobilityAction, EMobilityActionType } from "./e-mobility-actions";

export const reducer = (state: IEMobilityState = {}, action: EMobilityAction) => {
  switch (action.type) {
    case EMobilityActionType.GET_E_MOBILITY_DATA:
      return {
        loading: true,
        connectionGrouId: action.connectionGrouId,
        scenarioId: action.scenarioId,
        year: action.year
      };
    case EMobilityActionType.GET_E_MOBILITY_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false
      };
    case EMobilityActionType.GET_E_MOBILITY_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
};

export interface IEMobilityState extends ILoadingBehavior {
  count?: number;
  capacity?: number;
  consumption?: number;
  co2Saving?: number;
  year?: number;
}
