<ng-template [ngIf]="autofill">
  <nav class="ene-sidebar">
    <ul ene-sidenav-start>
      <li
        *ngFor="let item of sidebarButtons.start; let i = index"
        ene-sidebar-item
        [link]="item.name"
        [class]="item.sidebarItemClass"
        [ngClass]="{ start: item.progressIndicatorFirst, end: item.progressIndicatorLast }"
        [ngStyle]="item.buttonStyle"
        [class.disabled]="item.disabled"
        #sidebarItem
        [ene-tooltip]="_getTranslationKey(item.name) | translate"
        placement="right"
        offset="0"
        show-delay="300"
      >
        <ene-icon [icon]="item.icon" [project]="iconsProject"></ene-icon>
      </li>
    </ul>
    <ul ene-sidenav-center>
      <li
        *ngFor="let item of sidebarButtons.center; let i = index"
        ene-sidebar-item
        [link]="item.name"
        [ngStyle]="item.buttonStyle"
        #sidebarItem
        [ene-tooltip]="_getTranslationKey(item.name) | translate"
        placement="right"
        offset="0"
        show-delay="300"
      >
        <ene-icon [icon]="item.icon" [project]="iconsProject"></ene-icon>
      </li>
    </ul>
    <ul ene-sidenav-end>
      <li
        *ngFor="let item of sidebarButtons.end; let i = index"
        ene-sidebar-item
        [link]="item.name"
        [ngStyle]="item.buttonStyle"
        #sidebarItem
        [ene-tooltip]="_getTranslationKey(item.name) | translate"
        placement="right"
        offset="0"
        show-delay="300"
      >
        <ene-icon [icon]="item.icon" [project]="iconsProject"></ene-icon>
      </li>
    </ul>
  </nav>
</ng-template>
<ng-content select="[ene-sidebar]"></ng-content>

<div class="vertical-container" [ngClass]="{ horizontalWidthExceed: horizontalWidthExceed }">
  <ng-content select="[align=vertical]"></ng-content>
  <ng-content></ng-content>
</div>
<div class="horizontal-container" [style.width]="layout.horizontalContainerWidth" [style.left]="layout.positionLeft">
  <ng-content select="[align=horizontal]"></ng-content>
</div>

<ng-content select="[ene-sidenav-content-container]"></ng-content>
