import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IRegionIdentifier } from "../util.service";
import { AccountingMethod } from "../../configs/accounting-method";
import { EMPTY, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { IRegionProperties } from "../region.service";
import { acceptJsonWithoutCache } from "../../state/common";
import { catchError, map } from "rxjs/operators";
import { RegionTypes } from "../../core/enum/region-types.enum";
import { IRank } from "../../models/co2-cockpit/rank.interface";

@Injectable({
  providedIn: "root"
})
export class RegionPropertyKpiService {
  constructor(private http: HttpClient) {}

  public getRegionCo2Rank(
    { regionId, regionType }: IRegionIdentifier,
    year: number,
    accountingMethod: AccountingMethod
  ): Observable<IRank> {
    if (!regionId || !year || !accountingMethod || !regionType || regionType === RegionTypes.UNION) {
      return EMPTY;
    }

    const params = new HttpParams({
      fromObject: {
        regionId,
        regionType,
        accountingMethod,
        year: String(year)
      }
    });
    const url = `${environment.regionPropertyApi}/kpis/rank`;

    return this.http
      .get<IRank>(url, { headers: new HttpHeaders(acceptJsonWithoutCache), params })
      .pipe(
        map((res) => res),
        catchError(() => of(null))
      );
  }

  public getRegionBuildings({ regionId, regionType }: IRegionIdentifier, year: number): Observable<number> {
    if (!regionId || !regionType || !year) {
      return EMPTY;
    }

    const url = `${environment.regionPropertyApi}/kpis/buildings-count`;
    const headers = new HttpHeaders(acceptJsonWithoutCache);
    const params = new HttpParams({
      fromObject: {
        regionId,
        regionType,
        year: String(year)
      }
    });

    return this.http.get<{ countBuildings: number }>(url, { headers, params }).pipe(
      map((data) => data.countBuildings),
      catchError(() => EMPTY)
    );
  }

  public getRegionProperties(
    { regionId, regionType }: IRegionIdentifier,
    year: number | string
  ): Observable<IRegionProperties> {
    if (!regionId || !regionType || !year) {
      return EMPTY;
    }

    const url = `${environment.regionPropertyApi}/kpis`;
    const headers = new HttpHeaders(acceptJsonWithoutCache);
    const params = new HttpParams({
      fromObject: {
        regionId,
        regionType,
        year: String(year)
      }
    });

    return this.http.get<IRegionProperties>(url, { headers, params });
  }
}
