<h3 [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.STANDARDS.TITLE') | translate"></h3>
<p [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.STANDARDS.PARAGRAPH_1') | translate"></p>
<p class="info-padded-box"><img src="../../../../../../assets/images/info/climate/mitte.png" alt="" /></p>
<div *ngFor="let method of methods">
  <div class="img info-padded-box">
    <img [src]="getAssetUrl('/images/info/methods/') + method.toLowerCase() + '.svg'" alt="{{method}}" />
  </div>
  <p class="info-padded-box"
     [innerHTML]="getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.STANDARDS.' + method) | translate">
  </p>
</div>
<p class="button">
  <button class="ene-button-pill-primary" (click)="goToSectors()">
    <ene-icon icon="ec_header_settings"></ene-icon>
    {{ getTenantSpecificTranslation('INFO.DETAILS.BACKGROUND.STANDARDS.GOTOSECTORS') | translate }}
  </button>
</p>
