<div class="wrapper">
  <gp-button xlarge secondary outlined (click)="undoChanges()">
    {{ "APP.UNDO" | translate }}
  </gp-button>
  <gp-button xlarge secondary outlined (click)="close()">
    {{ "APP.CANCEL" | translate }}
  </gp-button>
  <gp-button secondary xlarge filled (click)="save()">
    {{ "APP.SAVE" | translate }}
  </gp-button>
</div>
