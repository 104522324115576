<div class="chart_box">
  <h3>{{ "COCKPIT.CHARTS.ENERGY_EFFECIENCY.TITLE" | translate }}</h3>
  <div class="chart_container" *ngIf="chartData.length">
    <div class="chart_center_container">
      <ene-chart
        #chartSynthesisBuilding
        [data]="chartData"
        [settings]="chartSettings"
        [defaultColors]="false"
        type="synthesis"
        #chart
      ></ene-chart>
    </div>
    <div class="legend_center_container">
      <div class="legend">
        <div *ngFor="let item of legendItems; let i = index">
          <span
            class="ene-bar-arrow-right"
            [attr.style]="
              sanitizer.bypassSecurityTrustStyle(
                '--ene-bar-arrow-background-color: ' +
                  item.color +
                  '; 
                            width: ' +
                  (legendWidthStart + legendWidthSize * i) +
                  '%;'
              )
            "
            [options]="tooltipOptions"
            [ene-tooltip]="
              '<b>' +
              ('COCKPIT.CHARTS.ENERGY_EFFECIENCY.POWER_REQUIREMENT' | translate) +
              '</b>' +
              '<br>' +
              ('COCKPIT.CHARTS.ENERGY_EFFECIENCY.CLASS' | translate) +
              ' ' +
              item.name +
              '<br>' +
              item.tooltip
            "
            [tooltip-class]="'tooltip_ee_class_' + item.id"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="no_data" *ngIf="!chartData.length">
    {{ "TDI.NO_DATA_AVAILABLE.LONG" | translate }}
  </div>
</div>
