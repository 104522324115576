import { AfterViewInit, Component, Inject, OnDestroy, Optional } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { cloneDeep, isEqual, omit } from "lodash";
import { Subject, combineLatest } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { DisplayChartConfig } from "../../../../../../models/charts.models";
import { RegionService } from "../../../../../../services/region.service";
import { Helper } from "../../../../../../share/helper";
import * as fromRoot from "../../../../../../state/index";
import { HideExport, YEAR } from "../../../const";
import { FinalEnergyService } from "../../../final-energy/final-energy.service";
import { Co2CockpitKPIService } from "../../../services/co2-cockpit-kpi.service";
import { ChartCo2SectorsService, ICo2EmissionSectorResult } from "../chart-co2-sectors.service";
import { AccountingMethodService } from "@energy-city/components";

@Component({
  selector: "app-chart-co2-sectors-full",
  templateUrl: "./chart-co2-sectors-full.component.html",
  styleUrls: ["./chart-co2-sectors-full.component.scss", "../../../../../../../styles/chart.customlegend.include.scss"]
})
export class ChartCo2SectorsFullComponent implements OnDestroy, AfterViewInit {
  public dqi: number;
  public displayConfig: DisplayChartConfig = {
    switcher: false,
    showMethod: true
  };
  public gpChartRef: Highcharts.Chart = null;

  private destroy$: Subject<void> = new Subject();

  constructor(
    public store: Store<fromRoot.IApplicationState>,
    private chartCo2SectorsService: ChartCo2SectorsService,
    private finalEnergyService: FinalEnergyService,
    private co2CockpitKPIService: Co2CockpitKPIService,
    private regionService: RegionService,
    private accountingMethodService: AccountingMethodService,
    @Optional() @Inject(YEAR) private yearInjected: number,
    @Optional() @Inject(HideExport) public hideExport: boolean
  ) {}

  public ngAfterViewInit(): void {
    this.createDataChangeHandler();
  }

  private createDataChangeHandler(): void {
    combineLatest([
      this.accountingMethodService.currentParameters$,
      this.regionService.selectedRegionUpLevels$,
      this.store.pipe(select(fromRoot.GetTimelineState))
    ])
      .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe(() => {
        this.requestDqiAndKpiData();
      });
  }

  public requestDqiAndKpiData(): void {
    const calculateTotal = (data, year) => {
      return Helper.objToArrayDeep(data.map((e) => e.result))
        .map((e) => e.element)
        .filter((e) => e.value != null)
        .reduce((acc, e) => acc + e.value, 0);
    };

    const selectedRegionDetails = this.regionService.selectedRegionDetails;

    const chartData$ = this.chartCo2SectorsService.getChartData(selectedRegionDetails, this.yearInjected);
    chartData$.pipe(takeUntil(this.destroy$)).subscribe((emission) => this.updateDqi(emission));

    const regionIdentifier20 = this.regionService.getSelectedRegionLevelId(20);

    if (!regionIdentifier20) {
      console.info("No region identifier for level 20 found");
      return;
    }

    const chartData20$ = this.chartCo2SectorsService.getChartData(regionIdentifier20);
    const childLevels$ = this.regionService.getRegionChildLevels(regionIdentifier20.regionId);

    this.co2CockpitKPIService.requestChartData({
      selectedRegionLevel: selectedRegionDetails.level,
      regionType: selectedRegionDetails.regionType,
      chartData$,
      chartData20$,
      childLevels$,
      calculateTotal
    });
  }

  private async updateDqi(emissions: ICo2EmissionSectorResult[]) {
    this.dqi = this.getDqi(emissions);
  }

  private getDqi(emissions: ICo2EmissionSectorResult[]): number {
    const formattedEmissionsData = cloneDeep(emissions).reduce((acc, { result: [data], sector }) => {
      const plucked = this.finalEnergyService.pluckToLevelWithKey(omit(data, "year"), "value");
      const grouped = this.finalEnergyService.joinGrouped(plucked);

      return { ...acc, [sector.type]: grouped };
    }, {});

    return this.finalEnergyService.calculateDQI(formattedEmissionsData as any);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public setChartRef(chartRef: Highcharts.Chart) {
    this.gpChartRef = chartRef;
  }
}
