<div class="ene-surface-box-flat">
  <div class="chart-container-override">
    <co2-chart-emissions-sunburst
      #chart
      legendPosition="bottom-centered"
      (gpChartRef)="setChartRef($event)"
    ></co2-chart-emissions-sunburst>
  </div>
  <app-accounting-info-wrapper class="dqi" [dqi]="dqi" [config]="displayConfig">
    <div style="margin-right: 1em; position: relative;" *ngIf="!hideExport">
      <gp-chart-export-menu *ngIf="gpChartRef" [chartRef]="gpChartRef"></gp-chart-export-menu>
    </div>
  </app-accounting-info-wrapper>
</div>
