import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { DataInputFooterComponent } from "./footer.component";
import { EneModalService } from "@energy-city/ui/modal";
import { Store } from "@ngrx/store";

@NgModule({
  declarations: [DataInputFooterComponent],
  imports: [CommonModule, TranslateModule],
  exports: [DataInputFooterComponent],
  providers: [EneModalService, Store]
})
export class DataInputFooterModule {}
