import { AuthActionType, AuthActions } from "./auth-actions";

export function reducer(state = [], action: AuthActions) {
  switch (action.type) {
    case AuthActionType.LOGIN:
    case AuthActionType.LOGIN_SUCCESS:
      return action;
    case AuthActionType.ERROR:
      console.error(action.payload);
      return action;
    default:
      return state;
  }
}
