import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";

@Component({
  selector: "app-toggle-button",
  templateUrl: "./toggle-button.component.html",
  styleUrls: ["./toggle-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleButtonComponent implements OnInit {
  public _checked: boolean;
  public _icon: string;
  private iconChecked: string;
  private iconUnchecked: string;

  @Input()
  public disabled: boolean;

  /**
   * object is also allowed to trigger same value if needed.
   */
  @Input()
  public set checked(value: boolean | { value: boolean }) {
    this._checked = typeof value === "boolean" ? value : value.value;
    this.updateIcon();
    this.cdr.detectChanges();
  }

  @Input()
  public set icons(values: Array<string>) {
    if (!Array.isArray(values) || values.length !== 2) {
      console.warn("[ToggleButtonComponent]: Invalid icons initialization!");
      return;
    }

    [this.iconChecked, this.iconUnchecked] = values;
  }

  @Output()
  public change = new EventEmitter<boolean>();

  public ngOnInit(): void {
    if (this.iconChecked) {
      this.updateIcon();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  public onChange(event: Event): void {
    event.stopPropagation();
    this.updateIcon();
    this.change.emit(this._checked);
  }

  private getIcon(checked: boolean): string {
    return checked ? this.iconChecked : this.iconUnchecked;
  }

  private updateIcon() {
    this._icon = this.getIcon(this._checked);
  }
}
