import { ActionReducer, Action, combineReducers } from "@ngrx/store";
import { IApplicationState } from "..";
import { IGetAccountingMethodsConfigState } from "./accounting-methods/get/get-accounting-methods-config-reducer";
import { IPutAccountingMethodsConfigState } from "./accounting-methods/put/put-accounting-methods-config-reducer";
import { reducer as getReducer } from "./accounting-methods/get/get-accounting-methods-config-reducer";
import { reducer as putReducer } from "./accounting-methods/put/put-accounting-methods-config-reducer";

export interface IConfigState {
  getAccountingMethodsConfig: IGetAccountingMethodsConfigState;
  putAccountingMethodsConfig: IPutAccountingMethodsConfigState;
}

export const reducer: ActionReducer<any, Action> = combineReducers({
  getAccountingMethodsConfig: getReducer,
  putAccountingMethodsConfig: putReducer
});

export const getConfigState = (state: IApplicationState) => state && state.app && state.app.config;
