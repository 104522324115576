import { EnergyType } from "../../../../models/charts.models";
import { environment } from "../../../../../environments/environment";
import { EnergyTypes } from "../../../../configs/accounting-method";
import { IEnergySector } from "../../../../services/region-property/interfaces/region-property-energy.interface";

export const FINAL_ENERGY_SECTORS_YEAR_LIMIT = 6;

export const trafficColorMap = {
  total: "#228A97",
  onRoad: "#51D6A6",
  railway: "#39D0CB",
  aviation: "#2D92BE",
  waterTransport: "#2755A5",
  inlandNavigation: "#2B5DB5",
  freight: "#3DE0DB",
  regionalRailTransportUrban: "#3DE0DB",
  regionalRailTransportNonUrban: "#3DE0DB",
  longDistanceRailTransport: "#3DE0DB",
  automobile: "#57E5B1",
  poweredTwoWheeler: "#57E5B1",
  lightCommercialVehicles: "#57E5B1",
  serviceBus: "#57E5B1",
  truck: "#57E5B1",
  coach: "#57E5B1"
};

export const energyTypeMap = {
  fuelGrouped: [
    "dieselFossil",
    "dieselBio",
    "gasolineFossil",
    "gasolineBio",
    "kerosene",
    "liquefiedPetroleumGas",
    "biogas",
    "naturalGas",
    "oil",
    "gas",
    "hydrogen",
    "bioethanol",
    "gasoline",
    "gasoil",
    "biodiesel",
    "compressedNaturalGas",
    "gasolineFourStroke",
    "hybridDiesel",
    "naturalGas",
    "gasolineTwoStroke",
    "compressedNaturalGasGasoline",
    "liquefiedPetroleumGasGasoline",
    "hybridGasoline",
    "gasolineE85",
    "diesel"
  ]
};

export const energyTypes = {
  FINAL: "final",
  PRIMARY: "primary"
};

export const DEFAULT_TRAFFIC_COLOR = "#008797";
export const DEFAULT_FINAL_ENERGY_SECTOR_COLOR = "#5D2F58";

export const energySectors: Array<IEnergySector> = [
  {
    url: () => `${environment.regionPropertyApi}/energy/stationary-energy`,
    level: 2,
    type: EnergyTypes.STATIONARY_ENERGY,
    weatherCorrection: true,
    energyType: EnergyType.FINAL,
    localMix: true
  },
  {
    url: () => `${environment.regionPropertyApi}/energy/transportation`,
    level: 4,
    type: EnergyTypes.TRANSPORTATION,
    weatherCorrection: true,
    energyType: EnergyType.FINAL,
    localMix: true
  }
];
