
import { SetsActionTypes, SetsActions } from "./sets.actions";
import { get } from "lodash";
import { DELETE_MEASURES_SET_ERROR, DELETE_MEASURES_SET_SUCCESS, SAVE_MEASURES_SET_ERROR, SAVE_MEASURES_SET_SUCCESS } from "../../constants/measures";

export interface SetsState {
  sets?: Array<any>;
  currentSet?: any;
  error?: { message: string };
  success?: {
    message: string;
    newSetId?: string;
  };
}

const initialState: SetsState = {};

export function reducer(state: SetsState = initialState, action: SetsActions): SetsState {
  switch (action.type) {
    case SetsActionTypes.GET_SETS_SUCCESS:
      return {
        ...state,
        sets: action.payload,
        currentSet: get(action, "payload[0]", null),
        error: null
      };
    case SetsActionTypes.SET_CURRENT_SET:
      return {
        ...state,
        currentSet: action.payload
      };
    case SetsActionTypes.GET_SETS_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case SetsActionTypes.SAVE_SET_SUCCESS:
      return {
        ...state,
        success: {
          message: SAVE_MEASURES_SET_SUCCESS,
          newSetId: action.newSetId
        }
      };
    case SetsActionTypes.SAVE_SET_ERROR:
      return {
        ...state,
        error: { message: SAVE_MEASURES_SET_ERROR }
      };
    case SetsActionTypes.DELETE_SET_SUCCESS:
      return {
        ...state,
        success: {
          message: DELETE_MEASURES_SET_SUCCESS,
          newSetId: null
        }
      };
    case SetsActionTypes.DELETE_SET_ERROR:
      return {
        ...state,
        error: { message: DELETE_MEASURES_SET_ERROR }
      };
    default:
      return state;
  }
}
