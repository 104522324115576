<div class="simulation-goal-editor">
  <div class="simulation-goal-editor__header">
    <span>
      {{ "SIMULATION.SCENARIO.GOAL_EDITOR.TITLE" | translate }}
    </span>
    <ene-icon icon="ec_building_delete_address" (click)="close()"> </ene-icon>
  </div>
  <mat-vertical-stepper [linear]="true" #stepper>
    <mat-step>
      <ng-template matStepLabel>
        {{ "SIMULATION.SCENARIO.GOAL_EDITOR.STEPS.SELECT_YEAR" | translate }}
      </ng-template>
      <div class="simulation-goal-editor__slider-wrapper">
        <mat-slider
          [min]="2010"
          [max]="2050"
          [value]="value.year"
          [thumbLabel]="true"
          (change)="yearChanged($event.value)"
        >
        </mat-slider>
      </div>
      <div class="simulation-goal-editor__step-controls">
        <button matStepperNext class="simulation-goal-editor__step-controls--light">
          {{ "MEASURE.EDITOR.GO_TO_NEXT_STEP" | translate | uppercase }}
        </button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        {{ "SIMULATION.SCENARIO.GOAL_EDITOR.STEPS.SELECT_REDUCTION_GOAL" | translate }}
      </ng-template>
      <div class="simulation-goal-editor__slider-wrapper">
        <mat-slider
          [min]="0"
          [max]="1"
          [value]="value.goal"
          [tickInterval]="1"
          [step]="0.1"
          [thumbLabel]="true"
          [displayWith]="formatRangeValuePercentage"
          (change)="goalChanged($event.value)"
        >
        </mat-slider>
      </div>
      <div class="simulation-goal-editor__step-controls">
        <button matStepperPrevious class="simulation-goal-editor__step-controls--light">
          {{ "MEASURE.EDITOR.GO_TO_PREVIOUS_STEP" | translate | uppercase }}
        </button>
        <button matStepperNext class="simulation-goal-editor__step-controls--light">
          {{ "MEASURE.EDITOR.GO_TO_NEXT_STEP" | translate | uppercase }}
        </button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        {{ "SIMULATION.SCENARIO.GOAL_EDITOR.STEPS.SAVE_OR_RESET_GOAL" | translate }}
      </ng-template>
      <span class="simulation-goal-editor__last-step-description">
        {{ "SIMULATION.EDITOR.SAVE_OR_RESET_ENTRIES.DESCRIPTION" | translate }}
      </span>
      <div class="simulation-goal-editor__step-controls">
        <button class="simulation-goal-editor__step-controls--save" (click)="saveChanges()">
          <ene-icon icon="ec_save"></ene-icon>
          <span>
            {{ "MEASURE.EDITOR.SAVE" | translate | uppercase }}
          </span>
        </button>
        <button class="simulation-goal-editor__step-controls--light" (click)="close()">
          {{ "MEASURE.EDITOR.RESET_TO_DEFAULT" | translate | uppercase }}
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
