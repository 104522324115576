import { PopupService } from "../../../../modules/pop-up/services/popup/popup.service";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  ViewChildren
} from "@angular/core";
import { AccountingMethod } from "../../../../configs/accounting-method";

import { Store } from "@ngrx/store";
import { last } from "lodash";
import { combineLatest, forkJoin, Observable, Subject } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import * as fromRoot from "../../../../state/index";
import { IButton, REPORT_WARNING_MODAL_OPTIONS } from "../../print.const";
import { PrintService } from "../../services/print.service";
import { HelpTabChange } from "../../../../state/index";
import { UtilService } from "../../../../services/util.service";

import { NguCarousel, NguCarouselConfig } from "@ngu/carousel";
import { assetUrl } from "../../../../share/asset-url";
import { GlossaryService } from "@energy-city/co2balance-components/services";
import { RegionPropertyReportYearsService } from "../../../../services/region-property/region-property-report-years.service";

interface IReport {
  buttons: Array<Array<IButton>>;
  method: AccountingMethod;
  allYearsDisabled: boolean;
  selectedYear: number | undefined;
}
@Component({
  selector: "app-reports-panel",
  templateUrl: "./reports-panel.component.html",
  styleUrls: ["./reports-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren("carousel") private carousels: QueryList<NguCarousel<any>>;

  public reportMethods: Array<IReport>;
  public radioOptions = { checkbox: true };
  public footerModalContent = REPORT_WARNING_MODAL_OPTIONS;

  public carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 600,
    point: {
      visible: true,
      hideOnSingleSlide: true
    },
    loop: false,
    easing: "ease"
  };

  private yearLimitPerItem = 12;
  private destroy$: Subject<undefined> = new Subject();
  private isInitialized$: Subject<boolean> = new Subject();

  constructor(
    public store: Store<fromRoot.IApplicationState>,
    private popup: PopupService,
    private printService: PrintService,
    private utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private reportYearsService: RegionPropertyReportYearsService,
    @Optional() private glossary: GlossaryService
  ) {}

  public ngOnInit(): void {
    combineLatest([this.utilService.clientConfig$, this.utilService.regionIdentifier$])
      .pipe(
        takeUntil(this.destroy$),
        switchMap(([clientConfig, regionIdentifier]) => {
          const availableMethods = clientConfig.accountingMethods.methods.map(
            (method) => AccountingMethod[method.type]
          );
          const reoprtYears$: Array<Observable<IReport>> = availableMethods.map((method) =>
            this.reportYearsService.getReportYears(regionIdentifier, method).pipe(
              map(
                (reportYears): IReport => {
                  const buttons = reportYears.map((yearlyItem) => {
                    return { value: yearlyItem.year, disabled: !yearlyItem.isReportAvailable };
                  });
                  return {
                    buttons: this.formatButtonsList(buttons),
                    method: method,
                    allYearsDisabled: !buttons.some((button) => !button.disabled),
                    selectedYear: last(buttons.filter((item) => !item.disabled))?.value
                  };
                }
              )
            )
          );
          return forkJoin(reoprtYears$);
        })
      )
      .subscribe((reportYears) => {
        this.reportMethods = reportYears;
        this.isInitialized$.next(true);
      });
  }

  public ngAfterViewInit(): void {
    this.isInitialized$.pipe(filter((isInitialized) => isInitialized)).subscribe(() => {
      // initialization changes need to be digested before we can set the carousel
      this.cdr.detectChanges();
      this.carousels?.forEach((item) => {
        item?.moveTo(this.reportMethods[this.reportMethods.length - 1].buttons.length - 1, true);
      });
    });
  }

  public trackByFn(_: number, item: IButton): number {
    return item.value;
  }

  public onChangeYear(report: IReport, year: number): void {
    report.selectedYear = year;
  }

  public openReport(method: AccountingMethod, year: number): void {
    this.printService.selectedReportYear = year;
    this.printService.showPrintPreview(method);
  }

  public formatButtonsList(buttonsList: Array<IButton>): Array<Array<IButton>> {
    if (!buttonsList) {
      return [];
    }

    const result = [];
    const buttons = [...buttonsList];

    for (; buttons.length > 0; ) {
      result.push(buttons.splice(0, this.yearLimitPerItem));
    }

    return result;
  }

  public openGlossary() {
    if (this.glossary) {
      this.glossary.openTab(0);
    } else {
      const action = new HelpTabChange("definitions.#basisbilanz");
      this.store.dispatch(action);
      this.popup.openGlossaryAtAnchor("definitions.#basisbilanz");
    }
  }

  public getAssetUrl(url: string): string {
    return assetUrl(url);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
