import { InjectionToken } from "@angular/core";
import { IUserConfigs } from "../components/user-popover/user-popover.models";
import { ILoginData } from "@energy-city/components";

export interface ITooltip {
  placement: string;
  showDelay: number;
}

export type PopperTrigger = "click" | "hover";

export type PopperPlacement = "top" | "bottom" | "left" | "right";

export interface IPopper {
  trigger: PopperTrigger;
  placement: PopperPlacement;
  timeout: number;
}

export interface IHeaderConfigs {
  logo: string;
  tenant: string;
  municipality_name: string;
  crestImage: string;
  help?: any;
  launchpadLinks?: Array<any>;
  user: IUserConfigs;
}

export const DYNAMIC_HEADER_DATA = new InjectionToken<any>("DYNAMIC_HEADER_DATA");

export const TOOLTIP_CONFIGS: ITooltip = {
  placement: "bottom",
  showDelay: 300
};

export const POPPER_CONFIGS: IPopper = {
  trigger: "click",
  placement: "top",
  timeout: 200
};

export class HeaderConfigs {
  public static getFormattedData({ auth, app }: { auth: ILoginData; app: any }): IHeaderConfigs {
    return {
      logo: auth.logo_main,
      tenant: auth.tenant,
      municipality_name: auth.municipality_name,
      crestImage: app.theme.name,
      help: app.help,
      launchpadLinks: app.launchpadLinks || [],
      user: {
        given_name: auth.given_name,
        family_name: auth.family_name,
        email: auth.email,
        langCode: auth.language,
        allowedLanguages: app.allowedLanguages
      }
    };
  }
}
