import { Injectable } from "@angular/core";
import { IChartData, IDataItems, IGroupedDataItems, ISeriesData } from "../models/final-energy-data.interface";
import { isNumber } from "lodash";

@Injectable()
export class FinalEnergyService {
  private readonly categories = [
    {
      translationKey: "RESOURCES.PRIVATEHOUSEHOLD",
      category: "privateHousehold"
    },
    {
      translationKey: "RESOURCES.COMMERCE",
      category: "commerce"
    },
    {
      translationKey: "RESOURCES.INDUSTRY",
      category: "industry"
    },
    {
      translationKey: "RESOURCES.COMMUNITYFACILITIES",
      category: "communityFacilities"
    },
    {
      translationKey: "RESOURCES.TRANSPORTATION",
      category: "transportation"
    }
  ];

  public pluckToLevelWithKey(obj: any, key: string, res: IDataItems = {}): IDataItems {
    for (const i in obj) {
      if (obj[i].hasOwnProperty(key)) {
        if (res[i]) {
          res[i].push(obj[i]);
        } else {
          res[i] = [obj[i]];
        }
      } else if (typeof obj[i] === "object") {
        res = this.pluckToLevelWithKey(obj[i], key, res);
      }
    }
    return res;
  }

  public groupEnergyTypes(data: IDataItems, energyTypeMap: { [key: string]: Array<string> }): IDataItems {
    const grouped = {};
    for (const i in energyTypeMap) {
      if (!energyTypeMap.hasOwnProperty(i)) {
        continue;
      }
      energyTypeMap[i].forEach((item) => {
        if (data[item]) {
          grouped[i] = grouped[i] ? [...grouped[i], ...data[item]] : data[item];
          delete data[item];
        }
      });
    }
    for (const i in grouped) {
      if (!grouped.hasOwnProperty(i)) {
        continue;
      }
      data[i] = data[i] ? [...data[i], ...grouped[i]] : grouped[i];
    }
    return data;
  }

  public joinGrouped(grouped: IDataItems): IGroupedDataItems {
    const result = {};
    for (const i in grouped) {
      if (!grouped.hasOwnProperty(i)) {
        continue;
      }
      const avgQ = grouped[i].reduce(
        (acc, item) => {
          item.quality = item.quality ? item.quality : 0;
          item.value = item.value ? item.value : 0;

          item.quality *= item.value;
          acc.sumValue += item.value;
          acc.sumQuality += item.quality;
          return acc;
        },
        { sumValue: 0, sumQuality: 0 }
      );
      const quality = avgQ.sumQuality && avgQ.sumValue ? avgQ.sumQuality / avgQ.sumValue : 0;
      result[i] = { value: avgQ.sumValue, quality };
    }
    return result;
  }

  public calculateDQI(data: IChartData): number {
    const allData: any = Object.values(data).reduce(
      (acc: any, item) => {
        if (typeof item !== "object") {
          return acc;
        }
        Object.values(item)
          .filter(
            (dataItem) =>
              dataItem.hasOwnProperty("value") &&
              dataItem.hasOwnProperty("quality") &&
              isNumber(dataItem.quality) &&
              isNumber(dataItem.value)
          )
          .forEach(({ value, quality }) => {
            acc.sumQuality += value * quality;
            acc.sumValue += value;
          });
        return acc;
      },
      { sumValue: 0, sumQuality: 0 }
    );

    return allData.sumQuality / allData.sumValue;
  }

  public getSeries(
    data: IChartData,
    energyTypeColorMap: any
  ): {
    series: Array<ISeriesData>;
    categoryKeys: Array<string>;
  } {
    const series: Map<string, ISeriesData> = new Map();
    const categories = this.categories.map((cat) => cat.category);
    for (const category in data) {
      if (!data.hasOwnProperty(category) || !Boolean(category)) {
        continue;
      }
      const index = categories.indexOf(category);
      if (index === -1) {
        console.warn("Unexpected category", category);
        continue;
      }

      for (const subcategory in data[category]) {
        if (!data[category].hasOwnProperty(subcategory)) {
          continue;
        }

        if (!series[subcategory]) {
          const color = energyTypeColorMap[subcategory]?.color;
          const legendIndex = energyTypeColorMap[subcategory]?.legendIndex;

          if (!color) {
            console.warn("unexpected subcategory ", subcategory);
            continue;
          }
          series[subcategory] = {
            translationKey: `RESOURCES.${subcategory.toUpperCase()}`,
            color,
            legendIndex,
            data: new Array(categories.length).fill(0)
          };
        }

        series[subcategory].data[index] += data[category][subcategory].value;
      }
    }
    let seriesList: ISeriesData[] = Object.values(series);

    // remove series where all values are 0
    seriesList = seriesList.filter((timeSeries) =>
      timeSeries.data.map((value) => value !== 0).reduce((a, b) => a || b)
    );

    return {
      series: seriesList,
      categoryKeys: this.categories.map((cat) => cat.translationKey)
    };
  }

  public getKpiTotal(series: any, year: number): number {
    return series // .filter(item => item.visible)
      .reduce((acc, item) => {
        return acc + item.data.filter((e) => e.category === year).reduce((sum, seria) => sum + (seria.y || 0), 0);
      }, 0);
  }
}
