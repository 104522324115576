import { Injectable } from "@angular/core";
import { NavigationCategory } from "@energy-city/components";
import { EneModalService } from "@energy-city/ui/modal";
import { select, Store } from "@ngrx/store";
import { DataInputConfigService } from "libs/shared/src/lib/data-input/config/data-input-config.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as fromDataPanel from "../../../state";
import { DataInputService } from "../data-input.service";

const MODAL_ID = "import-notice";

@Injectable()
export class NavigationService {
  constructor(
    private store: Store<fromDataPanel.DataPanelState>,
    private dataInputService: DataInputService,
    private modal: EneModalService,
    private dataInputConfigService: DataInputConfigService
  ) {}

  public getCategories(): Observable<Array<NavigationCategory>> {
    return this.dataInputConfigService.getCategories().pipe(
      map((categories) => {
        return categories.map((category) => {
          return new NavigationCategory({
            ...category,
            disabled: false
          });
        });
      })
    );
  }

  public getInitialCategory(categories: any): any {
    if (!categories && !categories.length) {
      return;
    }

    const foundCategory = categories.find((category: any) => !category.disabled);
    const { id } = foundCategory;

    return id;
  }

  public lock(value: boolean): void {
    this.store.dispatch(new fromDataPanel.LockTable(value));
  }

  public save(): void {
    this.dataInputService.save();
  }

  public import(): void {
    this.modal.open(MODAL_ID);
  }

  public export(): void {
    this.dataInputService.export();
  }

  public selectLockedState(): Observable<boolean> {
    return this.store.pipe(select(fromDataPanel.getLockedState));
  }
}
