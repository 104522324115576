import { IEnvDto } from "@co2-shared/common";
import { IExtendedCockpitTab } from "../models/co2-cockpit/extended-tab.interface";
import { UnitConfig } from "@enersis/gp-components/gp-kpi";

const UNITS: { [key: string]: UnitConfig } = {
  tonsCo2: {
    category: "weight",
    base: "t",
    onlyUnits: ["t"],
    numberFormat: {
      maximumFractionDigits: 0
    }
  },
  GWh: {
    category: "decimal",
    base: "k",
    unitSuffix: "Wh",
    onlyUnits: ["G"],
    numberFormat: {
      maximumFractionDigits: 1
    }
  },
  percentage: {
    category: "percentage",
    base: "decimal",
    onlyUnits: ["%"],
    numberFormat: {
      maximumFractionDigits: 1
    }
  },
  gCo2PerKWh: {
    category: "weight",
    base: "g",
    onlyUnits: ["g"],
    unitSuffix: "\xA0CO<sub>2</sub>e/kWh",
    numberFormat: {
      maximumFractionDigits: 0
    }
  }
};

/**
 * Gets the tabs config for the CO2 cockpit (or diagram panel or report panel)
 * that used be fetched from the co2balance-energy-city-config-service via the
 * `clientConfig` endpoint.
 * @param envFile
 * @returns
 */
export function getTabsConfig(envFile: IEnvDto): IExtendedCockpitTab[] {
  const hideMainIssuerCharts = envFile?.co2balance?.featureFlags?.hideMainIssuerCharts ?? false;
  return [
    getGreenhouseGasTabConfig({ hideMainIssuerCharts }),
    getFinalEnergyTabConfig({ hideMainIssuerCharts }),
    getDatabaseTabConfig()
  ];
}

function getGreenhouseGasTabConfig(featureFlags: { hideMainIssuerCharts: boolean }): IExtendedCockpitTab {
  const sectors_subtab: IExtendedCockpitTab = {
    name: "sectors",
    active: true,
    chart: {
      componentKey: "ChartCo2SectorsComponent"
    },
    kpi: [
      {
        id: "co2_cockpit_extended_kpi",
        type: "row",
        items: [
          {
            id: "greenhouse_gases_sectors_total",
            unitConfig: UNITS.tonsCo2,
            icon: "gpi-filled-co2-emissions",
            title: "COCKPIT.CO2.KPI.GREENHOUSE_GASES_SECTORS_TOTAL",
            mapping: "total",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "greenhouse_gases_sectors_average",
            unitConfig: UNITS.tonsCo2,
            icon: "gpi-average",
            title: "COCKPIT.CO2.KPI.GREENHOUSE_GASES_SECTORS_AVERAGE",
            mapping: "average",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "greenhouse_gases_sectors_difference_to_average",
            unitConfig: UNITS.tonsCo2,
            icon: "gpi-delta",
            title: "COCKPIT.CO2.KPI.GREENHOUSE_GASES_SECTORS_DIFFERENCE_TO_AVERAGE",
            mapping: "difference_to_average",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          }
        ]
      }
    ],
    description: "COCKPIT.CO2.TABS.GREENHOUSE_GAS.TABS.SECTORS.DESCRIPTION"
  };

  const energy_related_subtab: IExtendedCockpitTab = {
    name: "energy_related",
    chart: {
      componentKey: "ChartEnergyRelatedGreenhouseGasesComponent"
    },
    kpi: [
      {
        id: "co2_cockpit_extended_kpi",
        type: "row",
        items: [
          {
            id: "energy_related_greenhouse_gases_total",
            unitConfig: UNITS.tonsCo2,
            icon: "gpi-filled-co2-emissions",
            title: "COCKPIT.CO2.KPI.ENERGY_RELATED_GREENHOUSE_GASES_TOTAL",
            mapping: "total",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "energy_related_greenhouse_gases_average",
            unitConfig: UNITS.tonsCo2,
            icon: "gpi-average",
            title: "COCKPIT.CO2.KPI.ENERGY_RELATED_GREENHOUSE_GASES_AVERAGE",
            mapping: "average",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "energy_related_greenhouse_gases_difference_to_average",
            unitConfig: UNITS.tonsCo2,
            icon: "gpi-delta",
            title: "COCKPIT.CO2.KPI.ENERGY_RELATED_GREENHOUSE_GASES_DIFFERENCE_TO_AVERAGE",
            mapping: "difference_to_average",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          }
        ]
      }
    ],
    description: "COCKPIT.CO2.TABS.GREENHOUSE_GAS.TABS.ENERGY_RELATED.DESCRIPTION"
  };

  const main_reason_subtab: IExtendedCockpitTab = {
    name: "main_reason",
    chart: {
      componentKey: "Co2EmissionMainPolluterChartComponent"
    },
    kpi: [
      {
        id: "co2_cockpit_extended_kpi",
        type: "row",
        items: [
          {
            id: "greenhouse_gases_co2_main_reason_electricity_total",
            unitConfig: UNITS.tonsCo2,
            icon: "gpi-filled-squared-bolt",
            title: "KPI.CO2_ELECTRICITY",
            mapping: "electricity",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "greenhouse_gases_co2_main_reason_heat_total",
            unitConfig: UNITS.tonsCo2,
            icon: "gpi-squared-radiator",
            title: "KPI.CO2_HEAT",
            mapping: "heat",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "greenhouse_gases_co2_main_reason_transportation_total",
            unitConfig: UNITS.tonsCo2,
            icon: "gpi-filled-car",
            title: "KPI.CO2_TRANSPORTATION",
            mapping: "transportation"
          }
        ]
      }
    ],
    description: "COCKPIT.CO2.TABS.GREENHOUSE_GAS.TABS.MAIN_REASON.DESCRIPTION"
  };

  return {
    name: "greenhouse_gas",
    active: true,
    tabs: [sectors_subtab, energy_related_subtab, ...(featureFlags.hideMainIssuerCharts ? [] : [main_reason_subtab])]
  };
}

function getFinalEnergyTabConfig(featureFlags: { hideMainIssuerCharts: boolean }): IExtendedCockpitTab {
  const sectors_subtab: IExtendedCockpitTab = {
    name: "sectors",
    active: true,
    chart: {
      componentKey: "ChartFinalEnergySectorsComponent"
    },
    kpi: [
      {
        id: "co2_cockpit_extended_kpi",
        type: "row",
        items: [
          {
            id: "enlarged_final_energy_sectors_energy_total",
            unitConfig: UNITS.GWh,
            icon: "gpi-filled-final-energy",
            title: "COCKPIT.CO2.KPI.FINAL_ENERGY_SECTORS_ENERGY_TOTAL",
            mapping: "total",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "enlarged_final_energy_sectors_energy_average",
            unitConfig: UNITS.GWh,
            icon: "gpi-average",
            title: "COCKPIT.CO2.KPI.FINAL_ENERGY_SECTORS_ENERGY_AVERAGE",
            mapping: "average",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "enlarged_final_energy_sectors_energy_difference_to_average",
            unitConfig: UNITS.GWh,
            icon: "gpi-delta",
            title: "COCKPIT.CO2.KPI.FINAL_ENERGY_SECTORS_ENERGY_DIFFERENCE_TO_AVERAGE",
            mapping: "difference_to_average",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          }
        ]
      }
    ],
    description: "COCKPIT.CO2.TABS.FINAL_ENERGY.TABS.SECTORS.DESCRIPTION"
  };

  const energy_carriers_subtab: IExtendedCockpitTab = {
    name: "energy_carrier",
    chart: {
      componentKey: "ChartFinalEnergyPerEnergyCarrierComponent"
    },
    kpi: [
      {
        id: "co2_cockpit_extended_kpi",
        type: "row",
        items: [
          {
            id: "final_energy_energy_carrier_total",
            unitConfig: UNITS.GWh,
            icon: "gpi-filled-final-energy",
            title: "COCKPIT.CO2.KPI.FINAL_ENERGY_ENERGY_CARRIER_ENERGY_TOTAL",
            mapping: "total",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "final_energy_energy_carrier_average",
            unitConfig: UNITS.GWh,
            icon: "gpi-average",
            title: "COCKPIT.CO2.KPI.FINAL_ENERGY_ENERGY_CARRIER_ENERGY_AVERAGE",
            mapping: "average",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "final_energy_energy_carrier_difference_to_average",
            unitConfig: UNITS.GWh,
            icon: "gpi-delta",
            title: "COCKPIT.CO2.KPI.FINAL_ENERGY_ENERGY_CARRIER_DIFFERENCE_TO_AVERAGE",
            mapping: "difference_to_average",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          }
        ]
      }
    ],
    description: "COCKPIT.CO2.TABS.FINAL_ENERGY.TABS.ENERGY_CARRIER.DESCRIPTION"
  };

  const main_reason_subtab: IExtendedCockpitTab = {
    name: "main_reason",
    chart: {
      componentKey: "PublicSectorMainPolluterChartComponent"
    },
    kpi: [
      {
        id: "co2_cockpit_extended_kpi",
        type: "row",
        items: [
          {
            id: "greenhouse_gases_ps_main_reason_electricity_total",
            unitConfig: UNITS.GWh,
            icon: "gpi-filled-squared-bolt",
            title: "KPI.ENERGY_CONSUMPTION.ELECTRICITY",
            mapping: "electricity",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "greenhouse_gases_ps_main_reason_heat_total",
            unitConfig: UNITS.GWh,
            icon: "gpi-squared-radiator",
            title: "KPI.ENERGY_CONSUMPTION.HEAT",
            mapping: "heat",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "greenhouse_gases_ps_main_reason_transportation_total",
            unitConfig: UNITS.GWh,
            icon: "gpi-filled-car",
            title: "KPI.ENERGY_CONSUMPTION.TRANSPORTATION",
            mapping: "transportation"
          }
        ]
      }
    ],
    description: "COCKPIT.CO2.TABS.GREENHOUSE_GAS.TABS.MAIN_REASON.DESCRIPTION"
  };

  const traffic_subtab: IExtendedCockpitTab = {
    name: "traffic",
    chart: {
      componentKey: "ChartFinalEnergyTrafficComponent"
    },
    kpi: [
      {
        id: "co2_cockpit_extended_kpi",
        type: "row",
        items: [
          {
            id: "final_energy_traffic_total",
            unitConfig: UNITS.GWh,
            icon: "gpi-filled-final-energy",
            title: "COCKPIT.CO2.KPI.FINAL_ENERGY_TRAFFIC_ENERGY_TOTAL",
            mapping: "total"
          },
          {
            id: "final_energy_traffic_average",
            unitConfig: UNITS.GWh,
            icon: "gpi-average",
            title: "COCKPIT.CO2.KPI.FINAL_ENERGY_TRAFFIC_ENERGY_AVERAGE",
            mapping: "average"
          },
          {
            id: "final_energy_traffic_difference_to_average",
            unitConfig: UNITS.GWh,
            icon: "gpi-delta",
            title: "COCKPIT.CO2.KPI.FINAL_ENERGY_TRAFFIC_DIFFERENCE_TO_AVERAGE",
            mapping: "difference_to_average"
          }
        ]
      }
    ],
    description: "COCKPIT.CO2.TABS.FINAL_ENERGY.TABS.TRAFFIC.DESCRIPTION"
  };

  return {
    name: "final_energy",
    tabs: [
      sectors_subtab,
      energy_carriers_subtab,
      ...(featureFlags.hideMainIssuerCharts ? [] : [main_reason_subtab]),
      traffic_subtab
    ]
  };
}

function getDatabaseTabConfig(): IExtendedCockpitTab {
  const federal_mix_subtab: IExtendedCockpitTab = {
    name: "federal_mix",
    active: true,
    chart: {
      componentKey: "FederalMixComponent"
    },
    kpi: [
      {
        id: "co2_cockpit_extended_kpi",
        type: "row",
        items: [
          {
            id: "share_of_renewables_in_consumption",
            unitConfig: UNITS.percentage,
            icon: "gpi-filled-co2-emissions",
            title: "COCKPIT.CO2.KPI.SHARE_OF_RENEWABLES_IN_CONSUMPTION",
            mapping: "share",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "specific_emission_factor",
            unitConfig: UNITS.gCo2PerKWh,
            icon: "gpi-average",
            title: "COCKPIT.CO2.KPI.SPECIFIC_EMISSION_FACTOR",
            mapping: "emission_factor",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "change_since_1990",
            unitConfig: UNITS.percentage,
            icon: "gpi-delta",
            title: "COCKPIT.CO2.KPI.CHANGE_SINCE_1990",
            mapping: "change_since_1990",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          }
        ]
      }
    ],
    description: "COCKPIT.CO2.TABS.DATABASE.TABS.FEDERAL_MIX.DESCRIPTION"
  };

  const local_mix_subtab: IExtendedCockpitTab = {
    name: "local_energy_mix",
    chart: {
      componentKey: "LocalElectricityComponent"
    },
    kpi: [
      {
        id: "co2_cockpit_extended_kpi",
        type: "row",
        items: [
          {
            id: "share_of_renewables_in_consumption",
            unitConfig: UNITS.percentage,
            icon: "gpi-filled-co2-emissions",
            title: "COCKPIT.CO2.KPI.SHARE_OF_RENEWABLES_IN_CONSUMPTION",
            mapping: "share",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "specific_emission_factor",
            unitConfig: UNITS.gCo2PerKWh,
            icon: "gpi-average",
            title: "COCKPIT.CO2.KPI.SPECIFIC_EMISSION_FACTOR",
            mapping: "emission_factor",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          },
          {
            id: "change_since_1990",
            unitConfig: UNITS.percentage,
            icon: "gpi-delta",
            title: "COCKPIT.CO2.KPI.CHANGE_SINCE_1990",
            mapping: "change_since_1990",
            describing_icons: [
              {
                name: "ec_witterungskorrektur",
                icon: "ec_witterungskorrektur",
                active: false
              }
            ]
          }
        ]
      }
    ],
    description: "COCKPIT.CO2.TABS.DATABASE.TABS.LOCAL_ENERGY_MIX.DESCRIPTION"
  };

  return {
    name: "database",
    tabs: [federal_mix_subtab, local_mix_subtab]
  };
}
