import { TranslateService } from "@ngx-translate/core";
import { DecimalPipe } from "@angular/common";
import * as Highcharts from "highcharts";
import { SectorType, MainPolluterDataType } from "../../../../models/charts.models";

export const ROOT_KEY = "root";
export const TOTAL_KEY = "TOTAL";
export const COLOR_MODEL = "rgba";

export const COLORS = {
  root: "transparent",
  electricity: "#CA2525",
  transportation: "#0592A1",
  heat: "#560F50"
};

export const SECTORS_LIST = [
  SectorType.TOTAL_BALANCE,
  SectorType.PRIVATE_HOUSEHOLD,
  SectorType.COMMERCE,
  SectorType.INDUSTRY,
  SectorType.COMMUNITY_FACILITIES,
  SectorType.TRANSPORTATION
];

export const TRANSPORTATION_KEY = {
  [SectorType.TOTAL_BALANCE]: "TRANSPORTAT_AND_MECHANICAL_ENERGY",
  [SectorType.PRIVATE_HOUSEHOLD]: "MECHANICAL_ENERGY",
  [SectorType.COMMERCE]: "MECHANICAL_ENERGY",
  [SectorType.INDUSTRY]: "MECHANICAL_ENERGY",
  [SectorType.COMMUNITY_FACILITIES]: "MECHANICAL_ENERGY",
  [SectorType.TRANSPORTATION]: "TRANSPORTATION"
};

export class MainPolluter {
  constructor(private translate: TranslateService, private decimalPipe: DecimalPipe) {}

  public getOptions(dataType: MainPolluterDataType): any {
    const PIPE_FORMAT = "1.0-0";
    const LOCALE = "de-DE";
    const totalTitle = this.getTranslate(TOTAL_KEY);
    const pipeTransformer = this.decimalPipe.transform;
    const unit = this.getUnit(dataType);

    return {
      chart: {
        unit: " [t]",
        height: 331
      },
      title: {
        displayed: false
      },
      plotOptions: {
        series: {
          events: {
            click: null
          }
        }
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        padding: 0,
        formatter: function () {
          const { value, parent = ROOT_KEY, name, id } = this.point;
          const parentPoint = this.series.data.find((item) => item.id === parent);
          const percentage = Math.round((value / parentPoint.value) * 100);
          const formattedValue = pipeTransformer(value, PIPE_FORMAT, LOCALE);
          const formattedTotal = pipeTransformer(parentPoint.value, PIPE_FORMAT, LOCALE);
          let header = "";

          if (id !== ROOT_KEY) {
            header = `
              <b>${parentPoint.name}</b>
              <p>${name}</p>
            `;
          }

          return `
            ${header}
            <p>${formattedValue} ${unit} (${percentage} %)</p>
            <p>${totalTitle}: ${formattedTotal} ${unit}</p>
          `;
        }
      }
    };
  }

  public getSeries(response: any, sector: string): any {
    const data = this.getFormattedData(response, sector).filter((item: any) => item.value !== 0);
    const dataWithoutRoot = data.filter((item: any) => item.value !== undefined);

    return [
      {
        type: "sunburst",
        cursor: "pointer",
        center: ["45%", "50%"],
        size: "80%",
        allowDrillToNode: true,
        dataLabels: {
          enabled: false
        },
        // highcharts renders sectors with value 0 in some cases
        // as quarters. To avoid this problem, we only add sectors
        // where value is different from 0
        data: dataWithoutRoot.length ? data : []
      }
    ];
  }

  private getFormattedData(data: object, sector: string): Array<object> {
    const root = {
      id: ROOT_KEY,
      name: this.getTranslate(sector),
      color: COLORS.root
    };
    const flattened = this.flatten(data, ROOT_KEY);

    flattened.sort((prev: any, curr: any) => {
      return curr.parent === prev.parent ? curr.value - prev.value : 0;
    });

    const adapted = this.getAdaptedProperties(flattened, sector);

    return [root, ...adapted];
  }

  private getAdaptedProperties(data: Array<object>, sector: string): Array<object> {
    let index = 0;
    let color = "";
    let decimal = 0;

    return data.map((item: any) => {
      if (!item.parent) {
        return;
      }

      const name = item.name;
      const translationKey = SectorType.TRANSPORTATION === name ? TRANSPORTATION_KEY[sector] : name;

      item.name = this.getTranslate(translationKey);

      if (item.parent === ROOT_KEY) {
        color = COLORS[name];
        index = 0;
        decimal = item.length + 1;

        return { ...item, color };
      } else {
        index += 1;

        const _color: Highcharts.ColorType = new Highcharts.Color(color)
          .setOpacity(1 - index / decimal)
          .get(COLOR_MODEL);

        return {
          ...item,
          color: _color as string
        };
      }
    });
  }

  private flatten(data: object, parent = ""): Array<object> {
    const keys = Object.keys(data);

    return keys.reduce((acc, name) => {
      const node = data[name];
      const id = `${parent}.${name}`;
      const item: any = { id, name, parent };

      if (typeof node === "object") {
        const flattened = this.flatten(node, `${parent}.${name}`);
        const { length } = Object.keys(node);
        const newItem = { ...item, length };

        return [...acc, newItem, ...flattened];
      } else {
        const newItem = { ...item, value: node };

        return [...acc, newItem];
      }
    }, []);
  }

  private getTranslate(name: string): string {
    return this.translate.instant(`RESOURCES.${name.toUpperCase()}`);
  }

  private getUnit(dataType: MainPolluterDataType): string {
    switch (dataType) {
      case MainPolluterDataType.CO2: {
        return "t CO<sub>2</sub>e";
      }
      case MainPolluterDataType.PUBLIC_SECTOR: {
        return "kWh";
      }
      default: {
        return "";
      }
    }
  }
}
