import { FactorEmissionState } from "./index";
import { FactorEmissionActionTypes, FactorEmissionActionsUnion } from "./factor-emission.actions";

const initialState = (): FactorEmissionState => ({
  data: null,
  loading: false,
  errorMessage: ""
});

export function factorEmissionReducer(state: FactorEmissionState = initialState(), action: FactorEmissionActionsUnion): FactorEmissionState {
  switch (action.type) {
    case FactorEmissionActionTypes.GET_FACTOR_EMISSION_DATA: {
      return { ...state, loading: true };
    }
    case FactorEmissionActionTypes.GET_FACTOR_EMISSION_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: "",
        data: action.payload
      };
    }
    case FactorEmissionActionTypes.GET_FACTOR_EMISSION_DATA_ERROR: {
      return {
        ...initialState(),
        loading: false,
        errorMessage: action.errorMessage
      };
    }
    case FactorEmissionActionTypes.PATCH_FACTOR_EMISSION_DATA: {
      return { ...state, loading: true };
    } 
    default: {
      return state;
    }
  }
}
