import { Component, OnInit, ViewChild } from "@angular/core";
import { Subscription, zip } from "rxjs";
import { map } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import * as fromRoot from "../../../../state/index";
import { TenantInfoService } from "../../../../services/tenantInfo.service";
import { ModulesService } from "../../../../services/modules.service";
import { RegionService } from "../../../../services/region.service";
import { AppActionType } from "../../../../state/index";
import { IDistrictKpi, DistrictKpi } from "../../../../models/kpi";

import { TabsContainerComponent } from "@energy-city/ui/tabs";
import { ofType } from "@ngrx/effects";
import { assetUrl } from "../../../../share/asset-url";

@Component({
  selector: "app-info-sectors",
  templateUrl: "./info-sectors.component.html",
  styleUrls: ["./info-sectors.component.scss"]
})
export class InfoSectorsComponent implements OnInit {
  @ViewChild("tabs", { static: true }) private tabs: TabsContainerComponent;
  protected subscriptionCollection: Array<Subscription> = [];
  public infoSectorsTab: any = {};
  public population: number;
  public buildings_count: number;
  public area_m2: number;
  public pipeDecimal = {
    type: "decimal"
  };
  public pipeArea = {
    type: "eneFormatUnit",
    category: "area",
    unit: "m²"
  };

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private moduleService: ModulesService,
    private regionService: RegionService,
    private tenantInfoService: TenantInfoService
  ) {}

  public ngOnInit() {
    this.store.pipe(select(fromRoot.GetAppState), ofType(AppActionType.GET_CLIENT_CONFIG_SUCCESS)).subscribe(() => {
      this.infoSectorsTab = this.moduleService.getPanelOfActiveModule("info").tabs[0];
    });

    zip(this.regionService.selectedRegionCountBuildings$, this.regionService.selectedRegionDetails$)
      .pipe(map(([countBuildings, region]) => new DistrictKpi({ countBuildings, region })))
      .subscribe((data: IDistrictKpi) => {
        this.population = data.population;
        this.buildings_count = data.buildings_count;
        this.area_m2 = data.area_m2;
      });
  }

  public goToTab() {
    this.tabs.selectTab(1);
  }

  public getTenantSpecificTranslation(path: string): string {
    return this.tenantInfoService.getTranslation(path);
  }

  public getAssetUrl(url: string): string {
    return assetUrl(url);
  }
}
