import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneAccordeonComponent } from "./accordeon/accordeon.component";
import { EneTopicComponent } from "./topic/topic.component";

@NgModule({
  declarations: [EneAccordeonComponent, EneTopicComponent],
  imports: [EneIconModule, CommonModule, TranslateModule],
  exports: [EneAccordeonComponent, EneTopicComponent]
})
export class EneAccordeonModule {}
