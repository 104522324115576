import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Observable, of, concat, EMPTY } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IRegionIdentifier } from "../model/region-identifier";
import { API_ENDPOINT_SERVICE_TOKEN, IApiEndpointService } from "../utils/api-endpoint.interface";
import { acceptJsonWithoutCache } from "../utils/http-headers";

@Injectable({
  providedIn: "root"
})
export class CoaDataService {
  constructor(
    private http: HttpClient,
    @Inject(API_ENDPOINT_SERVICE_TOKEN) private apiEndpointService: IApiEndpointService,
    private domSanitizer: DomSanitizer
  ) {}

  /**
   * --- A small history lesson ---
   *
   * The coat of arms (CoA) was initially stored in the config service as static
   * assets, committed to the repository. The name of each file was the
   * regionId. The only place where the CoA was used was in the print header
   * component.
   *
   * Later on, there was a decision that because CoAs "belong" to a region, they
   * should be stored in the region core service (infrastructure-region).
   * Onboarding new Regions would mean, that also the CoA would be uploaded via
   * an ETL process that moves the CoA to the S3 bucket of the region. In the DB
   * alongside the region data, the URL to the CoA was stored. A fallback was
   * introduced, that if the CoA was not found in the region core, it would be
   * fetched from the config service.
   *
   * With the inception of the GAIA platform project, the CoA should now be
   * displayed first time outside of the co2balance module, in the header area
   * of the GAIA platform (region-selection MFE). Because of time pressure to
   * get to marked as soon as possible, a separate CoA solution was introcued
   * "just for the time being": storing the CoA in the gp-root-frontend directly
   * as assets, just as it was in the config service. In the "regions.json" file
   * that was deployed alongside the gp-root-frontend, the URL to the CoA was
   * manually entered for each region. Although we had now 3 places where the
   * CoA could be stored, it was possible to just tell the GAIA platform to use
   * the CoA from the region core service.
   *
   * At some point, uploading the CoA to the region core service brought this
   * "bug", where the content-type of each svg file was "octet-stream" instead
   * of "image/svg+xml". The problem was "fixed" by loading the svg file as text
   * and then converting it to a data-url. This problem and this workaround was
   * the reason why we could not always just use the CoA from the region core
   * service in the region-selection header.
   *
   * --- The new standard ---
   *
   * As we learnt from xkcd (https://xkcd.com/927), the only way to unify
   * multiple competing standards is to introduce a new one. The new standard
   * is:
   * - The CoAs are stored in our strapi CMS alongside each (selectable) region.
   * - The region property service tries to fetch the CoA from the CMS and will
   *   fall back to the region core service.
   * - If no CoA was found in either the CMS or the region core service, the
   *   frontend will try to fetch the CoA from the config service, as it was
   *   before.
   * - Because the octet-stream problem still exists for many existing CoAs, but
   *   the region-property might also return valid svg, we just try to load the
   *   CoA with the src attribute of the img tag. If it fails, we try to re-load
   *   it as data-url.
   *
   * hint: loading the CoA _always_ as data-url doesn't work, because the s3
   * bucket of the CMS does not allow CORS requests, in contrast to the "old" s3
   * buckets.
   */
  getCoaImage(
    region: IRegionIdentifier,
    coaLoadError$?: Observable<string>
  ): Observable<SafeResourceUrl | string | null> {
    return this.getCoaUrlS3(region).pipe(
      switchMap((url) => {
        // if no url was found, we try our luck at the config service
        if (url) {
          return concat(
            of(url),
            coaLoadError$?.pipe(switchMap((erroredUrl) => this.reloadCoaAsDataUrl(erroredUrl))) || EMPTY
          );
        }
        return this.getCoaConfigService(region);
      }),

      catchError(() => of(null))
    );
  }

  public getCoaUrlConfigService(region: IRegionIdentifier): string {
    return `${this.apiEndpointService.getConfigApi()}/images/coatOfArms/${region.regionId}.svg`;
  }

  private getCoaUrlS3(region: IRegionIdentifier): Observable<string | null> {
    const url = `${this.apiEndpointService.getRegionPropertyApi()}/region/coa`;
    const params = new HttpParams({
      fromObject: {
        regionId: region.regionId,
        regionType: region.regionType
      }
    });
    return this.http
      .get<{ coa: string }>(url, { headers: new HttpHeaders(acceptJsonWithoutCache), params })
      .pipe(
        // in case of no image available we get an empty response
        map((url) => url?.coa),
        catchError(() => of(null))
      );
  }

  private getCoaConfigService(region: IRegionIdentifier): Observable<SafeResourceUrl> {
    return this.http
      .get(this.getCoaUrlConfigService(region), {
        responseType: "text"
      })
      .pipe(
        map((src) => {
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml,${encodeURIComponent(src)}`);
        })
      );
  }

  public reloadCoaAsDataUrl(url: string): Promise<SafeResourceUrl> {
    const imgType = url.slice(-3) === "svg" ? "image/svg+xml" : "image/png";
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const dataUrl = (reader.result as string).replace(/^data:.*?;/i, `data:${imgType};`);
            resolve(this.domSanitizer.bypassSecurityTrustResourceUrl(dataUrl));
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }
}
