import { Inject, Injectable } from "@angular/core";
import { Filter } from "@enersis/gp-components/gp-map2-filter-control";
import { IGpMapOptions, MapConfig } from "@enersis/gp-components/gp-map2-viewer";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Accounting } from "../../accounting/accounting-common/model/accounting";
import { Sector } from "../../accounting/accounting-common/model/sectors/sector.enum";
import { IRegion } from "../../common/model/region-identifier";
import { RegionType } from "../../common/model/region-type.enum";
import { API_ENDPOINT_SERVICE_TOKEN, IApiEndpointService } from "../../common/utils/api-endpoint.interface";
import { ITokenService, TOKEN_SERVICE_TOKEN } from "../../common/utils/token.interface";
import { BuildingLayerConfig } from "../model/building-layer-config";
import { TenantMapConfig } from "../model/tenant-map-config";
import { IMainRegion, MainRegionService } from "./main-region.service";
import { TenantMapConfigService } from "./tenant-map-config.service";

const DEFAULT_ZOOM_LEVELS: { [regionLevel: number]: number } = {
  20: 6.9,
  40: 8,
  50: 10,
  60: 14
};

@Injectable({
  providedIn: "root"
})
export class MapConfigService {
  private readonly buildingLayerConfig: BuildingLayerConfig;
  private tenantConfig!: TenantMapConfig;

  constructor(
    private tenantMapConfigService: TenantMapConfigService,
    private mainRegionService: MainRegionService,
    @Inject(API_ENDPOINT_SERVICE_TOKEN) private apiEndpointService: IApiEndpointService,
    @Inject(TOKEN_SERVICE_TOKEN) private tokenService: ITokenService
  ) {
    this.buildingLayerConfig = new BuildingLayerConfig();
  }

  getMapConfig(region: IRegion, accounting: Accounting): Observable<MapConfig> {
    return this.tenantMapConfigService.getTenantConfig().pipe(
      map(
        (tenantConfig): MapConfig => {
          this.tenantConfig = tenantConfig;
          const mainRegion = this.mainRegionService.getMainRegion();
          const initialZoomLevel = this.getInitialZoomLevel(mainRegion);
          return {
            viewer: {
              center: region.coordinates,
              zoom: initialZoomLevel,
              glyphs: this.apiEndpointService.assetUrl("map/fonts/{fontstack}/{range}.pbf"),
              sources: {
                building: this.buildingLayerConfig.getSource(
                  this.apiEndpointService.getRegionPropertyApi(),
                  region.regionId,
                  tenantConfig.buildingYear ?? accounting.year,
                  tenantConfig.isBuildingYearFix()
                ),
                region: tenantConfig.getRegionSource(
                  this.apiEndpointService.getRegionPropertyApi(),
                  accounting.year,
                  accounting.accountingMethod,
                  accounting.weatherCorrection,
                  accounting.electricityMix,
                  accounting.useUserFactors,
                  accounting.timestamp
                )
              },
              terrain: { exaggeration: 1.2, active: false },
              layers: [
                { type: "template", id: "streetV1/sky" },
                { type: "template", id: "streetV1/satellite" },
                { type: "template", id: "streetV1/basemapFills" },
                { type: "template", id: "streetV1/basemapLines" },
                this.buildingLayerConfig.getLayerConfig(),
                ...this.tenantConfig.getLayerConfigs(),

                { type: "template", id: "streetV1/building-number-label" },
                { type: "template", id: "streetV1/basemapSymbols" }
              ]
            },
            extensions: {
              legend2: true,
              layerControl: {
                sections: [this.buildingLayerConfig.getLayerControlConfig(), this.tenantConfig.getLayerControlConfig()]
              },
              filterControl: {
                filters: this.getFilters(accounting.getSectors())
              },
              searchbar: true,
              sourceHandler: true
            }
          };
        }
      )
    );
  }

  getRegionLevel(): Array<string> {
    return this.tenantConfig.getRegionLevels();
  }

  getMapOptions(): IGpMapOptions {
    return {
      accessToken: "pk.eyJ1IjoiZW5lcnNpcy1jaCIsImEiOiJjbDQ1azBhbTgyMXdyM2lxeWp5anpxMzQ0In0.O7wGeQwfTuufeiNd_ssn4g",
      transformRequest: this.transformWithCurrentToken()
    };
  }

  getFilters(sectors: Array<Sector>): Array<Filter> {
    return [this.tenantConfig.getUnselectableRegionFilter(), this.tenantConfig.getSectorFilter(sectors)];
  }

  getRegionType(layerId: string): RegionType {
    return (
      this.tenantConfig.regionLayers.find((layer) => layer.getLayerId() === layerId)?.getRegionType() ||
      RegionType.BASIC
    );
  }

  private transformWithCurrentToken(): mapboxgl.TransformRequestFunction {
    return (url: string) => ({
      url,
      headers: { Authorization: `Bearer ${this.tokenService.getCo2BalanceToken()}` }
    });
  }

  private getInitialZoomLevel(mainRegion: IMainRegion): number {
    if (mainRegion.zoomLevel) {
      return mainRegion.zoomLevel;
    }
    const zoomLevel = DEFAULT_ZOOM_LEVELS[mainRegion.level];
    if (zoomLevel === undefined) {
      throw Error(`No zoom level defined for region level ${mainRegion.level}`);
    }
    return zoomLevel;
  }
}
