<div
  class="wrapper"
  [popper]="dqiPopper"
  [popperTrigger]="'click'"
  [popperPlacement]="'auto-end'"
  [popperCloseOnClickOutside]="true"
  [popperDisabled]="disabled"
>
  <!-- <ng-template ngFor let-index [ngForOf]="[0,1,2,3,4]"> -->
  <div class="dqi__element" [ngClass]="{ active: dqi > index }" *ngFor="let index of [0, 1, 2, 3, 4]"></div>
  <!-- </ng-template> -->
</div>
<popper-content #dqiPopper class="popover dqi">
  <ng-content></ng-content>
  <ene-range-slider
    [config]="sliderConfig"
    [step]="1"
    [(ngModel)]="dqi"
    [tooltips]="true"
    [connect]="[true, false]"
    [min]="0"
    [max]="5"
    class="circles numbers"
    (change)="onChange($event)"
    ngDefaultControl
    #slider
  ></ene-range-slider>
</popper-content>
