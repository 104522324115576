import { showNotification, NotificationSettings } from "@enersis/gp-components/gp-notification";

/**
 * A wrapper around the gp-notification API.
 * Usage:
 * ```ts
 * notification.success("message");
 * notification.warning("message");
 * notification.info("message");
 *
 * // or with additional options:
 * notification.success("message", { hasDismissButton: true, persistent: true });
 * ```
 */
export const notification = {
  /** shows a success-notification for a short time */
  success: (message: string, options?: NotificationSettings) =>
    showNotification(message, {
      type: "success",
      duration: 2500,
      ...options
    }),

  /** shows a warning-notification for slightly longer time */
  warning: (message: string, options?: NotificationSettings) =>
    showNotification(message, {
      type: "warning",
      duration: 5000,
      ...options
    }),

  /** shows an info-notification for slightly longer time */
  info: (message: string, options?: NotificationSettings) =>
    showNotification(message, {
      type: "warning",
      duration: 5000,
      ...options
    })
};
