import { Component, HostListener, Inject, NgZone, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as fromRoot from "@energy-city/components";

import {
  ModulesService,
  AuthenticationService,
  UtilService,
  BasicAppComponent,
  AuthActionType,
  GetClientConfig,
  AppActionType,
  assetUrl,
  injectCssLinkToDocumentRoot
} from "@energy-city/components";
import { TranslateService } from "@ngx-translate/core";
import { get } from "lodash";
import { DEFAULT_LANGUAGES } from "../../../../libs/components/src/lib/app/modules/header/components/user-popover/user-popover.models";
import { EventSubscriptionToken, unsubscribeFromEvent } from "@enersis/gp-components/events";

import { languageSlice } from "@enersis/gp-components/language";
import { updateLocale } from "@enersis/gp-components/locale";
import { DOCUMENT } from "@angular/common";

declare var require: any;

@Component({
  selector: "co2balance-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent extends BasicAppComponent implements OnInit, OnDestroy {
  public panelsWidth: number;

  private searchResultSubscription: EventSubscriptionToken;
  private languageMap: { [key: string]: string } = {
    de: "de-de",
    "de-ch": "de-ch",
    fr: "fr-fr",
    en: "en-us"
  };

  constructor(
    protected appStore: Store<fromRoot.IApplicationState>,
    protected moduleService: ModulesService,
    protected authenticationService: AuthenticationService,
    protected utilService: UtilService,
    protected translate: TranslateService,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();

    this.auth$ = this.appStore.pipe(select(fromRoot.GetAuthState));
    this.authSubscription = this.auth$.subscribe((res) => {
      switch (res.type) {
        case AuthActionType.LOGIN_SUCCESS:
          this.authState = res.payload;
          this.appStore.dispatch(new GetClientConfig({}));
          // Activate Google Analytics only when in Production environment
          if (this.authenticationService.tokenParsed.gaProperty) {
            (window as any).activateGoogleAnalytics(this.authenticationService.tokenParsed.gaProperty);
          } else {
            console.error("Couldn't activate Analytics. Can't find gaProperty");
          }
          break;
      }
    });
    this.app$ = this.appStore.select(fromRoot.GetAppState);
    this.appSubscription = this.app$.subscribe((res) => {
      switch (res.type) {
        case AppActionType.GET_CLIENT_CONFIG_SUCCESS:
          const clientConfig = res.payload;
          this.contactModalType = clientConfig.useNewContactForm ? "new" : "old";
          this.prepareTheme();
          this.moduleService.setClientConfig(clientConfig);
          this.utilService.clientConfig = clientConfig;
          this.utilService.displayDataPrivacyMessage =
            get(clientConfig, "module[0].panels.buildings.displayDataPrivacyMessage") ||
            get(clientConfig, "module[0].panels.selection.displayDataPrivacyMessage") ||
            false;
          this.translate.setDefaultLang(DEFAULT_LANGUAGES[0].code);
          break;
      }
    });

    languageSlice.subscribe((lang) => {
      updateLocale(lang);
      this.zone.run(() => this.translate.use(this.languageMap[lang]));
    });
  }

  public ngOnInit(): void {
    injectCssLinkToDocumentRoot(assetUrl("/fonts/MaterialIcons.css"), this.document);
  }

  @HostListener("panelContainerWidthChanged", ["$event"])
  onPanelContainerWidthChanged(event) {
    this.panelsWidth = Number(event.detail);
  }

  public prepareTheme() {
    // set theme
    require("style-loader!libs/components/src/lib/styles/theme-co2balance.scss");
  }

  public ngOnDestroy() {
    unsubscribeFromEvent(this.searchResultSubscription);
  }
}
