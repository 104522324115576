<co2-kpi-region-indicators [accounting]="accounting$ | async"></co2-kpi-region-indicators>

<gp-card>
  <co2-kpi-emissions-per-capita [accounting]="accounting$ | async"></co2-kpi-emissions-per-capita>
</gp-card>

<gp-card>
  <co2-kpi-emissions-absolute-change
    [accounting]="accounting$ | async"
    [accounting1990]="accounting1990$ | async"
  ></co2-kpi-emissions-absolute-change>
  <co2-kpi-emissions-relative-change
    [accounting]="accounting$ | async"
    [accounting1990]="accounting1990$ | async"
  ></co2-kpi-emissions-relative-change>
</gp-card>

<gp-card>
  <co2-kpi-rank-emissions
    [accounting]="accounting$ | async"
    [enabled]="isRankEnabled$ | async"
  ></co2-kpi-rank-emissions>
</gp-card>

<app-chart-co2-sectors-compact></app-chart-co2-sectors-compact>
