import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EneIconModule } from "@energy-city/ui/icon";
import { SharedModule } from "@energy-city/components";
import { NavigationComponent } from "./navigation.component";
import { EnePopperModule } from "@energy-city/ui/popper";

@NgModule({
  declarations: [NavigationComponent],
  imports: [CommonModule, SharedModule, TranslateModule, EneIconModule, EnePopperModule],
  exports: [NavigationComponent]
})
export class DataInputNavigationModule {}
