<section class="header">
  <div class="header__logo-shield">
    <img
      class="coat-of-arms"
      [class.hiddenCOA]="!coatWasLoaded"
      (load)="coatLoaded()"
      (error)="onCoaLoadError($event)"
      [src]="coatOfArmsUrl$ | async"
    />
  </div>
  <div class="header__middle">
    <h2>{{ "PRINT_VIEW.SUBHEADER.TITLE" | translate }} {{ year }}</h2>
    <h2>{{ "PRINT_VIEW.SUBHEADER.SUBTITLE" | translate }} {{ regionName }}</h2>
  </div>
  <ng-container *ngIf="regionDetails$ | async as regionDetails">
    <table class="header__end">
      <tr>
        <td>{{ "CLIENTS.DEFAULT.INFO.DETAILS.INHABITANTS" | translate }}</td>
        <ng-container *ngIf="regionDetails.population; else empty">
          <td [innerHTML]="regionDetails.population | eneUnit: pipeDecimal"></td>
        </ng-container>
      </tr>
      <tr>
        <td>{{ "CLIENTS.DEFAULT.INFO.DETAILS.BUILDINGS_COUNT" | translate }}</td>
        <ng-container *ngIf="buildings$ | async as buildings; else empty">
          <td [innerHTML]="buildings | eneUnit: pipeDecimal"></td>
        </ng-container>
      </tr>
      <tr>
        <td>{{ "CLIENTS.DEFAULT.INFO.DETAILS.AREA_M2" | translate }}</td>
        <ng-container *ngIf="regionDetails.area_m2; else empty">
          <td [innerHTML]="regionDetails.area_m2 | eneUnit: pipeArea"></td>
        </ng-container>
      </tr>
    </table>
  </ng-container>
</section>
<div>
  <gp-editable-text
    multiline
    editIcon
    [placeholder]="'PRINT_VIEW.EDITABLETEXT.PLACEHOLDER' | translate"
  ></gp-editable-text>
</div>
<ng-template #empty>
  <span>{{ "TDI.NOT_AVAILABLE.LONG" | translate }}</span>
</ng-template>
