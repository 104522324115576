import { Action } from "@ngrx/store";

export enum BenchmarkActions {
  GET_QUALITIVE_DATA = "[Benchmark] GET_QUALITIVE_DATA",
  GET_QUANTITIVE_DATA = "[Benchmark] GET_QUANTITIVE_DATA",
  GET_AVERAGE_DATA = "[Benchmark] GET_AVERAGE_DATA",
  GET_QUALITIVE_DATA_SUCCESS = "[Benchmark] GET_QUALITIVE_DATA_SUCCESS",
  GET_QUANTITIVE_DATA_SUCCESS = "[Benchmark] GET_QUANTITIVE_DATA_SUCCESS",
  ERROR = "[Benchmark] ERROR",
  GET_AVERAGE_DATA_SUCCESS = "[Benchmark] GET_AVERAGE_DATA_SUCCESS"
}

export class GetQualitiveBenchmark implements Action {
  public readonly type = BenchmarkActions.GET_QUALITIVE_DATA;
}

export class GetQualitiveBenchmarkSuccess implements Action {
  public readonly type = BenchmarkActions.GET_QUALITIVE_DATA_SUCCESS;
  constructor(public payload: { data: any }) {}
}

export class GetQuantitiveBenchmark implements Action {
  public readonly type = BenchmarkActions.GET_QUANTITIVE_DATA;
}

export class GetQuantitiveBenchmarkSuccess implements Action {
  public readonly type = BenchmarkActions.GET_QUANTITIVE_DATA_SUCCESS;
  constructor(public payload: { data: any }) {}
}

export class BenchmarkError implements Action {
  public readonly type = BenchmarkActions.ERROR;
  constructor() {}
}

export type BenchmarkActionTypes =
  | GetQualitiveBenchmarkSuccess
  | GetQuantitiveBenchmark
  | GetQuantitiveBenchmarkSuccess
  | GetQualitiveBenchmark;
