import { YearlyValue, TimeSeries } from "../../../accounting-common/model/time-series/time-series";

export class Scenario {
  constructor(
    public readonly isDefault: boolean,
    public readonly title: string,
    public readonly description: string,
    public readonly regionId: string,
    public readonly scenarioId: string,
    public readonly targets: Array<YearlyValue> = []
  ) {}

  getAbsoluteTargets(referenceValue: number): TimeSeries {
    const absoluteValues = [];
    for (const target of this.targets) {
      absoluteValues.push({ value: (1 - target.value) * referenceValue, year: target.year });
    }
    return new TimeSeries(absoluteValues, "reductionTargets");
  }
}
