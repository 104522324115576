/* tslint:disable:member-access */

import { Action } from "@ngrx/store";
import { ISimulationScenario } from "../../models/simulation-model";

export enum SimulationActionTypes {
  GET_DATA = "[Simulation] GET_DATA",
  GET_DATA_SUCCESS = "[Simulation] GET_DATA_SUCCESS",
  GET_DATA_FAILED = "[Simulation] GET_DATA_FAILED",
  GET_SCENARIOS = "[Simulation] GET_SCENARIOS",
  GET_SCENARIOS_SUCCESS = "[Simulation] GET_SCENARIOS_SUCCESS",
  GET_SCENARIOS_FAILED = "[Simulation] GET_SCENARIOS_FAILED",
  UPDATE_SCENARIO = "[Simulation] UPDATE_SCENARIOS",
  UPDATE_SCENARIO_SUCCESS = "[Simulation] UPDATE_SCENARIOS_SUCCESS",
  UPDATE_SCENARIO_FAILED = "[Simulation] UPDATE_SCENARIOS_FAILED",
  ADD_SCENARIO = "[Simulation] ADD_SCENARIOS",
  ADD_SCENARIO_SUCCESS = "[Simulation] ADD_SCENARIOS_SUCCESS",
  ADD_SCENARIO_FAILED = "[Simulation] ADD_SCENARIOS_FAILED",
  DELETE_SCENARIO = "[Simulation] DELETE_SCENARIOS",
  DELETE_SCENARIO_SUCCESS = "[Simulation] DELETE_SCENARIOS_SUCCESS",
  DELETE_SCENARIO_FAILED = "[Simulation] DELETE_SCENARIOS_FAILED"
}

export class GetScenariosSuccess implements Action {
  readonly type = SimulationActionTypes.GET_SCENARIOS_SUCCESS;

  constructor(public payload: { scenarios: any }) {}
}

export class GetScenariosFailed implements Action {
  readonly type = SimulationActionTypes.GET_SCENARIOS_FAILED;

  constructor() {}
}

export class GetScenarios implements Action {
  readonly type = SimulationActionTypes.GET_SCENARIOS;

  constructor() {}
}

export class UpdateScenario implements Action {
  readonly type = SimulationActionTypes.UPDATE_SCENARIO;

  constructor(public payload: ISimulationScenario) {}
}

export class UpdateScenarioSuccess implements Action {
  readonly type = SimulationActionTypes.UPDATE_SCENARIO_SUCCESS;

  constructor() {}
}

export class UpdateScenarioFailed implements Action {
  readonly type = SimulationActionTypes.UPDATE_SCENARIO_FAILED;

  constructor(public payload: any) {}
}

export class AddScenario implements Action {
  readonly type = SimulationActionTypes.ADD_SCENARIO;

  constructor(public payload: ISimulationScenario) {}
}

export class AddScenarioSuccess implements Action {
  readonly type = SimulationActionTypes.ADD_SCENARIO_SUCCESS;

  constructor() {}
}

export class AddScenarioFailed implements Action {
  readonly type = SimulationActionTypes.ADD_SCENARIO_FAILED;

  constructor(public payload: any) {}
}

export class DeleteScenario implements Action {
  readonly type = SimulationActionTypes.DELETE_SCENARIO;

  constructor(public payload: { scenarioId: string }) {}
}

export class DeleteScenarioSuccess implements Action {
  readonly type = SimulationActionTypes.DELETE_SCENARIO_SUCCESS;

  constructor() {}
}

export class DeleteScenarioFailed implements Action {
  readonly type = SimulationActionTypes.DELETE_SCENARIO_FAILED;

  constructor(public payload: any) {}
}

export type SimulationActions =
  | GetScenarios
  | GetScenariosSuccess
  | GetScenariosFailed
  | UpdateScenario
  | UpdateScenarioSuccess
  | UpdateScenarioFailed
  | AddScenario
  | AddScenarioSuccess
  | AddScenarioFailed
  | DeleteScenario
  | DeleteScenarioSuccess
  | DeleteScenarioFailed;
