import { NgModule } from "@angular/core";
import { ContactFormNewComponent } from "./contact-form/contact-form.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ContactsContainerComponent } from "./contacts-container/contacts-container.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { ContactsService } from "./contacts.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { PipesModule } from "../../core/pipe/pipes.module";
import { EffectsModule } from "@ngrx/effects";
import { ContactPersonComponent } from "./contact-person/contact-person.component";
import { ContactNewEffects, SharedModule } from "@energy-city/components";

@NgModule({
  declarations: [ContactFormNewComponent, ContactsContainerComponent, ContactsComponent, ContactPersonComponent],
  imports: [
    SharedModule,
    FormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    PipesModule,
    EffectsModule.forFeature([ContactNewEffects])
  ],
  exports: [ContactsContainerComponent],
  providers: [ContactsService]
})
export class ContactsModule {}
