import { Injectable } from "@angular/core";
import { AuthenticationService } from "../modules/authentication/services/exported/authentication.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { IUserInfo } from "@energy-city/components";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Roles } from "../core/enum/roles.enum";

@Injectable({
  providedIn: "root"
})
export class AuthorizationService {
  private _userInfo: BehaviorSubject<IUserInfo> = new BehaviorSubject<IUserInfo>(null);

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  public get userInfo$(): Observable<IUserInfo> {
    if (!this._userInfo.value) {
      this.loadUserInfo();
    }

    return this._userInfo.asObservable();
  }

  public isAllowedToEdit$(regionId: string): Observable<boolean> {
    return this.userInfo$.pipe(map((userInfo: IUserInfo) =>
      Boolean(userInfo?.roles.filter((item: string) => item.includes(regionId) && item.includes(Roles.EDITOR)).length)
    ));
  }

  private loadUserInfo(): void {
    const url = `${this.authService.tokenParsed.iss}/protocol/openid-connect/userinfo`;
    this.http.get<IUserInfo>(url)
      .pipe(catchError(() => of(null)))
      .subscribe( (userInfo: IUserInfo) => this._userInfo.next(userInfo));
  }
}
