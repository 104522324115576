import { EmissionDataService } from "../../../accounting-emission/data/emission-data.service";
import { AccountingMethod } from "./accounting-method.enum";
import { AccountingMethodGPC } from "./accounting-method-gpc";
import { AccountingMethodBiCO2sh } from "./accounting-method-bico2sh";
import { AccountingMethodSTET } from "./accounting-method-stet";
import { AccountingMethodBisko } from "./accounting-method-bisko";
import { AccountingMethodBICO2BW } from "./accounting-method-bico2bw";

export class AccountingMethodFactory {
  static create(accountingMethod: AccountingMethod, emissionDataService: EmissionDataService) {
    switch (accountingMethod) {
      case AccountingMethod.GPC:
        return new AccountingMethodGPC(emissionDataService);
      case AccountingMethod.BISKO:
        return new AccountingMethodBisko(emissionDataService);
      case AccountingMethod.STET:
        return new AccountingMethodSTET(emissionDataService);
      case AccountingMethod.BICO2SH:
        return new AccountingMethodBiCO2sh(emissionDataService);
      case AccountingMethod.BICO2BW:
        return new AccountingMethodBICO2BW(emissionDataService);
      default:
        throw Error(`Unknown accounting method ${accountingMethod}`);
    }
  }
}
