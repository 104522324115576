import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneChartModule } from "@energy-city/ui/chart";
import { TranslateModule } from "@ngx-translate/core";
import { SimulationComponent } from "./simulation.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatStepperModule } from "@angular/material/stepper";
import { MatButtonModule } from "@angular/material/button";
import { ReactiveFormsModule } from "@angular/forms";
import { SimulationScenarioComponent } from "./simulation-scenario/simulation-scenario.component";
import { SimulationGoalEditorComponent } from "./simulation-goal-editor/simulation-goal-editor.component";
import { EneModalModule } from "@energy-city/ui/modal";
import { EneFormsModule } from "@energy-city/ui/forms";
import { SimulationService } from "../../services/simulation/simulation.service";
import { AccountingSimulationModule } from "@co2-shared/accounting-simulation";

@NgModule({
  declarations: [SimulationComponent, SimulationScenarioComponent, SimulationGoalEditorComponent],
  entryComponents: [SimulationComponent, SimulationGoalEditorComponent],
  imports: [
    CommonModule,
    EneChartModule,
    EneIconModule,
    TranslateModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    EneModalModule,
    EneFormsModule,
    MatStepperModule,
    MatSliderModule,
    MatDialogModule,
    AccountingSimulationModule
  ],
  exports: [SimulationComponent],
  providers: [SimulationService]
})
export class SimulationModule {}
