import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import {
  SimulationActionTypes,
  GetScenariosSuccess,
  GetScenariosFailed,
  DeleteScenario,
  DeleteScenarioFailed,
  DeleteScenarioSuccess,
  AddScenario,
  AddScenarioSuccess,
  AddScenarioFailed,
  UpdateScenario,
  UpdateScenarioSuccess,
  UpdateScenarioFailed,
  GetScenarios
} from "./simulation-actions";
import { switchMap, map, catchError, tap, exhaustMap } from "rxjs/operators";
import { of } from "rxjs";
import { EneSnackbarService } from "@energy-city/ui/snackbar";
import { TranslateService } from "@ngx-translate/core";
import { IGetScenariosSuccess } from "../../models/simulation-model";
import { SimulationService } from "../../services/simulation/simulation.service";

@Injectable()
export class SimulationEffects {
  @Effect()
  public getScenarios = this.actions$.pipe(
    ofType(SimulationActionTypes.GET_SCENARIOS),
    switchMap(() =>
      this.simulationService.getScenarios().pipe(
        map(({ result: scenarios }: IGetScenariosSuccess) => new GetScenariosSuccess({ scenarios })),
        catchError(() => of(new GetScenariosFailed()))
      )
    )
  );

  @Effect()
  public deleteScenario = this.actions$.pipe(
    ofType(SimulationActionTypes.DELETE_SCENARIO),
    exhaustMap((action: DeleteScenario) =>
      this.simulationService.deleteScenario(action.payload.scenarioId).pipe(
        map(() => new DeleteScenarioSuccess()),
        catchError((error) => of(new DeleteScenarioFailed({ error })))
      )
    )
  );

  @Effect()
  public addScenario = this.actions$.pipe(
    ofType(SimulationActionTypes.ADD_SCENARIO),
    switchMap((action: AddScenario) =>
      this.simulationService.addScenario(action.payload).pipe(
        map(() => new AddScenarioSuccess()),
        catchError((error) => of(new AddScenarioFailed({ error })))
      )
    )
  );

  @Effect()
  public updateScenario = this.actions$.pipe(
    ofType(SimulationActionTypes.UPDATE_SCENARIO),
    switchMap((action: UpdateScenario) =>
      this.simulationService.updateScenario(action.payload).pipe(
        map(() => new UpdateScenarioSuccess()),
        catchError((error) => of(new UpdateScenarioFailed({ error })))
      )
    )
  );

  @Effect()
  public editScenarioSuccess = this.actions$.pipe(
    ofType(
      SimulationActionTypes.UPDATE_SCENARIO_SUCCESS,
      SimulationActionTypes.DELETE_SCENARIO_SUCCESS,
      SimulationActionTypes.ADD_SCENARIO_SUCCESS
    ),
    tap((action) => {
      this.translate
        .get(`SIMULATION.SCENARIO.MESSAGES.MODIFYING_SUCCESSFUL`)
        .subscribe((translation) => this.snackbar.success(translation));
    }),
    map(() => new GetScenarios())
  );

  @Effect({ dispatch: false })
  public editScenarioFailed = this.actions$.pipe(
    ofType(
      SimulationActionTypes.UPDATE_SCENARIO_FAILED,
      SimulationActionTypes.DELETE_SCENARIO_FAILED,
      SimulationActionTypes.ADD_SCENARIO_FAILED
    ),
    tap((action) => {
      this.translate
        .get(`SIMULATION.SCENARIO.MESSAGES.MODIFYING_FAILED`)
        .subscribe((translation) => this.snackbar.error(translation));
    })
  );

  constructor(
    private actions$: Actions,
    private simulationService: SimulationService,
    private snackbar: EneSnackbarService,
    private translate: TranslateService
  ) {}
}
