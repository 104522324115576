import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  Inject,
  AfterViewInit
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import * as fromRoot from "@energy-city/components";
import { Store } from "@ngrx/store";

export interface IGlossaryData {
  title: string;
  icon: string;
  sections: Array<IGlossaryTabData>;
  initialTab?: number;
  initialAnchor?: string;
}

export interface IGlossaryTabData {
  id: string | number;
  title?: string;
  content?: string;
}

@Component({
  selector: "app-glossary",
  templateUrl: "./glossary.component.html",
  styleUrls: ["./glossary.component.scss"]
})
export class GlossaryComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("glossaryContent", { static: false }) public glossaryContent: ElementRef;

  protected subscriptionCollection: Array<Subscription> = [];

  public glossary_data: IGlossaryData;
  public glossary_tabs: Array<IGlossaryTabData> = [];
  public glossary_activeTab: number = 0;
  public glossary_title: string = "";

  constructor(
    private dialogRef: MatDialogRef<GlossaryComponent>,
    private store: Store<fromRoot.IApplicationState>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data: IGlossaryData
  ) {
    this.assignSectionsIds(data);

    this.glossary_data = data;
    this.glossary_activeTab = data.initialTab || 0;

    if (!this.glossary_data) {
      return;
    }

    this.glossary_tabs = this.glossary_data.sections;
    this.glossary_title = this.glossary_data.title;
  }

  public ngOnInit(): void {
    if (this.glossary_data.initialAnchor) {
      this.goToAnchorByName(this.glossary_data.initialAnchor);
    }
  }

  public ngAfterViewInit(): void {
    this.scrollToTheTop();
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach((subscr) => subscr.unsubscribe());
  }

  private assignSectionsIds(glossary_data: IGlossaryData) {
    if (glossary_data && glossary_data.sections) {
      // check if ids are set. if not set it by number
      let i = 0;
      for (const section of glossary_data.sections) {
        if (section.id == null) {
          section.id = i++;
        }
      }
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public changeTab(tabIndex: number) {
    this.glossary_activeTab = tabIndex;
    this.scrollToTheTop();
  }

  private scrollToTheTop(): void {
    const natElem = this.glossaryContent?.nativeElement;
    if (!natElem) {
      return;
    }
    natElem.scrollTop = 0;
  }

  /**
   * Jump to anchor, maybe also change tab
   * the click needs to be on a <a data-anchor="(<tabId>.)<#anchorId>">
   * @param event
   */
  public goToAnchorByEvent(event) {
    if (event.srcElement.tagName !== "A") {
      return;
    }
    const anchors = event.srcElement.attributes.getNamedItem("data-anchor");
    if (!anchors || !anchors.nodeValue) {
      return;
    }
    const anchorPath = anchors.nodeValue.split(".");
    this._goToAncher(anchorPath);
  }

  public goToAnchorByName(anchor: string) {
    const anchorPath = anchor.split(".");
    this._goToAncher(anchorPath);
  }

  private _goToAncher(anchorPath: Array<string>) {
    // set tab
    if (anchorPath.length === 2) {
      // find index of tab by id
      const tabIndex = this.glossary_tabs.findIndex((tab) => tab.id === anchorPath[0]);
      if (tabIndex < 0) {
        return;
      }
      this.glossary_activeTab = tabIndex;
    }

    // scroll to section
    const section = anchorPath[anchorPath.length - 1]; // could be 0 or 1 index
    if (section) {
      // if tab change, the content needs time to load
      // @TODO: Maybe better to use here the translation service and wait for finish
      setTimeout(() => {
        // Have to use any here, because property "_elementRef" is private in MatDialogContainer
        const _this = this;
        const containerRef = ((this.dialogRef._containerInstance as any)["_elementRef"] as ElementRef).nativeElement;
        const natElem = this.glossaryContent?.nativeElement;
        if (!natElem) {
          return;
        }
        const anchorElem = natElem.querySelector(section);
        if (!anchorElem) {
          return;
        }
        anchorElem.scrollIntoView();
        containerRef.scrollTo(0, -1 * containerRef.scrollTop);
      }, 100);
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}
