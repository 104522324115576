import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { IPanelEvent } from "../models/ui-sidepanel.interface";
import { EneSidepanelComponent } from "../components/sidepanel/ui-sidepanel.component";
import { EneSidenavComponent } from "../components/sidenav/ui-sidenav.component";

@Injectable({
  providedIn: "root"
})
export class EneSidenavService {
  public onPanelUpdate$: ReplaySubject<any> = new ReplaySubject<any>(null);
  public onPanelChange$: ReplaySubject<IPanelEvent> = new ReplaySubject<IPanelEvent>(null); // @TCH wieso replaysubject ?? - UBE
  public onPanelInit$: ReplaySubject<EneSidepanelComponent> = new ReplaySubject<EneSidepanelComponent>(null); // @TCH wieso replaysubject ?? - UBE
  public onPanelVerticalHeightChange$: Subject<number> = new Subject<number>();

  public sidenav: EneSidenavComponent;

  public responsiveCalculator(
    innerWidth?: number | boolean,
    returnNameInsteadOfNumber: boolean = false
  ): string | number {
    if (!innerWidth) {
      innerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    // switch of true so it will execute always. I've used switch here becaus it probably looks cleaner with it.
    switch (true) {
      case innerWidth < 1024:
        return returnNameInsteadOfNumber ? "sm" : 42;
      case innerWidth >= 1024 && innerWidth < 1440:
        return returnNameInsteadOfNumber ? "md" : 48;
      case innerWidth >= 1440 && innerWidth < 1930:
        return returnNameInsteadOfNumber ? "lg" : 64;
      case innerWidth >= 1930:
        return returnNameInsteadOfNumber ? "xl" : 80;
    }
  }
}
