import { IUnitOption } from "../../../../configs/data-panel";
import { Action } from "@ngrx/store";
import { IDataInputDto } from "../../interfaces/data-input-dto";

export enum DataInputActionTypes {
  GET_TABLE_DATA = "[Data Table]: Get data-input data",
  GET_TABLE_DATA_SUCCESS = "[Data Table]: Get data-input data successfully",
  GET_TABLE_DATA_ERROR = "[Data Table]: Get data-input data error",
  LOCK_TABLE = "[Data Table]: Lock / Unlock table",
  PATCH_TABLE_DATA = "[Data Table]: Patch data-input data",
  UPDATE_TABLE_DATA = "[Data Table]: Update data-input data",
  UPDATE_TABLE_DATA_BY_ID = "[Data Table]: Update values by id",
  UPDATE_UNIT = "[Data Table]: Update selected unit",
  IMPORT_CSV = "[Data TAble]: Import data into csv file"
}

export interface IUpdateProps {
  id: string;
  year: number;
  value: number;
  quality: number;
}

export interface ICategory {
  category: string;
  nestedCategory: string;
}

export class GetTableData implements Action {
  public readonly type = DataInputActionTypes.GET_TABLE_DATA;

  constructor(public regionId: string, public props: ICategory) {}
}

export class GetTableDataSuccess implements Action {
  public readonly type = DataInputActionTypes.GET_TABLE_DATA_SUCCESS;

  constructor(public payload: IDataInputDto) {}
}

export class GetTableDataError implements Action {
  public readonly type = DataInputActionTypes.GET_TABLE_DATA_ERROR;

  constructor(public errorMessage: string) {}
}

export class LockTable implements Action {
  public readonly type = DataInputActionTypes.LOCK_TABLE;

  constructor(public prop: boolean) {}
}

export class PatchTableData implements Action {
  public readonly type = DataInputActionTypes.PATCH_TABLE_DATA;

  constructor(public regionId: string, public props: ICategory, public payload: IDataInputDto) {}
}

export class UpdateTableData implements Action {
  public readonly type = DataInputActionTypes.UPDATE_TABLE_DATA;

  constructor(public regionId: string, public props: ICategory, public payload: IDataInputDto) {}
}

export class UpdateTableById implements Action {
  public readonly type = DataInputActionTypes.UPDATE_TABLE_DATA_BY_ID;

  constructor(public props: IUpdateProps) {}
}

export class UpdateUnit implements Action {
  public readonly type = DataInputActionTypes.UPDATE_UNIT;

  constructor(public props: IUnitOption) {}
}

export type DataInputActionsUnion =
  | GetTableData
  | GetTableDataSuccess
  | GetTableDataError
  | LockTable
  | PatchTableData
  | UpdateTableData
  | UpdateTableById
  | UpdateUnit;
