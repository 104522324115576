/* Buttons */
// Headers
import {
  DefaultHeaderComponent,
  Co2BalanceHeaderComponent,
  HansewerkHeaderComponent
} from "../../modules/header/components";

import { DefaultFooterComponent } from "../../modules/footer/components";
import { ChartEnergyRelatedGreenhouseGasesComponent } from "../../modules/co2-cockpit/charts/greenhouse-gases/energy-related/chart-energy-related-greenhouse-gases.component";
import { ChartGreenhouseGasesTrendComponent } from "../../modules/co2-cockpit/charts/greenhouse-gases/trend/chart-greenhouse-gases-trend.component";
import { ChartFinalEnergySectorsComponent } from "../../modules/co2-cockpit/charts/final-energy/sectors/chart-final-energy-sectors.component";
import { ChartFinalEnergyTrafficComponent } from "../../modules/co2-cockpit/charts/final-energy/traffic/chart-final-energy-traffic.component";
import { ChartFinalEnergyPerEnergyCarrierComponent } from "../../modules/co2-cockpit/charts/final-energy/energy-carrier/chart-final-energy-per-energy-carrier.component";
import { ChartCo2SectorsFullComponent } from "../../modules/co2-cockpit/charts/greenhouse-gases/sectors/full/chart-co2-sectors-full.component";
import { AccountingMethodsComponent } from "../../modules/data-panel/components/accounting-methods/accounting-methods.component";
import { ChartEnergyEfficiencyComponent } from "../../components/charts/cockpit/energy-efficiency/chart-energy-efficiency.component";
import { DataInputComponent } from "../../modules/data-panel/components/data-input/data-input.component";
import { DownloadReportComponent } from "../../components/dynamic/download-report/download-report.component";
import { FactorManagementComponent } from "../../modules/data-panel/components/factor-management/factor-management.component";

// Footers
/* Info Panel */
export class EntryComponents {
  public static charts = {
    ChartEnergyRelatedGreenhouseGasesComponent: ChartEnergyRelatedGreenhouseGasesComponent,
    ChartEnergyEfficiencyComponent: ChartEnergyEfficiencyComponent,
    ChartGreenhouseGasesTrendComponent: ChartGreenhouseGasesTrendComponent,
    ChartFinalEnergySectorsComponent: ChartFinalEnergySectorsComponent,
    ChartFinalEnergyTrafficComponent: ChartFinalEnergyTrafficComponent,
    ChartFinalEnergyPerEnergyCarrierComponent: ChartFinalEnergyPerEnergyCarrierComponent,
    ChartCo2SectorsComponent: ChartCo2SectorsFullComponent
  };

  public static buttons = {
    DownloadReportComponent: DownloadReportComponent
  };

  public static headers = {
    default: DefaultHeaderComponent,
    dena: Co2BalanceHeaderComponent,
    enersis: Co2BalanceHeaderComponent,
    hansewerk: HansewerkHeaderComponent
  };

  public static footers = {
    default: DefaultFooterComponent
  };

  public static dataPanel = {
    AccountingMethods: AccountingMethodsComponent,
    FactorManagement: FactorManagementComponent,
    DataInput: DataInputComponent
  };
}
